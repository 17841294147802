import app from "./ngmodule";

export class AsyncToQ {
  static $inject = ["$q"];

  constructor(private $q: ng.IQService) {}

  convert<T>(promise: Promise<T> | (() => Promise<T>)): ng.IPromise<T> {
    let defer = this.$q.defer<T>();

    if (typeof promise === "function") {
      promise = promise();
    }

    promise.then(defer.resolve).catch(defer.reject);

    return defer.promise;
  }
}

app.factory("async-to-q", AsyncToQ);
