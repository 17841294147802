'use strict';

import './index.scss';
import app from '../ngmodule';
import { ControllerWithValidationBase } from '../control-with-validation-base';
import angular from 'angular';
import { IValidationService } from '../../services/validator-service';
import $ from "jquery";
import "kendo-ui-core";

const Enter_Keycode: number = 13;

class TextBoxController extends ControllerWithValidationBase {
    public multiline?: boolean;
    public onUpdate?: null | ((args: any) => void) = null;
    public onFired?: null | ((args: any) => void) = null;
    public value?: string;
    public disabled: boolean;
    public icon?: string;

    static $inject = ['$element', '$sce', 'vmValidationService', '$timeout', '$compile', '$scope'];
    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, validationService: IValidationService, protected $timeout: ng.ITimeoutService, protected $compile: ng.ICompileService, protected $scope: ng.IScope) {
        super($element, $sce, validationService);

        this.disabled = false;
    }

    $onInit() {
        super.$onInit();

        if (angular.isDefined(this.multiline) == false) {
            this.multiline = this.$element.attr('multiline') == 'true';
        }

        if (angular.isDefined(this.icon) == false) {
            this.icon = this.$element.attr('icon');
        }

        this.$timeout(() => {
            if(this.multiline == false)
            {
                this.$element.find('input').kendoTextBox({
                    label: {
                        content: this.prompt,
                        floating: true
                    }
                });

                if(this.icon)
                {
                    // ok, so we need to insert an icon into the ui, the kendo api doesn't support this with JS, but does allow for it through addition of html
                    let html = this.$compile(`<span class="k-input-sufix" ng-click="$ctrl.fired()"><span class="k-input-icon k-icon k-i-${this.icon}"></span></span>`)(this.$scope);
                    this.$element.find('.k-input.k-textbox').append(html);
                }
            }
            else {
                this.$element.find('textarea').kendoTextArea({
                    resize: "both",
                    label: {
                        content: this.prompt,
                        floating: true
                    }
                });
            }

            this.doEnableDisable();
        });
    }

    protected doEnableDisable(): void {
        let kendo: any;

        if(this.multiline == false)
        {
            kendo = this.$element.find('input').data('kendoTextBox');
        }
        else
        {
            kendo = this.$element.find('textarea').data('kendoTextArea');
        }

        if(!kendo)
        {
            return;
        }

        kendo.enable(!this.disabled);
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        super.$onChanges(changes);

        if (changes.value && changes.value.currentValue) {
            this.value = changes.value.currentValue;
        }

        if (changes.disabled && changes.disabled.currentValue != undefined) {
            this.disabled = changes.disabled.currentValue;
            this.doEnableDisable();
        }
    }

    iterceptKeyStroke($event: any): void {
        if ($event.which == Enter_Keycode) {
            this.fired();
            $event.preventDefault();
        }
    }

        // called when an icon we have is hit or enter is pressed
    fired(): void {
        if (this.onFired == null) {
            return;
        }

        this.validate(this.value);

        this.onFired({ $event: { value: this.value, control: this } });
    }

    changed(): void {
        this.validate(this.value);

        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { value: this.value, control: this } });
    }
}

app.component('vmTextbox', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after'
    },
    bindings: {
        value: '=',
        prompt: '<',
        icon: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        validator: '=',
        id: '<',
        multiline: '<',
        disabled: '<',
        onUpdate: '&',
        onFired: '&'
    },
    controller: TextBoxController
});