import { IWebServiceUrl } from '../common/web-service-url';
import app from './ngmodule';

export interface IGlobalSettingsService {
    getSetting(name: string): ng.IPromise<string | undefined>;
}

export class vmGlobalSettingsService implements IGlobalSettingsService {

    static $inject = ['webServiceUrl', '$q', '$http'];
    constructor(private webServiceUrl: IWebServiceUrl, protected $q: ng.IQService, protected $http: ng.IHttpService) { }

    getSetting(name: string): ng.IPromise<string | undefined> {
        let r = this.$q.defer<string>();

        let url = `${this.webServiceUrl.odata('GlobalSettings', '4')}?$filter=Name eq '${name}'&$top=1`;

        this.$http.get(url).then((response) => {
            let data = response.data as any;
            if ((data?.value?.length ?? 0) > 0) {
                r.resolve(data.value[0].Value);
            } else {
                r.resolve(undefined);
            }
        });

        return r.promise;
    }
}

app.factory('vmGlobalSettingsService', vmGlobalSettingsService);