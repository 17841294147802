'use strict';

import app from '../../../ngmodule';
import { ControllerBase } from '../../../control-base';

export class CardFooterController extends ControllerBase {
}

app.component('vmCardFooter', {
    template: require('./template.html').default,
    transclude: true,
    controller: CardFooterController
});