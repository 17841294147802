'use strict';

import app from './player/ngmodule';
import '@uirouter/angularjs';

app.config(
  ['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {

    $stateProvider.
      state('root', {
        abstract: true,
        url: '/a',
        views: {
          'body@': {
            component: 'bodyCtrl'
          },

          'content-control@root': {
            component: 'rootContentCtrl'
          }
        }
      }).




      state('login', {
        url: '/a/login?returnUrl',
        views: {
          'body@': {
            component: 'loginCtrl'
          }
        }
      });




    $urlRouterProvider.otherwise('/a/dashboard');
  }]);