'use strict';

import app from '../../ngmodule';
import angular from 'angular';

app.controller('presentRootCtrl', ['$scope', 'bworkflowApi', '$location', 'transitionCache', '$stateParams', '$rootScope', 'playerButtons', 'playerActions',
  function ($scope, bworkflowApi, $location, transitionCache, $stateParams, $rootScope, playerButtons, playerActions) {

    //If we land here directly (/continue), we won't have a player model
    //Fetch one and continue

    $scope.loading = false;
    if (!transitionCache.getItem($stateParams.workingDocumentId)) {
      $scope.loading = true;
      bworkflowApi.continueChecklist($stateParams.workingDocumentId).
        then(function (pm) {
          $scope.transitionWithPlayerModel(pm, 'none');
        }, function (ex) {
          $scope.transitionWithPlayerModel(ex.data, 'none');
        });
    }

    //button controlls
    $scope.buttonStates = playerButtons;

    $scope.buttonActions = playerActions;

    $scope.models = {
      answerModel: {},
      playerModel: {}
    };


    $scope.$on('$stateChangeSuccess', function () {
      $scope.buttonStates.areAjaxing = false;
    });

    //This stuff effectively wires up browser back
    //to checklist back
    $scope.$on('$stateChangeStart', function (ev, toState, toParams, fromState, fromParams) {

      if (!$scope.buttonStates.areAjaxing) {
        //are we trying to hit forward or backwards?
        var prevNonce = $rootScope.nonceStack[$rootScope.nonceStack.length - 2];
        if (toParams.nonce == prevNonce && typeof prevNonce !== 'undefined') {
          //we're going back
          ev.preventDefault();
          if ($scope.buttonStates.canPrevious) {
            $rootScope.nonceStack.pop();
            $rootScope.nonceStack.pop();
            $scope.buttonActions.doTransition('prev', toParams.nonce);
          }
        }
      }
    });
  }]);