import './index.scss';

import app from '../../ngmodule';
import angular, { IRootElementService } from 'angular';
import { ITakePictureController, TakePictureOptions, TakePictureResult } from '../interface';
import base64 from 'base64-arraybuffer';

class Html5TakePictureController implements ITakePictureController {
	public showCamera: boolean = false;
	public options?: TakePictureOptions;
	public onCancel?: () => void;
	public onPicture?: ((result: TakePictureResult) => void);

	static $inject = ['$element', '$timeout', '$window'];
	constructor(
		protected $element: ng.IRootElementService,
		protected $timeout: ng.ITimeoutService,
		protected $window: ng.IWindowService
	) {
	}

	$onChanges(changes: ng.IOnChangesObject) {
		if ('showCamera' in changes) {
			if (this.showCamera) {
				this.startCamera();
			} else {
				this.stopCamera();
			}
		}
	}

	$onInit() {

		// Move the modal to the Body root
		$(this.$element).appendTo('body');
	}

	startCamera() {
		this.$window.navigator.mediaDevices.getUserMedia({
			video: {
				facingMode: 'environment'
			}
		}).then(stream => {
			this.$timeout(() => {
				const video = this.$element.find('video')[0] as HTMLVideoElement;
				video.srcObject = stream;
			})
		}).catch(error => {
			console.log(error);
		})
	}

	stopCamera() {
		const video = this.$element.find('video')[0] as HTMLVideoElement;
		const src = video?.srcObject as any;
		if (src != null) {
			src.getTracks().forEach((track: any) => track.stop());
		}
	}

	$onDestroy() {
		this.stopCamera();
		$(this.$element).remove();
	}

	takePicture() {
		const video = this.$element.find('video')[0] as HTMLVideoElement;
		var canvas = document.createElement("canvas");
		canvas.width = this.options?.maxWidth || video?.videoWidth || 800;
		canvas.height = this.options?.maxHeight || video?.videoHeight || 600;
		const ctx = canvas.getContext('2d');

		ctx?.drawImage(video as HTMLVideoElement, 0, 0, canvas.width, canvas.height);

		const dataUrl = canvas.toDataURL();
		this.onPicture?.({
			dataUrl
		});
	}

	cancel() {
		this.onCancel?.();
	}
}

export function useHtml5TakePictureController() {
	app.component('vmTakePicture', {
		template: require('./template.html').default,
		bindings: {
			onCancel: '&',
			onPicture: '&',
			options: '<',
			showCamera: '<'
		},
		controller: Html5TakePictureController
	});
}