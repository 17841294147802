'use strict';

import './../../directives/vm-textbox';

import angular from 'angular';
import questionDirectiveBase from '../question-directive-base';
import questionsModule from '../ngmodule';

questionsModule.directive('questionFreeText', ['$timeout', 'bworkflowApi', 'languageTranslate', function ($timeout, bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {

      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.presented.controlShowsValidationErrors = true;
      scope.presented.controlShowsPrompt = true;

      scope.answer = scope.presented.Answer;

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return scope.answer.Text;
      };

      scope.changed = function ($event) {
        scope.validate('single');
      };
    }
  });

}]);