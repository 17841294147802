'use strict';

import app from '../ngmodule';
import './style.scss';
import { stringify } from '@uirouter/core';
import angular from 'angular';
import { ControllerBase } from '../control-base';

class ButtonBuilder {
    button: any;

    constructor(id: string) {
        this.button = {};
        this.button.id = id;
        this.button._visible = false;
        this.button.visible = () => this.button._visible;
        this.button.disabled = () => false;
        this.button.text = '';
        this.flex(33);
    }
    build() {
        return this.button;
    }
    text(text: string | number) {
        this.button._visible = true;
        this.button.text = text.toString();
        return this;
    }
    icon(font: string, icon: string) {
        this.button._visible = true;
        this.button.icon = { font, icon };
        return this;
    }
    cls(cls: string) {
        if (cls) {
            this.button.class = this.button.class || {};
            this.button.class[cls] = true;
        }
        return this;
    }
    click(click: Function) {
        this.button.click = click.bind(this.button);
        return this;
    }
    flex(flex: number) {
        this.button.flex = flex;
        return this;
    }
    disabled(fn: Function) {
        this.button.disabled = fn.bind(this.button);
        return this;
    }
    visible(fn: Function) {
        this.button.visible = fn.bind(this.button);
        return this;
    }
}

class MaterialKeyPadController extends ControllerBase {
    public step: number;
    public text: string;
    public keypadLayout: string;

    protected keypad: any[][];

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();

        this.step = 0;
        this.text = '';
        this.keypadLayout = '';
        this.keypad = [];
    }

    $onInit(): void {
        const self = this;

        const disableDigit = (digit: string) => {
            if (self.text != null && self.step) {
                let places = 0;
                let step = self.step;
                while (step !== Math.floor(step)) {
                    step *= 10;
                    places++;
                }

                let dp = self.text.indexOf('.');
                if (dp > 0) {
                    let check = self.text.replace('.', '') + digit;
                    let left = check.length - dp;
                    if (left > places) {
                        return true;
                    }

                    let n = Number(check + '0'.repeat(places - left));
                    return n % step !== 0;
                }
            }
            return false;
        };

        const textButton = (text: string) => {
            var btn = new ButtonBuilder(text);
            btn.text(text);
            btn.click(function () {
                self.text += btn.button.text;
            });

            return btn;
        };

        const numberButton = (number: number) => {
            let btn = textButton(number.toString());

            btn.disabled(function () {
                return disableDigit(btn.button.text);
            });

            return btn;
        };

        const iconButton = (font: string, icon: string) => new ButtonBuilder(icon).
            icon(font, icon);

        const backspaceButton = () => iconButton('material-icons', 'backspace').
            cls('md-accent').
            click(function () {
                if (self.text.length) {
                    self.text = (self.text || '').substr(0, self.text.length - 1);
                }
            }).
            disabled(() => !self.text);

        const decimalButton = () => textButton('.').
            disabled(function () {
                return (self.text || '').indexOf('.') >= 0;
            }).
            visible(function () {
                return self.keypadLayout === 'decimal';
            });

        const normalKeypad = [
            [numberButton(1).build(), numberButton(2).build(), numberButton(3).build()],
            [numberButton(4).build(), numberButton(5).build(), numberButton(6).build()],
            [numberButton(7).build(), numberButton(8).build(), numberButton(9).build()],
            [backspaceButton().build(), numberButton(0).build(), decimalButton().build()]];


        self.keypad = normalKeypad;
    }
}

app.component('materialKeypad', {
    template: require('./template.html').default,
    require: {
        ngModelCtrl: 'ngModel'
    },
    bindings: {
        text: '=ngModel',
        step: '<',
        keypadLayout: '<'
    },
    controller: MaterialKeyPadController
});