'use strict';

import app from './ngmodule';
import { generateCombGuid } from '../utils/util';

export interface IVMCombGuid {
    newComb(): string;
}

// simple service that wraps up combGuids as a service. We can link into these through the utils (which this does), but this gives us a service where
// we can handle these in a better way
class vmCombGuid implements IVMCombGuid {
    static $inject = [];
    constructor() {

    }

    newComb(): string {
        return generateCombGuid();
    }
}

app.factory('vmCombGuid', vmCombGuid);

// For backwards compatiblity we also publish this
app.factory('generateCombGuid', [() => generateCombGuid]);