'use strict';

import { debugFunc } from '../../utils/debug-func';
import app from '../ngmodule';

export function useDesktopLoginDetection() {
  const log = debugFunc(`auth.transitions`);

  app.run(["$transitions", "$state", function ($transitions, $state) {
    $transitions.onStart({ to: 'root.**' }, function (trans) {
      log.trace(`transition from ${trans.$from()} to ${trans.$to()}`)
      var authSvc = trans.injector().get('authSvc');
      return authSvc.isAuthenticated().then(authenticated => {
        if (!authenticated) {
          log.info(`Not authenticated`);
          // User isn't authenticated. Login, then continue
          return authSvc.login().then(user => {
            return user != null;
          }).catch(() => false);
        } else {
          return true;
        }
      });
    });
  }]);
}