'use strict';

import './index.scss';
import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';

class CheckboxController extends ControllerBase {
    public invalid: boolean;
    public prompt: string;
    public selected?: boolean;
    public model: any;
    public id: string;
    public disabled: boolean;
    public onUpdate?: null | ((args: any) => void) = null;

    static $inject = ['$element', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService) {
        super();

        this.invalid = false;
        this.prompt = '';
        this.disabled = false;
        this.id = 'checkbox' + uuidv4();
    }

    $onInit(): void {
        if (this.selected == null) {
            this.selected = false;
        }

        this.$timeout(() => {
            this.$element.find('input').kendoCheckBox({
                label: this.prompt
            });
        });        
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.selected) {
            this.selected = changes.selected.currentValue;
        }
    }

    changed(): void {
        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { selected: this.selected, model: this.model, prompt: this.prompt } });
    }
}

app.component('vmCheckbox', {
    template: require('./template.html').default,
    bindings: {
        model: '<',
        selected: '=',
        prompt: '<',
        invalid: '<',
        disabled: '<',
        onUpdate: '&'
    },
    controller: CheckboxController
});