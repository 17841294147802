'use strict';

import angular from 'angular';
import 'angular-resource';
import '@uirouter/angularjs';
import '@uirouter/core';
import 'angular-animate';
//import 'angular-material';
//import 'angular-material/angular-material.css';
import 'angular-filter';
import 'angular-messages';

import '../services/ng-geolocation';
import '../services/cached-execution-handlers';
import '../services/bworkflow-api/bworkflow-api';
import '../services/ngmodule';
import '../animation-directives';
import '../questions';
import '../directives';
import '../filters';
import '../common';

let app: ng.IModule | null = null;

try {
  // in the IOS app, the player application has already been defined
  // by the bootstrapping we do there, so we don't want to write over the top
  // of that, so we get that module
  app = angular.module('player');
} catch (e) {
  // ok so we must be running in a normal browser, so in that case we create the module
  app = angular.module('player', []);
}

export default app as ng.IModule;

// now no matter what, add our dependancies
app.requires.push('ngResource',
  'ui.router',
  'ui.router.util',
  'ui.router.router',
  'ui.router.state',
  'ngGeolocation',
  'animationDirectives',
  'transitionCache',
  'questions',
  'ngAnimate',
  'bootstrapCompat',
  'angular.filter',
  'vm-directives',
  'vm-simple-directives',
  'vm-filters',
  'vm-services',
  'vm-common',
  'ngMessages',
  'vcRecaptcha'

/*    
                 'leaflet-directive',
                 'angular-loading-bar',
                 'ngPinchZoom',
                 'angularBootstrapNavTree',
                 'pascalprecht.translate',
                 'languageTranslation',
                 'angular-svg-round-progress',
                 'siyfion.sfTypeahead',
                 'bworkflowAnimationFullScreen',
                 'temperatureModule',
                 'oc.lazyLoad',
             */
/*
                    'ngToast',
                    'bc.AngularKeypad',
                    'moment-picker',
                    'chart.js',
                    'temperature-probe-manager-module',
                    'bluetherm-thermaq-module',
                    'monospaced.qrcode',
                    'bluemaestro-tempo-module',
                    'vcRecaptcha'
                */);