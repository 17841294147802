'use strict';

import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import './vm-accordion-pane';
import { AccordionPaneController } from './vm-accordion-pane';
import { ControllerBase } from '../control-base';

export class AccordionController extends ControllerBase {
    public panels: Array<AccordionPaneController>;
    public id: string;

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();

        this.panels = [];
        this.id = 'accordian' + uuidv4();
    }

    isAccordianPaneController(test: AccordionPaneController | number): test is AccordionPaneController {
        return typeof test !== 'number';
    }

    selectTab(pane: AccordionPaneController | number): void {
        let p: AccordionPaneController;

        if (this.isAccordianPaneController(pane)) {
            p = pane;
        }
        else {
            if (pane < 0 || pane >= this.panels.length) {
                return;
            }

            p = this.panels[pane];
        }

        for (var i = 0; i < this.panels.length; i++) {
            this.panels[i].selected = false;
        }

        p.selected = true;
    }

    addPane(pane: AccordionPaneController): void {
        pane.parent = this.id;
        this.panels.push(pane);

        this.calculateSelectedPane();
    }

    calculateSelectedPane(): void {
        if (this.panels.length == 0) {
            return;
        }

        var selected = null;

        for (var i = 0; i < this.panels.length; i++) {
            let t = this.panels[i];

            if (t.selected == true) {
                selected = t;
                break;
            }
        }

        if (selected == null) {
            selected = this.panels[0];
        }

        selected.selected = true;
    }
}

app.component('vmAccordion', {
    template: require('./template.html').default,
    transclude: true,
    controller: AccordionController
});