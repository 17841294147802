
export interface IWindowRazorData {
	tenantUrl: string;
	apiprefix: string;
	authprefix: string;
	portalprefix: string;
	odataprefix: string;
	siteprefix: string;
	mediaprefix: string;
	environment: string;
}

declare global {
	interface Window {
		razordata: IWindowRazorData;
	}
}

export function updateRazorData(razorData: any, tenantUrl: string): IWindowRazorData {
	if (!tenantUrl.startsWith(`http`)) {
		tenantUrl = `https://${tenantUrl}`;
	}

	Object.assign(razorData, {
		...razorData,
		tenantUrl,
		apiprefix: `${tenantUrl}/api/`,
		authprefix: `${tenantUrl}/auth/`,
		// apiprefix: `${tenantUrl}/portal/api/v1/`,
		// authprefix: `${tenantUrl}/portal/api/v1/auth/`,
		portalprefix: `${tenantUrl}/portal/`,
		odataprefix: `${tenantUrl}/odata/`,
		siteprefix: `${tenantUrl}/spa/`,
		mediaprefix: `${tenantUrl}/media/`,
		proxyapi: `${tenantUrl}/api/`,
		proxylro: `${tenantUrl}/lro-worker/`,
	})

	return razorData as IWindowRazorData;
}


export function createRazorData(tenantUrl: string, environment: string): IWindowRazorData {
	let baseData = {
		environment
	}

	const razorData = updateRazorData(baseData, tenantUrl);

	console.log(`Using tenantUrl = ${razorData.tenantUrl}`);

	return razorData;
}
