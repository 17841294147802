'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionSelectCompany', ['bworkflowApi', '$timeout', 'languageTranslate', function (bworkflowApi, $timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.answer = scope.presented.Answer;

      scope.presented.controlShowsValidationErrors = true;
      scope.presented.controlShowsPrompt = true;

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return scope.answer.SelectedId;
      };

      scope.updateSelection = function (id, name) {
        scope.answer.SelectedId = id;
        scope.answer.SelectedName = name;
      };

      scope.searchItemSelected = function($event) {
        if($event.item == null)
        {
          scope.updateSelection("00000000-0000-0000-0000-000000000000", null); // an empty Guid get's translated to null on the server
          return;
        }

        scope.updateSelection($event.item.id, $event.item.name);
      };    
      
      scope.search = {
        search: function(text, callback) {

          var parameters = {
            text: text,
            page: 1,
            count: 5,
            userid: scope.presented.userid            
          };

          bworkflowApi.execute('CompanyDirectory', 'Search', parameters, undefined, true).then(function (result) {
            var results = [];

            angular.forEach(result.companies, function (option) {
              results.push({ text: option.name, data: option, class: undefined });
            });

            callback(results);
          });
        }
      };
    }
  });
}]);