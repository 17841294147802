import { IWatchablePersistantStorage, IPersistantStorage } from './types';

type ChangeCallback = (data: any) => void;

export class WatchablePersistantStorage implements IWatchablePersistantStorage, IPersistantStorage {
  private _onChange: { [key: string]: ChangeCallback[] } = {};

  constructor(private delegate: IPersistantStorage) {

  }

  createNamespace(namespace: string): IPersistantStorage {
    return new WatchablePersistantStorage(this.delegate.createNamespace(namespace));
  }

  onChange(key: string, callback: (data: any) => void): () => void {
    let callbacks = this._onChange[key];
    if (callbacks == null) {
      callbacks = [];
      this._onChange[key] = callbacks;
    }
    callbacks.push(callback);

    // Return unsubscribe function
    return () => {
      var i = callbacks.indexOf(callback);
      if (i >= 0) {
        callbacks.splice(i, 1);
        if (callbacks.length == 0) {
          delete this._onChange[key];
        }
      }
    }
  }

  getItem<T>(key: string, success?: (data: T) => void): ng.IPromise<T> {
    return this.delegate.getItem<T>(key, success);
  }

  setItem(key: string, data: any, success?: () => void, failure?: (err: Error) => void): ng.IPromise<void> {
    let promise = this.delegate.setItem(key, data, success, failure);

    promise.then(() => {
      let callbacks = this._onChange[key];
      if (callbacks != null) {
        callbacks.map(cb => cb(data))
      }
    })

    return promise;
  }

  removeItem(key: string, success?: (removed: boolean) => void): ng.IPromise<boolean> {
    let promise = this.delegate.removeItem(key, success);

    promise.then(() => {
      let callbacks = this._onChange[key];
      if (callbacks != null) {
        callbacks.map(cb => cb(null))
      }
    })

    return promise;
  }

  clear(callback?: () => void): ng.IPromise<void> {
    return this.delegate.clear(callback);
  }

  getKeys(callback?: (keys: string[]) => void): ng.IPromise<string[]> {
    return this.delegate.getKeys(callback);
  }
}
