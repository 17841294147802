import { IWebServiceUrl } from "../common/web-service-url";
import ngmodule from "./ngmodule";

export interface LroResponse {
	lroId?: string | null;
}

export interface LroDto<TResult> {
	id: string;
	status: 'queued' | 'starting' | 'finished' | 'error' | 'zombied' | 'running';
	lastUpdatedUtc?: string;
	dequeuedUtc?: string;
	startedUtc?: string;
	resultUtc?: string;
	errorUtc?: string;
	error?: string;
	zombiedUtc?: string;
	result?: TResult;
}

export interface ILroService {
	queue(url: string, params: any): ng.IPromise<string>;
	getProgress<TResult>(lroId: string, lastUpdatedUtc?: string, timeout?: number): ng.IPromise<LroDto<TResult> | null>;
	monitorResult<TResult>(lroId: string, progressCallback?: (progress: any) => void, pollInterval?: number): ng.IPromise<TResult>;
}

class LroService implements ILroService {
	static $inject = ['$http', '$timeout', '$q', 'webServiceUrl'];

	constructor(private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, private $q: ng.IQService, private webServiceUrl: IWebServiceUrl) {

	}

	queue(url: string, params: any) {
		return this.$http.post<LroResponse>(url, params).then(response => {
			if (response.data != null && response.data.lroId != null) {
				return response.data.lroId;
			}
			throw new Error('Error starting Lro');
		})
	}

	getProgress<TResult>(lroId: string, lastUpdatedUtc?: string, timeout?: number): ng.IPromise<LroDto<TResult> | null> {
		return this.$http.get<LroDto<TResult>>(`${this.webServiceUrl.api('LongRunningOperation/WaitForProgress')}/${lroId}?timeout=${timeout}&lastUpdatedUtc=${lastUpdatedUtc || ''}`).then(progressResponse => {
			if (progressResponse.data != null) {
				return progressResponse.data;
			}
			throw new Error('Error retrieving Lro');
		}).catch(err => {
			return null;
		})
	}
	/*
		LroService.prototype.getRunning = function () {
			return $http.get(`${lroApi}LongRunningOperation/Running`).then(response => {
				if (response.data != null) {
					return response.data;
				}
				throw new Error('Error retrieving Running');
			})
		}
	*/
	monitorResult<TResult>(lroId: string, progressCallback?: (progress: any) => void, pollInterval?: number): ng.IPromise<TResult> {
		let deferred = this.$q.defer<TResult>();
		pollInterval = pollInterval || 30000;
		let lastProgress: LroDto<TResult>;

		let pollNow = () => {
			this.getProgress<TResult>(lroId, lastProgress != null ? lastProgress.lastUpdatedUtc : undefined, pollInterval).then(lroDto => {
				if (lroDto == null) {
					deferred.reject('not found');
				} else {
					lastProgress = lroDto;

					if (progressCallback != null) {
						progressCallback(lroDto);
					}

					if (lroDto.dequeuedUtc == null) {
						// It has not been dequeued by the LroScheduler yet
						lroDto.status = 'queued';
					} else if (lroDto.startedUtc == null) {
						// It has been dequeued and LroScheduler is finding an LroWorker
						lroDto.status = 'starting';
					} else if (lroDto.resultUtc != null) {
						lroDto.status = 'finished';
					} else if (lroDto.errorUtc != null) {
						lroDto.status = 'error';
					} else if (lroDto.zombiedUtc != null) {
						lroDto.status = 'zombied';
					} else {
						lroDto.status = 'running';
					}

					deferred.notify(lroDto);

					if (lroDto.resultUtc != null) {
						deferred.resolve(lroDto.result);
					} else if (lroDto.errorUtc != null) {
						deferred.reject(lroDto.error);
					} else if (lroDto.zombiedUtc != null) {
						deferred.reject('zombied');
					} else {
						// We are still running, schedule another poll
						this.$timeout(pollNow, 0);
					}
				}
			}).catch(err => {
				if (err.status === 404) { // Not found
					deferred.reject(err);
				} else {

					// Possible Http errors contacting LroWorker, we will wait for a concrete Result, Error or Zombie
					deferred.notify({
						...lastProgress,
						status: 'trouble',
						error: err.status ? 'Http error ' + err.status : 'Progress error'
					});
					this.$timeout(pollNow, 5000);
				}
			});
		}

		pollNow();

		return deferred.promise;
	}
}

ngmodule.factory('lroService', LroService);
