'use strict';

import app from '../../ngmodule';
import '../../../services/bworkflow-api/bworkflow-api';
import './list';

app.controller('dashboardMenuCtrl', ["$scope", "bworkflowApi",
  function ($scope, bworkflowApi) {
    bworkflowApi.getDashboard().
      then(function (data) {
        $scope.dashboards = data.Dashboards;
      });
  }]);