import angular from "angular";

import * as _ from 'lodash';
import { IPublishedResourceCompoundId, IBeginChecklistResult, ISaveChecklistResult, INextChecklistResult, IPrevChecklistResult, IContinueChecklistResult, IChecklistPlayer, IOfflineChecklistExecutionQueue } from '../../bworkflow-api/types';
import { generateCombGuid, makeGuidWithOffset } from "../../../utils/util";
import { createContext, LingoContext } from "./lingojs";
import { OfflineChecklistCache } from "./offline-checklist-cache";
import { IOfflineWorkingDocument, IWorkingDocumentFinishParameters, IOfflineChecklist, OfflineChecklistModel } from './types';
import moment from 'moment-timezone';
import ngmodule from './ngmodule';
import { CreateOfflineQuestionRenderer } from './offline-question-renderer';
import { FacilityFactory } from './facilities/index';
import { CreateOfflineAnswerConverter } from './offline-answer-converter';
import * as uuid from 'uuid';
import { IPersistantStorage } from '../../../common/persistant-storage/types';
import { JsonTree, JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';
import { IPresentedQuestion } from './presented-types';
import { IFreezable } from './lingojs/lingo-runtime-context';
import { buildExterns, IExternBuilder } from "./externs-builder";
import { ILingoAnswer } from "./lingojs/lingo-answers-context";
import requireMap from './require-map';
import { IPrepareable, IWorkingDocumentContext, PrepareType, FacilityType } from 'lingo-api';
import { IAuthService } from '../../auth/auth-svc/index';
import { JavascriptModuleCache } from "./javascript-module-cache";

import * as lingoApiModule from 'lingo-api';

export function createOfflineChecklistFromResponse(data: OfflineChecklistModel): IOfflineChecklist {
    const lingoModule = data.Checklist.modules.find(m => m.moduleType === 'lingo-js-source');
    const helpers = _.chain(data.Checklist.modules)
        .filter(m => m.moduleType === 'lingo-js-helper')
        .map(m => ({
            name: m.name,
            code: m.outputs.find(o => o.type === 'js')?.content ?? '',
            sourceMap: m.outputs.find(o => o.type === 'js.map')?.content
        }))
        .filter(m => m.code !== '')
        .value();

    return {
        compoundId: {
            id: Number(data.Id),
            groupId: Number(data.GroupId),
            resourceId: Number(data.ResourceId)
        },
        groupName: data.GroupName ?? '',
        name: data.Name,
        resourceNodeId: data.ResourceNodeId ?? '',
        lingo: lingoModule != null ? {
            code: lingoModule?.outputs?.find(o => o.type === 'js')?.content ?? '',
            sourceMap: lingoModule?.outputs?.find(o => o.type === 'js.map')?.content ?? ''
        } : undefined,
        helpers: helpers,
        tracking: data.Tracking ?? 0,
    };
}

interface WorkingDocumentIndex {
    finished: boolean;
    lastAccessedUtc: Date;                  // Dont use Moment here as this record is JSON serialized not JsonTree'ed
    expiresAtUtc: Date;
}

type WorkingDocumentsIndex = { [id: string]: WorkingDocumentIndex };
type WorkingDocumentsCache = { [id: string]: IOfflineWorkingDocument };

interface IRuntimePlayerContext {
    checklistId: string;
    workingDocumentId: string;
    checklists: IOfflineChecklist[];
    requiredChecklists: {
        groupName: string;
        name: string;
        checklist: IOfflineChecklist;
        exports: { [helper: string]: any };
    }[];
    lingoApiModule?: typeof lingoApiModule;
}

interface WorkingDocumentStoredMeta {
    workingDocumentId: string,
    dateStartedUtc: moment.Moment,
    checklist: IOfflineChecklist,
    checklists: IOfflineChecklist[],
    reviewerId: string,
    revieweeId: string,
    args: any
}

interface WorkingDocumentStoredConsts {
    consts: any[];
}

interface WorkingDocumentStoredVariables {
    runtimeContextState: any,
    finished: boolean,
    lastAccessedUtc: moment.Moment,
    expiresAtUtc: moment.Moment
}

interface WorkingDocumentStoredData {
    meta: any;
    consts: any;
    variables: any;
}

JsonTreeTranslators.register({
    ctr: Object,
    create: () => new Object(),
    flatten(o: any) {
        // Remove any Angular appended properties
        let result = Object.assign({}, o);
        delete result.$$hashKey;
        return result;
    }
}, {
    ctr: moment().constructor,
    name: `Moment`,
    flatten(o: moment.Moment) {
        return {
            dt: o.toISOString(),
            tz: o.tz()
        }
    },
    fatten(o: { dt: number, tz: number }, fatten: Convert, store: Convert) {
        let m = moment(fatten(o.dt));
        if (o.tz) {
            m = m.tz(fatten(o.tz))
        }
        return store(m);
    }
})


const WorkingDocumentExpirySettings = {
    checkEverySeconds: moment.duration(1, 'minute'),
    defaultExpireAfter: moment.duration(1, 'hour')
}

// This class is responsible for saving/restoring any WorkingDocument data outside of the Lingo contexts
class WorkingDocumentFreezer implements IFreezable {

    private _presentedQuestions: IPresentedQuestion[] = [];
    private _transaction: IPresentedQuestion[] = [];

    name: string = `WorkingDocumentVariables`;
    freeze() {
        let frozen = {
            presentedQuestions: this._transaction
        }
        this._transaction = [];
        return frozen;
    }
    unfreeze(popsicle: any, first: boolean): void {
        if (first) {
            this._presentedQuestions = [];
        }
        this._presentedQuestions.push.apply(popsicle.presentedQuestions);
        this._transaction = popsicle.presentedQuestions;
    }

    presentQuestion(question: IPresentedQuestion) {
        this._transaction.push(question);
        this._presentedQuestions.push(question);
    }

    getPresentedQuestions() {
        return this._presentedQuestions;
    }
}

export interface IOfflineChecklistPlayer extends IChecklistPlayer {
    getWorkingDocument(workingDocumentId: string, throwIfNotFound?: boolean): Promise<IOfflineWorkingDocument>;
    saveWorkingDocument(workingDocument: IOfflineWorkingDocument): Promise<void>;
    getCurrentContext(throwIfNotFound?: boolean): IWorkingDocumentContext;
}

export class OfflineChecklistPlayer implements IOfflineChecklistPlayer, IExternBuilder {
    private workingDocumentsIndex?: WorkingDocumentsIndex;
    private workingDocuments: WorkingDocumentsCache = {};
    private storage: IPersistantStorage;
    private currentWorkingDocumentContext?: IWorkingDocumentContext;
    private javascriptModuleCache = new JavascriptModuleCache();

    static $inject = [
        `persistantStorage`,
        `offlineChecklistCache`,
        `createOfflineQuestionRenderer`,
        `createOfflineAnswerConverter`,
        `offlineChecklistExecutionQueue`,
        `facilityFactory`,
        `$timeout`,
        `$injector`,
        'authSvc'];

    constructor(
        persistantStorage: IPersistantStorage,
        private offlineChecklistCache: OfflineChecklistCache,
        private createOfflineQuestionRenderer: CreateOfflineQuestionRenderer,
        private createOfflineAnswerConverter: CreateOfflineAnswerConverter,
        private offlineChecklistExecutionQueue: IOfflineChecklistExecutionQueue,
        private facilityFactory: FacilityFactory,
        private $timeout: ng.ITimeoutService,
        private $injector: ng.auto.IInjectorService,
        private authSvc: IAuthService
    ) {
        this.storage = persistantStorage.createNamespace(`OfflineChecklistPlayer`);

        // Schedule a check for expired WorkingDocuments straight away
        $timeout(this.pruneExpiredWorkingDocuments.bind(this), 0);

        // Watch for updates to the Offline Checklist cache so we can 'prepare' them appropriately
        offlineChecklistCache.onUpdate(async (updated: IOfflineChecklist[], all: IOfflineChecklist[]) => {
            let promises = updated.map(checklist => {
                let imports = this.helperImports({
                    checklistId: ``,
                    workingDocumentId: ``,
                    checklists: all,
                    requiredChecklists: []
                });

                return checklist.helpers.map(async h => {
                    const helper = this.javascriptModuleCache.createOrUpdate(`${checklist.groupName}/${checklist.name}/${h.name}`, checklist.tracking, h.code, imports);
                    // Wait for any facilities to prepare for 'cached'
                    return this.prepare(helper.module.exports, `cached`);
                });
            });

            await Promise.all(_.flatten(promises));
        })
    }

    buildExterns(externs: any[], iterate: (o: any) => any): void {
        // We have no externs we want to add, but we need to "do nothing" here otherwise the default ExternBuilder will iterate our properties and try to 
        // add everything we reference to the Externs array
    }

    private async prepare(exports: any, prepareType: PrepareType): Promise<void> {
        if (exports != null) {
            let all = [];
            for (let key in exports) {
                let e = exports[key] as IPrepareable;
                if (e != null && angular.isFunction(e.prepare)) {
                    all.push(Promise.resolve(e.prepare(prepareType)));
                }
            }
            await Promise.all(all);
        }
    }

    private async pruneExpiredWorkingDocuments(): Promise<void> {
        let index = await this.getWorkingDocumentsIndex();
        let toBeDeleted: string[] = [];
        for (let wdid in index) {
            // If the document is currently in memory then it means its been accessed recently, dont delete it, it might be in use right now
            if (this.workingDocuments[wdid] == null) {
                let wdi = index[wdid];

                let expiresAt = moment(wdi.expiresAtUtc);
                if (wdi.finished) {
                    toBeDeleted.push(wdid);
                    console.log(`Deleting WorkingDocument ${wdid} which is finished`);
                } else if (moment().isAfter(expiresAt)) {
                    toBeDeleted.push(wdid);
                    console.log(`Deleting WorkingDocument ${wdid} which is expired at ${expiresAt.format()}`);
                }
            } else {
                console.log(`WorkingDocument ${wdid} maybe in use, not deleting`);
            }
        }

        if (toBeDeleted.length) {
            toBeDeleted.map(async wdid => {
                delete index[wdid];
                await this.storage.createNamespace(wdid).clear();
            });
            await this.saveWorkingDocumentsIndex(index);
        }

        // reschedule next check
        this.$timeout(this.pruneExpiredWorkingDocuments.bind(this), WorkingDocumentExpirySettings.checkEverySeconds.asMilliseconds());
    }

    private async getWorkingDocumentsIndex(): Promise<WorkingDocumentsIndex> {
        if (this.workingDocumentsIndex == null) {
            this.workingDocumentsIndex = await this.storage.getItem<WorkingDocumentsIndex>(`workingDocumentsIndex`) || {};
        }
        return this.workingDocumentsIndex;
    }

    private async saveWorkingDocumentsIndex(index: WorkingDocumentsIndex): Promise<void> {
        this.workingDocumentsIndex = index;
        return await this.storage.setItem(`workingDocumentsIndex`, this.workingDocumentsIndex);
    }

    async hasWorkingDocument(workingDocumentId: string): Promise<boolean> {
        let workingDocumentIndex = await this.getWorkingDocumentsIndex();
        return workingDocumentIndex[workingDocumentId] != null;
    }

    private async restoreWorkingDocument(storedData: WorkingDocumentStoredData): Promise<IOfflineWorkingDocument> {
        let metaData: WorkingDocumentStoredMeta = JsonTree.fatten(storedData.meta)
        let checklist = metaData.checklist;
        let workingDocumentId = metaData.workingDocumentId;
        let context = createContext();

        let workingDocumentContext: IWorkingDocumentContext = {
            workingDocumentId,
            checklistId: checklist.resourceNodeId,
            args: metaData.args,
            revieweeId: metaData.revieweeId,
            reviewerId: metaData.reviewerId
        }

        let checklistContext: IRuntimePlayerContext = {
            workingDocumentId: workingDocumentId,
            checklistId: checklist.resourceNodeId,
            checklists: metaData.checklists,
            requiredChecklists: []
        }
        let imports = this.helperImports(checklistContext);
        let helpersExports = await Promise.all(checklist.helpers.map(async h => {
            const helper = this.javascriptModuleCache.createOrUpdate(`${checklist.groupName}/${checklist.name}/${h.name}`, checklist.tracking, h.code, imports);
            // Wait for any facilities to prepare for 'pre-restore'
            await this.prepare(helper.module.exports, `pre-restore`);

            return helper.module.exports;
        }));

        let jsonTree = new JsonTree({
            externs: await buildExterns(this, imports, ...helpersExports)
        });
        context.runtimeContext.jsonTree = jsonTree;

        let constData: WorkingDocumentStoredConsts = jsonTree.fatten(storedData.consts);
        let lingoJsModule = this.javascriptModuleCache.createOrUpdate(`${checklist.groupName}/${checklist.name}.lingo`, checklist.tracking, checklist.lingo?.code ?? ``,
            this.scriptImports(checklistContext, constData.consts, jsonTree),
            context.lingoSyntax
        ).module;

        // Const data is data which should not change and can be JsonTree externed
        jsonTree.options.externs.push.apply(jsonTree.options.externs, constData.consts);

        let variableData: WorkingDocumentStoredVariables = jsonTree.fatten(storedData.variables);
        let workingDocumentFreezer = new WorkingDocumentFreezer();
        context.runtimeContext.register(workingDocumentFreezer);

        context.runtimeContext.setState(variableData.runtimeContextState);

        let workingDocument: IOfflineWorkingDocument = {
            workingDocumentId: workingDocumentId,
            answersContext: context.answersContext,
            checklist: checklist,
            checklists: metaData.checklists,
            dateStartedUtc: moment(metaData.dateStartedUtc),
            lingoJsModule: lingoJsModule,
            lingoSyntax: context.lingoSyntax,
            programContext: context.programContext,
            runtimeContext: context.runtimeContext,
            interpolateContext: this.createInterpolateContext(context, ...helpersExports),
            freezer: workingDocumentFreezer,
            finished: variableData.finished,
            lastAccessedUtc: variableData.lastAccessedUtc,
            expiresAtUtc: variableData.expiresAtUtc,
            consts: constData.consts,
            workingDocumentContext
        }

        return workingDocument;
    }

    async saveWorkingDocument(workingDocument: IOfflineWorkingDocument): Promise<void> {
        let metaData: WorkingDocumentStoredMeta = {
            workingDocumentId: workingDocument.workingDocumentId,
            dateStartedUtc: workingDocument.dateStartedUtc,
            checklist: workingDocument.checklist,
            checklists: workingDocument.checklists,
            revieweeId: workingDocument.workingDocumentContext.revieweeId,
            reviewerId: workingDocument.workingDocumentContext.reviewerId,
            args: workingDocument.workingDocumentContext.args
        }
        let variableData: WorkingDocumentStoredVariables = {
            runtimeContextState: workingDocument.runtimeContext.getState(),
            finished: workingDocument.finished,
            lastAccessedUtc: workingDocument.lastAccessedUtc,
            expiresAtUtc: workingDocument.expiresAtUtc
        }
        let constData: WorkingDocumentStoredConsts = {
            consts: workingDocument.consts
        }

        let jsonTree = workingDocument.runtimeContext.jsonTree;
        let storedData: WorkingDocumentStoredData = {
            meta: JsonTree.flatten(metaData),
            consts: JsonTree.flatten(constData),
            variables: jsonTree.flatten(variableData)
        }
        await this.storage.createNamespace(workingDocument.workingDocumentId).setItem(`storedData`, storedData);

        let workingDocumentsIndex = await this.getWorkingDocumentsIndex();
        workingDocumentsIndex[workingDocument.workingDocumentId] = {
            finished: workingDocument.finished,
            lastAccessedUtc: workingDocument.lastAccessedUtc.toDate(),
            expiresAtUtc: workingDocument.expiresAtUtc.toDate()
        };
        await this.saveWorkingDocumentsIndex(workingDocumentsIndex);

        this.workingDocuments[workingDocument.workingDocumentId] = workingDocument;
    }

    async getWorkingDocument(workingDocumentId: string, throwIfNotFound: boolean = true): Promise<IOfflineWorkingDocument> {
        // Look in memory cache first
        let workingDocument = this.workingDocuments[workingDocumentId];
        if (workingDocument == null) {
            let workingDocumentsIndex = await this.getWorkingDocumentsIndex();
            // We may have it stored ..
            if (workingDocumentsIndex[workingDocumentId] != null) {
                // We should have the document in storage ..
                let storedData = await this.storage.createNamespace(workingDocumentId).getItem<WorkingDocumentStoredData>(`storedData`);
                if (storedData == null) {
                    // We don't appear to have the document, remove it from the index
                    delete workingDocumentsIndex[workingDocumentId];
                    await this.saveWorkingDocumentsIndex(workingDocumentsIndex);
                } else {
                    // Restore the workingDocument
                    workingDocument = await this.restoreWorkingDocument(storedData);
                    workingDocumentsIndex[workingDocument.workingDocumentId] = {
                        lastAccessedUtc: workingDocument.lastAccessedUtc.toDate(),
                        expiresAtUtc: workingDocument.expiresAtUtc.toDate(),
                        finished: workingDocument.finished
                    }
                    await this.saveWorkingDocumentsIndex(workingDocumentsIndex);

                    this.workingDocuments[workingDocument.workingDocumentId] = workingDocument;
                }
            }
        }
        if (workingDocument == null && throwIfNotFound) {
            throw new Error(`WorkingDocument not found`);
        }
        return workingDocument;
    }

    async hasChecklist(publishedResourceId: IPublishedResourceCompoundId): Promise<boolean> {
        return await this.offlineChecklistCache.getChecklist(publishedResourceId) != null;
    }

    async beginChecklist(compoundId: IPublishedResourceCompoundId, revieweeId: string, args: any): Promise<IBeginChecklistResult> {
        let checklist = await this.offlineChecklistCache.getChecklist(compoundId);
        if (checklist == null) {
            return null;
        }

        let workingDocument = await this.createWorkingDocument(checklist, revieweeId, args);
        return this.continueChecklist(workingDocument.workingDocumentId);
    }

    async saveChecklist(workingDocumentId: string, answerModel: any): Promise<ISaveChecklistResult> {
        let workingDocument = await this.getWorkingDocument(workingDocumentId);
        let answerConverter = this.createOfflineAnswerConverter(workingDocument);
        let convertedAnswers = answerConverter.convertAnswerModel(answerModel);
        return workingDocument.programContext.receiveAnswers(convertedAnswers).then(() => {
            return this.saveWorkingDocument(workingDocument);
        });
    }

    private finishParameters(workingDocument: IOfflineWorkingDocument): IWorkingDocumentFinishParameters {
        // We send the PresentedQuestions as a seperate array below, the Answers at the moment contain a reference to their original
        // question definition, but we dont want to send these definitions in full, we only need each question id & name to
        // be able to link to the correct PresentedQuestion
        let answers: any = workingDocument.answersContext.getAllAnswers().map(answers => answers.map(answer => {
            return answer.finishParameter();
        }).filter(p => p != null))
        return {
            workingDocumentId: workingDocument.workingDocumentId,
            dateStartedUtc: workingDocument.dateStartedUtc.format(),
            dateCompletedUtc: moment.utc().format(),
            publishedGroupResourceId: workingDocument.checklist.compoundId.id || 0,
            answers: answers
        };
    }

    async nextChecklist(workingDocumentId: string, answerModel: any, useOfflineQueue: boolean): Promise<INextChecklistResult> {
        let workingDocument = await this.getWorkingDocument(workingDocumentId);
        let answerConverter = this.createOfflineAnswerConverter(workingDocument);
        let convertedAnswers = answerConverter.convertAnswerModel(answerModel);
        return workingDocument.programContext.receiveAnswers(convertedAnswers).then(async valid => {
            // Note, if we are _not valid_ then it means we are not advancing to the next page and we pass last set of answers to .continueChecklist
            // so it can re-render the invalid page with these answers
            return this.continueChecklist(workingDocumentId, !valid ? convertedAnswers : []).then(async r => {
                if (r.State === 'Finished') {
                    this.offlineChecklistExecutionQueue.finish(this.finishParameters(workingDocument));
                }
                return r;
            });

        });
    }

    async prevChecklist(workingDocumentId: string): Promise<IPrevChecklistResult> {
        let workingDocument = await this.getWorkingDocument(workingDocumentId);
        let lastAnswers = workingDocument.answersContext.getLastAnswers();
        workingDocument.programContext.previous();
        return this.continueChecklist(workingDocumentId, lastAnswers);
    }

    getCurrentContext(throwIfNotFound: boolean = true): IWorkingDocumentContext {
        if (this.currentWorkingDocumentContext == null && throwIfNotFound) {
            throw new Error(`WorkingDocumentContext is null`);
        }
        return this.currentWorkingDocumentContext as IWorkingDocumentContext;
    }

    async continueChecklist(workingDocumentId: string, lastAnswers?: ILingoAnswer[]): Promise<IContinueChecklistResult> {
        let workingDocument = await this.getWorkingDocument(workingDocumentId);
        this.currentWorkingDocumentContext = workingDocument.workingDocumentContext;

        return workingDocument.lingoJsModule.exports.default.call(workingDocument.programContext, workingDocument.workingDocumentContext).then(async data => {
            workingDocument.lastAccessedUtc = moment().utc();

            let result: IContinueChecklistResult = null;
            if (data.presentArgs) {
                let presenter = this.createOfflineQuestionRenderer(workingDocument);
                let presented = presenter.makePresented(data.presentArgs.presentable, lastAnswers || []);

                result = {
                    WorkingDocumentId: workingDocumentId,
                    State: 'Presenting',
                    CanPrevious: data.presentArgs.canPrevious,
                    IsValid: !workingDocument.programContext.invalidReasons.any(),
                    Presented: presented
                };
            } else {
                workingDocument.finished = true;
                result = {
                    WorkingDocumentId: workingDocumentId,
                    State: 'Finished',
                    CanPrevious: false,
                    IsValid: true
                };
            }

            await this.saveWorkingDocument(workingDocument);

            return result;
        });
    }

    private createFacilityFactory(context: IRuntimePlayerContext): any {
        return new Proxy({}, {
            get: (target: any, name: FacilityType) => {
                const factory = this.facilityFactory[name];
                if (factory == null) {
                    return target[name];
                }
                const cls = factory();
                return (definition: any) => {
                    let f = this.$injector.invoke(cls, null, {
                        context, definition
                    })
                    return f;
                }
            }
        });
    }

    private createRequireFactory(context: IRuntimePlayerContext): ((request: string) => any) {
        return (request: string) => {
            // This could be any of 
            //
            // 1. require('<library>')
            //    This is a request for a library Js module, (eg moment)
            //
            // 2. require('./<helper>')
            //    This is a request for a helper Js module (./ is relative to current)
            //
            // 3a. require('checklist/<other-checklist>/<helper>')
            // 3b. require('checklist/<publishing-group>/<other-checklist>/<helper>')
            //    This is a request for another checklists helper Js module ('checklist/' is special cased)

            const isOtherChecklist = request.toLowerCase().startsWith('checklist/');
            const isLocalHelper = request.startsWith('./');
            if (!isOtherChecklist && !isLocalHelper) {
                // 1. requesting a library Js module 
                // if its "lingo-api" then we special case that out ..
                switch (request.toLowerCase()) {
                    case `lingo-api`:
                        if (context.lingoApiModule == null) {
                            context.lingoApiModule = {
                                ...lingoApiModule,
                                Facility: this.createFacilityFactory(context)
                            }
                        }
                        return context.lingoApiModule;
                }

                // Fall through to Node style require ..
                const result = requireMap[request];
                if (result == null) {
                    throw new Error(`Required library not found '${request}'`);
                }
                return result;
            }

            // 2. or 3. either way we are requesting for a Helper module
            const split = request.split('/');
            const helperName = split.pop() as string;
            let possibleChecklist = context.checklists.find(c => c.resourceNodeId === context.checklistId);

            if (isOtherChecklist) {
                if (split.length < 2 || split.length > 3) {
                    throw new Error(`Invalid checklist require format, should be either 'checklist/<other-checklist>/<helper>' or 'checklist/<publishing-group>/<other-checklist>/<helper>'`);
                }
                const checklistName = split.pop() as string;
                const isExplicitPublishingGroup = split.length === 2;
                const groupName = isExplicitPublishingGroup ? split.pop() : possibleChecklist?.groupName;

                possibleChecklist = context.checklists.find(c => c.groupName === groupName && c.name == checklistName);
                if (possibleChecklist == null) {
                    // did not find the checklist
                    // if we did not specify an explicit <publishing-group> then we can look through all groups for the checklist
                    if (!isExplicitPublishingGroup) {
                        possibleChecklist = context.checklists.find(c => c.name == checklistName);
                    }
                }
            }

            if (possibleChecklist == null) {
                throw new Error(`Required checklist '${request}' is not found`);
            }
            const checklist = possibleChecklist as IOfflineChecklist;

            let alreadyRequired = context.requiredChecklists.find(rc => rc.groupName === checklist.groupName && rc.name === checklist.name);
            if (alreadyRequired != null) {
                return alreadyRequired.exports[helperName];
            }

            let imports = this.helperImports(context);
            let exports = Object.create(null);

            checklist.helpers.map(h => {
                const helper = this.javascriptModuleCache.createOrUpdate(`${checklist.groupName}/${checklist?.name}/${h.name}`, checklist.tracking, h.code, imports);
                exports[h.name] = helper.module.exports;
            })

            context.requiredChecklists.push({
                groupName: checklist.groupName,
                name: checklist.name,
                checklist: checklist,
                exports
            });

            return exports[helperName];
        }
    }

    private static makeIdFactory(baseId: string | null) {
        let nextId = 1;
        if (baseId == null) {
            baseId = uuid.v4();
        }
        const knownId = baseId as string;
        return function makeId(offset?: number | string): string {
            if (typeof offset === 'string') {
                return uuid.v5(offset, knownId);
            } else {
                if (offset == null) {
                    offset = nextId++;
                }
                return makeGuidWithOffset(knownId, offset);
            }
        }
    }

    private static makeConstFactory(consts: any[], jsonTree: JsonTree) {
        return function Const(o: any): any {
            if (consts.indexOf(o) === -1 && jsonTree.options.externs.indexOf(o) === -1) {
                consts.push(o);
                jsonTree.options.externs.push(o);            // Make it extern in JsonTree
            }
            return o;
        }
    }

    private scriptImports(checklistContext: IRuntimePlayerContext, consts: any[], jsonTree: JsonTree): any {
        return {
            require: this.createRequireFactory(checklistContext).bind(this),
            makeId: OfflineChecklistPlayer.makeIdFactory(checklistContext.checklistId),
            Const: OfflineChecklistPlayer.makeConstFactory(consts, jsonTree),
        }
    }

    private helperImports(checklistContext: IRuntimePlayerContext): any {
        return {
            require: this.createRequireFactory(checklistContext).bind(this),
            makeId: OfflineChecklistPlayer.makeIdFactory(checklistContext.checklistId)
        }
    }

    createInterpolateContext(lingoContext: LingoContext, ...exports: any[]) {
        return new Proxy({}, {
            get(target: any, name: string) {
                let v = lingoContext.programContext.locals[name];
                if (v !== undefined) {
                    return v;
                }
                v = lingoContext.programContext.consts[name];
                if (v !== undefined) {
                    return v;
                }
                v = lingoContext.lingoSyntax[name];
                if (v !== undefined) {
                    return v;
                }
                for (var e in exports) {
                    v = exports[e][name];
                    if (v !== undefined) {
                        return v;
                    }
                }
                return v;
            }
        })
    }

    async createWorkingDocument(checklist: IOfflineChecklist, revieweeId: string, args: any): Promise<IOfflineWorkingDocument> {
        // Get all offline checklists now, incase we need them in a "requireChecklist" call
        let workingDocumentId = generateCombGuid();

        let workingDocumentContext: IWorkingDocumentContext = {
            checklistId: checklist.resourceNodeId,
            workingDocumentId,
            revieweeId,
            reviewerId: await (await this.authSvc.getUser()).userId,
            args
        }

        let checklistContext: IRuntimePlayerContext = {
            workingDocumentId: workingDocumentId,
            checklistId: checklist.resourceNodeId,
            checklists: await this.offlineChecklistCache.all(),
            requiredChecklists: [],
        }

        let imports = this.helperImports(checklistContext);
        let helpersExports = await Promise.all(checklist.helpers.map(async h => {
            const helper = this.javascriptModuleCache.createOrUpdate(`${checklist.groupName}/${checklist.name}/${h.name}`, checklist.tracking, h.code, imports);
            await this.prepare(helper.module.exports, `pre-run`);
            return helper.module.exports;
        }));

        let context = createContext();
        let workingDocumentFreezer = new WorkingDocumentFreezer();
        context.runtimeContext.register(workingDocumentFreezer);

        let jsonTree = new JsonTree({
            externs: await buildExterns(this, imports, ...helpersExports)
        });
        context.runtimeContext.jsonTree = jsonTree;
        let consts: any[] = [];
        let lingoJsModule = this.javascriptModuleCache.createOrUpdate(`${checklist.groupName}/${checklist.name}.lingo`, checklist.tracking, checklist.lingo?.code ?? ``,
            this.scriptImports(checklistContext, consts, jsonTree),
            context.lingoSyntax
        ).module;

        let workingDocument: IOfflineWorkingDocument = {
            workingDocumentId: workingDocumentId,
            answersContext: context.answersContext,
            checklist: checklist,
            checklists: checklistContext.requiredChecklists.map(rc => rc.checklist),            // Only keep the checklists that were actually required
            dateStartedUtc: moment.utc(),
            lingoJsModule: lingoJsModule,
            lingoSyntax: context.lingoSyntax,
            programContext: context.programContext,
            runtimeContext: context.runtimeContext,
            interpolateContext: this.createInterpolateContext(context, ...helpersExports),
            freezer: workingDocumentFreezer,
            finished: false,
            lastAccessedUtc: moment().utc(),
            expiresAtUtc: moment().add(WorkingDocumentExpirySettings.defaultExpireAfter).utc(),
            consts: consts,
            workingDocumentContext
        }

        // Don't save it here, it will be saved when continueChecklist is called 
        await this.saveWorkingDocument(workingDocument);

        return workingDocument;
    }
}

ngmodule.factory(`offlineChecklistPlayer`, OfflineChecklistPlayer);