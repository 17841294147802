'use strict';

import './../../directives/vm-checkbox-list';
import './../../directives/vm-radio-list';
import './../../directives/vm-dropdown-select';

import './index.scss';
import angular from 'angular';
import questionDirectiveBase from '../question-directive-base';
import questionsModule from '../ngmodule';

questionsModule.directive('questionMultiChoice', ["bworkflowApi", "$timeout", "languageTranslate", "_", function (bworkflowApi, $timeout, languageTranslate, _) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.presented.controlShowsValidationErrors = true;
      scope.presented.controlShowsPrompt = true;

      scope.answer = scope.presented.Answer;
      if (scope.answer.Selected == null) {
        scope.answer.Selected = {};
      }

      scope.selected = [];
      angular.forEach(scope.presented.Choices, function (choice) {
        if (scope.presented.AllowMultiSelect) {
          if (scope.answer.Selected[choice.Id]) {
            scope.selected.push(choice);
          }
        }
        else {
          if (choice.Id == scope.answer.Selected) {
            scope.selected.push(choice);
          }
        }
      });

      switch (scope.presented.DisplayFormat) {
        case 'List_Horizontal':
        case 'List':
          scope.inputCtrlType = scope.presented.AllowMultiSelect ? 'checkbox' : 'radio';
          break;
        case 'Compact':
          scope.inputCtrlType = scope.presented.AllowMultiSelect ? 'compact_multiple' : 'compact_single';
          if (scope.presented.AllowMultiSelect) {
            scope.compact_multiple = {
              answer: _.chain(scope.answer.Selected).
                toPairs().
                filter(i => !!i[1]).
                map(i => i[0]).
                value()
            };


            // compact_multiple stores its answers in an array, however we need an object with key: true values
            scope.$watchCollection('compact_multiple.answer', (newValue, oldValue) => {
              scope.answer.Selected = _.chain(newValue).
                map(i => [i, true]).
                fromPairs().
                value();
            });
          }

          break;
      }

      scope.buttonLayout = scope.presented.DisplayFormat == 'List_Horizontal' ? 'row' : 'column';

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return scope.answer.Selected;
      };

      scope.limitSelection = function (max) {
        scope.$watchCollection('answer.Selected', function () {
          if (angular.isDefined(scope.answer) && angular.isDefined(scope.answer.Selected)) {
            var count = Object.count(scope.answer.Selected, true);

            angular.forEach(scope.presented.Choices, function (c) {
              c.disabled = !scope.answer.Selected[c.Id] && count >= max;
            });
          }
        });
      };

      scope.vmControlChanged = function ($event) {
        if (scope.presented.AllowMultiSelect) {
          scope.answer.Selected = {};

          angular.forEach($event.selections, function (s) {
            scope.answer.Selected[s.Id] = true;
          });
        }
        else {
          if ($event.selections.length == 0 || $event.selections[0] == null) {
            scope.answer.Selected = null;
          }
          else {
            scope.answer.Selected = $event.selections[0].Id;
          }
        }

        scope.validate('single');
      };

      scope.mandatoryvalidator = function (quesiton, value, ruleData, stage) {
        var result = {
          passed: false,
          message: ruleData.message
        };

        if (scope.presented.AllowMultiSelect) {
          angular.forEach(value, function (option) {
            if (option == true) {
              result.passed = true;
              result.message = '';
            }
          });
        }
        else {
          result.passed = value != null && !angular.equals({}, value);
        }

        return result;
      };
    }
  });

}]);