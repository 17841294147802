import { IHasId, IHasName, OData, PrepareType, IHasTracking } from 'lingo-api';
import { ODataOfflineCache, ODataOfflineType } from '../../../odata-offline-cache';
import { OfflineCache } from "../../../offline-cache";
import { IExternBuilder } from "../externs-builder";


export abstract class ReadOnlyODataFacility<TId extends number | string, TRecord extends IHasId<TId> & IHasName, TODataRecord extends IHasTracking> implements IExternBuilder {
	private offlineCache?: ODataOfflineCache<TODataRecord>;

	static $inject = [
		`webServiceUrl`,
		`$http`];
	constructor(
		protected offlineFeed: ODataOfflineType,
		private options: {
			uniqueId: (record: TODataRecord) => string
		}
	) {
	}

	abstract mapRecord(odataRecord: TODataRecord): TRecord;

	buildExterns(externs: any[], iterate: (o: any) => any): void {
		// This will resolve by "externs-builder", note we do not iterate this result as the getAll() promise could return a different number of records over time
		// which will change the "externs" array we are building and break workingdocument save/restore
		externs.push(this.getAll());
	}

	getOfflineCache(): ODataOfflineCache<TODataRecord> {
		if (this.offlineCache == null) {
			this.offlineCache = OfflineCache.get(this.offlineFeed) || new ODataOfflineCache<TODataRecord>(
				this.offlineFeed,
				{
					uniqueId: this.options.uniqueId
				}
			)
		}
		return this.offlineCache as ODataOfflineCache<TODataRecord>;
	}

	async prepare(prepareType: PrepareType): Promise<any> {
		// Always prepare and get the cache
		return this.getOfflineCache().get();
	}

	async get(id: TId): Promise<TRecord | undefined> {
		const all = await this.getAll();
		return all?.find(r => r.id === id);
	}

	async getByName(name: string): Promise<TRecord | undefined> {
		const all = await this.getAll();
		return all?.find(r => r.name === name);
	}

	async getAll(): Promise<TRecord[] | undefined> {
		let odataRecords = await this.getOfflineCache().get();
		return odataRecords.map(this.mapRecord);
	}
}
