'use strict';

import app from './ngmodule';
import angular from 'angular';
import base64 from 'base64-arraybuffer';

class BlobItem {
  constructor(blob) {
    this.url = URL.createObjectURL(blob);
    this.ref = 0;
  }

  addRef() {
    this.ref++;
  }

  release() {
    if (--this.ref === 0) {
      URL.revokeObjectURL(this.url);
      delete this.url;
    }
  }
}


app.factory('blobCache', function () {
  let cache = Object.create(null);

  return {
    get: function (url) {
      return Promise.resolve(cache[url]);
    },
    getOrCreate: function (url, response) {
      let item = cache[url];
      if (angular.isUndefined(item)) {
        let b64 = base64.decode(response.data); // This is assumed to be Base64 encoded
        let blob = new Blob([b64], { type: response.headers()['content-type'] });
        item = new BlobItem(blob);
        cache[url] = item;
      }
      return Promise.resolve(item);
    }
  };

});