'use strict';
import app from '../ngmodule';
import angular from 'angular';
import { ITypeAheadDataSource, ITypeAheadSearchCallback, ITypeAheadCallbackDataItem } from './interfaces';

// An ITypeAheadDataSource that wraps up an odata feed
export class OdataTypeAheadDataSource implements ITypeAheadDataSource {
    protected feed: any;
    protected queryFieldName: string;
    protected displayFieldName: string;

    constructor(feed: any, queryFieldName: string | null, displayFieldName: string | null) {
        this.feed = feed;

        if (queryFieldName != null) {
            this.queryFieldName = queryFieldName; // easy its supplied
        }
        else {
            if (feed.template.parameterdefinitions.length == 0) {
                this.queryFieldName = 'query'; // we just go with a default
            }
            else {
                // ok we are going to assume that the first parameter defined is what we will be searching on
                let def = feed.template.parameterdefinitions[0];

                if (def.externalnames !== undefined && def.externalnames != null && def.externalnames != '') {
                    this.queryFieldName = def.externalnames;
                }
                else {
                    this.queryFieldName = def.internalname;
                }
            }
        }

        if (displayFieldName != null) {
            this.displayFieldName = displayFieldName;
        }
        else {
            if (feed.template.visiblefields == null || feed.template.visiblefields == '') {
                this.displayFieldName = "Name"; // take a whild guess
            }
            else {
                this.displayFieldName = feed.template.visiblefields.split(',')[0].trim();
            }
        }

        this.feed.addAfterLoadHook((feed: any, context: any) => {
            let callback = context.callback as ITypeAheadSearchCallback;

            let values: Array<ITypeAheadCallbackDataItem> = [];

            for (let item of feed.data) {
                values.push({ text: item.alldata[this.displayFieldName], data: item, class: undefined });
            }

            callback(values);
        });
    }

    search(term: string, callback: ITypeAheadSearchCallback): void {
        this.feed.parameters[this.queryFieldName] = term;

        let context = {
            term: term,
            callback: callback
        };

        this.feed.getData(true, context);
    }

    staticItems(callback: ITypeAheadSearchCallback): void {
        // nothing to do here
        callback([]);
    }
}