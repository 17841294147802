import _ from "lodash";
import { Question, Answer } from "./question";
import { IMultiChoiceQuestionDefinition, MultiChoiceDisplayFormat, IMultiChoiceItemDefinition, DesignMultiChoiceQuestion } from 'lingo-api';
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { IMultiChoiceAnswer, IPresentedMultiChoiceQuestion } from "../../presented-types";
import { JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';


export class MultiChoiceQuestion extends Question implements DesignMultiChoiceQuestion {
	allowMultiSelect?: boolean = false;
	displayFormat?: MultiChoiceDisplayFormat = `List`;
	choices?: IMultiChoiceItemDefinition[] = [];
	defaultAnswer?: IMultiChoiceItemDefinition[];

	constructor() {
		super(`multiChoice`);
	}
}

export class MultiChoiceAnswer extends Answer implements ILingoAnswer {
	constructor(public answer: IMultiChoiceAnswer, public presentedQuestion: IPresentedMultiChoiceQuestion) {
		super(answer, presentedQuestion);
	}

	get value() {
		return this.answer.Selected;
	}
	set value(value: { [Id: string]: boolean } | string) {
		this.answer.Selected = value;
	}

	countSelected(): number {
		if (this.presentedQuestion.definition.allowMultiSelect) {
			return _.values(this.answer.Selected as any).filter(c => !!c).length;
		} else {
			return this.answer.Selected ? 1 : 0;
		}
	}

	isSelected(value: string) {
		const choices = (this.presentedQuestion.definition.choices as IMultiChoiceItemDefinition[]);
		const choice = choices.find(c => c.value === value);
		if (choice == null) {
			return false;
		}
		if (this.presentedQuestion.definition.allowMultiSelect) {
			let selected: { [id: string]: boolean } = this.answer.Selected as { [id: string]: boolean };
			return selected[choice.id];
		} else {
			return this.answer.Selected == choice.id;
		}
	}

	toString(): string | null {
		const choices = (this.presentedQuestion.definition.choices as IMultiChoiceItemDefinition[]);
		if (this.presentedQuestion.definition.allowMultiSelect) {
			const selected: { [id: string]: boolean } = this.answer.Selected as { [id: string]: boolean };
			const selectedChoices = choices.filter(c => selected[c.id]);
			return selectedChoices.map(c => c.display).join(',');
		} else {
			const selected = this.answer.Selected as string;
			const selectedChoice = choices.find(c => c.id === selected);
			return selectedChoice?.display ?? null;
		}
	}
}

JsonTreeTranslators.register({
	ctr: MultiChoiceQuestion,
	create: () => new MultiChoiceQuestion()
}, {
	ctr: MultiChoiceAnswer,
	flatten(o: MultiChoiceAnswer) {
		return {
			answer: o.answer,
			presentedQuestion: o.presentedQuestion
		}
	},
	fatten(o: any, fatten: Convert, store: Convert) {
		return store(new MultiChoiceAnswer(fatten(o.answer), fatten(o.presentedQuestion)));
	}
});

