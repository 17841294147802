import { WebServiceUrl, IWebServiceUrl } from '../common/web-service-url';
import ngModule from '../vmplayer/ngmodule';

export interface IPublicChecklist {
	Id: string;
	Name: string;
	Description: string;
	PictureId: string;
	Type: string;
	PublishingGroupId: number;
	ResourceId: number;
	TicketId: string;
}

export interface IPublicChecklistService {
	getPublicChecklists(resourceGroupName: string): ng.IPromise<IPublicChecklist[]>;
}

class PublicChecklistService implements IPublicChecklistService {
	static $inject = ['webServiceUrl', '$http'];

	constructor(
		private webServiceUrl: IWebServiceUrl,
		private $http: ng.IHttpService
	) {

	}

	getPublicChecklists(resourceGroupName: string): ng.IPromise<IPublicChecklist[]> {
		return this.$http.get<IPublicChecklist[]>(`${this.webServiceUrl.api('Published/PublicChecklists')}/${resourceGroupName}`).then(response => response.data);
	}
}

ngModule.factory('PublicChecklistSvc', PublicChecklistService);