
'use strict';

import app from '../../ngmodule';
import './style.scss';

app.directive('questionTasklistFolder', ['appUpdateMonitor',
    function (appUpdateMonitor) {
        return {
            template: require('./template.html').default,
            restrict: 'E',
            link: function (scope, element, attrs) {
                appUpdateMonitor.addPauser(scope); // Pause updates whilst our scope is alive
            }
        };

    }]);