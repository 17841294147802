'use strict';

import { IPersistantStorage } from '../../../common/persistant-storage';
import { IWebServiceUrl } from "../../../common/web-service-url";
import oidc from 'oidc-client/index.js';
import * as router from '@uirouter/angularjs';
import ngModule from '../../../vmplayer/ngmodule';
import { OAuthServiceBase, OidcStorageAdapter } from './oauth-svc-base';
import { AsyncToQ } from '../../async-to-q';
import { debugFunc } from '../../../utils/debug-func';
import { UIRouter, UrlParts } from '@uirouter/angularjs';
import { sleep } from '../../../utils/sleep';

export class DesktopOAuthService extends OAuthServiceBase {
	static $inject = [
		`$q`,
		`$http`,
		`$rootScope`,
		`webServiceUrl`,
		`$state`,
		`persistantStorage`,
		`$urlService`,
		`$location`,
		`async-to-q`];
	constructor(
		$q: ng.IQService,
		$http: ng.IHttpService,
		$rootScope: ng.IRootScopeService,
		webServiceUrl: IWebServiceUrl,
		$state: any,
		persistantStorage: IPersistantStorage,
		$urlService: router.UrlService,
		$location: ng.ILocationService,
		asyncToQ: AsyncToQ
	) {
		super($q, $http, $rootScope, webServiceUrl, $state, persistantStorage, $urlService, $location, asyncToQ, debugFunc(`DesktopOAuthService`));
	}

	override async createUserManager(): Promise<oidc.UserManager> {
		let mgr = new oidc.UserManager({
			authority: `/auth`,
			client_id: `vm.spa`,
			client_secret: 'Sample secret',
			redirect_uri: `${this.webServiceUrl.tenantUrl}/spa/signin-oidc`,
			response_type: `code`,
			scope: `openid profile vm.api vm.odata vm.media offline_access`,
			post_logout_redirect_uri: `${this.webServiceUrl.tenantUrl}/spa/signout-oidc`,
			userStore: new OidcStorageAdapter(this.storage.createNamespace(`oidc`))
		});

		// Hook the URL to detect signin and signout redirects
		this.$urlService.rules.when('/signin-oidc', () => {
			this.signinCallback(this.$location.absUrl()).then(() => {
				this.$location.url(this.webServiceUrl.site(''));
			})
		});
		this.$urlService.rules.when('/signout-oidc', () => {
			this.signoutCallback(this.$location.absUrl()).then(() => {
				this.$location.url(this.webServiceUrl.site(''));
			})
		});

		return mgr;
	}

	override userManagerSignin(): Promise<void> {
		return this.mgr.then(mgr => mgr.signinRedirect()).then(() => sleep(2000));	// 2s sleep is enough time for browser to finish redirect
	}

	override userManagerSignout(): Promise<void> {
		return this.mgr.then(mgr => mgr.signoutRedirect());
	}

	override displayOAuthError(): Promise<void> {
		return new Promise((resolve, reject) => {
			window.alert('There was a possible network issue authenticating, press Ok to try again');
			sleep(5000).then(resolve);
		})
	}
}

export function useDesktopOAuthService() {
	ngModule.factory(`authSvc`, DesktopOAuthService);
}
