'use strict';

import angular from 'angular';
import 'angular-resource';
import 'angular-translate';
import requireMap from '../checklist-player/offline-checklist-player/require-map';

var languageTranslation = angular.module('languageTranslation', ['ngResource', 'pascalprecht.translate']);

export default languageTranslation;

/*
                                    languageTranslation.factory('makeInterpolateReady', function () {
                                        var re = /\[\[([^#][^\]]+)\]\]/g;        // Find any [[id]] (but not [[#id]])
                                        var subst = '{{$1}}';                   // replace with {{id}}
                                        return function (text) {
                                            return text.replace(re, subst);
                                        };
                                    });
                                    */

languageTranslation.factory('languageTranslate.Missing', ['$q', '$http', '$translate', 'languageTranslate.Loader', function ($q, $http, $translate, languageTranslateLoader) {
  var rooturl = window.razordata.apiprefix + 'LanguageTranslate/';
  var registered = Object.create(null); // Formally registered translationId's with their English text
  var missing = Object.create(null);

  var factory = function ngTranslateMissingTranslation(translationId) {
    // We are only interested in missing English translationid since English is the master language
    if ($translate.use() != 'en')
      return translationId;

    if (translationId in registered)
      return registered[translationId];

    if (translationId in missing)
      return translationId;

    missing[translationId] = true;
    $http({ url: rooturl + "MissingTranslation", method: "POST", params: { translationid: translationId, nativetext: translationId } });

    return translationId;
  };

  factory.register = function (elm) {
    var translationId = elm.nodeName.toLowerCase();
    if (translationId in registered) // Already done
      return;

    // Remember we have registered this translation
    var english = angular.element(elm).html();
    registered[translationId] = english;

    var englishLoader = languageTranslateLoader({ key: 'en' });
    englishLoader.then(function (englishTable) {
      // If the English text is missing or has changed (developer changed it) then we need to inform server of the change
      if (!(translationId in englishTable) || english != englishTable[translationId]) {
        $http({ url: rooturl + "MissingTranslation", method: "POST", params: { translationid: translationId, nativetext: english } });
      }
    });
  };

  return factory;
}]);

languageTranslation.factory('languageTranslate.Loader', ['$q', '$http', '$window', function ($q, $http, $window) {
  var rooturl = $window.razordata.apiprefix + 'LanguageTranslate/';
  var cache = Object.create(null);

  var fn = function (options) {
    if (options.key in cache) {
      if (!angular.isFunction(cache[options.key].resolve)) {
        var d = $q.defer();
        d.resolve(cache[options.key]);
        return d.promise;
      } else

        return cache[options.key].promise; // a previous called has not resolved yet, return previous promise
    }

    // Not called yet, retrieve translations and cache the result
    var d = $q.defer();
    cache[options.key] = d;
    /* TODO
                            $http({ url: rooturl + "NGTranslations", method: "GET", params: { culturename: options.key } })
                                .then(function (data) {
                                    cache[options.key] = data;
                                    d.resolve(data);
                                });
                            */
    d.resolve({}); // TODO remove 
    return d.promise;
  };

  return fn;
}]);


languageTranslation.provider('languageTranslate', [function () {
  var rooturl = window.razordata.apiprefix;
  this.$get = ['$translate', '$q', '$http', '$timeout', function ($translate, $q, $http, $timeout) {
    var svc = {};

    var _culturename;

    var _culturenamePromise = $http({
      url: `${rooturl}User/GetDefaultLanguage`
    }).
      then(function (response) {
        _culturename = response.data;
        return _culturename;
      });

    // Temporary function which is overriden once the 'languageTranslate' service is created below
    svc.getCulturename = function () {
      return _culturenamePromise;
    };


    svc.currentLanguage = { culturename: 'en', languagename: 'English', nativename: 'English' };

    // Abrieviated access to selected language code
    svc.code = svc.currentLanguage.culturename;

    // Dictionary of available languages keyed by culturename
    svc.languages = { 'en': svc.currentLanguage };

    var $getLanguages = null;
    svc.getLanguages = function () {
      if ($getLanguages == null) {
        $getLanguages = $http({ url: rooturl + "LanguageTranslate/AvailableLanguages", method: "GET" }).
          then(function (response) {
            //                    svc.languages = {};
            response.data.forEach(function (l) {
              svc.languages[l.culturename] = l;
            });

            return response.data;
          });
      }
      return $getLanguages;
    };

    svc.browserSelectLanguage = function (culturename) {
      if (culturename in svc.languages) {
        svc.currentLanguage = svc.languages[culturename];
        svc.code = svc.currentLanguage.culturename;

        $translate.use(culturename);

        if (culturename !== _culturename) {
          $http({
            method: 'POST',
            url: `${rooturl}User/SetDefaultLanguage`,
            data: {
              code: culturename
            }
          }).

            then(function (response) {
              _culturename = response.data;
            });
        }
      }
    };

    // Get available languages, and then sync with the current chosen language
    svc.getLanguages().then(function () {
      svc.getCulturename().then(function (culturename) {
        svc.browserSelectLanguage(culturename);
      });
    });


    // Return the model's translated property value
    // Assumptions:
    // model.prop exists
    // model.prop'translated' is an array of translations of the form { 'culturename':..., prop:translation for culture }
    svc.localised = function (model, prop) {
      // Get the translation table
      if (!model)
        return '';

      var translations = model.translated;
      if (!translations)
        return model[prop]; // No translation table, return default prop value

      // Find current translation and return its text (if found)
      var translatedText = translations.find(function (t) { return t.culturename == svc.currentLanguage.culturename; });
      var text = translatedText ? translatedText[prop] : model[prop];
      return text || model[prop];
    };

    return svc;
  }];
}]);

languageTranslation.controller('languageTranslateCtrl', ['$scope', 'languageTranslate', function ($scope, languageTranslate) {
  $scope.getCurrentLanguage = function () {
    return languageTranslate.currentLanguage;
  };

  $scope.browserSelectLanguage = function (culturename) {
    languageTranslate.browserSelectLanguage(culturename);
  };

  $scope.languages = {};

  languageTranslate.getLanguages().then(function (data) {
    $scope.languages = data;
  });

}]);

languageTranslation.config(['$translateProvider', 'languageTranslateProvider', function ($translateProvider, languageTranslateProvider) {
  $translateProvider.preferredLanguage('en');
  $translateProvider.usePostCompiling(true);

  $translateProvider.useLoader('languageTranslate.Loader');
  $translateProvider.useMissingTranslationHandler('languageTranslate.Missing');
}]);

languageTranslation.run(['$translate', function ($translate) {
  // A little nasty, but place $translate at window scope so none angular code has access to translations
  window.$ng_translate = $translate;
  window.$confirm = window.confirm;
  window.confirm = function (message) {
    return window.$confirm($translate.instant(message));
  };
}]);

languageTranslation.directive('registerTranslations', ['languageTranslate.Missing', function (languageTranslateMissing) {
  return {
    restrict: 'E',
    compile: function (tElem, tAttrs) {
      var children = tElem.children();
      for (var i = 0, ii = children.length; i < ii; i++) {
        if (children[i].nodeType == 1) {
          languageTranslateMissing.register(children[i]);
        }
      }

      tElem.remove();
    }
  };

}]);

// Expect
// <div template-replace>
//   <span>transcluded html [[#myarg1]] and [[#myarg2]]</span>
//   <myarg1>transclude this</myarg1>
//   <myarg2>and this also</myarg2>
// </div>
languageTranslation.directive('bwTemplateReplace', ['$compile', '$document', '$timeout',
  function ($compile, $document, $timeout) {
    return {
      restrict: 'AC',
      transclude: true,
      link: function (scope, iElement, iAttrs, controller, transclude) {
        transclude(scope, function (clone, $scope) {
          $timeout(function () {
            // Our template is the first real child element (nodeType 1)
            var template = null;
            for (var i = 0, ii = clone.length; i < ii; i++) {
              if (clone[i].nodeType == 1) {
                template = angular.element(clone[i]);
                break;
              }
            }

            // Remember the template's text, then transclude it and empty its contents
            var html = angular.copy(template.text());
            iElement.append(template); // Transcluding keeps external directives intact
            template.empty(); // We can populate its inards from scratch

            // Split the html into pieces seperated by [[#tagname]] parts
            if (html) {
              var htmlLen = html.length;

              var textStart = 0;
              while (textStart < htmlLen) {
                var tagName = null,
                  tagEnd = htmlLen,
                  textEnd = htmlLen;

                var tagStart = html.indexOf("[[#", textStart);
                if (tagStart >= 0) {
                  tagEnd = html.indexOf("]]", tagStart);
                  if (tagEnd >= 0) {
                    tagName = html.substr(tagStart + 3, tagEnd - tagStart - 3);
                    tagEnd += 2;
                    textEnd = tagStart;
                  }
                }

                // Text parts have to be created, $compiled and appended
                var text = html.substr(textStart, textEnd - textStart);
                if (text.length) {
                  var textNode = $document[0].createTextNode(text);
                  template.append($compile(textNode)($scope));
                }

                // Tag parts are located in the clone then transclude appended to keep external directives intact (note each tagNode can only be referenced once)
                if (tagName && tagName.length) {
                  var tagNode = clone.filter(tagName);
                  if (tagNode.length) {
                    template.append(tagNode);
                  }
                }
                textStart = tagEnd;
              }
            }
          }, 0);
        });
      }
    };

  }]);


/* This directive edits a root-objects translated texts, it is designed to edit the following object as an example
{
    type: 'task',
    name: 'Clean floor',
    description: 'Clean floor in detail',
    translated: [
    {
        culturename: 'en',
        name: 'Clean floor',
        description: 'Clean floor in detail'
    }, {
        culturename: 'es-ES',
        name: 'Clean floor (Spanish)',
        description: 'Clean floor in detail (Spanish)'    
    }
    ]
}

To edit the above object's "name" field, the following edit-localised-text element can be used
<edit-localised-text root-object="myobj" root-property="name" translated-object="myobj.translated" translated-property="name"></edit-localised-text>

and the "description"
<edit-localised-text root-object="myobj" root-property="description" translated-object="myobj.translated" translated-property="description"></edit-localised-text>
*/
languageTranslation.directive('editLocalisedText', ['languageTranslate', 'generateCombGuid', function (languageTranslate, generateCombGuid) {
  return {
    templateUrl: require('./edit-localised-text.template.html').default,
    restrict: 'E',
    scope: {
      rootObject: '=',
      rootProperty: '@',
      translatedObject: '=',
      translatedProperty: '@',
      multiline: '=',
      readonly: '=',
      mvcModelName: '@'
    },
    controllerAs: '$ctrl',
    controller: function ($scope) {
      let ctrl = this;
      ctrl.uniqueId = generateCombGuid();

      function reset() {
        if (ctrl.languages == null) return;
        ctrl.translatedProperty = $scope.translatedProperty || $scope.rootProperty || 'value';

        delete ctrl.model;
        delete ctrl.selected;

        ctrl.translatedObject = $scope.translatedObject || $scope.rootObject;
        if (ctrl.translatedObject) {
          ctrl.model = ctrl.translatedObject.reduce((acc, cur) => { acc[cur.culturename] = cur; return acc; }, {});

          let defaultValue = $scope.rootProperty != null ? $scope.rootObject[$scope.rootProperty] : '';
          let missingCultures = ctrl.languages.filter(language => ctrl.translatedObject.find(c => c.culturename == language.culturename) === undefined);
          missingCultures.forEach(language => ctrl.translatedObject.push({ culturename: language.culturename, value: language.culturename == 'en' ? defaultValue : '' }));

          if (ctrl.selectedLanguage != null) {
            ctrl.selected = ctrl.translatedObject.find(c => c.culturename == ctrl.selectedLanguage.culturename);
          }
        }
      }

      languageTranslate.getLanguages().then(languages => {
        ctrl.languages = languages;
        ctrl.selectedLanguage = languages[0];
        reset();
      });

      function onSelectedLanguageChanged(language) {
        if (ctrl.model != null && language != null) {
          ctrl.selected = ctrl.model[language.culturename];
          reset();
        }
      }

      ctrl.onSelectedValueChanged = function () {
        if (ctrl.selected.culturename == 'en' && $scope.rootProperty != null) {
          $scope.rootObject[$scope.rootProperty] = ctrl.selected[ctrl.translatedProperty];
        }
      }

      ctrl.translate = function () {
        if (ctrl.selected != null) {
          ctrl.autoTranslating = true;
          let target = ctrl.selected;
          let englishText = ctrl.translatedObject.find(c => c.culturename == 'en')[ctrl.translatedProperty];
          languageTranslate.autoTranslate(englishText, target.culturename).then(translation => {
            delete ctrl.autoTranslating;
            target[ctrl.translatedProperty] = translation;
          }).catch(err => {
            delete ctrl.autoTranslating;
            alert(err);
          })
        }
      }

      $scope.$watch('rootObject', reset);
      $scope.$watch('translatedObject', reset);
      $scope.$watch('$ctrl.selectedLanguage', onSelectedLanguageChanged);

      reset();
    }
  }
}])
