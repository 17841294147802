
export enum UpdateState {
	unknown,			// Package does not exist
	downloaded,			// Package exists but is not installed
	idle,				// Package is up to date, installed and ready to go
	checking,			// Checking package for update
	downloading,		// Download the package
	verifying,			// Verifying package contents
	updateReady,		// Package is downloaded, verified and ready to be applied
	applying,			// Package is being applied
	applied,			// Package has been applied, restart of package logic is required to reach 'idle'
	error
}

export interface IAppUpdateState {
	state: UpdateState;
	percent: number | null;
	downloadedBytes: number | null;
	error: string | null;
}

export interface IAppUpdateProvider {
	updateState: IAppUpdateState;

	check(): Promise<UpdateState>;
	resetError(): void;
	apply(): Promise<UpdateState>;
}
