import moment from 'moment';
import app from './ngmodule';
import angular from 'angular';

export enum ChainActionStatus {
    none = 1,
    running,
    finished,
    error
}

export interface IVMChainService {
    createChain(actionFn: IChainExecuteAction): IChain;
}

export interface IChainAction {
    data: any;
    status: ChainActionStatus;
    message: string;
}

export interface IChainExecuteActionFinished { (success: boolean, message: string): void };

export interface IChainExecuteAction { (data: any, chain: IChainAction, finishedCallBack: IChainExecuteActionFinished): void };

// Interface for objects that are responsible for running a set of actions in a chain (one after the other)
export interface IChain {
    actions: Array<IChainAction>;

    currentAction: IChainAction | null;
    currentIndex: number;
    paused: boolean;

    readonly finished: boolean;

    start(): void;
    pause(): void;

    addAction(data: any): void;
    addActions(items: Array<any>): void;
}

class vmChain implements IChain {
    public actions: Array<IChainAction>;
    public currentAction: IChainAction | null;
    public currentIndex: number;
    public paused: boolean;

    constructor(protected actionFn: IChainExecuteAction, protected $timeout: ng.ITimeoutService) {
        this.actions = new Array<IChainAction>();
        this.currentAction = null;
        this.currentIndex = -1;

        this.paused = false;
    }

    public get finished(): boolean {
        return this.currentIndex >= this.actions.length;
    }

    protected nextAction(): void {
        this.currentIndex++;

        if (this.currentIndex >= this.actions.length) {
            return;
        }

        this.currentAction = this.actions[this.currentIndex];

        this.currentAction.status = ChainActionStatus.running;

        this.actionFn(this.currentAction.data, this.currentAction, (success: boolean, message: string) => {
            if (this.currentAction == null) {
                return;
            }

            this.currentAction.status = success ? ChainActionStatus.finished : ChainActionStatus.error;
            this.currentAction.message = message;

            if (this.paused == true) {
                return;
            }

            // we do this in a timeout to give the UI time to update etc
            this.$timeout(() => this.nextAction());
        });
    }

    start(): void {
        this.paused = false;
        this.nextAction();
    }

    pause(): void {
        this.paused = true;
    }

    addAction(data: any): void {
        this.actions.push({ data: data, status: ChainActionStatus.none, message: '' });
    }

    addActions(items: Array<any>): void {
        for (let i of items) {
            this.addAction(i);
        }
    }
}

class vmChainService implements IVMChainService {
    static $inject = ['$timeout'];
    constructor(protected $timeout: ng.ITimeoutService) {

    }

    createChain(actionFn: IChainExecuteAction): IChain {
        let chain = new vmChain(actionFn, this.$timeout);

        return chain;
    }
}

app.factory('vmChainService', vmChainService);