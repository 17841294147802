'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionNumber', ['bworkflowApi', 'languageTranslate', '$timeout', function (bworkflowApi, languageTranslate, $timeout) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.answer = scope.presented.Answer;

      scope.presented.controlShowsValidationErrors = true;
      scope.presented.controlShowsPrompt = true;
      scope.keyPadVisible = false;

      // bc-keypad expects a string, otherwise it will blank its model
      scope.answer = scope.answer || {};
      if (scope.answer.Number == null) {
        scope.answer.Number = '';
      }
      if (scope.answer.Number != null) {
        scope.answer.Number = scope.answer.Number.toString();
      }

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return Number(scope.answer.Number);
      };

      scope.changed = function ($event) {
        scope.validate('single');
      };

      scope.toggleKeyPad = function() {
        scope.keyPadVisible = !scope.keyPadVisible;
      }
    }
  });
}]);