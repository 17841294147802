'use strict';

import angular from 'angular';
import { DisposeFunction, Disposer } from '../../../utils/disposer';
import app from '../../ngmodule';

export interface IDialogDelegate {
    filterProbes?: (probes: any[]) => any[];
    close: () => void;
}

class SelectTemperatureProbeDialog {
    private disposer: Disposer = new Disposer();
    public delegate?: IDialogDelegate;

    public probes?: any[];

    static $inject = [
        'temperature-probe-manager',
        '$timeout'
    ];

    constructor(
        private temperatureProbe: any,
        private $timeout: ng.ITimeoutService
    ) {
    }

    $onInit() {
        this.$timeout(() => {
            if (angular.isFunction(this.temperatureProbe.stopScan)) {
                this.disposer.add(new DisposeFunction(this.temperatureProbe.stopScan.bind(this.temperatureProbe)));
            }

            this.probes = this.delegate?.filterProbes?.(this.temperatureProbe.probes) || this.temperatureProbe.probes;

            this.temperatureProbe.startScan();

            this.disposer.add(new DisposeFunction(this.temperatureProbe.subscribe((event: any) => {
                switch (event.name) {
                    case 'button':
                        this.temperatureProbe.activeProbe(event.probe).then(this.delegate?.close);
                        break;

                    case 'removed':
                        this.probes = this.delegate?.filterProbes?.(this.temperatureProbe.probes) || this.temperatureProbe.probes;
                        break;

                    case 'probes':
                        this.probes = this.delegate?.filterProbes?.(event.probes) || event.probes;
                        break;

                    case 'connected':
                        this.temperatureProbe.stopScan();
                        this.delegate?.close();
                        break;

                    case 'disconnected':
                        this.temperatureProbe.startScan();
                        break;
                }
            })));
        });
    }

    isActiveProbe(probe: any) {
        return probe === this.temperatureProbe.probe;
    }

    connect(probe: any) {
        this.temperatureProbe.activeProbe(probe);
    }

    $onDestroy() {
        this.disposer.disposeAll();
    }
}

app.component('selectTemperatureProbeDialog', {
    template: require('./template.html').default,
    bindings: {
        delegate: '<'
    },
    controller: SelectTemperatureProbeDialog
});
