import { IBasicPersistantStorage } from './types';

export class IndexedDbPersistantStorage implements IBasicPersistantStorage {
	private db: Promise<IDBDatabase>;

	constructor(private namespace: string, private objectStore: string, private version: number) {
		const anyWindow = window as any;

		this.db = new Promise((resolve, reject) => {
			// we grab index db and fall back to the shim if none of those come through
			const indexedDB = window.indexedDB || anyWindow.mozIndexedDB || anyWindow.webkitIndexedDB || anyWindow.msIndexedDB || anyWindow.shimIndexedDB;
			const request = indexedDB.open(this.namespace, this.version);

			request.onupgradeneeded = event => {
				const db = request.result;

				// we are going to use one object store for each of the types of queue we store
				// in here. Each object will be pulled out by its type property, which will match the
				// the key value handed into the toLocalStorage and fromLocalStorage functions
				db.createObjectStore(this.objectStore, { keyPath: 'type' });
			}

			request.onerror = event => {
				reject(event);
			}

			request.onsuccess = event => {
				resolve(request.result);
			}
		});
	}

	getItem(key: string, success?: (data: any) => void): void {
		this.db.then(db => {
			const transaction = db.transaction([this.objectStore], "readonly");
			const objectStore = transaction.objectStore(this.objectStore);

			const request = objectStore.get(key);
			request.onerror = event => {
				success?.(null);							// getItem never returns undefined
			}

			request.onsuccess = event => {
				let result = request.result;
				success?.(result?.data ?? null);			// getItem never returns undefined
			}
		})
	}

	setItem(key: string, data: any, success?: () => void, failure?: (err: Error) => void): void {
		this.db.then(db => {
			const transaction = db.transaction([this.objectStore], "readwrite");
			const objectStore = transaction.objectStore(this.objectStore);

			const request = objectStore.put({ type: key, data: data });

			request.onerror = event => {
				failure?.(new Error(request.error?.message));
			}

			request.onsuccess = event => {
				success?.();
			}
		})
	}

	removeItem(key: string, success?: (removed: boolean) => void): void {
		this.db.then(db => {
			const transaction = db.transaction([this.objectStore], "readwrite");
			const objectStore = transaction.objectStore(this.objectStore);

			const request = objectStore.delete(key);

			request.onerror = event => {
				success?.(false);
			}

			request.onsuccess = event => {
				success?.(true);
			}
		})
	}

	clear(callback?: () => void): void {
		this.db.then(db => {
			const transaction = db.transaction([this.objectStore], "readwrite");
			const objectStore = transaction.objectStore(this.objectStore);

			const request = objectStore.clear();

			request.onerror = event => {

			}

			request.onsuccess = event => {
				callback?.();
			}
		})
	}

	getKeys(callback?: (keys: string[]) => void) {
		throw new Error(`Not implemented`);
	}

	createNamespace(namespace: string): IBasicPersistantStorage {
		let fullnameSpace = this.namespace;
		if (fullnameSpace.length != 0) {
			fullnameSpace += ".";
		}
		fullnameSpace += namespace;
		return new IndexedDbPersistantStorage(fullnameSpace, this.objectStore, this.version);
	}
}