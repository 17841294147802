'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';
import {OdataTypeAheadDataSource} from '../../directives/vm-type-ahead/odata-type-ahead-data-source';

questionsModule.directive('questionSelectUser', ['bworkflowApi', 'languageTranslate', function (bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.answer = scope.presented.Answer;

      scope.presented.controlShowsValidationErrors = true;
      scope.presented.controlShowsPrompt = true;

      scope.currentSelection = null;

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return scope.answer.SelectedId;
      };

      scope.updateSelection = function (id, name) {
        scope.answer.SelectedId = id;
        scope.answer.SelectedName = name;
      };

      scope.options = [];
      bworkflowApi.labelUsers(scope.presented.AllowedLabels).then(function (r) {
        scope.options = r;
      });

      // this just does a simple search on the options that we have in memory and returns the results
      scope.noneOdataSearch = {
        search: function(text, callback) {
          var results = [];

          angular.forEach(scope.options, function (option) {
            if (option.Name.toLowerCase().indexOf(text.toLowerCase()) > -1) {
              results.push({ text: option.Name, data: option, class: undefined });
            }
          });

          callback(results);
        }
      }

      scope.odataSearch = {
        search: function(text, callback) {
          scope.odataSource.search(text, callback);
        }
      }

      scope.searchItemSelected = function($event) {
        if($event.item == null)
        {
          scope.updateSelection("00000000-0000-0000-0000-000000000000", null); // an empty Guid get's translated to null on the server
          return;
        }

        if(scope.presented.datasource == null)
        {
          scope.updateSelection($event.item.UserId, $event.item.Name);
        }
        else
        {
          scope.updateSelection($event.item.alldata.UserId, $event.item.alldata.Name);
        }
      }

      scope.init = function() {
        if(scope.presented.datasource == null)
        {
          scope.search = scope.noneOdataSearch;
          return;
        }

        scope.search = scope.odataSearch;

        bworkflowApi.getDataFeed(scope.presented.datasource).then((feed) => {
          feed.allowMultipleAjax = true; // EVS-1404 fix - For searching the user could type quicker than we can search, allowing multiple Ajax means we will always catch the users last search query
          scope.odataSource = new OdataTypeAheadDataSource(feed, null, null);
        });
      }

      scope.init();
    }
  });
}]);