'use strict';

import app from '../ngmodule';

app.config(['$stateProvider',
  function ($stateProvider) {

    $stateProvider.
      state('root.dashboard', {
        url: '/dashboard',
        views: {
          'menu@root': {
            template: require('./menu/template.html').default,
            controller: 'dashboardMenuCtrl'
          }
        }
      }).



      state('root.dashboard.name', {
        url: '/:name',
        views: {
          'content@root': {
            template: require('./content/template.html').default,
            controller: 'dashboardContentCtrl'
          }
        }
      });



  }]);