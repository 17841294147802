'use strict';

import './index.scss';
import '../../directives/vm-validation-error-list';
import questionsModule from '../ngmodule';

//This guy is a wrapper you can use in your question templates
//If you do <div questionWrapper="presented">...</div>, you get the
//prompt, notes, custom styling etc
questionsModule.directive('questionWrapper', ['$sce', function ($sce) {
  return {
    template: require('./template.html').default,
    transclude: true,
    replace: true,
    restrict: 'A',
    scope: {
      presented: '=questionWrapper' //bind to value of this attribute
    },
    link: function (scope, elt, attrs) {
      //Behaviour for the general question template
      //TODO: Link up notes event handler!
      if (typeof attrs.renderPrompt === 'undefined')
        scope.shouldRenderPrompt = true;
      else
        scope.shouldRenderPrompt = attrs.renderPrompt.toLowerCase() == 'true';

      scope.presented.promptAsHtml = $sce.trustAsHtml(scope.presented.Prompt);
      //scope.presented.promptAsHtml = function () { return $sce.trustAsHtml(scope.presented.Prompt); };

      //since angular won't parse the string replace in the style
      $(elt).attr('style', scope.presented.HtmlPresentation.CustomStyling);

      scope.$on('help_add_steps', function (event, args) {
        if (scope.presented.HelpContent != null && scope.presented.Name != null) {
          var step = {
            element: 'div[data-presentable-name="' + scope.presented.Name + '"]',
            intro: scope.presented.HelpContent,
            position: scope.presented.HelpPosition.toLowerCase()
          };


          args.push(step);
        }
      });
    }
  };

}]);