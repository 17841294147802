'use strict';

import './index.scss';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { StateService, TransitionService } from '@uirouter/angularjs';
import { IVMModal, IVMModalManagerService } from '../../services/vm-modal-manager-service';
import { EventEmitter } from 'events';

declare var $: any; // give us a typescript variable to access jquery

class ModalController extends ControllerBase implements IVMModal, IVMRegisteredComponent {
    public name: string;

    protected _title?: string;
    public titleAsString?: string;
    public size?: string;
    public sizeAsString?: string;
    public hasCloseButton: boolean;
    public hasSaveButton: boolean;
    public animation: string;

    public isShow: boolean = false;
    public onClose: (($event:any) => void) | null = null;
    public onSave: (($event:any) => void) | null = null;

    private _unhookBackButton: Function | null = null;
    private _scrollMonitor: ng.IPromise<void> | null = null;

    private _backdropElement: any;
    public events: EventEmitter = new EventEmitter();

    isString(value: string | any): value is string {
        if (value == null) {
            return true;
        }

        return (value as string).substring !== undefined;
    }

    getComponentType(): string {
        return 'Modal';
    }

    get title(): string | undefined {
        return this._title;
    }

    set title(value: string | undefined) {
        if (this.isString(value)) {
            this._title = this.$sce.trustAsHtml(value);
        }
        else {
            this._title = value; // assume its already trusted
        }
    }

    static $inject = ['$element', 'vmRegisteredComponentProvider', 'vmModalManagerService', '$sce', '$timeout', '$scope'];
    constructor(
        protected $element: ng.IRootElementService,
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider,
        protected vmModalManagerService: IVMModalManagerService,
        protected $sce: ng.ISCEService,
        private $timeout: ng.ITimeoutService,
        private $scope: ng.IScope
    ) {
        super();

        this.name = '';
        this.hasCloseButton = true;
        this.hasSaveButton = true;
        this.animation = 'slideRight';
        this._backdropElement = null;
    }

    $onInit(): void {
        if (angular.isDefined(this.name) == false || this.name == '') {
            console.warn('vm modal must be given a name');
            throw 'vm-modal must be given a name';
        }

        this.vmRegisteredComponentProvider.register(this);

        if (angular.isDefined(this.title) == false) {
            this.title = this.$element.attr('title');
        }

        if (angular.isDefined(this.title) == false) {
            this.title = this.titleAsString;
        }

        if (angular.isDefined(this.size) == false) {
            this.size = this.$element.attr('size');
        }

        if (angular.isDefined(this.size) == false) {
            this.size = this.sizeAsString;
        }

        if (angular.isDefined(this.size) == false) {
            this.size = 'normal';
        }

        if (angular.isDefined(this.hasCloseButton) == false) {
            this.hasCloseButton = true;

            let v = this.$element.attr('hasCloseButton');
            if(v)
            {
                this.hasCloseButton = v.toLocaleLowerCase() == 'true';
            }
        }

        if (angular.isDefined(this.hasSaveButton) == false) {
            this.hasSaveButton = true;

            let v = this.$element.attr('hasSaveButton');
            if(v)
            {
                this.hasSaveButton = v.toLocaleLowerCase() == 'true';
            }
        }

        if (angular.isDefined(this.animation) == false) {
            this.animation = 'slideRight';
        }

        // we have to move ourselves to the body element as the backdrop goes over the top of us
        // otherwise. Apparently this is a known issue with Bootstrap modals :(
        $(this.$element).prependTo('body');
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.title && changes.title.currentValue != null) {
            this.title = changes.title.currentValue;
        }

        if (changes.hasCloseButton && changes.hasCloseButton.currentValue != null) {
            this.hasCloseButton = changes.hasCloseButton.currentValue;
        }

        if (changes.hasSaveButton && changes.hasSaveButton.currentValue != null) {
            this.hasSaveButton = changes.hasSaveButton.currentValue;
        }

        if (changes.animation && changes.animation.currentValue != null) {
            this.animation = changes.animation.currentValue;
        }
    }

    $onDestroy(): void {
        // take us off of the body
        $(this.$element).remove();
        this.vmRegisteredComponentProvider.deRegister(this);

        if (this._unhookBackButton) {
            this._unhookBackButton();
            this._unhookBackButton = null;
        }

        this.events.emit('destroyed', this);
    }

    getModalElement(): HTMLElement {
        return this.$element.find("#" + this.name)[0];
    }

    show(): void {
        if(this.isShow)
        {
            return;
        }

        this.isShow = true;
        this.vmModalManagerService.push(this);

        this.$timeout(() => {
            this.getModalElement().classList.add('is-active');
            this.events.emit('shown', this);
            // we're going to notify interested controls that they're in a modal (vm-map control being the main thing here as it has issues with modals)
            // we do this through a message broadcast down the scope stack
            this.$scope.$broadcast('vm-modal.init', { modal: this });
        });
    }

    close(raiseCloseEvent: boolean = true): void {
        this.isShow = false;

        this.$timeout(() => {
            this.getModalElement().classList.remove('is-active');
            this.getModalElement().classList.remove('modal-hidden');
            this.vmModalManagerService.pop(this);

            if(raiseCloseEvent)
            {
                this.onClose?.({ $event: { modal: this }});
            }
        });
    }

    toggle(): void { 
        this.isShow ? this.close() : this.show();
    }

    saveChanges(): void {
        this.onSave?.({ $event: { modal: this }});
    }

    makeInvisible(): void {
        this.getModalElement().classList.add('modal-hidden');

        if (this._backdropElement == null) {
            this._backdropElement = $('body').find(".modal-backdrop:not('.modal-hidden')");
        }

        this._backdropElement?.addClass('modal-hidden');
    }

    makeVisible(): void {
        this.getModalElement().classList.remove('modal-hidden');
        this._backdropElement?.removeClass('modal-hidden');
    }
}

app.component('vmModal', {
    template: require('./template.html').default,
    transclude: {
        'header': '?vmModalHeader',
        'body': 'vmModalBody',
        'footer': '?vmModalFooter'
    },
    bindings: {
        name: '@',
        title: '<',
        titleAsString: '@',
        size: '<',
        sizeAsString: '@',
        hasCloseButton: '<',
        hasSaveButton: '<',
        animation: '<',
        cardHeaderClass: '<',
        onClose: '&',
        onSave: '&'
    },
    controller: ModalController
});

// This factory simply wraps the 'Modal' RegisteredComponentProvider
export class VMModalProvider {
    static $inject = ['vmRegisteredComponentProvider'];
    constructor(
        private vmRegisteredComponentProvider: IVMRegisteredComponentProvider
    ) {

    }

    get(name: string): IVMModal {
        return this.vmRegisteredComponentProvider.get(name, 'Modal') as unknown as IVMModal;    // double cast *is* required
    }
}

app.factory('vmModalProvider', VMModalProvider);