'use strict';

import app from './ngmodule';

export interface IVMRegisteredComponent {
    name: string;
    getComponentType(): string;
}

export interface IVMRegisteredComponentProvider {
    register(component: IVMRegisteredComponent): void;
    deRegister(name: IVMRegisteredComponent): void;

    // retrieves a component. This method should only be called when it is known
    // that the registration has already taken place.
    get(name: string, type: string): IVMRegisteredComponent;
    getTyped<TComponent>(name: string, type: string): TComponent;

    // retrieves a component. This method should be used if there is any chance of
    // the component not having been registered yet.
    waitFor(name: string, type: string): ng.IPromise<IVMRegisteredComponent>
}

class WaitFor {
    public type: string;
    public name: string;
    public deferred: ng.IDeferred<IVMRegisteredComponent>;

    constructor(type: string, name: string, deferred: ng.IDeferred<IVMRegisteredComponent>) {
        this.type = type;
        this.name = name;
        this.deferred = deferred;
    }
}

export class vmRegisteredComponentProvider implements IVMRegisteredComponentProvider {
    private components: any;
    private waitFors: Array<WaitFor>;

    static $inject = ['$q'];
    constructor(protected $q: ng.IQService) {
        this.components = {};
        this.waitFors = [];
    }

    private registerComponentType(type: string): any {
        type = type.toLocaleLowerCase();

        if (this.components[type] != null) {
            return this.components[type];
        }

        let result = {};
        this.components[type] = result;

        return result;
    }

    private manageWaitFors(component: IVMRegisteredComponent, type: string): void {
        let completed: Array<WaitFor> = [];

        for (let w of this.waitFors) {
            if (w.type != type) {
                continue;
            }

            if (w.name != component.name) {
                continue;
            }

            w.deferred.resolve(component);
            completed.push(w);
        }

        for (let w of completed) {
            let index = this.waitFors.indexOf(w);
            this.waitFors.splice(index, 1);
        }
    }

    register(component: IVMRegisteredComponent): void {
        let t = component.getComponentType();

        let components = this.registerComponentType(t);

        components[component.name] = component;

        this.manageWaitFors(component, t);
    }

    deRegister(component: IVMRegisteredComponent): void {
        let components = this.registerComponentType(component.getComponentType());

        if (components[component.name] == null) {
            return;
        }

        delete components[component.name];
    }

    get(name: string, type: string): IVMRegisteredComponent {
        let components = this.registerComponentType(type);

        return components[name] as IVMRegisteredComponent;
    }

    getTyped<TComponent>(name: string, type: string): TComponent {
        let components = this.registerComponentType(type);

        return components[name] as TComponent;
    }

    waitFor(name: string, type: string): ng.IPromise<IVMRegisteredComponent> {
        let c = this.get(name, type);

        if (c != null) {
            return this.$q.when(c);
        }

        let d = this.$q.defer<IVMRegisteredComponent>();
        let w = new WaitFor(type, name, d);
        this.waitFors.push(w);

        return d.promise;
    }
}

app.factory('vmRegisteredComponentProvider', vmRegisteredComponentProvider);