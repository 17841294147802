'use strict';

import app from './ngmodule';

app.directive('sticky', ["$mdSticky",
  function Sticky($mdSticky) {
    return {
      restrict: 'A',
      link: function (scope, element) {
        $mdSticky(scope, element);
      }
    };

  }]);