import { IChecklistPlayer, IBeginChecklistResult, ISaveChecklistResult, INextChecklistResult, IPrevChecklistResult, IContinueChecklistResult, IPublishedResourceCompoundId } from '../bworkflow-api/types';
import { IWebServiceUrl } from "../../common/web-service-url";

import ngmodule from './ngmodule';
import { ILroService } from '../lro-service';

export class OnlineChecklistPlayer implements IChecklistPlayer {

	constructor(private $http: ng.IHttpService, private webServiceUrl: IWebServiceUrl, private lroService: ILroService) {
	}

	async hasWorkingDocument(workingDocumentId: string): Promise<boolean> {
		return true;
	}

	async hasChecklist(publishedResourceId: IPublishedResourceCompoundId): Promise<boolean> {
		return true;
	}

	beginChecklist(publishedResourceId: IPublishedResourceCompoundId, revieweeId: string, args: any): Promise<IBeginChecklistResult> {
		return new Promise((resolve, reject) => {
			this.$http.post<IBeginChecklistResult>(this.webServiceUrl.api(`Player/Begin`), {
				groupId: publishedResourceId.groupId,
				resourceId: publishedResourceId.resourceId,
				revieweeId: revieweeId,
				args: args,
				publishingGroupResourceId: publishedResourceId.id,
				compoundName: publishedResourceId.compoundName,
				playerType: 'spa'
			}).then(response => {
				if (response.data.LingoDebugLroId != null) {
					this.lroService.monitorResult(response.data.LingoDebugLroId).then(result => {
						resolve(result);
					}).catch(reject);
				} else {
					resolve(response.data);
				}
			}, reject);
		});
	}

	saveChecklist(workingDocumentId: string, answerModel: any): Promise<ISaveChecklistResult> {
		return new Promise((resolve, reject) => {
			this.$http.post<ISaveChecklistResult>(this.webServiceUrl.api(`Player/Save`), {
				WorkingDocumentId: workingDocumentId,
				Answers: answerModel,
				PlayerType: 'spa'
			}).then(response => {
				resolve(response.data);
			}, reject);
		});
	}

	nextChecklist(workingDocumentId: string, answerModel: any, useOfflineQueue: boolean): Promise<INextChecklistResult> {
		return new Promise((resolve, reject) => {
			if (!useOfflineQueue) {
				this.$http.post<INextChecklistResult>(this.webServiceUrl.api(`Player/Next`), {
					WorkingDocumentId: workingDocumentId,
					Answers: answerModel,
					PlayerType: 'spa'
				}).then(response => {
					if (response.data.LingoDebugLroId != null) {
						this.lroService.monitorResult(response.data.LingoDebugLroId).then(result => {
							resolve(result);
						}).catch(reject);
					} else {
						resolve(response.data);
					}
				}, reject);
				// TO BE DONE
				//      workingDocumentExecutionHandler.next(workingDocumentId, answerModel);
			}
		});
	}

	prevChecklist(workingDocumentId: string): Promise<IPrevChecklistResult> {
		return new Promise((resolve, reject) => {
			this.$http.post<ISaveChecklistResult>(this.webServiceUrl.api(`Player/Previous`), {
				WorkingDocumentId: workingDocumentId,
				Answers: {},
				PlayerType: 'spa'
			}).then(response => {
				resolve(response.data);
			}, reject);
		});
	}

	continueChecklist(workingDocumentId: string): Promise<IContinueChecklistResult> {
		return new Promise((resolve, reject) => {
			this.$http.get<IContinueChecklistResult>(this.webServiceUrl.api(`Player/Continue/${workingDocumentId}?playerType=spa`))
				.then(response => {
					if (response.data.LingoDebugLroId != null) {
						this.lroService.monitorResult(response.data.LingoDebugLroId).then(result => {
							resolve(result);
						}).catch(reject);
					} else {
						resolve(response.data);
					}
				}, reject);
		});
	}
}

ngmodule.factory(`onlineChecklistPlayer`, [
	'$http',
	'webServiceUrl',
	'lroService',
	function (
		$http: ng.IHttpService,
		webServiceUrl: IWebServiceUrl,
		lroService: ILroService
	) {
		return new OnlineChecklistPlayer(
			$http,
			webServiceUrl,
			lroService
		);
	}])