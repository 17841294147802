import { OfflineCache, OfflineCacheOptions } from "./offline-cache";
import * as _ from 'lodash';
import { OData, IHasTracking } from "lingo-api";
import ngmodule from "./ngmodule";
import { IWebServiceUrl } from "../common/web-service-url";

export type ODataOfflineType =
	`Companies` |
	`FacilityStructures` |
	`GlobalSettings` |
	`Hierarchies` |
	`HierarchyBuckets` |
	`Labels` |
	`LabelGroups` |
	`OfflineChecklists` |
	`Rosters` |
	`TaskTypes` |
	`UserTypes`;


export class ODataOfflineCache<TODataRecord extends IHasTracking> extends OfflineCache<TODataRecord[], OData.Response<TODataRecord>> {
	private static webServiceUrl: IWebServiceUrl;

	public static initODataOfflineCache(webServiceUrl: IWebServiceUrl): void {
		ODataOfflineCache.webServiceUrl = webServiceUrl;
	}

	public lastTracking: number = 0;

	constructor(
		name: ODataOfflineType,
		options: Partial<OfflineCacheOptions<TODataRecord[], OData.Response<TODataRecord>>> & {
			uniqueId: (record: TODataRecord) => string
		}
	) {
		super(name, null, {
			url: () => `${ODataOfflineCache.webServiceUrl.odataOffline(name)}?$filter=tracking gt ${this.lastTracking}`,
			transform: (response, cached) => {
				this.lastTracking = _.maxBy(response.value, r => r.tracking)?.tracking || 0;
				let result = _.uniqBy(response.value.concat(cached || []), options.uniqueId);
				return Promise.resolve(result);
			},
			refreshMs: 5 * 60 * 1000,
			retryMs: 10 * 1000,
			timeoutMs: 30 * 1000,
			...options
		})
	}
}


ngmodule.run([
	`webServiceUrl`,
	function (
		webServiceUrl: IWebServiceUrl
	) {
		ODataOfflineCache.initODataOfflineCache(webServiceUrl);
	}
])