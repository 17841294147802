'use strict';

import angular from 'angular';

export default angular.
  module('ngGeolocation', []).
  factory('$geolocation', ['$rootScope', '$window', '$q', '$timeout', function ($rootScope, $window, $q, $timeout) {

    function Coordinates(lat, lng, alt, acc, head, vel, altacc) {
      this.latitude = lat;
      this.longitude = lng;
      this.accuracy = acc;
      this.altitude = alt !== undefined ? alt : null;
      this.heading = head !== undefined ? head : null;
      this.speed = vel !== undefined ? vel : null;

      if (this.speed === 0 || this.speed === null) {
        this.heading = NaN;
      }
      this.altitudeAccuracy = altacc !== undefined ? altacc : null;
    }

    function supported() {
      return 'geolocation' in $window.navigator;
    }

    var retVal = {
      getCurrentPosition: function (options) {
        var deferred = $q.defer();
        if (supported()) {
          $window.navigator.geolocation.getCurrentPosition(
            function (position) {
              $timeout(function () {
                retVal.position.coords = new Coordinates(position.coords.latitude, position.coords.longitude, position.coords.altitude, position.coords.accuracy, position.coords.heading, position.coords.speed, position.coords.altitudeAccuracy);
                retVal.position.timestamp = position.timestamp;
                deferred.resolve(position);
              });
            },
            function (error) {
              $timeout(function () {
                deferred.reject({
                  error: error
                });

              });
            }, options);
        } else {
          deferred.reject({
            error: {
              code: 2,
              message: 'This web browser does not support HTML5 Geolocation'
            }
          });


        }
        return deferred.promise;
      },

      watchPosition: function (options) {
        if (supported()) {
          if (!this.watchId) {
            this.watchId = $window.navigator.geolocation.watchPosition(
              function (position) {
                $timeout(function () {
                  retVal.position.coords = new Coordinates(position.coords.latitude, position.coords.longitude, position.coords.altitude, position.coords.accuracy, position.coords.heading, position.coords.speed, position.coords.altitudeAccuracy);
                  retVal.position.timestamp = position.timestamp;
                  delete retVal.position.error;
                  $rootScope.$broadcast('$geolocation.position.changed', position);
                });
              },
              function (error) {
                $timeout(function () {
                  retVal.position.error = error;
                  delete retVal.position.coords;
                  delete retVal.position.timestamp;
                  $rootScope.$broadcast('$geolocation.position.error', error);
                });
              }, options);
          }
        } else {
          retVal.position = {
            error: {
              code: 2,
              message: 'This web browser does not support HTML5 Geolocation'
            }
          };


        }
      },

      clearWatch: function () {
        if (this.watchId) {
          $window.navigator.geolocation.clearWatch(this.watchId);
          delete this.watchId;
        }
      },

      position: {}
    };


    return retVal;
  }]);