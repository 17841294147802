'use strict';

import app from './ngmodule';
import { IMediaOnClient, IVMMediaService } from './vm-media-service';
import { AsyncToQ } from './async-to-q';
import * as pdfjs from 'pdfjs-dist/webpack'

import base64 from 'base64-arraybuffer';

export interface IPdfDocument {
    pageCount: number;

    getPage(num: number): ng.IPromise<IPdfPage>;
}

export interface IPdfPage {
    pageNumber: number;
    render(canvas: HTMLCanvasElement, scale: number): void;
}

export interface IVMPdf {
    mediaOnClientToPdf(onClient: IMediaOnClient): ng.IPromise<IPdfDocument>;

    isPdfDocument(test: any): test is IPdfDocument;
}

class vmPdfDocument implements IPdfDocument {
    protected pdfDoc: pdfjs.PDFDocumentProxy;

    get pageCount(): number {
        return this.pdfDoc.numPages;
    }

    constructor(doc: pdfjs.PDFDocumentProxy, protected $q: ng.IQService, protected asyncToQ: AsyncToQ) {
        this.pdfDoc = doc;
    }

    getPage(num: number): ng.IPromise<IPdfPage> {
        if (num < 1 || num > this.pdfDoc.numPages) {
            return this.$q.reject('Value must be between 1 and the number of pages');
        }

        return this.asyncToQ.convert(async () => {
            let p = await this.pdfDoc.getPage(num);

            return new vmPdfPage(p, this.asyncToQ);
        });
    }
}

class vmPdfPage implements IPdfPage {
    protected pdfPage: pdfjs.PDFPageProxy;

    get pageNumber(): number {
        return this.pdfPage.pageNumber;
    }

    constructor(page: pdfjs.PDFPageProxy, protected asyncToQ: AsyncToQ) {
        this.pdfPage = page;
    }

    render(canvas: HTMLCanvasElement, scale: number): void {
        let vp = this.pdfPage.getViewport({ scale: scale });

        let context = canvas.getContext('2d');

        canvas.height = vp.height;
        canvas.width = vp.width;

        this.asyncToQ.convert(async () => {
            if (context == null) {
                return;
            }

            await this.pdfPage.render({ canvasContext: context, viewport: vp });
        });
    }
}

class vmPdf implements IVMPdf {
    static $inject = ['$q', 'vmMediaService', 'async-to-q'];
    constructor(protected $q: ng.IQService, protected vmMediaService: IVMMediaService, protected asyncToQ: AsyncToQ) {

    }

    isPdfDocument(test: any): test is IPdfDocument {
        return (test as IPdfDocument).getPage !== undefined;
    }

    mediaOnClientToPdf(onClient: IMediaOnClient): ng.IPromise<IPdfDocument> {
        return this.asyncToQ.convert(async () => {
            try {
                return await this.createPdfDocument(onClient);
            } catch (err) {
                console.error(err);
                throw err;
            }
        });
    }

    protected async createPdfDocument(onClient: IMediaOnClient): Promise<IPdfDocument> {
        if (onClient.data == null) {
            throw 'MediaOnClient object must have data for it to be read as a pdf';
        }

        let blob: Blob = onClient.data.loadedAsBlob ? onClient.data.data as Blob : new Blob([base64.decode(onClient.data.data as string)], { type: onClient.getMimeType() });

        let url = URL.createObjectURL(blob);

        let p = pdfjs.getDocument(url);

        let doc = await p.promise;

        URL.revokeObjectURL(url);

        return new vmPdfDocument(doc, this.$q, this.asyncToQ);
    }
}

app.factory('vmPdf', vmPdf);