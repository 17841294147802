'use strict';

import './ngmodule';
import './simple-directives-ngmodule';
import './sticky';
import './string-to-number';
import './material-keypad';
import './media-image';
import './vm-button';
import './vm-dropdown-button';
import './compile';
import './vm-card-list';
import './vm-checkbox';
import './vm-checkbox-list';
import './vm-datetime-picker';
import './vm-dropdown-select';
import './vm-modal';
import './vm-radio';
import './vm-radio-list';
import './vm-tab-control';
import './vm-textbox';
import './vm-numeric-textbox';
import './vm-validation-error-list';
import './drawer-menu';
import './qr-code-scanner';
import './vm-collapse';
import './vm-accordion';
import './vm-embedded-player';
import './vm-indeterminate-progress';
import './vm-presentation';
import './vm-widget-container';
import './vm-widget';
import './vm-circular-progress';
import './vm-chart';
import './vm-type-ahead';
import './qr-code-generator';
import './vm-pdf-viewer';
import './vm-star-rating';
import './vm-map';
import './vm-tree-view';
import './vm-dropdown-panel';
import './vm-syntax-highlighting';
import './json-formatter';

//import './vm-take-picture';		// This is included explicitly by Desktop or VMPlayer via the useXXXTakePictureController() methods