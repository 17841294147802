'use strict';

import { debugFunc } from '../../utils/debug-func';
import app from '../../vmplayer/ngmodule';

const log = debugFunc(`ConnectableDevice`);

app.
  constant('ConnectableDeviceStates', {
    0: 'Disconnected',
    1: 'Connecting',
    2: 'Connected',
    3: 'Disconnecting',
    4: 'Error',
    5: 'Unavailable',
    Named: {
      Disconnected: 0,
      Connecting: 1,
      Connected: 2,
      Disconnecting: 3,
      Error: 4,
      Unavailable: 5
    }
  }).



  factory('ConnectableDevice', ['ConnectableDeviceStates', function (statesEnum) {

    return function (self, id, name, other) {
      self.id = id;
      self.name = name;
      self.other = other;
      self.onEvent = Object.create(null);

      var setStatus = function (newState, newStatusText) {
        if (angular.isUndefined(newState)) {
          return self.status;
        }
        var oldStatus = self.status;
        self.status = newState;
        self.statusText = angular.isDefined(newStatusText) ? newStatusText : statesEnum[newState];
        if (log) {
          log.info(self.id + '..' + self.statusText);
        }
        if (oldStatus != newState) {
          var eventCallbacks = self.onEvent[newState];
          if (angular.isDefined(eventCallbacks)) {
            angular.forEach(eventCallbacks, function (callback) {
              callback();
            });
            delete self.onEvent[newState];
          }
        }
      };
      setStatus(statesEnum.Named.Disconnected);

      self.on = function (status, callback) {
        var callbacks = self.onEvent[status];
        if (angular.isUndefined(callbacks)) {
          callbacks = [];
          self.onEvent[status] = callbacks;
        }
        callbacks.push(callback);
        return function () {
          var idx = callbacks.indexOf(callback);
          if (idx >= 0) {
            callbacks.splice(idx, 1);
          }
        };
      };

      self.onConnecting = function (callback) {
        return self.on(statesEnum.Named.Connecting, callback);
      };
      self.connecting = function () {
        setStatus(statesEnum.Named.Connecting);
      };
      self.isConnecting = function () {
        return self.status == statesEnum.Named.Connecting;
      };

      self.onConnected = function (callback) {
        return self.on(statesEnum.Named.Connected, callback);
      };
      self.connected = function () {
        setStatus(statesEnum.Named.Connected);
      };
      self.isConnected = function () {
        return self.status == statesEnum.Named.Connected;
      };
      self.canConnect = function () {
        return self.status == statesEnum.Named.Disconnected;
      };

      self.onDisconnecting = function (callback) {
        return self.on(statesEnum.Named.Disconnecting, callback);
      };
      self.disconnecting = function () {
        setStatus(statesEnum.Named.Disconnecting);
      };
      self.isDisconnecting = function () {
        return self.status == statesEnum.Named.Disconnecting;
      };

      self.onDisconnected = function (callback) {
        return self.on(statesEnum.Named.Disconnected, callback);
      };
      self.disconnected = function () {
        setStatus(statesEnum.Named.Disconnected);
      };
      self.isDisconnected = function () {
        return self.status == statesEnum.Named.Disconnected;
      };
      self.canDisconnect = function () {
        return self.status == statesEnum.Named.Connected;
      };

      self.error = function (error) {
        setStatus(statesEnum.Named.Error, error);
      };
      self.isError = function () {
        return self.status == statesEnum.Named.Error;
      };
      self.unavailable = function () {
        setStatus(statesEnum.Named.Unavailable);
      };
      self.isUnavailable = function () {
        return self.status == statesEnum.Named.Unavailable;
      };
      return self;
    };
  }]);