'use strict';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { IWidget, IWidgetContainer } from '../vm-widget-container';

class WidgetController extends ControllerBase implements IWidget {
    public name: string;

    static $inject = ['$element', 'vmRegisteredComponentProvider'];
    constructor(protected $element: ng.IRootElementService, protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider) {
        super();

        this.name = '';
    }

    $onInit(): void {
        if (angular.isDefined(this.name) == false || this.name == '') {
            console.warn('vm-widget must be given a name');
            throw 'vm-widget must be given a name';
        }

        this.vmRegisteredComponentProvider.waitFor(this.name, 'WidgetContainer').then((c: unknown) => {
            if (c == null) {
                console.warn('vm-widget could not find a container for ' + this.name);
                return;
            }

            (c as IWidgetContainer).setContent(this);
        });
    }

    getContent(): ng.IRootElementService {
        return this.$element;
    }
}

app.component('vmWidget', {
    template: require('./template.html').default,
    transclude: true,
    bindings: {
        name: '@'
    },
    controller: WidgetController
});