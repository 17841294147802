import { LingoRuntimeContext, IFreezable } from './lingo-runtime-context';
import { IPresented } from '../presented-types';

export interface ILingoAnswer {
    answer: any;
    presented: IPresented;
    value: any;
    finishParameter(): any;
}

export class LingoAnswersContext implements IFreezable {
    public readonly name: string = `LingoAnswersContext`;
    private _answerStack: ILingoAnswer[][];
    public Answer: ILingoAnswer;
    public Answers: ILingoAnswer[];
    private _transaction: ILingoAnswer[][] = [];

    constructor(runtimeContext: LingoRuntimeContext) {
        var self = this;
        runtimeContext.register(this);

        this._answerStack = [];
        this.Answer = new Proxy<ILingoAnswer>(Object.create(null), {
            get(target, name: string) {
                var answer = self.getAnswer(name);
                if (!answer) {
                    throw new Error(`Answer ${name} not found`);
                }
                return answer;
            },
            apply(target, thisArg, args) {
                var name = args.shift();
                var index = args.shift();
                var answer = self.getAnswer(name, index);
                if (!answer) {
                    throw new Error(`Answer ${name} not found`);
                }
                return answer;
            }
        })
        this.Answers = new Proxy([], {
            get(target, name: string) {
                return self.getAnswers(name);
            }
        })
    }

    freeze() {
        let frozen = {
            answerStack: this._transaction
        }
        this._transaction = [];
        return frozen;
    }

    unfreeze(popsicle: any, first: boolean): void {
        if (first) {
            this._answerStack = [];
        }
        this._answerStack.push.apply(this._answerStack, popsicle.answerStack);
        this._transaction = popsicle.answerStack;
    }

    commitTransaction(): void {
        this._answerStack.push.apply(this._answerStack, this._transaction);
    }

    rollbackTransaction(): void {
        this._transaction = [];
    }

    beginTransaction(answers: ILingoAnswer[]): void {
        this._transaction = [answers];
    }

    getAllAnswers(): ILingoAnswer[][] {
        return this._answerStack;
    }

    getLastAnswers(): ILingoAnswer[] {
        if (this._answerStack.length == 0) {
            return [];
        }
        return this._answerStack[this._answerStack.length - 1];
    }

    // Return array of all answers with given name, latest answer first ie last answer is at [0]
    getAnswers(name: string): ILingoAnswer[] {
        let answers: ILingoAnswer[] = [];
        let answerStack = [...this._answerStack, ...this._transaction];
        for (var i = answerStack.length - 1; i >= 0; i--) {
            var answer = answerStack[i].find(a => a.presented.Name === name);
            if (answer) {
                answers.push(answer);
            }
        }
        return answers;
    }

    // Get answer with given name and optional index (defaults to last answer)
    getAnswer(name: string, index?: number): ILingoAnswer | null {
        index = index || 0;
        var answers = this.getAnswers(name);
        if (index < answers.length) {
            return answers[index];
        }
        return null;
    }
}
