'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionClaimHierarchyBucketTasks', ['bworkflowApi', 'sharedScope', 'languageTranslate', function (bworkflowApi, sharedScope, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.sharedScope = sharedScope.shared;

      scope.ui = 'selectbucket';
      scope.buckets = [];
      scope.bucket = null;
      scope.roster = null;
      scope.isvisible = false;
      scope.rosterCache = null;
      scope.rosterUsers = null;

      var rootObj = scope.presented.template.listento;
      scope.presented.HidePrompt = scope.presented.template.usecompactui;

      scope.teamHierarchyFeedTemplate = {
        name: 'teamhierarchy',
        feed: 'TeamHierarchys',
        filter: "",
        orderbyfields: 'RosterName',
        idfields: ['Id'],
        itemsperpage: null
      };


      scope.teamHierarchyFeed = bworkflowApi.createDataFeed(scope.teamHierarchyFeedTemplate, scope);

      scope.teamHierarchyFeed.afterLoadHooks.push(function (feed) {
        scope.rosterCache = {};
        angular.forEach(feed.data, function (item) {
          if (angular.isDefined(scope.rosterCache[item.alldata.RosterId])) {
            return;
          }

          scope.rosterCache[item.alldata.RosterId] = item;
        });
      });

      if (rootObj != null) {
        var i = rootObj.indexOf('.');
        if (i != -1)
          rootObj = rootObj.first(i);

        scope.$watch('sharedScope.' + rootObj, function (newValue, oldValue) {
          if (newValue == null) {
            return;
          }

          if (newValue.tasks.length > 0 && scope.presented.template.hidewhentaskspresent == true) {
            // there are tasks so nothing for us to do
            return;
          }

          scope.isvisible = true;

          // so there are zero tasks, so we need to swing into action and give the user a choice
          // of buckets to copy the tasks from
          scope.showHideTaskList(false);

          scope.getclaimabletasks();
        });
      }

      scope.getclaimabletasks = function () {
        bworkflowApi.execute('TaskUtilities', 'GetClaimableTasks', {}).
          then(function (data) {
            scope.buckets = data.buckets;

            if (data.buckets.length == 0) {
              // nothing to claim, so let's just show the task list
              scope.showHideTaskList(true);
            }
          }, function (tasks) {

          });
      };

      scope.selectbucket = function (bucket) {
        scope.bucket = bucket;
        scope.ui = 'confirmbucket';
      };

      scope.unselectbucket = function (bucket) {
        scope.bucket = null;
        scope.ui = 'selectbucket';
      };

      scope.queryForUser = function (bucket) {
        if (scope.presented.template.allowassignmenttootherusers == false) {
          scope.claimbucket(bucket);

          return;
        }

        scope.ui = 'selectroster';

        if (scope.rosterCache == null) {
          scope.teamHierarchyFeed.getData(true);
        }
      };

      scope.unselectroster = function () {
        scope.ui = 'confirmbucket';
        scope.roster = null;
      };

      scope.unselectrosteruser = function () {
        scope.ui = 'selectroster';
      };

      scope.selectroster = function (roster, bucket) {
        scope.rosterUsers = [];
        scope.roster = roster;
        scope.ui = 'selectrosteruser';
        angular.forEach(scope.teamHierarchyFeed.data, function (item) {
          if (item.alldata.RosterId != roster.alldata.RosterId) {
            return;
          }

          if (item.alldata.UserId == null) {
            return;
          }

          scope.rosterUsers.push(item);
        });
      };

      scope.showHideTaskList = function (show) {
        if (scope.presented.template.hideshowtasklist == true) {
          scope.$emit('player_broadcast', {
            name: 'question-task-list.visiblechange',
            data: {
              name: scope.presented.template.listento,
              visible: show
            }
          });


        }
      };

      scope.claimbucket = function (bucket, userid, rosterid) {
        var params = {
          tasks: bucket.tasks
        };


        if (angular.isDefined(userid)) {
          params.assignto = userid;
          params.assigntorosterid = rosterid;
        }

        bworkflowApi.execute('TaskUtilities', 'Reassign', params).
          then(function (data) {
            scope.bucket = null;
            scope.ui = 'selectbucket';

            scope.isvisible = false;

            scope.$emit('player_broadcast', {
              name: 'question-task-list.refreshTasks',
              data: {
                name: scope.presented.template.listento,
                forceonline: true
              }
            });



            scope.showHideTaskList(true);

            if (scope.presented.template.allowassignmenttootherusers == true) {
              scope.getclaimabletasks();
            }

          }, function (tasks) {

          });
      };
    }
  });

}]);