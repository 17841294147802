export interface InvalidReason {
    questionName: string;
    reason: string;
}

export class ValidateWithReasons {
    public reasons: InvalidReason[] = [];

    add(questionName: string, reason: string) {
        this.reasons.push({
            questionName: questionName,
            reason: reason
        });
    }

    any() {
        return this.reasons.length;
    }
}

