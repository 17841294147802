'use strict';

import './style.scss';
import '../../services/site-map';
import app from '../ngmodule';

import angular from 'angular';
import { ControllerBase } from '../control-base';
import { ISiteMapNode, ISiteMapService, ISiteMapData } from '../../services/site-map/site-map-service';

class DrawerMenuController extends ControllerBase {
    public map?: Array<ISiteMapNode>;
    public onLoaded: (() => void) | null = null;
    public showOnlyDashboards: boolean = true;

    static $inject = ['siteMapService', '$timeout', '$element'];
    constructor(protected siteMapService: ISiteMapService, private $timeout: ng.ITimeoutService, protected $element: ng.IRootElementService) {
        super();
    }

    $onInit(): void {
        if (this.showOnlyDashboards == undefined) {
            this.showOnlyDashboards = this.valueFromHtml('show-only-dashboards', true, this.$element);
        }

        this.siteMapService.getSiteMap().then((map: ISiteMapData) => 
        {
            this.map = map.map;

            if(this.showOnlyDashboards)
            {
                let newMap = [];
                // we have some filtering to do
                for(var m of this.map)
                {
                    if(m.dashboards)
                    {
                        newMap.push(m);
                    }
                }

                this.map = newMap;
            }
        }).then(() => {
            this.$timeout(() => { this.onLoaded?.(); });
        });
    }

    itemSelected(item: any): void {
        this.siteMapService.navigateTo(item);
    }
}

app.component('drawerMenu', {
    template: require('./template.html').default,
    bindings: {
        showOnlyDashboards: '<',
        onLoaded: '&'
    },
    controller: DrawerMenuController
});