import app from './ngmodule';

export interface IVMComponentUtilities {
    // Utility method that takes a source object and a dot notation string. The method returns the value at the end of the dot notation, so for example
    // if { something: { else: { thisvalue: 5 } } } was the source and the selector was 'something.else.thisvalue' the result returned would be 5    
    getValueFromDotNotation(source: any, selector: string): any
}

class vmComponentUtilities implements IVMComponentUtilities {
    getValueFromDotNotation(source: any, selector: string): any {
        if (!selector || selector == '') {
            return source;
        }

        let parts = selector.split('.');
        let obj = source;
        for (let p of parts) {
            if (obj[p] == null) {
                return null;
            }

            obj = obj[p];
        }

        return obj;
    }
}

app.factory('vmComponentUtilities', vmComponentUtilities);