import app from '../../ngmodule';
import { generateCombGuid } from '../../../utils/util';


app.directive('questionTasklistProductorderingUi', ['$timeout', 'appUpdateMonitor', 'bworkflowApi', 'vmRegisteredComponentProvider',
	function ($timeout, appUpdateMonitor, bworkflowApi, vmRegisteredComponentProvider) {
		return {
			template: require('./template.html').default,
			restrict: 'E',
			link: function (scope, element, attrs) {
				// ok so this is basically horrible, but there is a bit of work in breaking out what the tasklist directive
				// does into a service and then having things like finishing a task be done in that. At the moment the code
				// is designed around the scope in this directive being the same object as the task list (I know a complete
				// corruption of containing concerns, but its the way it was originally programmed). Without that break out of
				// functionality we need to reference the task list scope so can't have our own so we have no way of raising events
				// etc and signaling we're done nicely, so we're hacking in this dependency to the name of the modal we are in
				// and can then use that to close things.
				const modal = vmRegisteredComponentProvider.get('TaskList-showTaskDialog', 'modal');

				scope.currentOrder = null;

				appUpdateMonitor.addPauser(scope); // Pause updates whilst our scope is alive

				scope.reviewing = false;

				scope.editOrder = function (task, catalog) {
					var order = null;
					angular.forEach(task.orders, function (o) {
						if (o.catalogid == catalog.id) {
							order = o;
						}
					});

					if (order == null) {
						order = {
							__isnew: true,
							id: generateCombGuid(),
							items: [],
							catalogid: catalog.id
						};


						task.orders.push(order);
					}

					scope.currentOrder = order;
					scope.currentCatalog = angular.copy(catalog);

					scope.populateCatalog(scope.currentCatalog, scope.currentOrder, false);
				};

				// takes an order and populates the catalog with the quantities ordered
				scope.populateCatalog = function (catalog, order, onlyOrders) {
					var toRemove = [];

					angular.forEach(catalog.items, function (item) {
						item.quantity = null;

						if (catalog.stockcountwhenordering == true) {
							item.stock = null;
						}

						var orderItem = null;
						angular.forEach(order.items, function (oi) {
							if (oi.productid == item.id) {
								orderItem = oi;
							}
						});

						if (orderItem != null) {
							item.quantity = orderItem.quantity;
							item.price = orderItem.price;

							if (catalog.stockcountwhenordering == true) {
								item.stock = orderItem.stock;
							}
						}

						if (onlyOrders) {
							if (scope.isZeroValue(item.quantity) && scope.isZeroValue(item.stock)) {
								toRemove.push(item);
							}
						}
					});

					angular.forEach(toRemove, function (r) {
						var index = catalog.items.indexOf(r);
						if (index != -1) {
							catalog.items.splice(index, 1);
						}
					});
				};

				scope.isZeroValue = function (value) {
					return angular.isDefined(value) == false ||
						value == null ||
						value == 0;
				};

				scope.calculateQuantity = function (item, catalog) {
					if (catalog.stockcountwhenordering == false) {
						return;
					}

					var stock = parseInt(item.stock);

					if (isNaN(stock)) {
						return;
					}

					item.quantity = item.minstocklevel - stock;

					if (item.quantity < 0) {
						item.quantity = 0;
					}
				};

				scope.changeReviewing = function (isReviewing) {
					scope.reviewing = isReviewing;
				};

				scope.populateOrder = function (catalog, order) {
					var items = order.items; // at the end this will hold the items that should be removed
					order.items = [];

					angular.forEach(catalog.items, function (item) {
						if (scope.isZeroValue(item.quantity) && scope.isZeroValue(item.stock)) {
							return;
						}

						var orderItem = null;
						angular.forEach(items, function (oi) {
							if (oi.productid == item.id) {
								orderItem = oi;
							}
						});

						if (orderItem == null) {
							orderItem = {
								id: generateCombGuid(),
								__isnew: true,
								productid: item.id
							};

						} else {
							orderItem.__isnew = false;
							var index = items.indexOf(orderItem);
							if (index != -1) {
								items.splice(index, 1);
							}
						}

						order.items.push(orderItem);
						orderItem.quantity = item.quantity;
						orderItem.price = item.price;

						if (catalog.stockcountwhenordering == true) {
							orderItem.stock = item.stock;
							orderItem.minstocklevel = item.minstocklevel; // we store this against the order item so that we maintain history of the value at the time of ordering
						}
					});
				};

				scope.saveOrder = function (catalog, order) {
					scope.populateOrder(catalog, order);

					bworkflowApi.execute('TaskListManagement', 'SaveOrder', {
						taskid: scope.editing.id,
						order: angular.copy(scope.currentOrder),
						userid: scope.presented.userid
					}).
						then(function (data) {
							order.__isnew = false;

							angular.forEach(order.items, function (item) {
								item.__isnew = false;
							});
						});

					scope.currentOrder = null;
					scope.currentCatalog = null;
				};

				scope.cancelEdit = function (catalog, order) {
					scope.currentOrder = null;
					scope.currentCatalog = null;
				};

				scope.deleteOrder = function (catalog, order) {
					if (confirm('Are you sure you want to delete the order') == false) {
						return;
					}

					var index = scope.editing.orders.indexOf(scope.currentOrder);
					if (index != -1) {
						scope.editing.orders.splice(index, 1);
					}

					if (angular.isDefined(scope.currentOrder.__isnew) == false || scope.currentOrder.__isnew == false) {
						// it's been saved onto the server, so lets let that know
						bworkflowApi.execute('TaskListManagement', 'DeleteOrder', {
							id: scope.currentOrder.id
						});

					}

					scope.currentOrder = null;
					scope.currentCatalog = null;
				};

				scope.reviewOrders = function () {
					scope.reviewCatalogs = [];

					angular.forEach(scope.editing.orders, function (order) {
						var catalog = null;

						angular.forEach(scope.editing.catalogs, function (c) {
							if (order.catalogid == c.id) {
								catalog = c;
							}
						});

						catalog = angular.copy(catalog);

						scope.populateCatalog(catalog, order, true);

						scope.reviewCatalogs.push(catalog);
					});

					scope.reviewing = true;
				};

				scope.doFinish = function () {
					// ok so if nothing is ordered, then we'll finish this as incomplete
					var hasItems = false;

					angular.forEach(scope.editing.orders, function (order) {
						angular.forEach(order.items, function (item) {
							if (scope.isZeroValue(item.quantity) == false || scope.isZeroValue(item.stock) == false) {
								hasItems = true;
							}
						});
					});

					if (hasItems == false) {
						scope.finishingIncomplete(scope.editing);
						scope.editing.complete = false;
					} else {
						scope.editing.complete = true;
					}

					scope.finish(scope.editing, '', true, true);
					modal.close();
				};
			}
		};

	}]);

