'use strict';

import { IValidationService } from '../services/validator-service';
import { ControllerWithValidationBase } from './control-with-validation-base';
import angular from 'angular';

export interface ISelectionEvent {
    prompt: string;
    selected: boolean;
    model: any;
}

export interface IListSelectionEvent {
    selections: Array<any>;
    changed?: any;
    newValue?: boolean;
}

export interface IControlWithChoiceOption {
    prompt: string;
    selected: boolean;
    model: any;
}

class ControlWithChoiceOption implements IControlWithChoiceOption {
    prompt: string;
    selected: boolean;
    model: any;

    constructor() {
        this.prompt = '';
        this.model = '';
        this.selected = false;
    }
}

export class ControllerWithChoicesBase extends ControllerWithValidationBase implements ng.IOnChanges {
    public onUpdate: null | ((args: any) => void) = null; // event handler called when there is an update to the selections array
    public choices: Array<any>; // the set of choices handed in by whoever contains us
    public selected: Array<any> | any; // the set of selected choices handed in by whoever contains us, this will get manipulated by us
    public displayField: string; // the name of the property on an object in the choices array that should be used as the prompt for the item
    public valueField: string | null; // the name of the property on an object in the choices array that should be populated into the selected property. If null, then the whole object will be selected.
    public options: Array<IControlWithChoiceOption>; // set of options object with each item being a wrapped copy of the choices objects to make management of what's selected easy

    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, validationService: IValidationService) {
        super($element, $sce, validationService);

        this.choices = [];
        this.selected = [];
        this.options = new Array<IControlWithChoiceOption>();
        this.displayField = '';
        this.valueField = null;
    }

    protected isPrimitive(test: any): boolean {
        return test !== Object(test);
    }

    $onInit() {
        super.$onInit();

        if (angular.isDefined(this.displayField) == false) {
            let df = this.$element.attr('display-field');
            if (df != null) {
                this.displayField = df;
            }
        }

        if (angular.isDefined(this.valueField) == false) {
            let vf = this.$element.attr('value-field');
            if (vf != null) {
                this.valueField = vf;
            }
        }        

        this.buildOptions();
    }

    newOption(prompt: string, selected: boolean, model: any) {
        let o = new ControlWithChoiceOption();
        o.prompt = prompt;
        o.selected = selected;
        o.model = model;

        return o;
    }

    changed(evt: ISelectionEvent): void {
        let selectedOption : IControlWithChoiceOption | null = null;

        for(let o of this.options)
        {
            if(o.model == evt.model)
            {
                selectedOption = o;
                break;
            }
        }

        if(selectedOption == null)
        {
            return;
        }

        if(selectedOption.selected != evt.selected)
        {
            selectedOption.selected = evt.selected;

            this.doOnUpdate(selectedOption.model, selectedOption.selected);
        }
    }

    protected getSelected(o:IControlWithChoiceOption): any {
        if(this.valueField)
        {
            return o.model[this.valueField];
        }

        return o.model;
    }

    doOnUpdate(changed: any, newValue: boolean | null) {
        if(Array.isArray(this.selected)) {
            this.selected.length = 0;

            for (let o of this.options) {
                if (o.selected == false) {
                    continue;
                }
    
                this.selected.push(this.getSelected(o));
            }
        }
        else {
            for(let o of this.options) {
                if(o.selected)
                {
                    // if the selected isn't an array, then we set it at the first selcted object we come across
                    this.selected = this.getSelected(o);
                    break;
                }
            }
        }


        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { selections: this.selected, changed: changed, newValue: newValue }});
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        super.$onChanges(changes);

        if (changes.choices) {
            this.choices = changes.choices.currentValue;
        }

        if (changes.selected) {
            this.selected = changes.selected.currentValue;
        }

        if (changes.choices || changes.selected) {
            this.buildOptions();
        }
    }

    buildOptions(): void {
        this.options = new Array<IControlWithChoiceOption>();

        if (this.choices == null || this.choices.length == 0) {
            return;
        }

        let getValue = (c: any) => {
            return this.isPrimitive(c) == false ? c[this.displayField] : c;
        };

        for (let choice of this.choices) {
            let option = this.newOption(getValue(choice), false, choice);

            this.options.push(option);
        }

        if (this.selected == null) {
            return;
        }

        // now select the options that are selected. Since the selected property could be an array or not
        // we wrap what ever as an array to simplify the logic of actually doing the selection
        let selected = Array.isArray(this.selected) ? this.selected as Array<any> : [this.selected];

        for (let choice of selected) {
            for(let option of this.options)
            {
                if(this.getSelected(option) == choice)
                {
                    option.selected = true;
                    break;
                }
            }
        }
    }

    selectAll(raiseChanged?: boolean): void {
        for (let o of this.options) {
            o.selected = true;
        }

        if (raiseChanged) {
            this.doOnUpdate(null, null);
        }
    }

    unselectAll(raiseChanged?: boolean): void {
        for (let o of this.options) {
            o.selected = false;
        }

        if (raiseChanged) {
            this.doOnUpdate(null, null);
        }
    }
}
