'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionLabel', ['$interpolate', 'sharedScope', 'languageTranslate', function ($interpolate, sharedScope, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, undefined, languageTranslate);
      scope.showHelp = function () {
        scope.$emit('help_start', {});
      };

      scope.raiseEvent = function (eventName, args) {
        // allows someone to raise an event to get other elements to do things,
        // for example clicking a button in the label refreshing a task list
        scope.$emit('player_broadcast', {
          name: eventName,
          data: args
        });

      };

      scope.openWindow = function (url, target) {
        window.open(url, target);

        return false;
      };

      if (scope.presented.Listen) {
        scope.sharedScope = sharedScope.shared;

        var rootObj = scope.presented.ListenTo;
        var i = rootObj.indexOf('.');
        if (i != -1)
          rootObj = rootObj.first(i);

        scope[rootObj] = sharedScope.get(rootObj);

        scope.isVisible = function () {
          return scope.$eval('sharedScope.' + scope.presented.ListenTo);
        };
      }
    }
  });

}]);