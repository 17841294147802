'use strict';

import angular from 'angular';
import cachedExecutionHandlersModule from './ngmodule';

export default cachedExecutionHandlersModule;

cachedExecutionHandlersModule.factory('cachedExecutionHandlers', ['$q', 'bworkflowApi', '$timeout', function ($q, bworkflowApi, $timeout) {
  var cachedExecutionHandlerService = {
    cachedCountryStates: null,

    getAllCountryStates: function () {
      var d = $q.defer();

      var doResolve = function () {
        d.resolve(cachedExecutionHandlerService.cachedCountryStates);
      };

      if (cachedExecutionHandlerService.cachedCountryStates != null) {
        $timeout(doResolve);
      } else {
        bworkflowApi.execute('CountryStates', 'GetAll', {}).
          then(function (data) {
            cachedExecutionHandlerService.cachedCountryStates = data.countrystates;

            doResolve();
          }, function (tasks) {

          });
      }

      return d.promise;
    }
  };


  return cachedExecutionHandlerService;
}]);