'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';
import Handlebars from 'handlebars';
import {OdataTypeAheadDataSource} from '../../directives/vm-type-ahead/odata-type-ahead-data-source';

questionsModule.directive('questionSelectOdata', ['bworkflowApi', 'languageTranslate', function (bworkflowApi, languageTranslate) {
    return $.extend({}, questionDirectiveBase, {
      template: require('./template.html').default,
      link: function (scope, elt, attrs) {
        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
        scope.answer = scope.presented.Answer;
        scope.selectedName = null;
        if(scope.answer.Selected != null)
        {
            scope.selectedName = scope.answer.Selected.name;
        }

        scope.presented.controlShowsValidationErrors = true;
        scope.presented.controlShowsPrompt = true;
  
        // this supports validation as each question type stores it's answer in a different way
        scope.getAnswerValue = function () {
          return angular.equals({}, scope.answer.Selected) ? null : scope.answer.Selected;
        };

        scope.updateSelection = function (obj) {
            scope.answer.Selected = obj;
            if(obj != null)
            {
                scope.selectedName = obj.name
            }            
        }

        scope.searchItemSelected = function($event) {
            if($event.item == null)
            {
              scope.updateSelection({}); // an empty Guid get's translated to null on the server
              return;
            }

            scope.updateSelection($event.item.alldata);
          }

        scope.init = function() {
            bworkflowApi.getDataFeed(scope.presented.datasource).then((feed) => {
              feed.allowMultipleAjax = true; // EVS-1404 fix - For searching the user could type quicker than we can search, allowing multiple Ajax means we will always catch the users last search query
              scope.search = new OdataTypeAheadDataSource(feed, null, null);
            });
        }
    
        scope.init();
      }
    });
  
  }]);