import { Moment } from "moment";
import { Observable, Subscription } from "rxjs";

export interface AboutItem {
	order: number;
	tag?: string;
	display: string;
	value?: string;
	subscription?: Subscription;
	fetching: boolean;
	options: AboutOptions;
}

interface AboutOptions {
	timestamp?: Moment;
}

export class AboutCategory extends Array<AboutItem> {
	protected about: {
		[display: string]: AboutItem
	} = {};
	private updateCount = 0;
	private changeCount = 0;

	visible: boolean = true;

	static $inject = [
		`$timeout`
	];

	constructor(
		private $timeout: ng.ITimeoutService,
		public id: string,
		public display: string
	) {
		super();
	}

	private update(force: boolean = false) {
		if (force || (this.changeCount > 0 && this.updateCount == 0)) {
			this.changeCount = 0;
			this.$timeout(() => {
				this.splice(0, this.length);		// in place remove all items
				let sortedKeys = Object.keys(this.about).sort();
				sortedKeys.map(key => {
					let display = key;
					let number = display.indexOf('.');
					if (number >= 0) {
						display = display.substr(number + 1);
					}
					this.push(this.about[key]);
				})
			});
		}
	}

	cancel(): void {
		Object.values(this.about).map(i => i.subscription?.unsubscribe());
	}

	deleteAbout(args: (number | string)[]) {
		Object.keys(this.about).map(key => {
			let about = this.about[key];
			if (args.indexOf(about.order) >= 0 || (about.tag != null && args.indexOf(about.tag) >= 0)) {
				delete this.about[key];
				this.changeCount++;
			}
		})
		this.update();
	}

	beginUpdate() {
		this.updateCount++;
	}

	endUpdate() {
		this.updateCount--;
		this.update();
	}

	setAbout(args: {
		order: number,
		tag?: string,
		display: string,
		value: Observable<any> | Promise<any> | any | null,
		options?: AboutOptions
	}) {
		let { order, tag, display, value, options } = args;
		let key = `${order}.${display}`;
		if (value == null) {
			if (this.about[key] != null) {
				delete this.about[key];
				this.changeCount++;
				this.update();
			}
		} else if (value instanceof Promise) {
			this.about[key] = {
				order,
				tag,
				display,
				fetching: true,
				options: { ...options }
			};
			value.then(realValue => {
				this.about[key].value = realValue?.toString();
				this.about[key].fetching = false;
				this.changeCount++;
				this.update();
			})
			this.changeCount++;
			this.update();
		} else if (value instanceof Observable) {
			this.about[key] = {
				order,
				tag,
				display,
				fetching: true,
				options: { ...options }
			};
			// Do this after creating the object above since subscribe could be synchronous
			this.about[key].subscription = value.subscribe(realValue => {
				this.about[key].value = realValue?.toString();
				this.about[key].fetching = false;
				this.changeCount++;
				this.update();
			});
			this.changeCount++;
			this.update();
		} else {
			this.about[key] = {
				order,
				tag,
				display,
				value: value?.toString(),
				fetching: false,
				options: { ...options }
			};
			this.changeCount++;
			this.update();
		}
	}
}

