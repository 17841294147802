'use strict';

import app from '../ngmodule';
import './vm-tab-pane';
import './index.scss';
import { TabPaneController } from './vm-tab-pane';
import { ControllerBase } from '../control-base';

export class TabController extends ControllerBase {
    public tabs: Array<TabPaneController>;
    public class: string;
    public useDropDown: boolean;

    protected prompt?: string;

    static $inject = ['$element', '$timeout', '$scope'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService, protected $scope: ng.IScope) {
        super();

        this.class = "";
        this.useDropDown = false;
        this.prompt = "";

        this.tabs = [];
    }

    $onInit() {
        super.$onInit();

        if (this.class == undefined) {
            this.class = this.valueFromHtml("class", '', this.$element);
        }

        this.$timeout(() => {
            let selected: TabPaneController | null = this.calculateSelectedTab();

            if(selected == null)
            {
                return;
            }

            this.selectTab(selected);
        });
    }

    isTabPaneController(test: TabPaneController | number): test is TabPaneController {
        return typeof test !== 'number';
    }

    selectTab(tab: TabPaneController | number, hideDropDown?: boolean): void {
        let t: TabPaneController;
        let index = 0;

        if (this.isTabPaneController(tab)) {
            t = tab;

            for (var i = 0; i < this.tabs.length; i++) {
                if(this.tabs[i] == tab)
                {
                    index = i;
                }
            }
        }
        else {
            if (tab < 0 || tab >= this.tabs.length) {
                return;
            }

            t = this.tabs[tab];
            index = tab;
        }

        for (var i = 0; i < this.tabs.length; i++) {
            this.tabs[i].selected = false;
        }

        t.selected = true;

        // some things have UI issues when shown in tabs (for example leaflet maps), we broadcast down so that
        // these guys can do what ever they need to do
        this.$scope.$broadcast('vm-tab-control.show', {tabPane: t, tabControl: this});

        this.$timeout(() => {
            t.notifyChildren('show');
        });

        this.prompt = t.title;

        if(hideDropDown)
        {
            this.toggleDropMenu();
        }
    }

    addTab(tab: TabPaneController): void {
        this.tabs.push(tab);

        this.calculateSelectedTab();
    }

    removeTab(tab: TabPaneController): void {
        let index = this.tabs.indexOf(tab);

        if (index == -1) {
            return;
        }

        this.tabs[index].notifyChildren('destroy');

        this.tabs.splice(index, 1);

        this.calculateSelectedTab();
    }

    calculateSelectedTab(): TabPaneController | null {
        if (this.tabs.length == 0) {
            return null;
        }

        var selected = null;

        for (var i = 0; i < this.tabs.length; i++) {
            let t = this.tabs[i];

            if (t.selected == true) {
                selected = t;
                break;
            }
        }

        if (selected == null) {
            selected = this.tabs[0];
        }

        selected.selected = true;

        return selected;
    }

    toggleDropMenu(): void {
        this.$element.find('.dropdown.vm-tab-dropdown').toggleClass('is-active');
    }
}

app.component('vmTabControl', {
    template: require('./template.html').default,
    transclude: true,
    bindings: {
        class: '<',
        useDropDown: '<'
    },    
    controller: TabController
});