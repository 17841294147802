import { result } from 'lodash';
import app from '../ngmodule';
import './validation-rules';
import { CustomValidationRule } from './validation-rules/custom-validation-rule';
import { LengthValidationRule } from './validation-rules/length-validation-rule';
import { MandatoryValidationRule } from './validation-rules/mandatory-validation-rule';
import { RegaularExpressionValidationRule } from './validation-rules/regular-expression-validation-rule';

// the result of a validation operation
export interface IValidationResult {
    errors: Array<string>;
}

// function that can be used to create the error message for a failed validation
export interface IValidationMessageFactory { (validationRule: IValidationRule, value: any, template: any): string };

export interface IValidationRule {
    isValid(value: any, template: any): IValidationResult;
}

export interface IValidationHook { (value: any, template: IValidationTemplate, errors: Array<string>): boolean };

export interface IValidationTemplate {
    rules: Array<IValidationRule>;
}

export interface IValidated {
    validation: IValidationTemplate;
    errors: Array<string> | undefined;
    afterValidationHooks: Array<IValidationHook> | undefined;
}

export interface IValidationService {
    // help function for javascript (rather than typescript) to create validator rule objects for use in a validation graph
    createValidator(name: string, ...args: any[]): any;
    // processes a validation graph, recursing its way down the validation graph and validating properties on the source object against the rules in the validation graph 
    process(source: any, validationGraph: any): IValidationResult;
}

class vmValidationService implements IValidationService {
    protected validatorLookup: { [key: string]: any } = {};

    constructor() {
        this.validatorLookup["mandatory"] = MandatoryValidationRule;
        this.validatorLookup["length"] = LengthValidationRule;
        this.validatorLookup["custom"] = CustomValidationRule;
        this.validatorLookup["regular expression"] = RegaularExpressionValidationRule;
    }

    isValidated(test: any): test is IValidated {
        return (test as IValidated).validation !== undefined;
    }

    isPrimitive(test: any): boolean {
        return test !== Object(test);
    }

    createValidator(name: string, ...args: any[]): any {
        let v = this.validatorLookup[name.toLowerCase()];

        let instance = v != null ? new v(...args) : null;

        return instance;
    }

    process(source: any, validationGraph: any): IValidationResult {
        let result = {
            errors: new Array<string>()
        };

        this.processValidationGraph(source, validationGraph, result);

        return result;
    }

    processValidationGraph(source: any, validationGraph: any, allResults: IValidationResult): void {
        if (this.isPrimitive(source) == true) {
            // ok, the thing we are testing is a primitive, we need to wrap things a bit for this
            return this.processValidationGraph({ toValidate: source }, { toValidate: validationGraph }, allResults);
        }

        for (let p in validationGraph) {
            if (p == "errors") {
                // we add an errors property onto the objects in the validation graph
                // so skip this from being checked
                continue;
            }

            let vals = validationGraph[p];
            let sourceValue = source[p];

            if (Array.isArray(vals) && Array.isArray(sourceValue)) {
                // if the property in the validation template has been defined to be an array, then the stuff under it needs to be run on each
                // item of the array rather than on the array object its self.
                for (let i of sourceValue) {
                    this.processValidationGraph(i, vals[0], allResults);
                }

                // we aren't going to recurse into the array object its self at this point
                return;
            }

            vals.errors = undefined;

            if (this.isValidated(vals)) {
                // there are validator rules applied to this, so there are expectations of the value is source to match these rules, so we need to run them
                let errors = new Array<string>();
                for (let r of vals.validation.rules) {
                    let result = r.isValid(sourceValue, vals.validation);

                    if (result.errors.length > 0) {
                        for (let e of result.errors) {
                            errors.push(e);
                            allResults.errors.push(e);
                        }
                    }
                }

                if (errors.length > 0) {
                    vals.errors = errors; // we decorate the validation graph with the errors to make it easy for controls to show their validations
                }

                if(vals.afterValidationHooks)
                {
                    for(let h of vals.afterValidationHooks) {
                        h(sourceValue, vals.validation, errors);
                    }
                }
            }

            // run over any sub properties to validate down the object graph, we only do this if the property is an object of some variety
            if (sourceValue != null && this.isPrimitive(sourceValue) == false) {
                this.processValidationGraph(sourceValue, vals, allResults);
            }
        }
    }
}

app.factory('vmValidationService', vmValidationService);