import { Question, Answer } from "./question";
import { JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';
import { ISectionDefinition, IQuestionDefinition, DesignSection } from 'lingo-api';
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { IPresentedAnswer, IPresentedSection } from "../../presented-types";

export class Section extends Question implements DesignSection {
	children?: IQuestionDefinition[] = [];

	constructor() {
		super(`section`);
	}
}

export class SectionAnswer extends Answer implements ILingoAnswer {
	constructor(public answer: IPresentedAnswer, public presentedQuestion: IPresentedSection) {
		super(answer, presentedQuestion);
	}

	get value() {
		return null;
	}
	set value(value: any) {
	}

	finishParameter(): any {
		return null;
	}
}


JsonTreeTranslators.register({
	ctr: Section,
	create: () => new Section()
}, {
	ctr: SectionAnswer,
	flatten(o: SectionAnswer) {
		return {
			answer: o.answer,
			presentedQuestion: o.presentedQuestion
		}
	},
	fatten(o: any, fatten: Convert, store: Convert) {
		return store(new SectionAnswer(fatten(o.answer), fatten(o.presentedQuestion)));
	}
});