'use strict';

import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';

class RadioController extends ControllerBase {
    public invalid: boolean;
    public selected: boolean;
    public value: string | null;
    public display: string | null;
    public model: any;
    public id: string;
    public disabled: boolean;
    public onUpdate?: null | ((args: any) => void) = null;

    static $inject = ['$element', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService) {
        super();

        this.invalid = false;
        this.selected = false;
        this.display = null;
        this.value = null;
        this.disabled = false;
        this.id = 'radio' + uuidv4();
    }

    $onInit(): void {
        if (this.display != null) {
            // we trim off the spaces as angular seems to do this when setting ngModel through ngValue, so
            // if there are spaces at the end we end up with this.selected not being true when a selection is
            // made if there are trailing spaces
            this.display = this.display.trim();
        }

        this.value = this.selected ? this.display : null;        
    }

    changed(): void {
        this.$timeout(
            () => {
                this.selected = this.value == this.display;

                if (this.onUpdate == null) {
                    return;
                }
        
                this.onUpdate({ $event: { selected: this.selected, model: this.model, prompt: this.display }});
            }
        );
    }
}

app.component('vmRadio', {
    template: require('./template.html').default,
    bindings: {
        model: '<',
        selected: '=',
        display: '<',
        invalid: '<',
        name: '<',
        disabled: '<',
        onUpdate: '&'
    },
    controller: RadioController
});