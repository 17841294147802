'use strict';

import angular from 'angular';
import { ltrim, rtrim } from '../utils/util';

import filtersModule from './ngmodule';

filtersModule.filter('url', ['$window', function ($window) {
  return function (inputstr) {
    return inputstr.replace(/^~\//, rtrim($window.razordata.siteprefix) + '/');
  };
}]);

filtersModule.filter('clientUrl', ['$window', function ($window) {
  return function (inputstr) {
    // First, look for the SPA Virtual root ~/# becomes domainname/spa/a/
	//	Lucifer-417 - added domainname and spa/a/
    if (inputstr.startsWith('~/#')) {
      return inputstr.replace(/^~\/#\//, rtrim(window.razordata.siteprefix) + '/a/');
    } else {
      return inputstr.replace(/^~\//, rtrim(window.razordata.siteprefix) + '/');
    }
  };
}]);

filtersModule.filter('mediaUrl', ['$window', function ($window) {
  return function (inputstr) {
    return inputstr.replace(/^~\//, rtrim(window.razordata.mediaprefix) + '/');
  };
}]);

filtersModule.filter('mediaItem', ['$window', function ($window) {
  return rtrim($window.razordata.portalprefix) + '/Media/GetMediaItem?id=' + inputstr;
}]);

filtersModule.filter('dateOrTime', [function () {
  return function (inputstr) {
    var t = moment(inputstr);
    if (moment().isSame(t, 'day'))
      return t.format('h:mm A');
    else
      return t.format('d MMM');
  };
}]);

filtersModule.filter('chop', [function () {
  return function (inputstr, maxlen) {
    if (inputstr.length <= maxlen)
      return inputstr;

    var chopped = inputstr.substring(0, maxlen);
    chopped = chopped + '...';
    return chopped;
  };
}]);

filtersModule.directive('bwkChop', ['$timeout', function ($timeout) {
  return {
    restrict: 'EA',
    scope: {
      'callback': '=',
      'text': '=',
      'key': '='
    },

    link: function (scope, elt, attrs) {
      //is it >140?
      if (scope.text.length < 140) {
        $(elt).text(scope.text);
        return;
      }

      scope.choptext = scope.text.substring(0, 140) + '...';
      $(elt).text(scope.choptext);
      var readmoreelt = $('<a href="">Read More</a>');
      $(elt).append(readmoreelt);
      $(readmoreelt).on('click', function () {
        $timeout(function () {
          scope.callback(scope.key);
        });
      });
    }
  };

}]);