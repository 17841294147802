'use strict';

import app from '../../ngmodule';
import angular from 'angular';

app.controller('finishingCtrl', ['$scope', '$stateParams', 'transitionCache', 'bworkflowApi',
  function ($scope, $stateParams, transitionCache, bworkflowApi) {
    $scope.models.playerModel = transitionCache.getAndDelete($stateParams.workingDocumentId);

    $scope.buttonStates.canPrevious = $scope.models.playerModel.CanPrevious;
    $scope.buttonStates.canNext = false;

    $scope.doFinish = function () {
      $scope.buttonStates.areAjaxing = true;
      bworkflowApi.nextChecklist($scope.models.playerModel.WorkingDocumentId, $scope.models.answerModel).
        then(function (r) {
          $scope.buttonStates.areAjaxing = false;
          $scope.transitionWithPlayerModel(r, 'right');
        }, function (ex) {
          $scope.buttonStates.areAjaxing = false;
          $scope.transitionWithPlayerModel(ex.data, 'right');
        });
    };
  }]);