import angular from "angular";
import _ from "lodash";

// The externs-builder is responsible for generating an array of "externs" suitable for passing to a JsonTree serializer/deserializer - the "externs" array
// must always be the "same length and order" in order for JsonTree to succesfully stringify and then parse an object graph which uses the "externs"
// So any IExternBuilder must gaurantee that it meets this rule aswell
export interface IExternBuilder {
	buildExterns(externs: any[], iterate: (o: any) => any): void;
}

export async function buildExterns(...args: any[]): Promise<any[]> {
	let externs: any[] = [];
	let iterate = (obj: any): any => {
		if (obj !== null && externs.indexOf(obj) === -1) {
			if (angular.isArray(obj)) {
				externs.push(Promise.resolve(obj));			// #1 This will push the array itself after the flattenDeep below
				externs.push(obj);
			} else if (typeof obj === 'object') {
				externs.push(obj);
				// If the object implements IExternBuilder then call it rather than iterating its properties
				if (`buildExterns` in obj) {
					obj.buildExterns(externs, iterate);
				} else {
					let keys = Object.keys(obj).sort();
					for (let p in keys) {
						const k = keys[p];
						const o = obj[k];
						iterate(o);
					}
				}
			} else if (typeof obj === 'function') {
				externs.push(obj);
			}
		}
		return obj;
	}
	args.map(iterate);

	// Deep flatten the array and wait for any Promises to resolve (replacing their entry with the Promise result) - note in order to insert an Array into this final
	// array you should use Promise.resolve(your array) as we do above #1
	let deepExterns = await Promise.all(_.flattenDeep(externs).map(async (e: any) => {
		if (e != null && typeof e.then === 'function') {
			return await e;
		} else {
			return e;
		}
	}));

	return deepExterns;
}
