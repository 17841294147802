import moment from 'moment';
import { IPersistantStorage } from '../common/persistant-storage';
import ngmodule from './ngmodule';
import angular from 'angular';
import { IAuthService } from './auth/auth-svc';

interface Stored<T> {
	value: T;
	timestamp: Date;
}

export interface OfflineCacheOptions<TCacheValue, TResponseValue> {
	url: (value: TCacheValue | undefined) => string;
	refreshMs: number;
	retryMs: number;
	timeoutMs: number;
	count: number;
	transform: (response: TResponseValue, cached: TCacheValue | undefined) => Promise<TCacheValue>;
	allowAnonymous: boolean;
}

type Watcher<TValue> = (value: TValue) => void;

export class OfflineCache<TCacheValue, TResponseValue> {
	public static persistantStorage: IPersistantStorage;
	public static $http: ng.IHttpService;
	public static $timeout: ng.ITimeoutService;
	public static authSvc: IAuthService;

	private static _caches: { [name: string]: any } = {};

	private stored?: Stored<TCacheValue> | null = null;
	private storage: IPersistantStorage;
	private options: OfflineCacheOptions<TCacheValue, TResponseValue>;

	private $fetch?: Promise<TCacheValue>;
	private watches: Watcher<TCacheValue>[] = [];

	public static initOfflineCache(
		$http: ng.IHttpService,
		$timeout: ng.ITimeoutService,
		persistantStorage: IPersistantStorage,
		authSvc: IAuthService
	) {
		OfflineCache.$http = $http;
		OfflineCache.$timeout = $timeout;
		OfflineCache.persistantStorage = persistantStorage;
		OfflineCache.authSvc = authSvc;
	}

	public static get(name: string): any {
		return OfflineCache._caches[name];
	}

	constructor(
		public name: string,
		url: string | null,
		options?: Partial<OfflineCacheOptions<TCacheValue, TResponseValue>>,
	) {
		let defaults: OfflineCacheOptions<TCacheValue, TResponseValue> = {
			refreshMs: 5 * 60 * 1000,
			retryMs: 10 * 1000,
			timeoutMs: 30 * 1000,
			count: -1,
			url: () => url || ``,
			transform: async (response, cache) => (response as unknown) as TCacheValue,
			allowAnonymous: false
		};
		this.options = {
			...defaults,
			...options
		}

		this.storage = OfflineCache.persistantStorage.createNamespace(name);
		this.fetchFromCache().then(value => {
			this.fetchAndReschedule();
		})

		OfflineCache._caches[name] = this;
	}

	public watch(watcher: Watcher<TCacheValue>): () => void {
		this.watches.push(watcher);
		return () => {
			const i = this.watches.indexOf(watcher);
			if (i >= 0) {
				this.watches.splice(i, 1);
			}
		}
	}

	private notifyWatchers() {
		this.watches.map(watcher => {
			try {
				watcher(this.stored?.value as TCacheValue);
			} catch {
			}
		});
	}

	private fetchFromCache(): Promise<TCacheValue | undefined> {
		return new Promise((resolve, reject) => {
			this.storage.getItem<Stored<TCacheValue>>(`stored`).then(stored => {
				if (stored != null) {
					this.stored = stored;
					this.notifyWatchers();
				}
				resolve(this.stored?.value);
			}).catch(err => {
				reject(err);
			})
		});
	}

	async fetch(): Promise<TCacheValue> {
		// Make sure we only fetch one at a time ..
		if (this.$fetch == null) {
			let url = this.options.url(this.stored?.value);

			this.$fetch = new Promise<TCacheValue>((resolve, reject) => {
				let p = this.options.allowAnonymous ? Promise.resolve(true) : OfflineCache.authSvc.isAuthenticated();
				p.then(allow => {
					if (allow) {
						OfflineCache.$http.get<TResponseValue>(url, {
							timeout: this.options.timeoutMs
						}).then(async response => {
							if (response.status === 200) {
								let value = await this.options.transform(response.data, this.stored?.value);
								this.set(value).then(() => {
									if (this.stored != null) {
										resolve(this.stored?.value);
									} else {
										reject();
									}
								}).catch(reject);
							} else {
								reject();
							}
						}).catch(reject);
					} else {
						reject();
					}
				}).catch(reject);
			}).then(result => {
				delete this.$fetch;
				return result;
			}).catch(err => {
				delete this.$fetch;
				throw err;
			})
		}
		return this.$fetch;
	}

	private async fetchAndReschedule(): Promise<TCacheValue> {
		return this.fetch().then(value => {
			if (this.options.count !== 0) {
				if (this.options.count > 0) {
					this.options.count--;
				}
				OfflineCache.$timeout(this.options.refreshMs).then(this.fetchAndReschedule.bind(this));
			}
			return value;
		}).catch(err => {
			return OfflineCache.$timeout(this.options.retryMs).then(this.fetchAndReschedule.bind(this));
		})
	}

	async get(): Promise<TCacheValue> {
		if (this.stored == null) {
			return this.fetchFromCache().then(value => {
				if (value != null) {
					return value;
				} else {
					return this.fetchAndReschedule();
				}
			})
		} else {
			return this.stored.value;
		}
	}

	async set(value: TCacheValue): Promise<void> {
		this.stored = Object.assign(this.stored || {}, {
			value: value,
			timestamp: moment().utc().toDate()
		});
		this.notifyWatchers();
		return this.storage.setItem(`stored`, this.stored);
	}
}

ngmodule.run([
	`$http`,
	`$timeout`,
	`persistantStorage`,
	`authSvc`,
	function (
		$http: ng.IHttpService,
		$timeout: ng.ITimeoutService,
		peristantStorage: IPersistantStorage,
		authSvc: IAuthService
	) {
		OfflineCache.initOfflineCache($http, $timeout, peristantStorage, authSvc);
	}
])