'use strict';

import angular from 'angular';

angular.module('vmngUtils', []).

  factory('ngTimeout', ['$timeout', function ($timeout) {
    var _insideAngular = 0;

    return function ngTimeout() {
      var fns = Array.prototype.slice.call(arguments);
      var empty = true;
      fns.forEach(function (fn) {
        if (angular.isFunction(fn)) {
          empty = false;
        }
      });

      if (empty) {
        return angular.identity;
      }

      return function () {
        var args = Array.prototype.slice.call(arguments);
        var callback = function () {
          _insideAngular++;
          try {
            fns.forEach(function (fn) {
              if (angular.isFunction(fn)) {
                fn.apply(null, args);
              }
            });
          } finally {
            _insideAngular--;
          }
        };

        if (_insideAngular == 0) {
          $timeout(callback, 0);
        } else {
          // Already within Angular, call direct
          callback();
        }
      };
    };
  }]).

  factory('actionQueue', [function () {
    var _nextId = 0;
    return function () {
      var f = function (action) {
        if (f._q) {
          f._q.push(action);
        } else {
          f._q = [];

          var firstTime = true;
          var finish = function () {
            if (!firstTime) {
              return;
            }
            firstTime = false;

            if (f._q && f._q.length) {
              var next = f._q.shift();
              next(finish);
            }
            if (f._q && f._q.length == 0) {
              f._q = null;
            }
          };

          action(finish);
        }
      };

      f._id = ++_nextId;
      f._q = null;
      return f;
    };
  }]).

  factory('CRC16', [function () {
    var crc16tab = [
      0x0000, 0x1021, 0x2042, 0x3063, 0x4084, 0x50a5, 0x60c6, 0x70e7,
      0x8108, 0x9129, 0xa14a, 0xb16b, 0xc18c, 0xd1ad, 0xe1ce, 0xf1ef,
      0x1231, 0x0210, 0x3273, 0x2252, 0x52b5, 0x4294, 0x72f7, 0x62d6,
      0x9339, 0x8318, 0xb37b, 0xa35a, 0xd3bd, 0xc39c, 0xf3ff, 0xe3de,
      0x2462, 0x3443, 0x0420, 0x1401, 0x64e6, 0x74c7, 0x44a4, 0x5485,
      0xa56a, 0xb54b, 0x8528, 0x9509, 0xe5ee, 0xf5cf, 0xc5ac, 0xd58d,
      0x3653, 0x2672, 0x1611, 0x0630, 0x76d7, 0x66f6, 0x5695, 0x46b4,
      0xb75b, 0xa77a, 0x9719, 0x8738, 0xf7df, 0xe7fe, 0xd79d, 0xc7bc,
      0x48c4, 0x58e5, 0x6886, 0x78a7, 0x0840, 0x1861, 0x2802, 0x3823,
      0xc9cc, 0xd9ed, 0xe98e, 0xf9af, 0x8948, 0x9969, 0xa90a, 0xb92b,
      0x5af5, 0x4ad4, 0x7ab7, 0x6a96, 0x1a71, 0x0a50, 0x3a33, 0x2a12,
      0xdbfd, 0xcbdc, 0xfbbf, 0xeb9e, 0x9b79, 0x8b58, 0xbb3b, 0xab1a,
      0x6ca6, 0x7c87, 0x4ce4, 0x5cc5, 0x2c22, 0x3c03, 0x0c60, 0x1c41,
      0xedae, 0xfd8f, 0xcdec, 0xddcd, 0xad2a, 0xbd0b, 0x8d68, 0x9d49,
      0x7e97, 0x6eb6, 0x5ed5, 0x4ef4, 0x3e13, 0x2e32, 0x1e51, 0x0e70,
      0xff9f, 0xefbe, 0xdfdd, 0xcffc, 0xbf1b, 0xaf3a, 0x9f59, 0x8f78,
      0x9188, 0x81a9, 0xb1ca, 0xa1eb, 0xd10c, 0xc12d, 0xf14e, 0xe16f,
      0x1080, 0x00a1, 0x30c2, 0x20e3, 0x5004, 0x4025, 0x7046, 0x6067,
      0x83b9, 0x9398, 0xa3fb, 0xb3da, 0xc33d, 0xd31c, 0xe37f, 0xf35e,
      0x02b1, 0x1290, 0x22f3, 0x32d2, 0x4235, 0x5214, 0x6277, 0x7256,
      0xb5ea, 0xa5cb, 0x95a8, 0x8589, 0xf56e, 0xe54f, 0xd52c, 0xc50d,
      0x34e2, 0x24c3, 0x14a0, 0x0481, 0x7466, 0x6447, 0x5424, 0x4405,
      0xa7db, 0xb7fa, 0x8799, 0x97b8, 0xe75f, 0xf77e, 0xc71d, 0xd73c,
      0x26d3, 0x36f2, 0x0691, 0x16b0, 0x6657, 0x7676, 0x4615, 0x5634,
      0xd94c, 0xc96d, 0xf90e, 0xe92f, 0x99c8, 0x89e9, 0xb98a, 0xa9ab,
      0x5844, 0x4865, 0x7806, 0x6827, 0x18c0, 0x08e1, 0x3882, 0x28a3,
      0xcb7d, 0xdb5c, 0xeb3f, 0xfb1e, 0x8bf9, 0x9bd8, 0xabbb, 0xbb9a,
      0x4a75, 0x5a54, 0x6a37, 0x7a16, 0x0af1, 0x1ad0, 0x2ab3, 0x3a92,
      0xfd2e, 0xed0f, 0xdd6c, 0xcd4d, 0xbdaa, 0xad8b, 0x9de8, 0x8dc9,
      0x7c26, 0x6c07, 0x5c64, 0x4c45, 0x3ca2, 0x2c83, 0x1ce0, 0x0cc1,
      0xef1f, 0xff3e, 0xcf5d, 0xdf7c, 0xaf9b, 0xbfba, 0x8fd9, 0x9ff8,
      0x6e17, 0x7e36, 0x4e55, 0x5e74, 0x2e93, 0x3eb2, 0x0ed1, 0x1ef0];


    return function (buf, start, len) {
      var crc = 0;
      var j, i;


      for (i = start; i < start + len; i++) {

        var c = buf[i];
        if (c > 255) {
          throw new RangeError();
        }
        j = (c ^ crc >> 8) & 0xFF;
        crc = crc16tab[j] ^ crc << 8;
      }

      return (crc ^ 0) & 0xFFFF;
    };
  }]).

  factory('binUtils', function () {
    var _byteToHex = [
      '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '0A', '0B', '0C', '0D', '0E', '0F',
      '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '1A', '1B', '1C', '1D', '1E', '1F',
      '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '2A', '2B', '2C', '2D', '2E', '2F',
      '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '3A', '3B', '3C', '3D', '3E', '3F',
      '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '4A', '4B', '4C', '4D', '4E', '4F',
      '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '5A', '5B', '5C', '5D', '5E', '5F',
      '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '6A', '6B', '6C', '6D', '6E', '6F',
      '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '7A', '7B', '7C', '7D', '7E', '7F',
      '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '8A', '8B', '8C', '8D', '8E', '8F',
      '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '9A', '9B', '9C', '9D', '9E', '9F',
      'A0', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'A7', 'A8', 'A9', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF',
      'B0', 'B1', 'B2', 'B3', 'B4', 'B5', 'B6', 'B7', 'B8', 'B9', 'BA', 'BB', 'BC', 'BD', 'BE', 'BF',
      'C0', 'C1', 'C2', 'C3', 'C4', 'C5', 'C6', 'C7', 'C8', 'C9', 'CA', 'CB', 'CC', 'CD', 'CE', 'CF',
      'D0', 'D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'DA', 'DB', 'DC', 'DD', 'DE', 'DF',
      'E0', 'E1', 'E2', 'E3', 'E4', 'E5', 'E6', 'E7', 'E8', 'E9', 'EA', 'EB', 'EC', 'ED', 'EE', 'EF',
      'F0', 'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'FA', 'FB', 'FC', 'FD', 'FE', 'FF'];


    function bytesToHex(bytes, start, end, seperator) {
      var h = "";
      if (seperator) {
        for (var i = start; i < end; i++) {
          if (h.length) {
            h += seperator;
          }
          h = h + _byteToHex[bytes[i]];
        }
      } else {
        for (var i = start; i < end; i++) {
          h = h + _byteToHex[bytes[i]];
        }
      }
      return h;
    }

    function arrayCompare(array1, start1, array2, start2, length) {
      if (array1.length < start1 + length) {
        return false;
      }

      if (array2.length < start2 + length) {
        return false;
      }

      for (var i = 0; i < length; i++) {
        if (array1[start1 + i] != array2[start2 + i]) {
          return false;
        }
      }
      return true;
    }

    function BE_readInt32(bytes, offset) {
      // Note readInt32 natively handles sign
      return (bytes[offset + 0] << 24) + (bytes[offset + 1] << 16) + (bytes[offset + 2] << 8) + bytes[offset + 3];
    }

    function BE_readUInt32(bytes, offset) {
      return BE_readInt32(bytes, offset) >>> 0; // Removes sign
    }

    function BE_readInt24(bytes, offset) {
      var i = (bytes[offset + 0] << 16) + (bytes[offset + 1] << 8) + bytes[offset + 2];
      return i & 0x800000 ? i - 0x1000000 : i;
    }

    function BE_readUInt24(bytes, offset) {
      return (bytes[offset + 0] << 16) + (bytes[offset + 1] << 8) + bytes[offset + 2];
    }

    function BE_readInt16(bytes, offset) {
      var i = (bytes[offset + 0] << 8) + bytes[offset + 1];
      return i & 0x8000 ? i - 0x10000 : i;
    }

    function BE_readUInt16(bytes, offset) {
      return (bytes[offset + 0] << 8) + bytes[offset + 1];
    }

    function readInt8(bytes, offset) {
      var b = bytes[offset];
      return b & 0x80 ? b - 0x100 : b;
    }

    function readUInt8(bytes, offset) {
      return bytes[offset];
    }


    function LE_readInt32(bytes, offset) {
      // Note readInt32 natively handles sign
      return bytes[offset + 0] + (bytes[offset + 1] << 8) + (bytes[offset + 2] << 16) + (bytes[offset + 3] << 24);
    }

    function LE_readUInt32(bytes, offset) {
      return LE_readInt32(bytes, offset) >>> 0; // Removes sign
    }

    function LE_readInt24(bytes, offset) {
      var i = bytes[offset + 0] + (bytes[offset + 1] << 8) + (bytes[offset + 2] << 16);
      return i & 0x800000 ? i - 0x1000000 : i;
    }

    function LE_readUInt24(bytes, offset) {
      return bytes[offset + 0] + (bytes[offset + 1] << 8) + (bytes[offset + 2] << 16);
    }

    function LE_readInt16(bytes, offset) {
      var i = bytes[offset + 0] + (bytes[offset + 1] << 8);
      return i & 0x8000 ? i - 0x10000 : i;
    }

    function LE_readUInt16(bytes, offset) {
      return bytes[offset + 0] + (bytes[offset + 1] << 8);
    }

    function UTF8String(bytes, offset, length) {
      var data = bytes;
      if (offset !== undefined) {
        if (length !== undefined) {
          data = bytes.slice(offset, offset + length);
        } else {
          data = bytes.slice(offset);
        }
      }
      return String.fromCharCode.apply(null, data);
    }

    function UTF8Array(str) {
      if (str == null) {
        return str;
      }
      var out = [],
        p = 0;
      for (var i = 0; i < str.length; i++) {
        var c = str.charCodeAt(i);
        if (c < 128) {
          out[p++] = c;
        } else if (c < 2048) {
          out[p++] = c >> 6 | 192;
          out[p++] = c & 63 | 128;
        } else if (
          (c & 0xFC00) == 0xD800 && i + 1 < str.length &&
          (str.charCodeAt(i + 1) & 0xFC00) == 0xDC00) {
          // Surrogate Pair
          c = 0x10000 + ((c & 0x03FF) << 10) + (str.charCodeAt(++i) & 0x03FF);
          out[p++] = c >> 18 | 240;
          out[p++] = c >> 12 & 63 | 128;
          out[p++] = c >> 6 & 63 | 128;
          out[p++] = c & 63 | 128;
        } else {
          out[p++] = c >> 12 | 224;
          out[p++] = c >> 6 & 63 | 128;
          out[p++] = c & 63 | 128;
        }
      }
      return out;
    }

    return {
      bytesToHex: bytesToHex,
      arrayCompare: arrayCompare,
      bigEndian: {
        readInt32: BE_readInt32,
        readUInt32: BE_readUInt32,
        readInt24: BE_readInt24,
        readUInt24: BE_readUInt24,
        readInt16: BE_readInt16,
        readUInt16: BE_readUInt16,
        readInt8: readInt8,
        readUInt8: readUInt8
      },

      littleEndian: {
        readInt32: LE_readInt32,
        readUInt32: LE_readUInt32,
        readInt24: LE_readInt24,
        readUInt24: LE_readUInt24,
        readInt16: LE_readInt16,
        readUInt16: LE_readUInt16,
        readInt8: readInt8,
        readUInt8: readUInt8
      },

      UTF8String: UTF8String,
      UTF8Array
    };

  })

  // This simple service is used to manage a set of "cookie timers"
  // Each Cookie Timer specifies a Cookie (whats it identified by) and timeout (when it should be called in milliseconds)
  // a callback function and its context
  // Rather than using 1 $timeout per Cookie this is managed using a single $timeout which basically
  // waits on the 'next expiring cookie'
  // Adding or removing cookies will simply reschedule
  .factory('cookieTimerSvc', ['$timeout', function ($timeout) {
    return function (scope) {
      var _cookies = Object.create(null);
      var _nextTimeout = null;

      function reschedule() {
        var next = null;
        angular.forEach(_cookies, function (record) {
          if (next == null || record.expireTime.isBefore(next.expireTime)) {
            next = record;
          }
        });

        if (_nextTimeout != null) {
          $timeout.cancel(_nextTimeout);
          _nextTimeout = null;
        }

        if (next != null) {
          _nextTimeout = $timeout(function () {
            if (_cookies != null) {
              // Remove the cookie timer, callback can re-add it if necessary
              delete _cookies[next.cookie];
              next.callback(next.context, {
                reset: function (timeout) {
                  if (moment.isMoment(timeout)) {
                    next.expireTime = timeout;
                  } else {
                    next.expireTime = moment.utc().add(timeout, 'ms');
                  }
                  _cookies[next.cookie] = next;
                  reschedule();
                }
              });

            }
          }, next.expireTime.diff(moment.utc()));
        }
      }

      var cookieTimer = {
        setTimer: function (cookie, timeout, context, callback) {
          var record = _cookies[cookie];
          if (!angular.isDefined(record)) {
            record = {
              cookie: cookie,
              callback: callback,
              context: context
            };

            _cookies[cookie] = record;
          }
          if (moment.isMoment(timeout)) {
            record.expireTime = timeout;
          } else {
            record.expireTime = moment.utc().add(timeout, 'ms');
          }
          reschedule();
        },

        clearTimer: function (cookie) {
          var record = _cookies[cookie];
          if (angular.isDefined(record)) {
            delete _cookies[cookie];
            reschedule();
          }
        },

        destroy: function () {
          if (_nextTimeout != null) {
            $timeout.cancel(_nextTimeout);
            _nextTimeout = null;
          }
          _cookies = null;
        }
      };


      if (scope) {
        scope.$on('$destroy', function () {
          cookieTimer.destroy();
          cookieTimer = null;
        });
      }

      return cookieTimer;
    };
  }]).

  factory('semver', [function () {
    function Semver(version) {
      if (version instanceof Semver) {
        version = version.version;
      } else if (Array.isArray(version)) {
        version = Array.join(version, '.');
      } else if (typeof version !== 'string') {
        version = (version || '').toString();
      }
      var parts = version.split('.').map(function (part) {
        return Number(part);
      }).filter(function (n) {
        return Number.isSafeInteger(n);
      });

      this.parts = parts;
      this.version = parts.join('.');
      this.isValid = parts.length == 3 || parts.length == 4;
    }

    Semver.prototype.compare = function (compare, digits) {
      compare = compare instanceof Semver ? compare : new Semver(compare);
      var comparePartsLength = compare.parts.length;
      var thisPartsLength = this.parts.length;
      if (typeof digits !== 'undefined') {
        comparePartsLength = comparePartsLength > digits ? digits : comparePartsLength;
        thisPartsLength = thisPartsLength > digits ? digits : thisPartsLength;
      }
      if (comparePartsLength > thisPartsLength) {
        return -compare.compare(this, digits);
      }
      for (var i = 0; i < thisPartsLength; i++) {
        var diff = this.parts[i] - (i >= compare.parts.length ? 0 : compare.parts[i]);
        if (diff !== 0) {
          return diff;
        }
      }
      return 0;
    };

    Semver.prototype.isSame = function (compare) {
      return this.compare(compare) === 0;
    };

    Semver.prototype.isCompatibleOrLater = function (compare) {
      return this.compare(compare, 2) >= 0; // Major, minor must match to be compatible versions
    };

    return function (version) {
      return new Semver(version);
    };
  }]);