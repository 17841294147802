'use strict';

import mod from './ngmodule';

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context('./', true, /\.js$/));

export default mod;