'use strict';

import app from '../../ngmodule';
import './style.scss';

app.component('questionTasklistTaskTile', {

  template: require('./template.html').default,
  bindings: {
    'task': '<',
    'presented': '<'
  },

  controller: ["TaskListUserInterfaceType", "webServiceUrl", function (TaskListUserInterfaceType, webServiceUrl) {
    this.UserInterfaceType = TaskListUserInterfaceType;
    this.url = webServiceUrl;
  }]
});