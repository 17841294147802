import { IWorkingDocumentFinishParameters } from "../checklist-player/offline-checklist-player";

export interface IWorkingDocument {
    workingDocumentId: string;
}

export interface IPublishedResourceCompoundId {
    id?: number;
    groupId?: number;
    resourceId?: number;
    compoundName?: string;
}

export type IBeginChecklistResult = any;
export type ISaveChecklistResult = any;
export type INextChecklistResult = any;
export type IPrevChecklistResult = any;
export type IContinueChecklistResult = any;

export interface IAnswerModel {
    [presentedId: string]: any;
}

export interface IChecklistPlayer {
    hasWorkingDocument(workingDocumentId: string): Promise<boolean>;
    hasChecklist(publishedResourceId: IPublishedResourceCompoundId): Promise<boolean>;

    beginChecklist(publishedResourceId: IPublishedResourceCompoundId, revieweeId: string, args: any): Promise<IBeginChecklistResult>;
    saveChecklist(workingDocumentId: string, answerModel: IAnswerModel): Promise<ISaveChecklistResult>;

    nextChecklist(workingDocumentId: string, answerModel: IAnswerModel, useOfflineQueue: boolean): Promise<INextChecklistResult>
    prevChecklist(workingDocumentId: string): Promise<IPrevChecklistResult>;

    continueChecklist(workingDocumentId: string): Promise<IContinueChecklistResult>;
}

export interface IChecklistPlayerQ {
    hasWorkingDocument(workingDocumentId: string): ng.IPromise<boolean>;
    hasChecklist(publishedResourceId: IPublishedResourceCompoundId): ng.IPromise<boolean>;

    beginChecklist(publishedResourceId: IPublishedResourceCompoundId, revieweeId: string, args: any): ng.IPromise<IBeginChecklistResult>;
    saveChecklist(workingDocumentId: string, answerModel: IAnswerModel): ng.IPromise<ISaveChecklistResult>;

    nextChecklist(workingDocumentId: string, answerModel: IAnswerModel, useOfflineQueue: boolean): ng.IPromise<INextChecklistResult>
    prevChecklist(workingDocumentId: string): ng.IPromise<IPrevChecklistResult>;

    continueChecklist(workingDocumentId: string): ng.IPromise<IContinueChecklistResult>;
}

export abstract class ExecutionQueue {
    execute(method: string, ...args: any[]): ng.IPromise<any> {
        let fn: (...args: any[]) => ng.IPromise<any> = (this as any)[method];
        if (fn == null) {
            throw new Error(`Method ${method} not implemented`);
        }
        return fn(...args);
    }
}

export interface IOfflineChecklistExecutionQueue {
    finish(parameters: IWorkingDocumentFinishParameters): ng.IPromise<any>;
}
