'use strict';

import angular from 'angular';
import { ControllerBase } from './control-base';

export abstract class ControllerWithPromptBase extends ControllerBase {
    public prompt?: string;
    public promptAsHtml?: string;

    constructor(protected $sce: ng.ISCEService) {
        super();

    }

    $onInit(): void {
        super.$onInit();

        if (angular.isDefined(this.prompt) == false) {
            this.prompt = '';
        }

        if (angular.isString(this.prompt)) {
            this.promptAsHtml = this.$sce.trustAsHtml(this.prompt);
        }
        else {
            this.promptAsHtml = this.prompt;
        }
    }
}