'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';
import temperatureProbeManager from '../../device-drivers/temperature-probe-manager';
import './select-probe-dialog';
import './style.scss';
import { debugFunc } from '../../utils/debug-func';

const log = debugFunc(`question-temperature-probe`);

questionsModule.directive('questionTemperatureProbe', [
  'temperature-probe-manager',
  '$filter',
  'persistantStorage',
  'serverBasedUserSettings',
  'bworkflowApi',
  'cookieTimerSvc',
  'languageTranslate',
  'temperatureConvert',
  'vmModalProvider',
  '$timeout',
  function (
    temperatureProbeManager,
    $filter,
    persistantStorage,
    serverBasedUserSettings,
    bworkflowApi,
    cookieTimerSvc,
    languageTranslate,
    temperatureConvert,
    vmModalProvider,
    $timeout
  ) {
    var TEMPERATURE_PROBE_LAST_SERIAL_NUMBER = 'TemperatureProbe.LastSerialNumber';
    var temperatureReadingLookups = {
      'Realtime': 'temperature',
      'MaximumReached': 'maxTemperature'
    };


    return $.extend({},
      questionDirectiveBase, {
      scope: {
        presented: '=',
        pageScope: '=',
        testProbe: '='
      },

      template: require('./template.html').default,
      link: function (scope, elt, attrs) {
        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

        var _cookieTimer = cookieTimerSvc(scope);

        scope.answer = scope.presented.Answer;
        scope.showSelectProbe = false;
        scope.onDestroy = [];

        scope.selectProbeDialogDelegate = {
          filterProbes: filterProbes,
          close: () => {
            vmModalProvider.get('select-temperature-probe-modal')?.close()
          }
        }

        var temperatureProbe = scope.testProbe || temperatureProbeManager;
        function getProbeReading(probe) {
          if (!probe) {
            probe = temperatureProbe.probe;
          }
          if (probe) {
            return probe.readings[temperatureReadingLookups[scope.presented.TemperatureReading] || 'temperature'];
          }
        }

        function getProbeReadingValue(probe) {
          var reading = getProbeReading(probe);
          if (reading != null) {
            return reading.value;
          }
        }

        // this supports validation as each question type stores it's answer in a different way
        scope.getAnswerValue = function () {
          return scope.presented.Answer.Celcius;
        };

        if (angular.isDefined(temperatureProbe.supported) == true && temperatureProbe.supported == false) {
          scope.supported = false;
          return;
        }
        scope.supported = true;

        // Register ourselves as a temperature client 
        scope.onDestroy.push(temperatureProbe.addClient(scope));

        scope.degrees = function (reading) {
          if (reading == null || angular.isUndefined(reading)) {
            return '';
          }

          if (scope.presented.DisplayAsFarenheit) {
            reading = temperatureConvert.celsius.toFarenheit(reading);
          }
          var result = $filter('number')(reading, 2);
          result += '\xB0'; // &deg;
          result += scope.presented.DisplayAsFarenheit ? 'F' : 'C';

          return result;
        };

        var getReadingPassStatus = function (celcius) {
          if (celcius == null || angular.isUndefined(celcius)) {
            return 3;
          }
          // min/max are always Celcius
          if (celcius > scope.presented.MaximumPassReading) {
            return 2;
          } else if (celcius < scope.presented.MinimumPassReading) {
            return 1;
          }
          return 0;
        };

        scope.setReading = function (celcius) {
          scope.reading = celcius;
          scope.readingStatus = getReadingPassStatus(celcius);
        };

        scope.record = function (celcius) {
          var reading = getProbeReading();
          scope.answer.Celcius = celcius || reading && reading.value;
          scope.answer.ExtraValues = {
            RawCelcius: reading && reading.rawValue,
            ProbeSerialNumber: reading && reading.serialNumber
          };

          scope.recordedStatus = getReadingPassStatus(scope.answer.Celcius);
        };

        scope.clearRecorded = function () {
          scope.answer.Celcius = null;
          scope.recordedStatus = null;
        };

        if (scope.presented.Answer && angular.isDefined(scope.presented.Answer.Celcius) && scope.presented.Answer.Celcius != null) {
          scope.record(scope.presented.Answer.Celcius);
        }

        scope.tp = temperatureProbe;
        scope.battery = temperatureProbe.battery;

        temperatureProbe.startMonitor(scope.presented.RefreshPeriod);

        scope.allowedProbeProviders = scope.presented.AllowedProbeProviders ? scope.presented.AllowedProbeProviders.split(',') : null;
        scope.allowedProbeSerialNumbers = scope.presented.AllowedProbeSerialNumbers ? scope.presented.AllowedProbeSerialNumbers.split(',') : null;

        // Check if there is a currently active probe and whether its allowed on this question ..
        if (temperatureProbe.probe && scope.allowedProbeSerialNumbers) {
          var allowedProbe = scope.allowedProbeSerialNumbers.find(function (serialNumber) {
            return temperatureProbe.probe.serialNumber == serialNumber;
          });

          if (!allowedProbe) {
            temperatureProbe.activeProbe(null);
          }
        }

        function resetOutOfRangeTimeout() {
          var probe = temperatureProbe.probe;
          if (probe && scope.presented.ProbeOutOfRangeTimeout) {
            _cookieTimer.setTimer(probe, scope.presented.ProbeOutOfRangeTimeout * 1000, null, function () {
              // probe has not reported - remove it, it is out of range
              probe.remove();
            });
          }
        }

        function onProbeConnect(probe) {
          if (probe) {
            temperatureProbe.startMonitor(scope.presented.RefreshPeriod);
            probe.getSerialNumber().then(function (serialNumber) {
              persistantStorage.setItem(TEMPERATURE_PROBE_LAST_SERIAL_NUMBER, serialNumber);
            });

            resetOutOfRangeTimeout();
          }
        };

        scope.isActiveProbe = function (probe) {
          return probe === temperatureProbe.probe;
        };

        scope.connect = function (probe) {
          temperatureProbe.activeProbe(probe);
        };

        function filterProbes(probes) {
          var filtered = [];
          angular.forEach(probes, function (probe) {
            if (scope.allowedProbeProviders != null && scope.allowedProbeProviders.indexOf(probe.provider.id) < 0) {
              return;
            }
            if (scope.allowedProbeSerialNumbers != null && scope.allowedProbeSerialNumbers.indexOf(probe.serialNumber) < 0) {
              return;
            }
            if (scope.presented.ProbeOutOfRangeTimeout && moment.duration(moment.utc().diff(getProbeReading(probe).updateTimestamp)).asSeconds() > scope.presented.ProbeOutOfRangeTimeout) {
              // last reading is too old, probe is out of range
              return;
            }
            filtered.push(probe);
          });
          return filtered;
        }

        scope.selectProbe = function (ev) {
          $timeout(() => {
            scope.showSelectProbe = vmModalProvider.get('select-temperature-probe-modal').show();

            /*{
            targetEvent: ev,
            locals: {
              temperatureProbe: temperatureProbe,
              filterProbes: filterProbes,
              presented: scope.presented
            }
          })).


            then(function (probe) {
              scope.connect(probe);
              delete scope.showSelectProbe;
            }, function () {
              delete scope.showSelectProbe;
            });
            */
          });
        };

        scope.$on('$destroy', function () {
          scope.onDestroy.forEach(function (fn) {
            if (angular.isFunction(fn)) {
              fn();
            }
          });
          temperatureProbe.shutdown(scope.presented.DisconnectProbeTimeout);
        });

        scope.probes = filterProbes(temperatureProbe.probes);

        scope.onDestroy.push(temperatureProbe.subscribe(function (event) {
          log.debug('event ' + event.name);
          switch (event.name) {
            case 'updated':
              scope.setReading(getProbeReadingValue());
              resetOutOfRangeTimeout();
              break;

            case 'button':
              if (!scope.showSelectProbe && temperatureProbe.probe === event.probe) {
                event.probe.measure().then(scope.record);
              }
              break;

            case 'existing':
            case 'added':
              if (scope.showSelectProbe) // if showing the dialog then user must manually select
                return;
              scope.probes = filterProbes(temperatureProbe.probes);
              if (!temperatureProbe.activeProbe() && !temperatureProbe.connectingProbe) {
                if (scope.allowedProbeSerialNumbers && scope.allowedProbeSerialNumbers.length == 1) {
                  // We are only allowed to connect to a specific probe, last serial does not matter
                  if (scope.probes.length == 1) {
                    scope.connect(event.probe);
                  }
                } else {
                  persistantStorage.getItem(TEMPERATURE_PROBE_LAST_SERIAL_NUMBER, function (lastProbeSerialNumber) {
                    if (lastProbeSerialNumber) {
                      event.probe.getSerialNumber().then(function (serialNumber) {
                        if (serialNumber == lastProbeSerialNumber) {
                          if (!temperatureProbe.activeProbe() && !temperatureProbe.connectingProbe) {// Double check not connected
                            scope.connect(event.probe);
                          }
                        }
                      });
                    }
                  });
                }
              }
              break;

            case 'removed':
              scope.probes = filterProbes(temperatureProbe.probes);
              break;

            case 'probes':
              scope.probes = filterProbes(event.probes);
              if (temperatureProbe.activeProbe()) {
                return;
              }
              if (scope.showSelectProbe)
                return;

              if (scope.allowedProbeSerialNumbers && scope.allowedProbeSerialNumbers.length == 1) {
                if (scope.probes.length == 1) {
                  scope.connect(scope.probes[0]);
                }
              } else {
                persistantStorage.getItem(TEMPERATURE_PROBE_LAST_SERIAL_NUMBER, function (lastProbeSerialNumber) {
                  if (lastProbeSerialNumber) {
                    var probe = scope.probes.find(function (probe) {
                      return probe.serialNumber == lastProbeSerialNumber;
                    });
                    if (probe) {
                      scope.connect(probe);
                    }
                  } else if (!scope.showSelectProbe) {
                    scope.selectProbe();
                  }
                });
              }
              break;

            case 'connected':
              temperatureProbe.stopScan();
              onProbeConnect(event.probe);
              break;

            case 'disconnected':
              temperatureProbe.startScan();
              break;
          }

        }));

        // Scan for probes at until we connect to a probe
        temperatureProbe.startScan();

      }
    });

  }]);