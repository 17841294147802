'use strict';

import app from '../ngmodule';
import angular from 'angular';

app.factory('tabletProfile', ['persistantStorage', function (persistantStorage) {
  return function (success) {
    return persistantStorage.onChange('TabletProfile', function (profile) {
      success(angular.isString(profile) ? JSON.parse(profile) : profile || {});
    });
  };
}]);