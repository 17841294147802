'use strict';

import './index.scss';
import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { Chart, registerables } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { v4 as uuidv4 } from 'uuid';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

class ChartController extends ControllerBase {
    public class: string;
    public chartData: any;
    public width: string;
    public height: string;

    protected ctx?: HTMLCanvasElement;
    protected chart?: Chart;

    protected firstShow: boolean;

    // these are constructed from the color.adobe.com site using 2 of the blues from the VM logo as a starting color
    protected defaultColors: Array<string> = [
        '#00406B',
        '#19A3FF',
        '#006DB7',
        '#6B3F00',
        '#B86C00',
        '#0C3AED',
        '#ED4818',
        '#00ADEE',
        '#EDB518',
        '#0CEDAE'
    ];

    static $inject = ['$element', '$scope', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $scope: ng.IScope, protected $timeout: ng.ITimeoutService) {
        super();
        this.class = '';

        this.width = "400px";
        this.height = "400px";
        this.firstShow = false;
    }

    $onInit() {
        super.$onInit();

        this.ctx = (this.$element.find("div canvas") as unknown) as HTMLCanvasElement;

        // hook into the message from the tab control that get's sent when there is a tab selection, this can only
        // be intercepted by a control that's contained in the tab
        this.$scope.$on('vm-tab-pane-control.show', (evt, args) => {
            this.$timeout(() => {
                if(this.chart && this.firstShow == false)
                {
                    this.unRenderChart();
                    this.renderChart();

                    this.firstShow = true;
                }
            });
        });

        if(this.chartData)
        {
            this.renderChart();
        }
    }

    $onDestroy(): void {
        this.unRenderChart();
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        let reRender: boolean = false;

        if (changes.class) {
            this.class = changes.class.currentValue;
            reRender = true;
        }
        if (changes.chartData) {
            this.chartData = changes.chartData.currentValue;

            this.manageColors();

            reRender = true;
        }

        if (changes.width) {
            this.width = changes.width.currentValue;

            reRender = true;
        }

        if (changes.height) {
            this.height = changes.height.currentValue;

            reRender = true;
        }

        if(reRender)
        {
            this.unRenderChart();
            this.renderChart();
        }
    }

    renderChart(): void {
        if (this.chartData == null || !this.ctx) {
            return;
        }

        if(!this.width)
        {
            this.width = "400px";
        }

        if(!this.height)
        {
            this.height = "400px";
        }

        this.chart = new Chart(this.ctx, this.chartData);
    }

    unRenderChart(): void {
        if (!this.chart) {
            return;
        }

        this.chart.destroy();
        this.chart = undefined;
    }

    protected manageColors() : void {
        if(!this.chartData || !this.chartData.data || !this.chartData.data.datasets)
        {
            return;
        }

        for(var d of this.chartData.data.datasets)
        {
            d.backgroundColor = this.mergeColors(d.backgroundColor);
        }
    }

    protected mergeColors(customColors: Array<string>) : Array<string> {
        let result = [...this.defaultColors];

        if(customColors)
        {
            result = [...customColors, ...this.defaultColors];
        }

        return result;
    }
}

app.component('vmChart', {
    template: require('./template.html').default,
    bindings: {
        class: '<',
        chartData: '<',
        width: '<',
        height: '<'
    },
    controller: ChartController
});