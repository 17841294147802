'use strict';

import './index.scss';

import app from '../../ngmodule';
import angular from 'angular';
import { randomString } from '../../../utils/util';

app.controller('playCtrl', ['$scope', '$rootScope', '$state', 'transitionCache', 'animationManager', '$timeout', '$compile', '$window', 'ChecklistState',
  function ($scope, $rootScope, $state, transitionCache, animationManager, $timeout, $compile, $window, ChecklistState) {

    $scope.getFullServerPath = function (path) {
      var trimP = path;
      if (trimP[0] == '/')
        trimP = trimP.substring(1);
      var trimPrefix = $window.razordata.apiprefix;
      if (trimPrefix[trimPrefix.length - 1] == '/')
        trimPrefix = trimPrefix.substring(0, trimPrefix.length - 1);
      return trimPrefix + '/' + path;
    };

    $scope.$on('doSlideModal', function (ev, template) {
      var smr = $('.slide-modal-wrapper');
      var includeEl = $('<div ng-include="\'' + template + '\'"></div>');
      var compiled = $compile(includeEl)(ev.targetScope);
      $(smr).append(compiled);
      $timeout(function () {
        $('.slide-modal-root').hide('slide', {
          direction: 'left'
        },
          function () {
            $('.slide-modal-wrapper').show('slide', {
              direction: 'right'
            });

          });

      });

    });
    $scope.$on('unSlideModal', function (ev) {
      $('.slide-modal-wrapper').hide('slide', {
        direction: 'right'
      },
        function () {
          $('.slide-modal-wrapper').empty();
          $('.slide-modal-root').show('slide', {
            direction: 'left'
          });

        });
    });

    $scope.$on('player_broadcast', function (event, args) {
      if (args.__handled) {
        return;
      }
      // this event gets raised by questions when they want to notify other questions that something has changed.
      // typically this occurrs in a dashboard. We need to broadcast this back down the scopes to let others know
      // of the change. The event object created by who ever created the event should be of the form

      // args.name = name of event to broadcast out
      // args.data = object containing arguments relevant to the event
      $scope.$broadcast(args.name, args.data);
      args.__handled = true;
    });

    $scope.$on('player_broadcast_ajax_error', function (event, args) {
      // this allows code to handle the ajax errors themselves and then
      // to also broadcast it out and have it handled by the normal code as well.
      // An example of this is the odata feeds, which add the noUI flag to the object
      // so its logged on the server, but nothing is shown to the user
      $scope.$broadcast('ajax.error', args);
    });

    $scope.transitionWithPlayerModel = function (r, slideDir, noncearg) {
      if (typeof slideDir === 'undefined')
        slideDir = 'right';

      var wdid = r.WorkingDocumentId;
      transitionCache.addItem(r, wdid);

      var nonce = noncearg || randomString(7);
      $rootScope.nonceStack.push(nonce);

      if (r.State == 'Error') {
        animationManager.setDirection(slideDir);
        $state.go('root.play.present.error', {
          workingDocumentId: wdid,
          nonce: nonce
        });

      } else if (r.State == 'Presenting') {
        $rootScope.bodyClass = 'player';
        animationManager.setDirection(slideDir);
        $state.go('root.play.present.presentable', {
          workingDocumentId: wdid,
          nonce: nonce
        });

      } else if (r.State == 'Finishing') {
        animationManager.setDirection(slideDir);
        $state.go('root.play.present.finishing', {
          workingDocumentId: wdid,
          nonce: nonce
        });

      } else if (r.State == 'Finished') {
        if (r.Presented) {
          animationManager.setDirection(slideDir);
          $state.go('root.play.present.presentable', {
            workingDocumentId: wdid,
            nonce: nonce
          });

        } else {
          animationManager.setDirection(slideDir);
          $state.go('root.play.present.finished', {
            workingDocumentId: wdid,
            nonce: nonce
          });

        }
      }
    };
  }]);