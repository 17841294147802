import { ITokenAuthService } from "./auth-svc";
import { IHttpService } from "angular";
import { debugFunc } from "../../utils/debug-func";
//import { sleep } from '../../../utils/sleep';
import app from '../ngmodule';

const log = debugFunc(`AuthHttpTokenService`);

export class AuthHttpTokenService implements ng.IHttpInterceptor {
  private authSvc?: ITokenAuthService;

  static $inject = [
    `$q`,
    `$injector`
  ];
  constructor(
    private $q: ng.IQService,
    private $injector: ng.auto.IInjectorService
  ) {
  }

  request = (config: ng.IRequestConfig): ng.IRequestConfig | ng.IPromise<ng.IRequestConfig> => {
    // we are only going to adjust the authorization if there isn't something already there
    if (config.headers && config.headers.Authorization) {
      return config;
    }

    this.authSvc = this.authSvc || this.$injector.get<ITokenAuthService>('authSvc');
    return this.authSvc.getAccessToken().then(accessToken => {
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    });
  }

  responseError = <T>(rejection: any): ng.IPromise<ng.IHttpResponse<T>> | ng.IHttpResponse<T> => {
    this.authSvc = this.authSvc || this.$injector.get<ITokenAuthService>('authSvc');
    if (rejection.status === 401 && this.authSvc != null) {
      log.info('Unauthorised');

      return new Promise((resolve, reject) => {
        this.authSvc?.isAuthenticated().then(authenticated => {
          if (authenticated) {
            // We are logged in, but not Authorised for this resource ..
            reject(rejection);
          } else {
            log.info('Login - Attemping Login');
            //sleep(5000).then(resolve);

            // Ok, not authorised, lets do it ..
            return this.authSvc?.login().then(user => {
              // we have to late inject $http 
              log.info('Login - User returned');
              let $http = this.$injector.get<IHttpService>('$http');
              resolve($http(rejection.config));
            }).catch(reject);
          }
        });
      });
    }

    // Not auth error ..
    return this.$q.reject(rejection);
  }
}

export function useAuthHttpTokenService() {
  app.factory(`auth-http-svc`, AuthHttpTokenService);

  app.config([
    '$httpProvider',
    function ($httpProvider: ng.IHttpProvider) {
      $httpProvider.interceptors.push('auth-http-svc');
      $httpProvider.defaults.withCredentials = true;
    }
  ]);
}
