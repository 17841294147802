import { IWebServiceUrl } from "../../../../../common/web-service-url";
import { FacilityType, IGlobalSetting, IGlobalSettingsFacility, IGlobalSettingsFacilityDefinition, OData } from 'lingo-api';
import { ODataBuilder } from "../../../../odata-builder";
import { ReadOnlyODataFacility } from "../read-only-odata-facility";

export class GlobalSettingsFacility extends ReadOnlyODataFacility<number, IGlobalSetting, OData.Offline.GlobalSetting> implements IGlobalSettingsFacility {
	readonly facilityType: FacilityType = `globalSettings`;

	static $inject = [
		`definition`,
		`webServiceUrl`,
		`$http`];
	constructor(
		private definition: IGlobalSettingsFacilityDefinition,
	) {
		super(`GlobalSettings`, {
			uniqueId: r => r.id.toString()
		});
	}

	mapRecord(odata: OData.Offline.GlobalSetting): IGlobalSetting {
		return odata;
	}

	async getString(name: string): Promise<string | undefined> {
		const settings = (await this.getOfflineCache().get()) || [];
		const setting = settings.find(setting => setting.name == name);
		if (setting == null) {
			return undefined;
		}
		return setting.value;
	}

	async getJson<T = any>(name: string): Promise<T | undefined> {
		const settings = (await this.getOfflineCache().get()) || [];
		const setting = settings.find(setting => setting.name == name);
		if (setting == null) {
			return undefined;
		}
		return JSON.parse(setting.value) as T;
	}
}
