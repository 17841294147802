'use strict';

import angular from 'angular';

var animationDirectivesModule = angular.module('animationDirectives', ['ui.router.router', 'ui.router.compat', 'ui.router.state']);
export default animationDirectivesModule;

animationDirectivesModule.directive('slideInOut', ['$timeout', '$parse', '$state', 'animationManager',
  function ($timeout, $parse, $state, animationManager) {
    return {
      compile: function (elt, attrs, transclude) {
        $(elt).hide();

        //Return postlink
        return function (scope, elt, attrs) {
          //let's get the stack of states
          var stateOrder = $parse(attrs['stateOrder'])(scope);
          var slideChooseMethod = attrs['slideInOut'] || 'stateOrder';

          //util for figuring out what direction to slide out in
          var slideOutDirection = function (toState, fromState) {
            var srcIndex = $.inArray(fromState.name, stateOrder);
            var dstIndex = $.inArray(toState.name, stateOrder);

            if (srcIndex > dstIndex)
              return 'right';
            else
              return 'left';
          };

          //Handle leaving
          //we ignore any transition where toParams does not have a slideDirection
          //Prevents conflicts with preventing back-button transitions in a controller
          scope.$on('$stateChangeStart', function (ev, toState, toParams, fromState, fromParams) {
            if (!toParams.slideDirection && slideChooseMethod == 'stateParams')
              return;

            if (slideChooseMethod == 'animationManager' && animationManager.direction == 'none')
              return;
            if (slideChooseMethod == 'animationManager' && !animationManager.direction)
              return; //unplanned

            if (!scope.haveSuppressedTransitionAlready) {
              //If we don't check that, we get nasty infinite loop
              scope.haveSuppressedTransitionAlready = true;

              var slideDirect;
              if (slideChooseMethod == 'stateOrder')
                slideDirect = slideOutDirection(toState, fromState); else
                if (slideChooseMethod == 'animationManager')
                  slideDirect = animationManager.direction == 'left' ? 'right' : 'left'; else
                  if (slideChooseMethod == 'stateParams')
                    slideDirect = toParams.slideDirection == 'left' ? 'right' : 'left';
              ev.preventDefault(); //do the transition on OUR terms
              $(elt).hide('slide', {
                direction: slideDirect
              },
                'normal', function () {
                  //now actually do the transition
                  $timeout(() => {
                    scope.$apply(function () {
                      $state.transitionTo(toState, toParams);
                    });
                  });
                });
            }
          });

          //Handle entering
          scope.$on('$stateChangeSuccess', function (ev, toState, toParams, fromState, fromParams) {
            if (!toParams.slideDirection && slideChooseMethod == 'stateParams')
              $(elt).show(); //just show it if they don't want us to slide
            if (slideChooseMethod == 'animationManager' && animationManager.direction == 'none')
              $(elt).show();


            var slideDirect;
            if (slideChooseMethod == 'stateOrder')
              slideDirect = slideOutDirection(fromState, toState); //flip args for other direction
            else if (slideChooseMethod == 'animationManager')
              slideDirect = animationManager.direction || 'right'; else
              if (slideChooseMethod == 'stateParams')
                slideDirect = toParams.slideDirection || 'right';

            animationManager.direction = null;

            $timeout(function () {//makes it wati after any ng-repeat
              $(elt).show('slide', {
                direction: slideDirect
              },
                'normal');
            });
          });
        };
      }
    };

  }]);


animationDirectivesModule.factory('animationManager', [function () {

  var service = {
    direction: 'none',
    setDirection: function (dir) {
      this.direction = dir;
    }
  };


  return service;

}]);