'use strict';

import app from '../../ngmodule';
import angular from 'angular';

app.controller('errorCtrl', [
  '$scope', 'transitionCache', 'bworkflowApi', '$state', '$q', '$stateParams',
  function ($scope, transitionCache, bworkflowApi, $state, $q, $stateParams) {
    $scope.models.playerModel = transitionCache.getAndDelete($stateParams.workingDocumentId);
    $scope.buttonStates.canPrevious = false;
    $scope.buttonStates.canNext = false;
    $scope.pm = $scope.models.playerModel;
  }]);