'use strict';

import app from '../ngmodule';
import { ControllerWithValidationBase } from '../control-with-validation-base';
import angular from 'angular';
import { IValidationService } from '../../services/validator-service';
import $ from "jquery";
import "kendo-ui-core";

const Enter_Keycode: number = 13;

class NumericTextBoxController extends ControllerWithValidationBase {
    public onUpdate?: null | ((args: any) => void) = null;
    public value?: number;
    public disabled: boolean;
    public readOnly: boolean;
    public format?: string; // see the kendo ui number format documentation for info on this 
    public round?: boolean;
    public restrictDecimals?: boolean;
    public decimals?: number;
    public min?: number;
    public max?: number;

    protected kendoNumeric?: kendo.ui.NumericTextBox;

    static $inject = ['$element', '$sce', 'vmValidationService', '$timeout', '$compile', '$scope'];
    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, validationService: IValidationService, protected $timeout: ng.ITimeoutService, protected $compile: ng.ICompileService, protected $scope: ng.IScope) {
        super($element, $sce, validationService);

        this.disabled = false;
        this.readOnly = false;
    }

    $onInit() {
        super.$onInit();

        if(this.round === undefined)
        {
            this.round = this.valueFromHtml('round', undefined, this.$element);
        }
        if(this.decimals === undefined)
        {
            this.decimals = this.valueFromHtml('decimals', undefined, this.$element);
        }
        if(this.min === undefined)
        {
            this.min = this.valueFromHtml('min', undefined, this.$element);
        }
        if(this.max === undefined)
        {
            this.max = this.valueFromHtml('max', undefined, this.$element);
        }
        if(this.format === undefined)
        {
            this.format = this.valueFromHtml('format', this.decimals === undefined ? 'n' : `n${this.decimals}`, this.$element);
        }        

        this.$timeout(() => {
            this.$element.find('input').kendoNumericTextBox({
                format: this.format,
                round: this.round,
                restrictDecimals: this.decimals != undefined,
                decimals: this.decimals,
                min: this.min,
                max: this.max,
                label: {
                    content: this.prompt,
                    floating: true
                }
            });

            // the code to find the kendo numeric code is a pain as there are multiple input elements :(
            for(let i of this.$element.find('input.k-input-inner'))
            {
                this.kendoNumeric = $(i).data("kendoNumericTextBox");

                if(this.kendoNumeric)
                {
                    break;
                }
            }

            if(this.disabled)
            {
                this.kendoNumeric?.enable(!this.disabled);
            }

            if(this.readOnly)
            {
                this.kendoNumeric?.enable(!this.readOnly);
            }
        });
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        super.$onChanges(changes);

        if (changes.value && changes.value.currentValue) {
            this.value = changes.value.currentValue;
        }

        if(changes.disabled && changes.disabled.currentValue != undefined && this.kendoNumeric) {
            this.kendoNumeric.enable(!changes.disabled.currentValue);
        }

        if(changes.readOnly && changes.readOnly.currentValue != undefined && this.kendoNumeric) {
            this.kendoNumeric.readonly(!changes.readOnly.currentValue);
        } 

    }

    changed(): void {
        this.validate(this.value);

        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { value: this.value, control: this } });
    }
}

app.component('vmNumericTextbox', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after'
    },
    bindings: {
        value: '=',
        prompt: '<',
        format: '<',
        round: '<',
        decimals: '<',
        min: '<',
        max: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        validator: '=',
        id: '<',
        disabled: '<',
        readOnly: '<',
        onUpdate: '&'
    },
    controller: NumericTextBoxController
});