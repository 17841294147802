'use strict';

import angular from 'angular';
import questionsModule from './ngmodule';

questionsModule.directive('bwkStyle', ['$sce', function ($sce) {
  return {
    link: function (scope, elt, attrs) {
      var styleText = attrs.bwkStyle;
      $(elt).attr('style', styleText);
    }
  };

}]);