'use strict';

import angular from 'angular';
import questionDirectiveBase from '../question-directive-base';
import questionsModule from '../ngmodule';
import moment from 'moment';

questionsModule.directive('questionDatasource', ['$timeout',
  'bworkflowApi',
  '$http',
  '$interval',
  '$sce',
  '$filter',
  '$interpolate',
  '$q',
  'languageTranslate',
  function ($timeout, bworkflowApi, $http, $interval, $sce, $filter, $interpolate, $q, languageTranslate) {
    return $.extend({}, questionDirectiveBase, {
      template: require('./template.html').default,
      link: function (scope, elt, attrs) {
        scope.feedManagers = {};

        // Expose feedManagers on pageScope under name of this DataSource (eg DS.Tasks exposed as pageScope.DS.Tasks)
        questionDirectiveBase.link(scope, scope.feedManagers, bworkflowApi, languageTranslate);

        //some utility methods to support sensible default values for date parameters

        scope.adjustDate = function (date, amount, units) {
          return moment(date).add(amount, units).toDate();
        };

        // current time
        scope.now = function () {
          return new Date();
        };

        // current day
        scope.today = function () {
          var now = scope.now();

          return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        };

        scope.tomorrow = function () {
          var today = scope.today();

          return moment(today).add(1, 'days').toDate();
        };

        scope.yesterday = function () {
          var today = scope.today();

          return moment(today).subtract(1, 'days').toDate();
        };

        scope.startOfWeek = function () {
          var today = scope.today();

          return moment(today).startOf('week').toDate();
        };

        scope.endOfWeek = function () {
          var today = scope.today();

          return moment(today).endOf('week').toDate();
        };

        scope.startOfMonth = function () {
          var today = scope.today();

          return moment(today).startOf('month').toDate();
        };

        scope.endOfMonth = function () {
          var today = scope.today();

          return moment(today).endOf('month').toDate();
        };

        scope.constructFeeds = function () {
          angular.forEach(scope.presented.Feeds, function (value, index) {
            var feedManager = scope.constructFeed(value);

            scope.feedManagers[feedManager.template.name] = feedManager;

            bworkflowApi.registerDataFeed(feedManager);
          });

          // first hit
          scope.checkUpdatesRequired();

          // now start the periodic refresh
          scope.startRefreshes();
        };

        scope.constructFeed = function (feed) {
          return bworkflowApi.createDataFeed(feed, scope);
        };

        scope.checkUpdatesRequired = function () {
          if (angular.isDefined(scope.feedManagers) == false) {
            return;
          }

          angular.forEach(scope.feedManagers, function (value, key) {
            if (value.countdown == null || value.countdown == "" || value.countdown == -1) {
              return;
            }

            if (value.isAjaxing == true) {
              return; // its already doing its thing
            }

            value.countdown = value.countdown - 1;

            if (value.countdown == 0) {
              value.getData(false, {
                refresh: true
              });

            }
          });
        };

        scope.startRefreshes = function () {
          if (angular.isDefined(scope.stop)) return;

          scope.stop = $interval(function () {
            scope.checkUpdatesRequired();
          }, 1000);
        };

        scope.stopRefreshes = function () {
          if (angular.isDefined(scope.stop)) {
            $interval.cancel(scope.stop);
            scope.stop = undefined;
          }
        };

        scope.$on('$destroy', function () {
          // Make sure that the interval is destroyed too
          scope.stopRefreshes();
        });

        scope.constructFeeds();
      }
    });

  }]);