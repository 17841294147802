export interface DebugFuncConfig {
	info: boolean;
	debug: boolean;
	error: boolean;
	trace: boolean;
	warn: boolean;
}

type DebugFuncConfigs = { [func: string]: DebugFuncConfig };
let debugFuncConfigs: DebugFuncConfigs = {};

let saved = window.localStorage.getItem(`vmplayer-debug-func-config`);
if (saved) {
	debugFuncConfigs = JSON.parse(saved);
}

function debugFuncConfigSave() {
	window.localStorage.setItem(`vmplayer-debug-func-config`, JSON.stringify(debugFuncConfigs));
}

function debugFuncConfig(func: string) {
	let config = debugFuncConfigs[func];
	if (config == null) {
		config = {
			info: true,
			debug: false,
			error: true,
			trace: false,
			warn: false
		}
		debugFuncConfigs[func] = config;
	}
	return config;
}

// Give access to above func globally
export interface DebugFunc {
	info: (str: string) => void;
	debug: (str: string) => void;
	error: (err: any) => void;
	trace: (str: string) => void;
	warn: (str: string) => void;
}

export function debugFunc(func: string): DebugFunc {
	const config = debugFuncConfig(func);
	return {
		info: (str: string) => {
			if (config.info) {
				console.log(`${func} - ${str}`);
			}
		},
		debug: (str: string) => {
			if (config.debug) {
				console.debug(`${func} - ${str}`);
			}
		},
		error: (err: any) => {
			if (config.error) {
				console.error(`${func} - ${err}`);
			}
		},
		trace: (str: string) => {
			if (config.trace) {
				console.trace(`${func} - ${str}`);
			}
		},
		warn: (str: string) => {
			if (config.warn) {
				console.warn(`${func} - ${str}`);
			}
		},
	}
}

declare global {
	interface Window {
		debugFunc: any;
	}
}

debugFunc.config = debugFuncConfig;
debugFunc.configs = debugFuncConfigs;
debugFunc.configSave = debugFuncConfigSave;

window.debugFunc = debugFunc;
