Prism.languages.lingo = {
    'comment': {
      pattern: /\/\/.*|\/\*[\s\S]*?\*\//,
      greedy: true
    },
    'string': {
      pattern: /(["'])(?:\\[\s\S]|(?!\1)[^\\])*\1/,
      greedy: true
    },
    'number': /\b(?:0x[A-Fa-f0-9]+|\d+(?:\.\d+)?(?:[eE][+-]?\d+)?)\b/,
    'keyword': /\b(present|section|question|answer|finish|with|all|sections|parent|subsection|facility|answers|time|datetime|from|select|where|contains|as|display|orderby|function|make|maker|import|export|readonly|end|return|when|is|otherwise)\b/,
    'built-in': /\b(itemat|isnull|ifnull|getquestion|href|LingoPublishedResource)\b/,
    'constant': {
      pattern: /\b(this|true|false|null)\b/,
      alias: 'literal'
    }
  };