'use strict';

import app from '../../ngmodule';
import { UpdateState } from './iapp-update-provider';

app.controller('appUpdateToastController', function () {
  this.dismiss = function () {

  };
});

// EVS-847 Ability to update App without logging out/in
app.factory('appUpdateMonitor', ['$injector', '$interval', '$rootScope', '$timeout', function ($injector, $interval, $rootScope, $timeout) {
  var _pausers = [];
  var _pausedChecks = 0;
  var _appUpdateProvider = null;

  if ($injector.has('appUpdateProvider')) {
    _appUpdateProvider = $injector.get('appUpdateProvider');
  }

  var svc = {
    updateApplyCounter: 0,
    updatePending: false,
    status: UpdateState.idle,

    addPauser: function (pauser) {
      // pauser = function() as bool : true to pause App Updates
      // pauser = {
      //   fn: function() as bool : true to pause App Updates
      //   scope: scope to watch $destroy and remove the pauser
      // }
      if (angular.isFunction(pauser)) {
        pauser = {
          fn: pauser
        };

      } else if (angular.isDefined(pauser) && angular.isDefined(pauser.$root) && pauser.$root === $rootScope) {
        // pauser is a scope object
        pauser = {
          scope: pauser
        };

      } // else pauser is a POJO

      // scope with fn means we pause whilst this scope is alive 
      if (angular.isDefined(pauser.scope) && !angular.isDefined(pauser.fn)) {
        pauser.fn = function () {
          return true;
        };
      }

      _pausers.push(pauser);

      // Return a function which removes the Pauser
      var fnRemovePauser = function () {
        var i = _pausers.indexOf(pauser);
        if (i >= 0) {
          _pausers.splice(i, 1);
        }
      };

      if (angular.isDefined(pauser.scope)) {
        pauser.scope.$on('$destroy', function () {
          fnRemovePauser();

          // Now a Pauser has been removed, see if we can check now ..
          if (_pausedChecks) {
            _applyUpdateIfPossible();
          }
        });
      }

      return fnRemovePauser;
    }
  };


  let updatePendingToast = function () {
    let showing = false;
    return {
      show: () => {
        if (!showing) {
          showing = true;

          $.snackbar({
            content: 'Updating...',
            onClose: () => showing = false
          })
        }
      },
      hide: () => { },
      showing: () => showing
    };
  }();

  function _checkForUpdate() {
    if (_appUpdateProvider != null) {
      _appUpdateProvider.check().then(status => {
        svc.status = status;
        svc.updatePending = status === UpdateState.updateReady;

        _applyUpdateIfPossible();
      })
    }
  }

  function _applyUpdateIfPossible() {
    if (svc.updatePending) {
      updatePendingToast.show();

      var pause = false;

      if (!svc.updateApplyCounter) {
        svc.updateApplyCounter = 3;
      }

      function applyNow() {
        if (_appUpdateProvider != null) {
          _appUpdateProvider.apply().then(() => {
            console.log(`Reloading window after update ...`);
            window.location.href = '/index.html';
          });
        }
      }

      function countdown() {
        // See if any pausers want us to pause App update check
        _pausers.forEach(function (pauser) {
          pause |= pauser.fn() ? true : false;
        });
        if (pause) {
          _pausedChecks++; // Keep track of missed checks, we can perform these when Pausers are removed (above)
          delete svc.updateApplyCounter;
          return;
        }

        svc.updatePending = 2;
        if (svc.updateApplyCounter <= 0) {
          applyNow();
        } else {
          svc.updateApplyCounter--;
          $timeout(countdown, 1000);
        }
      }

      countdown();
    } else {
      updatePendingToast.hide();
    }
  }

  $interval(_checkForUpdate, 5 * 60 * 1000); // Every 5 minutes check for new version

  // Check now aswell
  _checkForUpdate();

  return svc;
}]);
