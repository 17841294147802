'use strict';

import app from '../../ngmodule';

import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerWithValidationBase } from '../../control-with-validation-base';
import { IValidationService } from '../../../services/validator-service';
import { TabController } from '..';

export class TabPaneController extends ControllerWithValidationBase {
    public title?: string;
    public titleAsString?: string;
    public selected: boolean;
    public tabControl: any;
    public id: string;
    public visible: boolean;
    public icon?: string;
    public class?: string;

    static $inject = ['$element', '$sce', 'vmValidationService', '$scope'];
    constructor(protected $element: ng.IRootElementService, $sce: ng.ISCEService, protected validationService: IValidationService, protected $scope: ng.IScope) {
        super($element, $sce, validationService);

        this.selected = false;
        this.tabControl = null;
        this.visible = true;
        this.id = 'tab' + uuidv4();
        this.icon = undefined;
    }

    // we have both title and title-as-string due to the fact that
    // when this is put in a repeater question, having a string with a space in the title
    // was causing an error in the $compile used in the repeater. We can wrap single quotes around things, so
    // title="'something with a space'" but have also added title-as-string which expects a string to side step this
    $onInit(): void {
        super.$onInit();

        if (angular.isDefined(this.title) == false) {
            this.title = this.$element.attr('title');
        }

        if (angular.isDefined(this.title) == false) {
            this.title = this.titleAsString;
        }

        if (this.icon == undefined) {
            this.icon = this.valueFromHtml("icon", '', this.$element);
        }

        if (this.class == undefined) {
            this.class = this.valueFromHtml("class", '', this.$element);
        }        

        this.tabControl.addTab(this);
    }

    $onDestroy(): void {
        this.tabControl.removeTab(this);
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        super.$onChanges(changes);

        if (changes.visible && changes.visible.currentValue) {
            this.visible = changes.visible.currentValue;
        }
    }

    getContent(): ng.IRootElementService {
        return this.$element;
    }

    notifyChildren(event: string) : void {
        // some things have UI issues when shown in tabs (for example leaflet maps), we broadcast down so that
        // these guys can do what ever they need to do
        this.$scope.$broadcast('vm-tab-pane-control.' + event, {tabPane: this, tabControl: this.tabControl});
    }
}

app.component('vmTabPane', {
    template: require('./template.html').default,
    bindings: {
        title: '<',
        titleAsString: '@',
        selected: '<',
        visible: '<',
        icon: '<',
        class: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        validator: '=',
    },
    transclude: true,
    require: {
        tabControl: '^vmTabControl'
    },
    controller: TabPaneController
});