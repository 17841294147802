'use strict';

import questionsModule from '../ngmodule';

questionsModule.constant('TaskListUserInterfaceType', {
  ClockInClockOut: 0,
  Desktop: 1,
  SaveFinish: 2,
  Ordering: 3,
  Hidden: 4
});