'use strict';

import '../../../directives/vm-card-list';
import app from '../../ngmodule';
import angular from 'angular';

app.component('checklistsCtrl', {
  template: require('./template.html').default,
  bindings: {
    group: '<'
  }
});