'use strict';

import angular from 'angular';
import questionDirectiveBase from '../question-directive-base';
import questionsModule from '../ngmodule';
import moment from 'moment';

questionsModule.directive('questionRepeater', ['$timeout',
  'bworkflowApi',
  '$compile',
  '$sce',
  'languageTranslate',
  function ($timeout, bworkflowApi, $compile, $sce, languageTranslate) {
    return $.extend({}, questionDirectiveBase, {
      link: function (scope, elt, attrs) {
        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
        scope.feed = null;

        scope.data = [];

        scope.feeds = {};
        scope.selectedRow = null;

        scope.mediaImageUrl = window.razordata.portalprefix + 'MediaImage/';
        scope.membershipImageUrl = window.razordata.portalprefix + 'MembershipImage/';

        scope.date = function (dt) {
          if (angular.isDefined(dt) == false) {
            return moment();
          }

          return moment(dt);
        };

        scope.expandUrl = function (url) {
          return bworkflowApi.getfullurl(url);
        };

        scope.raiseEvent = function (eventName, args) {
          // allows someone to raise an event to get other elements to do things,
          // for example clicking a button in the label refreshing a task list
          scope.$emit('player_broadcast', {
            name: eventName,
            data: args
          });

        };

        scope.clearSources = function () {
          if (scope.presented.cleardatasources != null && scope.presented.cleardatasources != '') {
            var sources = scope.presented.cleardatasources.split(',');
            var fields = null;

            if (scope.selectedRow != null) {
              fields = scope.selectedRow;
            }

            angular.forEach(sources, function (source) {
              var promise = bworkflowApi.getDataFeed(source);

              if (promise != null) {
                promise.then(function (toUpdate) {
                  // copy across what was selected into the parameters for the feed
                  toUpdate.clearParameters(fields);
                });
              }
            });
          }
        };

        scope.update = function (data, datasources, refreshNow, mergeParams) {
          var feeds = [];

          // if datasources isn't defined then we work back through our properties
          // to see if we can make things simple for the caller
          if (angular.isDefined(datasources) == false || datasources == null) {
            // nothing specified, so fall back to defaults
            if (scope.presented.updatedatasources != null && scope.presented.updatedatasources != '') {
              // we have update sources, so use them
              feeds = scope.presented.updatedatasources.split(',');
            } else {
              feeds = scope.presented.datasource.split(',');
            }
          } else {
            feeds = datasources;
          }

          var refresh = true;
          if (angular.isDefined(refreshNow) == true) {
            refresh = refreshNow;
          }

          angular.forEach(feeds, function (feed) {
            var promise = bworkflowApi.getDataFeed(feed);

            if (promise != null) {
              promise.then(function (found) {
                found.parameters = (mergeParams ? found.parameters : null) || {};

                angular.forEach(data, function (value, key) {
                  found.parameters[key] = value;
                });

                if (refresh == true) {
                  found.getData(true);
                }
              });
            } else {
              console.warn(feed + ' is not a valid datasource');
              return;
            }
          });

          scope.selectedRow = data;
        };

        scope.trustAsHtml = function (text) {
          return $sce.trustAsHtml(text);
        };

        scope.showSection = function (sectionName) {
          scope.$emit('player_broadcast', {
            name: sectionName,
            data: {
              action: 'show'
            }
          });


        };

        scope.$on('help_add_steps', function (event, args) {
          if (scope.presented.HelpContent != null && scope.presented.Name != null) {
            var step = {
              element: 'div[data-presentable-name="' + scope.presented.Name + '"]',
              intro: scope.presented.HelpContent,
              position: scope.presented.HelpPosition.toLowerCase()
            };


            args.push(step);
          }
        });

        scope.openWindow = function (url, target) {
          window.open(url, target);

          return false;
        };

        if (scope.presented.datasource) {
          var split = scope.presented.datasource.split(',');

          // we store each of the feeds in a varibale based on their name
          angular.forEach(split, function (source) {
            var promise = bworkflowApi.getDataFeed(source);

            if (promise != null) {
              promise.then(function (feed) {
                scope.feeds[feed.template.name] = feed;
              });
            }
          });

          // we give the first feed special treatment, its the default feed
          // and can be got at through some shortcut properties on us.
          var promise = bworkflowApi.getDataFeed(split[0]);
          if (promise != null) {
            promise.then(function (feed) {
              scope.feed = feed;

              scope.data = scope.feed.data;
            });
          }
        }

        var content = '<div data-presentable-name="{{presented.Name}}">' + scope.presented.repeatcontent + '</div>';

        if (scope.presented.hidewhennodata == true) {
          content = "<div ng-if='presented.hidewhennodata == false || (presented.hidewhennodata == true && data.length > 0)'>" + content + "</div>";
        }

        content = content + '<ul class="pager" ng-if="presented.showcontrols && feed.template.usepaging"><li><a href="#" ng-click="feed.previousPage()" ng-if="feed.page > 1">Previous</a></li><li><a href="#" ng-click="feed.nextPage()" ng-if="feed.data.length > 0">Next</a></li></ul>';

        var dirElement = $compile(content)(scope);
        $(elt).append(dirElement);
      }
    });

  }]);