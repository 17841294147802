'use strict';

import angular from 'angular';
import questionDirectiveBase from '../question-directive-base';
import questionsModule from '../ngmodule';
import './style.scss';

questionsModule.directive('questionDatatable', ['$timeout', 'bworkflowApi', 'languageTranslate', function ($timeout, bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.feed = null;
      scope.loading = false;

      scope.displayContainer = {
        displayfields: []
      };


      scope.selectedRow = null;

      scope.clearSources = function () {
        if (scope.presented.cleardatasources != null && scope.presented.cleardatasources != '') {
          var sources = scope.presented.cleardatasources.split(',');

          var fields = null;
          if (scope.selectedRow != null) {
            fields = scope.selectedRow.alldata;
          }

          angular.forEach(sources, function (source) {
            var promise = bworkflowApi.getDataFeed(source);

            if (promise != null) {
              promise.then(function (toUpdate) {
                // copy across what was selected into the parameters for the feed
                toUpdate.clearParameters(fields);
              });
            }
          });
        }
      };

      scope.rowclicked = function (row) {
        scope.clearSources();

        if (scope.presented.updatedatasources != null && scope.presented.updatedatasources != '') {
          scope.selectedRow = row;

          var sources = scope.presented.updatedatasources.split(',');

          angular.forEach(sources, function (source) {
            var promise = bworkflowApi.getDataFeed(source);

            if (promise != null) {
              promise.then(function (toUpdate) {
                angular.forEach(row.alldata, function (value, key) {
                  toUpdate.parameters[key] = value;
                });

                // get data and force a refresh
                toUpdate.getData(true);
              });
            }
          });
        }
      };

      if (scope.presented.datasource) {
        // we get our data from a data source object
        var promise = bworkflowApi.getDataFeed(scope.presented.datasource);

        if (promise != null) {
          promise.then(function (feed) {

            feed.addBeforeLoadHook(function (f) {
              scope.loading = true;
            });

            feed.addAfterLoadHook(function (f) {
              scope.loading = false;
            });

            feed.addAfterErrorHook(function (f) {
              scope.loading = false;
            });

            if (scope.presented.usedatasourcevisiblefields == true) {
              scope.displayContainer = feed.template;
            } else {
              scope.displayContainer = scope.presented;
            }

            scope.feed = feed;
          });
        }
      }
    }
  });

}]);

questionsModule.directive('questionDatatableCell', ['$compile', function ($compile) {
  return {
    restrict: 'E',
    scope: {
      field: '=',
      row: '='
    },

    link: function (scope, element, attrs) {
      var val = null;
      if (scope.$parent.presented.usedatasourcevisiblefields == true) {
        val = scope.row.data[scope.field];
      } else {
        val = scope.row.alldata[scope.field];
      }

      var formatter = scope.$parent.feed.getFormat(scope.field);

      var content = scope.$parent.feed.applyFormat(val, formatter, false);

      var dirElement = $compile(content)(scope);
      $(element).append(dirElement);
    }
  };

}]);