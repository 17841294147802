'use strict';

import angular from 'angular';
import { IValidated, IValidationService } from '../services/validator-service';
import { ControllerWithPromptBase } from './control-with-prompt-base';

export abstract class ControllerWithValidationBase extends ControllerWithPromptBase implements ng.IOnChanges {
    public showValidationMessages?: boolean;
    private _validationMessages: string[] = [];
    public validator: IValidated | undefined;

    get validationMessages() {
        return this._validationMessages;
    }
    set validationMessages(value: string[]) {
        this._validationMessages = angular.copy(value);
    }

    constructor(protected $element: ng.IRootElementService, $sce: ng.ISCEService, protected validationService: IValidationService) {
        super($sce);
    }

    $onInit() {
        super.$onInit();

        if (angular.isDefined(this.showValidationMessages) == false) {
            this.showValidationMessages = (this.$element.attr('show-validation-messages') || 'true') == 'true';
        }
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.validationMessages) {
            this._validationMessages = angular.copy(changes.validationMessages.currentValue as string[]);
        }
    }

    protected validate(source: any): void {
        if (this.validator == null) {
            return;
        }

        this._validationMessages = [];

        let result = this.validationService.process(source, this.validator);

        if (result.errors.length > 0) {
            this._validationMessages = result.errors;
        }
    }
}
