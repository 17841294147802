import { FacilityType, OData, ITaskType, ITaskTypesFacility, ITaskTypesFacilityDefinition, Guid } from 'lingo-api';
import { ReadOnlyODataFacility } from "../read-only-odata-facility";

export class TaskTypesFacility extends ReadOnlyODataFacility<Guid, ITaskType, OData.Offline.TaskType> implements ITaskTypesFacility {
	readonly facilityType: FacilityType = `taskTypes`;

	static $inject = [
		`definition`
	];
	constructor(
		private definition: ITaskTypesFacilityDefinition
	) {
		super(`TaskTypes`, {
			uniqueId: r => r.id,
		});
	}

	mapRecord(odata: OData.Offline.TaskType): ITaskType {
		return odata;
	}
}

