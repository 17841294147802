'use strict';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import 'angular-cache';
import base64 from 'base64-arraybuffer';
import { IVMMediaService, IMediaOnServer, MediaOnServerSource, IMediaOnClient } from '../../services/vm-media-service';

class MediaImageData {
    base64: string;
    contentType: string;

    constructor(b64: string, ct: string) {
        this.base64 = b64;
        this.contentType = ct;
    }
}

class MediaImageController extends ControllerBase {
    protected img?: HTMLImageElement;
    protected onClient?: IMediaOnClient;
    protected useCache: boolean;
    protected type: string;
    protected width: number;
    protected height: number;
    protected url: string;
    protected mediaId?: string;
    protected keepSquare: boolean;

    protected loading: boolean;
    protected initialised: boolean;

    static $inject = ['$scope', '$element', '$attrs', 'webServiceUrl', '$http', 'CacheFactory', 'vmMediaService'];
    constructor(protected $scope: ng.IScope,
        protected $element: ng.IRootElementService,
        protected $attrs: ng.IAttributes,
        protected webServiceUrl: any,
        protected $http: ng.IHttpService,
        protected CacheFactory: any,
        protected vmMediaService: IVMMediaService) {
        super();

        this.useCache = true;
        this.type = 'media';
        this.width = 100;
        this.height = 100;
        this.url = '';
        this.loading = false;
        this.keepSquare = true;

        this.initialised = false;
    }

    $onInit(): void {
        if(this.$attrs.cache != null)
        {
            this.useCache = this.$attrs.cache.toLowerCase() == 'true';
        }

        if (this.keepSquare) {
            if (this.width == null && this.height) {
                this.width = this.height;
            } else if (this.height == null && this.width) {
                this.height = this.width;
            }
        }

        this.initialised = true;

        if (this.mediaId != null) {
            this.buildImageUrl();
        }
    }

    getMediaOnServerSource(): MediaOnServerSource {
        let result: MediaOnServerSource = MediaOnServerSource.media;

        switch (this.type) {
            case "media":
                result = MediaOnServerSource.media;
                break;
            case "membership":
            case "user":
                result = MediaOnServerSource.membership;
                break;
            case "tasktype":
                result = MediaOnServerSource.taskType;
                break;
        }

        return result;
    }

    buildImageUrl(): void {
        if(this.initialised == false)
        {
            return;
        }

        if (this.mediaId == null || this.onClient != null) {
            return;
        }

        let onServer: IMediaOnServer = {
            id: this.mediaId,
            name: 'someimage.png',
            source: this.getMediaOnServerSource(),
            options: {
                format: 'png',
                width: this.width,
                height: this.height,
                cache: this.useCache
            }
        }

        this.onClient = this.vmMediaService.createOnClientFromOnServer([onServer])[0];
        this.loading = true;
        let me = this;
        this.vmMediaService.loadClient(this.onClient).then(function (value: IMediaOnClient) {
            me.loading = false;

            if (!me.img) {
                me.img = me.$element.find('span img')[0] as HTMLImageElement;
            }

            if (me.img == null || value.data == null || value.data.loadedAsBlob) {
                return;
            }

            // imageData could come from the cache or a fresh server hit
            me.img.src = `data:${value.data.contentType};base64,${value.data.data as string}`;
        }).catch(err => {
        })
    }

    $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.mediaId || changes.width || changes.height || changes.type) {
            this.buildImageUrl();
        }
    }
}

app.component('mediaImage', {
    template: require('./template.html').default,
    transclude: {
        'image': '?custom',
    },
    bindings: {
        mediaId: '<',
        width: '<',
        height: '<',
        type: '<',
        keepSquare: '<'
    },
    controller: MediaImageController
});