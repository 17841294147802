import app from '../../ngmodule';

app.directive('questionTasklistTaskTypeEditor', [
	function () {
		return {
			template: require('./template.html').default,
			restrict: 'E',
			scope: {
				task: '=ngModel'
			},

			link: function (scope, element, attrs) {
				scope.setTaskType = function (taskType) {
					scope.task.subtasktypeid = taskType.id;

					scope.$emit('question-task-list-task-type-editor.changed');
				};
			}
		};

	}]);

