import { Question, Answer } from "./question";
import { JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { IFreeTextQuestionDefinition, DesignFreeTextQuestion } from 'lingo-api';
import { IFreeTextAnswer, IPresentedFreeTextQuestion } from "../../presented-types";


export class FreeTextQuestion extends Question implements DesignFreeTextQuestion {
	defaultAnswer?: string;
	allowMultiline?: boolean = false;
	constructor() {
		super(`freeText`);
	}
}

export class FreeTextAnswer extends Answer implements ILingoAnswer {
	constructor(public answer: IFreeTextAnswer, public presentedQuestion: IPresentedFreeTextQuestion) {
		super(answer, presentedQuestion);
	}

	get value() {
		return this.answer.Text;
	}
	set value(value: string | null) {
		this.answer.Text = value;
	}
}

JsonTreeTranslators.register({
	ctr: FreeTextQuestion,
	create: () => new FreeTextQuestion()
}, {
	ctr: FreeTextAnswer,
	flatten(o: FreeTextAnswer) {
		return {
			answer: o.answer,
			presentedQuestion: o.presentedQuestion
		}
	},
	fatten(o: any, fatten: Convert, store: Convert) {
		return store(new FreeTextAnswer(fatten(o.answer), fatten(o.presentedQuestion)));
	}
});
