'use strict';

import './index.scss';

import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { StringNullableChain } from 'lodash';
import { IVMMapCords, IVMMapMarker, IVMMappingService } from '../../services/vm-mapping-service';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { IVMModalListener, IVMModalManagerService, VMModalListenerOperation, IVMModal } from '../../services/vm-modal-manager-service';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

/* This code is needed to properly load the images in the Leaflet CSS */
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export class MapController extends ControllerBase implements IVMRegisteredComponent, IVMModalListener {
    public name: string;
    public center: undefined | string | IVMMapCords;
    public markers?: Array<IVMMapMarker>;
    public width: number | undefined;
    public height: number | undefined;

    protected hidden: boolean;
    protected containedInModal: IVMModal | null;
    protected id: string;
    protected map: any; // this is the pointer to the leaflet map object
    protected layer: any;
    protected _markers: Array<any>; // this is the set of marker objects from leaflet

    static $inject = ['$element', '$timeout', '$scope', 'vmMappingService', 'vmRegisteredComponentProvider', 'vmModalManagerService'];
    constructor(protected $element: ng.IRootElementService, 
        protected $timeout: ng.ITimeoutService, 
        protected $scope: ng.IScope,
        protected vmMappingService: IVMMappingService, 
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider,
        protected vmModalManagerService: IVMModalManagerService) {
        super();

        this.name = '';
        this._markers = [];
        this.id = 'map' + uuidv4();
        this.hidden = false;
        this.containedInModal = null;

        this.vmModalManagerService.register(this);
    }

    $onDestroy(): void {
        this.vmModalManagerService.deRegister(this);
    }

    getComponentType(): string {
        return 'Map';
    }

    notify(operation: VMModalListenerOperation, nowShowingModal: IVMModal | null, service: IVMModalManagerService): void {
        if(service.modalCount > 0)
        {
            this.hidden = nowShowingModal != this.containedInModal ? true : false; // we hide if we aren't a part of the modal that is now showing
            return;
        }

        this.hidden = false;
    }

    $onInit(): void {
        if (angular.isDefined(this.name) == false || this.name == '') {
            console.warn('vm map must be given a name');
            throw 'vm-map must be given a name';
        }

        this.vmRegisteredComponentProvider.register(this);

        if (this.width == undefined) {
            this.width = this.valueFromHtml("width", undefined, this.$element);
        }

        if (this.height == undefined) {
            this.height = this.valueFromHtml("height", undefined, this.$element);
        }

        // hook into the message from the tab control that get's sent when there is a tab selection, this can only
        // be intercepted by a control that's contained in the tab
        this.$scope.$on('vm-tab-control.show', (evt, args) => {
            this.$timeout(() => {
                this.map.invalidateSize();
                this.layer.redraw();
            });
        });

        // hook into the message that get's sent by the modal when it's finished initialising. Since we have issues
        // with modals and hide ourselves when they're shown, we need to not do this if we are in the currently showing modal
        this.$scope.$on('vm-modal.init', (evt, args) => {
            this.containedInModal = args.modal;
        });

        this.$timeout(() => {
            this.map = L.map(this.id);

            if(this.center)
            {
                this.setMapView(this.vmMappingService.toCords(this.center), 13);
            }
            else
            {
                this.setMapView(this.vmMappingService.getDefaultCenter(), 13);
            }

            this.layer = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            });
            
            this.layer.addTo(this.map);   

            if(this.markers)
            {
                this.setMarkers(this.markers);
            }
        });
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if(changes.center && changes.center.currentValue != undefined && this.map) {
            this.setMapView(changes.center.currentValue);
        }

        if(changes.markers && changes.markers.currentValue != undefined && this.map) {
            this.setMarkers(changes.markers.currentValue);
        }
    }

    setMapView(cords: string | IVMMapCords, zoom?: number): void {
        let c = this.vmMappingService.toCords(cords);

        this.map.setView([c.latitude, c.longitude], zoom);
    }

    setMapZoom(level: number)
    {
        this.map.setZoom(level);
    }

    clearMarkers() {
        for(let m of this._markers)
        {
            m.removeFrom(this.map);
        }

        this._markers = [];
    }

    setMarkers(markers: Array<IVMMapMarker>) {
        this.clearMarkers();

        for(let m of markers)
        {
            let lm = L.marker([m.cords.latitude, m.cords.longitude], { draggable: m.canDrag });
            lm.vmMarker = m; // add the external marker object that the leaflet marker represents
            lm.vmMap = this; // add ourselves for call back at drag end

            if(m.canDrag)
            {
                lm.on('dragend', (event: any) => {
                    let marker = event.target;
                    marker.vmMap.handleDragEnd(marker);
                });
            }

            this._markers.push(lm);

            lm.addTo(this.map);

            if(m.message)
            {
                let p = lm.bindPopup(m.message);

                if(m.open)
                {
                    p.openPopup();
                }
            }
        }
    }

    handleDragEnd(leafletMarker: any) {
        this.$timeout(() => {
            let marker = leafletMarker.vmMarker as IVMMapMarker;
            let position = leafletMarker.getLatLng();

            marker.cords.latitude = position.lat;
            marker.cords.longitude = position.lng;
        });
    }
}

app.component('vmMap', {
    template: require('./template.html').default,
    bindings: {
        name: '<',
        center: '<',
        markers: '<',
        width: '<',
        height: '<'
    },
    controller:  MapController
});