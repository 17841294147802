'use strict';

import app from '../ngmodule';
import './begin';
import './error';
import './finishing';
import './play';
import './present';
import './present-root';
import './header';
import './footer';

app.config(
  ['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {

    $stateProvider.
      state('root.play', {
        abstract: true,
        url: '/player',
        views: {
          'header@root': {
            component: 'playerButtonsCtrl'
          },

          'content@root': {
            template: require('./play/template.html').default,
            controller: 'playCtrl'
          },

          'footer@root': {
            component: 'playerFooterCtrl'
          }
        }
      }).



      state('root.play.begin', {
        url: '/begin/?groupId&resourceId&revieweeId&args&publishingGroupResourceId',
        views: {
          'player': {
            template: require('./begin/template.html').default,
            controller: 'beginCtrl'
          }
        }
      }).



      state('root.play.present', {
        url: '/continue/:workingDocumentId/?nonce',
        views: {
          'player': {
            template: require('./present-root/template.html').default,
            controller: 'presentRootCtrl'
          }
        }
      }).



      state('root.play.present.presentable', {
        views: {
          'playerContent': {
            template: require('./present/template.html').default,
            controller: 'presentCtrl'
          }
        }
      }).



      state('root.play.present.error', {
        views: {
          'playerContent': {
            template: require('./error/template.html').default,
            controller: 'errorCtrl'
          }
        }
      }).



      state('root.play.present.finishing', {
        views: {
          'playerContent': {
            template: require('./finishing/template.html').default,
            controller: 'finishingCtrl'
          }
        }
      }).



      state('root.play.present.finished', {
        views: {
          'playerContent': {
            template: require('./finished/template.html').default,
            controller: 'presentCtrl'
          }
        }
      });



  }]);