import { IExternBuilder } from '../../externs-builder';
import * as uuid from 'uuid';
import { IWebServiceUrl } from '../../../../../common/web-service-url';
import { IBworkflowApiService } from '../../../../bworkflow-api/bworkflow-api';
import { OfflineCache } from '../../../../offline-cache';
import { ODataBuilder } from '../../../../odata-builder';
import moment from 'moment';
import { FacilityType, Guid, IFacility, IOfflineCreateResult, IOfflineUpdateResult, ITasksFacility, ITasksFacilityDefinition, ITaskWithContext, IUpdateTaskRequest } from 'lingo-api';
import { ICreateTaskRequest } from 'lingo-api';
import { LabelsFacility } from '../labels-facility/index';

export class TasksFacility implements IFacility, IExternBuilder, ITasksFacility {
	readonly facilityType: FacilityType = `tasks`;

	static $inject = [
		`definition`,
		`bworkflowApi`,
		`webServiceUrl`
	];
	constructor(
		private definition: ITasksFacilityDefinition,
		private bworkflowApi: IBworkflowApiService,
		private webServiceUrl: IWebServiceUrl
	) {
	}

	buildExterns(externs: any[], iterate: (o: any) => any): void {
		externs.push(iterate(this.definition));
	}

	async get<TContext = any>(id: Guid): Promise<ITaskWithContext<TContext>> {
		return new Promise<ITaskWithContext<TContext>>((resolve, reject) => {
			this.bworkflowApi.execute(`TaskFacility`, `GetTask`, id).then(response => {
				resolve(response.task);
			})
		});
	}

	async create<TContext = any>(definition: ICreateTaskRequest<TContext>): Promise<IOfflineCreateResult<ITaskWithContext<TContext>>> {
		const task = {
			...definition,
			id: uuid.v4()
		}

		const onlineResult = new Promise<ITaskWithContext<TContext>>((resolve, reject) => {
			this.bworkflowApi.execute(`TaskFacility`, `CreateTask`, task).then(response => {
				resolve(response.task);
			})
		});

		return {
			id: task.id,
			result: onlineResult
		}
	}

	/** Update an existing Task with a partial Task Definition */
	async update<TContext = any>(definition: IUpdateTaskRequest<TContext>): Promise<IOfflineUpdateResult<ITaskWithContext<TContext>>> {
		return new Promise<ITaskWithContext<TContext>>((resolve, reject) => {
			this.bworkflowApi.execute(`TaskFacility`, `UpdateTask`, definition).then(response => {
				resolve(response.task);
			})
		});
	}
}