'use strict';

import angular from 'angular';
import '../../utils/bootstrap-compat';
import questionsModule from '../ngmodule';

questionsModule.directive('questionTaskBatchOperations', ['$timeout', 'languageTranslate', function ($timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, undefined, languageTranslate);

    }
  });

}]);