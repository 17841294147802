'use strict';

import './app-init';
import { createRazorData } from './window-razordata';
import bootstrapApp from './bootstrap-app';
import { useApplicationCacheAppUpdateProvider } from './player/services/app-update-monitor/application-cache-app-update-provider';
import LogRocket from 'logrocket';
import { useHtml5TakePictureController } from './directives/vm-take-picture/html5-camera/index';
import { useDesktopOAuthService } from './services/auth/auth-svc/desktop-oauth-svc';
import { useAuthHttpTokenService } from './services/auth/auth-http-token-svc';
import { useDesktopAboutService } from './services/about-service/desktop-about-service';
import { useDesktopLoginDetection } from './services/auth/run';

const environment = process.env.NODE_ENV;
console.log(`Environment is ${environment}`);

/* Logrocket not used yet
if (environment === `production`) {
    LogRocket.init(`spho80/lucifer`);
}
*/

declare var window: any;

var loc = window.location;
const tenantUrl = `${loc.protocol}//${loc.hostname}`;
window.razordata = createRazorData(tenantUrl, `desktop`);

// Not using ApplicationCache for updates/caching as it is deprecated, should look to use ServiceWorkers
//useApplicationCacheAppUpdateProvider();

useDesktopOAuthService();
useAuthHttpTokenService();
useDesktopLoginDetection();

useHtml5TakePictureController();
useDesktopAboutService();

bootstrapApp('player');
