'use strict';

import './index.scss';
import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';

class IndeterminateProgressController extends ControllerBase {
    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();
    }
}

app.component('vmIndeterminateProgress', {
    template: require('./template.html').default,
    controller: IndeterminateProgressController
});