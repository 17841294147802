'use strict';

import app from '../../player/ngmodule';
import '../../services/auth';
import '../../directives/drawer-menu';
import '../../directives/vm-widget-container';
import '../../services/vm-dashboard-service';
import './index.scss';

app.component('bodyCtrl', {
  template: require('./template.html').default,
  controller: [
    "authSvc",
    "$window",
    "$element",
    "$timeout",
    "webServiceUrl",
    "vmDashboardService",
    "vmRegisteredComponentProvider",
    "aboutSvc",
    "$interval",
    "vmModalManagerService",
    "vmGlobalSettingsService",
    function (
      authSvc,
      $window,
      $element,
      $timeout,
      webServiceUrl,
      vmDashboardService,
      vmRegisteredComponentProvider,
      aboutSvc,
      $interval,
      vmModalManagerService,
      vmGlobalSettingsService) {
      this.computedHiddenDashboardModels = [];
      this.sideNavElement = null;
      this.topNavElement = null;

      this.isSmallWidth = true;
      this.sideNavVisible = true;

      this.brandingMediaId = undefined;

      // implement the interface to work with the vmRegisteredComponentProvider
      this.name = "body";
      this.getComponentType = function () {
        return "ApplicationFramework";
      }

      this.$onInit = function () {
        vmRegisteredComponentProvider.register(this);

        this.sideNavElement = document.getElementById('vm-app-sidenav');
        this.topNavElement = document.getElementById('vm-app-topnav');

        let u = new URL(webServiceUrl.site(''));
        // we do this as it allows custom css (in spa-theme.css) to override things without have to use !important etc as the style can be more specific than the ones in the
        // default css simply by selecting .some-domain-com in their css selector
        $element.addClass(u.hostname.split('.').join('-'));

        vmDashboardService.getHiddenDashboards(null).then(models => {
          this.computedHiddenDashboardModels = models;
        });

        angular.element($window).on('resize', () => {
          $timeout(() => {
            this.configureSideNav();
          });
        });

        vmModalManagerService.register(this);

        vmGlobalSettingsService.getSetting("spa.theme.branding").then((mediaId) => {
          if (!mediaId) {
            return;
          }

          this.brandingMediaId = mediaId;
        });

        this.configureSideNav(true);
      };

      this.$onDestroy = function () {
        vmRegisteredComponentProvider.deRegister(this);
        vmModalManagerService.deRegister(this);
      }

      this.calculateSize = function () {
        this.isSmallWidth = $window.innerWidth < 768; // this is what bulma defines as medium
      };

      this.configureSideNav = function (ignorePrevious) {
        let previous = this.isSmallWidth;

        this.calculateSize();

        if (previous != this.isSmallWidth || ignorePrevious) {
          if (this.isSmallWidth == true) {
            this.closeSideNav();
          }
          else {
            this.openSideNav();
          }
        }
      }

      this.toggleSideNav = function (evt) {
        this.sideNavElement.classList.toggle('closed');
      };

      this.closeSideNav = function () {
        this.sideNavElement.classList.add('closed');
      }

      this.openSideNav = function () {
        this.sideNavElement.classList.remove('closed');
      }

      this.closeTopNav = function () {
        this.topNavElement.classList.add('closed');
      }

      this.openTopNav = function () {
        this.topNavElement.classList.remove('closed');
      }

      this.logout = async () => {
        try {
          await authSvc.logout();
        }
        catch
        {
          console.warn('There was an issue attempting to logout, refreshing page to see if this resolves the issue');
          $window.location.reload();
        }
      }

      // we register ourselves as a listener of the vmModalManagerService so that we can
      // show and hide the top nav bar when modals are shown
      this.notify = function (operation, nowShowingModal, service) {
        if (nowShowingModal == null) {
          this.openTopNav();
          $("html").removeClass('is-clipped');
          return;
        }

        if (operation == 1) {
          this.closeTopNav();
          $("html").addClass('is-clipped');
        }
      }

      this.refresh = () => {
        $window.location.reload();
      }

      this.showAbout = () => {
        this.aboutCategories = aboutSvc.refresh(this.aboutCategories);
        let modal = vmRegisteredComponentProvider.get("body-about", "modal");
        modal.show();
        modal.events.on('closed', () => {
          aboutSvc.cancel(this.aboutCategories);
        })
      }

      this.aboutFilter = (item) => {
        return !!item.value;
      }

      this.aboutCategoryFilter = (item) => {
        return !!item.visible;
      }
    }]
});