'use strict';

import app from '../../ngmodule';
import angular from 'angular';

app.component('playerFooterCtrl', {
  template: require('./template.html').default,
  bindings: {},
  controller: ["$scope", "playerButtons", "playerActions", function ($scope, playerButtons, playerActions) {

    $scope.buttonStates = playerButtons;

    $scope.buttonActions = playerActions;

    $scope.$on('$stateChangeSuccess', function () {
      $scope.buttonStates.areAjaxing = false;
    });

    //This stuff effectively wires up browser back
    //to checklist back
    $scope.$on('$stateChangeStart', function (ev, toState, toParams, fromState, fromParams) {

      if (!$scope.buttonStates.areAjaxing) {
        //are we trying to hit forward or backwards?
        var prevNonce = $rootScope.nonceStack[$rootScope.nonceStack.length - 2];
        if (toParams.nonce == prevNonce && typeof prevNonce !== 'undefined') {
          //we're going back
          ev.preventDefault();
          if ($scope.buttonStates.canPrevious) {
            $rootScope.nonceStack.pop();
            $rootScope.nonceStack.pop();
            $scope.buttonActions.doTransition('prev', toParams.nonce);
          }
        }
      }
    });
  }]
});