'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionProgressindicator', ['$timeout', 'languageTranslate', function ($timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, undefined, languageTranslate);
    }
  });

}]);