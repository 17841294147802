'use strict';

import app from './ngmodule';
import { ISiteMapService } from './site-map/site-map-service';

declare var $: any; // give us a typescript variable to access jquery

export interface IVMDashboardService {
    getDashboards(dashboardName: string | null): ng.IPromise<Array<any>>;
    getHiddenDashboards(): ng.IPromise<Array<any>>;
    showDashboard(dashboardName: string, broadcastName: string | undefined, args: any): void;
}

export class vmDashboardService implements IVMDashboardService {

    static $inject = ['siteMapService', '$q', '$timeout', '$rootScope'];
    constructor(protected siteMapService: ISiteMapService, protected $q: ng.IQService, protected $timeout: ng.ITimeoutService, protected $rootScope: ng.IRootScopeService) {
    }

    protected filterByName(name: string | null, items: Array<any>) {
        if (name == null) {
            return items;
        }

        let result = [];

        for (let item of items) {
            if (item.ChecklistName == name) {
                result.push(item);
            }
        }

        return result;
    }

    protected transformToPlayerModels(dbs: Array<any>): Array<any> {
        let result: Array<any> = [];

        for (let db of dbs) {
            result.push(this.transformToPlayerModel(db));
        }

        return result;
    }

    protected transformToPlayerModel(db: any) {
        var am = {} as any;

        //enumerate all the answerable nodes in the answerModel
        (function thisfunc(_, p) {//jquery each makes the actual value the second arg, so define the function this way
            if (p.IsAnswerable) {
                let id: string = p.Id;
                am[id] = p.Answer || {};
                p.Answer = am[p.Id];
            }
            if (p.Children) {
                $.each(p.Children, thisfunc);
            }
        })(undefined, db.Presented);

        return {
            PlayerModel: db,
            AnswerModel: am
        };
    }

    public getDashboards(dashboardName: string | null): ng.IPromise<Array<any>> {
        return this.siteMapService.getSiteMap().then(map => {
            let items = this.transformToPlayerModels(this.filterByName(dashboardName, map.dashboardData.Dashboards));

            return this.$q.when(items);
        });
    }

    public getHiddenDashboards(): ng.IPromise<Array<any>> {
        return this.siteMapService.getSiteMap().then(map => {
            let items: Array<any> = [];

            for (let hidden of map.hiddenDashboards) {
                items.push(this.filterByName(hidden.name, map.dashboardData.Dashboards)[0]);
            }

            return this.$q.when(this.transformToPlayerModels(items));
        });
    }

    public showDashboard(dashboardName: string, broadcastName: string | undefined, args: any): void {
        this.siteMapService.navigateToDashboard(dashboardName, () => {
            if(broadcastName)
            {
                // we send the args into the dashboard through angularjs broadcast
                this.$rootScope.$broadcast(broadcastName, args);
            }
        });
    }
}

app.factory('vmDashboardService', vmDashboardService);