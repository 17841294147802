'use strict';

import app from '../ngmodule';
import angular from 'angular';

app.factory('quickStartTaskService', ['$http', 'webServiceUrl', '$q', 'taskTypesService', '_', function ($http, webServiceUrl, $q, taskTypesSvc, _) {
  var svc = {
    getSiteTypeTaskTypes: function () {
      return $http({
        url: webServiceUrl.odata('/UserTypeTaskTypes'),
        method: 'GET'
      }).
        then(function (response) {
          // Index SiteTypes by their SiteType
          return _.groupBy(response.data.value, 'UserTypeId');
        });
    },

    getQRcodes: function () {
      return $http({
        url: webServiceUrl.odata('/QRCodes?$filter=IsASite eq true or TaskTypeId ne null&$expand=User,TaskType&$select=QRcode,User/UserId,User/Name,User/UserTypeId,TaskType/*'),
        method: 'GET'
      }).
        then(function (response) {
          return response.data.value.reduce(function (map, r) {
            map[r.QRcode] = {
              qrcode: r.QRcode,
              site: r.User,
              tasktype: r.TaskType ? {
                defaulttoroster: null,
                mediaid: r.TaskType.MediaId,
                requiresbasicdetails: false,
                requiressitedetails: false,
                tasktypeid: r.TaskType.Id,
                text: r.TaskType.Name,
                type: 'Shortcut'
              } :
                null
            };

            return map;
          }, {});
        });
    },

    getBeacons: function () {
      return $http({
        url: webServiceUrl.odata('/Beacons?$filter=IsASite eq true or TaskTypeId ne null&$expand=User,TaskType&$select=Id,User/UserId,User/Name,User/UserTypeId,TaskType/*'),
        method: 'GET'
      }).
        then(function (response) {
          return response.data.value.reduce(function (map, r) {
            map[r.Id] = {
              beaconId: r.Id,
              site: r.User,
              tasktype: r.TaskType ? {
                defaulttoroster: null,
                mediaid: r.TaskType.MediaId,
                requiresbasicdetails: false,
                requiressitedetails: false,
                tasktypeid: r.TaskType.Id,
                text: r.TaskType.Name,
                type: 'Shortcut'
              } :
                null
            };

            return map;
          }, {});
        });
    },

    getTaskTypes: function () {
      return taskTypesSvc.getTaskTypes().then(function (tasktypes) {
        return tasktypes.reduce(function (map, taskType) {
          map[taskType.Id] = {
            defaulttoroster: null,
            mediaid: taskType.MediaId,
            requiresbasicdetails: false,
            requiressitedetails: false,
            tasktypeid: taskType.Id,
            text: taskType.Name,
            type: 'Shortcut'
          };

          return map;
        }, {});
      });
    },

    prepare: function () {
      var sttt = svc.getSiteTypeTaskTypes();
      var tt = svc.getTaskTypes();
      var qrcodes = svc.getQRcodes();
      var beacons = svc.getBeacons();
      var deferred = $q.defer();
      $q.all([sttt, tt, qrcodes, beacons]).then(function (results) {
        deferred.resolve({
          siteTypeTaskTypes: results[0],
          taskTypes: results[1],
          qrcodes: results[2],
          beacons: results[3]
        });

      });
      return deferred.promise;
    }
  };

  return svc;
}]);