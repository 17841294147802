'use strict';

import { QRCode, ErrorCorrectLevel, QRNumber, QRAlphaNum, QR8BitByte, QRKanji } from 'qrcode-generator-ts/js'
import app from '../simple-directives-ngmodule';
import angular from 'angular';

class QrCodeGeneratorController {
    public value?: string;
    public cellSize: number;

    protected qrCode: QRCode;

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        this.qrCode = new QRCode();
        this.cellSize = 4;
    }

    $onInit() {

    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.value && changes.value.currentValue) {
            this.value = changes.value.currentValue;
        }

        if (changes.cellSize && changes.cellSize.currentValue) {
            this.cellSize = changes.cellSize.currentValue;
        }

        this.createQRCode();
    }

    createQRCode(): void {
        this.qrCode.clearData();

        if (this.value == null) {
            return;
        }

        this.qrCode.addData(this.value);
        this.qrCode.make();

        let img = this.$element.find('.qrcode')[0] as HTMLImageElement;

        img.src = this.qrCode.toDataURL(this.cellSize);
    }
}

app.component('qrCodeGenerator', {
    template: require('./template.html').default,
    bindings: {
        value: '<',
        cellSize: '<'
    },
    controller: QrCodeGeneratorController
});