'use strict';

import app from '../../ngmodule';

import angular from 'angular';
import './index.scss';

import { ControllerBase } from '../../control-base';
import { AccordionController } from '..';

export class AccordionPaneController extends ControllerBase {
    public title?: string;
    public titleAsString?: string;
    public selected: boolean;
    public accordianControl: any;
    public id?: string;
    public parent?: string;

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();

        this.selected = false;
        this.accordianControl = null;
    }

    // we have both title and title-as-string due to the fact that
    // when this is put in a repeater question, having a string with a space in the title
    // was causing an error in the $compile used in the repeater. We can wrap single quotes around things, so
    // title="'something with a space'" but have also added title-as-string which expects a string to side step this
    $onInit(): void {
        if (angular.isDefined(this.title) == false) {
            this.title = this.$element.attr('title');
        }

        if (angular.isDefined(this.title) == false) {
            this.title = this.titleAsString;
        }

        if (this.title == null) {
            throw 'The accordian pane must have a title or title-as-string defined';
        }

        // we add this to the vm-accordion-pane html element as mdb uses some pseudo selectors based on child index
        this.$element.addClass('accordion-item');

        this.id = this.title.replace(/\s+/g, '');

        this.accordianControl.addPane(this);
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.selected) {
            this.selected = changes.selected.currentValue;
        }
    }

    toggleOpen(): void {
        this.$element.find('> .accordion-panel')[0].classList.toggle('open');
    }
}

app.component('vmAccordionPane', {
    template: require('./template.html').default,
    bindings: {
        title: '<',
        titleAsString: '@',
        selected: '<'
    },
    transclude: true,
    require: {
        accordianControl: '^vmAccordion'
    },
    controller: AccordionPaneController
});