import Prism from 'prismjs';

var PLUGIN_NAME = 'lingo-debugger';
Prism.plugins.lingoDebugger = {
	setErrors: function (pre, errors) {
		if (pre.tagName !== 'PRE' || !pre.classList.contains(PLUGIN_NAME)) {
			return;
		}

		pre.setAttribute('data-errors', errors ? JSON.stringify(errors) : '');
	},

	onToggle: function (pre, on, callback) {
		if (pre.tagName !== 'PRE' || !pre.classList.contains(PLUGIN_NAME)) {
			return;
		}

		pre.onToggle = pre.onToggle || [];
		let index = pre.onToggle.indexOf(callback);
		if (on && index === -1) {
			pre.onToggle.push(callback);
		} else if (!on && index !== -1) {
			pre.onToggle.splice(index, 1);
		}
	},

	setBreakPoints: function (pre, breakPoints) {
		if (pre.tagName !== 'PRE' || !pre.classList.contains(PLUGIN_NAME)) {
			return;
		}

		pre.setAttribute('data-breakpoints', JSON.stringify(breakPoints));

		var breakPointElms = pre.querySelectorAll('span.break-point');
		if (!breakPointElms) {
			return;
		}

		Array.from(breakPointElms).forEach(function (breakPointElm, lineNumber) {
			breakPointElm.classList.remove('active');
			if (breakPoints.includes(lineNumber)) {
				breakPointElm.classList.add('active');
			}
		});
	},

	getBreakPoints: function (pre) {
		if (pre.tagName !== 'PRE' || !pre.classList.contains(PLUGIN_NAME)) {
			return;
		}

		var breakPointElms = pre.querySelectorAll('span.break-point');
		if (!breakPointElms) {
			return;
		}

		var breakPoints = [];
		Array.from(breakPointElms).forEach(function (breakPointElm, lineNumber) {
			if (breakPointElm.classList.contains('active')) {
				breakPoints.push(lineNumber);
			}
		});

		return breakPoints;
	}
};

Prism.hooks.add('before-insert', function (env) {
	// Fetch the array of errors from the env object
	const code = env.element;
	const pre = code.parentNode;

	const errorsText = pre.getAttribute('data-errors');
	const allErrors = JSON.parse(errorsText || '[]');

	// Create a map of errors for easier access
	var errorMap = {};
	for (var i = 0; i < allErrors.length; i++) {
		var error = allErrors[i];
		if (!errorMap[error.row]) {
			errorMap[error.row] = [];
		}
		errorMap[error.row].push(error);
	}

	// Split the highlighted code into lines
	var lines = env.highlightedCode.split('\n');

	// Iterate over the lines and add the error marker where necessary
	for (var i = 0; i < lines.length; i++) {
		let newLine = `<span class="source-line"><span class="line-gutter"><span class="break-point"></span><span class="line-number" data-line-number="${i + 1}"></span></span><span class="source-code">${lines[i]}</span>`;
		var errors = errorMap[i];
		if (errors) {
			// Append the error marker
			errors.forEach(function (error) {
				newLine += `<span class="error-marker" style="margin-left: calc(4em + ${error.col} * 1ch)"></span><span class="error-message" data-text="${error.message}">`;
				if (error.data) {
					Object.keys(error.data).forEach(function (key) {
						newLine += `<span class="error-data"><span class="error-data-key" data-text="${key}"></span><span class="error-data-value" data-text="${error.data[key]}"></span></span>`;
					});
				}
				newLine += '</span>';
			});
		}
		newLine += '</span>';
		lines[i] = newLine;
	}
	// Update the highlighted code
	env.highlightedCode = lines.join('\n');
});

Prism.hooks.add('complete', function (env) {
	if (!env.code) {
		return;
	}

	var code = env.element;
	var pre = code.parentNode;

	if (!pre || !/pre/i.test(pre.nodeName)) {
		return;
	}

	code.classList.remove(PLUGIN_NAME);
	pre.classList.add(PLUGIN_NAME);

	let allowedBreakPoints = JSON.parse(pre.getAttribute('data-allowed-breakpoints') || '[]');
	let breakPoints = JSON.parse(pre.getAttribute('data-breakpoints') || '[]');
	let sourceLineElms = code.querySelectorAll('span.source-line');
	let sourceLine = parseInt(pre.getAttribute('data-source-line'));

	Array.from(sourceLineElms).forEach(function (sourceLineElm, lineNumber) {
		let sourceCodeElm = sourceLineElm.querySelector('.source-code');
		sourceCodeElm.classList.remove('active');
		if (sourceLine === lineNumber) {
			sourceCodeElm.classList.add('active');
		}

		let breakPointElm = sourceLineElm.querySelector('.break-point');
		if (allowedBreakPoints.includes(lineNumber)) {
			breakPointElm.classList.add('allowed');
			breakPointElm.classList.remove('active');
			if (breakPoints.includes(lineNumber)) {
				breakPointElm.classList.add('active');
			}
		}

		breakPointElm.addEventListener('click', function () {
			let callbacks = pre.onToggle;
			if (callbacks) {
				callbacks.forEach(function (callback) {
					callback(Object.assign({
						breakPointLineNumber: lineNumber,
						breakPointActive: !breakPointElm.classList.contains('active')
					}, env));
				});
			}
		});
	});
});
