import moment from 'moment';
import app from './ngmodule';
import angular from 'angular';
import * as XLSX from 'xlsx';
import { IMediaOnClient, MediaOnClientStatus, IVMMediaService } from './vm-media-service';
const { read, write, utils } = XLSX;

export interface IXlsxConversionResult {
    data: any;
    success: boolean;
    reason: string | null;
}

export interface IXlsxWorkbook {

}

export interface IVMXlsx {
    FileToJson(file: File, worksheet: number | string): ng.IPromise<IXlsxConversionResult>;
    FileToWorkbook(file: File): ng.IPromise<IXlsxConversionResult>;

    MediaOnClientToJson(client: IMediaOnClient, worksheet: number | string): ng.IPromise<IXlsxConversionResult>;
    MediaOnClientToWorkbook(client: IMediaOnClient): ng.IPromise<IXlsxConversionResult>;
}

class vmWorkbook implements IXlsxWorkbook {
    protected book: XLSX.WorkBook;

    constructor(b: XLSX.WorkBook) {
        this.book = b;
    }

    isStringNotNumber(test: number | string): test is string {
        return (test as string).substr !== undefined;
    }

    public sheetToJson(worksheet: number | string | null | undefined): any {
        let sheet: XLSX.WorkSheet | null = null;

        if (worksheet == null) {
            worksheet = 0;
        }

        if (this.isStringNotNumber(worksheet)) {
            sheet = this.book.Sheets[worksheet];
        }
        else {
            sheet = this.book.Sheets[this.book.SheetNames[worksheet]];
        }

        let result = XLSX.utils.sheet_to_json(sheet);

        return result;
    }

    public sheetNames(): Array<string> {
        return this.book.SheetNames;
    }
}

class vmXlsx implements IVMXlsx {
    static $inject = ['$q', '$timeout', 'vmMediaService'];
    constructor(protected $q: ng.IQService, protected $timeout: ng.ITimeoutService, protected vmMediaService: IVMMediaService) {

    }

    isString(test: string | ArrayBuffer | Blob): test is string {
        return (test as string).substr !== undefined;
    }

    FileToJson(file: File, worksheet: number | string | null | undefined): ng.IPromise<IXlsxConversionResult> {
        let p: ng.IDeferred<IXlsxConversionResult> = this.$q.defer<IXlsxConversionResult>();

        let reader = new FileReader();

        reader.onload = () => {
            if (reader.result == null) {
                return;
            }

            var data = new Uint8Array(reader.result as ArrayBuffer);
            let workbook = XLSX.read(data, { type: 'array' });

            let result = new vmWorkbook(workbook).sheetToJson(worksheet);

            p.resolve({ data: result, success: true, reason: null });
        };

        reader.readAsArrayBuffer(file);

        return p.promise;
    }

    FileToWorkbook(file: File): ng.IPromise<IXlsxConversionResult> {
        let p: ng.IDeferred<IXlsxConversionResult> = this.$q.defer<IXlsxConversionResult>();

        let reader = new FileReader();

        reader.onload = () => {
            if (reader.result == null) {
                return;
            }

            var data = new Uint8Array(reader.result as ArrayBuffer);
            let workbook = XLSX.read(data, { type: 'array' });

            let result = new vmWorkbook(workbook);

            p.resolve({ data: result, success: true, reason: null });
        };

        reader.readAsArrayBuffer(file);

        return p.promise;
    }

    MediaOnClientToJson(client: IMediaOnClient, worksheet: number | string | null | undefined): ng.IPromise<IXlsxConversionResult> {
        if (client.status != MediaOnClientStatus.loaded) {
            return this.$q.when({ data: null, success: false, reason: 'The media client is not in the loaded state' });
        }

        if (client.data == null || client.data.data == null) {
            return this.$q.when({ data: null, success: false, reason: 'The media client does not have any data' });
        }

        if(client.data.loadedAsBlob) {
            return this.$q.when({ data: null, success: false, reason: 'The media client has been loaded as a blob, this is not currently supported for conversion to XLSX' });
        }

        let workbook: XLSX.WorkBook;
        if (this.isString(client.data.data)) {
            workbook = XLSX.read(this.vmMediaService.getDataFromString(client.data.data as string), { type: 'base64' });
        }
        else {
            workbook = XLSX.read(client.data.data, { type: 'array' });
        }

        let result = new vmWorkbook(workbook).sheetToJson(worksheet);

        return this.$q.when({ data: result, success: true, reason: null });
    }

    MediaOnClientToWorkbook(client: IMediaOnClient): ng.IPromise<IXlsxConversionResult> {
        if (client.status != MediaOnClientStatus.loaded) {
            return this.$q.when({ data: null, success: false, reason: 'The media client is not in the loaded state' });
        }

        if (client.data == null || client.data.data == null) {
            return this.$q.when({ data: null, success: false, reason: 'The media client does not have any data' });
        }

        if(client.data.loadedAsBlob) {
            return this.$q.when({ data: null, success: false, reason: 'The media client has been loaded as a blob, this is not currently supported for conversion to XLSX' });
        }

        let workbook: XLSX.WorkBook;
        if (this.isString(client.data.data)) {
            workbook = XLSX.read(this.vmMediaService.getDataFromString(client.data.data as string), { type: 'base64' });
        }
        else {
            workbook = XLSX.read(client.data.data, { type: 'array' });
        }

        let result = new vmWorkbook(workbook);

        return this.$q.when({ data: result, success: true, reason: null });
    }
}

app.factory('vmXlsx', vmXlsx);