'use strict';

import angular, { Injectable, IControllerConstructor } from 'angular';

export abstract class ControllerBase implements ng.IOnInit {
    constructor() {

    }

    $onInit(): void {

    }

    valueFromHtml(name: string, returnIfNoValue: any, $element: angular.IRootElementService): any {
        let val: string | undefined = $element.attr(name);

        if(val == undefined)
        {
            return returnIfNoValue;
        }

        return val;
    }
}

export abstract class ControlBase implements ng.IComponentOptions {
    public abstract controller?: string | Injectable<IControllerConstructor>;
    public controllerAs?: `$ctrl`;
    public abstract template?: string | Injectable<(...args: any[]) => string>;
    public bindings?: { [boundProperty: string]: string };
    public transclude?: false;
    public require?: {};
}