'use strict';

import app from '../ngmodule';
import angular from 'angular';

app.factory('playerActions', ['$rootScope', function ($rootScope) {
  return {
    doTransition: function (action) {
      if (action === 'next') {
        $rootScope.$broadcast('embedded-player.next');
      } else if (action === 'previous') {
        $rootScope.$broadcast('embedded-player.back');
      }
    }
  }
}]);