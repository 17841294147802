'use strict';

import angular from 'angular';

let app: ng.IModule | null = null;

app = angular.module('vm-common', []);

export default app as ng.IModule;

// now no matter what, add our dependancies
app.requires.push(
);
