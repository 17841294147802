const ngmodule = require('../ngmodule').default;

ngmodule
	.directive('vmCollapse', [`$document`, function ($document) {
		return {
			restrict: 'A',
			link: function (scope, elt) {
				const header = elt[0].children[0];
				const body = elt[0].children[1];

				const clickToCollapse = elt.attr('vm-collapse');
				const scrollIntoView = elt.attr('scroll-into-view');

				let collapseSelector = $(header).data("target");
				let $collapse = elt.find(collapseSelector);

				// The "body listener" is attached to "click touch" events _only_ when a collapse is opened so we can detect
				// when the user clicks "outside" the collapse and auto close it
				const bodyListener = (ev) => {
					const clickInHeader = header.contains(ev.target);
					const clickInBody = body.contains(ev.target);

					let close = false;
					switch (clickToCollapse) {
						case 'outside':
							close = !clickInHeader && !clickInBody;
							break;

						case 'true':
						default:
							if (ev.type === 'click') {
								close = !clickInHeader;
							} else {
								close = !clickInHeader && !clickInBody;
							}
							break;
					}
					if (close) {
						$collapse.collapse('hide');
					}
				}

				const bodyListenerEvents = ['click', 'touchend'];
				const addBodyListener = () => {
					bodyListenerEvents.forEach(function (ev) {
						$document[0].body.addEventListener(ev, bodyListener);
					});

				}

				const removeBodyListener = () => {
					bodyListenerEvents.forEach(function (ev) {
						$document[0].body.removeEventListener(ev, bodyListener);
					});
				}

				// When collapse is opened we can optionally scroll into view and attach our "body listener"
				const collapseOpened = () => {
					if (scrollIntoView) {
						elt[0].scrollIntoView(true);
					}

					addBodyListener();
				}
				$collapse.on('shown.bs.collapse', collapseOpened);

				// When the collapse closes we must remove our "body listener"
				const collapseClosed = () => {
					removeBodyListener();
				}
				$collapse.on('hide.bs.collapse', collapseClosed);

				// If the collapse is already opened, attach "body listener"
				if ($collapse.hasClass('collapse show')) {
					addBodyListener();
				}

				// Tidy up
				scope.$on('$destroy', () => {
					$collapse.off('show.bs.collapse', collapseOpened);
					$collapse.off('hide.bs.collapse', collapseClosed);
					removeBodyListener();
				});
			}
		}
	}]);
