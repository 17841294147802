'use strict';

import './index.scss';
import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';

export interface IProgressValueRange {
    min: number;
    max: number;
    circleClass: string;
    textClass: string;
}

class CircularProgressController extends ControllerBase {
    protected currentCircleClass: string;
    public circleClass: string;
    public textClass: string;
    public value: number;
    public valueRanges: Array<IProgressValueRange> | null;

    protected percentages: Array<IProgressValueRange>;

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();

        this.currentCircleClass = 'bg-primary';
        this.circleClass = '';
        this.textClass = '';
        this.value = 0;
        this.valueRanges = null;
        this.percentages = [];
    }

    $onInit() {
        super.$onInit();

        this.calculatePercentages();
    }

    protected calculateCurrentCircleClass() {
        if (this.value >= this.percentages.length) {
            return;
        }

        this.currentCircleClass = this.percentages[Math.round(this.value)].circleClass;
    }

    protected calculatePercentages() {
        this.percentages = [];

        let bgClass = 'bg-primary';
        let txtClass = 'text-primary';

        if (this.circleClass != null && this.circleClass != '') {
            bgClass = this.circleClass;
        }

        if (this.textClass != null && this.textClass != '') {
            txtClass = this.textClass;
        }

        for (let i = 0; i <= 100; i++) {
            let p = { circleClass: bgClass, textClass: txtClass, min: i, max: i };

            this.percentages.push(p);

            if (this.valueRanges == null) {
                continue;
            }

            for (let perc of this.valueRanges) {
                if (i < perc.min || i > perc.max) {
                    continue;
                }

                if (perc.circleClass != null && perc.circleClass != '') {
                    p.circleClass = perc.circleClass;
                }

                if (perc.textClass != null && perc.textClass != '') {
                    p.textClass = perc.textClass;
                }
            }
        }

        this.calculateCurrentCircleClass();
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.circleClass) {
            this.circleClass = changes.circleClass.currentValue;
        }

        if (changes.textClass) {
            this.textClass = changes.textClass.currentValue;
        }

        if (changes.value) {
            this.value = Math.round(changes.value.currentValue);
            this.calculateCurrentCircleClass();
        }

        if (changes.valueRanges) {
            this.valueRanges = angular.copy(changes.valueRanges.currentValue);
            this.calculatePercentages();
        }
    }
}

app.component('vmCircularProgress', {
    template: require('./template.html').default,
    bindings: {
        circleClass: '<',
        textClass: '<',
        valueRanges: '<',
        value: '<'
    },
    controller: CircularProgressController
});