export interface IDispose {
	dispose(): void;
}

export class Disposer {
	private disposables: { [group: string]: IDispose[] } = {};

	disposeAll(): void {
		for (let group in this.disposables) {
			let disposeGroup = this.disposables[group];

			disposeGroup.forEach(disposable => {
				disposable.dispose();
			})
		}
		this.disposables = {};
	}

	dispose(groups: string[]): void {
		groups.forEach(group => {
			let disposeGroup = this.disposables[group];

			if (disposeGroup != null) {
				disposeGroup.forEach(disposable => {
					disposable.dispose();
				})
			}
			delete this.disposables[group];
		});
	}

	add<TDispose extends IDispose>(disposable: TDispose, group: string = 'default'): TDispose {
		let disposeGroup = this.disposables[group];
		if (disposeGroup == null) {
			disposeGroup = [];
			this.disposables[group] = disposeGroup;
		}
		if (disposeGroup.indexOf(disposable) == -1) {
			disposeGroup.push(disposable);
		}
		return disposable;
	}

	remove(disposable: IDispose) {
		Object.keys(this.disposables).forEach(group => {
			const disposeGroup = this.disposables[group];
			const i = disposeGroup.indexOf(disposable);
			if (i >= 0) {
				disposeGroup.splice(i, 1);
			}
		})
	}
}

export class DisposeFunction implements IDispose {
	constructor(private fn: () => void) {

	}

	dispose() {
		this.fn();
	}
}