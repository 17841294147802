import app from './ngmodule';
import { IValidationMessageFactory, IValidationResult, IValidationRule } from '../';

// Validation rule that fails if the value handed into it is undefined or null
export abstract class ValidationRuleBase implements IValidationRule {
    constructor(public message: string | IValidationMessageFactory) {
        this.message = message;
    }

    protected isValidationMessageFactory(test: any): test is IValidationMessageFactory {
        return (test as IValidationMessageFactory).call !== undefined;
    }

    protected getMessage(value: any, template: any): string {
        if (this.isValidationMessageFactory(this.message)) {
            return this.message(this, value, template);
        }
        else {
            return this.message;
        }
    }

    abstract isValid(value: any, template: any): IValidationResult;
}