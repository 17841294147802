// This file will import a set of libraries that are expected to be used by Lingo Js checklists,
// you might be tempted to simply allow 
//
// var y = 'some_random_npm_library'
// var x = require(y)
//
// However, webpack likes to statically import stuff, and the above is not statically resolvable and 
// results in a Webpack warning
//
// Critical dependency: the request of a dependency is an expression
//
// This can be solved by producing a map object which takes expected library names and require them explicitly
// with the restriction that only the following list can be require'd in a checklist
export interface LingoJsRequireableModules<T> {
	"angular": T;
	"angular-filter": T;
	"angular-sanitize": T;
	"base64-arraybuffer": T;
	"jquery": T;
	"lodash": T;
	"moment": T;
	"moment-timezone": T;
	"sprintf-js": T;
	"text-encoding": T;
	"uuid": T;
}

const requireMap: any = {
	"angular": require("angular").default,
	"angular-filter": require("angular-filter").default,
	"angular-sanitize": require("angular-sanitize").default,
	"base64-arraybuffer": require("base64-arraybuffer").default,
	"jquery": require("jquery").default,
	"lodash": require("lodash").default,
	"moment": require("moment").default,
	"moment-timezone": require("moment-timezone").default,
	"sprintf-js": require("sprintf-js").default,
	"text-encoding": require("text-encoding").default,
	"uuid": require("uuid").default
}

export default requireMap;