'use strict';

import './index.scss';
import './../vm-radio';
import './../vm-validation-error-list';
import { v4 as uuidv4 } from 'uuid';

import app from '../ngmodule';
import { ControllerWithChoicesBase, ISelectionEvent } from '../control-with-choices-base';
import angular from 'angular';
import { IProgressValueRange } from '../vm-circular-progress';
import { IValidationService } from '../../services/validator-service';

class RadioListController extends ControllerWithChoicesBase {
    public name: string;
    public disabled: boolean;

    static $inject = ['$element', '$sce', 'vmValidationService'];
    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, validationService: IValidationService) {
        super($element, $sce, validationService);

        this.name = 'radio' + uuidv4();
        this.disabled = false;
    }

    changed(evt: ISelectionEvent): void {
        // we need to manage our other choices and unselect them
        this.unselectAll();
        super.changed(evt);

        this.validate(this.selected);
    }
}

app.component('vmRadioList', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after'
    },        
    bindings: {
        choices: '<',
        selected: '<',
        displayField: '@',
        valueField: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        validator: '=',        
        prompt: '<',
        disabled: '<',
        onUpdate: '&'
    },
    controller: RadioListController
});