'use strict';

import './index.scss';

import app from '../ngmodule';
import angular from 'angular';
import './vm-type-ahead-drop-down';
import { ControllerWithValidationBase } from '../control-with-validation-base';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { ITypeAheadDataSource, ITypeAhead, ITypeAheadDropDown, ITypeAheadCallbackDataItem, ITypeAheadDropDownSelectionArgs } from './interfaces';
import { OdataTypeAheadDataSource } from './odata-type-ahead-data-source';
import { IValidationService } from '../../services/validator-service';
import { IVMComponentUtilities } from '../../services/vm-component-utilities';
import { resolve } from 'path';

const Arrow_Up_Keycode: number = 38;
const Arrow_Down_Keycode: number = 40;
const Escape_Keycode: number = 27;
const Enter_Keycode: number = 13;

class TypeAheadController extends ControllerWithValidationBase implements IVMRegisteredComponent, ITypeAhead {
    public value?: string;
    public minChars: number;
    public delay: number;
    public useCache: boolean;
    public name: string;
    public source: any;
    // An object that is two way databound to the consumer of this component. The exact value that goes into this is dependent upon the source and the itemSelector
    public item: any;
    // a dot notation selects (this.that.something etc) that is used to select the value that goes into item when a selection is made.
    // So for example, in an odata source the object selected is an odata object, so without an itemSelector the item would be the odata object,
    // to make things easier for consumers of this though, if they wanted the Name property to be returned the itemSelector would be something like alldata.Name
    public itemSelector: any;

    protected searchText: string;
    protected searchResults: Array<ITypeAheadCallbackDataItem>;
    protected staticResults: Array<ITypeAheadCallbackDataItem>;
    protected debounceOptions: any;
    protected cache: any;

    protected dataSourceObject: ITypeAheadDataSource | null;
    protected dropDown: ITypeAheadDropDown | null;

    public onUpdate?: null | ((args: any) => void) = null;

    static $inject = ['$element', '$sce', 'vmRegisteredComponentProvider', 'bworkflowApi', '$timeout', '$compile', '$scope', 'vmValidationService', 'vmComponentUtilities'];
    constructor($element: ng.IRootElementService, 
        $sce: ng.ISCEService, 
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider, 
        protected bworkflowApi: any, 
        protected $timeout: ng.ITimeoutService, 
        protected $compile: ng.ICompileService,
        protected $scope: ng.IScope,
        validationService: IValidationService, 
        protected vmComponentUtilities: IVMComponentUtilities) {
        super($element, $sce, validationService);

        this.minChars = 3;
        this.delay = 150;
        this.useCache = true;
        this.name = '';

        this.dataSourceObject = null;

        this.searchText = '';
        this.searchResults = [];
        this.staticResults = [];
        this.cache = {};

        this.dropDown = null;
    }

    getComponentType(): string {
        return 'TypeAhead';
    }

    setDropDown(dropDown: ITypeAheadDropDown): void {
        this.dropDown = dropDown;
    }

    $onInit(): void {
        super.$onInit();

        if (this.name != null) {
            this.vmRegisteredComponentProvider.register(this);
        }

        if (this.minChars == null) {
            this.minChars = 3;
        }

        if (this.delay == null) {
            this.delay = 150;
            this.createDebounce();
        }

        if (this.useCache == null) {
            this.useCache = true;
        }

        if(this.value != null) {
            this.searchText = this.value;
        }

        this.$timeout(() => {
            // we need to initialise any mdb elements loaded dynamically, the username/password are hidden until things load
            // so we need to give them a kick once loading = false
            this.$element.find('input').kendoTextBox({
                label: {
                    content: this.prompt,
                    floating: true
                }
            });           

            let html = this.$compile(`<span class="k-input-sufix" ng-click="$ctrl.clear()"><span class="k-input-icon k-icon k-i-x"></span></span>`)(this.$scope);
            this.$element.find('.k-input.k-textbox').append(html);
        });
    }

    createDebounce() {
        this.debounceOptions = { debounce: this.delay };
    }

    createAutoComplete() {
        if (this.source == null) {
            return;
        }

        // we expect one of the following types for the dataSource property
        // 1. string -> This is taken as the name of an odata feed
        // 2. odata feed object
        // 3. a ITypeAheadOdataSourceTemplate
        // 4. some object that implements ITypeAheadDataSource

        if (typeof this.source === 'string') {
            // this is option 1
            this.bworkflowApi.getDataFeed(this.source).then((feed: any) => {
                this.dataSourceObject = new OdataTypeAheadDataSource(feed, null, null);
            });
        }
        else if (this.source.search != null) {
            // this is option 4
            this.dataSourceObject = this.source;
        }
        else if (this.source.getData != null) {
            // this is option 2
            this.dataSourceObject = new OdataTypeAheadDataSource(this.source, null, null);
        }
        else if (this.source.feed != null) {
            // this is option 3
            this.dataSourceObject = new OdataTypeAheadDataSource(this.source.feed, this.source.queryFieldName, this.source.displayFieldName);
        }

        if (this.dataSourceObject == null || this.dataSourceObject.staticItems == null) {
            return;
        }

        this.dataSourceObject.staticItems((values) => {
            this.staticResults = values;
            this.searchResults = values;
        });
    }

    $onDestroy(): void {
        this.vmRegisteredComponentProvider.deRegister(this);
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.source) {
            this.source = changes.source.currentValue;
            this.createAutoComplete();
        }

        if (changes.delay) {
            this.delay = changes.delay.currentValue;
            this.createDebounce();
        }
    }

    showChoices(): void {
        if (this.dropDown == null) {
            return;
        }

        if (this.searchText.length < this.minChars || this.searchResults.length == 0) {
            this.hideChoices();
            return;
        }

        this.dropDown.show(this.$element.find('.content')[0]);
    }

    hideChoices(): void {
        if (this.dropDown == null) {
            return;
        }

        this.dropDown.hide();
    }

    dropDownItemSelected($event: ITypeAheadDropDownSelectionArgs) {
        this.searchText = $event.item.text;
        this.hideChoices();

        let resolvedItem = $event.item;

        // so we support being able to select as our item a sub object of the item actually selected. This is done through dot notation. This helps support odata, so you can have alldata.name for example
        // where name would then be the item, even though the odata objects are what are being used to list things
        resolvedItem = this.vmComponentUtilities.getValueFromDotNotation(resolvedItem.data ? resolvedItem.data : resolvedItem, this.itemSelector);

        this.validate(resolvedItem);

        this.item = resolvedItem;

        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { value: $event.item.text, item: resolvedItem, selecteditem: $event.item, control: this } });
    }

    searchTextChanged(): void {
        if (this.dataSourceObject == null) {
            return;
        }

        if (this.searchText.length < this.minChars) {
            this.hideChoices();
            return;
        }

        if (this.useCache) {
            if (this.cache[this.searchText]) {
                this.showChoices();
                this.searchResults = this.cache[this.searchText];
                return;
            }
        }

        this.dataSourceObject.search(this.searchText, (items) => {
            this.searchResults = items.concat(this.staticResults);

            if (this.useCache) {
                this.cache[this.searchText] = items;
            }

            // we use a timeout here to give a dispatch cycle for the searchResults to propogate down to the
            // drop down component
            this.$timeout(() => {
                this.showChoices();
            });
        });
    }

    iterceptKeyStroke($event: any): void {
        if ($event.which == Enter_Keycode && this.searchText == '') {
            // no text and the enter key has been pressed, we'll fire an update event with nothing selected to support the case of clearing things
            this.clear($event.item);

            return;
        }

        if (this.dropDown == null) {
            return;
        }

        if ($event.which == Arrow_Down_Keycode) {
            this.dropDown.selectionDown();
            $event.preventDefault();
        }
        else if ($event.which == Arrow_Up_Keycode) {
            this.dropDown.selectionUp();
            $event.preventDefault();
        }
        else if ($event.which == Escape_Keycode) {
            this.hideChoices();
            $event.preventDefault();
        }
        else if ($event.which == Enter_Keycode) {
            this.dropDown.selectItem(null);
            $event.preventDefault();
        }
    }

    clear(item: any): void {
        this.validate(item);

        this.item = undefined;
        this.searchText = '';

        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { value: '', item: null, control: this } });
    }
}

app.component('vmTypeAhead', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after'
    },    
    bindings: {
        value: '<',
        name: '@',
        source: '<',
        minChars: '<',
        delay: '<',
        useCache: '<',
        prompt: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        onUpdate: '&',
        validator: '=?',
        item: '=?',
        itemSelector: '<'
    },
    controller: TypeAheadController
});