'use strict';

import app from '../../ngmodule';
import './style.scss';

app.component('questionTasklistShortcutTile', {

  template: require('./template.html').default,
  bindings: {
    'text': '<',
    'leftIcon': '<',
    'rightIcon': '<',
    'mediaId': '<'
  },

  controller: ["webServiceUrl", function (webServiceUrl) {
    this.url = webServiceUrl;

    const makeIcon = (name, value) => {
      if (value) {
        const split = value.split(' ');
        this[`${name}IconFont`] = split[0];
        this[`${name}IconName`] = split[1];
      }
    };
    this.$onInit = () => {
      makeIcon('left', this.leftIcon);
      makeIcon('right', this.rightIcon);
    };
  }]
});