'use strict';

import './window-init';
import './player';
import './routes';
import './config';
import './views';
import './services';
import { useTokenAuthService } from './services/auth';
import './common';
import '@fortawesome/fontawesome-free/js/all';
import 'typeface-roboto';
import '@popperjs/core';
import 'snackbarjs';
import 'arrive';
import './img';
import "@progress/kendo-theme-material/dist/all.scss";
import "./scss/import.scss";
//import "./scss/application/index.scss";
import 'animate.css/animate.css';

// Make Angular _ available to anyone
import './utils/angularise-libraries';

console.log(`BUILDNUMBER=${process.env.BUILD_BUILDNUMBER}`);