'use strict';

import '../auth';
import app from '../ngmodule';
import angular from 'angular';

export interface ISiteMapNodeRoute {
    name: string;
    args?: string;
}

export interface ISiteMapNode {
    name: string;
    oneOfRoles?: Array<string>;
    items: Array<ISiteMapNode>;
    route?: ISiteMapNodeRoute;
    isCurrent?: boolean;
    dashboards?: boolean;
}

export interface ISiteMapService {
    getSiteMap(): ng.IPromise<ISiteMapData>;
    setCurrentNode(routeName: string, args: string | undefined): void;
    navigateTo(node: ISiteMapNode): void;
    navigateToDashboard(dashboardName: string, callback?: any): void;
}

export interface ISiteMapData {
    map: Array<ISiteMapNode>; // this is the full merged site map, but does not contain any hidden dashboards
    hiddenDashboards: Array<ISiteMapNode>; // this is the set of hidden dashboards
    dashboardData: any; // this is the raw result of calling the bworkflow getdashboards api method
}

// for the moment we aren't looking to include the admin stuff in the spa, so we're only going to define the dashboards side of things for the moment
const SiteMapTemplate = [
    {
        name: 'Dashboards',
        route: {
            name: 'root.dashboard'
        },
        dashboards: true,
        items: []
    },
    {
        name: 'My',
        items: [
            {
                name: 'Checklists',
                route: {
                    name: 'root.select.groups'
                },
                items: []
            }
        ],
        oneOfRoles: ['User']
    }
]

/* This is an example of a fuller site map template

const SiteMapTemplate = [
    {
        name: 'Dashboards',
        route: {
            name: 'root.dashboard'
        },
        dashboards: true,
        items: []
    },
    {
        name: 'My',
        items: [
            {
                name: 'Checklists',
                route: {
                    name: 'root.select.groups'
                },
                items: []
            }
        ],
        oneOfRoles: ['User']
    },
    {
        name: 'Designers',
        items: [
            {
                name: 'Resources',
                oneOfRoles: ['ResourceManager'],
                items: []
            },

            {
                name: 'Publishing',
                oneOfRoles: ['PublishingManager'],
                items: []
            }],


        oneOfRoles: ['ResourceManager', 'PublishingManager']
    }];

*/

// The siteMapBuilder is responsible for merging the siteMapTemplate with the current users roles and the set of dashboards they have
// and returning an array of ISiteMapNode objects that represent the access the user has to the various area's of the system and the dashboards
// they have access to. This class is not exposed otuside of this file.
class SiteMapBuilder {
    createNode(from: ISiteMapNode): ISiteMapNode {
        let result = {
            name: from.name,
            route: from.route,
            items: [],
            isCurrent: false,
        };

        return result;
    }

    buildSiteMapNode(node: ISiteMapNode, roles: Array<string>): ISiteMapNode | null {
        var result = this.createNode(node);

        if (node.oneOfRoles != null) {
            var hasAccess = false;

            for (let role of node.oneOfRoles) {
                if (roles.indexOf(role) != -1) {
                    hasAccess = true;
                }
            }

            if (hasAccess == false) {
                return null;
            }
        }

        if (node.items != null) {
            for (let item of node.items) {
                var n = this.buildSiteMapNode(item, roles);

                if (n != null) {
                    result.items.push(n);
                }
            }
        }

        return result;
    }

    buildDashboardMapNode(dashboard: any): ISiteMapNode {
        var result = this.createNode(
            {
                name: dashboard.ChecklistName,
                route: {
                    name: 'root.dashboard.name',
                    args: dashboard.ChecklistName
                },
                items: []
            });

        return result;
    }

    buildSiteMap(roles: Array<string>, dashboards: Array<any>): ISiteMapData {
        var result = {
            map: new Array<ISiteMapNode>(),
            hiddenDashboards: new Array<ISiteMapNode>(),
            dashboardData: null
        };

        for (let node of SiteMapTemplate) {
            var n = this.buildSiteMapNode(node, roles);

            if (n == null) {
                break;
            }

            result.map.push(n);

            // if its the dashboards node, then we need to merge in the dashboards with
            // that node too
            if (node.dashboards != null) {
                n.dashboards = true;

                for (let dashboard of dashboards) {
                    // if a dashboard starts with an underscore then its not for interactions with the user directly
                    // but instead is doing things in background or with widgets and is treated differently (hidden)
                    if (dashboard.ChecklistName.startsWith('_') == false) {
                        n.items.push(this.buildDashboardMapNode(dashboard));
                    }
                    else {
                        result.hiddenDashboards.push(this.buildDashboardMapNode(dashboard));
                    }
                }
            }
        }

        return result;
    }
}

class SiteMapService implements ISiteMapService {
    private map: ISiteMapData | null;
    private storedRoute: ISiteMapNodeRoute | null;
    private currentNode?: ISiteMapNode;
    private builder: SiteMapBuilder;

    private dashboardTransitionCallback: any;

    static $inject = ["authSvc", "$q", "$state", "bworkflowApi", "$rootScope", "$transitions", "$timeout"];
    constructor(protected authSvc: any, protected $q: ng.IQService, protected $state: any, protected bworkflowApi: any, protected $rootScope: ng.IRootScopeService, protected $transitions: any, protected $timeout: ng.ITimeoutService) {
        this.builder = new SiteMapBuilder();
        this.map = null;
        this.storedRoute = null;


        this.$transitions.onSuccess({}, () => {
            if(!this.dashboardTransitionCallback)
            {
                return;
            }

            let callback = this.dashboardTransitionCallback;
            this.dashboardTransitionCallback = undefined;

            this.$timeout(() => {
                callback();
            });
        });
    }

    protected findMapNodeByRouteOnThread(node: ISiteMapNode, routeName: string, args: string | undefined): ISiteMapNode | null {
        if (node.route != null) {
            if (node.route.name == routeName) {
                if (args != null) {
                    if (node.route.args == args) {
                        return node;
                    }
                } else {
                    return node;
                }
            }
        }

        var n = null;

        for (var i = 0; i < node.items.length; i++) {
            n = this.findMapNodeByRouteOnThread(node.items[i], routeName, args);

            if (n != null) {
                break;
            }
        }

        return n;
    }

    protected findMapNodeByRoute(routeName: string, args: string | undefined): ISiteMapNode | null {
        if (this.map == null) {
            return null;
        }

        var result = null;

        for (var i = 0; i < this.map.map.length; i++) {
            result = this.findMapNodeByRouteOnThread(this.map.map[i], routeName, args);

            if (result != null) {
                break;
            }
        }

        return result;
    }

    getSiteMap(): ng.IPromise<ISiteMapData> {
        if (this.map != null) {
            return this.$q.when(this.map);
        }

        return this.authSvc.getUser().then((user: any) => {
            if (user.authenticated == false) {
                console.log('User is not authenticated, site map denied until authenticated');
                return;
            }

            var me = this;

            return this.bworkflowApi.getDashboard().then(function (data: any) {
                if (me.map != null) {
                    // we can end up here if two or more calls come in before we've loaded
                    return me.$q.when(me.map);
                }

                me.map = me.builder.buildSiteMap(user.roles, data.Dashboards);
                me.map.dashboardData = data;

                if (me.storedRoute != null) {
                    me.setCurrentNode(me.storedRoute.name, me.storedRoute.args);
                    me.storedRoute = null;
                }

                return me.$q.when(me.map);
            });
        });
    }

    setCurrentNode(routeName: string, args: string | undefined): void {
        if (this.map == null) {
            this.storedRoute = { name: routeName, args: args };
            return;
        }

        var node = this.findMapNodeByRoute(routeName, args);

        if (node == null) {
            return;
        }

        if (this.currentNode != null) {
            this.currentNode.isCurrent = false;
        }

        if (node.dashboards != null && node.dashboards == true) {
            // so it's the root dashboard node that's been returned. We'll
            // select it's first item instead of it
            if (node.items.length > 0) {
                this.navigateTo(node.items[0]);
            }
        }

        node.isCurrent = true;

        this.currentNode = node;
    }

    navigateTo(node: ISiteMapNode): void {
        if (node == null) {
            return;
        }

        if (node.route == null) {
            return;
        }

        var args = {
            name: ''
        };

        if (node.route.args != null) {
            args.name = node.route.args;
        }

        this.$state.go(node.route.name, args);
    }

    navigateToDashboard(dashboardName: string, callback?: any): void {
        let node = this.findMapNodeByRoute('root.dashboard.name', dashboardName);

        if(node == null)
        {
            return;
        }

        if(node.isCurrent)
        {
            if(callback)
            {
                callback();
            }
            
            return;
        }

        this.dashboardTransitionCallback = callback;
        this.navigateTo(node);
    }
}

app.factory('siteMapService', SiteMapService);