import angular from "angular";
import { IWebServiceUrl } from "../../common/web-service-url";
import ngmodule from "./ngmodule";

export type IPlayerExecute = (handler: string, method: string, parameters: any, timeout?: number) => ng.IPromise<any>;

ngmodule.factory(`PlayerExecute`, [
	`webServiceUrl`,
	`$http`,
	function (
		webServiceUrl: IWebServiceUrl,
		$http: ng.IHttpService
	): IPlayerExecute {
		return function (handler: string, method: string, parameters: any, timeout?: number) {
			let opts: ng.IRequestConfig = {
				url: webServiceUrl.api('Player/Execute'),
				method: 'POST',
				data: {
					Handler: handler,
					Method: method,
					Parameters: parameters
				}
			}
			if (angular.isDefined(timeout)) {
				opts.timeout = timeout;
			}

			return $http(opts).then(response => {
				return response.data;
			})
		}
	}])
