import moment from 'moment';
import app from './ngmodule';
import angular from 'angular';

export interface IVMDateTimeFormats {
    dateFormat: string;
    dateTimeFormat: string;
    timeFormat: string;
}

export interface IVMLocaleService {
    getDateTimeFormats(): IVMDateTimeFormats;
}

class vmLocaleService implements IVMLocaleService {
    private formats: IVMDateTimeFormats;

    constructor() {
        this.formats = {
            dateFormat: moment().creationData().locale.longDateFormat('LL'),
            dateTimeFormat: moment().creationData().locale.longDateFormat('LLL'),
            timeFormat: moment().creationData().locale.longDateFormat('LT')
        };
    }

    getDateTimeFormats(): IVMDateTimeFormats {
        return angular.copy(this.formats);
    }
}

app.factory('vmLocaleService', vmLocaleService);