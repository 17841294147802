'use strict';

import angular from 'angular';
import '../../filters/bworkflow-filters';
import 'angular-cookies';
import 'angular-resource';
import '../requests-error-handler';
import '../question-models';
import { rtrim, ltrim, generateCombGuid } from '../../utils/util';
import moment from 'moment';
import bworkflowApiModule from './ngmodule';

import '../checklist-player';

export default bworkflowApiModule;

bworkflowApiModule.factory('bworkflowApi', [
	'$q',
	'$resource',
	'$rootScope',
	'$timeout',
	'RequestsErrorHandler',
	'$http',
	'$interpolate',
	'$geolocation',
	'$filter',
	'$sce',
	'taskTypesService',
	'persistantStorage',
	'questionTaskListModels',
	'$window',
	'checklistPlayer',
	'webServiceUrl',
	'PlayerExecute',
	'authSvc',
	'BworkflowStorage',
	function (
		$q,
		$resource,
		$rootScope,
		$timeout,
		RequestsErrorHandler,
		$http,
		$interpolate,
		$geolocation,
		$filter,
		$sce,
		taskTypesService,
		persistantStorage,
		questionTaskListModels,
		$window,
		checklistPlayer,
		webServiceUrl,
		PlayerExecute,
		authSvc,
		BworkflowStorage
	) {
		// The execute method of bworkflowApi, allows for certain calls to be handled in a specific way
		// This is done through the executionHandlers object. The discovery of wether or not a call is to be handled
		// in a special way is done by searching the executionHandlers properties for something that mataches the handler
		// parameter. If this exists the object returned is inspected for a method with the same names as the method parameter.
		// If this exists, it is called with the same set of parameters being handed in. The method is expected to return
		// a promise.

		// Internal utility functions should go here
		var _compareTaskById = function (id) {
			return function (task) {
				return task.id == id;
			};
		};

		// This function will retry the fnPromise every 5 seconds until success
		function prefetchAndRetry(fnPromise) {
			fnPromise().catch(function (err) {
				$timeout(function () {
					prefetchAndRetry(fnPromise);
				}, 5000);
			});
		}

		const ifAuthenticated = fn => {
			return () => {
				return authSvc.isAuthenticated().then(authenticated => {
					if (!authenticated) {
						throw new Error('Not authenticated');
					}
					return fn();
				})
			}
		}

		prefetchAndRetry(ifAuthenticated(taskTypesService.getTaskTypes));
		prefetchAndRetry(ifAuthenticated(taskTypesService.getTaskTypeFinishedStatuses));
		prefetchAndRetry(ifAuthenticated(taskTypesService.getFinishedStatuses));
		prefetchAndRetry(ifAuthenticated(taskTypesService.getTaskTypeRelationships));

		// specialised handling of execution methods directed at the task list
		var taskListManagementExecutionHandler = {
			localStorageTasksKey: 'tasklistmanagement.tasks',
			supportOffline: true,

			_buildTaskListData: function (data) {
				// the data sent down for the task list is minimized somewhat. We need to unpack it a little
				// not allot, so code downline of us gets what its expecting.
				if (data.opentasks != null) {
					for (var i = 0; i < data.opentasks.length; i++) {
						var t = data.opentasks[i];
						var tt = data.tasktypes[t.tasktypeid];

						// easy ones first
						t.documentation = angular.copy(tt.documentation);
						t.finishedstatuses = angular.copy(tt.finishedstatuses);
						t.pausedstatuses = angular.copy(tt.pausedstatuses);
						t.statuses = angular.copy(tt.statuses);
						t.relationships = angular.copy(tt.relationships);

						if (t.starttime) {
							t.starttime = moment(t.starttime).toDate();
							if (t.lateafter) {
								t.lateafter = moment(t.lateafter).toDate();
							}
						}

						// Reference the TaskType directly for other stuff
						t.tasktype = tt;

						// slightly more complicated is the published resources, as these are a merge of what
						// the task has and what the task type has
						for (var j = 0; j < t.publishedresources.length; j++) {
							var p = t.publishedresources[j];

							angular.extend(p, data.tasktypes[t.tasktypeid].publishedresources[p.publishedresourceid]);
						}

						// copy across catalogs if where we can
						t.catalogs = [];

						if (angular.isDefined(t.site) == false) {
							continue;
						}

						for (var j = 0; j < data.catalogs.length; j++) {
							var cat = data.catalogs[j];

							// the task type can define what catalog names it will list,
							// so filter on this if we need to
							if (t.tasktype.catalogs.length > 0) {
								var addCat = false;
								angular.forEach(t.tasktype.catalogs, function (c) {
									if (c == cat.name) {
										addCat = true;
									}
								});

								if (addCat == false) {
									continue;
								}
							}

							for (var k = 0; k < cat.sites.length; k++) {
								if (angular.isDefined(t.site) == false || t.site == null) {
									continue;
								}

								if (cat.sites[k] == t.site.id) {
									t.catalogs.push(angular.copy(cat));
									break;
								}
							}
						}
					}
				}
			},
			_findTaskById: function (id) {
				for (var i = 0; i < bworkflowApiService.cachedTaskListData.opentasks.length; i++) {
					var t = bworkflowApiService.cachedTaskListData.opentasks[i];

					if (t.id == id) {
						return t;
					}
				}
			},
			_removeTaskFromCache: function (task, persist) {
				if (angular.isDefined(task) == false) {
					return;
				}
				// removes the task from the in memory cache.

				if (angular.isDefined(bworkflowApiService.cachedTaskListData.closedTasks) == false) {
					bworkflowApiService.cachedTaskListData.closedTasks = {};
				}

				// move it off to a closed tasks list, we use this in case the server
				// hasn't yet heard about a task being closed and upon refresh wants us to add it back
				// into our open list
				bworkflowApiService.cachedTaskListData.closedTasks[task.id] = {
					id: task.id
				};


				// remove from our open list and everything should be fine from there
				var index = bworkflowApiService.cachedTaskListData.opentasks.indexOf(task);
				if (index != -1)
					bworkflowApiService.cachedTaskListData.opentasks.splice(index, 1);

				if (angular.isDefined(persist) && persist == true) {
					bworkflowApiService.toLocalStorage(taskListManagementExecutionHandler.localStorageTasksKey, bworkflowApiService.cachedTaskListData);
				}
			},
			_getServerAdditions: function (data) {
				var toAdd = [];

				// EVS-447 Enable Auto-Refresh on Supervisor ToDo List
				if (angular.isDefined(bworkflowApiService.cachedTaskListData.closedTasks) == false) {
					bworkflowApiService.cachedTaskListData.closedTasks = {};
				}

				for (var i = 0; i < data.opentasks.length; i++) {
					var t = data.opentasks[i];

					var found = false;

					// make sure we haven't added it to our closed list
					if (angular.isDefined(bworkflowApiService.cachedTaskListData.closedTasks[t.id]) == true) {
						// in the closed list, no more to do
						found = true;
					}

					if (found == false) {
						for (var j = 0; j < bworkflowApiService.cachedTaskListData.opentasks.length; j++) {
							if (t.id == bworkflowApiService.cachedTaskListData.opentasks[j].id) {
								found = true;
								break;
							}
						}
					}

					if (found == false) {
						toAdd.push(t.id);
					}
				}

				return toAdd;
			},
			_manageServerRemovals: function (data) {
				// we are looking for tasks that we have as open, but the server doesn't have anymore
				var toRemove = [];
				for (var i = 0; i < bworkflowApiService.cachedTaskListData.opentasks.length; i++) {
					var t = bworkflowApiService.cachedTaskListData.opentasks[i];

					var found = false;
					for (var j = 0; j < data.opentasks.length; j++) {
						if (t.id == data.opentasks[j].id) {
							found = true;
							break;
						}
					}

					if (found == false && !t.createdLocally) {
						toRemove.push(t);
					}
				}

				if (toRemove.length > 0) {
					for (var i = 0; i < toRemove.length; i++) {
						taskListManagementExecutionHandler._removeTaskFromCache(toRemove[i], false);
					}

					bworkflowApiService.toLocalStorage(taskListManagementExecutionHandler.localStorageTasksKey, bworkflowApiService.cachedTaskListData);
				}

				return toRemove;
			},
			_manageSingleClockinToggle: function (task, originalParams) {
				var parameters = {
					userid: originalParams.userid,
					taskid: task.id,
					enforcesingleclockin: false,
					singleclockinmode: 'None',
					location: originalParams.location,
					notes: '',
					attemptclaim: false
				};

				bworkflowApiService.execute('TaskListManagement', 'ToggleClockinClockout', parameters);
			},
			_manageSingleClockinHelperNone: function (task, originalParams) {
				// This is the handler for None, so we don't do anything
			},
			_manageSingleClockinHelperPause: function (task, originalParams) {
				// Pause handler, so if the task active we pause it
				if (task.status != 'active') {
					return; // nothing to do with this one
				}

				task.status = 'paused';
				taskListManagementExecutionHandler._manageSingleClockinToggle(task, originalParams);
			},
			_manageSingleClockinHelperResume: function (task, originalParams) {
				// Resume handler, so we resume any task that is paused
				if (task.status != 'paused') {
					return; // nothing to do with this one
				}

				task.status = 'active';
				taskListManagementExecutionHandler._manageSingleClockinToggle(task, originalParams);
			},
            _manageSingleClockinHelperFinish: function (task, originalParams) {
				taskListManagementExecutionHandler._manageSingleClockinHelperFinishWithOptions(task, originalParams, false);
			},
			_manageSingleClockinHelperFinishComplete: function (task, originalParams) {
				taskListManagementExecutionHandler._manageSingleClockinHelperFinishWithOptions(task, originalParams, true);
			},

			_manageSingleClockinHelperFinishWithOptions: function (task, originalParams, completeFlag) {
				// Finish handler, we finish any task that is active or paused
				if (task.status != 'active' && task.status != 'paused') {
					return;
				}

				var status = task.status;

				task.status = 'finished';
				var parameters = {
					userid: originalParams.userid,
					taskid: task.id,
					enforcesingleclockin: false,
					singleclockinmode: 'None',
					location: originalParams.location,
					complete: completeFlag,
					completionnotes: 'Finished by other area scan',
					finishedstatuses: originalParams.finishedstatuses,
					workinggroupdata: null,
					clockout: status == 'active'
				};


				bworkflowApiService.execute('TaskListManagement', 'Finish', parameters, 5000);

				bworkflowApiService.notifyCachedTaskListDataNotificationReceiver(task.id, bworkflowApiService.cachedTaskListNotificationType.taskRemoved);
			},
			_manageSingleClockinMode: function (parameters) {
				if (parameters.enforcesingleclockin == true) {
					// we need to manage the status of the task that is currently clocked it
					// since its single clock in environment, we run through and pause anything that's active
					for (var i = 0; i < bworkflowApiService.cachedTaskListData.opentasks.length; i++) {
						var t = bworkflowApiService.cachedTaskListData.opentasks[i];

						if (t.status == 'active' && t.id != parameters.taskid) {
							t.status = 'paused';
						}
					}

					return;
				}

				if (parameters.singleclockinmode == 'Rules') {
					var task = taskListManagementExecutionHandler._findTaskById(parameters.taskid);
					if (task == null) {
						return null;
					}
					var level = bworkflowApiService.facilityHierarchyTaskTypeModel.findFacilityHierarchyLevelForTaskType(task.tasktypeid);

					if (level == null) {
						return;
					}

					var resolver = function (t) {
						if (t == task) {
							// if the task is the task we are clocking into/out of then signal its not of interest
							return null;
						}

						return t.tasktypeid;
					};

					// by the time we get here, the status of the task has been changed to what it is going to be
					var action = task.status == 'paused' || task.status == 'finished' ? questionTaskListModels.actionType.clockout : questionTaskListModels.actionType.clockin;

					var actions = bworkflowApiService.facilityHierarchyTaskTypeModel.calculateTaskActions(bworkflowApiService.cachedTaskListData.opentasks, resolver, level, action);

					for (var i = 0; i < actions.length; i++) {
						var a = actions[i];

						for (var j = 0; j < a.tasks.length; j++) {
							var otherTask = a.tasks[j];
							taskListManagementExecutionHandler["_manageSingleClockinHelper" + a.action](otherTask, parameters);
						}
					}
				}
			},
			_genericOfflineMethod: function (handler, method, parameters, timeout, save, callbackFn) {
				var callbackPromise;
				if (taskListManagementExecutionHandler.supportOffline == false) {
					callbackPromise = $q.all({
						execute: PlayerExecute(handler, method, parameters, timeout)
					});

					if (angular.isFunction(callbackFn)) {
						callbackPromise = callbackPromise.then(callbackFn);
					}
					return callbackPromise;
				}

				// at the moment, I'm assuming that the caller has set the state of the task
				// object appropriately. This may be better modelled here, will think on this
				callbackPromise = bworkflowApiService.queueExecutionCall(handler, method, parameters, timeout);
				if (angular.isFunction(callbackFn)) {
					callbackPromise.then(callbackFn);
				}

				if (angular.isDefined(save) && save == true) {
					bworkflowApiService.toLocalStorage(taskListManagementExecutionHandler.localStorageTasksKey, bworkflowApiService.cachedTaskListData);
				}

				var deferred = $q.defer();

				$timeout(function () {
					deferred.resolve();
				});

				return deferred.promise;
			},

			GetTasks: function (handler, method, parameters, timeout, forceonline) {
				// we intercept get task so that we can maintain the cache of tasks for the
				// user in localstorage. We also keep the set of tasks in memory against the API
				// for quick reference and manipulation by other methods.
				var deferred = $q.defer();

				if (angular.isDefined(forceonline) == false) {
					// by default we'll go offline
					forceonline = false;
				}

				if (forceonline == false) {
					// unless the caller specifically says we have to be online, all we actually do is
					// look for additions/removals from what we already have. So we just require the list
					// of ids, nothing else. If there are additions to this, we'll request them specifically
					// and update our cache
					parameters.onlyids = true;
				}

				var fnOnline = function (data) {
					// so we've been forced online, which means we get the whole lot.

					// some pre processing on the data
					taskListManagementExecutionHandler._buildTaskListData(data.execute);

					// Preserve localtasks until we receive Newtask record from server ..
					var localtasks = bworkflowApiService.cachedTaskListData.localtasks;
					bworkflowApiService.cachedTaskListData = data.execute;
					bworkflowApiService.cachedTaskListData.localtasks = localtasks;

					bworkflowApiService.toLocalStorage(taskListManagementExecutionHandler.localStorageTasksKey, data.execute);


					deferred.resolve({
						data: data.execute,
						_momentReceivedUtc: moment.utc()
					});

				};

				if (forceonline) {
					// Since we are being forced online we need to empty the cache now so we dont play with stale data
					// whilst waiting for fresh stuff
					bworkflowApiService.clearCache();

					// Queue the Forced-Online GetTasks, this way when we come back online it will auto execute for us
					taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, true, fnOnline);

					return deferred.promise;
				} else {
					RequestsErrorHandler.specificallyHandled(
						function () {
							$q.all({ execute: PlayerExecute(handler, method, parameters, timeout) }).then(
								function (data) {
									var result = {};

									// so what we have is the ids of the current set of tasks left on the server (we adjusted the parameters object for this above)
									// we need to manage things locally, removals are easy
									result.removed = taskListManagementExecutionHandler._manageServerRemovals(data.execute);

									// additions less so as we need to work them out, then request their full details
									var toAdd = taskListManagementExecutionHandler._getServerAdditions(data.execute);

									if (toAdd.length > 0) {
										// stuff to add, so we make a request for the details of the new stuff and resolve when that comes back
										parameters.onlyids = undefined;
										parameters.tasks = toAdd;

										RequestsErrorHandler.specificallyHandled(
											function () {
												$q.all({ execute: PlayerExecute(handler, method, parameters, timeout) }).then(
													function (toAddData) {
														// unpack it before we do anything
														taskListManagementExecutionHandler._buildTaskListData(toAddData.execute);

														result.added = [];
														// now move it across to our cache
														for (let i = 0; i < toAddData.execute.opentasks.length; i++) {
															var openTask = toAddData.execute.opentasks[i];

															openTask.clientDateTimeReceived = moment().toDate();

															// EVS-488 Task List Got In A Jumble
															// Remove item from list (if its in there) to prevent duplicates
															bworkflowApiService.cachedTaskListData.opentasks.remove(_compareTaskById(openTask.id));

															bworkflowApiService.cachedTaskListData.opentasks.push(openTask);
															result.added.push(openTask);
														}

														// save what we have to local storage
														bworkflowApiService.toLocalStorage(taskListManagementExecutionHandler.localStorageTasksKey, bworkflowApiService.cachedTaskListData);

														result.data = bworkflowApiService.cachedTaskListData;

														deferred.resolve(result);
													},
													function (toAddReason) {
														// huh, looks like there has been a problem, we'll just go with what we've got
														result.data = bworkflowApiService.cachedTaskListData;
														deferred.resolve(result);
													});

											});

										return;
									}

									result.data = bworkflowApiService.cachedTaskListData;

									// if we've reached here there is nothing to add, so we just resolve with what we've got
									deferred.resolve(result);
								},
								function (reason) {
									if (angular.isDefined(forceonline) && forceonline == true) {
										deferred.reject(reason);
									}

									// ok forceonline is false, so we can fallback to a cached version
									deferred.resolve({
										data: bworkflowApiService.cachedTaskListData
									});

								});

						});

				}

				return deferred.promise;
			},
			GetTask: function (handler, method, parameters, timeout) {
				// so we need to get the task out of our local cache and hand it back
				var deferred = $q.defer();

				var taskId = parameters.taskid;

				var task = taskListManagementExecutionHandler._findTaskById(taskId);

				$timeout(function () {
					deferred.resolve(task);
				});

				return deferred.promise;
			},
			NewTask: function (handler, method, parameters, timeout) {
				// ok, we aren't going to support this offline at the moment.
				// however, we intercept this call as the result of it is an addition
				// to the task list, so we grab that data and add it into ourselves
				// to make the ride smoother later on.

				var deferred = $q.defer();

				function resolveOffline(execute) {
					// unpack it before we do anything
					taskListManagementExecutionHandler._buildTaskListData(execute);

					// now move it across to our cache
					for (let i = 0; i < execute.opentasks.length; i++) {
						bworkflowApiService.cachedTaskListData.opentasks.push(execute.opentasks[i]);
						bworkflowApiService.cachedTaskListData.localtasks[execute.opentasks[i].id] = execute.opentasks[i];
					}

					// manage any single clockin stuff we need to handle as a part of the new task being created
					taskListManagementExecutionHandler._manageSingleClockinMode(parameters);

					deferred.resolve(execute.opentasks[0]);
				}

				function resolveOnline(data) {
					var execute = data.execute;
					// unpack it before we do anything
					if (execute.Success !== false) {
						taskListManagementExecutionHandler._buildTaskListData(execute);

						// now move it across to our cache, take into account we may already have the task created locally in which case we merge
						for (let i = 0; i < execute.opentasks.length; i++) {
							var existing = bworkflowApiService.cachedTaskListData.localtasks[execute.opentasks[i].id];
							if (angular.isUndefined(existing)) {
								bworkflowApiService.cachedTaskListData.opentasks.push(execute.opentasks[i]);
							} else {
								angular.extend(existing, execute.opentasks[i]);
								delete existing.createdLocally;
								delete bworkflowApiService.cachedTaskListData.localtasks[execute.opentasks[i].id];
							}
						}

						// manage any single clockin stuff we need to handle as a part of the new task being created
						taskListManagementExecutionHandler._manageSingleClockinMode(parameters);

						deferred.resolve(execute.opentasks[0]);
					} else {
						deferred.reject(execute.Message);
					}
				}

				var template = parameters.template;

				taskTypesService.getTaskType(template.tasktypeid).then(function (tasktype) {
					var useOffline = true;
					if (!tasktype) {
						// Could not get tasktype information (and not cached), let server handle it completely
						useOffline = false;
					}

					// We dont currently support offline spot tasks with checklists, if we have one then let the server handle it
					if (tasktype.ChecklistCount) {
						useOffline = false;
					}

					// By creating the TaskId locally the server will 1st check if it already exists and guarantee it does not create multiple tasks if
					// we timeout and retry
					parameters.taskid = generateCombGuid();

					// Queue the Newtask in the background
					taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, true, resolveOnline);

					// Give client some info on how this will work ..
					deferred.notify({
						offline: useOffline
					});


					if (!useOffline) {
						return;
					}

					var tt = {
						id: template.tasktypeid,
						autofinishtaskonlastchecklist: tasktype.AutoFinishAfterLastChecklist,
						autostart1stchecklist: tasktype.AutoStart1stChecklist,
						autofinishafterstart: tasktype.AutoFinishAfterStart,
						catalogs: [],
						documentation: [],
						finishedstatuses: tasktype.finishedstatuses,
						pausedstatuses: tasktype.pausedstatuses,
						publishedresource: [],
						relationships: tasktype.relationships,
						statuses: []
					};

					var execute = {
						catalogs: [],
						labels: [],
						lastworklog: null,
						opentasks: [{
							createdLocally: true,
							activities: [],
							description: null,
							id: parameters.taskid,
							labels: [],
							lateafter: null,
							level: 0,
							mediaid: template.mediaid,
							minimumdurationleft: null,
							name: template.text,
							notifyuser: false,
							orders: [],
							photos: [],
							photosupport: tasktype.PhotoSupport,
							projectjobtaskgroupid: null,
							projectjobtasktype: null,
							projectjobtaskworkingdocuments: null,
							publishedresources: [],
							requiresclaiming: false,
							site: parameters.template.siteid ? {
								id: parameters.template.siteid,
								name: parameters.template.sitename
							} :
								null,
							sortorder: 0,
							starttime: null,
							status: 'active',
							statusid: null,
							subtasktypeid: template.tasktypeid,
							tasktypeid: template.tasktypeid,
							userinterfacetype: tasktype.UserInterfaceType,
							workinggroupid: null,
							description: tasktype.Description
						}],

						tasktypes: {
							// this gets filled below
						}
					};


					execute.tasktypes[tt.id] = tt;

					resolveOffline(execute);
				});

				return deferred.promise;
			},
			ToggleClockinClockout: function (handler, method, parameters, timeout) {
				taskListManagementExecutionHandler._manageSingleClockinMode(parameters);

				return taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, true);
			},
			ClockinAndClockout: function (handler, method, parameters, timeout) {
				return taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, true);
			},
			SaveChanges: function (handler, method, parameters, timeout) {
				return taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, true);
			},
			Finish: function (handler, method, parameters, timeout) {
				taskListManagementExecutionHandler._manageSingleClockinMode(parameters);

				var result = taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, false);

				if (taskListManagementExecutionHandler.supportOffline == false) {
					return result;
				}

				// now we need to manage the open tasks, so find the task and move it to the not open pile
				var t = taskListManagementExecutionHandler._findTaskById(parameters.taskid);

				taskListManagementExecutionHandler._removeTaskFromCache(t, true);

				return result;
			},
			SavePhoto: function (handler, method, parameters, timeout) {
				var result = taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, false);

				return result;
			},
			RemovePhoto: function (handler, method, parameters, timeout) {
				var result = taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, false);

				return result;
			},
			ChangeTaskType: function (handler, method, parameters, timeout) {
				var result = taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, false);

				return result;
			},
			DeleteOrder: function (handler, method, parameters, timeout) {
				var result = taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, false);

				return result;
			},
			SaveOrder: function (handler, method, parameters, timeout) {
				var result = taskListManagementExecutionHandler._genericOfflineMethod(handler, method, parameters, timeout, false);

				return result;
			}
		};


		var genericExecutionHandlerMethod = function (handler, method, parameters, timeout) {
			var deferred = $q.defer();

			RequestsErrorHandler.specificallyHandled(
				function () {
					$q.all({ execute: PlayerExecute(handler, method, parameters, timeout) }).then(
						function (result) {
							deferred.resolve(result.execute);
						},
						function (reason) {
							deferred.reject(reason);
						});

				});


			return deferred.promise;
		};

		var assetTrackerExecutionHandler = {
			StoreGPSBeaconData: genericExecutionHandlerMethod
		};


		var environmentSensingExecutionHandler = {
			SensorReadingRecords: genericExecutionHandlerMethod
		};


		var offlineChecklistExecutionHandler = {
			Finish: genericExecutionHandlerMethod
		};

		var taskFacilityExecutionHandler = {
			CreateTask: genericExecutionHandlerMethod
		}

		var workingDocumentExecutionHandler = {
			supportOffline: true,
			callback: function (data) {
				// callbacks are expected to return a promise

				switch (data.method) {
					case 'next':
						return workingDocumentExecutionHandler.attemptNext(data.parameters);
				}

			},
			attemptNext: function (data) {
				return bworkflowApiService.nextChecklist(data.workingdocumentid, data.AnswerModel);
			},
			next: function (workingdocumentid, answerModel) {
				var callbackPromise;

				if (workingDocumentExecutionHandler.supportOffline == false) {
					callbackPromise = $q.all({
						execute: bworkflowApiService.nextChecklist(workingdocumentid, answerModel)
					});

					if (angular.isFunction(callbackFn)) {
						callbackPromise = callbackPromise.then(callbackFn);
					}
					return callbackPromise;
				}

				// we queue this call through as a callback queued call as it doesn't go through the execute end point. This means
				// our callback method will get called when it's time to perform the action, so the handler and method need to refer to
				// us.
				callbackPromise = bworkflowApiService.queueCall('Player', 'next', {
					workingdocumentid: workingdocumentid,
					AnswerModel: answerModel
				},
					null);

				bworkflowApiService.toLocalStorage(taskListManagementExecutionHandler.localStorageTasksKey, bworkflowApiService.cachedTaskListData);

				var deferred = $q.defer();

				$timeout(function () {
					deferred.resolve();
				});

				return deferred.promise;
			}
		};


		var executionHandlers = {
			TaskListManagement: taskListManagementExecutionHandler,
			AssetTracker: assetTrackerExecutionHandler,
			Player: workingDocumentExecutionHandler,
			EnvironmentSensing: environmentSensingExecutionHandler,
			OfflineChecklist: offlineChecklistExecutionHandler,
			TaskFacility: taskFacilityExecutionHandler
		};


		var mandatoryValidator = function (question, value, ruleData, stage) {
			if (angular.isDefined(value) == false || value == null || value == '') {
				return {
					passed: false,
					message: ruleData.message
				};

			}

			return {
				passed: true
			};

		};

		var minmaxdateValidator = function (question, value, ruleData, stage) {
			if (angular.isDefined(value) == false || value == null || value == '') {
				// if no value is entered we let it pass as if the implementor wants a value to be mandatory
				// they should add a mandatory validator
				return {
					passed: true
				};

			}

			var v = moment(value);

			if (angular.isDefined(ruleData.minimumdate) && ruleData.minimumdate != null) {
				var minDate = moment(ruleData.minimumdate, "YYYY-MM-DD");

				if (v.isBefore(minDate)) {
					return {
						passed: false,
						message: 'Value can not be before ' + minDate.format("dddd MMMM do YYYY")
					};

				}
			}

			if (angular.isDefined(ruleData.maximumdate) && ruleData.maximumdate != null) {
				var maxDate = moment(ruleData.maximumdate, "YYYY-MM-DD");

				if (v.isAfter(maxDate)) {
					return {
						passed: false,
						message: 'Value can not be after ' + maxDate.format("dddd MMMM do YYYY")
					};

				}
			}

			return {
				passed: true
			};

		};

		var minmaxlengthValidator = function (question, value, ruleData, stage) {
			if (angular.isDefined(value) == false || value == null || value == '') {
				// if no value is entered we let it pass as if the implementor wants a value to be mandatory
				// they should add a mandatory validator
				return {
					passed: true
				};

			}

			if (angular.isDefined(ruleData.minlength) && ruleData.minlength != null) {
				if (value.length < ruleData.minlength) {
					return {
						passed: false,
						message: 'Value can not be shorter than ' + ruleData.minlength
					};

				}
			}

			if (angular.isDefined(ruleData.maxlength) && ruleData.maxlength != null) {
				if (value.length > ruleData.maxlength) {
					return {
						passed: false,
						message: 'Value can not be longer than ' + ruleData.maxlength
					};

				}
			}

			return {
				passed: true
			};

		};

		var minmaxnumberValidator = function (question, value, ruleData, stage) {
			if (angular.isDefined(value) == false || value == null || value == '') {
				// if no value is entered we let it pass as if the implementor wants a value to be mandatory
				// they should add a mandatory validator
				return {
					passed: true
				};

			}

			if (angular.isDefined(ruleData.min) && ruleData.min != null) {
				if (value < ruleData.min) {
					return {
						passed: false,
						message: 'Value can not be less than ' + ruleData.min
					};

				}
			}

			if (angular.isDefined(ruleData.max) && ruleData.max != null) {
				if (value > ruleData.max) {
					return {
						passed: false,
						message: 'Value can not be greater than ' + ruleData.max
					};

				}
			}

			return {
				passed: true
			};

		};

		var allowedvaluesValidator = function (question, value, ruleData, stage) {
			if (angular.isDefined(value) == false || value == null || value == '') {
				// if no value is entered we let it pass as if the implementor wants a value to be mandatory
				// they should add a mandatory validator
				return {
					passed: true
				};

			}

			var found = false;
			for (var i = 0; i < ruleData.values.length; i++) {
				if (ruleData.values[i] == value) {
					found = true;
					break;
				}
			}

			if (found == false) {
				return {
					passed: false,
					message: ruleData.message
				};

			}

			return {
				passed: true
			};

		};

		var validatorHandlers = {
			mandatoryvalidator: mandatoryValidator,
			minmaxdatevalidator: minmaxdateValidator,
			minmaxlengthvalidator: minmaxlengthValidator,
			numberrangevalidator: minmaxnumberValidator,
			allowedvaluesvalidator: allowedvaluesValidator
		};


		//methods:
		var jsonPost = {
			method: 'POST',
			isArray: false
		};


		var jsonArrayPost = {
			method: 'POST',
			isArray: true
		};


		var jsonGet = {
			method: 'GET',
			isArray: false
		};


		var jsonArrayGet = {
			method: 'GET',
			isArray: true
		};

		var mkResolver = function (deferred) {
			return function (r) {
				deferred.resolve(r);
			};
		};

		var mkError = function (deferred, method) {
			return function (r) {
				deferred.reject(r);
			};
		};

		var bworkflowApiService = {
			// EVS-447 Enable Auto-Refresh on Supervisor ToDo List
			// Set to none undefined values
			cachedTaskListData: {
				opentasks: [],
				closedTasks: {},
				localtasks: {} // list of spot tasks created locally, they exist here and in opentasks until we get an update from server
			},

			clearCache: function () {
				bworkflowApiService.cachedTaskListData = {
					opentasks: [],
					closedTasks: {},
					localtasks: bworkflowApiService.cachedTaskListData.localtasks // do not clear these, Newtask response will remove them
				};
			},

			cachedTaskListDataNotificationReceivers: [],

			cachedTaskListNotificationType: {
				taskRemoved: 'Task Removed'
			},


			facilityHierarchyTaskTypeModel: null,

			geowatcher: null,
			datafeeds: {},
			noResultDistance: 100000,

			localStorageExecutionQueueKey: 'bworkflowapiservice.executionqueue',
			// how often the execution queue attempts to push up stuff from the queue to the server
			executionCallQueuePeriod: 1000,
			// a queue of calls that need to made back to the server made through the
			// execute method, but which support offline operations.
			executionCallQueue: [],
			executionCallQueueDeferred: {},

			offlineQueueDbVersion: 1,
			offlineQueueDbName: 'offline-queue',
			offlineQueueObjectStore: 'data',
			_offlineDb: null,
			dashboards: null,

			setFacilityHierarchyTaskTypeModel: function (rules) {
				bworkflowApiService.facilityHierarchyTaskTypeModel = questionTaskListModels.createModelFromRules(rules);
			},

			supportOffline: function (handler, support) {
				if (angular.isDefined(executionHandlers[handler]) == undefined) {
					return;
				}

				executionHandlers[handler].supportOffline = support;
			},

			getPublishedGroups: function () {
				const resource = $resource($window.razordata.apiprefix + 'Published/Groups/');
				return resource.query().$promise;
			},

			getPublishedGroup: function (id) {
				const resource = $resource($window.razordata.apiprefix + 'Published/Groups/:id');
				return resource.get({ id }).$promise;
			},

			beginChecklist: function (groupId, resourceId, revieweeId, args, publishingGroupResourceId) {
				if (resourceId == 0){
					resourceId = null;
				}
				return checklistPlayer.beginChecklist({
					id: Number(publishingGroupResourceId),
					groupId: Number(groupId),
					resourceId: resourceId
				}, revieweeId, args);
			},

			saveChecklist: function (workingDocumentId, answerModel) {
				return checklistPlayer.saveChecklist(workingDocumentId, answerModel);
			},

			nextChecklist: function (workingDocumentId, answerModel, useOfflineQueue) {
				return checklistPlayer.nextChecklist(workingDocumentId, answerModel, useOfflineQueue);
			},

			prevChecklist: function (workingDocumentId) {
				return checklistPlayer.prevChecklist(workingDocumentId);
			},

			continueChecklist: function (workingDocumentId) {
				return checklistPlayer.continueChecklist(workingDocumentId);
			},

			getDashboard: function () {
				if (this.dashboards == null) {
					this.dashboards = $q.defer();

					const resource = $resource($window.razordata.apiprefix + 'Player/Dashboard?playerType=spa', {}, {
						withCredentials: true
					});
					resource.get(r => {
						this.dashboards.resolve(r);
					}, mkError(this.dashboards));

				}
				return this.dashboards.promise;
			},

			allUsers: function () {
				var deferred = $q.defer();
				const resource = $resource($window.razordata.apiprefix + 'QHelpers/AllUsers');
				resource.query(mkResolver(deferred), mkError(deferred));
				return deferred.promise;
			},

			labelUsers: function (labels) {
				var deferred = $q.defer();
				const resource = $resource($window.razordata.apiprefix + 'QHelpers/AllUsersFromLabels', {});
				resource.query({
					labels: labels
				},
					mkResolver(deferred), mkError(deferred));
				return deferred.promise;
			},

			execute: function (handler, method, parameters, timeout, forceonline) {
				var h = executionHandlers[handler];

				if (angular.isDefined(h) == true) {
					var m = h[method];

					if (angular.isDefined(m) == true) {
						// we are going to add a client side date/timestamp to the parameters so
						// that server side code can use this if it so desires to know when
						// the action took place on the client as the time it receives it and when
						// it occurred could be very different
						parameters.clientDateTimeStampUtc = angular.isDefined(parameters.clientDateTimeStampUtc) ? parameters.clientDateTimeStampUtc : moment.utc().format('DD-MM-YYYY HH:mm:ss');
						return authSvc.getUser().then(user => {
							parameters.clientExecutingUserId = user?.userId;
							return m(handler, method, parameters, timeout, forceonline);
						});
					}
				}

				return PlayerExecute(handler, method, parameters, timeout);
			},

			doExecute: function (...args) {
				return PlayerExecute(...args);
			},

			logError: function (error) {
				var deferred = $q.defer();

				const resource = $resource($window.razordata.apiprefix + 'Player/LogError', {}, {
					post: jsonPost
				});
				resource.post({
					error: error
				},
					mkResolver(deferred), mkError(deferred));

				return deferred.promise;
			},

			getGPSLocation: function () {
				var deferred = $q.defer();
				SquareIT.GetGPSLocation({},
					function (result) {
						deferred.resolve(result);
					},
					function (error) {
						deferred.reject(error);
					});
				return deferred.promise;
			},

			watchGPSLocation: function () {
				$geolocation.watchPosition({
					enableHighAccuracy: true,
					timeout: 30000
				});


				return $geolocation;
			},

			clearWatchGPSLocation: function () {
				$geolocation.clearWatch();
			},

			currentLocation: function () {
				try {
					if (angular.isDefined($geolocation.position) == false) {
						return null;
					}

					if (angular.isDefined($geolocation.position.error)) {
						return {
							code: $geolocation.position.error.code,
							message: $geolocation.position.error.message
						};

					}

					var c = $geolocation.position;

					// we manually make a copy of the location to fix a bug
					// where sending the coords object back over the wire, or doing an
					// angular copy doesn't result in an object that can be used
					var loc = {
						coords: {
							accuracy: c.coords.accuracy,
							altitude: c.coords.altitude,
							altitudeAccuracy: c.coords.altitudeAccuracy,
							heading: c.coords.heading,
							latitude: c.coords.latitude,
							longitude: c.coords.longitude,
							speed: c.coords.speed
						}
					};



					return loc;
				} catch (e) {
					console.log('bworkflowApiService: An exception occurred while attempting to get the location');
				}
			},

			geolocate: function (street, town, postcode, state, country, geocodeurl) {
				if (town == null || town == '' || state == null || state == '' || postcode == null || postcode == '' || country == null || country == '') {
					return null;
				}

				var url = geocodeurl;
				if (url == null || url == '') {
					url = 'http://nominatim.openstreetmap.org/search?q=:street+:town+:state+:postcode+:country&format=json&polygon=0&addressdetails=0';
				}

				var geocoderesource = $resource(url, {}, {
					get: jsonArrayGet
				});


				var deferred = $q.defer();
				geocoderesource.get({
					street: street,
					town: town,
					postcode: postcode,
					state: state,
					country: country
				},
					mkResolver(deferred), mkError(deferred));

				return deferred.promise;
			},

			validate: function (question, value, answer, validators, stage, globalResult) {
				var result = true;

				question.presented.ValidationErrors = [];
				if (validators == null || validators.length == 0) {
					return true;
				}

				angular.forEach(validators, function (v) {
					if (angular.isDefined(validatorHandlers[v.type]) == false) {
						return; // no client side validator, it'll get handled on the server anyway
					}

					var vResult = null;

					if (angular.isDefined(question[v.type])) {
						// the question does something special for this type of validator, let it handle things
						vResult = question[v.type](question, value, v, stage);
					} else {
						vResult = validatorHandlers[v.type](question, value, v, stage);
					}

					if (vResult.passed == false) {
						result = false;

						if (angular.isDefined(globalResult)) {
							globalResult.errors.push(true);
						}

						question.presented.ValidationErrors.push(vResult.message);
					}
				});

				return result;
			},

			addCachedTaskListDataNotificationReceiver: function (receiver) {
				bworkflowApiService.cachedTaskListDataNotificationReceivers.push(receiver);
			},

			removeCachedTaskListDataNotificationReceiver: function (receiver) {
				var index = bworkflowApiService.cachedTaskListDataNotificationReceivers.indexOf(receiver);

				if (index == -1) {
					return;
				}

				bworkflowApiService.cachedTaskListDataNotificationReceivers.splice(index, 1);
			},

			notifyCachedTaskListDataNotificationReceiver: function (data, notificationType) {
				var d = { data: data, type: notificationType };
				angular.forEach(bworkflowApiService.cachedTaskListDataNotificationReceivers, function (r) {
					r.notify(d);
				});
			},

			fillOutFeed: function (feed, clientContext) {
				var t = {
					uselocalfeed: true,
					visiblefields: '',
					orderbyfields: '',
					filter: '',
					extrafields: '',
					includecount: false,
					datascope: '',
					transformfieldsaround: '',
					transformtocolumntitlesfields: '',
					transformtocolumnvaluesfields: '',
					fieldformats: [],
					parameterdefinitions: [],
					usepaging: true,
					itemsperpage: 10,
					refreshperiodseconds: 0,
					clientcontext: clientContext
				};


				var r = angular.copy(feed);

				angular.forEach(t, function (v, f) {
					if (angular.isDefined(r[f]) == true) {
						return;
					}

					r[f] = v;
				});

				return r;
			},

			odataCache: {},
			registerODataCacheExecutor: function (cache) {
				var persistedCacheKey = cache.feed + ':' + JSON.stringify(cache.query);
				var cacheFilter = function (me, data, params) {
					var filtered = (cache.filter || angular.identity)(data, params);
					// limit the itemsperpage like the OData feed does
					if (filtered.length > me.template.itemsperpage) {
						filtered.splice(me.template.itemsperpage);
					}
					return filtered;
				};
				var cacheExecutor = {
					refresh: function (me, processData) {
						// no local cache, fetch it and cache it
						var masterRequest = $http({
							url: webServiceUrl.odata(`/${cache.feed}`),
							method: 'GET',
							params: cache.query,
							headers: {
								Accept: 'application/json;odata=light, text/plain, */*'
							}
						});



						RequestsErrorHandler.specificallyHandled(
							function () {
								$q.all({ response: masterRequest }).then(
									function (httpData) {
										var data = httpData.response.data;
										persistantStorage.setItem(persistedCacheKey, {
											data: data,
											expiresAt: moment().add(cache.expireSeconds || 10 * 60, 'seconds')
										});

										if (me && me.useCacheResult) {
											var filteredData = angular.extend({}, data, {
												value: cacheFilter(me, data.value, me.parameters)
											});

											processData(filteredData);
										}
									});

							});
					},
					getData: function (me, processData) {
						var deferred = $q.defer();
						persistantStorage.getItem(persistedCacheKey, function (cacheItem) {
							// Use cached item right away if we have it
							if (cacheItem != null) {
								// we have cache data use it 
								var data = cacheItem.data;
								var filteredData = angular.extend({}, data, {
									value: cacheFilter(me, data.value, me.parameters)
								});

								processData(filteredData);
								deferred.resolve(true); // Fetched from cache will cancel the realtime request
							} else {
								deferred.resolve(false); // Cache miss
							}
							// If cached item has expired then refresh it aswell
							if (cacheItem == null || (cacheItem.expiresAt && moment(cacheItem.expiresAt).isBefore(moment()))) {
								cacheExecutor.refresh(me, processData);
							}
						});
						return deferred.promise;
					}
				};

				bworkflowApiService.odataCache[cache.key] = cacheExecutor;
				return cacheExecutor;
			},

			buildClientContext: function (f, sc, stack) {
				var clientContext = f.clientContext;
				var checklistName = null;
				if (clientContext == null && sc != null) {
					// Look for the PlayerModel in the scope tree to find the ChecklistName making the call
					var playerScope = sc;
					while (playerScope != null && checklistName == null) {
						if (playerScope.db != null && playerScope.db.PlayerModel != null) {
							checklistName = playerScope.db.PlayerModel.ChecklistName;
						}
						playerScope = playerScope.$parent;
					}
					clientContext = {};
				}

				clientContext.checklistname = clientContext.checklistname ?? checklistName;
				clientContext.checklistpage = clientContext.checklistpage ?? sc.presented?.Name;
				if (clientContext.line == null && stack != null) {
					var stackLines = stack.split('\n');
					clientContext.line = stackLines[2];         // this should be the callers line
				}
				return clientContext;
			},

			createDataFeed: function (f, sc) {
				var feed = bworkflowApiService.fillOutFeed(f, this.buildClientContext(f, sc, new Error().stack));
				// the notifier object on the manager can be watched by clients, so that they are 
				// notified that data has changed.
				var manager = {
					fullname: feed.fullname,
					orginalFeed: feed,
					template: angular.copy(feed),
					notifier: {
						refreshes: 0,
						id: feed.fullname
					},

					data: [],
					dataByKey: {},
					page: 1,
					countdown: 1,
					totalitemcount: null,
					isAjaxing: false,
					allowMultipleAjax: false, // EVS-1404 fix - ForWhen set True the feed will allow multiple concurrent getData calls - should be used carefully as Feeds in general were designed for single Ajax
					parameters: {},
					beforeLoadHooks: [],
					afterLoadHooks: [],
					afterErrorHooks: [],
					scope: sc,

					addBeforeLoadHook: function (hook) {
						var me = this;
						me.beforeLoadHooks.push(hook);
						return function () {
							var i = me.beforeLoadHooks.indexOf(hook);
							if (i >= 0) {
								me.beforeLoadHooks.splice(i, 1);
							}
						};
					},
					addAfterLoadHook: function (hook) {
						var me = this;
						me.afterLoadHooks.push(hook);
						return function () {
							var i = me.afterLoadHooks.indexOf(hook);
							if (i >= 0) {
								me.afterLoadHooks.splice(i, 1);
							}
						};
					},
					addAfterErrorHook: function (hook) {
						var me = this;
						me.afterErrorHooks.push(hook);
						return function () {
							var i = me.afterErrorHooks.indexOf(hook);
							if (i >= 0) {
								me.afterErrorHooks.splice(i, 1);
							}
						};
					},
					buildODataUrl: function () {
						var url = webServiceUrl.odata(this.template.feed);

						if (this.template.uselocalfeed == false) {
							url = this.template.feed;
						}

						return url;
					},
					buildParametersObject: function () {
						var result = {};

						// we only copy across what's been declared as a parameter
						for (var i = 0; i <= this.template.parameterdefinitions.length - 1; i++) {
							var def = this.template.parameterdefinitions[i];

							result[def.internalname] = undefined;

							var toFind = null;

							if (def.externalnames == null || def.externalnames == '') {
								// if no external names, just default mapping to the internal name
								toFind = [def.internalname];
							} else {
								toFind = def.externalnames.split(',');
							}

							for (var j = 0; j <= toFind.length - 1; j++) {
								if (angular.isDefined(this.parameters[toFind[j]]) == false && (def.defaultvalue == null || def.defaultvalue == '')) {
									continue;
								}

								// ok there is a mapping betwen the definition and a parameter handed in (or a default value)
								// lets copy that across using the internal name as the our reference point
								var value = this.parameters[toFind[j]];
								result[def.internalname] = value;

								// if there is a default value and a value hasn't been supplied in the parameters, use the default one
								if (def.defaultvalue != null && def.defaultvalue != '' && (value == null || value == '')) {
									result[def.internalname] = this.scope.$eval(def.defaultvalue);
								}

								// if there is a prequery instruction (as there is only a single option at the moment I'm keeping this code simple)
								if (def.prequeryinstruction == 'toUtc') {
									// we are expecting value to be a date or string that parses into a date
									var date = new Date(result[def.internalname]);

									date = date.toISOString();

									result[def.internalname] = date;
								}

								break;
							}
						}

						return result;
					},
					interpolateParameter: function (param) {
						var result = $interpolate(param, true, null, true);

						if (angular.isDefined(result) == false) {
							// this means the embedded expressions haven't been fully resolved (so the variables aren't defined)
							// not much we can do
							return undefined;
						} else if (result == null) {
							// this means there are no embedded expressions, its a raw url we have
							return param;
						}

						// we may need to do some work on the parameters given to us (transpose them to internal names or pre query processing)
						var params = this.buildParametersObject();

						return result(params);
					},
					createAngularExpression: function (textWithSquares) {
						// we just replace [[variablename]] to make {{variablename}}
						var partial = textWithSquares.split('[[').join('{{');

						return partial.split(']]').join('}}');
					},
					buildODataParameters: function () {
						var parameters = {
							datascope: null
						};

						var result = undefined;

						if (this.template.datascope != null) {
							parameters.datascope = this.template.datascope;
						}

						if (this.template.filter != null && this.template.filter != '') {
							result = this.createAngularExpression(this.template.filter);

							// angular $interpolate is returning undefined when it should be returning null
							// resulting in our code to identify what's happened not identifiying when there are no
							// expressions in the text. So do that here.
							if (result != this.template.filter) {
								result = this.interpolateParameter(result);

								if (angular.isDefined(result) == false) {
									return undefined;
								}
							}

							parameters.$filter = result;
						}

						if (this.template.orderbyfields != null && this.template.orderbyfields != '') {
							result = this.createAngularExpression(this.template.orderbyfields);

							if (result != this.template.orderbyfields) {

								result = this.interpolateParameter(result);

								if (angular.isDefined(result) == false) {
									return undefined;
								}
							}

							parameters.$orderby = result;
						}

						if (this.template.expandfields != null && this.template.expandfields != '') {
							result = this.createAngularExpression(this.template.expandfields);

							if (result != this.template.expandfields) {
								result = this.interpolateParameter(result);

								if (angular.isDefined(result) == false) {
									return undefined;
								}
							}

							parameters.$expand = result;
						}

						if (this.template.selectfields != null && this.template.selectfields != '') {
							result = this.createAngularExpression(this.template.selectfields);

							if (result != this.template.selectfields) {
								result = this.interpolateParameter(result);

								if (angular.isDefined(result) == false) {
									return undefined;
								}
							}

							parameters.$select = result;
						}

						if (this.template.usepaging == true) {
							parameters.$top = this.template.itemsperpage;
							parameters.$skip = (this.page - 1) * this.template.itemsperpage;
						}

						if (this.template.includecount == true) {
							parameters.$inlinecount = 'allpages';
						}

						return parameters;
					},
					clearParameters: function (fields) {
						if (fields == null || angular.isDefined(fields) == false) {
							this.parameters = {};
						} else {
							var me = this;
							angular.forEach(fields, function (value, key) {
								me.parameters[key] = undefined;
							});
						}

						this.getData(true);
					},
					shouldTransform: function () {
						return this.template.transformaroundfields != null &&
							this.template.transformaroundfields != '' &&
							this.template.transformtocolumntitlesfields != null &&
							this.template.transformtocolumntitlesfields != '' &&
							this.template.transformtocolumnvaluesfields != null &&
							this.template.transformtocolumnvaluesfields != '';
					},
					transform: function (data) {
						var result = [];
						var cache = {};
						var columnTitles = []; // the set of column titles calculated from the data
						var displayFields = [];

						displayFields = angular.copy(this.orginalFeed.displayfields);

						// first things first, we need to work out the columns that we are going to be adding
						// to all of the objects. To do this we run through the column fields and for each of those
						// grab their value in the data and store it if we haven't seent it before.
						angular.forEach(this.template.transformcolumnfields, function (column, index) {
							var def = {
								sourcecolumn: column,
								index: index,
								columns: {}
							};


							angular.forEach(data.value, function (row, index) {
								var val = row[column]; // so get the value in the data for the source column

								var colVal = def.columns[val]; // see if we've encountered this value before
								if (angular.isDefined(colVal) == false) {
									def.columns[val] = null; // we haven't so make an entry for it
									displayFields.push(val);
								}
							});

							columnTitles.push(def);
						});

						var me = this;

						// a function to calculate a key around which we can transform
						var buildKey = function (row) {
							var result = '';

							angular.forEach(me.template.transformkeyfields, function (field, index) {
								var v = row[field];
								if (angular.isDefined(v)) {
									result += v;
								}
							});

							return result;
						};

						angular.forEach(data.value, function (value, index) {
							var key = buildKey(value);

							var row = cache[key];

							if (angular.isDefined(row) == false) {
								row = {};

								// need to move across the visible fields first, we'll populate these
								// with values. If there are multiple occurances (based on the key) then
								// the first values will be used (what else could we do?)
								angular.forEach(me.template.displayfields, function (field, index) {
									if (angular.isDefined(value[field]) == false) {
										row[field] = null;
									} else {
										row[field] = value[field];
									}
								});

								// now we need to add the transformed column fields (null values initially)
								angular.forEach(columnTitles, function (colDef, index) {
									angular.forEach(colDef.columns, function (value, key) {
										row[key] = null;
									});
								});

								cache[key] = row;
								result.push(row);
							}

							// now we get the values for the transformed columns and populate the data object
							angular.forEach(columnTitles, function (colDef, varIndex) {
								var columnTitle = value[colDef.sourcecolumn];

								var val = row[columnTitle];
								if (val == null) {
									val = value[me.template.transformvaluefields[colDef.index]];
								} else {
									val = val + value[me.template.transformvaluefields[colDef.index]];
								}

								row[columnTitle] = val;
							});
						});

						return {
							value: result,
							displayFields: displayFields
						};

					},
					__getData: function (params, refresh, feed) {
						var headers = { Accept: 'application/json;odata=light, text/plain, */*' };
						headers['vm-clientcontext-checklistname'] = feed?.template?.clientcontext?.checklistname;
						headers['vm-clientcontext-checklistpage'] = feed?.template?.clientcontext?.checklistpage;
						headers['vm-clientcontext-line'] = feed?.template?.clientcontext?.line;
						return $http({
							url: this.buildODataUrl(),
							method: 'GET',
							params: params,
							headers: headers
						});
					},
					getData: function (refreshCaches, callContext) {
						var deferred = $q.defer();

						if (this.isAjaxing == true && this.allowMultipleAjax != true) // EVS-1404 fix - Allow multiple Ajax calls when client configures feed to do so
						{
							deferred.reject({
								reason: 'busy'
							});

							return deferred.promise;
						}
						var params = this.buildODataParameters();

						if (angular.isDefined(params) == false) {
							// one or more of the parameters aren't fully defined yet
							if (this.data.length > 0) {
								// there was data, there isn't now, so we need to update
								this.data.length = 0;
								this.notifier = {
									refreshes: this.notifier.refreshes + 1,
									id: this.notifier.id
								};

							}

							deferred.reject({
								reason: 'parameters'
							});

							return deferred.promise;
						}

						var me = this;

						me.isAjaxing = true;
						delete me.ajaxError;

						var refresh = refreshCaches;
						if (angular.isDefined(refresh) == false) {
							refresh = false;
						}

						angular.forEach(me.beforeLoadHooks, function (hook) {
							hook(me);
						});

						// feature/LUCIFER-159 Allow adjustment of certain Odata columns to remove the 'Z' from UTC DateTimes (these are assumed to be local datetimes in Madagascar OData v3)
						function luciferiseRemoveDateTimeUtcOffset(data, columns) {
							data.forEach(d => {
								columns.forEach(c => {
									let s = d[c];
									// EN-46 fix for s being null
									if (s != null && s.endsWith('Z')) {
										d[c] = s.substring(0, s.length - 1);
									}
								})
							})
						}

						function luciferiseFeed(data) {
							switch (me.template.feed.toLowerCase()) {
								case "rosters":
									luciferiseRemoveDateTimeUtcOffset(data, ['StartTime', 'EndTime', 'AutomaticScheduleApprovalTime', 'AutomaticEndOfDayTime']);
									break;
							}
						}

						var processData = function (data) {
							luciferiseFeed(data.value);

							var displayFields = me.template.displayfields;

							var d = data;

							if (me.template.includecount == true) {
								me.totalitemcount = data["odata.count"];
								if (me.totalitemcount == null) {        // Handle v4 odata feeds returning @odata.count instead
									me.totalitemcount = data["@odata.count"];
								}
							}

							if (me.shouldTransform() == true) {
								d = me.transform(data);

								displayFields = d.displayFields;
							}

							me.template.displayfields = displayFields;

							if (refresh == true) {
								me.dataByKey = {};
								me.data.length = 0;
							}

							angular.forEach(d.value, function (value, index) {
								var item = null;

								// we keep a cache of the objects we've built (based on their key) so
								// that we can update the existing object rather than creating a whole new one
								// this means things bound to the objects can animate etc from a previous value
								// to a new one, rather than being recreated and animating from 0
								var key = me.buildKey(value);

								if (angular.isDefined(me.dataByKey[key])) {
									item = me.dataByKey[key];
									// copy across the updates
									angular.forEach(value, function (v, k) {
										item.alldata[k] = v;
									});
								} else {
									item = {
										alldata: value,
										data: {}
									};


									me.dataByKey[key] = item;

									me.data.push(item);
								}

								// we copy across the set of visible fields (which have been split by someone else for us)
								// and put them somewhere easy for anyone else to reference
								angular.forEach(displayFields, function (field, index) {
									if (angular.isDefined(value[field]) == false) {
										item.data[field] = null;
									} else {
										item.data[field] = value[field];
									}
								});
							});

							me.isAjaxing = false;
							me.countdown = me.template.refreshperiodseconds;
							me.notifier = {
								refreshes: me.notifier.refreshes + 1,
								id: me.notifier.id
							};


							angular.forEach(me.afterLoadHooks, function (hook) {
								hook(me, callContext);
							});

							deferred.resolve(me.data);
						};

						var tryCache;
						var cache = me.template.cache;
						if (angular.isDefined(cache)) {
							var cacheExecutor = me.cacheExecutor;
							if (!cacheExecutor) {
								if (cache.key) {
									cacheExecutor = bworkflowApiService.odataCache[cache.key];
								} else {
									cacheExecutor = bworkflowApiService.registerODataCacheExecutor(me.template.cache);
								}
								me.cacheExecutor = cacheExecutor;
							}
							tryCache = cacheExecutor.getData(me, processData);
						} else {
							tryCache = $q.resolve(false);
						}

						tryCache.then(function (cacheHit) {
							if (!cacheHit) {
								RequestsErrorHandler.specificallyHandled(function () {
									$q.all({ response: me.__getData(params, refresh, me) }).then(
										function (httpData) {
											// prevent the cache from filling the result (likely only on 1st fill of the cache where it is also a HTTP request and could fill after this call)
											processData(httpData.response.data);
										},
										function (httpData) {
											// we don't want to show an error message for this
											me.isAjaxing = false;
											me.ajaxError = httpData;
											me.countdown = me.template.refreshperiodseconds;
											me.notifier = {
												refreshes: me.notifier.refreshes + 1,
												id: me.notifier.id
											};



											httpData.noUI = true;
											me.scope.$emit('player_broadcast_ajax_error', httpData);

											angular.forEach(me.afterErrorHooks, function (hook) {
												hook(me, httpData, callContext);
											});

											deferred.reject({
												reason: 'http',
												data: httpData
											});

										});

								});
							}
						});

						return deferred.promise;
					},
					buildKey: function (value) {
						var key = '';

						var loopOver = this.template.idfields;
						if (loopOver.length == 0) {
							loopOver = this.template.displayfields;
						}

						angular.forEach(loopOver, function (field, index) {
							if (angular.isDefined(value[field]) == false) {
								key = key + 'unknown';
							} else {
								key = key + value[field];
							}
						});

						return key;
					},
					getFormat: function (fieldName) {
						var formats = $filter('filter')(this.template.fieldformats, {
							fieldname: fieldName
						},
							true);

						return formats.length == 0 ? null : formats[0];
					},
					applyFormat: function (value, formatter, requiresTrusting) {
						var result = value;

						if (formatter != null) {
							switch (formatter.format) {
								case 'images':
									result = '<image-list ids="' + value + '" attributes="' + formatter.data + '"></image-list>';
									break;
								case 'image':
								case 'task type image':
								case 'membership image':
									var width = null;
									var height = null;

									if (formatter.data != null && formatter.data != '') {
										var obj = angular.fromJson(formatter.data);

										if (angular.isDefined(obj.width) == true) {
											width = obj.width;
										}

										if (angular.isDefined(obj.height) == true) {
											height = obj.height;
										}
									}

									var attrs = '';
									if (width != null) {
										attrs = ' width="' + width + '"';
									}

									if (height != null) {
										attrs = attrs + ' height="' + height + '"';
									}

									var type = '';

									switch (formatter.format) {
										case 'image':
											type = 'media';
											break;
										case 'task type image':
											type = 'tasktype';
											break;
										case 'membership image':
											type = 'user';
											break;
									}


									result = '<media-image media-id="' + value + '" type="' + type + '"' + attrs + '></media-image>';

									break;
								case 'task finish status':
									var showIcons = false;
									var showText = true;

									if (formatter.data != null && formatter.data != '') {
										var obj = angular.fromJson(formatter.data);

										if (angular.isDefined(obj.showIcons) == true) {
											showIcons = obj.showIcons;
										}

										if (angular.isDefined(obj.showText) == true) {
											showText = obj.showText;
										}
									}

									var iconText = '';
									var text = '';

									switch (value) {
										case 'Unapproved':
											if (showIcons) {
												iconText = '<i class="icon-minus" title="Unapproved"></i>&nbsp;';
											}
											if (showText) {
												text = value;
											}
											break;
										case 'Approved':
											if (showIcons) {
												iconText = '<i class="icon-thumbs-up" title="Approved"></i>&nbsp;';
											}
											if (showText) {
												text = value;
											}
											break;
										case 'Started':
											if (showIcons) {
												iconText = '<i class="icon-play" title="Started"></i>&nbsp;';
											}
											if (showText) {
												text = value;
											}
											break;
										case 'Paused':
											if (showIcons) {
												iconText = '<i class="icon-pause" title="Paused"></i>&nbsp;';
											}
											if (showText) {
												text = value;
											}
											break;
										case 'FinishedComplete':
											if (showIcons) {
												iconText = '<i class="icon-ok" title="Finished Complete"></i><i class="icon-thumbs-up" title="Finished Complete"></i>&nbsp;';
											}
											if (showText) {
												text = 'Finished Complete';
											}
											break;
										case 'FinishedIncomplete':
											if (showIcons) {
												iconText = '<i class="icon-ok" title="Finished Incomplete"></i><i class="icon-thumbs-down" title="Finished Incomplete"></i>&nbsp;';
											}
											if (showText) {
												text = 'Finished Incomplete';
											}
											break;
										case 'Cancelled':
											if (showIcons) {
												iconText = '<i class="icon-remove" title="Cancelled"></i>&nbsp;';
											}
											if (showText) {
												text = 'Cancelled';
											}
											break;
										case 'FinishedByManagement':
											if (showIcons) {
												iconText = '<i class="icon-ok" title="Finished By Management"></i><i class="icon-user" title="Finished By Management"></i>&nbsp;';
											}
											if (showText) {
												text = 'Finished By Management';
											}
											break;
										case 'FinishedBySystem':
											if (showIcons) {
												iconText = '<i class="icon-ok" title="Finished By System"></i><i class="icon-user" title="Finished By System"></i>&nbsp;';
											}
											if (showText) {
												text = 'Finished By System';
											}
											break;
										case 'ApprovedContinued':
											if (showIcons) {
												iconText = '<i class="icon-thumbs-up" title="Approved Continued"></i><i class="icon-repeat" title="Approved Continued"></i>&nbsp;';
											}
											if (showText) {
												text = 'Approved Continued';
											}
											break;
										case 'ChangeRosterRequested':
											if (showIcons) {
												iconText = '<i class="icon-share" title="Change Roster Requested"></i><i class="icon-question-sign" title="Change Roster Requested"></i>&nbsp;';
											}
											if (showText) {
												text = 'Change Roster Requested';
											}
											break;
										case 'ChangeRosterRejected':
											if (showIcons) {
												iconText = '<i class="icon-share" title="Change Roster Rejected"></i><i class="icon-ban-circle" title="Change Roster Rejected"></i>&nbsp;';
											}
											if (showText) {
												text = 'Change Roster Rejected';
											}
											break;
										case 'ChangeRosterAccepted':
											if (showIcons) {
												iconText = '<i class="icon-share" title="Change Roster Accepted"></i><i class="icon-ok" title="Change Roster Accepted"></i>&nbsp;';
											}
											if (showText) {
												text = 'Change Roster Accepted';
											}
											break;
									}


									result = iconText + '<span>' + text + '</span>';

									break;
								default:
									// just use angular to do the other formats
									var f = undefined;
									if (formatter.data != null && formatter.dat != '') {
										f = formatter.data;
									}
									result = '<span>' + $filter(formatter.format)(result, f) + '</span>';
									break;
							}

						} else if (result != null) {
							result = '<span>' + result + '</span>';
						}

						if (requiresTrusting == true) {
							return $sce.trustAsHtml(result);
						} else {
							return result;
						}
					},
					nextPage: function () {
						if (this.template.usepaging == false) {
							return;
						}

						this.page = this.page + 1;

						this.getData(true);
					},
					previousPage: function () {
						if (this.template.usepaging == false) {
							return;
						}

						if (this.page == 1) {
							return;
						}

						this.page = this.page - 1;

						this.getData(true);
					}
				};


				return manager;
			},

			registerDataFeed: function (feed) {
				var wrapper = bworkflowApiService.getFeedWrapper(feed.fullname);

				if (wrapper == null) {
					return null;
				}

				// first bit is to populate the feed
				wrapper.feed = feed;

				// if there are any outstanding promises for the feed let's fullfill them
				if (wrapper.outstandingpromises.length > 0) {
					angular.forEach(wrapper.outstandingpromises, function (value, index) {
						value.resolve(wrapper.feed);
					});

					wrapper.outstandingpromises.length = 0;
				}
			},

			getFeedWrapper: function (fullname) {
				if (fullname == null || fullname == '') {
					return null;
				}

				var trimmed = fullname.trim(); // spaces at the start and end we don't want my friend

				if (angular.isDefined(bworkflowApiService.datafeeds[trimmed]) == true) {
					return bworkflowApiService.datafeeds[trimmed];
				}

				var wrapper = {
					feed: null,
					outstandingpromises: []
				};


				bworkflowApiService.datafeeds[trimmed] = wrapper;

				return wrapper;
			},

			getDataFeed: function (fullname) {
				if (fullname == null || fullname == '') {
					return null;
				}

				var deferred = $q.defer();

				var wrapper = bworkflowApiService.getFeedWrapper(fullname);

				if (wrapper != null) {
					if (wrapper.feed != null) {
						// we already have the feed registered, so we resolve immediately
						$timeout(function () {
							deferred.resolve(wrapper.feed);
						});
					} else {
						// no feed registered, store the promise and resolve later when the feed is registered
						wrapper.outstandingpromises.push(deferred);
					}
				}

				return deferred.promise;
			},

			clearDataFeeds: function () {
				bworkflowApiService.datafeeds = {};
			},

			updateDataFeeds: function (feeds, properties, forceRefresh) {
				if (feeds != null) {
					// ok feeds could be a comma seperated list of feeds that are registered with us
					// or alternatively, it could be an array of feed objects already.

					if (typeof feeds === 'string') {
						if (feeds != '' && feeds != null) {
							var sources = feeds.split(',');
							angular.forEach(sources, function (source) {
								var promise = bworkflowApiService.getDataFeed(source);

								if (promise != null) {
									promise.then(function (toUpdate) {
										bworkflowApiService.mapDataFeedParameters(toUpdate, properties);

										toUpdate.getData(forceRefresh);
									});
								}
							});
						}
					} else {
						angular.forEach(feeds, function (feed) {
							bworkflowApiService.mapDataFeedParameters(feed, properties);
							feed.getData(forceRefresh);
						});
					}
				}
			},
			mapDataFeedParameters: function (feed, properties) {
				if (angular.isDefined(properties) == true && properties != null) {
					angular.forEach(properties, function (value, key) {
						feed.parameters[key] = value;
					});
				}
			},
			clearDataFeedParameters: function (feeds, selection) {
				if (feeds != null) {
					var fields = null;
					if (selection != null) {
						fields = selection;
					}

					// feeds can be a comma seperated list of strings or an array of feeds
					if (typeof feeds === 'string') {
						if (feeds != null && feeds != '') {
							var sources = feeds.split(',');

							angular.forEach(sources, function (source) {
								var promise = bworkflowApiService.getDataFeed(source);

								if (promise != null) {
									promise.then(function (toUpdate) {
										// copy across what was selected into the parameters for the feed
										toUpdate.clearParameters(fields);
									});
								}
							});
						}
					} else {
						angular.forEach(feeds, function (feed) {
							feed.clearParameters(fields);
						});
					}
				}
			},
			navigateToContinue: function (workingDocumentId) {
				$window.location.href = $window.razordata.siteprefix + "#/Player/Continue/" + workingDocumentId + "/?nonce=12345";
			},
			getfullurl: function (url) {
				return url.replace(/^~\//, rtrim($window.razordata.siteprefix) + '/');
			},
			getImageUrl: function () {
				return window.razordata.mediaprefix + 'MediaImage';
			},
			distanceBetween: function (lat1, lon1, lat2, lon2) {
				if (angular.isDefined(lat1) == false || angular.isDefined(lon1) == false || angular.isDefined(lat2) == false || angular.isDefined(lon2) == false) {
					return this.noResultDistance;
				}

				if (lat1 == null || lon1 == null || lat2 == null || lon2 == null) {
					return this.noResultDistance;
				}

				var R = 6371; // km
				var dLat = bworkflowApiService.toRad(lat2 - lat1);
				var dLon = bworkflowApiService.toRad(lon2 - lon1);
				var lat1 = bworkflowApiService.toRad(lat1);
				var lat2 = bworkflowApiService.toRad(lat2);

				var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
					Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
				var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
				var d = R * c;

				return d;
			},
			toRad: function (value) {
				return value * Math.PI / 180;
			},
			queueItem: function (obj) {
				var deferredCall = $q.defer();

				bworkflowApiService.executionCallQueue.push(obj);
				bworkflowApiService.executionCallQueueDeferred[obj.deferredGuid] = deferredCall;

				// persist the queue to local storage
				bworkflowApiService.toLocalStorage(bworkflowApiService.localStorageExecutionQueueKey, bworkflowApiService.executionCallQueue);

				if (bworkflowApiService.executionCallQueue.length == 1) {
					// the item we just added is the first, so lets start the queue
					bworkflowApiService.processNextQueuedExecutionCall();
				}

				return deferredCall.promise;
			},
			queueExecutionCall: function (handler, method, parameters, timeout) {
				// calls put through here are expected to be calling the Execute end point on the player
				var obj = {
					type: 'execute',
					handler: handler,
					method: method,
					parameters: parameters,
					timeout: timeout,
					deferredGuid: generateCombGuid()
				};


				return bworkflowApiService.queueItem(obj);
			},
			queueCall: function (handler, method, parameters, timeout) {
				// calls put through to this will utilise a call back to the execution handler to do what ever it is they need to
				// do
				var obj = {
					type: 'custom',
					handler: handler,
					method: method,
					parameters: parameters,
					timeout: timeout,
					deferrredGuid: generateCombGuid()
				};


				return bworkflowApiService.queueItem(obj);
			},
			processNextQueuedExecutionCall: function () {
				if (bworkflowApiService.executionCallQueue.length == 0) {
					// nothing to do
					return;
				}

				var next = bworkflowApiService.executionCallQueue[0];
				var period = 0; // 1st time round we have 0 delay (this should improve UI responsiveness)
				if (angular.isDefined(next.tryCount) == false) {
					next.tryCount = 1;
				} else {
					next.tryCount = next.tryCount + 1;
					period = bworkflowApiService.executionCallQueuePeriod * next.tryCount;
				}

				// limit retry period to a max of 30 seconds
				if (period > 30000) {
					period = 30000;
				}

				// If we are retrying an operation then we have to extend its individual timeout to give the server a chance to finish
				next.originalTimeout = next.originalTimeout || next.timeout || 30000;
				next.timeout = (period + next.originalTimeout) || period;

				$timeout(function () {
					bworkflowApiService.processQueuedExecutionCall();
				}, period);
			},
			processQueuedExecutionCall: function () {
				// we grab the top item off of the queue and process it
				if (bworkflowApiService.executionCallQueue.length == 0) {
					return;
				}

				var call = bworkflowApiService.executionCallQueue[0];
				var deferredCall = bworkflowApiService.executionCallQueueDeferred[call.deferredGuid];

				var success = function (call, data) {
					// the call made it through to the server, so remove it from the queue
					bworkflowApiService.executionCallQueue.splice(0, 1);
					// persist the queue to local storage
					bworkflowApiService.toLocalStorage(bworkflowApiService.localStorageExecutionQueueKey, bworkflowApiService.executionCallQueue);
					bworkflowApiService.processNextQueuedExecutionCall();

					if (angular.isDefined(deferredCall)) {
						deferredCall.resolve(data);
						delete bworkflowApiService.executionCallQueueDeferred[call.deferredGuid];
					}
				};

				if (call.type == 'execute' || angular.isDefined(call.type) == false) {
					RequestsErrorHandler.specificallyHandled(
						function () {
							$q.all({ execute: PlayerExecute(call.handler, call.method, call.parameters, call.timeout) }).then(
								function (data) {
									success(call, data);
								},
								function (result) {
									if (result.status != 0) {
										// looks like the server has had an issue, we need to have a think
										// about what to do here, as we don't want a bug server side to
										// block off all comms after it (since we pop off the top). Suggest
										// creating a blocked list, which items that fail like this get popped
										// onto and can be attempt to be processed once the primary list is cleared out
										// that way if the server hapened to be down at this point, it will still get processed
										// when things come back online.
									}
									// the call didn't make it through, just try again in awhile
									bworkflowApiService.processNextQueuedExecutionCall();
								});
						});
				} else {// the type must be custom then, in which case we get the handler and call back to it so that it can
					// do something specific, typically not through the execute API endpoint.
					var h = executionHandlers[call.handler];

					if (angular.isDefined(h) == true) {
						RequestsErrorHandler.specificallyHandled(
							function () {
								$q.all({ execute: h.callback(call) }).then(
									function (data) {
										success(call, data);
									},
									function (result) {
										if (result.status != 0) {
											// looks like the server has had an issue, we need to have a think
											// about what to do here, as we don't want a bug server side to
											// block off all comms after it (since we pop off the top). Suggest
											// creating a blocked list, which items that fail like this get popped
											// onto and can be attempt to be processed once the primary list is cleared out
											// that way if the server hapened to be down at this point, it will still get processed
											// when things come back online.
										}
										// the call didn't make it through, just try again in awhile
										bworkflowApiService.processNextQueuedExecutionCall();
									});
							});
					}
				}
			},

			toLocalStorage: function (key, obj) {
				return BworkflowStorage.setItem(key, obj);
			},

			fromLocalStorage: function (key, callback) {
				return BworkflowStorage.getItem(key).then(data => {
					callback?.(data);
					return data;
				})
			}
		};


		bworkflowApiService.fromLocalStorage(bworkflowApiService.localStorageExecutionQueueKey, function (data) {
			if (bworkflowApiService.executionCallQueue == null) {
				bworkflowApiService.executionCallQueue = [];
			}

			// There is a chance the executionCallQueue already had data in it, make sure we dont simply overwrite it
			if (angular.isArray(data) && data.length) {
				bworkflowApiService.executionCallQueue = bworkflowApiService.executionCallQueue.concat(data);
			}

			bworkflowApiService.processNextQueuedExecutionCall();
		});

		return bworkflowApiService;
	}]);


(function () {
	var filtersModule = angular.module('vm-filters');

	//Sneak in the client filter here
	filtersModule.filter('serverUrl', ['$window', function ($window) {
		return function (inputstr) {
			return inputstr.replace(/^~\//, rtrim($window.razordata.siteprefix) + '/');
		};
	}]);

	filtersModule.filter('mediaUrl', ['$window', function ($window) {
		return function (inputstr) {
			return inputstr.replace(/^~\//, rtrim($window.razordata.mediaprefix) + '/');
		};
	}]);


})();