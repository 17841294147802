import * as uuid from 'uuid';
import is_uuid from 'uuid-validate';
import angular from 'angular';
import { IExternBuilder } from '../../externs-builder';
import { CheckboxQuestion } from './checkbox-question';
import { DateTimeQuestion } from './date-time-question';
import { FreeTextQuestion } from './free-text-question';
import { LabelQuestion } from './label-question';
import { MultiChoiceQuestion } from './multi-choice-question';
import { NumberQuestion } from './number-question';
import { Section } from './section';
import { TemperatureProbeQuestion } from './temperature-probe-question';
import { IDesignDocumentFacility, IQuestionDefinition, ISectionDefinition, IDesignDocumentFacilityDefinition, DesignQuestion, ICheckboxQuestionDefinition, DesignCheckboxQuestion, IDateTimeQuestionDefinition, DesignDateTimeQuestion, IFreeTextQuestionDefinition, DesignFreeTextQuestion, ILabelQuestionDefinition, DesignLabelQuestion, IMultiChoiceQuestionDefinition, DesignMultiChoiceQuestion, INumberQuestionDefinition, DesignNumberQuestion, DesignSection, ITemperatureProbeQuestionDefinition, DesignTemperatureProbeQuestion, FacilityType, IFacility } from 'lingo-api';
import { IOfflineChecklistPlayer } from '../../offline-checklist-player';


export type QuestionClass<TQuestion> = new (definition: TQuestion) => TQuestion;

export class DesignDocumentFacility implements IFacility, IDesignDocumentFacility, IExternBuilder {
	public readonly facilityType: FacilityType = `designDocument`;

	private nextId: number = 1;
	public questions: { [name: string]: IQuestionDefinition } = Object.create(null);

	public allSections: ISectionDefinition[] = [];
	public sections: { [name: string]: ISectionDefinition } = Object.create(null);

	private static readonly DEFAULT_GUID_NAMESPACE = `0BB1CCEA-1F14-4168-9E23-D8566A15FA15`;

	static $inject = [`definition`, `offlineChecklistPlayer`];
	constructor(private definition: IDesignDocumentFacilityDefinition, private offlineChecklistPlayer: IOfflineChecklistPlayer) {
	}

	// Dont want to extern _context_ as we dont know what it contains
	buildExterns(externs: any[], iterate: (o: any) => any): void {
		externs.push(
			iterate(this.definition),
			iterate(this.questions),
			iterate(this.sections),
			iterate(this.allSections)
		);
	}

	// Make a predictive Guid based on a name which is namespaced to the contextual Checklist  
	private makeId(name: string) {
		let guidNamespace = this.definition.name;
		if (guidNamespace == null) {
			// default to using the name of the running checklist ..
			guidNamespace = this.offlineChecklistPlayer.getCurrentContext()?.workingDocumentId;
		}

		if (!is_uuid(guidNamespace)) {
			guidNamespace = uuid.v5(guidNamespace, DesignDocumentFacility.DEFAULT_GUID_NAMESPACE);
		}

		// Note, the following will generate the same Guid for the same set of inputs
		return uuid.v5(name, guidNamespace as string);
	}

	private createQuestion<TQuestion extends DesignQuestion, TDefinition extends IQuestionDefinition>(q: TQuestion, definition: TDefinition): TQuestion {
		Object.assign(q, definition);

		// All questions should really define a name, this is a fallback for 'anonymous' questions, but for dynamic checklists it will generate unpredictable question names
		if (q.name == null) {
			q.name = `question${this.nextId}`;
		}
		if (this.questions[q.name] != null) {
			throw new Error(`Question ${q.name} is already defined`);
		}
		this.nextId++;
		q.id = this.makeId(q.name);
		this.questions[q.name] = q;
		return q;
	}

	checkboxTemplate(definition: Partial<ICheckboxQuestionDefinition>): Partial<ICheckboxQuestionDefinition> {
		return { ...definition };
	}
	checkbox(definition: ICheckboxQuestionDefinition): DesignCheckboxQuestion {
		return this.createQuestion(new CheckboxQuestion(), definition);
	}

	dateTimeTemplate(definition: Partial<IDateTimeQuestionDefinition>): Partial<IDateTimeQuestionDefinition> {
		return { ...definition };
	}
	dateTime(definition: IDateTimeQuestionDefinition): DesignDateTimeQuestion {
		return this.createQuestion(new DateTimeQuestion(), definition);
	}

	freeTextTemplate(definition: Partial<IFreeTextQuestionDefinition>): Partial<IFreeTextQuestionDefinition> {
		return { ...definition };
	}
	freeText(definition: IFreeTextQuestionDefinition): DesignFreeTextQuestion {
		return this.createQuestion(new FreeTextQuestion(), definition);
	}

	labelTemplate(definition: Partial<ILabelQuestionDefinition>): Partial<ILabelQuestionDefinition> {
		return { ...definition };
	}
	label(definition: ILabelQuestionDefinition): DesignLabelQuestion {
		return this.createQuestion(new LabelQuestion(), definition);
	}

	multiChoiceTemplate(definition: Partial<IMultiChoiceQuestionDefinition>): Partial<IMultiChoiceQuestionDefinition> {
		let result = { ...definition };
		if (definition.choices != null) {
			if (definition.choices.find(c => angular.isString(c))) {
				result.choices = definition.choices?.map(c => {
					if (angular.isString(c)) {
						return {
							id: this.makeId(c),
							value: c,
							display: c
						}
					} else {
						return c;
					}
				})
			} else {
				result.choices = definition.choices;
			}
		}
		return result;
	}
	multiChoice(definition: IMultiChoiceQuestionDefinition): DesignMultiChoiceQuestion {
		return this.createQuestion(new MultiChoiceQuestion(), definition);
	}

	numberTemplate(definition: Partial<INumberQuestionDefinition>): Partial<INumberQuestionDefinition> {
		return { ...definition };
	}
	number(definition: INumberQuestionDefinition): DesignNumberQuestion {
		return this.createQuestion(new NumberQuestion(), definition);
	}

	section(definition: ISectionDefinition): DesignSection {
		let section = new Section();
		Object.assign(section, definition);
		if (this.sections[section.name] != null) {
			throw new Error(`Section ${section.name} is already defined`);
		}
		section.id = this.makeId(section.name);
		this.sections[section.name] = section;
		this.allSections.push(section);
		return section;
	}

	temperatureProbeTemplate(definition: Partial<ITemperatureProbeQuestionDefinition>): Partial<ITemperatureProbeQuestionDefinition> {
		return { ...definition };
	}
	temperatureProbe(definition: ITemperatureProbeQuestionDefinition): DesignTemperatureProbeQuestion {
		return this.createQuestion(new TemperatureProbeQuestion(), definition);
	}

	makeExports() {
		return {
			DesignDocument: this,
			Question: this.questions,
			Section: this.sections,
			AllSections: this.allSections
		}
	}
}

