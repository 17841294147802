'use strict';

import './index.scss';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { IVMMediaService, IMediaOnClient, IMediaOnServer, IMediaOnClientSaveToDeviceResult } from '../../services/vm-media-service';
import { IVMPdf, IPdfDocument, IPdfPage } from '../../services/vm-pdf';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { PredictAllAgeAndGenderWithFaceAlignmentTask } from 'face-api.js/build/commonjs/globalApi/PredictAgeAndGenderTask';

class pdfViewerController extends ControllerBase implements IVMRegisteredComponent {
    public name: string;
    public showControls: boolean;
    protected media?: IMediaOnServer | IMediaOnClient | IPdfDocument;

    protected pdfDocument?: IPdfDocument;
    protected pdfPageCache: Array<IPdfPage>;
    protected currentPage?: IPdfPage;
    protected currentIndex: number;
    protected currentScale: number;
    protected viewMode: string;

    protected canvasElement: HTMLCanvasElement | null;

    getComponentType(): string {
        return 'PdfViewer';
    }

    static $inject = ['$element', 'vmRegisteredComponentProvider', 'vmPdf', 'vmMediaService'];
    constructor(
        protected $element: ng.IRootElementService,
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider,
        protected vmPdf: IVMPdf,
        protected vmMediaService: IVMMediaService
    ) {
        super();

        this.name = '';
        this.showControls = true;
        this.currentIndex = 0;
        this.canvasElement = null;
        this.pdfPageCache = [];
        this.currentScale = 1;
        this.viewMode = 'fit-page';
    }

    $onInit(): void {
        if (angular.isDefined(this.name) == false || this.name == '') {
            console.warn('vm pdf viewer must be given a name');
            throw 'vm-pdf-viewer must be given a name';
        }

        this.vmRegisteredComponentProvider.register(this);

        this.canvasElement = this.$element.find('.viewer')[0] as HTMLCanvasElement;

        if (this.showControls == null) {
            this.showControls = true;
        }
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.media && changes.media.currentValue != null) {
            this.media = changes.media.currentValue;

            this.loadPdfDocument();
        }

        if (changes.showControls && changes.showControls.currentValue != null) {
            this.showControls = changes.showControls.currentValue;
        }
    }

    loadPdfDocument(): void {
        if (this.media == null) {
            this.pdfDocument = undefined;
            return;
        }

        this.pdfPageCache = [];
        this.currentPage = undefined;

        if (this.vmMediaService.isMediaOnServer(this.media)) {
            let onClients = this.vmMediaService.createOnClientFromOnServer([this.media]);
            this.vmMediaService.loadClient(onClients[0]).then((client) => {
                this.transformOnClientToPdf(client);
            });
        }
        else if (this.vmMediaService.isMediaOnClient(this.media)) {
            this.vmMediaService.loadClient(this.media).then((client) => {
                this.transformOnClientToPdf(client);
            });
        }
        else if (this.vmPdf.isPdfDocument(this.media)) {
            this.viewPage(1);
        }
    }

    transformOnClientToPdf(onClient: IMediaOnClient): void {
        this.vmPdf.mediaOnClientToPdf(onClient).then((doc) => {
            this.pdfDocument = doc;
            this.viewPage(1);
        });
    }

    viewPage(index: number) {
        if (this.pdfDocument == null) {
            return;
        }

        if (index < 1 || index > this.pdfDocument?.pageCount) {
            return;
        }

        this.currentIndex = index;

        // index is the page number, so is 1 based
        if (index - 1 < this.pdfPageCache.length) {
            // we've already been here before
            if (this.canvasElement == null) {
                return;
            }

            this.currentPage = this.pdfPageCache[index - 1];
            this.currentPage.render(this.canvasElement, this.currentScale);
            return;
        }

        this.pdfDocument.getPage(index).then((page) => {
            if (this.canvasElement == null) {
                return;
            }

            this.currentPage = page;
            this.pdfPageCache.push(page);

            this.currentPage.render(this.canvasElement, this.currentScale);
        });
    }

    backwards(): void {
        if (this.currentPage == null) {
            return;
        }

        this.viewPage(this.currentPage.pageNumber - 1);
    }

    forwards(): void {
        if (this.currentPage == null) {
            return;
        }

        this.viewPage(this.currentPage.pageNumber + 1);
    }

    toggleFitToPage(): void {
        if (this.currentPage == null) {
            return;
        }

        this.viewMode = this.viewMode == 'fit-page' ? 'normal' : 'fit-page';
        this.currentScale = 1;
        this.viewPage(this.currentPage.pageNumber);
    }

    zoomIn(): void {
        if (this.currentPage == null) {
            return;
        }

        this.viewMode = 'normal';
        this.currentScale = this.currentScale * 2;
        this.viewPage(this.currentPage.pageNumber);
    }

    zoomOut(): void {
        if (this.currentPage == null) {
            return;
        }

        this.viewMode = 'normal';
        this.currentScale = this.currentScale / 2;
        this.viewPage(this.currentPage.pageNumber);
    }

    $onDestroy(): void {
        this.vmRegisteredComponentProvider.deRegister(this);
    }
}

app.component('vmPdfViewer', {
    template: require('./template.html').default,
    bindings: {
        name: '@',
        media: '<',
        showControls: '<'
    },
    controller: pdfViewerController
});