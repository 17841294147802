import app from "../ngmodule";
import { IAboutService } from "./interface";
import { IWebServiceUrl } from "../../common/web-service-url";
import { AboutCategory } from "./about-category";
import * as Rx from "rxjs";
import { BaseAboutService } from "./base-about-service";

export class DesktopAboutService extends BaseAboutService implements IAboutService {
	static $inject = [
		`webServiceUrl`,
		`$timeout`
	];

	constructor(
		private webServiceUrl: IWebServiceUrl,
		$timeout: ng.ITimeoutService
	) {
		super($timeout);
	}

	getServerVersion(): Promise<string> {
		return fetch(this.webServiceUrl.api(`app/webappversion?nonce=${Math.random()}`)).then<string>(response => response.text());
	}

	refresh(categories?: AboutCategory[]): AboutCategory[] {
		categories = categories || [];
		let information = this.getOrAdd(categories, "information", "Information");
		information.beginUpdate();
		information.setAbout({ order: 1, display: `Server version`, value: this.getServerVersion() });
		information.endUpdate();
		return categories;
	}
}

export function useDesktopAboutService() {
	app.factory('aboutSvc', DesktopAboutService);
}