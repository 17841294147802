import { Question, Answer } from "./question";
import { JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { INumberQuestionDefinition, NumberQuestionKeypadType, DesignNumberQuestion } from 'lingo-api';
import { INumberAnswer, IPresentedNumberQuestion } from "../../presented-types";

export class NumberQuestion extends Question implements DesignNumberQuestion {
	defaultAnswer?: number;
	keypadType?: NumberQuestionKeypadType = 'none';
	step?: number = 1;

	constructor() {
		super(`number`);
	}
}

export class NumberAnswer extends Answer implements ILingoAnswer {
	constructor(public answer: INumberAnswer, public presentedQuestion: IPresentedNumberQuestion) {
		super(answer, presentedQuestion);
	}

	get value() {
		return Number(this.answer.Number);
	}
	set value(value: number | null) {
		this.answer.Number = Number(value);
	}
}

JsonTreeTranslators.register({
	ctr: NumberQuestion,
	create: () => new NumberQuestion()
}, {
	ctr: NumberAnswer,
	flatten(o: NumberAnswer) {
		return {
			answer: o.answer,
			presentedQuestion: o.presentedQuestion
		}
	},
	fatten(o: any, fatten: Convert, store: Convert) {
		return store(new NumberAnswer(fatten(o.answer), fatten(o.presentedQuestion)));
	}
});
