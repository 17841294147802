import app from './ngmodule';
import { IValidationMessageFactory, IValidationResult, IValidationRule } from '../';
import { ValidationRuleBase } from './validation-rule-base';

// Validation rule that fails if the value handed into it is undefined or null
export class RegaularExpressionValidationRule extends ValidationRuleBase {

    protected regExpression: RegExp;

    isRegularExpression(test: any): boolean {
        return (test as RegExp).test !== undefined;
    }

    constructor(message: string | IValidationMessageFactory, expression: string | RegExp) {
        super(message);

        this.regExpression = this.isRegularExpression(expression) ? expression as RegExp : new RegExp(expression);
    }

    isValid(value: any, template: any): IValidationResult {
        return this.regExpression.test(value) ? { errors: [] } : { errors: [this.getMessage(value, template)] };
    }
}