'use strict';

import app from '../../ngmodule';
import angular from 'angular';

app.controller('presentCtrl', ['$scope', '$stateParams', 'transitionCache', 'bworkflowApi', '$state', '$q', 'appUpdateMonitor',
  function ($scope, $stateParams, transitionCache, bworkflowApi, $state, $q, appUpdateMonitor) {
    appUpdateMonitor.addPauser($scope); // Pause updates whilst our scope is alive

    $scope.models.playerModel = transitionCache.getAndDelete($stateParams.workingDocumentId);

    $scope.buttonStates.canPrevious = $scope.models.playerModel.State == 'Presenting' ? //allow back only if we're presenting and canPrevious()
      $scope.models.playerModel.CanPrevious : false;
    $scope.buttonStates.canNext = $scope.models.playerModel.State == 'Presenting'; //don't want to next() for finished presented

    if ($scope.models.playerModel.Presented) {
      //enumerate all the answerable nodes in the answerModel
      (function thisfunc(_, p) {//jquery each makes the actual value the second arg, so define the function this way
        if (p.IsAnswerable) {
          $scope.models.answerModel[p.Id] = p.Answer || {};
          p.Answer = $scope.models.answerModel[p.Id];
        }
        if (p.Children) {
          $.each(p.Children, thisfunc);
        }
      })(undefined, $scope.models.playerModel.Presented);
    }

    $scope.$on('player.next', function (ev, args) {
      $scope.buttonActions.doTransition('next');
    });

    $scope.$on('player.back', function (ev, args) {
      $scope.buttonActions.doTransition('prev');
    });

    //Next/back button event handler- submit to server then transition to self
    //type is either 'next' or 'prev'
    $scope.buttonActions.doTransition = function (type, nonce) {
      // do any client side validation first
      if (type == 'next') {
        var data = {
          errors: [],
          stage: 'next'
        };

        $scope.$broadcast('validate', data);

        if (data.errors.length > 0) {
          return;
        }
      }

      $scope.buttonStates.areAjaxing = true; //turn on the AJAX loader spinny thing

      //start by broadcasting a next notification downwards
      //In the eventArgs will be a method that can be called to attach a promise to a list
      //We only actually do the server next method once all the promises have been resolved
      var promiseList = [];
      var attachPromise = function (promise) {
        promiseList.push(promise);
      };
      var ev = {
        attachPromise: attachPromise,
        type: type //either 'next' or 'prev'
      };
      //events are broadcast synchronously in angular:
      //https://groups.google.com/forum/#!msg/angular/yyH3FYAy5ZY/GVy0ckj29CIJ
      //Means that promiseList is populated after this line
      $scope.$broadcast('populateAnswer', ev);

      //only after the promises are resolved do we do anything else
      $q.all(promiseList).
        then(function () {
          if (type == 'next')
            return bworkflowApi.nextChecklist($scope.models.playerModel.WorkingDocumentId,
              $scope.models.answerModel);
          else
            if (type == 'prev')
              return bworkflowApi.prevChecklist($scope.models.playerModel.WorkingDocumentId);
            else
              return undefined;
        }).
        then(function (r) {
          $scope.buttonStates.areAjaxing = false;
          var slideDir = type == 'prev' ? 'left' : 'right';
          $scope.transitionWithPlayerModel(r, slideDir, nonce);
        }, function (ex) {
          $scope.buttonStates.areAjaxing = false;
          if (ex) {
            var slideDir = type == 'prev' ? 'left' : 'right'; //error callback
            $scope.transitionWithPlayerModel(ex.data, slideDir, nonce);
          }
        });

    };
  }]);