'use strict';

import './index.scss';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';

class ValidationErrorListController extends ControllerBase {
    public items?: [];
    public _items?: [];

    $onInit(): void {
        this._items = [];

        if (angular.isDefined(this.items) == true) {
            this._items = angular.copy(this.items);
        }
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.items) {
            this._items = angular.copy(this.items);
        }
    }
}

app.component('vmValidationErrorList', {
    template: require('./template.html').default,
    bindings: {
        items: '<'
    },
    controller: ValidationErrorListController
});