'use strict';

import '../../../directives/vm-card-list';

import app from '../../ngmodule';
import angular from 'angular';

app.component('groupsCtrl', {
  template: require('./template.html').default,
  bindings: {
    groups: '<'
  }
});