import bworkflowApiModule from './ngmodule';

bworkflowApiModule.factory('serverBasedUserSettings', ['$http', '$window', function ($http, $window) {
  const fullurl = () => $window.razordata.apiprefix;

  var svc = {
    getUserSetting: function (name) {
      return $http.get(fullurl() + 'UserSetting/Get?name=' + name).
        then(function (response) {
          return JSON.parse(response.data);
        });
    },

    setUserSetting: function (name, value) {
      return $http.post(
        fullurl() + 'UserSetting/Put', {
        name: name,
        value: JSON.stringify(value)
      });
    }
  };

  return svc;
}]);

