import QRScanner from 'qr-code-scanner';
import QrCode from 'qrcode-reader';
import './html5-qr-scanner.scss';
import { IVMRegisteredComponentProvider } from '../../services/vm-registered-component-provider';
import { IVMModal } from '../../services/vm-modal-manager-service';

const Html5QrScanner = function (scope, $timeout, $compile, vmRegisteredComponentProvider) {
	scope.scan = function () {
		const elm = require('./html5-qr-scanner.template.html').default;
		scope.html5qrscannerElement = $compile(elm)(scope);
		scope.html5qrscannerElement.appendTo('body');

		const modal = vmRegisteredComponentProvider.get("html5-qr-scanner", "modal");
		modal.show();

		$timeout(() => {
			const video = scope.html5qrscannerElement.find('video')[0];
			const canvas = $("<canvas></canvas>")[0];
			const cancelBtn = scope.html5qrscannerElement.find('#qr-code-scanner-cancel-btn');

			const qr = new QrCode();
			let scanning = true;

			cancelBtn.click(() => {
				scanning = false;
			})

			video.addEventListener('ended', close);
			video.addEventListener('loadedmetadata', () => {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
			});


			qr.callback = (err, scan) => {
				if (err) {
					return;
				}
				console.log(scan);
				scanning = false;
				scope.onScan({
					text: scan.result
				});
			}

			const scanFrame = () => {
				const ctxt = canvas.getContext('2d');
				ctxt.drawImage(video, 0, 0, canvas.width, canvas.height);
				qr.decode(ctxt.getImageData(0, 0, canvas.width, canvas.height));
				if (scanning) {
					requestAnimationFrame(scanFrame);
				} else {
					close();
				}
			}

			window.navigator.mediaDevices.getUserMedia({
				video: {
					facingMode: 'environment'
				}
			}).then(stream => {
				video.srcObject = stream;

				requestAnimationFrame(scanFrame);
			}).catch(error => {
				console.log(error);
				close();
			})

			function close() {
				$timeout(() => {
					if (video?.srcObject != null) {
						video.srcObject.getTracks().forEach(track => track.stop());
					}

					modal.close();
				});
			}
		});
	}

	scope.modalClosed = function () {
		$timeout(() => {
			scope.html5qrscannerElement.remove();
		});
	}
}

export default Html5QrScanner;