'use strict';

import { LingoRuntimeContext } from './lingo-runtime-context';
import { LingoAnswersContext } from './lingo-answers-context';
import { LingoProgramContext } from './lingo-program-context';

export { LingoRuntimeContext } from './lingo-runtime-context';
export { LingoAnswersContext } from './lingo-answers-context';
export { LingoProgramContext } from './lingo-program-context';

export interface LingoContext {
    runtimeContext: LingoRuntimeContext;
    answersContext: LingoAnswersContext;
    programContext: LingoProgramContext;
    lingoSyntax: any;
}

export function createContext(): LingoContext {
    let runtimeContext = new LingoRuntimeContext();
    let answersContext = new LingoAnswersContext(runtimeContext);
    let programContext = new LingoProgramContext(runtimeContext, answersContext);

    return {
        runtimeContext,
        answersContext,
        programContext,

        lingoSyntax: {
            Answer: answersContext.Answer,
            Answers: answersContext.Answers
        }
    };
}
