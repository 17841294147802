'use strict';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';

export interface IWidgetContainer {
    setContent(widget: IWidget): void;
}

export interface IWidget {
    getContent(): ng.IRootElementService;
}

class WidgetContainerController extends ControllerBase implements IWidgetContainer, IVMRegisteredComponent {
    public name: string;
    protected bodyReplaced: boolean;

    getComponentType(): string {
        return 'WidgetContainer';
    }

    static $inject = ['$element', 'vmRegisteredComponentProvider'];
    constructor(protected $element: ng.IRootElementService, protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider) {
        super();

        this.name = '';
        this.bodyReplaced = false;
    }

    $onInit(): void {
        if (angular.isDefined(this.name) == false || this.name == '') {
            console.warn('vm-widget-container must be given a name');
            throw 'vm-widget-container must be given a name';
        }

        this.vmRegisteredComponentProvider.register(this);
    }

    $onDestroy(): void {
        this.vmRegisteredComponentProvider.deRegister(this);
    }

    setContent(widget: IWidget): void {
        let el = widget.getContent();
        let toReplace = this.$element.find('.vm-widget-body-replace');

        toReplace.empty();
        toReplace.append(el);

        this.bodyReplaced = true;
    }
}

app.component('vmWidgetContainer', {
    template: require('./template.html').default,
    transclude: true,
    bindings: {
        name: '@'
    },
    controller: WidgetContainerController
});