'use strict';

import angular from 'angular';
import questionsModule from './ngmodule';
var sprintf = require('sprintf-js').sprintf;

//This directive is used to select the appropriate directive for a presented
//then, it inserts said directive into the DOM causing it to evaluate
//Used by top-level template and section template
questionsModule.directive('renderQuestion', ['$compile', '$parse', function ($compile, $parse) {
  return {
    restrict: 'EA',
    scope: {
      presented: '=renderQuestion',
      pageScope: '=',
      watch: '='
    },

    link: function (scope, elt, attrs) {
      var dirName = scope.presented.Directive;

      var childScope;
      var build = () => {
        if (childScope) {
          childScope.$destroy();
        }
        childScope = scope.$new();
        var elString = sprintf('<div %s presented="presented" page-scope="pageScope"></div>', dirName);
        elt.html(elString);
        $compile(elt.contents())(childScope);
      };
      build();

      if (scope.watch) {
        scope.$watch('presented', build);
      }
    }
  };

}]);