'use strict';

import app from '../../ngmodule';
import angular from 'angular';

app.component('revieweesCtrl', {
  template: require('./template.html').default,
  bindings: {
    group: '<'
  },

  controller: ["$stateParams", function ($stateParams) {
	this.resourceId = $stateParams.resourceId;
    this.publishingGroupResourceId = $stateParams.publishingGroupResourceId;
  }]
});