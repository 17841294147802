'use strict';

import './index.scss';
import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { StringNullableChain } from 'lodash';

export class ButtonController extends ControllerBase {
    public prompt: string;
    public icon: string;
    public size: string;
    public fillMode: string;
    public color: string;
    public rounded: string;
    public disabled: boolean;

    private kendoButton?: kendo.ui.Button;

    static $inject = ['$element', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService) {
        super();

        this.prompt = '';
        this.icon = '';
        this.size = '';
        this.fillMode = '';
        this.color = '';
        this.rounded = '';
        this.disabled = false;
    }

    protected valuesFromHtml() : void {
        if (this.prompt == undefined) {
            this.prompt = this.valueFromHtml("prompt", '', this.$element);
        }

        if (this.icon == undefined) {
            this.icon = this.valueFromHtml("icon", '', this.$element);
        }

        if (this.size == undefined) {
            this.size = this.valueFromHtml("size", '', this.$element);
        }

        if (this.fillMode == undefined) {
            this.fillMode = this.valueFromHtml("fill-mode", '', this.$element);
        }

        if (this.color == undefined) {
            this.color = this.valueFromHtml("color", '', this.$element);
        }

        if (this.rounded == undefined) {
            this.rounded = this.valueFromHtml("rounded", '', this.$element);
        }
    }

    $onInit(): void {
        this.valuesFromHtml();

        this.$timeout(() => {
            let config: kendo.ui.ButtonOptions = {} ;

            if(this.icon != '' && this.icon != undefined)
            {
                config.icon = this.icon;
            }

            if(this.size != '' && this.size != undefined)
            {
                config.size = this.size;
            }

            if(this.fillMode != '' && this.fillMode != undefined)
            {
                config.fillMode = this.fillMode;
            }

            if(this.color != '' && this.color != undefined)
            {
                config.themeColor = this.color;
            }

            if(this.rounded != '' && this.rounded != undefined)
            {
                config.rounded = this.rounded;
            }

            this.kendoButton = this.$element.find('button').kendoButton(config).data('kendoButton');

            this.kendoButton?.enable(!this.disabled);
        });        
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if(changes.disabled && changes.disabled.currentValue != undefined && this.kendoButton) {
            this.kendoButton.enable(!changes.disabled.currentValue);
        }
    }
}

app.component('vmButton', {
    template: require('./template.html').default,
    bindings: {
        prompt: '<',
        icon: '<',
        size: '<',
        fillMode: '<',
        color: '<',
        rounded: '<',
        disabled: '<'
    },
    controller:  ButtonController
});