import bworkflowErrorHandlingModule from './ngmodule';

export default bworkflowErrorHandlingModule;

var HEADER_NAME = 'Bworkflow-Handle-Errors-Generically';
var specificallyHandleInProgress = false;

bworkflowErrorHandlingModule.factory('RequestsErrorHandler', ['$q', '$injector', function ($q, $injector) {
  return {
    // --- The user's API for claiming responsiblity for requests ---
    specificallyHandled: function (specificallyHandledBlock) {
      specificallyHandleInProgress = true;
      try {
        return specificallyHandledBlock();
      } finally {
        specificallyHandleInProgress = false;
      }
    },

    // --- Response interceptor for handling errors generically ---
    responseError: function (rejection) {
      var shouldHandle = rejection && rejection.config && rejection.config.headers &&
        rejection.config.headers[HEADER_NAME];

      if (shouldHandle) {
        var $rootScope = $injector.get("$rootScope");

        $rootScope.$broadcast('ajax.error', rejection);
      }

      return $q.reject(rejection);
    }
  };

}]);

bworkflowErrorHandlingModule.config(['$provide', '$httpProvider', function ($provide, $httpProvider) {
  // intercept javascript issues first
  $provide.decorator("$exceptionHandler", ["$delegate", "$injector", function ($delegate, $injector) {
    return function (exception, cause) {
      var $rootScope = $injector.get("$rootScope");

      $rootScope.$broadcast('script.error', { exception: exception, cause: cause });

      $delegate(exception, cause);
    };
  }]);

  // now ajax

  $httpProvider.interceptors.push('RequestsErrorHandler');

  function makeStack() {
    // LUCIFER-224 realised we dont need to throw an Error to grab the stack
    var error = new Error('Ajax error');
    // We are not interested in the last 5 functions, these are within this module, we want the $http caller's stack and above
    var stack = error.stack.split('\n');
    var realStack = stack.slice(5, stack.length - 1);
    return realStack.join('\n');
  }

  // --- Decorate $http to add a special header by default ---
  function addHeaderToConfig(config) {
    config = config || {};
    config.headers = config.headers || {};

    // Add the header unless user asked to handle errors himself, or if it's already present due to someone
    // not calling specificallyHandled (for example in vmApp.js in the IOS/Android app) then we don't overwrite it
    if (!specificallyHandleInProgress && angular.isDefined(config.headers[HEADER_NAME]) == false) {
      config.headers[HEADER_NAME] = true;
    }

    // Tag this with the callers Stack so it can be reported should an error occur
    config.stack = makeStack();

    return config;
  }

  // The rest here is mostly boilerplate needed to decorate $http safely
  $provide.decorator('$http', ['$delegate', function ($delegate) {
    function decorateRegularCall(method) {
      return function (url, config) {
        return $delegate[method](url, addHeaderToConfig(config));
      };
    }

    function decorateDataCall(method) {
      return function (url, data, config) {
        return $delegate[method](url, data, addHeaderToConfig(config));
      };
    }

    function copyNotOverriddenAttributes(newHttp) {
      for (var attr in $delegate) {
        if (!newHttp.hasOwnProperty(attr)) {
          if (typeof $delegate[attr] === 'function') {
            newHttp[attr] = function () {
              return $delegate.apply($delegate, arguments);
            };
          } else {
            newHttp[attr] = $delegate[attr];
          }
        }
      }
    }

    var newHttp = function (config) {
      if (config.noHeaderModify) {
        return $delegate(config);
      } else {
        return $delegate(addHeaderToConfig(config));
      }
    };

    newHttp.get = decorateRegularCall('get');
    newHttp.delete = decorateRegularCall('delete');
    newHttp.head = decorateRegularCall('head');
    newHttp.jsonp = decorateRegularCall('jsonp');
    newHttp.post = decorateDataCall('post');
    newHttp.put = decorateDataCall('put');

    copyNotOverriddenAttributes(newHttp);

    return newHttp;
  }]);
}]);