'use strict';

import app from './ngmodule';

export interface IVMModal {
    name: string;

    // show's the modal
    show(): void;
    // closes the modal (remove it from the DOM)
    close(): void;
    // toggles the show/close of the modal
    toggle(): void;
    // hides the modal but does not remove it from the DOM
    makeInvisible(): void;
    // unhides the modal
    makeVisible(): void;
}

export enum VMModalListenerOperation {
    push = 1,
    pop
}

export interface IVMModalListener {
    notify(operation: VMModalListenerOperation, nowShowingModal: IVMModal | null, service: IVMModalManagerService): void;
}

export interface IVMModalManagerService {
    // pushes the modal onto the stack of modals that are being shown.
    // the currently shown modal (if there is one) is hidden
    push(modal: IVMModal): void;
    // pop's the top modal off of the stack and unhides the next modal in the stack
    // if there is one
    pop(modal: IVMModal): void;

    // registers a listener who will be notified of push and pop operations as they occur
    register(listener: IVMModalListener): void;
    // de-registers a listener
    deRegister(listener: IVMModalListener): void;

    // returns the number of modal's currently being managed
    get modalCount() : number;
}

export class vmModalManagerService implements IVMModalManagerService {
    private modals: Array<IVMModal>;
    private listeners: Array<IVMModalListener>;

    static $inject = ['$q'];
    constructor(protected $q: ng.IQService) {
        this.modals = [];
        this.listeners = [];
    }

    public push(modal: IVMModal): void {
        // Can't push a modal more than once
        if (this.modals.indexOf(modal) >= 0) {
            return;
        }

        if (this.modals.length > 0) {
            this.modals[this.modals.length - 1].makeInvisible();
        }

        this.modals.push(modal);

        this.notifyListeners(VMModalListenerOperation.push, modal);
    }

    public pop(modal: IVMModal): void {
        // A modal can be popped even if its not the top modal, this allows a parent modal to show another modal and then hide itself
        this.modals = this.modals.filter(m => m !== modal);

        if (this.modals.length > 0) {
            this.modals[this.modals.length - 1].makeVisible();
        }

        this.notifyListeners(VMModalListenerOperation.pop, this.modals.length > 0 ? this.modals[this.modals.length - 1] : null);
    }

    private notifyListeners(operation: VMModalListenerOperation, modal: IVMModal | null): void {
        for(let l of this.listeners)
        {
            l.notify(operation, modal, this);
        }
    }

    public register(listener: IVMModalListener) {
        // can't register the same object more than once
        if(this.listeners.indexOf(listener) >= 0)
        {
            return;
        }

        this.listeners.push(listener);
    }

    public deRegister(listener: IVMModalListener) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    get modalCount() : number {
        return this.modals.length;
    }
}

app.factory('vmModalManagerService', vmModalManagerService);