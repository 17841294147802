import angular from 'angular';
import { LingoRuntimeContext, IFreezable } from './lingo-runtime-context';
import { LingoAnswersContext, ILingoAnswer } from './lingo-answers-context';
import { ValidateWithReasons } from './validate-with-reasons';
import { IQuestionDefinition } from 'lingo-api';

export interface IPresentArgs {
    presentArgs: {
        presentable: IQuestionDefinition;
        canPrevious: boolean;
    }
}

export type ValidationFunction = (reasons: ValidateWithReasons) => Promise<void>;
export interface ValidateWith {
    question: IQuestionDefinition;
    validation?: ValidationFunction;
}
export type QuestionOrValidateWith = IQuestionDefinition | ValidateWith;


class AccessVariables {
    public accessed: any = Object.create(null);
    public all: any = Object.create(null);
    public variables = new Proxy(this.all, {
        get: (target, name: string) => {
            let v = this.all[name];
            if (v === undefined) {
                return v;
            }
            return this.accessed[name] = this.all[name];
        },
        set: (target, name: string, value: any) => {
            this.all[name] = value;
            return this.accessed[name] = this.all[name];
        }
    })

    reset() {
        this.accessed = Object.create(null);
    }

    merge(o: any) {
        Object.assign(this.all, o);
        this.accessed = o;
    }
}

export class LingoProgramContext implements IFreezable {
    public readonly name: string = `LingoProgramContext`;
    public presentCount: number = 0;
    public presentingArray: ValidateWith[] | null = null;
    public presentingIndex: number = 0;
    private _locals = new AccessVariables();
    public locals = this._locals.variables;
    private _consts = new AccessVariables();
    public consts = this._consts.variables;
    public next: number = 0;
    public prev: number = 0;
    public invalidReasons: ValidateWithReasons = new ValidateWithReasons();

    constructor(private runtimeContext: LingoRuntimeContext, private answersContext: LingoAnswersContext) {
        runtimeContext.register(this);
    }

    freeze(): any {
        let frozen = {
            locals: this._locals.accessed,
            consts: this._consts.accessed,
            next: this.next,
            prev: this.prev,
            presentingIndex: this.presentingIndex,
            presentCount: this.presentCount,
            presentingArray: this.presentingArray
        }
        this._consts.reset();
        this._locals.reset();
        return frozen;
    }

    unfreeze(popsicle: any, first: boolean, last: boolean): void {
        if (first) {
            this._locals.reset();
            this._consts.reset();
        }
        this._locals.merge(popsicle.locals);
        this._consts.merge(popsicle.consts);
        this.next = popsicle.prev;                  // This is intentional, we are restoring to where we were, not where we are next
        this.prev = popsicle.prev;
        this.presentingIndex = popsicle.presentingIndex;
        this.presentCount = popsicle.presentCount;
        this.presentingArray = popsicle.presentingArray;
    }

    running(): boolean {
        return this.presentingArray == null;
    }

    stop() {
        return {};
    }

    presenting(): ValidateWith {
        return (this.presentingArray || [])[this.presentingIndex];
    }

    async receiveAnswers(answers: ILingoAnswer[]): Promise<boolean> {
        let commit = true;
        let presenting = this.presenting();
        if (presenting != null) {
            this.answersContext.beginTransaction(answers);

            this.invalidReasons = new ValidateWithReasons();
            if (presenting.validation != null) {
                let result = presenting.validation(this.invalidReasons);
                if (result && typeof result.then === 'function') {
                    await result;
                }
                commit = !this.invalidReasons.any();
            }
            if (commit) {
                this.answersContext.commitTransaction();

                if (++this.presentingIndex >= (this.presentingArray?.length || 0)) {
                    // we have finished with this array of presents, allow the code to run forward to the next Present (or end)
                    this.presentingArray = null;
                } else {
                    // there is another presentable in this array of presents, push all context 
                    this.runtimeContext.push();
                }
            } else {
                this.answersContext.rollbackTransaction();
            }
        }
        return commit;
    }

    presentArgs(): IPresentArgs | null {
        let presenting = this.presenting();
        if (presenting != null) {
            return {
                presentArgs: {
                    presentable: presenting.question,
                    canPrevious: this.presentCount > 0
                }
            };
        }
        return null;
    }

    private makeValidateWith(arg: any): ValidateWith {
        if (`validation` in arg) {
            return arg;
        } else {
            return {
                question: arg as IQuestionDefinition
            }
        }
    }

    Present(...theArgs: QuestionOrValidateWith[]) {
        let args = theArgs.filter(a => a != null);
        if (args.length) {
            // we have something new to present, push all context
            this.runtimeContext.push();

            // Handle presenting a QuestionDefinition (normally a Section) or a Section.validateWith call ..
            this.presentingArray = [];
            args.forEach(arg => {
                if (angular.isArray(arg)) {
                    this.presentingArray?.push.apply(this.presentingArray, arg.map(a => this.makeValidateWith(a)));
                } else {
                    this.presentingArray?.push(this.makeValidateWith(arg))
                }
            })

            this.presentCount++;
            this.presentingIndex = 0;
        }
    }

    previous() {
        this.runtimeContext.pop(2);
        this.presentingArray = null;
        this.next = this.prev;
    }
}

