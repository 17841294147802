import questionModelsModule from './ngmodule';

questionModelsModule.factory('questionTaskListModels', [function () {
  var svc = {
    startBehaviors: {
      none: "None",
      finish: "Finish",
      pause: "Pause",
	  finishComplete: "FinishComplete" //Lucifer-669
    },

    finishBehaviors: {
      none: "None",
      resume: "Resume",
      finish: "Finish",
	  finishComplete: "FinishComplete" //Lucifer-669
    },

    actionType: {
      clockin: "Clock In",
      clockout: "Clock Out"
    },

    newDataLevel: function (level) {
      var result = {
        taskTypes: [],
        levelNumber: level,
        startBehaviors: {
          sameLevel: svc.startBehaviors.finish,
          higherLevel: svc.startBehaviors.pause,
          lowerLevel: svc.startBehaviors.finish
        },

        finishBehaviors: {
          sameLevel: svc.finishBehaviors.none,
          higherLevel: svc.finishBehaviors.resume,
          lowerLevel: svc.finishBehaviors.none
        }
      };



      return result;
    },
    newDataModel: function () {
      var result = {
        facilities: svc.newDataLevel(0),
        buildings: svc.newDataLevel(1),
        levels: svc.newDataLevel(2),
        zones: svc.newDataLevel(3),
        sites: svc.newDataLevel(4)
      };


      return result;
    },
    createModelFromRules: function (rules) {
      var d = rules;
      if (angular.isDefined(d) == false || d == null || angular.equals(d, {}) || angular.equals(d, [])) {
        d = svc.newDataModel();
      }

      var result = {
        data: d,
        levels: [d.facilities, d.buildings, d.levels, d.zones, d.sites],
        levelContainsTaskType: function (level, taskTypeId) {
          return level.taskTypes.includes(taskTypeId);
        },
        findFacilityHierarchyLevelForTaskType: function (taskTypeId) {
          for (var i = 0; i < this.levels.length; i++) {
            var l = this.levels[i];
            if (this.levelContainsTaskType(l, taskTypeId)) {
              return l;
            }
          }

          return null;
        },
        taskActions: function (tasks, taskTypeResolver, actionLevel, testLevel, behaviourType, behavior) {
          // returns the subset of tasks that actions must be performed against. The actionLevel variable is the level against which the clockin/clockout is being done.
          // The testLevel is the level that we are testing for tasks in. So for example if a task at the site level is clocked out of, then the level will be site, it
          // defines what happens to tasks above it's level and it will be the actionLevel. The testLevel will be tested for each task to see if the task is at that level.
          var result = { tasks: [], action: svc.startBehaviors.none };

          if (testLevel.taskTypes.length == 0) {
            return result;
          }

          result.action = actionLevel[behaviourType][behavior];

          for (var i = 0; i < tasks.length; i++) {
            var task = tasks[i];
            var taskTypeId = taskTypeResolver(task);

            if (taskTypeId == null) {
              // if the resolver say's the task isn't of interest (typically as its the task that is being clocked into or out of)
              continue;
            }

            if (this.levelContainsTaskType(testLevel, taskTypeId)) {
              result.tasks.push(task);

              tasks.splice(i, 1);
            }
          }

          return result;
        },
        calculateTaskActions: function (tasks, taskTypeResolver, level, actionType) {
          // tasks should be an array of objects that the caller knows what to do with, the taskTypeResolver function should be of the form somefunc(task) is called back against for each task and should pass back
          // the task type id of the task.
          var result = [];

          if (level == null) {
            return result;
          }

          var ts = [...tasks]; // we take a copy of the tasks array as we remove things from it as we go

          var behaviourType = actionType == svc.actionType.clockin ? "startBehaviors" : "finishBehaviors";

          var r = this.taskActions(ts, taskTypeResolver, level, level, behaviourType, "sameLevel");

          if (r.tasks.length != 0) {
            result.push(r);
          }

          for (var i = 0; i < level.levelNumber; i++) {
            var l = this.levels[i];

            r = this.taskActions(ts, taskTypeResolver, level, l, behaviourType, "higherLevel");
            if (r.tasks.length != 0) {
              result.push(r);
            }
          }

          for (var i = level.levelNumber; i < this.levels.length; i++) {
            var l = this.levels[i];

            r = this.taskActions(ts, taskTypeResolver, level, l, behaviourType, "lowerLevel");
            if (r.tasks.length != 0) {
              result.push(r);
            }
          }

          return result;
        }
      };


      return result;
    },
    getRulesFromModel: function (model) {
      return model.data;
    }
  };


  return svc;
}]);