const CordovaQrScanner = function (scope, $timeout) {
	const options = angular.extend({
		preferFrontCamera: false,
		showFlipCameraButton: false,
		showTorchButton: true
	}, scope.options || {});

	scope.scan = function () {
		cordova.plugins.barcodeScanner.scan(
			function (result) {
				$timeout(function () {
					if (!result.cancelled) {
						scope.model = result.text;
						scope.onScan({
							text: result.text
						});

					} else {
						scope.onCancel();
					}
					scope.onResult({
						result: result
					});
				});
			},
			function (error) {
				scope.onError(error);
			},
			options);
	}
}

export default CordovaQrScanner;