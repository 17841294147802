'use strict';

import app from '../ngmodule';

import CordovaQrScanner from './cordova-qr-scanner';
import Html5QrScanner from './html5-qr-scanner';

app.directive('qrCodeScanner', ['$timeout', '$compile', 'vmRegisteredComponentProvider', function ($timeout, $compile, vmRegisteredComponentProvider) {
  const hasCordovaScanner = angular.isDefined(window.cordova) && angular.isDefined(cordova.plugins) && angular.isDefined(cordova.plugins.barcodeScanner);
  return {
    template: function (tElement, tAttrs) {
      if (tElement[0].children.length) {
        return tElement[0].children[0];
      } else {
        return require('./template.html').default;
      }
    },
    restrict: 'E',
    require: "ngModel",
    scope: {
      model: '=ngModel',
      onScan: '&',
      onError: '&',
      onResult: '&',
      onCancel: '&',
      options: '=?',
      context: '=?',
      title: '@'
    },

    link: function (scope, element, attrs) {
      scope.title = scope.title || 'Scan';

      const ngTimeout = function (fn) {
        return (...args) => {
          if (fn != null) {
            $timeout(() => {
              fn.apply(scope, args);
            })
          }
        }
      }

      scope.onScan = ngTimeout(scope.onScan);
      scope.onError = ngTimeout(scope.onError);
      scope.onResult = ngTimeout(scope.onResult);

      if (hasCordovaScanner) {
        CordovaQrScanner(scope, $timeout);
      } else {
        Html5QrScanner(scope, $timeout, $compile, vmRegisteredComponentProvider);
      }
    }
  }
}]);