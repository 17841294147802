import './auth';
import './blob-cache';
import './bworkflow-api';
import './cached-execution-handlers';
import './checklist-player';
import './language-translate';
import './ng-geolocation';
import './ngmodule';
import './offline-cache';
import './pending-payments-service';
import './question-models';
import './requests-error-handler';
import './site-map';
import './transition-cache';
import './vm-locale-service';
import './vm-registered-component-provider';
import './vm-face-recognition';
import './async-to-q';
import './vm-dashboard-service';
import './vm-xlsx';
import './vm-chain-service';
import './public-checklist-svc';
import './vm-pdf';
import './validator-service';
import './vm-component-utilities';
import "./devices";
import "./about-service";
import './vm-modal-manager-service';
import './vm-global-settings-service';
import './tenant-info-svc';
import './vm-mapping-service';
import './vm-comb-guid';
import './lro-service';