import ngmodule from '../../ngmodule';
import { ILingoAnswer } from './lingojs/lingo-answers-context';
import { IOfflineWorkingDocument } from '.';
import { IHandlesQuestionTypes } from 'lingo-api';
import { ICheckboxAnswer, IPresentedCheckboxQuestion, IDateTimeAnswer, IPresentedDateTimeQuestion, IFreeTextAnswer, IPresentedFreeTextQuestion, ILabelAnswer, IPresentedLabelQuestion, IMultiChoiceAnswer, IPresentedMultiChoiceQuestion, INumberAnswer, IPresentedNumberQuestion, IPresentedAnswer, IPresentedSection, ITemperatureProbeAnswer, IPresentedTemperatureProbeQuestion, IPresented } from './presented-types';
import { CheckboxAnswer } from './facilities/design-document-facility/checkbox-question';
import { DateTimeAnswer } from './facilities/design-document-facility/date-time-question';
import { FreeTextAnswer } from './facilities/design-document-facility/free-text-question';
import { LabelAnswer } from './facilities/design-document-facility/label-question';
import { MultiChoiceAnswer } from './facilities/design-document-facility/multi-choice-question';
import { NumberAnswer } from './facilities/design-document-facility/number-question';
import { SectionAnswer } from './facilities/design-document-facility/section';
import { TemperatureProbeAnswer } from './facilities/design-document-facility/temperature-probe-question';
import { IAnswerModel } from '../../bworkflow-api/types';

export class OfflineAnswerConverter implements IHandlesQuestionTypes<(answer: any, presented: any) => ILingoAnswer> {
	static $inject = [`$interpolate`, `$parse`];

	constructor(
		public workingDocument: IOfflineWorkingDocument) {

	}

	[`checkbox`](answer: ICheckboxAnswer, presented: IPresentedCheckboxQuestion): ILingoAnswer {
		return new CheckboxAnswer(answer, presented);
	}

	[`dateTime`](answer: IDateTimeAnswer, presented: IPresentedDateTimeQuestion): ILingoAnswer {
		return new DateTimeAnswer(answer, presented);
	}

	[`freeText`](answer: IFreeTextAnswer, presented: IPresentedFreeTextQuestion): ILingoAnswer {
		return new FreeTextAnswer(answer, presented);
	}

	[`label`](answer: ILabelAnswer, presented: IPresentedLabelQuestion): ILingoAnswer {
		return new LabelAnswer(answer, presented);
	}

	[`multiChoice`](answer: IMultiChoiceAnswer, presented: IPresentedMultiChoiceQuestion): ILingoAnswer {
		return new MultiChoiceAnswer(answer, presented);
	}

	[`number`](answer: INumberAnswer, presented: IPresentedNumberQuestion): ILingoAnswer {
		return new NumberAnswer(answer, presented);
	}

	[`section`](answer: IPresentedAnswer, presented: IPresentedSection): ILingoAnswer {
		return new SectionAnswer(answer, presented);
	}

	[`temperatureProbe`](answer: ITemperatureProbeAnswer, presented: IPresentedTemperatureProbeQuestion): ILingoAnswer {
		return new TemperatureProbeAnswer(answer, presented);
	}

	convertAnswerModel(answerModel: IAnswerModel): ILingoAnswer[] {
		let pqs = this.workingDocument.freezer.getPresentedQuestions();
		let converted = Object.keys(answerModel).map(presentedId => {
			let presentedQuestionForAnswer = pqs.find(pq => pq.Id === presentedId);
			let answer: ILingoAnswer | null = null;
			if (presentedQuestionForAnswer != null) {
				answer = this.convertPresented(answerModel[presentedId], presentedQuestionForAnswer);
			}
			return answer;
		});
		return converted.filter(a => a != null) as ILingoAnswer[];
	}

	convertPresented(answer: IPresentedAnswer, presented: IPresented): ILingoAnswer {
		let fn = this[presented.QuestionType];
		if (fn == null) {
			throw new Error(`Invalid question type ${presented.QuestionType}`);
		}

		return fn.bind(this)(answer as any, presented as any);
	}
}

export type CreateOfflineAnswerConverter = (workingDocument: IOfflineWorkingDocument) => OfflineAnswerConverter;

ngmodule.factory(`createOfflineAnswerConverter`, [
	function (
	): CreateOfflineAnswerConverter {
		return (workingDocument: IOfflineWorkingDocument) => {
			return new OfflineAnswerConverter(workingDocument);
		}
	}
]);