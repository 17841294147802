import { IPublishedResourceCompoundId } from "../../bworkflow-api/types";
import { IPersistantStorage } from '../../../common/persistant-storage';
import { IWebServiceUrl } from "../../../common/web-service-url";
import _ from "lodash";

import ngmodule from './ngmodule';
import { IOfflineChecklistCache, IOfflineChecklist, ChecklistModules } from "./types";
import { createOfflineChecklistFromResponse } from "./offline-checklist-player";
import { OfflineCache } from '../../offline-cache';
import angular from "angular";
import { OData } from "lingo-api";
import { ODataOfflineCache } from "../../odata-offline-cache";

export type OnUpdateCallback = (updated: IOfflineChecklist[], all: IOfflineChecklist[]) => Promise<void>;
export class OfflineChecklistCache implements IOfflineChecklistCache {
    private offlineCache: ODataOfflineCache<OData.Offline.OfflineChecklist>;

    private onUpdated: OnUpdateCallback[] = [];

    public onUpdate(callback: OnUpdateCallback): () => void {
        this.onUpdated.push(callback);
        return () => {
            let i = this.onUpdated.indexOf(callback);
            if (i >= 0) {
                this.onUpdated.splice(i, 1);
            }
        }
    }

    static $inject = [`webServiceUrl`];
    constructor(
    ) {
        this.offlineCache = new ODataOfflineCache<OData.Offline.OfflineChecklist>(`OfflineChecklists`, {
            uniqueId: r => r.id.toString()
        });
    }

    async checkForUpdates(): Promise<void> {
        return this.offlineCache.fetch().then(() => { });
    }

    mapRecord(o: OData.Offline.OfflineChecklist): IOfflineChecklist {
        let checklist = JSON.parse(o.data) as ChecklistModules;

        const lingoModule = checklist.modules.find(m => m.moduleType === 'lingo-js-source');
        const helpers = _.chain(checklist.modules)
            .filter(m => m.moduleType === 'lingo-js-helper')
            .map(m => ({
                name: m.name,
                code: m.outputs.find(o => o.type === 'js')?.content ?? '',
                sourceMap: m.outputs.find(o => o.type === 'js.map')?.content
            }))
            .filter(m => m.code !== '')
            .value();

        return {
            compoundId: {
                id: Number(o.id),
                groupId: Number(o.publishingGroupId),
                resourceId: Number(o.resourceId)
            },
            groupName: o.groupName ?? '',
            name: o.name,
            resourceNodeId: o.resourceNodeId ?? '',
            lingo: lingoModule != null ? {
                code: lingoModule?.outputs?.find(o => o.type === 'js')?.content ?? '',
                sourceMap: lingoModule?.outputs?.find(o => o.type === 'js.map')?.content ?? ''
            } : undefined,
            helpers: helpers,
            tracking: o.tracking ?? 0,
        };
    }

    async all(): Promise<IOfflineChecklist[]> {
        return await (await this.offlineCache.get()).map(this.mapRecord);
    }

    async getChecklist(compoundId: IPublishedResourceCompoundId): Promise<IOfflineChecklist | undefined> {
        let checklists = await this.all();

        return checklists.find(c => {
            if (c.compoundId.id && compoundId.id) {
                return c.compoundId.id === compoundId.id;
            } else {
                return c.compoundId.groupId === compoundId.groupId && c.compoundId.resourceId === compoundId.resourceId;
            }
        });
    }

    /*    async addChecklist(checklist: IOfflineChecklist): Promise<IOfflineChecklist> {
            let checklists = await this.all();
            await this.offlineCache.set(this.mergeChecklists(checklists, [checklist]));
            return checklist;
        }
    */
}

ngmodule.factory(`offlineChecklistCache`, OfflineChecklistCache);
