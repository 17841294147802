'use strict';

import app from '../ngmodule';
import angular from 'angular';
import { BaseAuthService, anonymousUser, unknownUser } from './base-auth-svc';
import { IPersistantStorage } from '../../../common/persistant-storage';
import { IUser, IAuthService, ITokenAuthService } from './index';
import { IWebServiceUrl } from "../../../common/web-service-url";
import * as oidc from 'oidc-client';
import * as router from '@uirouter/angularjs';
import ngModule from '../../../vmplayer/ngmodule';
import { AsyncToQ } from '../../async-to-q';
import { debugFunc, DebugFunc } from '../../../utils/debug-func';
import formUrlEncoded from 'form-urlencoded';
import { AppDevice } from '../../../vmplayer/services/app-device';

export interface User extends IUser {
	authenticationType: string;
	success: boolean
}

// this class adapts an IPersistantStorage to an Oidc store - allows Oidc to store its bits in IPersistantStorage
export class OidcStorageAdapter extends oidc.WebStorageStateStore {
	private _storage: IPersistantStorage;

	constructor(storage: IPersistantStorage) {
		super({});
		this._storage = storage;
	}

	set(key: string, value: any) {
		return new Promise<void>((resolve, reject) => {
			this._storage.setItem(key, value, resolve, reject);
		});
	}

	get(key: string) {
		return new Promise<void>((resolve, reject) => {
			return this._storage.getItem(key, resolve);
		});
	}

	remove(key: string) {
		return new Promise<boolean>((resolve, reject) => {
			return this._storage.removeItem(key, resolve);
		});
	}

	getAllKeys() {
		return new Promise<string[]>((resolve, reject) => {
			return this._storage.getKeys(resolve);
		});
	}
}

export abstract class OAuthServiceBase extends BaseAuthService implements ITokenAuthService {
	protected readonly storage: IPersistantStorage;
	protected _mgr: Promise<oidc.UserManager> | null = null;
	private isSilentRenewalStarted: boolean = false;

	private ticketToken: string | undefined;
	constructor(
		$q: ng.IQService,
		$http: ng.IHttpService,
		$rootScope: ng.IRootScopeService,
		webServiceUrl: IWebServiceUrl,
		protected $state: any,
		protected persistantStorage: IPersistantStorage,
		protected $urlService: router.UrlService,
		protected $location: ng.ILocationService,
		protected asyncToQ: AsyncToQ,
		log: DebugFunc
	) {
		super($q, $http, webServiceUrl, $rootScope, log);
		this.storage = persistantStorage.createNamespace('authSvc');

	}

	get mgr(): Promise<oidc.UserManager> {
		if (this._mgr == null) {
			this._mgr = this.createUserManager();
		}
		return this._mgr;
	}

	// Derived should call this when /signin-oidc is hit
	signinCallback(url: string): Promise<void> {
		delete this.ticketToken;
		return this.mgr.then(mgr => mgr.signinCallback(url)).then();
	}

	// Derived should call this when /signout-oidc is hit
	signoutCallback(url: string): Promise<void> {
		delete this.ticketToken;
		return this.mgr.then(mgr => mgr.signoutCallback(url)).then();
	}

	// Derived classes must implement these
	abstract createUserManager(): Promise<oidc.UserManager>;
	abstract userManagerSignout(): Promise<void>;
	abstract userManagerSignin(): Promise<void>;
	abstract displayOAuthError(display: string): Promise<void>;

	startStopSilentRenew(start: boolean) {
		if (start && !this.isSilentRenewalStarted) {
			this.log.info(`Starting silent renew`);
			this.mgr.then(mgr => mgr.startSilentRenew());
		} else if (!start && this.isSilentRenewalStarted) {
			this.log.info(`Stopping silent renew`);
			this.mgr.then(mgr => mgr.stopSilentRenew());
		}
		this.isSilentRenewalStarted = !!start;
	}

	getAccessToken(): ng.IPromise<string | undefined> {
		let defer = this.$q.defer<string | undefined>();
		if (this.ticketToken) {
			defer.resolve(this.ticketToken);
		} else {
			this.mgr.then(mgr => mgr.getUser()).then(async oidcUser => {
				if (oidcUser?.expired) {
					defer.resolve(undefined);
				} else {
					if (this.state === 'logged-out' && oidcUser?.access_token) {
						// This is required to prevent eternal looping since getUser requires access_token
						this.setState('logging-in');
						// We have either just logged in, or we have a cached access token that is valid (ie we are still logged in)
						await this.getUser().then(user => {
							this.setState('logged-in', user)
						});
					}
					if (this.state === 'logged-in' && oidcUser?.access_token) {
						this.startStopSilentRenew(true);
					}
				}
				defer.resolve(oidcUser?.access_token);
			});
		}
		return defer.promise;
	}

	override isAuthenticated(): ng.IPromise<boolean> {
		return this.getAccessToken().then(token => !!token);
	}

	// This will prompt for a login until a successful login is made, errors are retried indefinitely
	async retrySignInForever(): Promise<void> {
		return this.userManagerSignin().catch(err => {
			if (err?.message !== "closed") {
				return this.displayOAuthError(err).then(this.retrySignInForever.bind(this));
			} else {
				this.cancelLogin();
				throw err;
			}
		});
	}

	override _login(): ng.IDeferred<void> {
		delete this.ticketToken;

		let $login = this.$q.defer<void>();
		this.retrySignInForever().then(() => {
			// signinCallback has been called, we are logged in
			$login.resolve();
		}).catch(err => $login.reject(err));
		return $login;
	}

	loginTicket(ticket: string): ng.IPromise<IUser> {
		delete this.ticketToken;
		let params = {
			'username': `s3cur1tyT!cket`,
			'password': ticket,
			'grant_type': `password`,
			'client_id': `vm.spa.public`,
			'scope': `vm.api vm.odata`,
			'client_secret': `Sample secret`
		};
		return this.$http.post<{ access_token: string }>(`${this.webServiceUrl.tenantUrl}/auth/connect/token`,
			formUrlEncoded(params),
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(response => {
				if (response.status == 200) {
					this.ticketToken = response.data.access_token;
					return {
						authenticated: true,
						anonymous: true,
						unknown: false,

						username: 'Anonymous',
						userId: '',
						email: '',
						roles: []
					}
				} else {
					throw response.statusText;
				}
			})
	}

	override _logout(): ng.IDeferred<void> {
		let $logout = this.$q.defer<void>();
		if (this.ticketToken) {
			delete this.ticketToken;
			$logout.resolve();
		} else {
			this.startStopSilentRenew(false);

			this.userManagerSignout().then(() => {
				// signoutCallback has been called, we are logged out
				$logout.resolve();
			})
		}
		return $logout;
	}
}

