'use strict';

import app from '../ngmodule';
import angular from 'angular';

app.factory('playerButtons', [function () {
  var subscribedCanFns = [];

  var svc = {
    nextText: 'Next',
    previousText: 'Back',
    canPrevious: false,
    canNext: false,
    areAjaxing: false,

    subscribeCan: function (fn, scope) {
      subscribedCanFns.push(fn);
      svc.update();
      var destroy = function () {
        var i = subscribedCanFns.indexOf(fn);
        if (i >= 0) {
          subscribedCanFns.splice(i, 1);
          svc.update();
        }
      };
      if (angular.isDefined(scope)) {
        scope.$on('$destroy', destroy);
      }
      return destroy;
    },

    update: function () {
      var can = {
        previous: true,
        next: true
      };


      angular.forEach(subscribedCanFns, function (fn) {
        fn(can);
      });

      svc.canPrevious = !!can.previous;
      svc.canNext = !!can.next;
    }
  };


  return svc;
}]);