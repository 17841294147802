import { IBasicPersistantStorage } from './types';

function timeout(fn: (...args: any[]) => void, delay?: number, ...args: any[]) {
    window.setTimeout(fn, delay, ...args);
}

function dataObject(data: any): any {
    if (data === undefined || data === null) {
        return data;
    }
    if (typeof data !== 'string') {
        return data;
    }
    try {
        return JSON.parse(data);
    } catch (err) {
        return data;
    }
}

export class LocalStorageBasedPersistantStorage implements IBasicPersistantStorage {
    private index: { [key: string]: any } = {};

    constructor(private namespace: string) {
        this.index = JSON.parse(localStorage.getItem(`${this.namespace}-index`) || '{}');
    }

    private saveIndex() {
        if (Object.keys(this.index).length === 0) {
            localStorage.removeItem(`${this.namespace}-index`);
        } else {
            localStorage.setItem(`${this.namespace}-index`, JSON.stringify(this.index));
        }
    }

    createNamespace(namespace: string): IBasicPersistantStorage {
        let fullnameSpace = this.namespace;
        if (fullnameSpace.length != 0) {
            fullnameSpace += ".";
        }
        fullnameSpace += namespace;
        return new LocalStorageBasedPersistantStorage(fullnameSpace);
    }

    getItem(key: string, success?: (data: any) => void): void {
        var data = dataObject(localStorage.getItem(`${this.namespace}.${key}`));
        // Update the index
        if (this.index[key] === undefined && data != null) {
            this.index[key] = true;
            this.saveIndex();
        }
        if (success) {
            timeout(success, 0, data);
        }
    }

    setItem(key: string, data: any, success?: () => void, failure?: (err: Error) => void) {
        try {
            localStorage.setItem(`${this.namespace}.${key}`, JSON.stringify(data));
            // Update the index
            if (this.index[key] === undefined) {
                this.index[key] = true;
                this.saveIndex();
            }
            if (success) {
                timeout(success, 0);
            }
        } catch (err) {
            if (failure) {
                failure(err);
            }
        }
    }

    removeItem(key: string, callback?: (removed: boolean) => void) {
        var exists = localStorage.getItem(`${this.namespace}.${key}`) != null;
        localStorage.removeItem(`${this.namespace}.${key}`);

        if (this.index[key] !== undefined) {
            delete this.index[key];
            this.saveIndex();
        }

        if (callback) {
            timeout(callback, 0, exists);
        }
    }

    clear(callback?: () => void) {
        // Use the index to remove all namespaced items
        for (let key in this.index) {
            localStorage.removeItem(`${this.namespace}.${key}`);
        }
        this.index = {};
        this.saveIndex();
        if (callback) {
            timeout(callback, 0);
        }
    }

    getKeys(callback?: (keys: string[]) => void) {
        var keys = Object.keys(this.index);
        if (callback) {
            callback(keys);
        }
    }
}
