'use strict';

import './index.scss';
import './interfaces';
import './vm-tree-view-level/index';
import app from '../ngmodule';
import { ControllerWithValidationBase } from '../control-with-validation-base';
import angular from 'angular';
import { IValidationService } from '../../services/validator-service';
import $ from "jquery";
import "kendo-ui-core";
import { ITreeViewNode } from './interfaces';
import { IVMComponentUtilities } from '../../services/vm-component-utilities';

class TreeViewController extends ControllerWithValidationBase {
    public items: any;
    public disabled: boolean;
    public selected: any;
    protected selectedNode?: ITreeViewNode;
    public displayField: string;
    public itemsField: string;
    public hasLines: boolean;
    public allowParentSelection: boolean;

    protected _items: Array<ITreeViewNode>;

    public onUpdate?: null | ((args: any) => void) = null;

    static $inject = ['$element', '$sce', 'vmValidationService', 'vmComponentUtilities'];
    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, validationService: IValidationService, protected vmComponentUtilities: IVMComponentUtilities) {
        super($element, $sce, validationService);

        this.disabled = false;
        this.displayField = '';
        this.itemsField = '';
        this._items = [];
        this.hasLines = false;
        this.allowParentSelection = false;
    }

    isArray(test: any): test is Array<any> {
        return (test as Array<any>).forEach !== undefined;
    }

    $onInit() {
        super.$onInit();

        if (this.hasLines == undefined) {
            this.hasLines = this.valueFromHtml("hasLines", false, this.$element);
        }

        if (this.allowParentSelection == undefined) {
            this.allowParentSelection = this.valueFromHtml("allowParentSelection", false, this.$element);
        }

        this.buildItemsModel();
    }

    // works its way down the items handed in and builds up an internal
    // model that makes things easier for other code to handle
    buildItemsModel(): void {
        if(this.items == null)
        {
            return;
        }
        
        let items: Array<any> = this.isArray(this.items) ? this.items : [this.items];

        this._items = new Array<ITreeViewNode>();

        for(var i of items)
        {
            let thread = this.buildItemsThread(i, 0);

            this._items.push(thread);
        }
    }

    buildItemsThread(item: any, level: number) : ITreeViewNode {
        let node: ITreeViewNode = {
            data: {
                item: item,
                treeView: this
            },
            prompt: this.$sce.trustAsHtml(this.vmComponentUtilities.getValueFromDotNotation(item, this.displayField)),
            isOpen: false,
            isSelected: false,
            level: level,
            items: [],
            selectedCallback: this.treeNodeSelectedCallBack,
        }

        if(!item[this.itemsField])
        {
            return node;
        }

        for(var i of item[this.itemsField])
        {
            let child = this.buildItemsThread(i, level + 1);
            node.items.push(child);
        }

        return node;
    }

    treeNodeSelectedCallBack($event: any, selected: ITreeViewNode) : void {
        $event.stopPropagation();

        if(selected.data.treeView.disabled)
        {
            return;
        }

        if(selected.data.treeView.allowParentSelection == false && selected.items.length > 0)
        {
            // we don't allow selection of nodes with children, so we ignore this selection event
            return;
        }

        if(selected.data.treeView.selectedNode)
        {
            selected.data.treeView.selectedNode.isSelected = false;
        }

        selected.data.treeView.selected = selected.data.item;
        selected.data.treeView.selectedNode = selected;
        selected.data.treeView.selectedNode.isSelected = true;

        selected.data.treeView.changed();
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        super.$onChanges(changes);

        if (changes.allowParentSelection && changes.allowParentSelection.currentValue) {
            this.allowParentSelection = changes.allowParentSelection.currentValue;
        }

        if (changes.hasLines && changes.hasLines.currentValue) {
            this.hasLines = changes.hasLines.currentValue;
        }

        if (changes.items && changes.items.currentValue) {
            this.items = changes.items.currentValue;

            this.buildItemsModel();
        }
    }

    changed(): void {
        this.validate(this.selected);

        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { value: this.selected, control: this } });
    }
}

app.component('vmTreeView', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after'
    },
    bindings: {
        items: '<',
        selected: '=',
        displayField: '@',
        itemsField: '@',
        allowParentSelection: '<',
        hasLines: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        validator: '=',        
        prompt: '<',
        disabled: '<',
        onUpdate: '&'
    },
    controller: TreeViewController
});