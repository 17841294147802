'use strict';

//import 'bootstrap-material-datetimepicker';
import './index.scss';

import app from '../ngmodule';
import angular from 'angular';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ControllerWithValidationBase } from '../control-with-validation-base';
import { IVMLocaleService, IVMDateTimeFormats } from '../../services/vm-locale-service';
import { IValidationService } from '../../services/validator-service';

declare var $: any; // give us a typescript variable to access jquery

class DateTimePickerController extends ControllerWithValidationBase {
    public id: string;
    private _value?: Date;

    // we are using a property getter/setter for value so that we can intercept and update the kendo date/time picker
    public set value(val: Date | undefined) {
        this._value = val;

        if(!this.kendoDatePicker)
        {
            return;
        }

        this.kendoDatePicker?.value(this.value);
    }

    public get value(): Date | undefined {
        if(this.isString(this._value))
        {
            let format = this.getFormat();
            this._value = moment(this._value, format).toDate();
        }

        return this._value;
    }

    public minDate?: Date;
    public maxDate?: Date;
    public showTime?: boolean;
    public showDate: boolean;
    public overrideFormat: IVMDateTimeFormats | string | null;
    public disabled: boolean;

    public formats: IVMDateTimeFormats;

    protected kendoDatePicker?: kendo.ui.DatePicker;

    public onUpdate?: null | ((args: any) => void) = null;

    static $inject = ['$element', '$sce', '$timeout', 'vmLocaleService', 'vmValidationService'];
    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, protected $timeout: ng.ITimeoutService, protected localeService: IVMLocaleService, validationService: IValidationService) {
        super($element, $sce, validationService);

        this.showDate = true;
        this.id = 'datetimepicker' + uuidv4();

        this.overrideFormat = null;
        this.formats = this.localeService.getDateTimeFormats();
        this.disabled = false;
    }

    $onInit() {
        super.$onInit();

        if (angular.isDefined(this.showTime) == false) {
            this.showTime = this.$element.attr('show-time') == 'true';
        }

        if (angular.isDefined(this.showDate) == false) {
            if (this.$element.attr('show-date') != null) {
                this.showDate = this.$element.attr('show-date') == 'true';
            }
            else {
                this.showDate = true;
            }
        }

        if (this.minDate == null) {
            this.minDate = new Date(1910, 1, 1);
        }

        if (this.maxDate == null) {
            this.maxDate = new Date(2130, 1, 1);
        }

        this.$timeout(() => {
            let format = this.getFormat();

            // ok, so kendo ui uses lowercase d for days and y for years, so we have to replace uppercase versions of these
            format = format.split("D").join("d").split("Y").join("y");

            let options: kendo.ui.DatePickerOptions = {
                label: {
                    content: this.prompt,
                    floating: true
                },
                value: this.value,
                min: this.minDate,
                max: this.maxDate,
                format: format,
                componentType: "modern",
                change: this.kendoControlhanged
            };

            let element = this.$element.find('input').kendoDatePicker(options);
            this.kendoDatePicker = element.data("kendoDatePicker");

            this.doEnableDisable();

            // this next bit is a bit hacky. The change event executes in the context of the kendo datetime picker. So we are going to store us against the jquery data object of the element, so the change method can call back into us
            element.data("vm-date-time-picker", this);
        });
    }

    protected doEnableDisable(): void {
        this.kendoDatePicker?.enable(!this.disabled);
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        super.$onChanges(changes);

        if (changes.disabled && changes.disabled.currentValue != undefined) {
            this.disabled = changes.disabled.currentValue;
            this.doEnableDisable();
        }
    }

    kendoControlhanged(e: any): void {
        e.sender.element.data('vm-date-time-picker').changedCallBack();
    }

    changedCallBack(): void {
        // we should only be called from the kendo ui changed event, which is outside of angular so get this onto the next angular dispatch
        this.$timeout(() => {
            this.value = this.kendoDatePicker?.value();

            this.changed();
        });
    }

    protected isString(test: any): boolean {
        if(!test)
        {
            return false;
        }
        
        return (test as string).substr !== undefined;
    }

    protected _getFormat(format: IVMDateTimeFormats): string {
        let result: string = this.formats.dateFormat;
        if (this.showDate == true && this.showTime == true) {
            result = this.formats.dateTimeFormat;
        }
        else if (this.showDate == false && this.showTime == true) {
            result = this.formats.timeFormat;
        }

        return result;
    }

    getFormat(): string {
        let result: string = this._getFormat(this.formats);

        // see if the above has been overriden
        if(this.overrideFormat != null)
        {
            if(this.isString(this.overrideFormat))
            {
                return this.overrideFormat as string;
            }

            result = this._getFormat(this.overrideFormat as IVMDateTimeFormats);
        }

        return result;
    }

    changed(): void {
        this.validate(this.value);

        if (this.onUpdate == null) {
            return;
        }

        this.onUpdate({ $event: { value: this.value, control: this } });
    }
}

app.component('vmDateTimePicker', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after'
    },       
    bindings: {
        value: '=',
        prompt: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        overrideFormat: '<',
        onUpdate: '&',
        minDate: '<',
        maxDate: '<',
        disabled: '<',
        validator: '=',
    },
    controller: DateTimePickerController
});