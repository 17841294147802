'use strict';

import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { StringNullableChain } from 'lodash';

export class StarRatingController extends ControllerBase {
    public prompt: string;
    public maxRating: number;
    public value: number;
    public size: string;
    public color: string;
    public disabled: boolean;

    protected rating: number;
    protected _size: string;
    protected _color: string;

    protected possibleRatings: Array<number>;

    static $inject = ['$element', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService) {
        super();

        this.prompt = '';
        this.maxRating = 5;
        this.value = 0;
        this.rating = 0;
        this.color = 'primary';
        this.size = '';
        this.disabled = false;

        this._color = this.color;
        this._size = this.size;

        this.possibleRatings = [];
    }

    protected valuesFromHtml() : void {
        if (this.prompt == undefined) {
            this.prompt = this.valueFromHtml("prompt", '', this.$element);
        }

        if (this.maxRating == undefined) {
            this.maxRating = this.valueFromHtml("max-rating", 5, this.$element);
        }

        if (this.color == undefined) {
            this.color = this.valueFromHtml("color", 'primary', this.$element);
        }
        this._color = 'txt-' + this.color;

        if (this.size == undefined) {
            this.size = this.valueFromHtml("size", '', this.$element);
        }
        if(this.size != '')
        {
            switch(this.size)
            {
                case 'xsmall':
                    this._size = 'k-icon-xs';
                    break;
                case 'small':
                    this._size = 'k-icon-sm';
                    break;
                case 'medium':
                    this._size = 'k-icon-md';
                    break;
                case 'large':
                    this._size = 'k-icon-lg';
                    break;
                case 'xlarge':
                    this._size = 'k-icon-xl';
                    break;
            }
        }

        for(var i = 1; i <= this.maxRating; i++)
        {
            this.possibleRatings.push(i);
        }
    }

    public preview(rating: number) : void {
        if(this.disabled)
        {
            return;
        }

        this.rating = rating;
    }

    public unpreview(rating: number) : void {
        if(this.disabled)
        {
            return;
        }

        this.rating = this.value;
    }

    public rate(rating: number) : void {
        if(this.disabled)
        {
            return;
        }

        this.value = rating;
    }

    $onInit(): void {
        this.valuesFromHtml();

        this.rating = this.value;
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if(changes.disabled && changes.disabled.currentValue != undefined) {
            this.disabled = changes.disabled.currentValue;
        }
    }

}

app.component('vmStarRating', {
    template: require('./template.html').default,
    bindings: {
        value: '=',
        prompt: '<',
        maxRating: '<',
        size: '<',
        color: '<',
        disabled: '<'
    },
    controller:  StarRatingController
});