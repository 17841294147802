import { Question, Answer } from "./question";
import { JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { ICheckboxQuestionDefinition, DesignCheckboxQuestion } from 'lingo-api';
import { ICheckboxAnswer, IPresentedCheckboxQuestion } from "../../presented-types";


export class CheckboxQuestion extends Question implements DesignCheckboxQuestion {
	defaultAnswer?: boolean;
	constructor() {
		super(`checkbox`);
	}
}

export class CheckboxAnswer extends Answer implements ILingoAnswer {
	constructor(public answer: ICheckboxAnswer, public presentedQuestion: IPresentedCheckboxQuestion) {
		super(answer, presentedQuestion)
	}

	get value(): boolean | null {
		return this.answer.Value;
	}
	set value(value: boolean | null) {
		this.answer.Value = value;
	}
}

JsonTreeTranslators.register({
	ctr: CheckboxQuestion,
	create: () => new CheckboxQuestion()
}, {
	ctr: CheckboxAnswer,
	flatten(o: CheckboxAnswer) {
		return {
			answer: o.answer,
			presentedQuestion: o.presentedQuestion
		}
	},
	fatten(o: any, fatten: Convert, store: Convert) {
		return store(new CheckboxAnswer(fatten(o.answer), fatten(o.presentedQuestion)));
	}
});