'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';
import moment from 'moment';
import * as _ from 'lodash';
import { generateCombGuid } from '../../utils/util';

import '../../utils/navigator-notification';
import './style.scss';

import './task-tile';
import './new-task-ui';
import './shortcut-tile';
import './start-finish-ui';
import './folder-ui';

questionsModule.constant('TaskListUserInterfaceType', {
  ClockInClockOut: 0,
  Desktop: 1,
  SaveFinish: 2,
  Ordering: 3,
  Hidden: 4
});

questionsModule.directive('questionTaskList', ['bworkflowApi',
  '$interval',
  '$sce',
  '$filter',
  '$timeout',
  '$q',
  'sharedScope',
  'appUpdateMonitor',
  'taskListService',
  'quickStartTaskService',
  'createNewTaskWorkflow',
  'navigator-notification',
  'persistantStorage',
  'safeBeaconService',
  'cookieTimerSvc',
  'languageTranslate',
  'TaskListUserInterfaceType',
  'webServiceUrl',
  '$rootScope',
  'vmRegisteredComponentProvider',
  'vmMediaService',
  function (
    bworkflowApi,
    $interval,
    $sce,
    $filter,
    $timeout,
    $q,
    sharedScope,
    appUpdateMonitor,
    taskListService,
    quickStartTaskService,
    createNewTaskWorkflow,
    navigatorNotification,
    persistantStorage,
    beaconSvc,
    cookieTimerSvc,
    languageTranslate,
    TaskListUserInterfaceType,
    webServiceUrl,
    $rootScope,
    vmRegisteredComponentProvider,
    vmMediaService
  ) {
    return $.extend({}, questionDirectiveBase, {
      template: require('./template.html').default,
      link: function (scope, elt, attrs) {
        scope.UserInterfaceType = TaskListUserInterfaceType;

        // Used to pass this scope to isolate scopes (such as clockin-clockout-ui) - nasty but quickest way to achieve at the moment
        scope.scope = scope;

        var cookieTimer = cookieTimerSvc();

        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

        // These are allocated once and we *modify* the array instead of reallocating a new one
        scope.chooseQSTBeaconSites = [];
        scope.chooseQSTQrCodeSites = [];
        scope.chooseQSTSites = null; // This points to either of the above, the UI displays this list

        function pushQSTSite(qstSites, qst) {
          if (!qstSites.find(function (q) {
            return q.site && qst.site && q.site.UserId == qst.site.UserId;
          })) {
            qstSites.push(qst);

            if (!scope.chooseQSTSites) {
              scope.chooseQSTSites = qstSites;
            }
          }
        }

        function clearQSTSite(qstSites, fn) {
          if (qstSites) {
            for (var i = qstSites.length - 1; i >= 0; i--) {
              if (fn(qstSites[i])) {
                qstSites.splice(i, 1);
              }
            }
            if (qstSites.length == 0 && scope.chooseQSTSites === qstSites) {
              delete scope.chooseQSTSites;
            }
          }
        }

        scope.showQSTSitesDialog = function (qstSites) {
          scope.chooseQSTSites = qstSites;
        };

        scope.hideQSTSitesDialog = function () {
          delete scope.chooseQSTSites;
        };

        function checkQSTBeaconExpiry(beacon) {
          var expireTime = moment.utc().subtract(10, 'seconds');
          if (!angular.isDefined(beacon.timestamp) || beacon.timestamp.isBefore(expireTime)) {
            clearQSTSite(scope.chooseQSTBeaconSites, function (qst) {
              return qst.beacon === beacon;
            });
          } else {
            cookieTimer.setTimer(beacon.beaconId, 10 * 1000, beacon, checkQSTBeaconExpiry);
          }
        }

        function updateQSTBeacons(beacons) {
          var compareTime = moment.utc().subtract(10, 'seconds');
          if (beacons) {
            angular.forEach(beacons, function (beacon) {
              if (beacon.range.id <= 1 && beacon.timestamp.isAfter(compareTime)) {// Near or Immediate, heard within last 10 seconds
                var qstBeacon = _qstSetup.beacons[beacon.beaconId];
                if (angular.isDefined(qstBeacon)) {
                  cookieTimer.setTimer(beacon.beaconId, 10 * 1000, beacon, checkQSTBeaconExpiry);

                  // The beacon will be either a Site and/or TaskType QST beacon ..
                  var site = qstBeacon.site;
                  var tasktype = qstBeacon.tasktype;

                  if (tasktype && site) {
                    pushQSTSite(scope.chooseQSTBeaconSites, {
                      beacon: beacon,
                      site: site,
                      tasktypes: [angular.extend({
                        siteid: site.UserId
                      },
                        tasktype)]
                    });

                  } else if (tasktype) {
                    pushQSTSite(scope.chooseQSTBeaconSites, {
                      beacon: beacon,
                      tasktypes: [angular.extend({}, tasktype)]
                    });

                  } else if (site) {
                    var siteTypeTaskTypes = _qstSetup.siteTypeTaskTypes[site.UserTypeId];
                    if (angular.isDefined(siteTypeTaskTypes)) {
                      var validTaskTypes = siteTypeTaskTypes.map(function (sttt) {
                        return angular.extend({
                          siteid: site.UserId
                        },
                          _qstSetup.taskTypes[sttt.TaskTypeId]);
                      });

                      pushQSTSite(scope.chooseQSTBeaconSites, {
                        beacon: beacon,
                        site: site,
                        tasktypes: validTaskTypes
                      });

                    }
                  }
                }
              }
            });
          }

          // Remove any expired beacons from the QST dialog ..
          clearQSTSite(scope.chooseQSTBeaconSites, function (qst) {
            return qst.beacon && qst.beacon.timestamp.isBefore(compareTime);
          });
        }

        var _qstSetup;
        quickStartTaskService.prepare().then(function (setup) {
          _qstSetup = setup;
          scope.enableQRCodeQST = Object.getOwnPropertyNames(setup.qrcodes).length > 0 && scope.presented.template.allowqrcodestart;
          scope.enableBeaconQST = Object.getOwnPropertyNames(setup.beacons).length > 0 && scope.presented.template.allowbeaconstart;

          if (scope.enableBeaconQST && !beaconSvc.notsupported) {
            scope.$on('$destroy', beaconSvc.startScanning(function (args) {
              updateQSTBeacons(args.alive);
            }, {
              forceScan: false,
              anyChange: true
            }));

          }
        });

        function createAndStartTaskTemplate(template) {
          createNewTaskWorkflow.createAndClockIn(template, scope.presented.userid, template.enforcesingleclockin, template.singleclockinmode).then(function (data) {
            scope.offline = false;
            scope.$emit('question-task-list.created-new-task', data, template.showui);
          }, function (error) {
            scope.offline = true;
          });
        }

        function extractQRCode(text) {
          if (!text) {
            return null;
          }
          var str = text.toString();
          try {
            var jobj = JSON.parse(str);
            if (angular.isObject(jobj)) {
              if (angular.isUndefined(jobj.type) || jobj.type != 'Site' || !jobj.value) {
                return null;
              }

              return jobj.value.toString();
            } else {
              // Not a JSON object, trust the text is valid 5 char QR Code (cant do a length check
              // as no gaurantee they are all 5 chars !!)
              return str;
            }
          } catch (e) {
            return str;
          }
        }

        scope.QRCodeScanned = function (text, preselectedTask) {
          var qrcode = extractQRCode(text);
          if (qrcode == null) {
            alert('Invalid QRCode');
            return;
          }

          var qrConfig = _qstSetup.qrcodes[qrcode];
          if (angular.isUndefined(qrConfig)) {
            alert('QRCode is not configured');
            return;
          }

          // This is where we decide what type of QR we have scanned and dictates what workflow will follow ..
          // #1. Site only QR -> Original QR workflow, find Task Types attached to the Site and start (allow user to choose if > 1) the selected Task Type
          // #2. TaskType only QR -> New QR workflow, start the scanned TaskType (will search Tasklist first or create a new Spot Task)
          // #3. TaskType + Site QR -> New QR workflow, start the scanned TaskType (will search Tasklist first for matching Site, or create a new Spot task at the scanned site)
          if (qrConfig.site && !qrConfig.tasktype) {
            // #1
            scope.siteQRCodeScanned(text, preselectedTask);
          } else if (qrConfig.site || qrConfig.tasktype) {
            // #2, #3
            if (preselectedTask) {
              if (preselectedTask.site && qrConfig.site && preselectedTask.site.id != qrConfig.site.UserId) {
                alert('This task is for ' + preselectedTask.site.name + ' not ' + qrConfig.site.Name);
                return;
              }
              if (qrConfig.tasktype && preselectedTask.id != qrConfig.tasktype.tasktypeid) {
                alert('This QRCode is for a task type of ' + qrConfig.tasktype.text);
                return;
              }
              scope.clockin(preselectedTask, true, scope.presented.template.qrcodesecondscanbehavior);
            } else {
              var task = scope.allTasks.find(function (t) {
                return (qrConfig.tasktype ? t.tasktypeid == qrConfig.tasktype.tasktypeid : true) && (qrConfig.site ? t.site && t.site.id == qrConfig.site.UserId : true);
              });
              if (task) {
                task = scope.tasks.find(function (t) {
                  return (qrConfig.tasktype ? t.tasktypeid == qrConfig.tasktype.tasktypeid : true) && (qrConfig.site ? t.site && t.site.id == qrConfig.site.UserId : true);
                });

                if (!task) {
                  alert(qrConfig.tasktype.text + ' cannot be started yet due to level restriction');
                  return;
                }

                if (scope.presented.template.qrcodesecondscanbehavior == 'None') {
                  return;
                }
                // Start the existing task instead of creating a Spot task
                var showUIForSecondScan = (task.status == 'active' && scope.presented.template.qrcodesecondscanbehavior == 'ShowUI');
                scope.showTask(task, scope.presented.template.showtaskuionqrcodescan || showUIForSecondScan).then(function (result) {
                  if (showUIForSecondScan) {
                    return;
                  }

                  if (result.action == 'gettask') {
                    scope.clockin(result.task, true, scope.presented.template.qrcodesecondscanbehavior);
                  }
                })
              } else {
                // This is a spot task ..

                // we look up the default roster (if any are defined) and use that to mimic how normal spot tasks work
                var tt = angular.copy(qrConfig.tasktype);
                if (scope.presented.template.taskcreationtemplates != null) {
                  for (let t of scope.presented.template.taskcreationtemplates) {
                    if (t.tasktypeid == tt.tasktypeid) {
                      tt.defaulttoroster = t.defaulttoroster;
                      break;
                    }
                  }
                }

                createAndStartTaskTemplate(angular.extend({
                  siteid: qrConfig.site ? qrConfig.site.UserId : null,
                  sitename: qrConfig.site ? qrConfig.site.Name : null,
                  showui: scope.presented.template.showtaskuionqrcodescan,
                  enforcesingleclockin: scope.presented.template.singleclockinmode == 'Automatic',
                  singleclockinmode: scope.presented.template.singleclockinmode
                }, tt));
              }
            }
          }
        };

        scope.siteQRCodeScanned = function (text, preselectedTask) {
          var qrcode = extractQRCode(text);
          if (qrcode == null) {
            alert('Invalid QRCode');
            return;
          }

          var qrConfig = _qstSetup.qrcodes[qrcode];
          if (angular.isUndefined(qrConfig) || !qrConfig.site) {
            alert('QRCode is not configured for site');
            return;
          }
          var site = qrConfig.site;
          if (preselectedTask && preselectedTask.site && preselectedTask.site.id != site.UserId) {
            alert('This task is for ' + preselectedTask.site.name + ' not ' + site.Name);
            return;
          }

          var siteTypeTaskTypes = _qstSetup.siteTypeTaskTypes[site.UserTypeId];
          if (angular.isUndefined(siteTypeTaskTypes)) {
            alert(site.Name + ' has no assigned Task Types');
            return;
          }

          var validTaskTypes = siteTypeTaskTypes.map(function (sttt) {
            return angular.extend({
              siteid: site.UserId
            },
              _qstSetup.taskTypes[sttt.TaskTypeId]);
          });

          if (preselectedTask) {
            var template = validTaskTypes.find(function (tt) {
              return tt.tasktypeid == preselectedTask.tasktypeid;
            });

            if (!template) {
              alert(preselectedTask.name + ' is not valid at ' + site.Name);
            } else {
              scope.clockin(preselectedTask);
            }
          } else if (validTaskTypes.length > 1) {
            scope.chooseQSTQrCodeSites.splice(0, scope.chooseQSTQrCodeSites.length);
            pushQSTSite(scope.chooseQSTQrCodeSites, {
              qrcode: qrcode,
              site: site,
              tasktypes: validTaskTypes
            });


          } else if (validTaskTypes.length == 1) {
            // Auto start the 1 and only task
            scope.startQSTTask(site, validTaskTypes[0]);
          }
        };

        scope.finishTaskQRCode = function (text, preselectedTask) {
          var qrcode = extractQRCode(text);
          if (qrcode == null) {
            alert('Invalid QRCode');
            return;
          }

          var qrConfig = _qstSetup.qrcodes[qrcode];
          if (angular.isUndefined(qrConfig)) {
            alert('QRCode is not configured');
            return;
          }

          if (preselectedTask) {
            if (qrConfig.site && preselectedTask.site && preselectedTask.site.id != qrConfig.site.UserId) {
              alert('This task is for ' + preselectedTask.site.name + ' not ' + qrConfig.site.Name);
              return;
            }
            if (qrConfig.tasktype && preselectedTask.tasktypeid != qrConfig.tasktype.tasktypeid) {
              alert('This QRCode is for ' + qrConfig.tasktype.text + ' not ' + preselectedTask.name);
              return;
            }

            scope.finishing(preselectedTask);
          }
        };

        scope.startQSTTask = function (site, template) {
          scope.hideQSTSitesDialog();

          // See if we can find the Task in the All Task List first ..
          var findFn;
          if (angular.isDefined(site)) {
            findFn = function (t) {
              return t.tasktypeid == template.tasktypeid && (t.site && t.site.id == site.UserId);
            };
          } else {
            findFn = function (t) {
              return t.tasktypeid == template.tasktypeid && !t.site;
            };
          }

          var task = scope.allTasks.find(findFn);
          if (task) {
            task = scope.tasks.find(findFn);

            if (!task) {
              alert(template.text + ' cannot be started yet due to level restriction');
              return;
            }

            // Start the existing task instead of creating a Spot task
            scope.showTask(task, scope.presented.template.showtaskuionqrcodescan).then(function (result) {
              if (result.action == 'gettask') {
                scope.clockin(result.task);
              }
            })
            return;
          }

          createAndStartTaskTemplate(angular.extend({}, template, {
            showui: scope.presented.template.showtaskuionqrcodescan, enforcesingleclockin: scope.presented.template.singleclockinmode == 'Automatic', singleclockinmode: scope.presented.template.singleclockinmode
          }));
        }

        scope.mediaurl = bworkflowApi.getfullurl('~/MediaImage');
        scope.showing = 'clockings';
        scope.canBack = false;
        scope.currentresource = null;

        // 1000 m in km (or 5280 feet to 1 mile)
        scope.longDistanceUnitsToSmallMultiplier = scope.presented.template.measurementtype == 'Metric' ? 1000 : 5280;
        scope.longDistanceUnits = scope.presented.template.measurementtype == 'Metric' ? 'KM' : 'MI';
        scope.shortDistanceUnits = scope.presented.template.measurementtype == 'Metric' ? 'M' : 'FT';

        scope.labelFilterAll = {
          name: 'Show <br/> All',
          id: null,
          listText: 'Show All'
        };

        scope.labels = [];
        scope.labelFilter = scope.labelFilterAll;
        scope.filteredTasks = [];
        scope.clockedIntoTasks = [];
        scope.notClockedIntoTasks = [];

        scope.activityoptions = [{
          value: null,
          text: 'To Do'
        },
        {
          value: true,
          text: 'Complete'
        },
        {
          value: false,
          text: 'Incomplete'
        }];


        scope.creatingTask = false;
        scope.creatingGroup = false;

        scope.currentFolder = null;
        scope.folderStack = [];

        scope.lastSiteClockinClockout = null;

        scope.refreshCountDown = 1;

        scope.url = webServiceUrl;

        scope.isClockedIn = false;
        scope.clockedInTo = null;

        scope.isFinalPage = false;
        bworkflowApi.setFacilityHierarchyTaskTypeModel(scope.presented.template.facilityhierarchyleveltasktyperules);

        scope.getExecutionQueueLength = function () {
          return bworkflowApi.executionCallQueue.length;
        };


        let waitingForConnection = function () {
          let showing = false;
          $.snackbar({
            id: 'question-tasklist-toast',
            content: 'Fetching tasks...',
            timeout: 0,
            onClose: () => {
              showing = false;
            }
          });
          return {
            show: () => {
              if (!showing) {
                showing = true;
                $('#question-tasklist-toast').show();
              }
            },
            hide: () => {
              $('#question-tasklist-toast').hide();
            },
            showing: () => showing
          };
        }();

        waitingForConnection.show();

        scope.isvisible = true;

        scope.inGotoChecklist = false;

        scope.getMaxLevel = function (tasks) {
          var maxLevel = 0;
          for (var i = 0; i < tasks.length; i++) {
            var t = tasks[i];

            if (t.level > maxLevel) {
              maxLevel = t.level;
            }
          }

          return maxLevel;
        };

        scope.filterByLevel = function (tasks, level) {
          var result = [];

          for (var i = 0; i < tasks.length; i++) {
            var t = tasks[i];

            if (t.status == 'active') {
              scope.isClockedIn = true;
              scope.clockedInTo = t;
            }

            if (t.level == level) {
              result.push(t);
            }
          }

          return result;
        };

        scope.getTasks = function (forceonline) {
          var parameters = {
            userid: scope.presented.userid,
            filterbylabels: scope.presented.filterbylabels,
            filterbystatusses: scope.presented.filterbystatusses,
            includeparentstasks: scope.presented.template.includeparentstasks,
            sorttype: scope.presented.template.sorttype,
            selectionquery: scope.presented.template.selectionquery            
          };

          // If force online then display a message whilst we retrieve the list
          if (forceonline) {
            waitingForConnection.show();
          }

          bworkflowApi.execute('TaskListManagement', 'GetTasks', parameters, undefined, forceonline).
            then(function (result) {
              scope.allTasks = result.data.opentasks;
              scope.tasks = scope.filterByLevel(result.data.opentasks, scope.getMaxLevel(result.data.opentasks));
              waitingForConnection.hide();

              // we need to work out the intersection of what we want to see with what we've been sent
              // this occurs because even though we filter tasks by the labels we want on the server,
              // tasks can have multiple labels attached, we may want to show these somewhere so we get the
              // data back for them, however for filtering we only want to filter on what's configured
              // for the task list
              var ls = [];
              angular.forEach(result.data.labels, function (value, key) {
                var ids = $filter('filter')(scope.presented.filterbylabels, value.id, true);

                if (ids.length > 0) {
                  ls.push(value);
                }
              });

              scope.labels = ls;

              if (result.added && result.added.length) {
                scope.tasksAdded = true;
              }

              if (result.removed && result.removed.length) {
                scope.tasksRemoved = true;
              }

              //	Lucifer-433 - at this point, check if there are rosters returned, and if not, 
              //	disable the AdHoc buttons that do not have a rosterId specified, and show the
              //	the message indicating that the user is not in a hierarchy
              if (result.data.rosters == null) {
                console.debug("Server has not returned the roster information for the user");
                scope.userrosters = null;
              } else {
                console.debug("Server has returned roster information for the user");
                scope.userrosters = result.data.rosters;
              }

              // _momentReceivedUtc will only exist when Online result
              if (result._momentReceivedUtc) {
                scope.validFromDate = moment(result._momentReceivedUtc);
                if (scope.presented.template.staleperiodseconds) {
                  scope.validToDate = moment(scope.validFromDate).add(scope.presented.template.staleperiodseconds, 'seconds');
                }
              }

              if (result.data.lastworklog != null) {
                console.debug("Server has provided a last clockin clockout, saving this for possible later use");
                scope.lastSiteClockinClockout = result.data.lastworklog;
              }

              scope.filterTasks();

              scope.calculateTaskDistances();

              if (scope.presented.template.sorttype == 'StartTime') {
                scope.calculateStartTimeSort();
              } else if (scope.presented.template.sorttype == 'SortOrder') {
                scope.calculateSortOrderSort();
              } else if (scope.presented.template.sorttype == 'TaskTypeName') {
				// Lucifer-601
				scope.calculateTaskTypeNameSort();
			  }


              // we support raising an event when the tasks have been loaded so that other things
              // on a dashboard can show information without having to hit the server again. Typical
              // use case is showing the total number of tasks somewhere. Doing the player_broadcast makes
              // this data available to anyone pointing to us through listento
              if (scope.presented.Name != null) {
                sharedScope.set(scope.presented.Name, {
                  tasks: scope.tasks,
                  type: 'tasksloaded'
                });


                scope.$emit('player_broadcast', {
                  name: scope.presented.Name,
                  data: {
                    tasks: scope.tasks,
                    type: 'tasksloaded'
                  }
                });


              }
            }, function (error) {

            });
        };


        scope.gotoFolder = function (folder) {
          var parameters = {
            userid: scope.presented.userid,
            taskid: scope.editing.id,
            mediafolderid: angular.isDefined(folder.folderid) == true ? folder.folderid : folder.id
          };


          $q.all({
            execute: bworkflowApi.execute('TaskListManagement', 'GetFolder', parameters, 5000)
          }).
            then(
              function (data) {
                scope.showing = 'folder';
                scope.currentFolder = data.execute;
                scope.folderStack.push(data.execute);

                folder.online = true;
              },
              function (reason) {
                folder.online = false;
              });

        };

        scope.gotoParentFolder = function () {
          if (scope.folderStack.length <= 1) {
            scope.closeCurrent();
          }

          var index = $.inArray(scope.folderStack[scope.folderStack.length - 1], scope.folderStack);

          if (index == -1) {
            return;
          }

          scope.folderStack.splice(index, 1);

          scope.currentFolder = scope.folderStack[scope.folderStack.length - 1];
        };

        scope.saveChanges = function (changes) {
          var parameters = {
            userid: scope.presented.userid,
            taskid: scope.editing.id,
            changes: changes
          };


          bworkflowApi.execute('TaskListManagement', 'SaveChanges', parameters).
            then(function (data) {
              angular.forEach(changes, function (value, key) {
                scope.original[key] = value;
              });

              // we now broadcast an event to let other task lists know
              // in case the task meets their filters so things need to be changed for them too
              scope.$emit('player_broadcast', {
                name: 'question-task-list.listitemchanged',
                data: {
                  task: angular.copy(scope.original),
                  source: scope.$id
                }
              });


            }, function (error) {

            });
        };

        scope.gotoChecklist = function (resource, event) {
          if (scope.inGotoChecklist == true) {
            // FIX for EVS-1576
            // we are already going to a checklist.
            return;
          }

          scope.inGotoChecklist = true;
          scope.currentresource = resource;

          var parameters = {
            userid: scope.presented.userid,
            taskid: scope.editing.id,
            publishinggroupresourceid: resource.publishedresourceid,
            playerType: 'spa'
          };


          $q.all({
            execute: bworkflowApi.execute('TaskListManagement', 'GotoChecklist', parameters, 10000)
          }).then(
            function (data) {
              if (data.execute.allow == true) {
                scope.showing = 'player';
                scope.isFinalPage = false;
                scope.workingdocumentid = data.execute.id;
                scope.buttonstates.areAjaxing = false;

                $timeout(() => {
                  let player = vmRegisteredComponentProvider.get('task-list-player', 'EmbeddedPlayer');
                  player.loadFromExisting(scope.workingdocumentid);
                });
              }

              scope.inGotoChecklist = false;
              scope.currentresource.online = true;
            },
            function (reason) {
              scope.inGotoChecklist = false;
              scope.currentresource.online = false;
            });
        };


        scope.buttonstates = {
          areAjaxing: false
        };


        // this event get's emitted by the section and allows us to commit the final page
        //  without having a UI so from a perceptions perspective it speeds things up
        scope.$on('section.finalpage', function () {
          scope.isFinalPage = true;
        });

        scope.next = function () {
          var args = {
            allowOffline: scope.isFinalPage,
            afterNextCallback: function () {
              // this only get's called if validation has passed
              if (scope.isFinalPage) {
                scope.embeddedPlayerFinished();
              }
            }
          };


          $rootScope.$broadcast('embedded-player.next', args);
        };

        scope.back = function () {
          $rootScope.$broadcast('embedded-player.back', null);
        };

        scope.$on('embedded-player.started', function (evt, model) {
          if (scope.resourceHasWorkingDocument(scope.currentresource, model.data.WorkingDocumentId) == false) {
            scope.currentresource.workingdocuments.push(model.data.WorkingDocumentId);
            scope.currentresource.workingdocumentcount = scope.currentresource.workingdocumentcount + 1;
          }
        });

        scope.$on('embedded-player.finished', function (evt, model) {
          model.show = false; // prevent the default finish step being shown
          scope.embeddedPlayerFinished(model);
        });

        scope.embeddedPlayerFinished = function (model) {
          if (scope.currentresource.ismandatory == true) {
            scope.currentresource.allowfinish = true;
          }

          scope.currentresource.completedworkingdocumentcount = scope.currentresource.completedworkingdocumentcount + 1;

          scope.calculateCanFinish(scope.editing);

          var task = scope.editing;

          var p = false;
          if (angular.isDefined(model) && angular.isDefined(model.data)) {
            p = model.data.Presented != null;
          }

          if (task.canfinish && task.tasktype.autofinishtaskonlastchecklist) {
            task.complete = true;

            // if it's not null, then that means the finish has occurred as a part of a finish with that has
            // presented something, so let the user see it
            scope.finish(task, '', true, undefined, p);
          }


          if (p == false) {
            scope.showing = 'clockings';
          }
        };

        scope.$on('embedded-player.finishing', function (evt, model) {
          if (scope.currentresource.preventfinishing == true) {
            model.show = false;

            if (scope.currentresource.ismandatory == true) {
              scope.currentresource.allowfinish = true;
            }

            scope.calculateCanFinish(scope.editing);

            var task = scope.editing;
            if (task.canfinish && task.tasktype.autofinishtaskonlastchecklist) {
              task.complete = true;
              scope.finish(task, '', true);
            }

            scope.showing = 'clockings';
          }
        });

        scope.$on('embedded-player.showstep', function (evt, model) {
          scope.canBack = model.CanPrevious;
        });

        scope.resourceHasWorkingDocument = function (resource, id) {
          var has = false;

          angular.forEach(resource.workingdocuments, function (value, index) {
            if (value == id) {
              has = true;
            }
          });

          return has;
        };

        scope.getLabelFilterName = function (filter) {
          return $sce.trustAsHtml(filter.name);
        };

        scope.getTaskDescription = function (task) {
          return $sce.trustAsHtml(task.description);
        };

        scope.showTask = function (task, showui) {
          var deferred = $q.defer();

          scope.showing = 'clockings';

          if (scope.creatingGroup == true) {
            task.selectedInGroup = !task.selectedInGroup;
            deferred.resolve({
              action: 'group',
              task: task
            });

          } else {

            task.loading = true;
            var parameters = {
              userid: scope.presented.userid,
              taskid: task.id
            };

            scope.original = task;

            bworkflowApi.execute('TaskListManagement', 'GetTask', parameters).
              then(function (data) {
                scope.showTaskDetails(data, false, showui);

                // with the intro of offline someone can go into finish incomplete
                // hit back to list, then hit on the task again in the task list
                // we need to resume where we were in this case, which means making
                // sure the incompletetasks list is populated with it
                if (data.status != 'finishing-incomplete') {
                  scope.incompleteTasks = null;
                } else {
                  scope.incompleteTasks = [data];
                }

                task.loading = false;

                deferred.resolve({
                  action: 'gettask',
                  task: data
                });

              }, function (error) {
                task.loading = false;
                deferred.reject(error);
              });
          }
          return deferred.promise;
        };

        scope.closeTaskOrChecklist = function () {
          if (scope.showing == 'player') {
            scope.showing = 'clockings';
          }
          else {
            let modal = vmRegisteredComponentProvider.get('TaskList-showTaskDialog', 'modal');
            modal.close();
          }
        }

        scope.showTaskDetails = function (data, refreshTaskList, showui) {
          scope.calculateCanFinish(data);

          if (angular.isDefined(showui) == false || showui == true) {
            scope.editing = data;

            if (scope.editing.photos.length > 0) {
              if (angular.isDefined(scope.editing.__loadedPhotos) == false) {
                scope.editing.__loadedPhotos = {
                  cache: {},
                  list: []
                };
              }

              angular.forEach(scope.editing.photos, function (p) {
                if (angular.isDefined(scope.editing.__loadedPhotos.cache[p.mediaid])) {
                  // already loaded it
                  return;
                }

                // ok we need to load the sucker
                let onServer = {
                  id: p.mediaid,
                  source: 1,
                  name: '',
                  options: {}
                };

                let onClient = vmMediaService.createOnClientFromOnServer([onServer])[0];

                scope.editing.__loadedPhotos.cache[p.mediaid] = onClient;
                scope.editing.__loadedPhotos.list.push(onClient);

                vmMediaService.loadClient(onClient);
              });
            }

            let modal = vmRegisteredComponentProvider.get('TaskList-showTaskDialog', 'modal');
            modal.show();
          }

          scope.creatingTask = false;

          if (angular.isDefined(refreshTaskList) == true && refreshTaskList == true) {
            scope.getTasks();
          }
        };

        scope.$on('question-task-list.created-new-task', function (event, task, showui) {
          // For newly created spot tasks, these have not yet been added to the task list, so make sure the task is actually listed
          var find = scope.tasks.find(function (t) {
            return t.id == task.id;
          });
          if (angular.isUndefined(find)) {
            scope.tasks.push(task);
          }

          // we don't have an original (item in the task list that's been clicked) in this scenario, so lets fake it                    
          scope.original = {};
          scope.original.status = task.status;

          scope.calculateCanFinish(task);

          scope.isClockedIn = true;
          scope.clockedInTo = task;

          if (task.tasktype.autofinishafterstart) {
            task.complete = true; // EVS-1385 - mark these tasks as Complete so they dont end up FinishedIncomplete
            scope.finish(task, '');

            var msg = 'Completed <b>' + task.name + '</b>';
            if (task.site && task.site.name) {
              msg += ' at <b>' + task.site.name + '</b>';
            }

            $.snackbar({
              htmlAllowed: true,
              content: msg,
              timeout: 3000
            }).show();

          } else {
            scope.showTaskDetails(task, true, showui);

            // EVS-632 Auto start 1st checklist on task start
            if (task.tasktype.autostart1stchecklist) {
              if (task.publishedresources && task.publishedresources.length) {
                scope.gotoChecklist(task.publishedresources[0]);
              }
            }
          }

          scope.filterTasks();
        });

        scope.$on('question-task-list.listitemchanged', function (event, data) {
          if (data.source == scope.$id || scope.presented.template.listen == false) {
            // we initiated the change event, so let's get out of here
            return;
          }

          scope.manageListItemChanged(data.task);
        });

        scope.$on('question-task-list.refreshTasks', function (event, args) {
          if (angular.isDefined(args.name)) {
            if (args.name != scope.presented.Name) {
              return;
            }
          }

          scope.getTasks(args.forceonline);
        });

        scope.$on('question-task-list.visiblechange', function (event, args) {
          if (angular.isDefined(args.name)) {
            if (args.name != scope.presented.Name) {
              return;
            }
          }

          scope.isvisible = args.visible;
        });

        scope.removeTask = function (task) {
          var index = scope.tasks.indexOf(task);
          if (index != -1) {
            scope.tasks.splice(index, 1);
            scope.filterTasks();
          }
        };

        scope.manageListItemChanged = function (task) {
          if (task == null) {
            return;
          }

          var ids = $filter('filter')(scope.tasks, task.id, true);
          var isOfInterest = scope.isTaskOfInterest(task);

          // so there are a few options here
          // 1. we have the task and its of interest - we do nothing
          // 2. we have the task and its not of interest - we remove it
          // 3. we don't have the taks and its not of interest - we do nothing
          // 4. we don't have the task and its of interest - we add it
          // so 2 and 4 mean we have to do something

          // 2 first
          if (ids.length > 0 && isOfInterest == false) {
            scope.removeTask(ids[0]);
          }

          // 4 now
          if (ids.length == 0 && isOfInterest == true) {
            scope.tasks.push(angular.copy(task));
            scope.filterTasks();
          }
        };

        scope.isTaskOfInterest = function (task) {
          if (scope.presented.filterbystatusses.length == 0) {
            // everything is of interest to us and we already have what we want
            return null;
          }

          // currently the only thing we filter in or out on is the task status
          var statuses = $filter('filter')(scope.presented.filterbystatusses, task.statusid, true);

          if (statuses.length == 0) {
            return false;
          }

          return true;
        };

        scope.showTaskById = function (id) {
          var t = null;

          angular.forEach(scope.tasks, function (value) {
            if (value.id == id) {
              t = value;
            }
          });

          if (t != null) {
            scope.showTask(t);
          }
        };

        scope.calculateCanFinish = function (task) {
          var canfinish = true;

          angular.forEach(task.publishedresources, function (value, index) {
            if (value.ismandatory == false) {
              return;
            }

            // if we are preventing finishing, then mandatory denotes it must be started
            if (value.allowfinish == false) {
              canfinish = value.allowfinish;
            }
          });

          if (canfinish == true && task.workinggroupid != null) {
            angular.forEach(task.workinggroupdata, function (value) {
              if (value.allowfinish == false) {
                canfinish = false;
              }
            });
          }

          task.photorequired = task.photosupport == 2 && task.photos.length == 0;
          task.canfinish = canfinish;
        };

        scope.showList = function () {
          scope.manageListItemChanged(scope.original);
          scope.original = null;
          scope.editing = null;

          scope.folderStack.length = 0;
        };

        scope.closeCurrent = function () {
          if (scope.showing == 'player') {
            // if the task is ended, then let's just go back to the task list as it will have been
            // ended by the checklist finishing.
            if (scope.editing.status == 'finished') {
              scope.showing = 'clockings';
              scope.closeCurrent();
              return;
            }
            // save what ever has been entered by the user on the current page
            $rootScope.$broadcast('embedded-player.save', null);
            scope.calculateCanFinish(scope.editing);
            scope.showing = 'clockings';
          } else if (scope.showing == 'folder') {
            scope.showing = 'clockings';
          } else if (scope.showing == 'clockings') {
            scope.showList();
          }
        };

        scope.setTaskStatus = function (task, status) {
          task.status = status;

          if (task.workinggroupid != null) {
            angular.forEach(scope.filteredTasks, function (t) {
              if (t.workinggroupid == task.workinggroupid) {
                t.status = status;
              }
            });
          }

          $rootScope.$broadcast('tasklist-task-status-changed', task);
        }

        scope.clockin = function (task, allowclockoutifactive, secondscanbehavior) {
          // the qr scan calls this function, if the user scans the one in the task list, which should be used for starting things, then
          // they likely actually want to clock out, so if the caller allows us to let's do that
          if (task.status == 'active') {
            if (angular.isDefined(allowclockoutifactive) && allowclockoutifactive == true) {
              scope.clockout(task, '', secondscanbehavior);
              return;
            }
            else {
              // they're already clocked into this task, so just get out
              return;
            }
          }


          var status = task.status;
          scope.setTaskStatus(task, 'active');

          scope.original.status = task.status;

          scope.calculateCanFinish(task);

          scope.toggleClockinClockout(task, null, status);

          scope.isClockedIn = true;
          scope.clockedInTo = task;

          // EVS-632 Auto start 1st checklist on task start
          if (task.tasktype.autostart1stchecklist) {
            if (task.publishedresources && task.publishedresources.length) {
              scope.gotoChecklist(task.publishedresources[0]);
            }
          }
          scope.filterTasks();

          if (task.tasktype.autofinishafterstart) {
            scope.finish(task, '');
          }
        };

        scope.cancelclockingout = function (task) {
          scope.setTaskStatus(task, 'active');
          scope.original.status = task.status;
          task.requiresnotes = false;
          scope.filterTasks();
        };

        scope.clockingout = function (task) {
          scope.setTaskStatus(task, 'pausing');
          scope.original.status = task.status;
          scope.filterTasks();
        };

        scope.pause = function (task, pausestate) {
          if (pausestate.requiresextranotes) {
            task.requiresnotes = true;
          } else {
            scope.clockout(task, pausestate.text);
          }

          return false;
        }

        scope.clockout = function (task, pausingNotes, secondscanbehavior) {
          if (angular.isDefined(secondscanbehavior) && secondscanbehavior == 'Finish') {
            task.complete = true;
            scope.finish(task, pausingNotes);
            return;
          }

          var status = task.status;
          scope.setTaskStatus(task, 'paused');
          scope.original.status = task.status;

          task.requiresnotes = false;

          scope.toggleClockinClockout(task, pausingNotes, status);

          scope.isClockedIn = false;
          scope.clockedInTo = null;
          scope.filterTasks();
        };

        scope.clearTaskNotifications = function (task) {
          persistantStorage.getItem('taskPromptTimes', function (taskPromptTimes) {
            taskPromptTimes = taskPromptTimes || Object.create(null);

            if (angular.isDefined(taskPromptTimes[task.id])) {
              delete taskPromptTimes[task.id];
              persistantStorage.setItem('taskPromptTimes', taskPromptTimes);
            }
          });
        };

        scope.toggleClockinClockout = function (task, notes, fallbacktostate) {
          if (task.status == 'active') {
            taskListService.setCurrentTask(task);
          } else if (task.status == 'paused' || task.status == 'finished') {
            taskListService.setCurrentTask(null);

            // fix for Lucifer-771
            task.__lastIntervalCalc = undefined;
          }

          if (task.site != null && task.site.latitude != null && task.site.longitude != null) {
            console.debug("site has geo coords, storing for possible later use");
            scope.lastSiteClockinClockout = {
              coords: {
                latitude: task.site.latitude,
                longitude: task.site.longitude
              }
            };


          } else {
            console.debug("site has no geo coords");
            scope.lastSiteClockinClockout = null;
          }

          var loc = bworkflowApi.currentLocation();

          loc = angular.copy(loc);

          var parameters = {
            userid: scope.presented.userid,
            taskid: task.id,
            enforcesingleclockin: scope.presented.template.singleclockinmode == 'Automatic',
            singleclockinmode: scope.presented.template.singleclockinmode,
            location: loc,
            notes: notes,
            activities: task.activities,
            attemptclaim: task.requiresclaiming
          };


          // No longer urgent or overdue ..
          scope.clearTaskNotifications(task);

          bworkflowApi.execute('TaskListManagement', 'ToggleClockinClockout', parameters).then(
            function () { },
            function (error) {
              // something went wrong, we need to fall back to something
              scope.setTaskStatus(task, fallbacktostate);
              scope.original.status = fallbacktostate;
              scope.filterTasks();
            });

          if (scope.presented.template.sorttype == 'LastClockin') {
            scope.calculateTaskDistances();
          }
        };

        scope.clockinAndOut = function (task, notes) {
          var loc = angular.copy(bworkflowApi.currentLocation());

          var parameters = {
            userid: scope.presented.userid,
            taskid: task.id,
            enforcesingleclockin: scope.presented.template.singleclockinmode == 'Automatic',
            singleclockinmode: scope.presented.template.singleclockinmode,
            location: loc,
            notes: notes
          };


          scope.setTaskStatus(task, 'paused');

          return bworkflowApi.execute('TaskListManagement', 'ClockinAndClockout', parameters).then(
            function () { },
            function (error) {
              // something went wrong, we need to fall back to something
              scope.setTaskStatus(task, fallbacktostate);
              scope.original.status = fallbacktostate;
              scope.filterTasks();
            });

          scope.filterTasks();
        };

        scope.getTaskWorklog = function (task) {
          var parameters = {
            userid: scope.presented.userid,
            taskid: task.id
          };


          bworkflowApi.execute('TaskListManagement', 'GetTaskWorklog', parameters).then(function (data) {
            task.worklog = data.log;
          }, function (error) { });
        };

        scope.finishing = function (task) {
          // we need to work out if they are finising the task complete or incomplete
          // if there aren't any activities on the task, then we have to ask the ESW
          // to tell us if its complete or incomplete. If there are activities we look
          // at their status to determine complete or incomplete.

          if (task.activities.length == 0) {
            // no activities, so we can't work it out on our own, so get the user to
            // let us know.

            scope.setTaskStatus(task, 'finishing');

            // if we are dealing with a group of tasks we need to set a few things up on the 
            // model
            if (task.workinggroupid != null) {
              task.complete = true;
              angular.forEach(task.workinggroupdata, function (t) {
                t.complete = true;
              });
            }

            scope.original.status = task.status;
            scope.incompleteTasks = null;
          } else {
            // activities, so we can inspect their status and do the relevant thing based
            // on their status.
            var acts = $filter('filter')(task.activities, {
              completed: false
            },
              true);

            if (acts.length > 0) {
              // there are incomplete activities
              scope.finishingIncomplete(task);
            } else {
              // all activities are marked as complete so just finish
              if (confirm('Are you sure you want to finish this task') == false) {
                return;
              }

              scope.setTaskStatus(task, 'finishing');
              scope.finish(task, '');
            }

          }
          scope.filterTasks();
        };

        scope.finishingIncomplete = function (task) {
          task.previousStatus = task.status;
          scope.setTaskStatus(task, 'finishing-incomplete');
          scope.original.status = task.status;

          scope.incompleteTasks = [];
          scope.incompleteTasks.push(task);
          scope.filterTasks();
        };

        scope.cancelfinishingincomplete = function (task) {
          scope.setTaskStatus(task, task.previousStatus);
          scope.original.status = task.status;

          scope.incompleteTasks = null;
          scope.filterTasks();
        };

        scope.findGroupFinishingDataMatch = function (taskid, items) {
          var result = null;

          angular.forEach(items, function (val) {
            if (val.id == taskid) {
              result = val;
            }
          });

          return result;
        };

        scope.finishingGroup = function (task) {
          var isFinishedComplete = task.complete;
          scope.incompleteTasks = [];

          if (task.complete == false) {
            scope.incompleteTasks.push(task);
          }

          angular.forEach(task.workinggroupdata, function (val) {
            if (val.complete == false) {
              isFinishedComplete = false;
              scope.incompleteTasks.push(val);
            }
          });

          // everything marked as finished, so no more to do here
          if (isFinishedComplete == true) {
            scope.finish(task, '');
            return;
          }

          scope.setTaskStatus(task, 'finishing-incomplete');
          scope.original.status = task.status;

          // ok the group side of things causes a little more work for us as
          // we need to get the finish statuses for the other tasks in the group.
          var parameters = {
            taskid: task.id,
            userid: scope.presented.userid
          };


          var current = task;

          bworkflowApi.execute('TaskListManagement', 'GetGroupFinishingDetails', parameters).then(function (data) {
            // merge the finishing statuses returned for each task with the workinggroupdata objects we already have
            angular.forEach(task.workinggroupdata, function (val) {
              var gt = scope.findGroupFinishingDataMatch(val.id, data.groupdata);

              if (gt == null) {
                return;
              }

              val.finishedstatuses = gt.finishedstatuses;
            });

          }, function (error) { });
          scope.filterTasks();
        };

        scope.finish = function (task, notes, clockout, overrideCanContinue, preventShowList) {
          if (angular.isDefined(overrideCanContinue) == false) {
            overrideCanContinue = false;
          }

          if (scope.incompleteTasks != null && overrideCanContinue == false) {
            var canContinue = true;

            angular.forEach(scope.incompleteTasks, function (task) {
              task.error = false;
              var taskCanContinue = false;

              angular.forEach(task.finishedstatuses, function (item) {
                if (item.selected == true) {
                  if (item.requiresextranotes == false || (item.requiresextranotes == true && item.notes != null && item.notes != '')) {
                    taskCanContinue = true;
                  }
                }
              });

              if (taskCanContinue == false) {
                task.error = true;
                canContinue = false;
              }
            });

            if (canContinue == false) {
              return false;
            }
          }

          var complete = task.complete;

          if (angular.isDefined(complete) == false) {
            complete = task.status == 'finishing';
          }

          var status = task.status;
          scope.setTaskStatus(task, 'finished');
          scope.filterTasks();
          scope.original.status = task.status;

          var loc = angular.copy(bworkflowApi.currentLocation());

          var parameters = {
            userid: scope.presented.userid,
            taskid: task.id,
            enforcesingleclockin: scope.presented.template.singleclockinmode == 'Automatic',
            singleclockinmode: scope.presented.template.singleclockinmode,
            location: loc,
            complete: complete,
            completionnotes: notes,
            finishedstatuses: task.finishedstatuses,
            workinggroupdata: null,
            clockout: angular.isDefined(clockout) ? clockout : false,
            activities: task.activities
          };


          if (task.workinggroupid != null) {
            parameters.workinggroupdata = task.workinggroupdata;
          }

          $q.all({
            finish: bworkflowApi.execute('TaskListManagement', 'Finish', parameters, 5000)
          }).
            then(
              function (httpData) {
                // EVS-453 - Trying to click into a task you've just finished throws a red error
                // Remove task from the client, if we wait for server response there is a window
                // for the user to click the task again when it has already disappeared from the 
                // offline cache and throw an undefined exception
                scope.removeTask(task);
                scope.isClockedIn = false;
                scope.clockedInTo = null;
                if (angular.isDefined(preventShowList) == false || preventShowList == false) {
                  scope.showList();
                }
                scope.getTasks();
              },
              function (httpData) {
                // well that didn't work, fall back to the last state.
                // We've seen bug reports where scope.original appears to be null
                // I haven't been able to replicate this locally, but think something
                // might going wrong after showList and things are somehow falling through
                // to this code (I can't see how). So lets just check to make sure the
                // state we think we are in is the state we are in.
                if (scope.original != null) {
                  scope.setTaskStatus(task, status);

                  if (angular.isDefined(task.finishErrorCount) == false) {
                    task.finishErrorCount = 0;
                  }
                  task.finishErrorCount = task.finishErrorCount + 1;

                  scope.original.status = status;
                }

                httpData.noUI = true;
                scope.$emit('player_broadcast_ajax_error', httpData);
                scope.filterTasks();
              });

        };

        scope.calculateStartTimeSort = function () {
          if (angular.isDefined(scope.tasks) == false) {
            return;
          }
          var future = moment().add(1, 'y').valueOf();

          angular.forEach(scope.tasks, function (task, index) {
            if (task.starttime == null) {
              task.sortNumber = future;
            } else {
              task.sortNumber = task.starttime.valueOf();
            }
          });
        };

        scope.calculateSortOrderSort = function () {
          if (angular.isDefined(scope.tasks) == false) {
            return;
          }

          angular.forEach(scope.tasks, function (task, index) {
            if (task.sortorder == null) {
              task.sortNumber = 100000;
            } else {
              task.sortNumber = task.sortorder;
            }
          });
        };

		scope.calculateTaskTypeNameSort = function () {
			// Lucifer-601
			if (angular.isDefined(scope.tasks) == false) {
				return;
			}

			angular.forEach(scope.tasks, function (task, index) {
				if (task.tasktypename == null) {
					task.sortNumber = "";
				}
				else {
					task.sortNumber = task.tasktypename;
				}
			});
		};


        scope.calculateTaskDistances = function () {
          if (angular.isDefined(scope.tasks) == false) {
            return;
          }

          var currentLocation = null;

          if (scope.presented.template.sorttype != 'LastClockin') {
            console.debug("Geocode sorting being used, retrieving current location");
            currentLocation = bworkflowApi.currentLocation();
          } else {
            if (scope.lastSiteClockinClockout == null) {
              console.debug("Last clockin clockout sorting being used, but no last site is available, falling back to geo code");
              currentLocation = bworkflowApi.currentLocation();
            } else {
              console.debug("Last clockin clockout sorting being used, using last location");
              currentLocation = scope.lastSiteClockinClockout;
            }
          }

          if (currentLocation == null || angular.isDefined(currentLocation.coords) == false || currentLocation.coords == null) {
            currentLocation = null;
          }

          $timeout(function () {
            angular.forEach(scope.tasks, function (task, index) {
              if (currentLocation == null) {
                task.distance = bworkflowApi.noResultDistance;
                return;
              }

              if (task.site == null) {
                task.distance = bworkflowApi.noResultDistance;
                return;
              }

              task.distance = bworkflowApi.distanceBetween(currentLocation.coords.latitude, currentLocation.coords.longitude, task.site.latitude, task.site.longitude);

              // we have the distance in km's, we might need to do more with this
              if (scope.presented.template.measurementtype == "Imperial") {
                // we have to convert to miles and feet
                task.distance = task.distance * 0.621371192;
              }

              if (scope.presented.template.sorttype != 'StartTime' 
			  	&& scope.presented.template.sorttype != 'SortOrder'
				  && scope.presented.template.sorttype != 'TaskTypeName') {
                task.sortNumber = task.distance;
              }
            });
          });
        };

        scope.filterByLabel = function (label) {
          scope.labelFilter = label;

          scope.filterTasks();
        };

        scope.filterTasks = function () {
          if (scope.labelFilter == scope.labelFilterAll) {
            scope.filteredTasks = scope.tasks.slice(0);
          } else {

            scope.filteredTasks.length = 0;

            angular.forEach(scope.tasks, function (task, index) {
              var labels = $filter('filter')(task.labels, {
                id: scope.labelFilter.id
              },
                true);

              if (labels.length > 0) {
                scope.filteredTasks.push(task);
              }
            });
          }

          scope.clockedIntoTasks = [];
          scope.notClockedIntoTasks = [];

          // ok, we are going to include any task that the user is clocked
          // into in the started list even if it's on another level, which
          // stops the case where the user is clocked in, then a higher level task comes
          // along and they can't clockin to the higher level one as they are already
          // clocked into one, but can't see the one they are clocked into due to the higher
          // level task taking the UI
          angular.forEach(scope.allTasks, function (task) {
            if (task.status == 'active') {
              scope.clockedIntoTasks.push(task);
            }
          });

          // and now we use the filtered tasks, which will have been filtered by level etc
          // to populate the rest
          angular.forEach(scope.filteredTasks, function (task) {
            if (task.status == 'active') {
              return; // it will have been added above
            }

            if (task.status == 'paused' || task.status == 'pausing') {
              scope.clockedIntoTasks.push(task);
            } else {
              scope.notClockedIntoTasks.push(task);
            }
          });
        };

        scope.showCreateTask = function (template, ev) {
          scope.creatingTask = true;
          scope.currentTemplate = angular.extend({}, template);

          let modal = vmRegisteredComponentProvider.get('TaskList-createTaskDialog', 'modal');
          modal.show();
        };

        scope.taskCreateClose = function (task) {
          if (task) {
            scope.creatingTask = false;
            scope.currentTemplate = null;
            scope.$emit('question-task-list.created-new-task', task);
          }
          else {
            scope.creatingTask = false;
            scope.currentTemplate = null;
          }

          let modal = vmRegisteredComponentProvider.get('TaskList-createTaskDialog', 'modal');
          modal.close();
        }

        scope.closeCreateTask = function () {
          scope.creatingTask = false;
          scope.currentTemplate = null;
        };

        scope.startRefreshCountDown = function () {
          if (angular.isDefined(scope.stop)) return;

          var lastFireTime;
          scope.stop = $interval(function () {
            var forceRefresh = false;
            if (angular.isDefined(scope.validToDate)) {
              forceRefresh = moment().isAfter(scope.validToDate);
            }

            // take lastClockinTime 
            // store the time each time the interval runs.
            // compare that to Now

            // everytime the interval fires grab the current time
            var _now = Date.now();


            var actives = $filter('filter')(scope.tasks, {
              status: 'active'
            },
              true);
            angular.forEach(actives, function (act) {
              if (act.minimumdurationleft == null || act.minimumdurationleft <= 0) {
                  return;
              }

              if (!act.__lastIntervalCalc) {
                  act.__lastIntervalCalc = new Date(_now - 1000);
              }

              act.minimumdurationleft = act.minimumdurationleft - Math.round((Math.abs(_now - act.__lastIntervalCalc) / 1000));

              act.__lastIntervalCalc = _now;
            });

            if (scope.editing != null && !forceRefresh) {
              // countdown while editing
              return;
            }

            if (scope.editing == null && !scope.creatingTask) {
              // We are displaying the Task List so can run the Tasks Added/Removed message timers
              if (scope.tasksAdded && !angular.isDefined(scope.tasksAddedExpiry)) {
                scope.tasksAddedExpiry = moment().add(scope.presented.template.refreshperiodseconds, 'seconds');
              }
              if (angular.isDefined(scope.tasksAddedExpiry)) {
                if (moment().isAfter(scope.tasksAddedExpiry)) {
                  scope.tasksAdded = false;
                  delete scope.tasksAddedExpiry;
                }
              }

              if (scope.tasksRemoved && !angular.isDefined(scope.tasksRemovedExpiry)) {
                scope.tasksRemovedExpiry = moment().add(scope.presented.template.refreshperiodseconds, 'seconds');
              }
              if (angular.isDefined(scope.tasksRemovedExpiry)) {
                if (moment().isAfter(scope.tasksRemovedExpiry)) {
                  scope.tasksRemoved = false;
                  delete scope.tasksRemovedExpiry;
                }
              }

              // we now check the late after stuff and update the flag on the task to manage that
              var now = moment();

              persistantStorage.getItem('taskPromptTimes', function (taskPromptTimes) {
                var urgentTasks = [];
                var overdueTasks = [];

                taskPromptTimes = taskPromptTimes || Object.create(null);
                angular.forEach(scope.tasks, function (task) {
                  var promptTimes = taskPromptTimes[task.id] || Object.create(null);
                  if (task.status == 'new' && task.notifyuser && angular.isUndefined(promptTimes.urgent)) {
                    promptTimes.urgent = new Date();
                    taskPromptTimes[task.id] = promptTimes;
                    urgentTasks.push(task);
                  }

                  if (task.lateafter) {
                    task.islate = now.isAfter(task.lateafter);

                    if (task.status == 'new' && task.islate && scope.presented.template.promptlatetasks && angular.isUndefined(promptTimes.overdue)) {
                      promptTimes.overdue = new Date();
                      taskPromptTimes[task.id] = promptTimes;
                      overdueTasks.push(task);
                    }
                  }
                });

                if (urgentTasks.length || overdueTasks.length) {
                  persistantStorage.setItem('taskPromptTimes', taskPromptTimes);
                  if (urgentTasks.length) {
                    var msg = urgentTasks.map('name').join('\n');
                    navigatorNotification.alert(msg, null, urgentTasks.length == 1 ? 'Urgent task' : 'Urgent tasks');
                  }
                  if (overdueTasks.length) {
                    var msg = overdueTasks.map('name').join('\n');
                    navigatorNotification.alert(msg, null, overdueTasks.length == 1 ? 'Overdue task' : 'Overdue tasks');
                  }
                }
              });
            }

            if (angular.isDefined(scope.firstGetTasks) == false) {
              scope.firstGetTasks = true;
            } else {
              scope.firstGetTasks = false;
            }

            var forceOnline = scope.firstGetTasks || forceRefresh;
            var shouldGetTasks = forceOnline;

            // Check if a Task refresh is required at all
            if (!shouldGetTasks && scope.presented.template.refreshperiodseconds) {
              scope.refreshCountDown = scope.refreshCountDown - 1;

              if (scope.refreshCountDown < 0) {
                scope.refreshCountDown = 0;
              }

              if (scope.refreshCountDown == 0) {
                shouldGetTasks = true;
              }
            }

            if (shouldGetTasks) {
              // reset the refresh countdown
              scope.refreshCountDown = scope.presented.template.refreshperiodseconds;
              if (forceOnline) {
                // we cannot be editing a task if forced online
                scope.editing = null;
                scope.creatingTask = false;

                delete scope.validToDate;
              }
              scope.getTasks(forceOnline);
            }
          }, 1000);

        };

        scope.$on('photo-manager.phototaken', function (event, args) {
          // args.data is an IMediaOnClient, created from the photo being taken
          var stringData = args.data.data.data; // so args.data is media on client, args.data.data is the media data, which has its own data property on it which is the actual string (yep lots of data)

          var param = {
            userid: scope.presented.userid,
            taskid: scope.editing.id,
            id: generateCombGuid(),
            text: scope.editing.name + ' photo.jpg',
            data: stringData
          };

          // set what the server representation of this will be
          args.data.mediaOnServer = {
            id: param.id
          };

          bworkflowApi.execute('TaskListManagement', 'SavePhoto', param).
            then(function (data) {
              scope.editing.photos.push({
                mediaid: param.id
              });

              scope.calculateCanFinish(scope.editing);
            }, function (error) { });
        });

        scope.$on('photo-manager.photoremoved', function (event, args) {
          // args.data is an IMediaOnClient, created from the photo being taken
          var param = {
            userid: scope.presented.userid,
            taskid: scope.editing.id,
            mediaid: args.data.mediaOnServer.id
          };

          bworkflowApi.execute('TaskListManagement', 'RemovePhoto', param).
            then(function (data) {
              var i = scope.editing.photos.findIndex(function (p) {
                return p.mediaid == param.mediaid;
              });
              if (i >= 0) {
                scope.editing.photos.splice(i, 1);
              }
              scope.calculateCanFinish(scope.editing);
            }, function (error) { });
        });

        scope.$on('question-task-list-task-type-editor.changed', function (event, args) {
          var param = {
            userid: scope.presented.userid,
            taskid: scope.editing.id,
            tasktypeid: scope.editing.subtasktypeid
          };


          bworkflowApi.execute('TaskListManagement', 'ChangeTaskType', param).
            then(function (data) { }, function (error) { });
        });

        scope.stopRefreshCountDown = function () {
          if (angular.isDefined(scope.stop)) {
            $interval.cancel(scope.stop);
            scope.stop = undefined;
          }
        };

        scope.cachedTaskListNotificationReceiver = {
          notify: function (data) {
            if (data.type == bworkflowApi.cachedTaskListNotificationType.taskRemoved) {
              var tasks = $filter('filter')(scope.tasks, data.data, true);

              if (tasks.length == 0) {
                return;
              }
              scope.removeTask(tasks[0]);
            }
          }
        }

        bworkflowApi.addCachedTaskListDataNotificationReceiver(scope.cachedTaskListNotificationReceiver);

        scope.$on('$destroy', function () {
          bworkflowApi.removeCachedTaskListDataNotificationReceiver(scope.cachedTaskListNotificationReceiver);
          // Make sure that the interval is destroyed too
          scope.stopRefreshCountDown();
        });

        // if its not already doing so, get it to do
        scope.positionWatch = {};
        scope.positionWatch = bworkflowApi.watchGPSLocation();

        // EVS-396 GeoSorting of the task list to be only once every 10sec
        // Use a combintation of SugarJS Delay and Throttle functions to give behaviour of
        // - Delay the GPS change by 10 second and then Throttle it by 10 second to make sure
        // 1. Do not recalculate more often than 10 seconds (eg)
        // 2. If during the delay another GPS occurs that it does not reset the 10second timer (like a plain Debounce would)
        var throttledCalculateTaskDistances = scope.calculateTaskDistances;
        if (scope.presented.template.sortthrottleseconds) {
          throttledCalculateTaskDistances = _.throttle(throttledCalculateTaskDistances, scope.presented.template.sortthrottleseconds * 1000);
        }

        scope.$watch('positionWatch.position.coords', function (newValue, oldValue) {
          if (scope.presented.template.sorttype == 'Geocode' || scope.presented.template.sorttype == 'LastClockin' && scope.lastSiteClockinClockout == null) {
            _.delay(throttledCalculateTaskDistances, scope.presented.template.sortthrottleseconds * 1000);
          }
        });

        bworkflowApi.supportOffline('TaskListManagement', scope.presented.template.supportoffline);

        scope.startRefreshCountDown();
      }
    });

  }]);