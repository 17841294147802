'use strict';

import './index.scss';
import './vm-card';

import app from '../ngmodule';
import { CardController } from './vm-card';
import { ControllerBase } from '../control-base';

export class CardListController extends ControllerBase {
    public cards: Array<CardController>;

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();

        this.cards = [];
    }

    addCard(tab: CardController): void {
        this.cards.push(tab);
    }

    focusOnCard(card: CardController): void {
        for (let c of this.cards) {
            if (c == card) {
                continue;
            }

            c.hide();
        }
    }

    focusOffCard(): void {
        for (let c of this.cards) {
            c.show();
        }
    }
}

app.component('vmCardList', {
    template: require('./template.html').default,
    transclude: true,
    controller: CardListController
});