'use strict';

import app from './ngmodule';
import { ltrim, rtrim } from '../utils/util';
import _ from 'lodash';

export interface ImageOptions {
  width?: number;
  height?: number;
  zoom?: number;
  format?: string;
  cache?: boolean;
  [key: string]: any;
}

export interface IWebServiceUrl {
  setTenantHostname(url: string): void;
  tenantUrl: string;

  api(path: string): string;
  odata(feed: string, version?: string): string;
  odataOffline(feed: string): string;
  auth(path: string): string;
  site(path: string): string;
  portal(path: string): string;
  mediaImage(mediaId: string, options: ImageOptions): string;
  mediaPreviewImage(mediaId: string, options: ImageOptions): string;
  membershipImage(mediaId: string, options?: ImageOptions): string;
  taskTypeImage(mediaId: string, options?: ImageOptions): string;
  menuImage(mediaId: string, options: ImageOptions): string;
  taskImage(mediaId: string, options: ImageOptions): string;
  toolBarImage(mediaId: string, options: ImageOptions): string;
  downloadFile(mediaId: string): string;
  customFile(url: string): string;
  mediaImagePrefix(): string;
  vmplayer(): string;
}

export class WebServiceUrl implements IWebServiceUrl {
  constructor(public tenantUrl: string) {
    this.setTenantHostname(tenantUrl);
  }

  private normalizeUrl(url: string): string {
    if (!url.startsWith('http')) {
      url = `https://${url}`;
    }
    if (url !== 'https://' && url.endsWith('/')) {
      url = url.substr(0, url.length - 1);
    }
    return url;
  }

  setTenantHostname(url: string): void {
    this.tenantUrl = this.normalizeUrl(url);
    console.log(`Using WebServiceUrl.TenantUrl = ${this.tenantUrl}`);
  }

  api(path: string): string {
    return `${this.tenantUrl}/api/${ltrim(path)}`;
  }

  auth(path: string) {
    return `${this.tenantUrl}/api/account/${ltrim(path)}`;
  }

  odata(feed: string, version?: string): string {
    return `${this.tenantUrl}/odata/odata-v${version || 3}/${ltrim(feed)}`;
  }

  odataOffline(feed: string): string {
    return `${this.tenantUrl}/odata-offline/${ltrim(feed)}`;
  }

  site(path: string): string {
    return `${this.tenantUrl}/spa/${ltrim(path)}`;
  }

  portal(path: string): string {
    return `${this.tenantUrl}/portal/${ltrim(path)}`;
  }

  makeImageUrl(endPoint: string, mediaId: string, options?: ImageOptions) {
    const opts = options || {};

    let o = _.pick(options, 'width', 'height', 'zoom', 'format', 'pageIndex');

    let query = Object.keys(o)
      .filter((v: string) => opts[v] !== undefined)
      .map(v => `${v}=${(options as any)[v]}`);

    if (opts.cache) {
      query.push('cache');
    }
    else {
      query.push('rnd=' + Date.now());
    }

    return `${this.tenantUrl}/media/${endPoint}/${mediaId}.png?${query.join('&')}`;
  }

  mediaImage(mediaId: string, options?: ImageOptions): string {
    return this.makeImageUrl('mediaimage', mediaId, options);
  }

  mediaPreviewImage(mediaId: string, options?: ImageOptions): string {
    return this.makeImageUrl('mediapreviewimage', mediaId, options);
  }

  membershipImage(mediaId: string, options?: ImageOptions): string {
    return this.makeImageUrl('membershipimage', mediaId, options);
  }

  taskTypeImage(mediaId: string, options?: ImageOptions): string {
    return this.makeImageUrl('tasktypeimage', mediaId, options);
  }

  downloadFile(mediaId: string): string {
    return `${this.tenantUrl}/media/download/${mediaId}`;
  }

  customFile(url: string): string {
    return url;
  }

  menuImage(mediaId: string, options?: ImageOptions) {
    return this.mediaImage(mediaId,
      Object.assign({
        height: 30,
        format: 'png'
      }, options));
  }

  taskImage(mediaId: string, options?: ImageOptions) {
    return this.mediaImage(mediaId,
      Object.assign({
        height: 75,
        format: 'png'
      }, options));
  }

  toolBarImage(mediaId: string, options?: ImageOptions) {
    return this.mediaImage(mediaId,
      Object.assign({
        height: 40,
        format: 'png'
      }, options));
  }

  mediaImagePrefix(): string {
    return `${this.tenantUrl}/portal/mediaimage`;
  }

  vmplayer(): string {
    return `${this.tenantUrl}/vmplayer`;
  }
}

app.factory('webServiceUrl', ['$window', function ($window: ng.IWindowService) {
  return new WebServiceUrl($window.razordata.tenantUrl);
}]);
