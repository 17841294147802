'use strict';

import './index.scss';

import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { StringNullableChain } from 'lodash';
import { IVMMapCords, IVMMapMarker, IVMMappingService } from '../../services/vm-mapping-service';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { IVMModalListener, IVMModalManagerService, VMModalListenerOperation, IVMModal } from '../../services/vm-modal-manager-service';
import Prism from 'prismjs';
import 'prismjs/themes/prism-coy.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/line-highlight/prism-line-highlight';
import 'prismjs/plugins/line-highlight/prism-line-highlight.css';

import './prism-lingo-debugger.css';
import './prism-lingo-debugger';
import './prism-lingo-definition';

export class SyntaxHighlightingController extends ControllerBase implements IVMRegisteredComponent {
    public name: string;
    public sourceCode: string;
    public currentLine: number | null;
    public striking: boolean | undefined;

    protected hasResized: boolean;

    static $inject = ['$element', '$timeout', '$scope', 'vmRegisteredComponentProvider'];
    constructor(protected $element: ng.IRootElementService,
        protected $timeout: ng.ITimeoutService,
        protected $scope: ng.IScope,
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider) {
        super();

        this.name = '';
        this.sourceCode = '';
        this.currentLine = null;
        this.hasResized = false;
    }

    getComponentType(): string {
        return 'Syntax-Highlighting';
    }

    $onInit(): void {
        if (angular.isDefined(this.name) != false || this.name != '') {
            this.vmRegisteredComponentProvider.register(this);
        }

        // hook into the message from the tab control that get's sent when there is a tab selection, this can only
        // be intercepted by a control that's contained in the tab
        this.$scope.$on('vm-tab-pane-control.show', (evt, args) => {
            if (this.hasResized) {
                return;
            }

            this.hasResized = true;

            this.$timeout(() => {
                Prism.highlightAllUnder(this.$element.find("pre")[0]); // the line highlight plugin can't measure the size of the elements if its not been shown, so re render giving it a chance to
            });
        });
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        let changed: boolean = false;

        if (changes.sourceCode && changes.sourceCode.currentValue != undefined) {
            this.sourceCode = changes.sourceCode.currentValue;
            changed = true;
        }

        if (changes.currentLine && changes.currentLine.currentValue != undefined) {
            this.currentLine = changes.currentLine.currentValue;
            changed = true;
        }

        if (changed) {
            this.$timeout(() => {
                Prism.highlightAllUnder(this.$element.find("pre")[0]);
            });
        }
    }
}

app.component('vmSyntaxHighlighting', {
    template: require('./template.html').default,
    bindings: {
        name: '@',
        sourceCode: '<',
        currentLine: '<',
        striking: '<'
    },
    controller: SyntaxHighlightingController
});