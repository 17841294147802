'use strict';

import app from '../../ngmodule';

app.directive('questionTasklistStartfinishUi', ['$timeout', 'appUpdateMonitor', 'vmRegisteredComponentProvider',
    function ($timeout, appUpdateMonitor, vmRegisteredComponentProvider) {
        return {
            template: require('./template.html').default,
            restrict: 'E',
            link: function (scope, element, attrs) {
                // ok so this is basically horrible, but there is a bit of work in breaking out what the tasklist directive
                // does into a service and then having things like finishing a task be done in that. At the moment the code
                // is designed around the scope in this directive being the same object as the task list (I know a complete
                // corruption of containing concerns, but its the way it was originally programmed). Without that break out of
                // functionality we need to reference the task list scope so can't have our own so we have no way of raising events
                // etc and signaling we're done nicely, so we're hacking in this dependency to the name of the modal we are in
                // and can then use that to close things.
                const modal = vmRegisteredComponentProvider.get('TaskList-showTaskDialog', 'modal');

                appUpdateMonitor.addPauser(scope);      // Pause updates whilst our scope is alive

                scope.doFinish = function () {
                    scope.editing.complete = true;

                    scope.finish(scope.editing, '', true);
                };

                // Hack selected scope.parent functions into our scope ..
                function callThenCloseDialog(args) {
                    const originalFn = scope[args]
                    scope[args] = function () {
                        var canClose = originalFn.apply(scope.$parent, arguments);

                        if (canClose == false) {
                            return;
                        }

                        modal.close();
                    }
                }

                // These functions will close the dialog
                [
                    'pause',
                    'finish',
                    'QRCodeScanned'].
                    forEach(callThenCloseDialog);
            }
        };
    }
]);