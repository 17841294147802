import app from "../ngmodule";

const log = debugFunc(`temperature-probe-manager`);

app.factory('temperature-calibration-data', [function () {
	// CalibrationData stores the formulae data used to calculate temperature adjustment from raw reading
	function CalibrationData() {
		this.slope = 1.0;
		this.constant = 0.0;
		this.reading0 = 0.0;
		this.reading100 = 100.0;
	};

	CalibrationData.prototype.getCalibration = function () {
		return {
			reading0: this.reading0,
			reading100: this.reading100,
			lastCalibratedOnUtc: this.lastCalibratedOnUtc
		};
	};

	CalibrationData.prototype.setCalibration = function (data) {
		// formulae is
		// adj_temp = temp * slope + constant
		//
		// if reading100 is undefined
		//   slope = 1
		// else
		//   slope = 100 / (reading100 - reading0) 
		//
		// constant = -reading0 * slope
		//
		// eg reading0 = 1 
		//    slope = 1, constant = -1
		//    adjust(1) -> 1 + -1 -> 0
		//    
		// eg reading0 = 1, reading100 = 90
		//    slope = 100 / 89, constant = -100/89
		//    adjust(1) -> (1 * 100 / 89) + (-100/89) -> 0
		//    adjust(90) -> (90 * 100 / 89) + (-100/89) -> 100
		if (angular.isDefined(data) && (angular.isDefined(data.reading0) || angular.isDefined(data.reading100))) {
			this.reading0 = data.reading0;
			this.reading100 = data.reading100;

			// Keep slope at exactly 1 if only one reading is defined ..
			if (angular.isDefined(this.reading0) && !angular.isDefined(this.reading100)) {
				this.reading100 = 100 + this.reading0;
			} else if (angular.isDefined(this.reading100) && !angular.isDefined(this.reading0)) {
				this.reading0 = this.reading100 - 100;
			}
		} else {
			this.reading0 = 0;
			this.reading100 = 100;
		}

		this.slope = 100.0 / (this.reading100 - this.reading0);
		this.constant = -this.reading0 * this.slope;

		this.lastCalibratedOnUtc = data && data.lastCalibratedOnUtc;
		this.calibrationStatus = moment().subtract(7, 'days').isAfter(this.lastCalibratedOnUtc) ? 1 : 2;
	};

	CalibrationData.prototype.adjust = function (rawTemp) {
		return (rawTemp * this.slope) + this.constant;
	};

	// Generate formulae data from raw readings
	CalibrationData.prototype.calibrate = function (reading0, reading100) {
		this.setCalibration({ reading0: reading0, reading100: reading100, lastCalibratedOnUtc: moment.utc().toDate() });
	}

	return CalibrationData;
}])

	.factory('temperature-calibration-point', [function () {
		// CalibrationPoint represents one of the calibration points
		function CalibrationPoint(probe, sequence, text, temperature) {
			this.probe = probe;
			this.sequence = sequence;
			this.text = text;
			this.temperature = temperature;
			this.calibrated = false;
		}

		CalibrationPoint.prototype.statesEnum = {
			0: 'Not started',
			1: 'Ice bath test',
			2: 'Boiling water test',
			4: 'Finished',
			named: {
				NotStarted: 0,
				IceBathTest: 1,
				BoilingWaterTest: 2,
				Finished: 3
			}
		};

		CalibrationPoint.prototype.calibrate = function () {
			if (!this.calibrated) {
				this.calibrated_temperature = this.probe.readings.temperature.rawValue;
				this.calibrated = true;
				this.sequence.calibrated.push(this);
				this.sequence.uncalibrated.remove(this);
				this.sequence.next();
			}
		}

		return CalibrationPoint;
	}])

	.factory('temperature-calibration-sequence', ['temperature-calibration-point', function (CalibrationPoint) {

		// CalibrationSequence controls the flow of calibration
		function CalibrationSequence(probe) {
			this.probe = probe;
			this.IceBathTest = new CalibrationPoint(probe, this, 'Ice bath', 0);
			this.BoilingWaterTest = new CalibrationPoint(probe, this, 'Boiling Water', 100);
			this.state = 0;
			this.canStart = true;
			this.canStop = false;
		}

		CalibrationSequence.prototype.start = function () {
			this.state = 1;
			this.canStart = false;
			this.canStop = false;
			this.uncalibrated = [this.IceBathTest, this.BoilingWaterTest];
			this.calibrated = [];
		}

		CalibrationSequence.prototype.next = function () {
			if (this.calibrated.length > 0) {
				this.canStop = true;
			}
			this.state++;
		}

		CalibrationSequence.prototype.stop = function () {
			this.canStop = false;
			this.state = 3;
			if (this.calibrated.length > 0) {
				this.probe.calibrationData.calibrate(this.IceBathTest.calibrated_temperature, this.BoilingWaterTest.calibrated_temperature);

				return this.probe.calibrationData.getCalibration();
			}
			return angular.undefined();
		}

		CalibrationSequence.prototype.statesEnum = CalibrationPoint.prototype.statesEnum;

		return CalibrationSequence;
	}])

	.factory('temperature-probe-manager', ['$q', 'ConnectableDevice', 'ngTimeout', '$timeout', 'temperature-calibration-sequence', 'temperature-calibration-data', function ($q, ConnectableDevice, ngTimeout, $timeout, CalibrationSequence, CalibrationData) {
		function Probe(manager, id, name, driver) {
			var self = this;
			ConnectableDevice(this, id, name, driver, log);
			this.manager = manager;
			this.driver = driver;

			this.isConnectable = angular.isDefined(driver.connect);

			// If the driver does not support Connect, then we simulate it being connected permanently
			if (!this.isConnectable) {
				this.connected();
			}

			this.buttonCallbacks = [];
			this.readings = Object.create(null);
			this.readings.temperature = Object.create(null);;
			this.battery = Object.create(null);
			this.canCalibrate = angular.isDefined(driver.canCalibrate) ? !!driver.canCalibrate : true;      // default to true
			if (this.canCalibrate) {
				this.calibrationData = new CalibrationData();
			}

			var connected = this.connected;
			this.connected = function () {
				connected();
				if (!manager.probe) {
					manager.activeProbe(self);
				}
			}
			var disconnected = this.disconnected;
			this.disconnected = function () {
				disconnected();
				if (manager.probe === self) {
					manager.activeProbe(null);
				}
			}
		}

		Probe.prototype.getSerialNumber = function () {
			var self = this;
			return this.driver.getSerialNumber().then(function (serialNumber) {
				self.serialNumber = serialNumber;
				return serialNumber;
			});
		}

		Probe.prototype.canIdentify = function () {
			return angular.isFunction(this.driver.identify) && this.isConnected();
		}

		Probe.prototype.canMeasure = function () {
			return angular.isFunction(this.driver.measure) && this.isConnected();
		}

		Probe.prototype.setCalibration = function (data) {
			if (this.calibrationData) {
				this.calibrationData.setCalibration(data);
			}
		}

		Probe.prototype.createCalibrationSequence = function () {
			return new CalibrationSequence(this);
		}

		var methods = ['connect', 'disconnect', 'identify', 'measure', 'configure']
		methods.forEach(function (method) {
			Probe.prototype[method] = function () {
				if (angular.isUndefined(this.driver[method])) {
					return $q.when();
				}

				// We are doing stuff, cancel any timed disconnect
				$timeout.cancel(this.$disconnectTimer);
				delete this.$disconnectTimer;

				log.info(this.id + ' ' + method);
				var deferred = $q.defer();
				var self = this;
				var args = Array.prototype.slice.call(arguments);

				// If this method is already being called then return the promise for it ..
				var executingPromise = self['$promise_' + method];
				if (angular.isDefined(executingPromise)) {
					return executingPromise;
				}

				// Otherwise if another call is in progress then Reject
				if (this.$executing) {
					log.info(self.id + ' ' + method + ' busy');
					deferred.reject('Busy');
				} else {
					self['$promise_' + method] = deferred.promise;
					this.$executing = $timeout(function () {
						log.info(self.id + ' ' + method + ' timeout');
						delete self.$executing;
						delete self['$promise_' + method];
						deferred.reject('Time out');
						if (typeof self.driver[method + 'Cancel'] === 'function') {
							self.driver[method + 'Cancel']();
						}
					}, 30000);
					this.driver[method].apply(this.driver, args).then(function (result) {
						log.info(self.id + ' ' + method + ' finished');
						$timeout.cancel(self.$executing);
						delete self.$executing;
						delete self['$promise_' + method];
						deferred.resolve(result);
					}, function (error) {
						log.info(self.id + ' ' + method + ' error ' + error);
						$timeout.cancel(self.$executing);
						delete self.$executing;
						delete self['$promise_' + method];
						deferred.reject(error);
					});
				}
				return deferred.promise;
			}
		});

		Probe.prototype.onButtonPressed = function (callback) {
			var self = this;
			this.buttonCallbacks.push(callback);

			return function () {
				var idx = self.buttonCallbacks.indexOf(callback);
				if (idx >= 0) {
					self.buttonCallbacks.splice(idx, 1);
				}
			};
		}

		Probe.prototype.buttonPressed = function () {
			var self = this;
			ngTimeout(function () {
				self.manager.publish({ probe: self, name: 'button' });

				self.buttonCallbacks.forEach(function (cb) {
					cb();
				});
			})();
		}

		Probe.prototype._updateManager = function () {
			if (this.manager.probe === this) {
				this.manager.currentTemperature = this.readings.temperature;
				this.manager.battery = this.battery;
				this.manager.publish({ probe: this, name: 'updated' });
			}
		}

		Probe.prototype.updated = function (args) {
			var self = this;
			if (self.manager.clients.length == 0 && self.isConnectable) {
				// Nobody is listening, give ourselves 20 seconds and then disconnect the probe
				if (!self.$disconnectTimer) {
					self.$disconnectTimer = $timeout(function () {
						delete self.$disconnectTimer;
						// Recheck clients before disconnecting
						if (self.manager.clients.length == 0 && self.isConnected()) {
							self.disconnect();
							self.manager.probe = null;
						}
					}, 20000);
				}
			}
			ngTimeout(function () {
				angular.copy(args, self.readings);
				self.readings.temperature.serialNumber = self.serialNumber;
				angular.copy(args.battery, self.battery);
				if (angular.isDefined(args.temperature.rawValue) && !angular.isDefined(args.temperature.value)) {
					self.readings.temperature.value = args.temperature.rawValue;
				}
				self._updateManager();
			})();
		}

		function Manager() {
			this.providers = [];
			this.providersById = {};

			// Once we have a provider this changes to true, providers should only add themselves if they are supported
			this.supported = false;

			this.probes = [];
			this.probesById = {};
			this._cachedProbesById = {};            // This keeps a list of Probes even after they are removed, prevents
			// a probe from being accidentally created 2+ times
			this.probe = null;
			this.subscribers = [];

			this.clients = [];

			this.currentTemperature = Object.create(null);

			this.options = {
				measurementMilliseconds: 1000,
				autoOffSeconds: 1 * 60,         // If device supported - how long after Disconnect should probe shutdown
				temperature: {
					lowAlarm: null,
					highAlarm: null,
					trimValue: null
				}
			}
		}

		Manager.prototype.activeProbe = function (probe, callback) {
			var self = this;
			if (angular.isUndefined(probe)) {
				return self.probe;
			} else {
				self.connectingProbe = probe;

				if (probe) {
					log.info('set activeProbe ' + probe.id + ' ' + probe.statusText);
				} else {
					log.info('set activeProbe NONE');
				}

				var p = $q.when();
				if (self.probe && self.probe !== probe) {
					var onDisconnected = function () {
						var disconnectedProbe = self.probe;
						self.probe = null;
						if (callback) {
							callback();
						}
						self.publish({ name: 'disconnected', probe: disconnectedProbe });
					};
					if (self.probe.isConnectable) {
						if (!self.probe.isDisconnected() && !self.probe.isDisconnecting()) {
							p = self.probe.disconnect().then(onDisconnected);
						} else if (self.probe.isDisconnecting()) {
							self.probe.onDisconnected(onDisconnected);
						}
					} else {
						onDisconnected();
					}
				}
				if (probe && self.probe !== probe) {
					var onConnected = function () {
						self.probe = probe;
						delete self.connectingProbe;
						if (callback) {
							callback();
						}
						self.publish({ name: 'connected', probe: probe });

						probe._updateManager();
					}
					if (probe.isConnectable) {
						if (!probe.isConnected() && !probe.isConnecting()) {
							p = p.then(function () {
								return probe.connect().then(onConnected);
							});
						} else if (probe.isConnecting()) {
							probe.onConnected(onConnected);
						} else {
							onConnected();
						}
					} else {
						onConnected();
					}
				}
				return p;
			}
		}

		Manager.prototype.registerProvider = function (provider) {
			if (angular.isUndefined(this.providersById[provider.id])) {
				this.providersById[provider.id] = provider;
				this.providers.push(provider);
				this.supported = true;
			}
		}

		Manager.prototype.startScan = function (timeout) {
			var self = this;

			var scan = function (timeout) {
				var all = [];
				self.providers.forEach(function (provider) {
					if (angular.isDefined(provider.startScan)) {
						all.push(provider.startScan(timeout));
					}
				});
				return all;
			}

			if (angular.isDefined(timeout)) {
				return $q.all(scan(timeout)).then(function () {
					self.publish({ name: 'probes', probes: self.probes });
					return self.probes;
				})
			} else if (!self.$infiniteScan) {
				self.$infiniteScan = true;
				var scanFinished = function () {
					self.publish({ name: 'probes', probes: self.probes });
					if (self.$infiniteScan) {
						deferred.notify(self.probes);
						$q.all(scan(3000)).then(scanFinished);
					} else {
						deferred.resolve(self.probes);
					}
				}
				// No timeout, issue a 3 second timeout to each provider (since some of them do not support infinite timeouts)
				// and then re-issue once scanComplete - this requires the stopScan() method to be called at some point
				var deferred = $q.defer();
				$q.all(scan(3000)).then(scanFinished);
				return deferred.promise;
			}
		}

		Manager.prototype.getScanState = function () {
			var state = angular.undefined;
			this.providers.forEach(function (provider) {
				if (angular.isDefined(provider.scanState)) {
					if (angular.isUndefined(state)) {
						state = provider.scanState;
					} else if (provider.scanState != state) {
						state = null;
					}
				}
			})

			if (state != null) {
				return state;
			} else {
				return 'Mixed';
			}
		}

		Manager.prototype.stopScan = function () {
			var all = [];
			delete this.$infiniteScan;
			this.providers.forEach(function (provider) {
				if (angular.isDefined(provider.stopScan)) {
					all.push(provider.stopScan());
				}
			});
			return $q.all(all);
		}

		Manager.prototype.findProbe = function (id) {
			return this.probesById[id];
		}

		Manager.prototype.providerProbes = function (provider) {
			return this.probes.filter(function (probe) {
				return probe.provider === provider;
			});
		}

		Manager.prototype.addProbe = function (provider, id, name, driver) {
			var self = this;
			var probe = self._cachedProbesById[id];
			if (angular.isUndefined(probe)) {
				probe = new Probe(this, id, name, driver);
				probe.provider = provider;
				self._cachedProbesById[id] = probe;
			}
			this.probesById[probe.id] = probe;
			this.probes.push(probe);
			this.lastContactUtc = moment.utc();

			probe.getSerialNumber();

			this.publish({ probe: probe, name: 'added' });

			probe.remove = function () {
				if (probe === self.probe) {
					probe.disconnected();
					self.probe = null;
					self.publish({ probe: probe, name: 'disconnected' });
				}
				delete self.probesById[probe.id];
				var idx = self.probes.indexOf(probe);
				if (idx >= 0) {
					self.probes.splice(idx, 1);
					self.publish({ probe: probe, name: 'removed' });
					if (angular.isDefined(probe.driver.onremove)) {
						probe.driver.onremove();
					}
				}
			}

			return probe;
		}

		Manager.prototype.configure = function (options) {
			if (angular.isDefined(options)) {
				this.options = options;
			}
			if (this.probe) {
				return this.probe.configure(options);
			}
			return $q.when(this.options);
		}

		Manager.prototype.subscribe = function (callback) {
			var self = this;
			this.subscribers.push(callback);
			return function () {
				var idx = self.subscribers.indexOf(callback);
				if (idx >= 0) {
					self.subscribers.splice(idx, 1);
				}
			}
		}

		Manager.prototype.publish = function (event) {
			this.subscribers.forEach(function (subscriber) {
				subscriber(event);
			});
		}

		Manager.prototype.onButton = function (callback) {
			return this.subscribe(function (event) {
				if (event.name == 'button') {
					callback(event);
				}
			});
		}

		Manager.prototype.pollProbe = function () {
			if (this.probe) {
				return this.probe.measure();
			} else {
				return $q.reject('Not connected');
			}
		}

		Manager.prototype.startMonitor = function (measurementSeconds) {
			if (this.probe) {
				this.options.measurementMilliseconds = measurementSeconds * 1000;
				return this.probe.configure(this.options);
			} else {
				return $q.reject('Not connected');
			}
		}

		Manager.prototype.shutdown = function (disconnectProbeSeconds) {
			var self = this;
			self.$shutdown = $timeout(function () {
				self.activeProbe(null);
				self.providers.forEach(function (provider) {
					if (angular.isFunction(provider.shutdown)) {
						provider.shutdown();
					}
				})
			}, disconnectProbeSeconds * 1000);
		}

		Manager.prototype.subscribeProbeList = function (callback) {
			var self = this;
			return this.subscribe(function (event) {
				if (event.name == 'probes' || event.name == 'added' || event.name == 'removed') {
					callback(self.probes);
				}
			})
		}

		Manager.prototype.setCalibration = function (data) {
			// TODO
		}

		Manager.prototype.createCalibrationSequence = function () {
			// TODO
		}

		Manager.prototype.addClient = function (client) {
			var self = this;
			this.clients.push(client);

			$timeout.cancel(self.$shutdown);
			delete self.$shutdown;

			if (this.clients.length == 1) {
				self.providers.forEach(function (provider) {
					if (angular.isFunction(provider.startup)) {
						provider.startup();
					}
				})
			}
			return function () {
				var idx = self.clients.indexOf(client);
				if (idx >= 0) {
					self.clients.splice(idx, 1);
				}
				if (self.clients.length == 0) {
					self.stopScan();
				}
			}
		}
		return new Manager();
	}])

	.controller('temperature-probe-manager-test', ['$scope', 'temperature-probe-manager', function ($scope, manager) {
		$scope.manager = manager;

		$scope.message = '';
		$scope.buttonPressed = 0;

		var removeClient = manager.addClient($scope);

		$scope.startScan = function () {
			manager.startScan(10000).then(function (probes) {
				$scope.message = 'Found ' + probes.length + ' probes';
			})
		}

		manager.subscribe(function (event) {
			if (event.name == 'button') {
				$scope.buttonPressed++;

				event.probe.measure();
			}
		})

		$scope.$on('$destroy', function () {
			removeClient();
		});
	}])

	.factory('temperatureConvert', function () {
		return {
			kelvin: {
				toKelvin: angular.identity,
				toCelsius: function (kelvin) {
					return kelvin - 273.15;
				},
				toFahrenheit: function (kelvin) {
					return ((kelvin - 273.15) * 9.0 / 5.0) + 32.0;
				}
			},
			celsius: {
				toCelsius: angular.identity,
				toFahrenheit: function (celsius) {
					return (celsius * 9.0 / 5.0) + 32.0;
				},
				toKelvin: function (celsius) {
					return celsius + 273.15;
				}
			},
			fahrenheit: {
				toFahrenheit: angular.identity,
				toCelsius: function (fahrenheit) {
					return (fahrenheit - 32.0) * 5.0 / 9.0;
				},
				toKelvin: function (fahrenheit) {
					return ((fahrenheit - 32.0) * 5.0 / 9.0) + 273.15;
				}
			}
		};
	})