'use strict';

import app from '../../ngmodule';
import angular from 'angular';

app.controller('beginCtrl', ['$stateParams', 'bworkflowApi', '$scope', 'playerButtons',
  function ($stateParams, bworkflowApi, $scope, playerButtons) {

    $scope.buttonStates = playerButtons;
    $scope.buttonStates.areAjaxing = true;
    //our job, is to fire off an api request to beginCtrl, then transition to present
    bworkflowApi.beginChecklist($stateParams.groupId, $stateParams.resourceId, $stateParams.revieweeId, $stateParams.args, $stateParams.publishingGroupResourceId).
      then(function (r) {
        $scope.buttonStates.areAjaxing = false;
        $scope.transitionWithPlayerModel(r);
      }, function (ex) {
        $scope.buttonStates.areAjaxing = false;
        $scope.transitionWithPlayerModel(ex.data); //error handle
      });
  }]);