'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionCheckbox', ['$sce', 'bworkflowApi', 'languageTranslate', function ($sce, bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.presented.controlShowsValidationErrors = true;
      if (typeof attrs.renderPrompt === 'undefined')
        scope.shouldRenderPrompt = true;
      else
        scope.shouldRenderPrompt = attrs.renderPrompt.ToLower() == 'true';

      scope.presented.promptAsHtml = $sce.trustAsHtml(scope.presented.Prompt);

      //since angular won't parse the string replace in the style
      $(elt).attr('style', scope.presented.HtmlPresentation.CustomStyling);
    }
  });

}]);