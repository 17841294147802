'use strict';

import angular from "angular";
import bootstrapCompat from './bootstrap-compat';

bootstrapCompat.factory('navigator-notification', ['$timeout', '$window', function ($timeout, $window) {
  if (angular.isDefined(navigator.notification) && angular.isDefined(navigator.notification.alert)) {
    return navigator.notification;
  } else {
    return {
      alert: function (message, alertCallback, title, buttonName) {
        $timeout(function () {
          if (title) {
            message = title + '\n\n' + message;
          }
          $window.alert(message);
          if (alertCallback) {
            alertCallback();
          }
        });
      },

      confirm: function (message, confirmCallback, title, buttonLabels) {
        $timeout(function () {
          if (title) {
            message = title + '\n\n' + message;
          }
          var r = $window.confirm(message);
          if (confirmCallback) {
            confirmCallback(r);
          }
        });
      },

      prompt: function (message, promptCallback, title, buttonLabels, defaultText) {
        $timeout(function () {
          if (title) {
            message = title + '\n\n' + message;
          }
          var r = $window.prompt(message, defaultText);
          if (promptCallback) {
            promptCallback(r);
          }
        });
      },

      beep: function () {
        // not supported without Cordova plugin
      }
    };

  }
}]);