'use strict';

import angular from 'angular';
import '../services/language-translate';
import '../services/bworkflow-api';

export default {
  scope: {
    presented: '=',
    pageScope: '='
  },

  restrict: 'EA',
  link: function (scope, scopeDefault, bwkApi, languageTranslate) {
    var bworkflowApi = bwkApi;

    // questions get access to languageTranslate through their L property, L.code is the current selected language (English = 'en')
    // which makes for Dashboard embedded translations easy
    // {{ {'en': 'Hello', 'fr': 'Bonjour'}[L.code] }}
    scope.L = languageTranslate;

    if (scope.presented.Name && scope.pageScope) {
      // Access to Question scope can be explicitly through the 'question'
      scope.pageScope.scope[scope.presented.Name] = scope;

      if (!angular.isDefined(scopeDefault)) {
        scopeDefault = scope;
      }
      scope.pageScope[scope.presented.Name] = scopeDefault;
    }

    scope.validate = function (stage, data) {
      if (angular.isDefined(scope.getAnswerValue) == false || angular.isUndefined(bworkflowApi)) {
        return;
      }

      var val = scope.getAnswerValue();

      bworkflowApi.validate(scope, val, scope.answer, scope.presented.validators, stage, data);
    };

    scope.$on('validate', function (ev, data) {
      if (scope.presented.Visible) {
        scope.validate(data.stage, data);
      }
    });

    scope.$on('populateAnswer', function (ev) {
      // Record whether we actually displayed the question or not so server can filter out for validation if required
      if (angular.isDefined(scope.answer)) {
        if (!scope.presented.Visible) {
          scope.answer.WasHidden = !scope.presented.Visible;
        } else {
          delete scope.answer.WasHidden;
        }
      }
    });

    scope.setVisible = function (visible) {
      scope.presented.Visible = visible;
    };

    scope.pageScope._onPresentedReady(scope.presented);
  }
};