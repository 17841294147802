import angular from "angular";
import { IJavascriptModule } from './types';

interface CachedJavascriptModule {
	scriptName: string;
	module: IJavascriptModule;
	tracking: number;
}

export class JavascriptModuleCache {
	private javascriptModules: CachedJavascriptModule[] = [];

	public createOrUpdate(scriptName: string, tracking: number, code: string, ...arrayOfArgs: any[]): CachedJavascriptModule {
		// Look in the cache first ..
		let cacheEntry = this.javascriptModules.find(m => m.scriptName === scriptName && m.tracking === tracking);
		if (cacheEntry != null) {
			return cacheEntry;
		}

		let javascriptModule = {
			exports: {},
			code,
			refcount: 0
		}
		cacheEntry = {
			scriptName,
			module: javascriptModule,
			tracking
		}
		let script = `
(function(module, args) {
    'use strict';
    var exports = module.exports;
    ${arrayOfArgs.map((args, index) => 'var { ' + Object.keys(args).join(', ') + ' } = args[' + index + '];').join('\n')}
    ${code}
})\n//# sourceURL=https://offline-checklist/${tracking}/${scriptName.replace(/ /g, '-')}.js`;
		try {
			let fn = eval(script);
			fn.call(null, javascriptModule, arrayOfArgs);

			// Cache the result
			this.javascriptModules.push(cacheEntry);
		} catch (err) {
			console.error(err);
		}
		return cacheEntry;
	}
}