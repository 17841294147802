import { Question, Answer } from "./question";
import { JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { ILabelQuestionDefinition, DesignLabelQuestion } from 'lingo-api';
import { ILabelAnswer, IPresentedLabelQuestion } from "../../presented-types";

export class LabelQuestion extends Question implements DesignLabelQuestion {
	constructor() {
		super(`label`);
	}
}

export class LabelAnswer extends Answer implements ILingoAnswer {
	constructor(public answer: ILabelAnswer, public presentedQuestion: IPresentedLabelQuestion) {
		super(answer, presentedQuestion);
	}

	get value() {
		return null;
	}
	set value(value: null) {
	}
}

JsonTreeTranslators.register({
	ctr: LabelQuestion,
	create: () => new LabelQuestion()
}, {
	ctr: LabelAnswer,
	flatten(o: LabelAnswer) {
		return {
			answer: o.answer,
			presentedQuestion: o.presentedQuestion
		}
	},
	fatten(o: any, fatten: Convert, store: Convert) {
		return store(new LabelAnswer(fatten(o.answer), fatten(o.presentedQuestion)));
	}
});
