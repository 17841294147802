'use strict';

import './index.scss';

import app from '../../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../../control-base';
import { ITypeAheadDropDown, ITypeAheadCallbackDataItem } from '../interfaces';
import { createPopper, Instance, beforeWrite } from '@popperjs/core';

class TypeAheadDropDownController extends ControllerBase implements ITypeAheadDropDown {
    public items: Array<ITypeAheadCallbackDataItem>;
    public typeAhead: any;
    public onSelect?: null | ((args: any) => void) = null;

    protected popper: Instance | null;
    protected dropDownElement: ng.IRootElementService | null;
    protected itemIndex: number;

    static $inject = ['$element', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService) {
        super();

        this.items = [];
        this.popper = null;
        this.dropDownElement = null;
        this.itemIndex = -1;
    }

    $onInit(): void {
        this.typeAhead.setDropDown(this);

        this.dropDownElement = this.$element.find('.dropdown-menu');
    }

    $onDestroy(): void {
        this.hide();
    }

    show(attachTo: HTMLElement): void {
        if (this.dropDownElement == null || this.items == null || this.items.length == 0) {
            return;
        }
        const sameWidth = {
            name: "sameWidth",
            enabled: true,
            phase: beforeWrite,
            requires: ["computeStyles"],
            fn: ( data: any ) => {
                data.state.styles.popper.width = `${data.state.rects.reference.width}px`;
            },
            effect: ( data: any ) => {
                data.state.elements.popper.style.width = `${data.state.elements.reference.offsetWidth}px`;
            }
        };

        this.popper = createPopper(attachTo, this.dropDownElement[0], {
            placement: 'bottom-start',
            strategy: 'fixed',
            modifiers: [sameWidth]
        });

        this.dropDownElement[0].setAttribute('data-show', '');
        this.dropDownElement.addClass('show');

        this.dropDownElement.addClass('showing'); // this animates it on show

        this.popper.update();
    }

    hide(): void {
        if (this.dropDownElement == null || this.popper == null) {
            return;
        }

        this.dropDownElement[0].removeAttribute('data-show');
        this.dropDownElement.removeClass('show');
        this.popper.destroy();
        this.popper = null;

        this.itemIndex = -1;
    };

    selectionUp(): void {
        if (this.items == null || this.items.length == 0) {
            return;
        }

        // yeah based on the name this would appear to be the wrong way, but it's named from
        // the ui perspective where up is moving down through 5,4,3 etc
        this.itemIndex--;

        if (this.itemIndex < 0) {
            this.itemIndex = this.items.length - 1;
        }
    }

    selectionDown(): void {
        if (this.items == null || this.items.length == 0) {
            return;
        }

        // yeah based on the name this would appear to be the wrong way, but it's named from
        // the ui perspective where down is moving down from 0th element to first etc
        this.itemIndex++;

        if (this.itemIndex >= this.items.length) {
            this.itemIndex = 0;
        }
    }

    selectItem(index: number | null) {
        // index == null indicates selecting this.itemIndex
        if (this.items == null || this.items.length == 0) {
            return;
        }

        if (index != null) {
            if (index < 0 || index >= this.items.length) {
                return;
            }

            this.itemIndex = index;
        }

        if (this.itemIndex < 0 || this.itemIndex >= this.items.length) {
            return;
        }

        let item = this.items[this.itemIndex];

        if (this.onSelect == null) {
            return;
        }

        this.onSelect({ $event: { item: item, control: this } });
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.items) {
            this.items = changes.items.currentValue;
        }
    }
}

app.component('vmTypeAheadDropDown', {
    template: require('./template.html').default,
    require: {
        typeAhead: '^vmTypeAhead'
    },
    bindings: {
        items: '<',
        onSelect: '&'
    },
    controller: TypeAheadDropDownController
}); 