'use strict';

import app from './player/ngmodule';

app.config(
  ['$httpProvider', '$locationProvider', function ($httpProvider, $locationProvider) {
    // we want any HTTP requests to send down our authentication cookies.
    $locationProvider.html5Mode(true);
    $httpProvider.defaults.withCredentials = true;

    $httpProvider.interceptors.push('ApplicationHeaderInterceptor');
  }]);

  app.service('ApplicationHeaderInterceptor', [function() {
    var service = this;

    service.request = function(config) {
        if(angular.isUndefined(window.cordova) == false)
        {
          config.headers['VirtualManager-APP'] = "true";
        }
        
        return config;
    };
}]);
  