import { OfflineCache } from "../../../../offline-cache";
import { IWebServiceUrl } from "../../../../../common/web-service-url";
import { IExternBuilder } from "../../externs-builder";
import { IMembersFacility, OData, IMembersFacilityDefinition, FacilityType, IFacility, PrepareType } from 'lingo-api';
import _ from "lodash";

const minimalSelect: (keyof OData.Legacy.Members)[] = [`UserId`, `Email`, `IsASite`, `Name`, `UserName`, `UserType`];

function arrayIfString(v: string | string[] | undefined | null): string[] | null {
	if (typeof v === 'string') {
		return (v as string).split(`,`);
	} else if (Array.isArray(v)) {
		return v;
	}
	return null;
}

export class MembersFacility implements IFacility, IMembersFacility, IExternBuilder {
	readonly facilityType: FacilityType = `members`;

	odataUrl: string;
	private offlineCache?: OfflineCache<OData.Legacy.Members[], OData.Legacy.Members[]>;

	static $inject = [
		`definition`,
		`webServiceUrl`,
		`$http`];
	constructor(
		private definition: IMembersFacilityDefinition,
		private webServiceUrl: IWebServiceUrl,
		private $http: ng.IHttpService) {
		let query = [];
		const d = definition.odataLegacy;
		if (d.filter) query.push(`$filter=${d.filter}`);

		const orderBy = arrayIfString(d.orderBy);
		if (orderBy) query.push(`$orderBy=${orderBy.join(',')}`);

		// We always require a minimal selection from Membership ..
		const select = _.chain((arrayIfString(d.select || ``)))
			.map(f => f.trim())
			.filter(f => !!f)
			.concat(minimalSelect)
			.uniq()
			.value();
		query.push(`$select=${select}`);

		this.odataUrl = this.webServiceUrl.odata(`Members`) + `?` + query.join('&');
	}
	getAllOData(): Promise<OData.Legacy.Members[] | undefined> {
		throw new Error("Method not implemented.");
	}

	buildExterns(externs: any[], iterate: (o: any) => any): void {
		// This will resolve by "externs-builder", note we do not iterate this result as the getAll() promise could return a different number of members over time
		// which will change the "externs" array we are building and break workingdocument save/restore
		externs.push(this.getAllODataLegacy());
	}

	async prepare(prepareType: PrepareType): Promise<any> {
		if (this.definition.offline) {
			this.offlineCache = OfflineCache.get(this.odataUrl) || new OfflineCache<OData.Legacy.Members[], any>(
				this.odataUrl,
				this.odataUrl,
				{
					transform: (response: any) => response.value
				}
			)

			return this.offlineCache?.get();
		}
	}

	async getAllODataLegacy(): Promise<OData.Legacy.Members[] | undefined> {
		if (this.definition.offline) {
			return this.offlineCache?.get();
		} else {
			return this.$http.get<any>(this.odataUrl).then(response => response.data.value);
		}
	}
}
