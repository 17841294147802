import { WebServiceUrl, IWebServiceUrl } from '../common/web-service-url';
import ngModule from '../vmplayer/ngmodule';

export interface TenantInfo {
	Name: string;
	Profile: string;
	WebAppVersion: string;
}

export interface ITenantInfoService {
	getInfo(): ng.IPromise<TenantInfo>;
}

class TenantInfoService implements ITenantInfoService {
	static $inject = ['webServiceUrl', '$http'];

	constructor(
		private webServiceUrl: IWebServiceUrl,
		private $http: ng.IHttpService
	) {

	}

	getInfo(): ng.IPromise<TenantInfo> {
		return this.$http.get<TenantInfo>(`${this.webServiceUrl.api('App/AppTenant')}`).then(response => response.data);
	}
}

ngModule.factory('TenantInfoSvc', TenantInfoService);