import module from '../ngmodule';

import { WatchablePersistantStorage } from './watchable-persistant-storage';
import { PromiseBasedPersistantStorage } from './promise-based-persistant-storage';
import { LocalStorageBasedPersistantStorage } from './local-storage-based-persistant-storage';
import { IBasicPersistantStorage, IPersistantStorage } from './types';

export * from './types';

var basicPersistantStorage: IBasicPersistantStorage;
export function rootBasicPersistantStorage(): IBasicPersistantStorage {
    if (basicPersistantStorage == null) {
        if (window.persistantStorage != null) {
            // Probably running on VMPlayer which has a native persistantStorage service
            // LUCIFER-370 - Avoid using the root peristantStorage, instead create a namespace we can use
            basicPersistantStorage = window.persistantStorage;
        } else {
            // Browser uses local storage
            basicPersistantStorage = new LocalStorageBasedPersistantStorage('');
        }
    }
    return basicPersistantStorage;
}

module.factory('persistantStorage', ["$q", "$window", function ($q: ng.IQService, $window: ng.IWindowService) {
    console.log('ng_persistantStorage from vm-common.persistantStorage');

    let rootStorage = rootBasicPersistantStorage();
    let ngStorage = rootStorage.createNamespace('root');

    return new WatchablePersistantStorage(new PromiseBasedPersistantStorage(ngStorage, $q));
}]);
