import questionsModule from './ngmodule';

questionsModule.factory('phoneBookMaker', function () {
	return function (opts) {
		opts = angular.extend({
			getName: angular.identity,
			click: angular.identity
		}, opts || {});
		opts.itemsPerLetterBucket = opts.itemsPerLetterBucket || 20;

		return function (items) {
			var maxSplitLength = 0;

			function split(letterBucket, splitLength) {
				maxSplitLength = maxSplitLength < splitLength ? splitLength : maxSplitLength;
				var split = [];
				letterBucket.items.forEach(function (item) {
					pushItem(item, split, splitLength);
				})
				return split;
			}

			function pushItem(item, letterBucket, splitLength) {
				var letters = opts.getName(item).trim();
				var bucket = letterBucket.find(function (b) {
					return b.match(letters);
				});
				if (bucket == null) {
					bucket = {
						calcLetters: function () {
							var first, last;
							var length = maxSplitLength < splitLength ? splitLength : maxSplitLength;
							bucket.items.forEach(function (i) {
								last = opts.getName(i).trim();
								if (!first) {
									first = last;
								}
							});

							if (first === last) {
								return first.substring(0, length) + '...';
							}
							for (var i = 0; i < first.length > last.length ? last.length : first.length; i++) {
								if (first[i] !== last[i]) {
									return first.substring(0, i + 1) + '...' + last.substring(0, i + 1);
								}
							}
							return first + '...' + last;
						},
						match: function (test) {
							if (test.length < splitLength) {
								return false;
							}
							return test.substring(0, splitLength).toLowerCase() === letters.substring(0, splitLength).toLowerCase();
						},
						items: [],
						click: function () {
							opts.click(bucket);
						}
					}
					letterBucket.push(bucket);
				}
				bucket.items.push(item);
				if (bucket.items.length > opts.itemsPerLetterBucket) {
					var splitBuckets = split(bucket, splitLength + 1);
					var i = letterBucket.indexOf(bucket);
					letterBucket.splice.apply(letterBucket, [i, 1].concat(splitBuckets));
				}
			}

			var sortedItems = items.slice().sort(function (a, b) {
				var aName = opts.getName(a);
				var bName = opts.getName(b);
				return aName.localeCompare(bName);
			});
			var root = [];

			if (sortedItems.length <= opts.itemsPerLetterBucket) {
				var allBucket = {
					items: sortedItems,
					letters: 'All',
					click: function () {
						opts.click(allBucket);
					}
				};
				root = [allBucket];
			} else {
				sortedItems.forEach(function (item) {
					pushItem(item, root, 1);
				});
				root.forEach(function (bucket) {
					bucket.letters = bucket.calcLetters();
				});
			}
			if (opts.sortBucket) {
				root.forEach(function (bucket) {
					bucket.items.sort(opts.sortBucket);
				});
			}
			return root;
		}
	}
});
