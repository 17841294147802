import { IChecklistPlayer, IPublishedResourceCompoundId, INextChecklistResult, ISaveChecklistResult, IPrevChecklistResult, IContinueChecklistResult, IChecklistPlayerQ } from '../bworkflow-api/types';
import { IOfflineChecklistCache } from './offline-checklist-player/types';

import ngmodule from './ngmodule';
import { createOfflineChecklistFromResponse } from './offline-checklist-player/offline-checklist-player';
import { AsyncToQ } from '../async-to-q';

export class ChecklistPlayer implements IChecklistPlayerQ {
	static $inject = [
		'async-to-q',
		`onlineChecklistPlayer`,
		`offlineChecklistPlayer`,
		`offlineChecklistCache`
	];

	constructor(private asyncToQ: AsyncToQ, private onlineChecklistPlayer: IChecklistPlayer, private offlineChecklistPlayer: IChecklistPlayer, private offlineChecklistCache: IOfflineChecklistCache) {
	}

	hasWorkingDocument(workingDocumentId: string): ng.IPromise<boolean> {
		return this.asyncToQ.convert(async () => {
			try {
				return await this.offlineChecklistPlayer.hasWorkingDocument(workingDocumentId) || await this.onlineChecklistPlayer.hasWorkingDocument(workingDocumentId);
			} catch (err) {
				console.error(err);
				throw err;
			}
		})
	}

	hasChecklist(publishedResourceId: IPublishedResourceCompoundId): ng.IPromise<boolean> {
		return this.asyncToQ.convert(async () => {
			try {
				return await this.offlineChecklistPlayer.hasChecklist(publishedResourceId) || await this.onlineChecklistPlayer.hasChecklist(publishedResourceId);
			} catch (err) {
				console.error(err);
				throw err;
			}
		});
	}

	beginChecklist(checklistId: IPublishedResourceCompoundId, revieweeId: string, args: any): ng.IPromise<any> {
		return this.asyncToQ.convert(async () => {
			try {
				let isOffline = await this.offlineChecklistPlayer.hasChecklist(checklistId);

				if (isOffline) {
					return await this.offlineChecklistPlayer.beginChecklist(checklistId, revieweeId, args);
				} else {
					return await this.onlineChecklistPlayer.beginChecklist(checklistId, revieweeId, args).then(data => {
						return data;
						// TODO  This may need to be handled
						/*if (data.Type === 'LingoJsOffline') {
							let checklist = createOfflineChecklistFromResponse(data);

							return this.offlineChecklistCache.addChecklist(checklist).then(() => {
								return this.offlineChecklistPlayer.beginChecklist(checklistId, revieweeId, args);
							})
						} else {
							return data;
						}*/
					})
				}
			} catch (err) {
				console.error(err);
				throw err;
			}
		});
	}

	saveChecklist(workingDocumentId: string, answerModel: any): ng.IPromise<ISaveChecklistResult> {
		return this.asyncToQ.convert(async () => {
			try {
				if (await this.offlineChecklistPlayer.hasWorkingDocument(workingDocumentId)) {
					return await this.offlineChecklistPlayer.saveChecklist(workingDocumentId, answerModel);
				} else {
					return await this.onlineChecklistPlayer.saveChecklist(workingDocumentId, answerModel);
				}
			} catch (err) {
				console.error(err);
				throw err;
			}
		})
	}

	nextChecklist(workingDocumentId: string, answerModel: any, useOfflineQueue: boolean): ng.IPromise<INextChecklistResult> {
		return this.asyncToQ.convert(async () => {
			try {
				if (await this.offlineChecklistPlayer.hasWorkingDocument(workingDocumentId)) {
					return await this.offlineChecklistPlayer.nextChecklist(workingDocumentId, answerModel, useOfflineQueue);
				} else {
					return await this.onlineChecklistPlayer.nextChecklist(workingDocumentId, answerModel, useOfflineQueue);
				}
			} catch (err) {
				console.error(err);
				throw err;
			}
		})
	}

	prevChecklist(workingDocumentId: string): ng.IPromise<IPrevChecklistResult> {
		return this.asyncToQ.convert(async () => {
			try {
				if (await this.offlineChecklistPlayer.hasWorkingDocument(workingDocumentId)) {
					return await this.offlineChecklistPlayer.prevChecklist(workingDocumentId);
				} else {
					return await this.onlineChecklistPlayer.prevChecklist(workingDocumentId);
				}
			} catch (err) {
				console.error(err);
				throw err;
			}
		})
	}

	continueChecklist(workingDocumentId: string): ng.IPromise<IContinueChecklistResult> {
		return this.asyncToQ.convert(async () => {
			try {
				if (await this.offlineChecklistPlayer.hasWorkingDocument(workingDocumentId)) {
					return await this.offlineChecklistPlayer.continueChecklist(workingDocumentId);
				} else {
					return await this.onlineChecklistPlayer.continueChecklist(workingDocumentId);
				}
			} catch (err) {
				console.error(err);
				throw err;
			}
		})
	}
}

ngmodule.factory(`checklistPlayer`, ChecklistPlayer);
