'use strict';

import './index.scss';
import Cropper from 'cropperjs';

import app from '../ngmodule';
import angular, { IWindowService } from 'angular';
import { ControllerBase } from '../control-base';
import { IMediaOnClient, IVMMediaService } from '../../services/vm-media-service';
import { IVMFaceRecognitionService } from '../../services/vm-face-recognition';

export interface IImageManipulatorChangedEvent {
    originalMediaOnClient: IMediaOnClient;
    editedMediaOnClient: IMediaOnClient;
}

class ImageManipulatorController extends ControllerBase {
    public mediaOnClient?: IMediaOnClient;

    protected editedMediaOnClient?: IMediaOnClient;
    protected imgElement?: HTMLImageElement;

    protected maxImageWidth: any;

    protected cropper?: Cropper;

    public onUpdate?: null | ((args: any) => void) = null;
    public onCancel?: null | ((args: any) => void) = null;

    static $inject = ['$element', '$timeout', 'vmMediaService', 'vmFaceRecognition', '$window'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService, protected vmMediaService: IVMMediaService, protected vmFaceRecognition: IVMFaceRecognitionService, protected $window: ng.IWindowService) {
        super();
    }

    $onInit(): void {
        if (this.mediaOnClient == null) {
            return;
        }

        this.vmMediaService.getDimensionsFromOnClient(this.mediaOnClient).then((d) => {
            if (d.width > this.$window.innerWidth) {
                this.maxImageWidth = "100%";
            }
            else {
                this.maxImageWidth = d.width + "px";
            }

            this.editedMediaOnClient = angular.copy(this.mediaOnClient);

            this.imgElement = this.$element.find('.mediaOnClient')[0] as HTMLImageElement;

            this.$timeout(() => {
                if (this.imgElement == null) {
                    return;
                }

                this.cropper = new Cropper(this.imgElement, {
                    guides: true,
                    rotatable: true,
                    scalable: false,
                    movable: false,
                    zoomable: false,
                    zoomOnTouch: false,
                    zoomOnWheel: false
                });
            });
        });
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.mediaOnClient) {
            this.mediaOnClient = changes.mediaOnClient.currentValue;

            this.editedMediaOnClient = angular.copy(this.mediaOnClient);
        }
    }

    changed($event: any): void {
        if ($event == null) {
            return;
        }

        $event.stopPropagation();
        $event.preventDefault();

        if (this.cropper == null || this.editedMediaOnClient == null) {
            return;
        }

        if (this.onUpdate == null) {
            return;
        }

        let canvas: HTMLCanvasElement = this.cropper.getCroppedCanvas();

        this.vmMediaService.createMediaDataFromCanvas(canvas).then((data) => {
            if (this.editedMediaOnClient == null) {
                return;
            }   
            
            if (this.onUpdate == null) {
                return;
            }            

            this.editedMediaOnClient.data = data;
            this.onUpdate({ $event: { originalMediaOnClient: this.mediaOnClient, editedMediaOnClient: this.editedMediaOnClient } });
        });
    }

    rotate(amount: number, $event: any): void {
        if ($event == null) {
            return;
        }

        $event.stopPropagation();
        $event.preventDefault();

        if (this.cropper == null) {
            return;
        }

        this.cropper.rotate(amount);
    }

    cancel($event: any): void {
        if ($event == null) {
            return;
        }

        $event.stopPropagation();
        $event.preventDefault();

        if (this.onCancel == null) {
            return;
        }

        this.onCancel({ $event: { originalMediaOnClient: this.mediaOnClient } });
    }

    async findFace($event: any): Promise<void> {
        if ($event == null) {
            return;
        }

        $event.stopPropagation();
        $event.preventDefault();

        if (this.imgElement == null) {
            return;
        }

        let detections = await this.vmFaceRecognition.detectSingleFace(this.imgElement);

        if (detections == null || this.cropper == null) {
            return;
        }

        let data = this.cropper.getCanvasData();
        let scaleX = data.width / data.naturalWidth;
        let scaleY = data.height / data.naturalHeight;
        let growX = detections.box.width * 0.15;
        let growY = detections.box.height * 0.15;

        this.cropper.setCropBoxData({
            left: ((detections.box.left - growX) * scaleX) + data.left,
            top: ((detections.box.top - 2 * growY) * scaleY) + data.top,
            width: (detections.box.width + 2 * growX) * scaleX,
            height: (detections.box.height + 3 * growY) * scaleY
        });
    }
}

app.component('vmImageManipulator', {
    template: require('./template.html').default,
    bindings: {
        mediaOnClient: '<',
        onUpdate: '&',
        onCancel: '&',
    },
    controller: ImageManipulatorController
});