'use strict';

import './index.scss';
import app from '../ngmodule';
import { ControllerWithChoicesBase, IControlWithChoiceOption } from '../control-with-choices-base';
import angular from 'angular';
import { IValidationService } from '../../services/validator-service';
import { threadId } from 'worker_threads';

declare var $: any; // give us a typescript variable to access jquery

class DropDownController extends ControllerWithChoicesBase {
    public multiSelect: boolean;

    public otherChoice?: any; // the object that represents the other choice in the list, if set and selected then the text box will be shown allowing free entry
    protected otherValue: string; // bound to by the text box to capture the text entered by the user if the other choice is selected
    protected otherOption?: IControlWithChoiceOption; // the option object that maps to the choice object
    protected otherPrompt?: string; // the prompt to use on the other option
    protected otherValueField?: string; // the name of the field on the choice object that should be set to the value entered into the text box when the other choice is selected
    protected kendoDropDown?: kendo.ui.DropDownList;
    protected kendoMultiSelect?: kendo.ui.MultiSelect;
    public disabled: boolean;

    protected allowBlankSelection: boolean;
    protected blankSelectionOption?: IControlWithChoiceOption;

    static $inject = ['$element', '$sce', '$timeout', '$transclude', 'vmValidationService', '$log'];
    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, private $timeout: ng.ITimeoutService, private $transclude: ng.ITranscludeFunction, validationService: IValidationService, private $log: ng.ILogService) {
        super($element, $sce, validationService);

        this.multiSelect = false;
        this.allowBlankSelection = false;
        this.disabled = false;
        this.otherValue = '';
    }

    $onInit() {
        if (angular.isDefined(this.otherValueField) == false) {
            let vf = this.$element.attr('other-value-field');
            if (vf != null) {
                this.otherValueField = vf;
            }
        }   

        if (angular.isDefined(this.multiSelect) == false) {
            this.multiSelect = this.$element.attr('multi-select') == 'true';
        }

        if (angular.isDefined(this.allowBlankSelection) == false) {
            this.allowBlankSelection = this.$element.attr('allow-blank-selection') == 'true';
        }

        super.$onInit();

        if(this.otherChoice != null)
        {
            // work out the option object that maps to the other choice
            for(let o of this.options)
            {
                if(o.model == this.otherChoice)
                {
                    this.otherOption = o;

                    // ok still a little work to do if things have been set up with primitive's rather than objects.
                    // In this scenario the model on the other option will be the same as the prompt, however when other
                    // is selected we present a text box to get what the actual value is, therefore the selected will contain
                    // the model/text to go into the textbox
                    if(this.isPrimitive(o.model) && this.selected != null)
                    {
                        // so this is a bit messy. For the other to be selected, the value in selected (or one of the values if its an array)
                        // needs to not be in the set of choices as it will be the text that has been entered. So we have to search for this scenario.
                        let v: any;
                        let selections: Array<any> = this.selected;
                        
                        if(this.isPrimitive(this.selected))
                        {
                            selections = [this.selected];
                        }

                        for(let s of selections)
                        {
                            let found = false;

                            for(let c of this.choices)
                            {
                                if(c == s)
                                {
                                    found = true;
                                    break;
                                }
                            }

                            if(found == false)
                            {
                                o.model = s;
                                o.selected = true; // it won't have been selected by the logic in our super as support for other isn't present there
                                break;
                            }
                        }
                    }

                    break;
                }
            }
        }

        if(this.allowBlankSelection)
        {
            // ok so we are set up to allow a blank selection, we do this by adding an extra option to our list of options
            let m: any = null;

            if(this.valueField)
            {
                m = {};
                m[this.valueField] = null;
            }

            this.blankSelectionOption = this.newOption("", !this.calculateHasSelection(), m);
            this.options.unshift(this.blankSelectionOption);
        }            

        this.manageOtherOption();

        this.$timeout(() => {
            try
            {
                if(this.multiSelect == false)
                {
                    let element = this.$element.find('input.single-select').kendoDropDownList({
                        dataTextField:  "prompt",
                        dataValueField: this.valueField == null ? undefined : this.valueField,
                        dataSource: this.options,
                        change: this.kendoDropDownChanged
                    });
                    this.kendoDropDown = element.data("kendoDropDownList");
    
                    // this next bit is a bit hacky. The change event executes in the context of the kendo drop down, so this in there is the kendo drop down not
                    // us. So we are going to store us against the jquery data object of the element, so the change method can call back into us
                    element.data("vm-drop-down", this);
    
                    let index = this.calculateSelectionIndex();
    
                    if(index.length > 0)
                    {
                        this.kendoDropDown?.select(index[0]);
                    }
    
                    if(this.disabled)
                    {
                        this.kendoDropDown?.enable(false);
                    }
                }
                else
                {
                    let element = this.$element.find('select').kendoMultiSelect({
                        dataTextField:  "prompt",
                        dataValueField: this.valueField == null ? undefined : this.valueField,
                        dataSource: this.options,
                        downArrow: true,
                        change: this.kendoDropDownChanged,
                    });
                    this.kendoMultiSelect = element.data("kendoMultiSelect");

                    // this next bit is a bit hacky. The change event executes in the context of the kendo drop down, so this in there is the kendo drop down not
                    // us. So we are going to store us against the jquery data object of the element, so the change method can call back into us
                    element.data("vm-drop-down", this);

                    let selections = this.calculateSelectionIndex();

                    if(selections.length > 0)
                    {
                        // err this selection api for this sucker is messy, you have to select using the value method.
                        // we have 2 options for this, if there is a valueField, then use that, if not we have to use
                        // the underlying objects themselves
                        let objects = [];
                        let data = this.kendoMultiSelect?.dataSource.data();
                        for(let i of selections)
                        {
                            if(this.valueField)
                            {
                                objects.push(this.options[i].model[this.valueField]);
                            }
                            else
                            {
                                if(data)
                                {
                                    objects.push(data[i]);
                                }
                            }
                        }

                        this.kendoMultiSelect?.value(objects);
                    }

                    if(this.disabled)
                    {
                        this.kendoMultiSelect?.enable(false);
                    }
                }
            }
            catch(error)
            {
                this.$log.warn(`The drop down with prompt ${this.prompt} could not be created due to ${error}`);
            }
        });
    }

    changedCallBack(): void {
        // we should only be called from the kendo ui changed event, which is outside of angular so get this onto the next angular dispatch
        this.$timeout(() => {
            if(this.multiSelect == false)
            {
                this.unselectAll();
                // ok so the typescript doesn't have selectedIndex defined (weird)
                let o: IControlWithChoiceOption = this.options[(this.kendoDropDown as any).selectedIndex];
    
                this.changed({ prompt: o.prompt, selected: true, model: o.model });
                this.validate(this.selected);    
            }        
            else
            {
                let selections: [] = this.kendoMultiSelect?.value();

                this.unselectAll();

                let options: Array<IControlWithChoiceOption> = [];
                for(let s of selections)
                {
                    for(let o of this.options)
                    {
                        if(this.isPrimitive(s))
                        {
                            if(this.valueField)
                            {
                                if(o.model[this.valueField] == s)
                                {
                                    // need to compare the values
                                    options.push(o);
                                    break;
                                }
                            }
                        }
                        else
                        {
                            // the kendo selection is one of out option objects
                            if(o.model == (s as any).model)
                            {
                                options.push(o);
                                break;
                            }
                        }
                    }
                }

                for(let o of options)
                {
                    o.selected = true;
                }

                this.doOnUpdate(null, null);
                this.validate(this.selected);    
            }
        });
    }

    kendoDropDownChanged(e: any): void {
        e.sender.element.data('vm-drop-down').changedCallBack();
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        super.$onChanges(changes);

        if (changes.disabled && changes.disabled.currentValue != undefined) {
            this.disabled = changes.disabled.currentValue;

            this.kendoDropDown?.enable(!this.disabled);
        }
    }

    isOtherValueFieldSet(): boolean {
        if(this.otherValueField == null)
        {
            this.$log.error(`The drop down with prompt ${this.prompt} allows selection of an other option with its choices being an object however no other-value-field has been set`);
            return false;
        }

        return true;
    }

    otherValueChanged(evt: any): void {
        if(this.otherChoice == null || this.otherOption == null)
        {
            return; // should never happen
        }

        if(this.isPrimitive(this.otherChoice))
        {
            // so the other choice is a string or something, therefore our selected property is just the text entered into the textbox
            this.otherOption.model = this.otherValue;
        }
        else {
            // so we have some sort of object model that represents the choices, we need the value field to work for this scenario
            if(this.isOtherValueFieldSet() == false)
            {
                return;
            }
            
            this.otherOption.model[this.otherValueField as string] = this.otherValue;
        }

        this.doOnUpdate(this.otherOption.model, this.otherOption.selected);
        this.validate(this.selected);
    }

    calculateHasSelection(): boolean {
        return this.calculateSelectionIndex().length > 0;
    }

    calculateSelectionIndex(): number[] {
        let result = [];

        for (var i = 0; i < this.options.length; i++) {
            let o: IControlWithChoiceOption = this.options[i];

            if (o.selected) {
                result.push(i);
            }
        }

        return result;
    }

    manageOtherOption(): void {
        if(this.otherOption == null)
        {
            return;
        }

        if(this.isPrimitive(this.otherChoice))
        {
            if((this.otherChoice as string) == '')
            {
                return;
            }

            this.otherValue = this.otherOption.model;
        }
        else {
            if(this.isOtherValueFieldSet() == false)
            {
                return;
            }

            this.otherValue = this.otherOption.model[this.otherValueField as string];
        }
    }

    hasSlot(slot: string) : boolean {
        return this.$transclude.isSlotFilled(slot);
    }
}

app.component('vmDropdownSelect', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after',
        'custom': '?customContent'
    },    
    bindings: {
        choices: '<',
        selected: '=',
        displayField: '<',
        valueField: '<',
        otherValueField: '<',
        multiSelect: '<',
        otherChoice: '<',
        otherPrompt: '<',
        showValidationMessages: '<',
        validationMessages: '<',
        validator: '=',        
        prompt: '<',
        allowBlankSelection: '<',
        disabled: '<',
        onUpdate: '&'
    },
    controller: DropDownController
});