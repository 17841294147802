'use strict';

import app from '../../ngmodule';

app.directive('questionTasklistNewtaskUi', ["$timeout", "bworkflowApi", "$animate", "$q", "tabletProfile", "appUpdateMonitor", "createNewTaskWorkflow", "webServiceUrl", "vmValidationService",
  function ($timeout, bworkflowApi, $animate, $q, tabletProfile, appUpdateMonitor, createNewTaskWorkflow, webServiceUrl, vmValidationService) {
    return {
      template: require('./template.html').default,
      restrict: 'E',
      require: 'ngModel',
      scope: {
        template: '=ngModel',
        presented: '=',
        onClose: '&'
      },

      link: function (scope, element, attrs) {
        scope.url = webServiceUrl;

        // the valudator object needs a little setting up due to the UI being able to present different optional things
        scope.validator = {};
        if (scope.template.requiresbasicdetails) {
          scope.validator.basicdetails = {
            name: {
              validation: {
                rules: [vmValidationService.createValidator("mandatory", "You must enter a name for the task")],
                errors: [] // we create an errors array so that we can bind the UI to this and get batch checking in the create method
              }
            }
          };
        }
        if (scope.template.requiressitedetails) {
          scope.validator.siteid = {
            validation: {
              rules: [vmValidationService.createValidator("mandatory", "You must select a site for the task")],
              errors: [] // we create an errors array so that we can bind the UI to this and get batch checking in the create method
            }
          };
        }

        if (!scope.template.basicdetails) {
          scope.template.basicdetails = {
            name: '',
            description: ''
          }
        }

        appUpdateMonitor.addPauser(scope); // Pause updates whilst our scope is alive

        scope.selection = { site: null };

        scope.memberFeedTemplate = {
          name: 'members',
          feed: 'Members',
          filter: "substringof('[[searchmemberssearch]]', Name) and IsASite eq true",
          orderbyfields: 'Name',
          idfields: ['UserId'],
          selectfields: 'UserId,Name',
          itemsperpage: 5,
          datascope: {
            ExcludeOutsideHierarchies: false,
            IncludeCurrentUser: true,
            ExcludeExpired: true
          },

          parameterdefinitions: [{
            internalname: 'searchmemberssearch'
          }]
        };

        scope.memberFeed = bworkflowApi.createDataFeed(scope.memberFeedTemplate, scope);

        var fnUnsubscribeTabletProfile;
        fnUnsubscribeTabletProfile = tabletProfile(function (profile) {
          if (profile.siteid) {
            var defaultMemberFeedTemplate = {
              name: 'members',
              feed: 'Members',
              filter: "UserId eq guid'" + profile.siteid + "'",
              orderbyfields: 'Name',
              idfields: ['UserId'],
              itemsperpage: 5
            };


            var defaultMemberFeed = bworkflowApi.createDataFeed(defaultMemberFeedTemplate, scope);
            defaultMemberFeed.afterLoadHooks.push(function (feed) {
              if (feed.data.length) {
                scope.selection.site = feed.data[0];
              }
            });
            defaultMemberFeed.getData(true);
          }

          fnUnsubscribeTabletProfile();
        });

        scope.create = function () {
          let result = vmValidationService.process(scope.template, scope.validator);
          if (result.errors.length > 0) {
            return;
          }

          // Prevent double-click immediately
          scope.isCreateDisabled = true;
          scope.isCreatingOnline = false;

          createNewTaskWorkflow.createAndClockIn(scope.template, scope.presented.userid, scope.presented.template.enforcesingleclockin).then(function (data) {
            scope.isCreateDisabled = false;
            scope.isCreatingOnline = false;
            scope.offline = false;
            scope.onClose({ data });
          }, function (error) {
            scope.isCreateDisabled = false;
            scope.isCreatingOnline = false;
            scope.offline = true;
            scope.onClose();
          }, function (notify) {
            scope.isCreatingOnline = !notify.offline;
          });
        };
      }
    };

  }]);