'use strict';

import app from './ngmodule';

export interface IVMMapCords {
    latitude: number;
    longitude: number;
}

export interface IVMMapMarker {
    cords: IVMMapCords;
    message?: string;
    open?: boolean;
    canDrag: boolean;
}

export interface IVMMappingService {
    getDefaultCenter(): IVMMapCords;
    toCords(cordsAsString : string | IVMMapCords) : IVMMapCords;

    findLocation(street: string, town: string, postcode: string, state: string, country: string):ng.IPromise<Array<IVMMapCords>>;
}

export class vmMappingService implements IVMMappingService {

    static $inject = ['$q', '$http'];
    constructor(protected $q: ng.IQService, protected $http: ng.IHttpService) {
    }

    isVMMapCords(test: any): test is IVMMapCords {
        return (test as IVMMapCords).latitude !== undefined;
    }

    getDefaultCenter(): IVMMapCords {
        // we have this centrally in the service so that other things that might interact with mapping can
        // get a default center location. We may want this to be dynamic based on the country they are in
        // for the moment I'm going with what things defaulted to in the vehicle management system, which is the only
        // place the maps were used
        return { latitude: 52.374004, longitude: 4.890359 };
    }

    toCords(cordsAsString : string | IVMMapCords) : IVMMapCords {
        if(this.isVMMapCords(cordsAsString))
        {
            return cordsAsString;
        }

        let split = cordsAsString.split(',');

        if(split.length != 2)
        {
            throw new Error('The string needs to represent a cordinate for conversion to a IVMMapCords');
        }

        return { latitude: parseFloat(split[0].trim()), longitude: parseFloat(split[1].trim()) }
    }

    findLocation(street: string, town: string, postcode: string, state: string, country: string) : ng.IPromise<Array<IVMMapCords>> {
        var deferred = this.$q.defer<Array<IVMMapCords>>();

        let url = `https://nominatim.openstreetmap.org/search?q=${street}+${town}+${state}+${postcode}+${country}&format=json&polygon=0&addressdetails=0`;

        this.$http.get(url, { withCredentials: false }).then((result) => {
            let locations = [];

            for(let l of result.data as Array<any>)
            {
                let r = {
                    latitude: l.lat,
                    longitude: l.lon
                }

                locations.push(r);
            }

            deferred.resolve(locations);
        });

        return deferred.promise;
    }
}

app.factory('vmMappingService', vmMappingService);