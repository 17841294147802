import bworkflowApiModule from './ngmodule';

bworkflowApiModule.factory('taskTypesService', ['$http', 'webServiceUrl', '$q', function ($http, webServiceUrl, $q) {
  function returnDataValue(response) {
    return response.data.value;
  }
  var svc = {
    getTaskTypes: function (force) {
      return $http({ url: webServiceUrl.odata('/TaskTypes'), method: 'GET', cache: !force }).then(returnDataValue);
    },
    getTaskTypesById: function (force) {
      return svc.getTaskTypes(force).then(function (list) {
        return list.reduce(function (map, item) {
          map[item.Id] = item;
          return map;
        }, {});
      });
    },
    getTaskTypeRelationships: function (force) {
      return $http({ url: webServiceUrl.odata('/TaskTypeRelationships'), method: 'GET', cache: !force }).then(returnDataValue);
    },
    getFinishedStatuses: function (force) {
      return $http({ url: webServiceUrl.odata('/FinishStatuses'), method: 'GET', cache: !force }).then(returnDataValue);
    },
    getFinishedStatusesById: function (force) {
      return svc.getFinishedStatuses(force).then(function (list) {
        return list.reduce(function (map, item) {
          map[item.Id] = item;
          return map;
        }, {});
      });
    },
    getTaskTypeFinishedStatuses: function (force) {
      return $http({ url: webServiceUrl.odata('/TaskTypeFinishedStatus'), method: 'GET', cache: !force }).then(returnDataValue);
    },
    getTaskTypeFinishedStatusesById: function (force) {
      return svc.getTaskTypeFinishedStatuses(force).then(function (list) {
        return list.reduce(function (map, item) {
          map[item.Id] = item;
          return map;
        }, {});
      });
    },
    getTaskType: function (tasktypeid) {
      return svc.getTaskTypesById().then(function (taskTypesById) {
        return svc.getTaskTypeFinishedStatuses().then(function (tasktypefinishedstatuses) {
          return svc.getFinishedStatuses().then(function (finishstatuses) {
            return svc.getTaskTypeRelationships().then(function (taskTypeRelationships) {
              var tasktype = taskTypesById[tasktypeid];
              if (tasktype) {
                if (angular.isUndefined(tasktype.finishedstatuses)) {
                  tasktype.finishedstatuses = [];
                  tasktype.pausedstatuses = [];
                  angular.forEach(tasktypefinishedstatuses, function (ttfs) {
                    if (ttfs.TaskTypeId != tasktypeid) {
                      return;
                    }
                    var fs = finishstatuses.find(function (i) { return i.Id == ttfs.ProjectJobFinishedStatusId; });
                    if (!fs) {
                      return;
                    }

                    var status = {
                      id: ttfs.Id,
                      requiresextranotes: fs.RequiresExtraNotes,
                      text: fs.Text
                    };

                    if (fs.Stage == 0) {
                      status.finishedstatusid = fs.Id;
                      tasktype.finishedstatuses.push(status);
                    } else if (fs.Stage == 1) {
                      status.pausedstatusid = fs.Id;
                      tasktype.pausedstatuses.push(status);
                    }
                  });
                }

                tasktype.relationships = taskTypeRelationships.filter(function (ttr) {
                  return ttr.SourceTaskTypeId === tasktypeid;
                }).map(function (ttr) {
                  return {
                    id: ttr.DestinationTaskTypeId,
                    name: ttr.DestinationTaskTypeName
                  };

                });
              }
              return tasktype;
            });
          });
        });
      });
    }
  };

  return svc;
}]);

