'use strict';

import('angular');
import app from './ngmodule';
import base64 from 'base64-arraybuffer';
import { read } from 'fs';
import { ITimeoutService, IWindowService } from 'angular';
import { IWebServiceUrl } from "../common/web-service-url";
import mod from '../questions';
import { data } from 'jquery';
import _ from 'lodash';
import { IUser } from './auth/auth-svc';
import { IPlayerExecute } from './bworkflow-api/player-execute';


export enum MediaOnServerSource {
    media = 1,
    membership,
    taskType,
    download,
    mediaPreview,
    unknown // indicates that this is an unknown source of media, the id is the URL to it
}

export interface IMediaOptions {
    format?: string;
    width?: number;
    height?: number;
    cache?: boolean;
    pageIndex?: number;
    loadAsBlob?: boolean;
}

export interface IMediaOnServer {
    id: string;
    name: string;
    source: MediaOnServerSource;
    options: IMediaOptions;
}

export interface IMediaOnServerNode {
    mediaOnServer?: IMediaOnServer;
    isFile: boolean;
    parentId?: string;
    viewStatus: MediaOnServerNodeViewStatus;
    description?: string;
    name: string;
    children: Array<IMediaOnServerNode>;
}

export enum MediaOnServerNodeViewStatus {
    unknown = 1,
    unread,
    previouslyRead,
    current
}

export enum MediaOnClientStatus {
    unloaded = 1,
    loading,
    loaded
}

export interface IMediaData {
    data: string | Blob | ArrayBuffer;
    contentType: string;
    loadedAsBlob: boolean;
}

class MediaData implements IMediaData {
    data: string | Blob;
    contentType: string;
    loadedAsBlob: boolean;

    private isDataBlob(test: string | Blob): test is Blob {
        return (test as Blob).stream !== undefined;
    }

    constructor(data: string | Blob, ct: string) {
        this.data = data;
        this.contentType = ct;
        this.loadedAsBlob = this.isDataBlob(data);
    }
}

export interface IMediaOnClientSaveToDeviceResult {
    success: boolean;
    message?: string;
    path?: string;
}

export interface IMediaOnClient {
    name: string;
    data: IMediaData | null;
    status: MediaOnClientStatus;
    isImage: boolean;

    mediaOnServer?: IMediaOnServer;

    getMimeType(): string;
}

export interface IVMMediaService {
    createMediaOnClientFromDataUrl(name: string, dataUrl: string): IMediaOnClient | null;
    createOnClientFromOnServer(items: Array<IMediaOnServer>): Array<IMediaOnClient>;
    createOnClientFromFiles(items: FileList): ng.IPromise<Array<IMediaOnClient>>;
    loadClient(onClient: IMediaOnClient): ng.IPromise<IMediaOnClient>;
    buildUrl(onServer: IMediaOnServer): string;
    createMediaDataFromCanvas(canvas: HTMLCanvasElement): ng.IPromise<IMediaData>;
    getDataFromString(base64: string): string;

    getDimensionsFromOnClient(client: IMediaOnClient): ng.IPromise<IImageDimensions>;
    removeOrientationFromOnClient(client: IMediaOnClient, maxWidth?: number, maxHeight?: number): ng.IPromise<IMediaOnClient>;
    getOrientationFromOnClient(client: IMediaOnClient): ng.IPromise<number>;

    getMediaOnServerNodes(showPageViewings: boolean, user: IUser) : ng.IPromise<Array<IMediaOnServerNode>>;

    isMediaOnClient(test: any): test is IMediaOnClient;
    isMediaOnServer(test: any): test is IMediaOnServer;
}

const imageFileExtensions: any = {
    gif: true,
    jpg: true,
    jpeg: true,
    png: true
}

export const fileExtensionsToMimeTypes: any = {
    csv: 'text/csv',
    pdf: 'application/pdf',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    gif: 'image/gif',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png'
}

export const mimeTypesToFileExtensions = _.invert(fileExtensionsToMimeTypes);

export interface IImageDimensions {
    width: number;
    height: number;
}

class MediaOnClient implements IMediaOnClient {
    protected _data: IMediaData | null;
    protected _name: string;

    status: MediaOnClientStatus;
    isImage: boolean;

    mediaOnServer?: IMediaOnServer;

    constructor(mediaOnServer?: IMediaOnServer, status?: MediaOnClientStatus) {
        this.isImage = false;

        if (mediaOnServer != null) {
            this.isImage = this.isFileImage(mediaOnServer.name);
        }

        this._name = mediaOnServer == null ? '' : mediaOnServer.name;
        this._data = null;
        this.status = status == null ? MediaOnClientStatus.unloaded : status;
        this.mediaOnServer = mediaOnServer;
    }

    isFileImage(type?: string): boolean {
        if (type == null) {
            return false;
        }

        // assuming type is a file name first
        let split = type.split('.');
        
        if(split.length == 1)
        {
            // ok, so not a file name, perhaps a content-type
            split = type.split('/');            

            if(split.length == 1)
            {
                return false;
            }
        }
        
        let ext = split.pop();

        if (ext == null ) {
            return false;
        }

        return imageFileExtensions[ext.toLowerCase()] != null
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;

        this.isImage = this.isFileImage(this._name);
    }

    get data(): IMediaData | null {
        return this._data;
    }

    set data(mediaImageData: IMediaData | null) {
        this._data = mediaImageData;
        this.status = mediaImageData == null ? MediaOnClientStatus.unloaded : MediaOnClientStatus.loaded;

        this.isImage = this.isFileImage(mediaImageData?.contentType);
    }

    public getMimeType(filename?: string): string {
        let result = '';

        if (filename == null) {
            filename = this.name;
        }

        if (filename == null) {
            return result;
        }

        let ext: string | undefined = filename.split('.').pop();

        if (ext == null) {
            return result;
        }

        if (fileExtensionsToMimeTypes[ext] == null) {
            return result;
        }

        return fileExtensionsToMimeTypes[ext];
    }
}

class vmMediaService implements IVMMediaService {
    protected imageCache: any;

    static $inject = ['webServiceUrl', 'CacheFactory', '$q', '$http', '$timeout', '$window', 'PlayerExecute'];
    constructor(protected webServiceUrl: IWebServiceUrl, protected CacheFactory: any, protected $q: ng.IQService, protected $http: ng.IHttpService, protected $timeout: ITimeoutService, protected $window: IWindowService, protected PlayerExecute: IPlayerExecute) {
        this.imageCache = CacheFactory.get('media-image-cache') || CacheFactory.createCache('media-image-cache', {
            capacity: 50,
            deleteOnExpire: 'none',
            maxAge: 30000,
            storageMode: 'localStorage',
            storeOnResolve: true
        });
    }

    buildUrl(onServer: IMediaOnServer): string {
        var makeUrl = this.webServiceUrl.mediaImage;

        switch (onServer.source) {
            case MediaOnServerSource.media:
                makeUrl = this.webServiceUrl.mediaImage;
                break;
            case MediaOnServerSource.membership:
                makeUrl = this.webServiceUrl.membershipImage;
                break;
            case MediaOnServerSource.taskType:
                makeUrl = this.webServiceUrl.taskTypeImage;
                break;
            case MediaOnServerSource.mediaPreview:
                makeUrl = this.webServiceUrl.mediaPreviewImage;
                break;
            case MediaOnServerSource.download:
                makeUrl = this.webServiceUrl.downloadFile;
                break;
            case MediaOnServerSource.unknown:
                makeUrl = this.webServiceUrl.customFile;
                break;
        }

        makeUrl = makeUrl.bind(this.webServiceUrl);
        return makeUrl(onServer.id, onServer.options);
    }

    isMediaOnClient(test: any): test is IMediaOnClient {
        return (test as IMediaOnClient).getMimeType !== undefined;
    }

    isMediaOnServer(test: any): test is IMediaOnServer {
        return (test as IMediaOnServer).source !== undefined;
    }

    isString(test: string | ArrayBuffer): test is string {
        return (test as string).substr !== undefined;
    }

    loadClient(onClient: IMediaOnClient): ng.IPromise<IMediaOnClient> {
        if (onClient.status == MediaOnClientStatus.loaded || onClient.mediaOnServer == null) {
            return this.$q.when(onClient);
        }

        onClient.status = MediaOnClientStatus.loading;

        let url: string = this.buildUrl(onClient.mediaOnServer);

        let result$: ng.IPromise<IMediaData> | null = null;

        let asBlob = false;
        if (onClient.mediaOnServer) {

            if (onClient.mediaOnServer.options.cache) {
                let cacheResult = this.imageCache.get(url);

                if (cacheResult != null) {
                    result$ = Promise.resolve(cacheResult);
                }
            }

            if (onClient.mediaOnServer.options.loadAsBlob) {
                asBlob = onClient.mediaOnServer.options.loadAsBlob;
            }
        }

        if (result$ == null) {
            result$ = this.$http.get(url, { responseType: asBlob ? "blob" : "arraybuffer" }).then(response => {
                // We only need the base64 data and the contentType of the image, cache these
                var imageData = new MediaData(asBlob ? response.data as Blob : base64.encode(response.data as ArrayBuffer), response.headers()['content-type']);

                if (onClient.mediaOnServer?.options.cache) {
                    try
                    {
                        this.imageCache.put(url, imageData);
                    }
                    catch(e)
                    {
                        console.warn(`there was an exception trying to media with id = ${onClient.mediaOnServer?.id} to the cache`);
                    }
                }

                return imageData;
            });
        }

        let r: ng.IDeferred<IMediaOnClient> = this.$q.defer<IMediaOnClient>();

        result$.then(function (imageData: IMediaData) {
            onClient.data = imageData;
            onClient.status = MediaOnClientStatus.loaded;

            r.resolve(onClient);
        });

        return r.promise;
    }

    createOnClientFromOnServer(items: IMediaOnServer[]): IMediaOnClient[] {
        let result: IMediaOnClient[] = new Array<IMediaOnClient>();

        for (let s of items) {
            let c = new MediaOnClient(s);

            result.push(c);
        }

        return result;
    }

    createOnClientFromFiles(items: FileList): ng.IPromise<Array<IMediaOnClient>> {
        let promises: Array<ng.IPromise<IMediaOnClient>> = new Array<ng.IPromise<IMediaOnClient>>();

        for (let i of items) {
            let p: ng.IDeferred<IMediaOnClient> = this.$q.defer<IMediaOnClient>();

            promises.push(p.promise);

            let c = new MediaOnClient(undefined, MediaOnClientStatus.unloaded);
            c.name = i.name;

            let reader: FileReader = new FileReader();

            reader.onload = () => {
                if (reader.result == null) {
                    return;
                }

                let d: string = reader.result as string; // we know its a string as its been read using readAsDataUrl
                d = this.getDataFromString(d);

                let data: MediaData = new MediaData(d, i.type);

                c.data = data;
                c.status = MediaOnClientStatus.loaded;

                p.resolve(c);
            }

            reader.readAsDataURL(i);
        }

        let result = this.$q.all(promises);

        return result;
    }

    parseFolderThread(folder: any): IMediaOnServerNode {
        let onServer: IMediaOnServer|undefined = folder.data.isfile == false ? undefined : {
            id: folder.data.mediaid,
            name: folder.data.filename,
            source: MediaOnServerSource.download,
            options: {
                format: folder.data.mimetype
            }
        };

        let viewStatus = MediaOnServerNodeViewStatus.unknown;

        switch (folder.data.viewstatus) {
            case "unread":
                viewStatus = MediaOnServerNodeViewStatus.unread;
                break;
            case "read previous":
                viewStatus = MediaOnServerNodeViewStatus.previouslyRead;
                break;
            case "current":
                viewStatus = MediaOnServerNodeViewStatus.current;
                break;                
        }

        let result: IMediaOnServerNode = {
            isFile: folder.data.isfile,
            viewStatus: viewStatus,
            mediaOnServer: onServer,
            description: folder.data.description,
            name: folder.label,
            parentId: folder.data.parentId,
            children: []
        }

        for(var c of folder.children)
        {
            let child = this.parseFolderThread(c);

            result.children.push(child);
        }

        return result;
    }

    getMediaOnServerNodes(showPageViewings: boolean, user: IUser): ng.IPromise<Array<IMediaOnServerNode>> {
        let p: ng.IDeferred<Array<IMediaOnServerNode>> = this.$q.defer<Array<IMediaOnServerNode>>();

        this.PlayerExecute('MediaDirectory', 'GetFolders', { showpageviewings: showPageViewings, userid: user.userId}).then((data) => {
            let result: Array<IMediaOnServerNode> = [];

            for(var f of (data as any).folders)
            {
                let c = this.parseFolderThread(f);
                result.push(c);
            }

            p.resolve(result);
        }, (reason) => {
            p.reject(reason);
        });

        return p.promise;
    }

    getDataFromString(base64: string): string {
        var b64idx = base64.indexOf('base64,');
        return b64idx >= 0 ? base64.substring(b64idx + 7) : base64;
    }

    breakOutDataUrl(dataUrl: string): { contentType: string, base64: string } | null {
        const dataIdx = dataUrl.indexOf('data:');
        const b64Idx = dataUrl.indexOf('base64,');
        if (dataIdx >= 0 && b64Idx >= 0) {
            return {
                contentType: dataUrl.substr(dataIdx + 5, b64Idx - (dataIdx + 6)),       // assumes 'data:xxxx;base64,yyyy'
                base64: dataUrl.substr(b64Idx + 7)
            }
        }
        return null;
    }

    createMediaOnClientFromDataUrl(name: string, dataUrl: string): IMediaOnClient | null {
        const breakOut = this.breakOutDataUrl(dataUrl);
        if (breakOut == null) {
            return null;
        }
        let moc = new MediaOnClient(undefined, MediaOnClientStatus.loaded);
        moc.name = `${name}.${mimeTypesToFileExtensions[breakOut.contentType]}`;
        moc.data = new MediaData(breakOut.base64, breakOut.contentType);
        moc.isImage = true;
        return moc;
    }

    createMediaDataFromCanvas(canvas: HTMLCanvasElement, dataAsBlob?: boolean): ng.IPromise<IMediaData> {
        let p: ng.IDeferred<IMediaData> = this.$q.defer<IMediaData>();

        if(dataAsBlob && dataAsBlob == true)
        {
            canvas.toBlob((blob: Blob | null) => {
                if(blob)
                {
                    p.resolve(new MediaData(blob, 'image/png'));
                    return;
                }

                p.reject('The client media does not represent an image');
            });
        }
        else
        {
            this.$timeout(() => {
                let d: string = this.getDataFromString(canvas.toDataURL());
                p.resolve(new MediaData(d, 'image/png'));
            });
        }

        return p.promise;        
    }

    getDimensionsFromOnClient(client: IMediaOnClient): ng.IPromise<IImageDimensions> {
        let p: ng.IDeferred<IImageDimensions> = this.$q.defer<IImageDimensions>();

        if (client == null || client.isImage == false || client.data?.loadedAsBlob) {
            this.$timeout(() => {
                p.reject('The client media does not represent an image');
            });

            return p.promise;
        }

        let img = new Image();
        img.onload = () => {
            p.resolve({ width: img.naturalWidth, height: img.naturalHeight });
        };

        img.src = 'data:image/png;base64,' + client.data?.data;

        return p.promise;
    }

    removeOrientationFromOnClient(client: IMediaOnClient, maxWidth?: number, maxHeight?: number, dataAsBlob?: boolean): ng.IPromise<IMediaOnClient> {
        let p: ng.IDeferred<IMediaOnClient> = this.$q.defer<IMediaOnClient>();

        let img = new Image();
        img.onload = () => {
            let c: HTMLCanvasElement = <HTMLCanvasElement>$("<canvas></canvas>")[0];

            c.width = img.naturalWidth;
            c.height = img.naturalHeight;

            if (maxWidth != null && maxHeight != null) {
                // easy, both are specified
                c.width = img.naturalWidth > maxWidth ? maxWidth : img.naturalWidth;
                c.height = img.naturalHeight > maxHeight ? maxHeight : img.naturalHeight;
            }
            else if (maxWidth != null) {
                // we have width, need to work out height to maintain same aspect
                c.width = img.naturalWidth > maxWidth ? maxWidth : img.naturalWidth;
                c.height = maxWidth / img.naturalWidth * img.naturalHeight;
            }
            else if (maxHeight != null) {
                c.height = img.naturalHeight > maxHeight ? maxHeight : img.naturalHeight;
                c.width = maxHeight / img.naturalHeight * img.naturalWidth;
            }

            let context: CanvasRenderingContext2D = <CanvasRenderingContext2D>c.getContext("2d");

            context.drawImage(img, 0, 0, c.width, c.height);

            this.createMediaDataFromCanvas(c, dataAsBlob).then((data) => {
                client.data = data;
                p.resolve(client);
            }, (reason) => {
                p.reject(reason);
            });
        };

        img.src = 'data:image/png;base64,' + client.data?.data;

        return p.promise;
    }

    getOrientationFromOnClient(client: IMediaOnClient): ng.IPromise<number> {
        let p: ng.IDeferred<number> = this.$q.defer<number>();

        if (client == null || client.data == null) {
            return this.$q.reject({ status: -2, reason: 'The client object is null or has no data' });
        }

        if (client.isImage == false) {
            return this.$q.reject({ status: -2, reason: 'The client object does not represent an image' });
        }

        let reader: FileReader = new FileReader();

        reader.onload = (event: ProgressEvent) => {
            if (!event.target) {
                return;
            }

            const file = event.target as FileReader;
            const view = new DataView(file.result as ArrayBuffer);

            if (view.getUint16(0, false) != 0xFFD8) {
                return this.$q.reject({ status: -2, reason: 'A JPG image must be supplied' });
            }

            const length = view.byteLength
            let offset = 2;

            while (offset < length) {
                if (view.getUint16(offset + 2, false) <= 8) return this.$q.reject({ status: -1, reason: 'No EXIF data is present in the image' });
                let marker = view.getUint16(offset, false);
                offset += 2;

                if (marker == 0xFFE1) {
                    if (view.getUint32(offset += 2, false) != 0x45786966) {
                        return this.$q.reject({ status: -1, reason: 'No EXIF orientation data is present in the image' });
                    }

                    let little = view.getUint16(offset += 6, false) == 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    let tags = view.getUint16(offset, little);
                    offset += 2;
                    for (let i = 0; i < tags; i++) {
                        if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                            return p.resolve(view.getUint16(offset + (i * 12) + 8, little));
                        }
                    }
                } else if ((marker & 0xFF00) != 0xFF00) {
                    break;
                }
                else {
                    offset += view.getUint16(offset, false);
                }
            }
            return this.$q.reject({ status: -1, reason: 'No EXIF data is present in the image' });
        };

        reader.readAsBinaryString(new Blob([base64.decode(client.data.data as string)], undefined));

        return p.promise;
    }
}

app.factory('vmMediaService', vmMediaService);