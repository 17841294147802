import { IBasicPersistantStorage, IPersistantStorage } from './types';

export class PromiseBasedPersistantStorage implements IPersistantStorage {
  constructor(private delegate: IBasicPersistantStorage, private $q: ng.IQService) {

  }

  createNamespace(namespace: string): IPersistantStorage {
    return new PromiseBasedPersistantStorage(this.delegate.createNamespace(namespace), this.$q);
  }

  getItem<T>(key: string, success?: (data: T) => void): ng.IPromise<T> {
    let deferred = this.$q.defer<T>();
    this.delegate.getItem(key, data => {
      if (success != null) {
        success(data);
      }
      deferred.resolve(data);
    });
    return deferred.promise;
  }

  setItem(key: string, data: any, success?: () => void, failure?: (err: Error) => void): ng.IPromise<void> {
    let deferred = this.$q.defer<void>();
    this.delegate.setItem(key, data, () => {
      if (success != null) {
        success();
      }
      deferred.resolve();
    }, err => {
      if (failure != null) {
        failure(err);
      }
      deferred.reject(err);
    });
    return deferred.promise;
  }

  removeItem(key: string, success?: (removed: boolean) => void): ng.IPromise<boolean> {
    let deferred = this.$q.defer<boolean>();
    this.delegate.removeItem(key, removed => {
      if (success != null) {
        success(removed);
      }
      deferred.resolve(removed);
    });
    return deferred.promise;
  }

  clear(callback?: () => void): ng.IPromise<void> {
    let deferred = this.$q.defer<void>();
    this.delegate.clear(() => {
      if (callback != null) {
        callback();
      }
      deferred.resolve();
    });
    return deferred.promise;
  }

  getKeys(callback?: (keys: string[]) => void): ng.IPromise<string[]> {
    let deferred = this.$q.defer<string[]>();
    this.delegate.getKeys(keys => {
      if (callback != null) {
        callback(keys);
      }
      deferred.resolve(keys);
    });
    return deferred.promise;
  }
}
