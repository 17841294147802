'use strict';

import bootstrapcompat from './ngmodule';

bootstrapcompat.run(['$rootScope', function ($rootScope) {
  // 'tab_selected' will be emitted by any 'tabify'ied tab
  $rootScope.$on('tab_selected', function (event, args) {
    // Fix for Leaflet maps which dont behave well on tabs - this tells them to invalidate their map
    $rootScope.$broadcast('invalidatemap');
    $rootScope.$broadcast('fill-height.changed');
  });
}]);

// directive to make a bootstrap tab out of the element the directive is placed on
bootstrapcompat.directive('tabify', ['$timeout',
  function ($timeout) {
    return {
      link: function (scope, element, attrs) {
        $timeout(function () {
          if (element.parent().children(0)[0] == element[0]) {
            element.children(0).tab('show');
          }
        });

        element.on('shown', function (e) {
          scope.$emit('tab_selected', {
            tabcontent: $("#" + $(e.target).data('tabid'))
          });

        });
      }
    };

  }]);


bootstrapcompat.directive('imageMagnify', ['$timeout',
  function ($timeout) {
    return {
      link: function (scope, element, attrs) {
        $timeout(function () {
          element.magnify();
        });
      }
    };

  }]);


bootstrapcompat.directive('tabifyTab', ['$timeout',
  function ($timeout) {
    return {
      link: function (scope, element, attrs) {
        scope.$on('select_tab', function (evt, data) {
          element.parent().parent().find('a[href="#' + element[0].id + '"]').click();
        });
      }
    };

  }]);


bootstrapcompat.directive('collapseify', ['$timeout', '$parse',
  function ($timeout, $parse) {
    return {
      link: function (scope, element, attrs) {
        $timeout(function () {
          element.collapse();
        });
      }
    };

  }]);


bootstrapcompat.directive('switchify', ['$timeout',
  function ($timeout) {
    return {
      require: "ngModel",
      link: function (scope, element, attrs, ngModel) {
        $timeout(function () {
          var angularModel = ngModel;

          element.bootstrapSwitch().on('switch-change', function (ev, data) {
            angularModel.$setViewValue(data.value);
          });
        });
      }
    };

  }]);


// directive to make a bootstrap datepicker out of the element its placed on
bootstrapcompat.directive('dateify', ['$timeout', '$rootScope',
  function ($timeout, $rootScope) {
    return {
      require: "ngModel",
      link: function (scope, element, attrs, ngModel) {
        var angularModel = ngModel;

        // Listen to all dateified scopes, we might
        $rootScope.dateified = $rootScope.dateified || [];

        $timeout(function () {
          var datePicker = element.datepicker({
            format: 'dd-mm-yyyy',
            clearBtn: true,
            onRender: function (date) {
              if (angular.isFunction(scope.dateOnRender)) {
                return scope.dateOnRender({
                  date: date
                });

              }
              return '';
            }
          }).
            on('changeDate', function (ev) {
              if (angular.isDefined(attrs.useEvent) == true) {
                scope.$emit('dateify.changed', moment(ev.date.valueOf()).format('DD-MM-YYYY'));
              } else {

                angularModel.$setViewValue(moment(ev.date.valueOf()).format('DD-MM-YYYY'));
              }

              angular.forEach($rootScope.dateified, function (dp) {
                dp.data("datepicker").update();
              });
            });
          $rootScope.dateified.push(datePicker);
        });

        scope.$on('$destroy', function () {
          var i = $rootScope.dateified.indexOf(scope);
          $rootScope.dateified.splice(i, 1);
        });
      }
    };

  }]);


bootstrapcompat.directive('datePicker', ['$timeout',
  function ($timeout) {
    return {
      restrict: 'E',
      require: "ngModel",
      scope: {
        model: '=ngModel',
        passThroughStyle: '@',
        format: '=',
        dateOnRender: '&'
      },

      template: '<input type="text" ng-model="date" bwk-style="{{passThroughStyle}}" readonly dateify date-on-render="dateOnRender" use-event></input>',
      link: function (scope, element, attrs, ngModel) {
        if (!scope.format) {
          scope.format = 'DD-MM-YYYY';
        }
        scope.setDate = function () {
          if (scope.model != null) {
            scope.date = moment(scope.model).format(scope.format);
          } else {
            scope.date = null;
          }
        };

        scope.$on('dateify.changed', function (ev, args) {
          if (scope.isChanging == true) {
            // we got here because the model has changed and we've updated the date, so no need to get into
            // an infinite loop.
            ev.stopPropagation();
            return;
          }

          ngModel.$setViewValue(moment(args, scope.format).toDate());
          ev.stopPropagation();
        });

        scope.isChanging = false;

        scope.$watch('model', function (newValue, oldValue) {
          var m = moment(newValue);

          if (m.isValid() == false) {
            scope.date = null;
            return;
          }

          if (m.format(scope.format) == scope.date) {
            return;
          }

          scope.isChanging = true;

          scope.setDate();

          scope.isChanging = false;
        });
      }
    };

  }]);


// directive to implement a star rating control on the element its placed upon
bootstrapcompat.directive('rating', ['$timeout',
  function ($timeout) {
    return {
      templateUrl: 'tasks/rating.html',
      require: "ngModel",
      link: function (scope, element, attrs, ngModel) {

        scope.rate = function (rating) {
          if (scope.readonly == true) {
            return;
          }

          scope.rating = rating.value;
          scope.angularModel.$setViewValue(scope.rating);

          //scope.$emit('rated', { rating: scope.rating });
        };

        scope.preview = function (rating) {
          if (scope.readonly == true) {
            return;
          }

          scope.rating = rating.value;
        };

        scope.unpreview = function (rating) {
          if (scope.readonly == true) {
            return;
          }

          if (scope.angularModel.$isEmpty(scope.angularModel.$modelValue) == false) {
            scope.rating = scope.angularModel.$modelValue;
          } else {
            scope.rating = -1;
          }
        };

        scope.angularModel = ngModel;
        scope.rating = -1;

        $timeout(function () {
          if (scope.angularModel.$isEmpty(scope.angularModel.$modelValue) == false) {
            scope.rating = scope.angularModel.$modelValue;
          }
        });

        var defaults = {
          max: 5
        };

        if (angular.isDefined(attrs.max)) {
          defaults.max = parseInt(attrs.max);
        }

        scope.possibleratings = [];
        for (var i = 0; i < defaults.max; i++) {
          var rating = {
            value: i + 1
          };

          scope.possibleratings.push(rating);
        }

        scope.readonly = false;
        if (angular.isDefined(attrs.readonly)) {
          scope.readonly = true;
        };
      }
    };

  }]);


// directive to allow for sign on glass capture on the element its placed upon
bootstrapcompat.directive('signonglass', ['$timeout',
  function ($timeout) {
    return {
      templateUrl: 'tasks/signonglass.html',
      link: function (scope, elt, attrs) {

        scope.clearsignature = function () {
          scope.signaturePad.clearCanvas();
        };

        scope.acceptsignature = function () {
          var signature = scope.signaturePad.getSignatureString();

          if (scope.mandatory == true) {
            if (signature == "[]") {
              scope.showmandatorymessage = true;
              return;
            }
          }

          // we raise an event to let who ever know that things are good to go
          scope.$emit('signed', {
            signature: signature
          });

        };

        var defaults = {
          width: 200,
          height: 100,
          mandatory: false
        };


        scope.jsonData = elt.find("input");
        scope.signature = elt.find("canvas");
        scope.wrapper = elt.find(".sigWrapper");

        angular.extend(defaults, scope.$eval(attrs.signonglass));

        scope.mandatory = defaults.mandatory;

        elt.attr("style", "width:" + defaults.width + "px");
        scope.wrapper.attr("style", "height:" + defaults.height + "px");
        scope.signature.attr("width", defaults.width);
        scope.signature.attr("height", defaults.height);

        scope.signaturePad = elt.signaturePad({
          output: scope.jsonData,
          validateFields: false,
          drawOnly: true,
          lineTop: defaults.height - 20
        });

      }
    };

  }]);



// directive to make sure the value of 2 inputs match each other. Typicall used to validate a password and confirm password text boxes
bootstrapcompat.directive('matches', ['$timeout', function ($timeout) {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      element.on('keyup', function () {
        $timeout(() => {
          scope.$apply(function () {
            var v = element.val() === scope.$eval(attrs.matches);

            ngModel.$setValidity('matches', v);
          });
        });
      });
    }
  };

}]);

bootstrapcompat.directive('modalify', ['$timeout',
  function ($timeout) {
    return {
      scope: {
        modalify: '='
      },

      link: function (scope, element, attrs) {
        scope.$watch('modalify', function (newValue, oldValue) {
          if (newValue == oldValue) {
            return;
          }

          if (newValue == true) {
            element.animate({
              scrollTop: 0
            },
              'slow');

            element.modal('show').on('hidden', function () {
              $timeout(function () {
                scope.modalify = false;
              });
            });
          } else {
            element.modal('hide');
          }
        });
      }
    };

  }]);


bootstrapcompat.directive('checkboxAsPills', ['$timeout',
  function ($timeout) {
    return {
      restrict: 'E',
      require: "ngModel",
      scope: {
        model: '=ngModel'
      },

      template: '<ul class="nav nav-pills"><li ng-class="{active: model}"><a href="#" ng-click="setTrue()">{{trueLabel}}</a></li><li ng-class="{active: !model}"><a href="#" ng-click="setFalse()">{{falseLabel}}</a></li></ul>',
      link: function (scope, element, attrs, ngModel) {
        scope.trueLabel = 'Yes';
        scope.falseLabel = 'No';

        var angularModel = ngModel;

        if (angular.isDefined(attrs.trueLabel)) {
          scope.trueLabel = attrs.trueLabel;
        }

        if (angular.isDefined(attrs.falseLabel)) {
          scope.falseLabel = attrs.falseLabel;
        }

        scope.setTrue = function () {
          angularModel.$setViewValue(true);
        };

        scope.setFalse = function () {
          angularModel.$setViewValue(false);
        };
      }
    };

  }]);


bootstrapcompat.directive('progressBar', ['$timeout',
  function ($timeout) {
    return {
      templateUrl: window.razordata.portalprefix + 'angulartemplates/bootstrap/progress.html',
      restrict: 'E',
      scope: {
        info: '=',
        success: '=',
        warning: '=',
        danger: '=',
        max: '='
      },

      link: function (scope, element, attrs) {
        // we take copies of these as we maybe doing some calcs
        // and we don't want the calc values propergating out of here
        scope.infoVal = scope.info;
        scope.successVal = scope.success;
        scope.warningVal = scope.warning;
        scope.dangerVal = scope.danger;

        if (angular.isDefined(scope.max) == true && scope.max != 0) {
          scope.$watch('info', function (newValue, oldValue) {
            scope.infoVal = newValue;

            scope.infoVal = scope.infoVal / scope.max * 100;
          });

          scope.$watch('success', function (newValue, oldValue) {
            scope.successVal = newValue;

            scope.successVal = scope.successVal / scope.max * 100;
          });

          scope.$watch('warning', function (newValue, oldValue) {
            scope.warningVal = newValue;

            scope.warningVal = scope.warningVal / scope.max * 100;
          });

          scope.$watch('danger', function (newValue, oldValue) {
            scope.dangerVal = newValue;

            scope.dangerVal = scope.dangerVal / scope.max * 100;
          });
        }
      }
    };

  }]);



bootstrapcompat.directive('imageList', ['$timeout',
  function ($timeout) {
    return {
      template: '<ul ng-if="imageIds.length > 0" class="thumbnails"><li ng-repeat="id in imageIds"><img ng-src="{{imageUrl(id)}}"></li></ul>',
      restrict: 'E',
      scope: {
        imageIds: '=?'
      },

      link: function (scope, element, attrs) {
        if (scope.imageIds == null && angular.isDefined(attrs.ids) == false) {
          scope.imageIds = [];
          return;
        }

        if (attrs.ids == 'null') {
          scope.imageIds = [];
          return;
        }

        if (scope.imageIds == null) {
          var ids = attrs.ids;

          scope.imageIds = ids.split(',');
        }

        scope.imageUrl = function (id) {
          id = id.trim();
          var url = window.razordata.mediaprefix + 'MediaImage/' + id + '.png';

          if (angular.isDefined(attrs.attributes) == true && attrs.attributes != '') {
            url = url + '?';
            var pairs = attrs.attributes.split(',');
            angular.forEach(pairs, function (pair, index) {
              var parts = pair.split(':');

              if (parts.length == 2) {
                url = url + parts[0].trim() + '=' + parts[1].trim() + '&';
              }
            });
          }

          return url;
        };
      }
    };

  }]);


var redactorOptions = {};

bootstrapcompat.directive('redactor', ['$timeout',
  function ($timeout) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {

        // Expose scope var with loaded state of Redactor
        scope.redactorLoaded = false;

        var updateModel = function updateModel(value) {
          // $timeout to avoid $digest collision
          $timeout(function () {
            scope.$apply(function () {
              if (angular.isDefined(ngModel.$viewValue) == true) {
                ngModel.$setViewValue(value);
              }
            });
          });
        },
          options = {
            changeCallback: updateModel
          },

          additionalOptions = attrs.redactor ?
            scope.$eval(attrs.redactor) : {},
          editor,
          $_element = angular.element(element);

        angular.extend(options, redactorOptions, additionalOptions);

        // prevent collision with the constant values on ChangeCallback
        if (!angular.isUndefined(redactorOptions.changeCallback)) {
          options.changeCallback = function () {
            updateModel.call(this);
            redactorOptions.changeCallback.call(this);
          };
        }

        // put in timeout to avoid $digest collision.  call render() to
        // set the initial value.
        $timeout(function () {
          editor = $_element.redactor(options);
          ngModel.$render();
        });

        ngModel.$render = function () {
          if (angular.isDefined(editor)) {
            $timeout(function () {
              $_element.redactor('set', ngModel.$viewValue || '');
              scope.redactorLoaded = true;
            });
          }
        };
      }
    };

  }]);


// taken from https://github.com/anthonychu/angular-fill-height-directive/blob/master/src/fill-height.js
bootstrapcompat.directive('fillHeight', ['$window', '$document', '$timeout',
  function ($window, $document, $timeout) {
    return {
      restrict: 'A',
      scope: {
        footerElementId: '@',
        additionalPadding: '@',
        initialDelay: '@',
        whenResized: '&'
      },

      link: function (scope, element, attrs) {
        scope.minHeightOnly = false;
        if (angular.isDefined(attrs.minHeightOnly)) {
          scope.minHeightOnly = true;
        }

        // in certain circumstances there may need to be a delay between when things happen and
        // when we want to calculate them. This is used as a work around on IOS safari where
        // the DOM didn't seem to have been adjusted, so the wrong height was being calculated.
        // The initial delay allows us to work around this on a case by case basis (I know I hate
        // setting some magic time number, but I've not been able to find anyway around this that
        // works reliably).
        scope.delay = 0;
        if (angular.isDefined(attrs.initialDelay)) {
          scope.delay = parseInt(attrs.initialDelay);
        }

        angular.element($window).on('resize', delayedWindowResize);

        function delayedWindowResize() {
          $timeout(function () {
            onWindowResize();
          }, scope.delay);
        }

        scope.$on('fill-height.changed', function () {
          delayedWindowResize();
        });

        delayedWindowResize();

        function onWindowResize() {
          var footerElement = angular.element($document[0].getElementById(scope.footerElementId));
          var footerElementHeight;

          if (footerElement.length === 1) {
            footerElementHeight = footerElement[0].offsetHeight +
              getTopMarginAndBorderHeight(footerElement) +
              getBottomMarginAndBorderHeight(footerElement);
          } else {
            footerElementHeight = 0;
          }

          var elementOffsetTop = $(element[0]).offset().top;

          var elementBottomMarginAndBorderHeight = getBottomMarginAndBorderHeight(element);

          var additionalPadding = scope.additionalPadding || 0;

          var elementHeight = $window.innerHeight -
            elementOffsetTop -
            elementBottomMarginAndBorderHeight -
            footerElementHeight -
            additionalPadding;

          if (scope.minHeightOnly == true) {
            element.css('min-height', elementHeight + 'px');
          } else {
            element.css('height', elementHeight + 'px');
          }

          if (angular.isDefined(scope.whenResized)) {
            scope.whenResized();
          }
        }

        function getTopMarginAndBorderHeight(element) {
          var footerTopMarginHeight = getCssNumeric(element, 'margin-top');
          var footerTopBorderHeight = getCssNumeric(element, 'border-top-width');
          return footerTopMarginHeight + footerTopBorderHeight;
        }

        function getBottomMarginAndBorderHeight(element) {
          var footerBottomMarginHeight = getCssNumeric(element, 'margin-bottom');
          var footerBottomBorderHeight = getCssNumeric(element, 'border-bottom-width');
          return footerBottomMarginHeight + footerBottomBorderHeight;
        }

        function getCssNumeric(element, propertyName) {
          return parseInt(element.css(propertyName), 10) || 0;
        }

      }
    };

  }]);


bootstrapcompat.directive('telerikReporting', ['$timeout', '$ocLazyLoad', '$http',
  function ($timeout, $ocLazyLoad, $http) {
    return {
      template: '<div id="telerikReporting" ng-if="noserver == false" class="telerik-reporting k-widget" fill-height>Loading ...</div><div ng-if="noserver == true" class="alert alert-error">The URL to the reporting server has not been configured</div>',
      restrict: 'E',
      scope: {
        report: '='
      },

      link: function (scope, element, attrs) {
        scope.rooturl = window.razordata.portalprefix + 'Reports/';

        scope.noserver = false;
        scope.created = false;

        scope.createControl = function () {
          $http.get(scope.rooturl + "ReportServerUrl").
            then(function (data) {
              if (data == null || data == 'null') {
                scope.noserver = true;
                return;
              }
              var reportUrl = data.url;
              // we are going to dynamically add the css and js requirments here
              // so that not every Tom, Dick and Harry has to get all this css and JS
              // even when they don't use reports.
              $ocLazyLoad.load([{
                type: 'css',
                path: reportUrl + '/ReportViewer/styles/kendo.common.min.css'
              },

              {
                type: 'css',
                path: reportUrl + '/ReportViewer/styles/kendo.metro.min.css'
              },

              {
                type: 'css',
                path: reportUrl + '/content/font-awesome.min.css'
              },

              {
                type: 'css',
                path: reportUrl + '/ReportViewer/styles/telerikReportViewer-9.1.15.624.css'
              },

              reportUrl + '/ReportViewer/js/kendo.web.min.js',
              reportUrl + '/ReportViewer/js/telerikReportViewer-9.1.15.624.min.js']).
                then(function () {
                  $('#telerikReporting').telerik_ReportViewer({
                    serviceUrl: reportUrl + "/api/reports/",
                    templateUrl: reportUrl + '/ReportViewer/templates/telerikReportViewerTemplate-9.1.15.624.html',
                    reportSource: {
                      report: scope.report.toString()
                    }
                  });


                });
            });

          scope.created = true;
        };

        scope.$watch('report', function (newValue, oldValue) {
          if (newValue == null) {
            return;
          }

          if (scope.created == false) {
            scope.createControl($(element).children()[0]);
          } else {
            var reportViewer = $("#telerikReporting").data("telerik_ReportViewer");

            reportViewer.reportSource({
              report: scope.report.toString()
            });

          }
        });

      }
    };

  }]);


bootstrapcompat.directive('powerBi', ['$timeout', '$ocLazyLoad', '$http',
  function ($timeout, $ocLazyLoad, $http) {
    return {
      templateUrl: window.razordata.portalprefix + 'angulartemplates/bootstrap/power_bi.html',
      restrict: 'E',
      require: 'ngModel',
      scope: {
        report: '=ngModel',
        token: '='
      },

      link: function (scope, elt, attrs, ngModel) {
        scope.created = false;

        // we have this variable so that we can lazy load scripts first time round
        // and have the template then do its thing
        scope._report = null;

        scope.fullscreen = function () {
          var report = powerbi.get(elt.find('.powerbireport')[0]);

          report.fullscreen();
        };

        scope.$on('power-bi.fullscreen', function () {
          scope.fullscreen();
        });

        scope.createControl = function () {
          $ocLazyLoad.load([window.razordata.portalprefix + 'Scripts/powerbi.js']).then(function () {
            scope.showReport();
          });

          scope.created = true;
        };

        scope.showReport = function () {
          scope._report = scope.report;

          $timeout(function () {
            powerbi.init();
          });
        };

        scope.$watch('report', function (newValue, oldValue) {
          if (newValue == null) {
            return;
          }

          if (scope.created == false) {
            scope.createControl();
          } else {
            scope.showReport();
          }
        });
      }
    };

  }]);


bootstrapcompat.directive('timeline', ['$timeout', '$ocLazyLoad', '$http', '$sce',
  function ($timeout, $ocLazyLoad, $http, $sce) {
    return {
      templateUrl: 'bootstrap/timeline.html',
      restrict: 'E',
      scope: {
        items: '=',
        dateformat: '='
      },

      link: function (scope, element, attrs) {
        scope.enteredGroup = null;

        scope.trustItems = function () {
          angular.forEach(scope.items, function (item) {
            item.trustedTagline = $sce.trustAsHtml(item.tagline);
            item.trustedBody = $sce.trustAsHtml(item.body);
          });
        };

        scope.enterGroup = function (group) {
          scope.enteredGroup = group;
        };

        scope.leaveGroup = function (group) {
          scope.enteredGroup = null;
        };

        scope.clicked = function (item) {
          scope.$emit('timeline.item.clicked', item);
        };

        scope.$watchCollection('items', function (newValue, oldValue) {
          scope.trustItems();
        });

        scope.trustItems();
      }
    };

  }]);


bootstrapcompat.directive('indeterminateProgressIndicator', ['$timeout',
  function ($timeout) {
    return {
      templateUrl: 'bootstrap/indeterminate_progress_indicator.html',
      restrict: 'E',
      scope: {
        title: '@',
        description: '@'
      },

      link: function (scope, element, attrs) {

      }
    };

  }]);


bootstrapcompat.directive('pills', ['$timeout',
  function ($timeout) {
    return {
      restrict: 'E',
      require: "ngModel",
      scope: {
        model: '=ngModel',
        options: '=',
        valueProperty: '@',
        textProperty: '@'
      },

      template: '<ul class="nav nav-pills"><li ng-repeat="o in options" ng-class="{active: model == o[valueProperty]}"><a href="#" ng-click="setValue(o[valueProperty])">{{o[textProperty]}}</a></li></ul>',
      link: function (scope, element, attrs, ngModel) {
        var angularModel = ngModel;

        scope.valueProperty = scope.valueProperty || 'value';
        scope.textProperty = scope.textProperty || 'text';

        scope.setValue = function (val) {
          angularModel.$setViewValue(val);
        };
      }
    };

  }]);


bootstrapcompat.directive('odataTypeahead', ['$timeout', 'bworkflowApi',
  function ($timeout, bworkflowApi) {
    return {
      templateUrl: 'bootstrap/odata_typeahead.html',
      restrict: 'E',
      require: "ngModel",
      scope: {
        selected: '=ngModel',
        datasource: '=?',
        name: '=?'
      },

      link: function (scope, element, attrs, ngModel) {
        scope.currentSelection = null;

        scope.cleardatasources = null;
        if (angular.isDefined(attrs.clearDatasources) == true) {
          scope.cleardatasources = attrs.clearDatasources;
        }

        scope.updatedatasources = null;
        if (angular.isDefined(attrs.updateDatasources) == true) {
          scope.updatedatasources = attrs.updateDatasources;
        }

        if (angular.isDefined(scope.name) == false && angular.isDefined(attrs.name) == true) {
          scope.name = attrs.name;
        }

        scope.clearSources = function () {
          bworkflowApi.clearDataFeedParameters(scope.cleardatasources, scope.currentSelection);
        };

        scope.updateSources = function () {
          // we store what the current selection is so that we can clear only what we've set
          // in the clear source method (if its called)
          scope.currentSelection = angular.copy(scope.watchcontainer.currentOdataObject.alldata);
          bworkflowApi.updateDataFeeds(scope.updatedatasources, scope.currentSelection, true);
        };

        scope.clear = function () {
          ngModel.$setViewValue(null);
          //                scope.selected = null;
          scope.watchcontainer.currentOdataObject = {};
          scope.clearSources();
          scope.updateSources();
        };

        scope.watchcontainer = {};
        scope.watchcontainer.currentOdataObject = {};

        scope.$watch('watchcontainer.currentOdataObject', function (newItem) {
          if (!newItem || angular.isDefined(newItem.alldata) == false) {
            return;
          }

          ngModel.$setViewValue(newItem);

          //                scope.selected = newItem;

          scope.clearSources();
          scope.updateSources();
        });

        scope.$watch('selected', function (newValue, oldValue) {
          scope.watchcontainer.currentOdataObject = newValue;
        });

        scope.$on('OdataTypeahead.clear', function (data, args) {
          if (angular.isDefined(args) && angular.isDefined(scope.name)) {
            if (angular.isDefined(args.name)) {
              if (args.name != scope.name) {
                return;
              }
            }
          }

          scope.clear();
        });
      },
      controller: ["$scope", "$element", "$attrs", function ($scope, $element, $attrs) {
        if (angular.isDefined($scope.datasource) == false && angular.isDefined($attrs.datasource) == true) {
          $scope.datasource = $attrs.datasource;
        }

        $scope.displayField = "Name";
        if (angular.isDefined($attrs.displayfield) == true) {
          $scope.displayField = $attrs.displayfield;
        }

        $scope.suggestionTemplate = "{{alldata." + $scope.displayField + "}}";

        if ($scope.datasource == null) {
          return;
        }

        $scope.asyncResults = null;

        $scope.options = {
          highlight: true
        };


        $scope.odataDataset = {
          displayKey: function (suggestion) {
            if (angular.isDefined(suggestion.alldata) == false) {
              return;
            }

            return suggestion.alldata[$scope.displayField];
          },
          source: function (query, syncResults, asyncResults) {
            $scope.asyncResults = syncResults;

            var fieldName = 'search';
            if ($scope.name != null && $scope.name != '') {
              fieldName = $scope.name + 'search';
            }

            $scope.feed.parameters[fieldName] = query;
            $scope.feed.getData(true);
          },
          async: true,
          templates: {
            suggestion: Handlebars.compile($scope.suggestionTemplate)
          }
        };



        if (typeof $scope.datasource === 'string') {
          var promise = bworkflowApi.getDataFeed($scope.datasource);

          if (promise != null) {
            promise.then(function (feed) {
              feed.afterLoadHooks.push(function (feed) {
                if ($scope.asyncResults != null) {
                  $scope.asyncResults(feed.data);
                }
              });

              $scope.feed = feed;
              $scope.feed.allowMultipleAjax = true; // EVS-1404 Fix slow typeahead search
            });
          }
        } else {
          $scope.feed = $scope.datasource;
          $scope.feed.allowMultipleAjax = true; // EVS-1404 Fix slow typeahead search

          $scope.feed.afterLoadHooks.push(function (feed) {
            if ($scope.asyncResults != null) {
              $scope.asyncResults(feed.data);
            }
          });
        }
      }]
    };

  }]);


bootstrapcompat.directive('taskBatchOperations', ['$timeout', 'bworkflowApi', '$q',
  function ($timeout, bworkflowApi, $q) {
    return {
      templateUrl: 'bootstrap/task_batch_operations.html',
      restrict: 'E',
      scope: {
        assignToDatasource: '=?',
        assignToParameterName: '=?',
        updateDatasources: '=?',
        name: '=?',
        features: '=',
        useButtons: '=?'
      },

      link: function (scope, element, attrs, ngModel) {
        scope.tasks = {};
        scope.taskCount = 0;

        scope.assigntooptions = [];

        scope.authentication = null;
        scope.authenticationfailed = false;

        if (scope.features.reassignallowclaim) {
          scope.assigntooptions.push({
            text: 'Me',
            value: 0
          });

        }

        if (scope.features.reassignallowassignother) {
          scope.assigntooptions.push({
            text: 'Original Owner',
            value: 1
          });

          scope.assigntooptions.push({
            text: 'Other',
            value: 2
          });

        }

        scope.assignment = {
          type: 0,
          touser: null
        };


        scope.operation = {
          type: ''
        };


        if (angular.isDefined(scope.name) == false && angular.isDefined(attrs.name) == true) {
          scope.name = attrs.name;
        }

        if (angular.isDefined(scope.assignToDatasource) == false && angular.isDefined(attrs.assignToDatasource) == true) {
          scope.assignToDatasource = attrs.assignToDatasource;
        }

        if (angular.isDefined(scope.assignToParameterName) == false && angular.isDefined(attrs.assignToParameterName) == true) {
          scope.assignToParameterName = attrs.assignToParameterName;
        }

        if (angular.isDefined(scope.updateDatasources) == false && angular.isDefined(attrs.updateDatasources) == true) {
          scope.updateDatasources = attrs.updateDatasources;
        }

        if (angular.isDefined(scope.useButtons) == false && angular.isDefined(attrs.useButtons) == true) {
          scope.useButtons = attrs.useButtons;
        }

        if (angular.isDefined(scope.features) == false) {
          // let the server work it out
          scope.features = {
            allowreassign: true,
            allowcancellation: true,
            allowfinishbymanagement: true,
            allowdelete: true,
            allowclearstarttime: true,
            reassignallowclaim: true,
            reassignallowassignother: true,
            requireauthentication: false,
            kioskmode: false
          };

        }

        scope.setoperation = function (op) {
          scope.operation = {
            type: op
          };

          scope.assignment = {
            type: 0,
            touser: null
          };

        };

        scope.add = function (task) {
          // we are expecting task to be an object from the task odata feed
          if (angular.isDefined(scope.tasks[task.Id]) == true) {
            return;
          }

          scope.tasks[task.Id] = task;
          scope.taskCount++;

          // we add a property so that who ever is calling us can react
          // if the task is removed from us through our UI
          task.taskbatchoperations_isbatched = true;

          switch (task.StatusText) {
            case "FinishedComplete":
            case "FinishedIncomplete":
            case "FinishedByManagement":
            case "FinishedBySystem":
            case "Cancelled":
              task.taskbatchoperations_action = "Finished: Cannot be changed";
              break;
            case "Started":
              task.taskbatchoperations_action = "Started: " + task.Owner + " must clock out before the task can be changed";
              break;
            default:
              task.taskbatchoperations_action = "Will be changed";
          }


          // we need to adjust the cancelled ones if its a delete that is being done
          if (task.StatusText == 'Cancelled' && scope.operation.type == 'delete') {
            task.taskbatchoperations_action = "Will be changed";
          }
        };

        scope.handleAdd = function (args) {
          if (angular.isDefined(args.task)) {
            scope.add(args.task);
          } else if (angular.isDefined(args.tasks)) {
            angular.forEach(args.tasks, function (task) {
              scope.add(task.alldata);
            });
          }
        };

        scope.$on('task-batch-operations.add', function (event, args) {
          if (angular.isDefined(args.name)) {
            if (args.name != scope.name) {
              return;
            }
          }

          scope.handleAdd(args);
        });

        scope.remove = function (task) {
          if (angular.isDefined(scope.tasks[task.Id]) == false) {
            return;
          }

          delete scope.tasks[task.Id];
          scope.taskCount--;

          // we add a property so that who ever is calling us can react
          // if the task is removed from us through our UI
          task.taskbatchoperations_isbatched = false;
        };

        scope.handleRemove = function (args) {
          if (angular.isDefined(args.task)) {
            scope.remove(args.task);
          } else if (angular.isDefined(args.tasks)) {
            angular.forEach(args.tasks, function (task) {
              scope.remove(task.alldata);
            });
          }
        };

        scope.$on('task-batch-operations.remove', function (event, args) {
          if (angular.isDefined(args.name)) {
            if (args.name != scope.name) {
              return;
            }
          }

          scope.handleRemove(args);
        });

        scope.toggle = function (task) {
          if (angular.isDefined(scope.tasks[task.Id]) == true) {
            scope.remove(task);
          } else {
            scope.add(task);
          }
        };

        scope.handleToggle = function (args) {
          if (angular.isDefined(args.task)) {
            scope.toggle(args.task);
          } else if (angular.isDefined(args.tasks)) {
            angular.forEach(args.tasks, function (task) {
              scope.toggle(task.alldata);
            });
          }
        };

        scope.$on('task-batch-operations.toggle', function (event, args) {
          if (angular.isDefined(args.name)) {
            if (args.name != scope.name) {
              return;
            }
          }

          scope.handleToggle(args);
        });

        scope.clear = function () {
          scope.error = null;

          angular.forEach(scope.tasks, function (task) {
            // we add a property so that who ever is calling us can react
            // if the task is removed from us through our UI
            task.taskbatchoperations_isbatched = false;
          });

          scope.tasks = {};
          scope.taskCount = 0;
          scope.operation = {
            type: ''
          };

          scope.authentication = null;
        };

        scope.updateSources = function () {
          bworkflowApi.updateDataFeeds(scope.updateDatasources, null, true);
        };

        scope.isAuthenticated = function (data) {
          if (angular.isDefined(data.authenticated) == true) {
            return data.authenticated;
          }

          return true;
        };

        scope.reassigntasks = function () {
          var params = {
            assignto: null,
            tasks: []
          };


          if (scope.assignment.type == 1) {
            params.assignto = 'original';
          } else if (scope.assignment.type == 2) {
            params.assignto = scope.assignment.touser.alldata.UserId;
          }

          if (scope.authentication != null) {
            params.authentication = scope.authentication;
          }

          angular.forEach(scope.tasks, function (task) {
            params.tasks.push({
              id: task.Id
            });

          });

          bworkflowApi.execute('TaskUtilities', 'Reassign', params).
            then(function (data) {
              if (scope.isAuthenticated(data) == false) {
                scope.authenticationfailed = true;
                return;
              }

              scope.updateSources();
              scope.clear();
            }, function (error) { });
        };

        scope.canceltasks = function () {
          var params = {
            tasks: []
          };


          if (scope.authentication != null) {
            params.authentication = scope.authentication;
          }

          angular.forEach(scope.tasks, function (task) {
            params.tasks.push({
              id: task.Id
            });

          });

          bworkflowApi.execute('TaskUtilities', 'Cancel', params).
            then(function (data) {
              if (scope.isAuthenticated(data) == false) {
                scope.authenticationfailed = true;
                return;
              }

              scope.updateSources();
              scope.clear();
            }, function (error) { });
        };

        scope.finishtasks = function (username, password, complete) {
          var params = {
            tasks: [],
            type: 'finishbymanagement',
            aquiretaskswhenfinishing: scope.features.aquiretaskswhenfinishing
          };


          if (scope.authentication != null) {
            scope.authentication.username = username;
            scope.authentication.password = password;
            params.authentication = scope.authentication;
          }

          angular.forEach(scope.tasks, function (task) {
            params.tasks.push({
              id: task.Id
            });

          });

          if (complete) {
            params.type = 'finishcomplete';
          }

          bworkflowApi.execute('TaskUtilities', 'Finish', params).
            then(function (data) {
              if (scope.isAuthenticated(data) == false) {
                scope.authenticationfailed = true;
                return;
              }

              scope.updateSources();
              scope.clear();
            }, function (error) { });
        };

        scope.deletetasks = function () {
          var params = {
            tasks: []
          };


          if (scope.authentication != null) {
            params.authentication = scope.authentication;
          }

          angular.forEach(scope.tasks, function (task) {
            params.tasks.push({
              id: task.Id
            });

          });

          bworkflowApi.execute('TaskUtilities', 'Delete', params).
            then(function (data) {
              if (scope.isAuthenticated(data) == false) {
                scope.authenticationfailed = true;
                return;
              }

              scope.updateSources();
              scope.clear();
            }, function (error) { });
        };

        scope.clearstarttime = function () {
          var params = {
            tasks: []
          };


          if (scope.authentication != null) {
            params.authentication = scope.authentication;
          }

          angular.forEach(scope.tasks, function (task) {
            params.tasks.push({
              id: task.Id
            });

          });

          bworkflowApi.execute('TaskUtilities', 'ClearStartTime', params).
            then(function (data) {
              if (scope.isAuthenticated(data) == false) {
                scope.authenticationfailed = true;
                return;
              }

              scope.updateSources();
              scope.clear();
            }, function (error) { });

        };

        scope.saveorauthenticate = function () {
          if (scope.features.requireauthentication == false) {
            scope.save();
          } else {
            scope.authentication = {
              username: null,
              password: null,
              kioskmode: scope.features.kioskmode
            };

          }
        };

        scope.cancelauthenticate = function () {
          scope.authentication = null;
        };

        scope.save = function (username, password) {
          switch (scope.operation.type) {
            case 'reassign':
              scope.reassigntasks();
              break;
            case 'cancel':
              scope.canceltasks();
              break;
            case 'finish':
              scope.finishtasks(username, password);
              break;
            case 'finishcomplete':
              scope.finishtasks(username, password, true);
              break;
            case 'delete':
              scope.deletetasks();
              break;
            case 'clearstarttime':
              scope.clearstarttime();
              break;
          }

        };

        scope.$on('task-batch-operations.batch', function (event, args) {
          if (angular.isDefined(args.name)) {
            if (args.name != scope.name) {
              return;
            }
          }

          // this event allows us to do a few things. args is an array of objects
          // each of which models an action
          angular.forEach(args.actions, function (action) {
            switch (action.type) {
              case 'add':
                scope.handleAdd(action.data);
                scope.taskCount = 0; // prevent the UI from showing
                break;
              case 'remove':
                scope.handleRemove(action.data);
                scope.taskCount = 0; // prevent the UI from showing
                break;
              case 'user':
                // this is expected to be an odata membership row
                scope.assignment.type = action.data.type; // should be 0,1 or 2
                scope.assignment.touser = action.data.user;
                break;
              case 'operation':
                scope.operation = {
                  type: action.data
                };

                break;
              case 'process':
                scope.save();
            }

          });
        });
      }
    };

  }]);


bootstrapcompat.directive('photoManager', ['$timeout', '$filter',
  function ($timeout, $filter) {
    return {
      templateUrl: 'bootstrap/photo_manager.html',
      restrict: 'E',
      scope: {
        photoWidth: '=',
        existingPhotos: '=',
        photoIdField: '='
      },

      link: function (scope, element, attrs) {
        scope.photos = [];

        var addPhotoFile = function (file, cb) {
          window.SquareIT.BWorkflow.HandleUploads({
            maxWidth: scope.photoWidth,
            maxHeight: null,
            allowImage: true,
            files: [file]
          },
            function (files) {
              $timeout(function () {
                scope.photos = scope.photos.concat(files);

                // get a base64 representation of the image and let others
                // know about it so that they can upload it or do something
                // else with it
                var b64idx = files[0].data.src.indexOf('base64,');
                var b64 = files[0].data.src.substring(b64idx + 7);

                scope.$emit('photo-manager.phototaken', {
                  data: b64,
                  image: files[0]
                });


                if (cb) {
                  cb();
                }
              });
            });
        };

        scope.takephoto = function () {
          if (window.cordova && navigator.camera) {
            navigator.camera.getPicture(function (imageUri) {
              window.resolveLocalFileSystemURL(imageUri, function (fileEntry) {
                fileEntry.file(function (file) {
                  addPhotoFile(file, function () {
                    navigator.camera.cleanup();
                  });
                });
              });
            }, function (error) {
              $timeout(function () {
                alert('Errot taking photo\n' + error);
              });
            }, {
              // Some common settings are 20, 50, and 100
              quality: 50,
              destinationType: Camera.DestinationType.FILE_URI,
              // In this app, dynamically set the picture source, Camera or photo gallery
              sourceType: Camera.PictureSourceType.CAMERA,
              encodingType: Camera.EncodingType.JPEG,
              mediaType: Camera.MediaType.PICTURE,
              allowEdit: false,
              correctOrientation: false //Corrects Android orientation quirks
            });
          } else {
            element.find('.camera_input').click();
          }
        };

        scope.photoTaken = function (file) {
          if (file.files.length === 0) return;

          addPhotoFile(file.files[0]);
        };

        scope.removePhoto = function (photo) {
          if (confirm('Are you sure you want to delete this photo') == false) {
            return;
          }

          var query = {};
          query[scope.photoIdField] = photo[scope.photoIdField];

          var p = $filter('filter')(scope.photos, query, true);

          if (p.length == 0) {
            return;
          }

          // remove it from our list
          var index = scope.photos.indexOf(p[0]);
          scope.photos.splice(index, 1);

          // let others know they need to do something
          scope.$emit('photo-manager.photoremoved', {
            photo: p[0]
          });

        };

        scope.loadPhotos = function () {
          if (angular.isDefined(scope.existingPhotos) == false || scope.existingPhotos == null) {
            return;
          }

          angular.forEach(scope.existingPhotos, function (photo) {
            var image = new Image();

            image.onload = function () {
              var p = {
                data: {
                  src: this.src
                }
              };


              p[scope.photoIdField] = photo[scope.photoIdField];

              scope.photos = scope.photos.concat(p);
            };

            image.src = window.razordata.mediaprefix + 'MediaImage/' + photo[scope.photoIdField] + '.png';
          });
        };

        scope.loadPhotos();
      }
    };

  }]);


bootstrapcompat.directive('makeDeviceUrl', ['$timeout',
  function ($timeout) {
    return {
      restrict: 'A',
      priority: -1,
      link: function (scope, elt, attrs) {
        if (!!window.cordova == false) {
          // cordova not present, so assume we are in a normal web browser
          return;
        }

        elt.on('click', function (e) {
          e.preventDefault();

          $timeout(function () {
            var prefix = window.razordata.siteprefix;
            var href = elt.attr('href');

            href = href.replace(prefix, '');

            location.href = href;
          });
        });
      }
    };

  }]);



bootstrapcompat.directive('tooltip', [function () {
  return {
    restrict: 'A',
    scope: {
      tooltip: '='
    },

    link: function (scope, element, attrs) {
      $(element).tooltip(scope.tooltip);
    }
  };

}]);

bootstrapcompat.directive('orgChart', ['$q', '$timeout', '$window', '$document', function ($q, $timeout, $window, $document) {
  return {
    templateUrl: window.razordata.portalprefix + 'angulartemplates/bootstrap/org_chart.html',
    scope: {
      data: '=ngModel',
      allowDragDrop: '=?',
      allowEdit: '=?',
      allowAdd: '=?',
      allowRemove: '=?',
      fullScreenElement: '=?',
      chartManager: '=?'
    },

    restrict: 'E',
    link: function (scope, element, attrs) {
      scope.isfullscreen = false;
      scope.showAddRoot = true;

      // we are going to use a custom template to display our nodes, we set that up first
      OrgChart.templates.virtualmanager = Object.assign({}, OrgChart.templates.ula);
      OrgChart.templates.virtualmanager.img_0 = null;
      OrgChart.templates.virtualmanager.field_0 = '<text class="field_0" style="font-size: 20px;" fill="#000000" x="125" y="30" text-anchor="middle">{val}</text>';
      OrgChart.templates.virtualmanager.field_1 = '<text class="field_1" style="font-size: 20px;" fill="#009dec" x="125" y="55" text-anchor="middle">{val}</text>';
      OrgChart.templates.virtualmanager.field_2 = '<text class="field_2" style="font-size: 10px;" fill="#757575" x="125" y="70" text-anchor="middle">{val}</text>';

      if (angular.isDefined(scope.allowDragDrop) == false && angular.isDefined(attrs.enableDragDrop)) {
        // if we aren't given it from the model, see if its on as a straight attribute
        scope.allowDragDrop = attrs.enableDragDrop;
      } else {
        scope.allowDragDrop = false;
      }

      if (angular.isDefined(scope.allowEdit) == false && angular.isDefined(attrs.enableEdit)) {
        // if we aren't given it from the model, see if its on as a straight attribute
        scope.allowEdit = attrs.enableEdit;
      } else {
        scope.allowEdit = false;
      }

      if (angular.isDefined(scope.allowAdd) == false && angular.isDefined(attrs.enableAdd)) {
        // if we aren't given it from the model, see if its on as a straight attribute
        scope.allowAdd = attrs.enableAdd;
      } else {
        scope.allowAdd = false;
      }

      if (angular.isDefined(scope.allowRemove) == false && angular.isDefined(attrs.enableRemove)) {
        // if we aren't given it from the model, see if its on as a straight attribute
        scope.allowRemove = attrs.enableRemove;
      } else {
        scope.allowRemove = false;
      }

      scope.fullScreenElement = element;
      if (angular.isDefined(attrs.fullScreenElementSelector)) {
        scope.fullScreenElement = $(attrs.fullScreenElementSelector);
      }

      if (angular.isDefined(scope.chartManager) == false || scope.chartManager == null) {
        scope.chartManager = {};
      }

      scope.chartManager.getBucket = function (nodeId) {
        return scope.chart.get(nodeId);
      };
      scope.chartManager.removeBucket = function (nodeId) {
        scope.chart.removeNode(nodeId);
      };
      scope.chartManager.addBucket = function (bucket) {
        scope.chart.addNode(bucket);
      };
      scope.chartManager.updateBucket = function (bucket) {
        scope.chart.updateNode(bucket);
      };
      scope.chartManager.getData = function () {
        // the config.nodes isn't documented in their documentation, but it seems
        // like the only way to get at the data and it reflects edits/adds etc to the chart
        return scope.chart.config.nodes;
      };

      scope.zoom = function (amount) {
        scope.chart.zoom(amount);
      };

      scope.toggleFullscreen = function () {
        if (scope.isfullscreen == false) {
          scope.orginalHeight = $(element).find('.org-chart-canvas').height();
          $(element).find('.org-chart-canvas').height($(document).height() - $(element).find('.org-chart-toolbar').height());
          $(scope.fullScreenElement)[0].requestFullscreen();
        } else {
          $(element).find('.org-chart-canvas').height(scope.orginalHeight);
          $window.document.exitFullscreen();
        }
      };

      var fullscreenchange = function () {
        $timeout(function () {
          scope.isfullscreen = !scope.isfullscreen;
          scope.drawChart(scope.data);
        });
      };

      $document[0].addEventListener('fullscreenchange', fullscreenchange);
      scope.$on('$destroy', function () {
        $document[0].removeEventListener('fullscreenchange', fullscreenchange);
      });

      scope.onEdit = function (nodeId) {
        scope.$evalAsync(function () {
          scope.$emit('org-chart.edit', {
            bucketId: nodeId,
            manager: scope.chartManager
          });

        });
      };

      scope.onAdd = function (nodeId) {
        if (nodeId == null && scope.chart.config.nodes.length > 0) {
          return;
        }

        scope.$evalAsync(function () {
          scope.$emit('org-chart.add', {
            bucketId: nodeId,
            manager: scope.chartManager
          });

        });
      };

      scope.onRemove = function (nodeId) {
        scope.$evalAsync(function () {
          scope.$emit('org-chart.remove', {
            bucketId: nodeId,
            manager: scope.chartManager
          });

        });
      };

      scope.drawChart = function (newValue) {
        var chartConfig = {
          template: 'virtualmanager',
          enableDragDrop: scope.allowDragDrop,
          nodeMouseClickBehaviour: BALKANGraph.action.none,
          nodeBinding: {
            field_0: "name",
            field_1: "usersname",
            field_2: "rolename"
          }
        };



        if (scope.allowEdit || scope.allowAdd || scope.allowRemove) {
          var nodeMenu = {};

          if (scope.allowEdit) {
            nodeMenu.onedit = {
              text: 'Edit',
              icon: '<i class="icon-pencil"></i>',
              onClick: scope.onEdit
            };

          }

          if (scope.allowAdd) {
            nodeMenu.onadd = {
              text: 'Add',
              icon: '<i class="icon-plus-sign"></i>',
              onClick: scope.onAdd
            };

          }

          if (scope.allowRemove) {
            nodeMenu.onremove = {
              text: 'Remove',
              icon: '<i class="icon-remove"></i>',
              onClick: scope.onRemove
            };

          }
        }

        chartConfig.nodeMenu = nodeMenu;

        scope.chart = new OrgChart($(element).find('.org-chart-canvas')[0], chartConfig);

        angular.forEach(newValue, function (node) {
          scope.chart.add(node);
        });

        scope.showAddRoot = newValue.length == 0;

        scope.chart.draw(BALKANGraph.action.init);
      };

      scope.$watch('data', function (newValue, oldValue) {
        if (angular.isDefined(newValue) == false || newValue == null) {
          return;
        }

        scope.drawChart(newValue);
      });
    }
  };

}]);



bootstrapcompat.factory('angular-templates', [function () {
  var rootPath = '';
  if (window.razordata.environment == 'desktop') {
    rootPath = window.razordata.portalprefix + 'angulartemplates/';
  }

  return function (path) {
    return rootPath + path;
  };
}]);

bootstrapcompat.factory('map-tile-layer', [function () {
  return function (type) {
    switch (type) {
      case 'satellite':
      default:
        return L.tileLayer(
          //'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          'https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1Ijoic3RldmVmNTEiLCJhIjoiY2l4czd2MWl0MDdsMTJ3bWZ3cW8zbjV1cSJ9.x-CpNO9xfAv9FmLjpajsng', {
          maxNativeZoom: 18,
          maxZoom: 24
        });
    }


  };
}]);


// This utility function L-destroyer is used to destroy (remove()) a list of Leaflet objects
bootstrapcompat.factory('L-destroyer', [function () {
  return function (scope) {
    return function (items) {
      items.forEach(function (i) {
        if (typeof i === 'string') {// string means look in scope
          if (scope[i]) {
            scope[i].remove();
            delete scope[i];
          }
        } else if (angular.isArray(i)) {// array means destroy all elements and blank the array
          i.forEach(function (ii) {
            if (ii) {
              ii.remove();
            }
          });
          i.length = 0;
        } else if (typeof i === 'object') {// object - assume its a Leaflet object
          i.remove();
        }
      });
    };
  };
}]);

bootstrapcompat.factory('VmAdminClasses', [function () {
  function From(Class) {
    return function (dto) {
      return dto == null ? null : dto instanceof Class ? dto : new Class(dto);
    };
  }

  // Clients can attach bits to transient object and they will not get JSON.stringified or Ajax serialized
  function AddTransient(obj) {
    Object.defineProperty(obj, 'transient', {
      value: {},
      writable: false
    });

  }

  function SetOriginal(obj, original) {
    // Delete it if already defined ..
    delete obj.original;

    Object.defineProperty(obj, 'original', {
      configurable: true,
      value: original,
      writable: false
    });

  }

  function FloorPlan(dto) {
    this.load(dto);
    AddTransient(this);
  }
  FloorPlan.from = From(FloorPlan);
  FloorPlan.prototype.load = function (dto) {
    this.imageurl = dto.imageurl || dto.ImageURL;
    this.indooratlasfloorplanid = dto.indooratlasfloorplanid || dto.IndoorAtlasFloorPlanId;
    this.topleft = dto.topleft ? L.latLng(dto.topleft) : L.latLng(dto.TopLeftLatitude, dto.TopLeftLongitude);
    this.topright = dto.topright ? L.latLng(dto.topright) : L.latLng(dto.TopRightLatitude, dto.TopRightLongitude);
    this.bottomleft = dto.bottomleft ? L.latLng(dto.bottomleft) : L.latLng(dto.BottomLeftLatitude, dto.BottomLeftLongitude);
    SetOriginal(this, dto);
  };
  FloorPlan.prototype.getLatLngBounds = function () {
    var bottomRight = L.latLng(this.topright.lat - this.topleft.lat + this.bottomleft.lat, this.topright.lng - this.topleft.lng + this.bottomleft.lng);
    var bounds = L.latLngBounds(this.topleft, this.topright).extend(this.bottomleft).extend(bottomRight);
    return bounds;
  };
  FloorPlan.prototype.getCenter = function () {
    var bottomRight = L.latLng(this.topright.lat - this.topleft.lat + this.bottomleft.lat, this.topright.lng - this.topleft.lng + this.bottomleft.lng);
    return L.latLng(
      (this.topleft.lat + this.topright.lat + this.bottomleft.lat + bottomRight.lat) / 4.0,
      (this.topleft.lng + this.topright.lng + this.bottomleft.lng + bottomRight.lng) / 4.0);
  };


  function GpsLocation(dto) {
    this.load(dto);
    AddTransient(this);
  }
  GpsLocation.from = From(GpsLocation);
  GpsLocation.prototype.load = function (dto) {
    angular.extend(this, {
      __isnew: true
    },
      dto);
    this.pos = L.latLng(dto.pos);
    SetOriginal(this, dto);
  };

  GpsLocation.type = {
    GPS: 0, // Location is based on GPS
    IndoorAtlas: 1, // Location is based on Indoor Atlas
    StaticBeacon: 2, // Location is of a Static Beacon (of which physical location is known)
    Task: 3, // Location is of a specific Task (of which physical location is known)
    Building: 4 // Location of a Facility Building
  };

  function Beacon(dto) {
    this.load(dto);
    AddTransient(this);
  }
  Beacon.from = From(Beacon);
  Beacon.prototype.load = function (dto) {
    angular.extend(this, dto);
    this.staticlocation = GpsLocation.from(dto.staticlocation);
    SetOriginal(this, dto);
  };
  Beacon.prototype.hasMoved = function () {
    if (!this.original.staticlocation) {
      return this.staticlocation ? true : false;
    }
    return !this.staticlocation.pos.equals(this.original.staticlocation.pos);
  };
  Beacon.prototype.undoMove = function () {
    this.staticlocation = GpsLocation.from(this.original.staticlocation);
    if (this.transient.marker) {
      this.transient.marker.setLatLng(this.staticlocation.pos);
    }
  };
  Beacon.prototype.nameAndId = function () {
    var html = '';
    if (this.name) {
      html += '<div><b>' + this.name + '</b></div>';
    }
    html += '<div class="muted">' + this.id + '</div>';
    if (this.staticlocation) {
      html += '<div class="muted">' + this.staticheightfromfloor + 'm</div>';
    }
    return html;
  };

  return {
    FloorPlan: FloorPlan,
    floorPlan: FloorPlan.from,
    Beacon: Beacon,
    beacon: Beacon.from,
    GpsLocation: GpsLocation,
    gpsLocation: GpsLocation.from
  };

}]);

// Use this service to create an "API" object which can be used to call methods on a Directive from a Controller
// Ordinarily you could use a simple {} object to do this, but Angular does not gaurantee order of construction and
// you can end up with a client calling methods on a Directive which have not been created yet - this factory
// gets around that by producing Promises for functions that are resolved when the real Directive declares its Api
//
// controller:
//   $scope.myDirectiveApi = directiveApi();
//   ...
//   $scope.myDirectiveApi.getMap().then(function(map) { .. do something with map });
//   $scope.myDirectiveApi.doSomething().then(function(fn) { fn(1,2,3); } );
//
// HTML:
//   <my-directive extern='myDirectiveApi'></my-directive>
//
// directive:
//   scope: {
//      extern: '='
//   }, link: function(scope) {
//     var map = L.Map();
//     if (scope.extern) {
//       scope.extern.getMap(function() { return map; } )
//       scope.extern.doSomething(function(a, b, c) { return a + b + c; })
//     }
bootstrapcompat.factory('directiveApi', ['$q', function ($q) {
  return function () {
    var api = new Object();
    var deferreds = new Object();

    var proxy = new Proxy(api, {
      get: function (target, name) {
        var methodProxy = api[name];
        var deferred = deferreds[name];

        if (!angular.isDefined(methodProxy)) {
          deferred = $q.defer();
          methodProxy = new Proxy(function () { }, {
            apply: function (target, ctx, args) {
              if (args.length == 1) {
                return deferred.resolve(args[0]);
              } else {
                return deferred.promise;
              }
            }
          });


          api[name] = methodProxy;
          deferreds[name] = deferred;
        }
        return methodProxy;
      }
    });

    return proxy;
  };
}]);

bootstrapcompat.directive('leafletMap', ['$q', '$timeout', 'angular-templates', 'map-tile-layer', 'L-destroyer', 'directiveApi', function ($q, $timeout, angularTemplates, mapTileLayer, Ldestroyer, directiveApi) {
  return {
    templateUrl: function () {
      return angularTemplates('leaflet-map.html');
    },
    scope: {
      extern: '='
    },

    restrict: 'E',
    link: function (scope, element, attrs) {
      var Ldestroy = Ldestroyer(scope);

      var divMap = element.find('#map')[0];
      var map = new L.Map(divMap, {
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: 'topleft'
        },

        contextmenu: true
      });


      scope.$on('$destroy', function () {
        map.remove();
      });

      // Fix for exiting fullscreen mode does not recentre map correctly (probably related to Bootstrap tab issue)
      map.on('exitFullscreen', function () {
        map.invalidateSize(false);
      });

      var tileLayer = mapTileLayer().addTo(map);

      // Declare external API for controllers to communicate with us
      if (!angular.isDefined(scope.extern)) {
        scope.extern = directiveApi();
      }

      var resizePoll = function () {
        map.invalidateSize(false);

        var mapSize = map.getSize();
        if (mapSize.x === 0 || mapSize.y === 0) {
          $timeout(resizePoll, 1000);
        } else {
          scope.extern.getMap(map);
        }
      };

      scope.$watch('extern', function (newValue) {
        map.whenReady(resizePoll);
      });

      scope.$on('invalidatemap', function (event, args) {
        resizePoll();
      });

      map.fitWorld();
    }
  };

}]);

bootstrapcompat.directive('leafletFloorPlan', ['$q', '$timeout', 'angular-templates', 'map-tile-layer', 'L-destroyer', 'directiveApi', function ($q, $timeout, angularTemplates, mapTileLayer, Ldestroyer, directiveApi) {
  return {
    templateUrl: function () {
      return angularTemplates('leaflet-floorplan.html');
    },
    scope: {
      floorplan: '=',
      fitBounds: '=',
      extern: '='
    },

    restrict: 'E',
    link: function (scope, element, attrs) {
      var Ldestroy = Ldestroyer(scope);

      if (angular.isUndefined(scope.extern)) {
        scope.extern = directiveApi();
      }

      var getMap = function (cb) {
        scope.extern.getMap().then(cb);
      };

      var floorplanMoved = function () {
        if (scope.floorPlanImage) {
          scope.floorPlanImage.reposition(scope.floorplan.topleft, scope.floorplan.topright, scope.floorplan.bottomleft);
        }
      };

      scope.$watch('floorplan.imageurl', function () {
        if (scope.floorPlanImage) {
          scope.floorPlanImage.src = scope.floorplan.imageurl;
        }
      });

      scope.$watch('floorplan.topleft.lat', floorplanMoved);
      scope.$watch('floorplan.topleft.lng', floorplanMoved);
      scope.$watch('floorplan.topright.lat', floorplanMoved);
      scope.$watch('floorplan.topright.lng', floorplanMoved);
      scope.$watch('floorplan.bottomleft.lat', floorplanMoved);
      scope.$watch('floorplan.bottomleft.lng', floorplanMoved);

      scope.$watch('floorplan', function (newValue, oldValue) {
        Ldestroy(['floorPlanImage']);

        if (newValue) {
          var topleft = scope.floorplan.topleft;
          var topright = scope.floorplan.topright;
          var bottomleft = scope.floorplan.bottomleft;

          getMap(function (map) {
            scope.floorPlanImage = L.imageOverlay.rotated(scope.floorplan.imageurl, topleft, topright, bottomleft, {
              opacity: 0.8,
              interactive: true
            }).
              addTo(map);

            if (scope.fitBounds) {
              scope.floorPlanImage.on('load', function (ev) {
                var bounds = scope.floorplan.getLatLngBounds();
                map.fitBounds(bounds, angular.isArray(scope.fitBounds) ? {
                  padding: scope.fitBounds
                } :
                  scope.fitBounds);
              });
            }
          });
        }
      });
    }
  };

}])

  // to replace \n to <br/>
  .filter('newlines', function () {
    return function (text) {
      if (text)
        return text.replace(/\n/g, '<br/>');
      return '';
    };
  }).

  factory('countdownService', ['$interval', function ($interval) {
    return function (start, options) {
      var timer;

      options = angular.extend({
        stopAtZero: true
      },
        options || {});

      var model = {
        text: '',
        start: start,
        stopAtZero: options.stopAtZero,
        end: options.end,
        units: options.units,
        max: options.max,
        digits: options.digits,
        destroy: function () {
          cancelTimer();
        }
      };


      function cancelTimer() {
        if (timer) {
          $interval.cancel(timer);
          timer = null;
        }
      }

      function update() {
        model.timespan = countdown(model.start, model.end, model.units, model.max, model.digits);
        if (model.timespan.value >= 0 && model.stopAtZero) {
          cancelTimer();
          if (options.fnZero) {
            options.fnZero(model);
          }
        } else {
          model.text = model.timespan.toString();
        }
        if (options.fnTick) {
          options.fnTick(model);
        }
      }

      timer = $interval(update, 1000);

      return model;
    };
  }]).

  directive('amCountdown', ['countdownService', function (countdownService) {
    return {
      template: '<span>{{ countdown }}</span>',
      restrict: 'E',
      require: "ngModel",
      scope: {
        model: '=ngModel',
        options: '=amCountDownOptions'
      },

      link: function (scope, element, attrs) {
        scope.options = scope.options || {};
        var optionsFnTick = scope.options.fnTick;
        var options = angular.extend(scope.options, {
          fnTick: function (model) {
            scope.countdown = model.text;
            if (optionsFnTick) {
              optionsFnTick(model);
            }
          }
        });

        var countdown = countdownService(scope.model, options);
        scope.$on('$destroy', countdown.destroy);
      }
    };

  }]);

  bootstrapcompat.factory('jsonDocumentDecoder', ['moment', function (moment) {
    function JTokenConverter_Null() {
        this.canConvert = typeToConvert => typeToConvert == "Null";
        this.decode = (element, typeToConvert, context) => null;
    }
  
    function JTokenConverter_Boolean() {
        this.canConvert = typeToConvert => typeToConvert == "Boolean";
        this.decode = (element, typeToConvert, context) => element == null ? null : element.toString().toLowerCase() == "true";
    }
  
    function JTokenConverter_Number() {
        this.canConvert = typeToConvert => (["SByte", "Byte", "Int16", "UInt16", "Int32", "Int64", "UInt32", "UInt64", "Single", "Double", "Decimal"]).includes(typeToConvert);
        this.decode = (element, typeToConvert, context) => element == null ? null : new Number(element);
    }
  
    function JTokenConverter_DateTime() {
        this.canConvert = typeToConvert => typeToConvert == "DateTime";
        this.decode = (element, typeToConvert, context) => moment(element);
    }
  
    function JTokenConverter_TimeSpan() {
        this.canConvert = typeToConvert => typeToConvert == "TimeSpan";
        this.decode = (element, typeToConvert, context) => moment.duration(element);
    }
  
    function JTokenConverter_String() {
        this.canConvert = typeToConvert => typeToConvert == "String" || typeToConvert == "Guid" || typeToConvert == "Char";
        this.decode = (element, typeToConvert, context) => element.toString();
    }
  
    function JTokenConverter_ArrayOf() {
        this.canConvert = typeToConvert => typeToConvert.startsWith("ArrayOf_") || typeToConvert.startsWith("ListOf_");
        this.decode = (element, typeToConvert, context) => {
            let result = [];
            context.nodeCreated(result);
            element.map(e => {
                let decoder = new JsonDocumentDecoder(e, context);
                result.push(decoder.decode(null));
            })
            return result;
        }
    }
  
    function JTokenConverter_DictionaryOf_String() {
        this.canConvert = typeToConvert => typeToConvert.startsWith("DictionaryOf_String_");
        this.decode = (element, typeToConvert, context) => {
            let result = {};
            let decoder = new JsonDocumentDecoder(element, context);
            context.nodeCreated(result);
            Object.keys(element).reduce((obj, key) => {
                obj[key] = decoder.decode(key);
                return obj;
            }, result);
            return result;
        }
    }
  
    function JTokenConverter_DictionaryOf() {
        this.canConvert = typeToConvert => typeToConvert.startsWith("DictionaryOf_");
        this.decode = (element, typeToConvert, context) => {
            let result = new Map();
            context.nodeCreated(result);
            element.map(e => {
                let decoder = new JsonDocumentDecoder(e, context);
                let dictionaryEntry = decoder.decode(null);
                result.set(dictionaryEntry.key, dictionaryEntry.value);
            })
            return result;
        }
    }
  
    function JTokenConverter_DictionaryEntry() {
        this.canConvert = typeToConvert => typeToConvert == "DictionaryEntry";
        this.decode = (element, typeToConvert, context) => {
            let decoder = new JsonDocumentDecoder(element, context);
            return {
                key: decoder.decode("Key"),
                value: decoder.decode("Value")
            }
        }
    }
  
    function JTokenConverter_Object() {
        this.canConvert = typeToConvert => true;
        this.decode = (element, typeToConvert, context) => {
            if (typeof element == "string") {
                return element;
            }
            let decoder = new JsonDocumentDecoder(element, context);
            let result = {
                $lingo_type: typeToConvert
            };
            context.nodeCreated(result);
            Object.keys(element).reduce((obj, key) => {
                obj[key] = decoder.decode(key);
                return obj;
            }, result);
            return result;
        }
    }
  
    let converters = [];
    converters.push(new JTokenConverter_Null());
    converters.push(new JTokenConverter_Boolean());
    converters.push(new JTokenConverter_Number());
    converters.push(new JTokenConverter_String());
    converters.push(new JTokenConverter_DateTime());
    converters.push(new JTokenConverter_TimeSpan());
    converters.push(new JTokenConverter_ArrayOf());
    converters.push(new JTokenConverter_DictionaryOf_String());
    converters.push(new JTokenConverter_DictionaryOf());
    converters.push(new JTokenConverter_DictionaryEntry());
    converters.push(new JTokenConverter_Object());
  
    function JTokenConverter() {
    }
  
    JTokenConverter.prototype.decode = function (element, typeToConvert, context) {
        let converter = converters.find(converter => converter.canConvert(typeToConvert));
        return converter.decode(element, typeToConvert, context);
    }
  
    function JsonCoderTypeCache(typeCacheElement) {
        this.typeCacheElement = typeCacheElement;
    }
  
    function JsonCoderObjectCache(objectCacheElement, typeCache) {
        this.typeCache = typeCache;
        this.objectCacheElement = objectCacheElement;
        this.idToObject = {};
    }
  
    JsonCoderObjectCache.prototype.getObject = function (id) {
        let object = this.idToObject[id];
        if (object === undefined) {
            let jArray = this.objectCacheElement[id];
            if (jArray === undefined) {
                return null;
            }
  
            let converter = new JTokenConverter();
            let context = new JsonCoderContext(this.typeCache, this);
            context.nodeCreated = (node) => this.idToObject[id] = node;
            object = converter.decode(jArray[1], jArray[0], context);
            this.idToObject[id] = object;
        }
        return object;
    }
  
    function JsonCoderContext(typeCache, objectCache) {
        this.typeCache = typeCache;
        this.objectCache = objectCache;
    }
  
    function JsonDocumentDecoder(jsonDocument, coderContext) {
        if (coderContext === undefined) {
            this.element = jsonDocument["RootValue"];
            this.typeCache = new JsonCoderTypeCache(jsonDocument["TypeCache"]);
            this.objectCache = new JsonCoderObjectCache(jsonDocument["ObjectCache"], this.typeCache);
            this.context = new JsonCoderContext(this.typeCache, this.objectCache);
        } else {
            this.element = jsonDocument;
            this.context = coderContext;
            this.objectCache = coderContext.objectCache;
            this.typeCache = coderContext.typeCache;
        }
    }
  
    JsonDocumentDecoder.prototype.decode = function (name, fnDefault = () => null) {
        let valueElement = this.element;
        if (name != null) {
            valueElement = this.element[name];
        }
        if (valueElement == null) {
            return fnDefault();
        }
  
        let valueArray = valueElement;
        if (valueArray.length == 1) {
            let id = valueArray[0].toString();
            return this.objectCache.getObject(id);
        }
  
        let typeToConvert = valueArray[0];
        let converter = new JTokenConverter();
        return converter.decode(valueArray[1], typeToConvert, this.context);
    }
  
    return {
        JTokenConverter: JTokenConverter,
        JsonCoderTypeCache: JsonCoderTypeCache,
        JsonCoderObjectCache: JsonCoderObjectCache,
        JsonCoderContext: JsonCoderContext,
        JsonDocumentDecoder: JsonDocumentDecoder
    }
  }]);  