import { generateCombGuid, makeGuidWithOffset } from '../../../utils/util';
import ngmodule from '../../ngmodule';
import { IOfflineWorkingDocument } from './types';
import _ from 'lodash';
import moment from 'moment';
import { IHandlesQuestionTypes, IQuestionDefinition, ICheckboxQuestionDefinition, IDateTimeQuestionDefinition, IFreeTextQuestionDefinition, ILabelQuestionDefinition, IMultiChoiceQuestionDefinition, INumberQuestionDefinition, ISectionDefinition, ITemperatureProbeQuestionDefinition, IMultiChoiceItemDefinition, DesignQuestion, DesignCheckboxQuestion, DesignDateTimeQuestion, DesignFreeTextQuestion, DesignLabelQuestion, DesignMultiChoiceQuestion, DesignNumberQuestion, DesignSection, DesignTemperatureProbeQuestion } from 'lingo-api';
import { ILingoAnswer } from './lingojs/lingo-answers-context';
import { IPresentedQuestion, IPresentedCheckboxQuestion, IPresentedDateTimeQuestion, IPresentedFreeTextQuestion, IPresentedLabelQuestion, IPresentedMultiChoiceQuestion, IPresentedNumberQuestion, IPresentedSection, IPresentedTemperatureProbeQuestion, IPresented, IPresentedMultiChoiceItem } from './presented-types';

const QuestionDirectives: IHandlesQuestionTypes<string> = {
	[`checkbox`]: `question-checkbox`,
	[`dateTime`]: `question-date-time`,
	[`freeText`]: `question-free-text`,
	['label']: `question-label`,
	[`multiChoice`]: `question-multi-choice`,
	[`number`]: `question-number`,
	[`section`]: `question-section`,
	[`temperatureProbe`]: `question-temperature-probe`
}

export class OfflineQuestionRenderer implements IHandlesQuestionTypes<(definition: any, lastAnswers: ILingoAnswer[]) => any> {
	static $inject = [`$interpolate`, `$parse`];

	constructor(
		private $interpolate: ng.IInterpolateService,
		private $parse: ng.IParseService,
		public workingDocument: IOfflineWorkingDocument) {

	}

	private interpolate(value: string): string {
		let fn = this.$interpolate(value, false, undefined, true);
		return fn(this.workingDocument.interpolateContext);
	}

	private getValidationErrors(definition: IQuestionDefinition): string[] {
		let questionReasons = (this.workingDocument.programContext.invalidReasons?.reasons || []).filter(reason => reason.questionName === definition.name);
		return questionReasons.map(reason => reason.reason);
	}

	private convertDefinition(definition: DesignQuestion): IPresentedQuestion {
		let presentedId = generateCombGuid()
		let presented: IPresentedQuestion = {
			definition: definition,
			AllowNotes: definition.allowNotes || false,
			HidePrompt: definition.hidePrompt || false,
			HtmlPresentation: {
				Alignment: definition.htmlPresentation?.alignment || 0,
				Clear: definition.htmlPresentation?.clear || 0,
				Layout: definition.htmlPresentation?.layout || 0,
				CustomClass: definition.htmlPresentation?.customClass || ``
			},
			IsAnswerable: true,
			Note: this.interpolate(definition.note || ``),
			PresentedType: definition.questionType === `section` ? `Section` : `Question`,
			Prompt: this.interpolate(definition.prompt || ``),
			QuestionType: definition.questionType,
			Visible: definition.visible || true,
			Directive: QuestionDirectives[definition.questionType],
			RootId: this.workingDocument.workingDocumentId,
			Name: definition.name ?? ``,
			PresentedId: presentedId,
			Id: presentedId,				// Yes, this is same as PresentedId
			QuestionId: makeGuidWithOffset(this.workingDocument.workingDocumentId, definition.id),
			ValidationErrors: this.getValidationErrors(definition),
			presentedDateUtc: moment().utc()
		}
		return presented;
	}

	private presentQuestion(presented: any, lastAnswers: ILingoAnswer[]): any {
		// To support "previous" (ie going back) in a checklist we must populate the previous answer
		if (lastAnswers.length > 0) {
			let la = lastAnswers.shift();
			Object.assign(presented.Answer, la?.answer);
		}
		// Keep track of all Presented questions so we can marry them up to their Answers
		this.workingDocument.freezer.presentQuestion(presented);
		return Object.assign({}, presented);			// Return a copy so the client can do whatever it wants to the Presented object without messing us up
	}

	checkbox(definition: DesignCheckboxQuestion, lastAnswers: ILingoAnswer[]): IPresentedCheckboxQuestion {
		let pq: IPresentedCheckboxQuestion = {
			...this.convertDefinition(definition),
			definition,
			Answer: {
				Value: definition.defaultAnswer ?? null
			}
		}
		return this.presentQuestion(pq, lastAnswers);
	}

	dateTime(definition: DesignDateTimeQuestion, lastAnswers: ILingoAnswer[]): IPresentedDateTimeQuestion {
		let pq: IPresentedDateTimeQuestion = {
			...this.convertDefinition(definition),
			definition,
			Answer: {
				DateTime: definition.defaultAnswer ?? null
			}
		}
		return this.presentQuestion(pq, lastAnswers);
	}


	freeText(definition: DesignFreeTextQuestion, lastAnswers: ILingoAnswer[]): IPresentedFreeTextQuestion {
		let pq: IPresentedFreeTextQuestion = {
			...this.convertDefinition(definition),
			definition,
			AllowMultiline: definition.allowMultiline ?? false,
			Answer: {
				Text: definition.defaultAnswer ?? null
			}
		}
		return this.presentQuestion(pq, lastAnswers);
	}

	label(definition: DesignLabelQuestion, lastAnswers: ILingoAnswer[]): IPresentedLabelQuestion {
		let pq: IPresentedLabelQuestion = {
			...this.convertDefinition(definition),
			definition,
			Answer: {
			}
		}
		return this.presentQuestion(pq, lastAnswers);
	}

	multiChoice(definition: DesignMultiChoiceQuestion, lastAnswers: ILingoAnswer[]): IPresentedMultiChoiceQuestion {
		let pq: IPresentedMultiChoiceQuestion = {
			...this.convertDefinition(definition),
			definition,
			AllowMultiSelect: definition.allowMultiSelect || false,
			DisplayFormat: definition.displayFormat || `List`,
			Choices: (definition.choices as IMultiChoiceItemDefinition[]).map(c => ({
				Id: c.id,
				Value: c.value,
				Display: c.display ?? c.value
			})) || [],
			Answer: {
				Selected: _.chain(definition.defaultAnswer || [])
					.map(c => [c.id, true])
					.fromPairs()
					.value()
			}
		}
		return this.presentQuestion(pq, lastAnswers);
	}

	number(definition: DesignNumberQuestion, lastAnswers: ILingoAnswer[]): IPresentedNumberQuestion {
		let pq: IPresentedNumberQuestion = {
			...this.convertDefinition(definition),
			definition,
			Answer: {
				Number: definition.defaultAnswer ?? null
			},
			ShowKeypad: definition.keypadType !== `none`,
			UseDecimalKeypad: definition.keypadType === `decimal`,
			Step: definition.step ?? 1
		}
		return this.presentQuestion(pq, lastAnswers);
	}

	section(definition: DesignSection, lastAnswers: ILingoAnswer[]): IPresentedSection {
		// Note, we don't pass Section through this.presentQuestion since Sections are not Questions, they contain questions
		return {
			...this.convertDefinition(definition),
			definition,
			Children: (definition.children || []).map(childDefinition => {
				return this.makePresented(childDefinition as DesignQuestion, lastAnswers);
			}),
			AllowFilterByCategory: false,			// TO BE DONE
			ChildCategories: [],// TO BE DONE
			datasource: ``,// TO BE DONE
			ngcontroller: ``,// TO BE DONE
			enabledeepedit: false// TO BE DONE
		}
	}

	temperatureProbe(definition: DesignTemperatureProbeQuestion, lastAnswers: ILingoAnswer[]): IPresentedTemperatureProbeQuestion {
		let pq: IPresentedTemperatureProbeQuestion = {
			...this.convertDefinition(definition),
			definition,
			Answer: {
				Celcius: definition.defaultAnswer ?? null
			},
			AllowedProbeProviders: definition.allowedProbeProviders ?? null,
			AllowedProbeSerialNumbers: definition.allowedProbeSerialNumbers ?? null,
			DisconnectProbeTimeout: definition.disconnectProbeTimeout ?? null,
			DisplayAsFahrenheit: definition.displayAsFahrenheit ?? false,
			MaximumPassReading: definition.maximumPassReading ?? null,
			MinimumPassReading: definition.minimumPassReading ?? null,
			ProbeOutOfRangeTimeout: definition.probeOutOfRangeTimeout ?? null,
			RefreshPeriod: definition.refreshPeriod ?? null,
			TemperatureReading: definition.readingType == 'realtime' ? `Realtime` : `MaximumReached`
		}
		return this.presentQuestion(pq, lastAnswers);
	}

	makePresented(question: DesignQuestion, lastAnswers: ILingoAnswer[]): IPresented {
		let fn = this[question.questionType];
		if (fn == null) {
			throw new Error(`Invalid question type ${question.questionType}`);
		}

		return fn.bind(this)(question as any, lastAnswers);
	}
}

export type CreateOfflineQuestionRenderer = (workingDocument: IOfflineWorkingDocument) => OfflineQuestionRenderer;

ngmodule.factory(`createOfflineQuestionRenderer`, [
	`$interpolate`,
	`$parse`,
	function (
		$interpolate: ng.IInterpolateService,
		$parse: ng.IParseService
	): CreateOfflineQuestionRenderer {
		return (workingDocument: IOfflineWorkingDocument) => {
			return new OfflineQuestionRenderer($interpolate, $parse, workingDocument);
		}
	}
]);