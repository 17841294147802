'use strict';

import './index.scss';
import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { StringNullableChain } from 'lodash';
import { ButtonController } from '../vm-button';
import { createPopper, Instance, beforeWrite } from '@popperjs/core';
import { IVMRegisteredComponent, IVMRegisteredComponentProvider } from '../../services/vm-registered-component-provider';


class DropDownPanelController extends ControllerBase implements IVMRegisteredComponent {
    public name: string;

    public prompt: string;
    public icon: string;
    public size: string;
    public fillMode: string;
    public color: string;
    public rounded: string;
    public disabled: boolean;
    public autoClose: boolean;
    public closeOnPanelClick: boolean;

    private kendoButton?: kendo.ui.Button;

    protected kendoDropButton?: kendo.ui.DropDownButton;

    protected popper: Instance | null;
    protected dropDownElement: ng.IRootElementService | null;

    static $inject = ['$element', '$timeout', '$document', 'vmRegisteredComponentProvider'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService, protected $document: ng.IDocumentService, protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider) {
        super();

        this.name = '';

        this.prompt = '';
        this.icon = '';
        this.size = '';
        this.fillMode = '';
        this.color = '';
        this.rounded = '';
        this.disabled = false;
        this.autoClose = true;
        this.closeOnPanelClick = false;

        this.popper = null;
        this.dropDownElement = null;

    }

    getComponentType(): string {
        return 'Popup';
    }

    protected valuesFromHtml() : void {
        if (this.prompt == undefined) {
            this.prompt = this.valueFromHtml("prompt", '', this.$element);
        }

        if (this.icon == undefined) {
            this.icon = this.valueFromHtml("icon", '', this.$element);
        }

        if (this.size == undefined) {
            this.size = this.valueFromHtml("size", '', this.$element);
        }

        if (this.fillMode == undefined) {
            this.fillMode = this.valueFromHtml("fill-mode", '', this.$element);
        }

        if (this.color == undefined) {
            this.color = this.valueFromHtml("color", '', this.$element);
        }

        if (this.rounded == undefined) {
            this.rounded = this.valueFromHtml("rounded", '', this.$element);
        }

        if (this.autoClose == undefined) {
            this.autoClose = this.valueFromHtml("auto-close", true, this.$element);
        }

        if (this.closeOnPanelClick == undefined) {
            this.closeOnPanelClick = this.valueFromHtml("close-on-panel-click", false, this.$element);
        }

    }

    $onInit(): void {
        this.valuesFromHtml();

        if(this.name && this.name != '')
        {
            this.vmRegisteredComponentProvider.register(this);
        }

        this.$timeout(() => {
            let config: kendo.ui.ButtonOptions = {} ;

            if(this.icon != '' && this.icon != undefined)
            {
                config.icon = this.icon;
            }

            if(this.size != '' && this.size != undefined)
            {
                config.size = this.size;
            }

            if(this.fillMode != '' && this.fillMode != undefined)
            {
                config.fillMode = this.fillMode;
            }

            if(this.color != '' && this.color != undefined)
            {
                config.themeColor = this.color;
            }

            if(this.rounded != '' && this.rounded != undefined)
            {
                config.rounded = this.rounded;
            }

            this.kendoButton = this.$element.find('button').kendoButton(config).data('kendoButton');

            this.kendoButton?.enable(!this.disabled);
        });         

        this.dropDownElement = this.$element.find('.dropdown-panel');
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if(changes.disabled && changes.disabled.currentValue != undefined && this.kendoDropButton) {
            (this.kendoDropButton as any).enable(!changes.disabled.currentValue);
        }
    }

    handleDocumentClick($event: any) : void {
        $event.data.$timeout(() => {
            $event.data.hide();
        });
    }

    hanldeInternalClick($event: any): void {
        if ($event == null) {
            return;
        }

        $event.stopPropagation();
    }

    toggleDropDownPanel(): void {
        if(this.popper == null)
        {
            this.show(this.$element.find('.content')[0]);
            return;
        }

        this.hide();
    }

    show(attachTo: HTMLElement): void {
        if (this.dropDownElement == null) {
            return;
        }

        // we hook onto the main HTML element so that clicks off of the panel close us
        if(this.autoClose)
        {
            this.$document.on('click', this, this.handleDocumentClick);
        }

        const sameWidth = {
            name: "sameWidth",
            enabled: true,
            phase: beforeWrite,
            requires: ["computeStyles"],
            fn: ( data: any ) => {
                //data.state.styles.popper.width = `${data.state.rects.reference.width}px`;
            },
            effect: ( data: any ) => {
                //data.state.elements.popper.style.width = `${data.state.elements.reference.offsetWidth}px`;
            }
        };

        this.popper = createPopper(attachTo, this.dropDownElement[0], {
            placement: 'bottom-start',
            strategy: 'fixed',
            modifiers: [sameWidth]
        });

        this.dropDownElement[0].setAttribute('data-show', '');
        this.dropDownElement.addClass('show');

        this.dropDownElement.addClass('showing'); // this animates it on show

        this.popper.update();
    }

    hide(): void {
        if (this.dropDownElement == null || this.popper == null) {
            return;
        }

        this.dropDownElement[0].removeAttribute('data-show');
        this.dropDownElement.removeClass('show');
        this.popper.destroy();
        this.popper = null;

        if(this.autoClose)
        {
            this.$document.off('click', this.handleDocumentClick);
        }
    };

    insidePanelClick(): void {
        if(!this.closeOnPanelClick)
        {
            return;
        }

        this.hide();
    }
}

app.component('vmDropdownPanel', {
    template: require('./template.html').default,
    transclude: true,
    bindings: {
        name: '@',
        prompt: '<',
        icon: '<',
        size: '<',
        fillMode: '<',
        color: '<',
        rounded: '<',
        choices: '<',
        disabled: '<',
        autoClose: '<',
        closeOnPanelClick: '<'
    },
    controller:  DropDownPanelController
});