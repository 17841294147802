import { JsonTree } from '@virtual-mgr/json-tree';

type Popsicle = any;
export interface IFreezable {
    readonly name: string;
    freeze(): Popsicle;
    unfreeze(popsicle: Popsicle, first: boolean, last: boolean): void;
}

interface INamedPopsicle {
    name: string;
    popsicle: Popsicle;
}

interface IFrozenState {
    frozen: INamedPopsicle[];
}

export class LingoRuntimeContext {
    private _stack: any[] = [];
    private _freezables: { [name: string]: IFreezable } = Object.create(null);
    public jsonTree: JsonTree = new JsonTree();

    getState(): any {
        return {
            _stack: this._stack
        }
    }

    setState(state: any): void {
        this._stack = state._stack;

        // Popping will restore the last pushed state
        this.pop(1);
    }

    freezeState(): IFrozenState {
        return {
            frozen: Object.keys(this._freezables).map(name => {
                return {
                    name,
                    popsicle: this._freezables[name].freeze()
                };
            })
        };
    }

    unfreezeState(frozenState: IFrozenState, first: boolean, last: boolean): void {
        frozenState.frozen.forEach(f => {
            let freezable = this._freezables[f.name];
            if (freezable) {
                freezable.unfreeze(f.popsicle, first, last);
            }
        })
    }

    push(): void {
        let str = this.jsonTree.stringify(this.freezeState())
        this._stack.push(str);
    }

    pop(count: number): void {
        for (var i = 0; i <= this._stack.length - count; i++) {
            let str = this._stack[i];
            let popsicle = this.jsonTree.parse(str);
            this.unfreezeState(popsicle, i === 0, i === this._stack.length - count);
        }
        while (count--) {
            this._stack.pop();
        }
    }

    register(freezable: IFreezable) {
        this._freezables[freezable.name] = freezable;
    }
}

