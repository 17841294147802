'use strict';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { IChecklistPlayer, IPublishedResourceCompoundId } from '../../services/bworkflow-api/types';

export interface IEmbeddedPlayer {
    loadFromNew(compoundId: IPublishedResourceCompoundId, revieweeId: string, args: string): void;
    loadFromExisting(workingDocumentId: string): void;
    next(): void;
    back(): void;
}

enum EmbeddedPlayerState {
    Unitialized = "Unitialized",
    Loading = "Loading",
    Presenting = "Presenting",
    Navigating = "Navigating",
    FinishedWithUI = "FinishedWithUI",
    FinishedWithNoUI = "FinishedWithNoUI"
}

class EmbeddedPlayerController extends ControllerBase implements IEmbeddedPlayer, IVMRegisteredComponent {
    public name: string;
    public showProgress: boolean;
    public onStateChange: any;

    public captureEmits: boolean;

    protected currentWorkingDocumentData: any;

    protected _state: EmbeddedPlayerState;

    static $inject = ['$element', '$scope', 'checklistPlayer', 'vmRegisteredComponentProvider', '$timeout'];
    constructor(protected $element: ng.IRootElementService,
        protected $scope: ng.IScope,
        protected checklistPlayer: IChecklistPlayer,
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider,
        protected $timeout: ng.ITimeoutService) {
        super();

        this.name = '';
        this.showProgress = true;
        this.currentWorkingDocumentData = null;
        this._state = EmbeddedPlayerState.Unitialized;
        this.captureEmits = false;
    }

    $onInit(): void {
        if (angular.isDefined(this.name) == false || this.name == '') {
            console.warn('vm embedded player must be given a name');
            throw 'vm-embedded-player must be given a name';
        }

        if (this.showProgress == null) {
            this.showProgress = true;
        }

        super.$onInit();

        this.vmRegisteredComponentProvider.register(this);

        this.manageEmits();
    }

    manageEmits() {
        var me = this;
        // we're going to stop the $emit's sent out by the play-embedded directive from
        // going any further
        this.$scope.$on('embedded-player.started', (evt) => {
            if(evt.stopPropagation && me.captureEmits)
            {
                evt.stopPropagation();
            }
        });
        this.$scope.$on('embedded-player.showstep', (evt) => {
            if(evt.stopPropagation && me.captureEmits)
            {
                evt.stopPropagation();
            }
        });
        this.$scope.$on('embedded-player.finishing', (evt) => {
            if(evt.stopPropagation && me.captureEmits)
            {
                evt.stopPropagation();
            }
        });
        this.$scope.$on('embedded-player.finished', (evt) => {
            if(evt.stopPropagation && me.captureEmits)
            {
                evt.stopPropagation();
            }
        });
    }

    getComponentType(): string {
        return 'EmbeddedPlayer';
    }

    $onDestroy(): void {
        this.vmRegisteredComponentProvider.deRegister(this);
    }

    protected setState(value: EmbeddedPlayerState, canBack: boolean): void {
        if(value == this._state)
        {
            // the state hasn't changed, the play embedded seems to call things a few times in some circumstances
            return;
        }

        this._state = value;

        if (this.onStateChange != null) {
            this.onStateChange({ $event: { newState: this._state, canBack: canBack, player: this }});
        }
    }

    loadFromNew(compoundId: IPublishedResourceCompoundId, revieweeId: string, args: string) {
        this.setState(EmbeddedPlayerState.Loading, false);
        this.currentWorkingDocumentData = null;

        this.checklistPlayer.beginChecklist(compoundId, revieweeId, args).then((data: any) => {
            this.currentWorkingDocumentData = data;
        });
    }

    loadFromExisting(workingDocumentId: string): void {
        this.setState(EmbeddedPlayerState.Loading, false);
        this.currentWorkingDocumentData = workingDocumentId;
    }

    next(): void {
        this.$scope.$broadcast('embedded-player.next');
    }

    back(): void {
        this.$scope.$broadcast('embedded-player.back');
    }

    checklistStarted(evt: any): void {
        this.setState(EmbeddedPlayerState.Presenting, evt.data.CanPrevious as boolean);
    }

    checklistFinished(evt: any): void {
        if (evt.data.Presented == null) {
            this.setState(EmbeddedPlayerState.FinishedWithNoUI, false);
            return;
        }

        this.setState(EmbeddedPlayerState.FinishedWithUI, false);
    }

    checklistNavigating(evt: any): void {
        if (evt.starting == true) {
            this.setState(EmbeddedPlayerState.Navigating, false);
        }
        else {
            this.setState(EmbeddedPlayerState.Presenting, evt.canBack as boolean);
        }
    }
}

app.component('vmEmbeddedPlayer', {
    template: require('./template.html').default,
    bindings: {
        name: '@',
        showProgress: '<',
        onStateChange: '&',
        captureEmits: '<'
    },
    controller: EmbeddedPlayerController
});