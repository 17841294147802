'use strict';

import app from '../../../ngmodule';
import { ControllerBase } from '../../../control-base';

export class CardHeaderController extends ControllerBase {
}

app.component('vmCardHeader', {
    template: require('./template.html').default,
    bindings: {
        class: '<'
    },
    transclude: true,
    controller: CardHeaderController
});