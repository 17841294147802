'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';
import moment from 'moment';
import '../../services/pending-payments-service';

questionsModule.directive('questionPendingPayments', ['pendingPaymentsService', 'bworkflowApi', 'languageTranslate', function (pendingPaymentsService, bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    scope: {
      presented: '=',
      pageScope: '='
    },

    link: function (scope, elt, attribs) {
      var couponErrors = {
        "InvalidCode": "Invalid code",
        "OutOfDate": "Invalid code",
        "AlreadyApplied": "Already applied",
        "CouponLimitReached": "Coupon limit reached",
        "NotApplied": "Not applied"
      };


      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      pendingPaymentsService.modelPendingPayments(scope.presented.pendingpayments);

      scope.presented.couponcodename = scope.presented.couponcodename || 'Coupon code';
      scope.pendingPayments = scope.presented.pendingpayments;
      scope.customer = scope.presented.customer;

      scope.coupon = {
        applied: []
      };

      scope.resetCouponCode = function () {
        scope.coupon.error = null;
        scope.coupon.message = null;
      };

      function updateCouponResult(response) {
        scope.pendingPayments = scope.pendingPayments || response.payments;
        angular.forEach(response.payments.Payments, function (p) {
          var pp = scope.pendingPayments.Payments.find(function (_) {
            return _.Id === p.Id;
          });
          angular.extend(pp, p);
        });
        scope.pendingPayments.totalDue = response.payments.totalDue;
        scope.pendingPayments.totalFull = response.payments.totalFull;
        scope.pendingPayments.totalDiscount = response.payments.totalDiscount;
        scope.coupon.applied = response.appliedCoupons;
        scope.order = response.order;
      }

      scope.applyCouponCode = function () {
        scope.coupon.checking = true;
        pendingPaymentsService.applyCouponCode(scope.coupon.couponcode, scope.presented.orderid).then(function (response) {
          scope.coupon.checking = false;
          if (response.result === "Applied") {
            scope.coupon.couponcode = '';
            updateCouponResult(response);
          } else {
            scope.coupon.error = couponErrors[response.result] || "Invalid code";
            scope.coupon.message = response.message;
          }
        }, function (err) {
          scope.coupon.error = "Error";
          scope.coupon.message = null;
          scope.coupon.checking = false;
        });
      };

      scope.removeCoupon = function (coupon) {
        pendingPaymentsService.removeCoupon(coupon.id, scope.presented.orderid).then(function (response) {
          updateCouponResult(response);
        });
      };

      // Get fresh list of applied coupons now ..
      pendingPaymentsService.orderCoupons(scope.presented.orderid).then(function (response) {
        updateCouponResult(response);
      });

      // A little strange having the PendingPayments question handle Order cancelling, but easiest place to put it currently
      scope.cancelOrder = function () {
        if (confirm('Cancelling an Order is irreversible, are you sure you want to cancel this Order ?')) {
          pendingPaymentsService.cancelOrder(scope.presented.orderid).then(function (response) {
            updateCouponResult(response);

            scope.$emit('order.cancelled', scope.presented.orderid);
          });
        }
      };
    }
  });

}]);