'use strict';

import './index.scss';
import './../vm-checkbox';
import './../vm-validation-error-list';

import app from '../ngmodule';
import { ISelectionEvent, ControllerWithChoicesBase } from '../control-with-choices-base';
import angular from 'angular';
import { IValidationService } from '../../services/validator-service';

class CheckboxListController extends ControllerWithChoicesBase {
    public disabled: boolean;

    static $inject = ['$element', '$sce', 'vmValidationService'];
    constructor($element: ng.IRootElementService, $sce: ng.ISCEService, validationService: IValidationService) {
        super($element, $sce, validationService);

        this.disabled = false;
    }

    changed(evt: ISelectionEvent): void {
        super.changed(evt);
        this.validate(this.selected);
    }
}

app.component('vmCheckboxList', {
    template: require('./template.html').default,
    transclude: {
        'before': '?before',
        'after': '?after'
    },        
    bindings: {
        choices: '<',
        selected: '=',
        displayField: '@',
        valueField: '<',
        showValidationMessages: '@',
        validationMessages: '<',
        validator: '=',        
        prompt: '<',
        disabled: '<',
        onUpdate: '&'
    },
    controller: CheckboxListController
});