import app from '../../ngmodule';

app.directive('questionTasklistDesktopUi', ['$http', '$sce', '$filter', 'appUpdateMonitor', 'webServiceUrl', 'vmValidationService', 'vmRegisteredComponentProvider',
	function ($http, $sce, $filter, appUpdateMonitor, webServiceUrl, vmValidationService, vmRegisteredComponentProvider) {
		return {
			template: require('./template.html').default,
			restrict: 'E',
			link: function (scope, element, attrs) {
				appUpdateMonitor.addPauser(scope); // Pause updates whilst our scope is alive

				scope.addComment = function (task) {
					scope.clockinAndOut(task, task.pausingnotes).then(function (data) {
						task.pausingnotes = "";
						scope.hasFocus = false;
						scope.getTaskWorklog(scope.editing);
					});
				};

				scope.statusChangeValidator = {
					statusid: {
						validation: {
							rules: [
								vmValidationService.createValidator("mandatory", "This field is mandatory")
							]
						}
					},
					referencenumber: {
						validation: {
							data: {
								mandatoryValidator: vmValidationService.createValidator("mandatory", "This field is mandatory")
							},
							rules: [
								vmValidationService.createValidator("custom", "This field is mandatory", (value, template) => {
									let result = true;
									
									for(var s of scope.editing.statuses)
									{
										if(s.id == scope.editing.statusid)
										{
											if(s.referencenumbermode == 2) // it needs to be a mandatory status type to require a referencenumber
											{
												let r = template.data.mandatoryValidator.isValid(value, template); // its mandatory, so test for that

												result = r.errors.length == 0;
											}
										}
									}

									return result;
								}),
							]
						}
					}
				}

				scope.originalStatus = {
					statusid: scope.editing.statusid,
					referencenumber: scope.editing.referencenumber
				}

				scope.statusHasChanged = function() {
					return scope.editing.statusid == scope.originalStatus.statusid && scope.editing.referencenumber == scope.originalStatus.referencenumber;
				}

				scope.commitChanges = function (task) {
					// do validation first
					let validation = vmValidationService.process(scope.editing, scope.statusChangeValidator);

					if(validation.errors.length > 0)
					{
						return;
					}

					scope.saveChanges({
						statusid: task.statusid,
						referencenumber: task.referencenumber
					});

					scope.originalStatus = {
						statusid: scope.editing.statusid,
						referencenumber: scope.editing.referencenumber
					}
				};

				scope.$watch('editing', function (newValue, oldValue) {
					if (newValue != null) {
						scope.getTaskWorklog(newValue);
					}
				});

				scope.buildODataUrl = function (feed, version) {
					return webServiceUrl.odata(feed, version);
				};

				scope.getTasksInGroup = function () {
					var url = scope.buildODataUrl('Tasks');

					url = url + "?$filter=GroupId eq guid'" + scope.editing.projectjobtaskgroupid + "'&$orderby=DateCreated asc&datascope=IncludeCurrentUser";
					$http({
						url: url,
						method: 'GET'
					}).then(function (data) {
							scope.editing.relatedtasks = data.data.value;
					});
				};

				scope.showRelatedTask = function (task) {
					scope.currenttask = task;

					scope.getChecklistDetailsForTask(task);

					let modal = vmRegisteredComponentProvider.get('TaskList-Desktop-UI', 'modal');
					modal.show();
				};

				scope.getChecklistDetailsForTask = function (task) {
					var url = scope.buildODataUrl('ChecklistResults');

					url = url + "?$filter=TaskId eq guid'" + task.Id + "'&$orderby=Checklist&datascope=IncludeCurrentUser";
					$http({
						url: url,
						method: 'GET'
					}).

						then(function (data) {
							// struggling to get the groupby filter to behave, so I am going to manually do the grouping here
							var checklists = {};
							angular.forEach(data.value, function (value, index) {
								if (angular.isDefined(checklists[value.WorkingDocumentId]) == false) {
									checklists[value.WorkingDocumentId] = {
										Name: value.Checklist,
										Questions: []
									};

								}

								checklists[value.WorkingDocumentId].Questions.push(value);
							});

							task.checklists = checklists;
						});
				};

				if (scope.editing.projectjobtaskgroupid != null) {
					// we need to go off and load the set of tasks associated with this task
					scope.getTasksInGroup();
				}
			}
		};

	}]);

