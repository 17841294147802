import ngmodule from './ngmodule';
import { IFacilityFactory } from './types';
import { DesignDocumentFacility } from './design-document-facility';
import { MembersFacility } from './members-facility';
import { WorkingDocumentFacility } from './working-document-facility';
import { GlobalSettingsFacility } from './global-settings-facility';
import { TasksFacility } from './tasks-facility';
import { LabelsFacility } from './labels-facility/index';
import { TaskTypesFacility } from './task-types-facility/index';

export class FacilityFactory implements IFacilityFactory {
	[`designDocument`] = () => DesignDocumentFacility;
	[`members`] = () => MembersFacility;
	[`workingDocument`] = () => WorkingDocumentFacility;
	[`globalSettings`] = () => GlobalSettingsFacility;
	[`tasks`] = () => TasksFacility;
	[`labels`] = () => LabelsFacility;
	[`taskTypes`] = () => TaskTypesFacility;
}

ngmodule.factory(`facilityFactory`, [function () {
	return new FacilityFactory();
}])