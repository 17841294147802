'use strict';

import app from '../ngmodule';

app.run(["$transitions", "$state", function ($transitions, $state) {
  $transitions.onStart({ to: 'root.**' }, function (trans) {
    var siteMapSvc = trans.injector().get('siteMapService');

    siteMapSvc.setCurrentNode(trans.to().name, trans.params().name);
  });
}]);