import moment from 'moment';
import jQuery from 'jquery';

eval(`(function (globals) {
var window = globals.window;
window.moment = globals.moment;
window.jQuery = window.$ = globals.jQuery;
})`)({
  window,
  moment,
  jQuery
});

// we have to put this after the eval above as kendo-ui-core freaks out otherwise
import 'kendo-ui-core';
