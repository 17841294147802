'use strict';

import angular from 'angular';

var transitionCache = angular.module('transitionCache', []);
export default transitionCache;

transitionCache.factory('transitionCache', [function () {

  //http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
  var genGUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : r & 0x3 | 0x8;
      return v.toString(16);
    });
  };

  var cacheService = {
    cache: {},
    addItem: function (item, key) {
      if (typeof key === 'undefined')
        key = genGUID();
      this.cache[key] = item;
      return key;
    },
    getItem: function (guid) {
      return this.cache[guid];
    },
    getAndDelete: function (guid) {
      var item = this.cache[guid];
      this.cache[guid] = undefined;
      return item;
    }
  };


  return cacheService;
}]);

transitionCache.factory('sharedScope', ['$q', function ($q) {
  var svc = {
    shared: {}
  };


  var getObj = function (name) {
    var o = svc.shared[name];
    if (!o) {
      o = {};
      svc.shared[name] = o;
    }
    return o;
  };

  svc.set = function (name, value) {
    var o = getObj(name);
    angular.extend(o, value);
  };

  svc.get = function (name) {
    return getObj(name);
  };

  return svc;
}]);