'use strict';

import '../../directives/vm-tab-control';
import '../../directives/vm-modal';
import questionsModule from '../ngmodule';

questionsModule.constant('HtmlSectionLayout', {
  Flow: 0,
  Table: 1,
  TableRow: 2,
  Tabbed: 3,
  Modal: 4,
  FullScreen: 5,
  FlowCard: 6,
  FlowCardAlternate: 7
});


//Section renderer- works the same way as freetext below basically
questionsModule.directive('questionSection', ["$injector", "bworkflowApi", "$timeout", "playerButtons", "HtmlSectionLayout", "$sce", function ($injector, bworkflowApi, $timeout, playerButtons, HtmlSectionLayout, $sce) {
  var _count = 0;
  let pageScopeControllerCount = 0;

  function scrollToFirstValidationError(pageScopeElement) {
    var firstValidationError = $('#firstValidationError');
    if (firstValidationError.length) {
      var errorTop = firstValidationError[0].offsetTop - pageScopeElement.offsetTop;
      window.scrollTo({
        top: errorTop,
        behavior: 'smooth'
      });
    }
  }

  function getDashboardName(scope) {
    while (scope != null) {
      if (scope.db != null && scope.db.PlayerModel != null) {
        return scope.db.PlayerModel.ChecklistName;
      }
      scope = scope.$parent;
    }
    return '';
  }

  return {
    scope: {
      presented: '=',
      pageScope: '='
    },

    template: require('./template.html').default,
    restrict: 'EA',
    controller: ["$scope", "$controller", "$element", function ($scope, $controller, $element) {
      $scope.HtmlSectionLayout = HtmlSectionLayout;

      // ngControllerCode will only be defined at the Page root (Page is a Section)
      // We only want 1 pageScope at the Page level
      var ngControllerCode = $scope.presented.ngcontroller;
      var fn = function () { };
      if (ngControllerCode) {
        let scriptName = (getDashboardName($scope) + '-' + $scope.presented.Name + '-' + ++pageScopeControllerCount).replace(/ /g, '-');
        fn = eval(`(${ngControllerCode})\n//# sourceURL=https://pageScope/${scriptName}.js`);
      }

      $scope.SectionController = function () {
        // If we have a pageScope already then this is not the Page root section
        if ($scope.pageScope) {
          return this;
        }

        // Must be Page root section, create a pageScope, this gets passed down to all questions..
        $scope.pageScope = $scope.$new();
        $scope.pageScope.scope = {}; // Bin for all named Questions scope objects
        $scope.pageScope.playerButtons = playerButtons;

        // LUCIFER-276 - Need to explicitly control the pageScope.onReady execution order - on VMPlayer relying on $timeout for some
        // reason executed the onReady() method before all questions had been Angular linked and caused
        // issues on Dashboards relying on pageScope[<question name>]
        // Here, we push all questions that will be presented into a list, and as each question is
        // angular linked we remove it from this list, once the list is empty we know everything is
        // ready and can call pageScope.onReady()
        $scope.pageScope._waitingForPresentedToLink = [];
        function addWaitingForPresentedToLink(presented) {
          $scope.pageScope._waitingForPresentedToLink.push(presented);
          if (presented.Children != null) {
            presented.Children.map(childPresented => addWaitingForPresentedToLink(childPresented));
          }
        }
        addWaitingForPresentedToLink($scope.presented);

        // pageScope can override this function
        $scope.pageScope.onready = function () {
        };

        // this function should really remain as it is, although its put on pageScope "just in case its ever needed to be overriden"
        $scope.pageScope._onPresentedReady = function (presented) {
          let i = $scope.pageScope._waitingForPresentedToLink.indexOf(presented);
          if (i >= 0) {
            $scope.pageScope._waitingForPresentedToLink.splice(i, 1);
            if ($scope.pageScope._waitingForPresentedToLink.length == 0) {
              $timeout(() => {
                console.log('pageScope ready');
                $scope.pageScope.onready();
                scrollToFirstValidationError($element[0]);
              });
            }
          }
        }

        $scope.$on('validationErrors', function () {
          scrollToFirstValidationError($element[0])
        });

        // Bypass strictDi for backwards compatability
        $injector.annotate(fn, false);

        var ctrl = $controller(fn, {
          $scope: $scope.pageScope
        }).constructor;
      };
    }],
    link: function (scope, elt, attrs) {
      // not sure if this is a good idea, but am implementing adjusting the html section layout
      // through the sections class as well as the default method. It just gives us an easier way of signaling an adjustment to the layout
      if (scope.presented.HtmlPresentation.CustomClass != null && scope.presented.HtmlPresentation.CustomClass != '') {
        angular.forEach(HtmlSectionLayout, function (val, key) {
          if (scope.presented.HtmlPresentation.CustomClass.indexOf(key) != -1) {
            scope.presented.HtmlPresentation.Layout = val;
          }
        });
      }

      scope.presented.promptAsHtml = $sce.trustAsHtml(scope.presented.Prompt);

      scope.allquestionscategory = {
        Category: 'All Questions',
        ErrorCount: 0
      };

      scope.feeds = {};

      if (scope.presented.presentasfinal) {
        // let anyone who's interested know this is the last page as dictated by the dude/dudet that put the checklist together
        console.log('Final page detected');
        scope.$emit('section.finalpage', scope.presented);
      }

      /* sectionUtils has been deleted in favor of webServiceUrl 
      if (scope.presented.urls) {
        sectionUtils.addUrls(scope.presented.urls);
      }
      */

      scope.visibleFirstEnter = true;

      if (scope.presented.AllowFilterByCategory == true && scope.presented.ChildCategories.length > 0) {
        scope.selectedcategory = scope.presented.ChildCategories[0];
      }

      if (scope.presented.HtmlPresentation.Layout == 3) {
        // tabbed presentation
        angular.forEach(scope.presented.Children, function (child) {
          if (child.PresentedType == 'Section') {
            child.HidePrompt = true;
            child.tabId = child.Id;
          }
        });
      } else if (scope.presented.HtmlPresentation.Layout == 4 || scope.presented.HtmlPresentation.Layout == 5) {
        // modal, we set this to visible = false by default
        scope.presented.Visible = false;

        // we watch the visible property so that we can add/remove the noscroll
        // class from the body, which gets added/removed as the scroll bars
        // on the body interfer with the full screen view of a section
        scope.$watch('presented.Visible', function (newValue, oldValue) {
          if (scope.visibleFirstEnter == true) {
            scope.visibleFirstEnter = false;
            return;
          }

          $('body').toggleClass('noscroll');
        });
      }

      scope.show = function () {
        if (scope.presented.HtmlPresentation.Layout == 4 || scope.presented.HtmlPresentation.Layout == 5) {
          // Find our parent first
          var parent = scope.$parent;
          while (parent != null) {
            if (angular.isDefined(parent.presented) && parent.presented !== scope.presented) {
              // Find our sibling Sections and hide them
              scope.hiddenSiblings = [];
              parent.presented.Children.forEach(function (sibling) {
                scope.hiddenSiblings.push({
                  sibling: sibling,
                  visible: sibling.Visible
                });

                sibling.Visible = sibling === scope.presented;
              });
              break;
            }
            parent = parent.$parent;
          }

        }
        scope.presented.Visible = true;
      };

      scope.close = function () {
        scope.presented.Visible = false;
        if (angular.isDefined(scope.hiddenSiblings)) {
          scope.hiddenSiblings.forEach(function (hs) {
            hs.sibling.Visible = hs.visible;
          });
          delete scope.hiddenSiblings;
        }
      };

      if (scope.presented.Name !== null && scope.presented.Name !== '') {

        // Hook ourselves into pageScope so it can access us by name
        scope.pageScope[scope.presented.Name] = scope;

        scope.$on(scope.presented.Name, function (evt, args) {
          if (args.action === 'show') {
            scope.show();
          }
        });
      }

      scope.setVisible = function (visible) {
        scope.presented.Visible = !!visible;
      };

      scope.selectcategory = function (category) {
        scope.selectedcategory = category;
        scope.toggleDropMenu();
      };

      scope.filterByCategory = function (c) {
        return function (child) {
          if (angular.isDefined(scope.selectedcategory) == false) {
            return true;
          }

          if (scope.selectedcategory === scope.allquestionscategory) {
            return true;
          }

          if (!child.Categories) {
            return true;
          }

          if (child.Categories.length === 0) {
            return true;
          }

          return child.Categories.indexOf(scope.selectedcategory.Category) !== -1;
        };
      };

      if (scope.presented.datasource) {
        var split = scope.presented.datasource.split(',');

        // we store each of the feeds in a varibale based on their name
        angular.forEach(split, function (source) {
          var promise = bworkflowApi.getDataFeed(source);

          if (promise !== null) {
            promise.then(function (feed) {
              var key = feed.notifier.id.split('.').join('');

              scope.feeds[key] = feed;

              scope.$watch('feeds.' + key + '.notifier', function (newValue, oldValue) {
                if (angular.isDefined(newValue) === false || newValue === null) {
                  return;
                }

                var isVisible = false;
                angular.forEach(scope.feeds, function (feed) {
                  if (feed.data.length > 0) {
                    isVisible = true;
                  }
                });

                scope.presented.Visible = isVisible;
              });
            });
          }
        });
      }

      scope.toggleDropMenu = function () {
        elt.find('.dropdown.vm-tab-dropdown').toggleClass('is-active');
      }

      // Hook all childscopes to our deepEdit mode
      function setDeepEditMode(s, deepEdit) {
        if (s === null || s.hasOwnProperty('presented') && s.presented.enabledeepedit) {
          return;
        }
        s.deepEdit = deepEdit;
        var cs = s.$$childHead;
        while (cs !== null) {
          setDeepEditMode(cs, deepEdit);
          cs = cs.$$childHead;
        }
        if (s.$$nextSibling !== null) {
          setDeepEditMode(s.$$nextSibling, deepEdit);
        }
      }

      if (scope.presented.enabledeepedit) {
        scope.deepEdit = {
          readonly: true
        };


        scope.toggleDeepEditMode = function () {
          scope.deepEdit.readonly = !scope.deepEdit.readonly;
          playerButtons.update();
        };

        $timeout(function () {
          setDeepEditMode(scope.$$childHead, scope.deepEdit);
        });
      }

      scope.pageScope._onPresentedReady(scope.presented);
    }
  };

}]);