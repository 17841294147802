'use strict';

import angular from 'angular';
import '../services/bworkflow-api/bworkflow-api';
import 'angular-animate';
import 'angular-moment';
//import 'angular-material-collapsible';
import '../device-drivers/temperature-probe-manager';
import '../filters';

var questionsModule = angular.module('questions', [
    'vm-services',
    'angularMoment',
    'languageTranslation',
    'temperature-probe-manager-module',
    'vm-filters',
    'ngAnimate',
    'angular-cache']);


//Framework methods...



export default questionsModule;