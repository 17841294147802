'use strict';

import app from '../../ngmodule';
import moment from 'moment';

app.directive('questionTasklistClockinClockoutUi', [
  '$timeout',
  'appUpdateMonitor',
  'vmRegisteredComponentProvider',
  function (
    $timeout,
    appUpdateMonitor,
    vmRegisteredComponentProvider
  ) {
    return {
      template: require('./template.html').default,
      restrict: 'E',

      link: function (scope, element, attrs) {
        // ok so this is basically horrible, but there is a bit of work in breaking out what the tasklist directive
        // does into a service and then having things like finishing a task be done in that. At the moment the code
        // is designed around the scope in this directive being the same object as the task list (I know a complete
        // corruption of containing concerns, but its the way it was originally programmed). Without that break out of
        // functionality we need to reference the task list scope so can't have our own so we have no way of raising events
        // etc and signaling we're done nicely, so we're hacking in this dependency to the name of the modal we are in
        // and can then use that to close things.
        const modal = vmRegisteredComponentProvider.get('TaskList-showTaskDialog', 'modal');

        scope.fileUploadOptions = {
          maxWidth: 300
        };

        appUpdateMonitor.addPauser(scope); // Pause updates whilst our scope is alive

        scope.viewinggroupinformation = false;
        scope.showingdocumentation = false;
        scope.showSetTaskType = false;

        scope.showGroupInformation = function () {
          scope.viewinggroupinformation = true;
        };

        scope.gotoTask = function (task) {
          scope.viewinggroupinformation = false;

          scope.parent.showTaskById(task.id);
        };

        scope.toggleShowDocuments = function () {
          scope.showingdocumentation = !scope.showingdocumentation;
        };

        scope.showChangeTaskType = function () {
          scope.showSetTaskType = true;
        };

        scope.$on('question-task-list-task-type-editor.changed', function () {
          scope.showSetTaskType = false;
        });

        scope.canStart = function (task) {
          if (task.starttime) {
            return moment().isAfter(task.starttime);
          }
          return true;
        };

        scope.completeactivities = function (activities) {
          if (activities == null) {
            return;
          }

          angular.forEach(activities, function (activity) {
            activity.completed = true;
          });
        }

        scope.showCamera = function() {
          vmRegisteredComponentProvider.get('TaskList-FileUpload', 'FileUpload').showTakePicture();
        }

        scope.fileUploadUpdated = function($event) {
          // ok, we are going to hack into the old photo manager 

          if($event.type == 1)
          {
            scope.$emit('photo-manager.phototaken', { data: $event.changed });
          }
          else
          {
            scope.$emit('photo-manager.photoremoved', { data: $event.changed });
          }
        }

        // Hack selected scope.parent functions into our scope ..
        function callThenCloseDialog(args) {
          const originalFn = scope[args]
          scope[args] = function () {
            var canClose = originalFn.apply(scope.$parent, arguments);

            if (canClose == false) {
              return;
            }

            modal.close();
          }
        }

        // These functions will close the dialog
        [
          'pause',
          'finish',
          'QRCodeScanned'].
          forEach(callThenCloseDialog);
      }
    }
  }
]);


