'use strict';

import './index.scss';
import app from '../ngmodule';
import { v4 as uuidv4 } from 'uuid';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { StringNullableChain } from 'lodash';
import { ButtonController } from '../vm-button';

export interface IDropDownButtonChoice {
    id: string;
    text: string;
    icon?: string;
    hidden?: boolean;
    enabled?: boolean;
    clicked: null | ((args: any) => void); // we support each choice having a click handler
}

class DropDownButtonController extends ButtonController {
    protected choices: Array<IDropDownButtonChoice>;
    public onUpdate: null | ((args: any) => void) = null; // event handler called when there is a choice selected that doesn't have its own clicked event handler
    public disabled: boolean;

    protected kendoDropButton?: kendo.ui.DropDownButton;

    static $inject = ['$element', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService) {
        super($element, $timeout);

        this.choices = new Array<IDropDownButtonChoice>();
        this.disabled = false;
    }

    $onInit(): void {
        this.valuesFromHtml();

        this.$timeout(() => {
            // ok so at the time of writing the typescript definitions for kendo ui core haven't been updated
            // to include the drop down button, though it is available in javascript, so going with untyped for the moment
            let config: any = {} ;

            if(this.icon != '' && this.icon != undefined)
            {
                config.icon = this.icon;
            }

            if(this.size != '' && this.size != undefined)
            {
                config.size = this.size;
            }

            if(this.fillMode != '' && this.fillMode != undefined)
            {
                config.fillMode = this.fillMode;
            }

            if(this.color != '' && this.color != undefined)
            {
                config.themeColor = this.color;
            }

            if(this.rounded != '' && this.rounded != undefined)
            {
                config.rounded = this.rounded;
            }

            config.items = [];
            for(let c of this.choices)
            {
                let item: any = {
                    id: c.id,
                    text: c.text,
                    icon: c.icon,
                    hidden: c.hidden ? c.hidden : false,
                    enabled: c.enabled ? c.enabled : true
                }

                config.items.push(item);
            }

            config.click = (e: any) => {
                let c = this.findChoice(e.id);

                if(c == null)
                {
                    // weird and shouldn't happen, let's just move on with our lives
                    console.warn(`vm-dropdown-list item click event fired without a matching item with id = ${e.id}`);
                    return;
                }

                if(c.clicked)
                {
                    c.clicked({ choice: c, element: this.$element[0] }); // we include the element in this as it gives us a way to support data-* attributes on the root element so that the click can have extra context
                    return;
                }

                if(this.onUpdate)
                {
                    this.onUpdate({ $event:{ choice: c, element: this.$element[0] }}); // we include the element in this as it gives us a way to support data-* attributes on the root element so that the click can have extra context
                }
            }

            this.kendoDropButton = (this.$element.find('button') as any).kendoDropDownButton(config).data("kendoDropDownButton");
            (this.kendoDropButton as any).enable(!this.disabled,);
        });        
    }

    protected findChoice(id: string): IDropDownButtonChoice | null {
        if(!id)
        {
            return null;
        }

        for(let c of this.choices)
        {
            if(c.id == id)
            {
                return c;
            }
        }

        return null;
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if(changes.disabled && changes.disabled.currentValue != undefined && this.kendoDropButton) {
            (this.kendoDropButton as any).enable(!changes.disabled.currentValue);
        }
    }
}

app.component('vmDropdownButton', {
    template: require('./template.html').default,
    bindings: {
        prompt: '<',
        icon: '<',
        size: '<',
        fillMode: '<',
        color: '<',
        rounded: '<',
        choices: '<',
        disabled: '<',
        onUpdate: '&'
    },
    controller:  DropDownButtonController
});