'use strict';

import questionsModule from './ngmodule';
import questionDirectiveBase from './question-directive-base';

questionsModule.directive('questionPhProbe', ['InstrumentWorksPHProbeSvc', '$filter', 'persistantStorage', 'serverBasedUserSettings', 'bworkflowApi', 'languageTranslate', function (pHProbeSvc, $filter, persistantStorage, serverBasedUserSettings, bworkflowApi, languageTranslate) {
  var PH_PROBE_LAST_SERIAL_NUMBER = 'pHProbe.LastSerialNumber';
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_ph_probe.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.answer = scope.presented.Answer;
      scope.probe = null;
      scope.showSelectProbe = false;
      scope.showCalibrateProbe = false;
      scope.onDestroy = [];

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return scope.answer.pH;
      };

      if (angular.isDefined(pHProbeSvc.supported) == true && pHProbeSvc.supported == false) {
        scope.supported = false;
        return;
      }
      scope.supported = true;


      scope.calibrateProbe = function () {
        scope.calibrationSequence = pHProbeSvc.createCalibrationSequence();
        scope.showCalibrateProbe = true;
      };

      scope.closeCalibrateProbe = function () {
        scope.calibrationSequence = null;
        scope.showCalibrateProbe = false;
      };

      scope.saveCalibration = function () {
        var calibrationData = scope.calibrationSequence.stop();
        if (angular.isDefined(calibrationData)) {
          serverBasedUserSettings.setUserSetting('pH Calibration:' + scope.probe.serialNumber, calibrationData);
        }
      };

      scope.pH = function (reading) {
        if (reading == null || angular.isUndefined(reading)) {
          return '';
        }

        var result = scope.calculateReading(reading);

        return result;
      };

      scope.calculateReading = function (reading) {
        return Math.round(reading * 100) / 100.0;
      };

      var getReadingPassStatus = function (pH) {
        if (pH == null || angular.isUndefined(pH)) {
          return 3;
        }
        // min/max are always pH
        if (pH > scope.presented.MaximumPassReading) {
          return 2;
        } else if (pH < scope.presented.MinimumPassReading) {
          return 1;
        }
        return 0;
      };

      scope.setReading = function (reading) {
        scope.reading = scope.calculateReading(reading);
        scope.readingStatus = getReadingPassStatus(reading);
      };

      scope.record = function (reading) {
        scope.answer.pH = scope.calculateReading(reading || pHProbeSvc.current_pH.value);
        scope.recordedStatus = getReadingPassStatus(scope.answer.pH);
      };

      scope.clearRecorded = function () {
        scope.answer.pH = null;
        scope.recordedStatus = null;
      };

      if (scope.presented.Answer && angular.isDefined(scope.presented.Answer.pH) && scope.presented.Answer.pH != null) {
        scope.record(scope.presented.Answer.pH);
      }

      scope.pHProbeSvc = pHProbeSvc;
      scope.battery = pHProbeSvc.battery;

      scope.$watch('pHProbeSvc.current_pH.value', function (newValue, oldValue) {
        if (angular.isDefined(newValue) == false) {
          return;
        }

        scope.setReading(newValue);
      });

      pHProbeSvc.startMonitor(scope.presented.RefreshPeriod);

      var onProbeConnect = function (probe) {
        scope.probe = probe;
        if (probe && probe.serialNumber) {
          persistantStorage.setItem(PH_PROBE_LAST_SERIAL_NUMBER, probe.serialNumber);
        }
      };

      scope.probes = [];
      scope.isActiveProbe = function (probe) {
        return probe === pHProbeSvc.getActiveProbe();
      };

      scope.connect = function (probe) {
        if (scope.disposeOnButton) {
          scope.disposeOnButton();
          scope.disposeOnButton = null;
        }
        scope.probe = probe;

        serverBasedUserSettings.getUserSetting('pH Calibration:' + scope.probe.serialNumber).then(function (data) {
          if (data) {
            probe.setCalibration(data);
          }
        });

        pHProbeSvc.setActiveProbe(probe, function (connected) {
          if (connected) {
            onProbeConnect(probe);
            // Disconnect will leave showSelectProbe dialog open
            if (probe) {
              scope.showSelectProbe = false;
            }
          } else {
            // We disconnected, if we are are not findingProbes then auto look for another
            if (!scope.showSelectProbe) {
              scope.findProbes(true); // Auto connect to 1st
            }
          }
        });
      };

      scope.closeSelectProbe = function () {
        scope.showSelectProbe = false;
        if (scope.removeProbesListener) {
          scope.removeProbesListener();
          scope.onDestroy.remove(scope.removeProbesListener);
          scope.removeProbesListener = null;
        }
      };

      scope.selectProbe = function () {
        scope.showSelectProbe = true;

        // Don't auto connect when showing the findProbes dialog (user has to select)
        scope.findProbes(false);
      };

      scope.$on('$destroy', function () {
        scope.onDestroy.forEach(function (fn) {
          if (angular.isFunction(fn)) {
            fn();
          }
        });
        pHProbeSvc.stopMonitor();
      });

      scope.removeProbesListener = null;
      scope.findProbes = function (autoConnect) {
        if (scope.removeProbesListener) {
          scope.removeProbesListener();
          scope.onDestroy.remove(scope.removeProbesListener);
        }
        scope.removeProbesListener = pHProbeSvc.addProbesListener(function (probes) {
          scope.probes = probes;
          if (pHProbeSvc.getActiveProbe()) {
            return;
          }
          if (autoConnect) {
            if (probes.length == 1) {
              scope.connect(probes[0]);
            } else {
              persistantStorage.getItem(PH_PROBE_LAST_SERIAL_NUMBER, function (lastProbeSerialNumber) {
                if (lastProbeSerialNumber) {
                  var probe = probes.find(function (probe) {
                    return probe.serialNumber == lastProbeSerialNumber;
                  });
                  if (probe) {
                    scope.connect(probe);
                  }
                } else {
                  scope.showSelectProbe = true;
                }
              });
            }
          }
        });
        pHProbeSvc.findProbes();
        scope.onDestroy.push(scope.removeProbesListener);
      };

      // Find and connect to 1st available probe
      scope.findProbes(true);
    }
  });

}]);

questionsModule.directive('questionSignature', ['bworkflowApi', 'languageTranslate', function (bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_signature.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.answer = scope.presented.Answer;

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return $(elt).find('input').val();
      };

      scope.$on('signatureReady', function (ev, elt) {
        var hidden = elt.find("input");
        var canvas = elt.find("canvas");
        var sigWrapper = elt.find(".sigWrapper");

        var dimensionAsCss = function (dim) {
          var result = dim.Value.toString();

          if (dim.Type == "Pixels") {
            result = result + "px";
          } else {
            result = result + "%";
          }

          return result;
        };

        elt.attr("style", "width:" + dimensionAsCss(scope.presented.Width));
        sigWrapper.attr("style", "height:" + dimensionAsCss(scope.presented.Height));
        canvas.attr("width", scope.presented.Width.Value);
        canvas.attr("height", scope.presented.Height.Value);

        scope.signaturePad = elt.signaturePad({
          output: hidden,
          validateFields: false,
          drawOnly: true,
          lineTop: scope.presented.Height.Value - 20
        });


        if (scope.answer.Signature != null && scope.answer.Signature != "") {
          scope.signaturePad.regenerate(scope.answer.Signature);
        }

        ev.stopPropagation();
      });

      scope.clearSignature = function () {
        scope.signaturePad.clearCanvas();
      };

      scope.$on('populateAnswer', function (ev) {
        scope.answer.Signature = $(elt).find('input').val(); //Need to update answer model
      });
    }
  });

}]);

// directive that is used by the signature question type to actually put in place the signature object and other script related stuff
questionsModule.directive('signaturepad', [function () {
  return {
    scope: {
      signaturedata: '='
    },

    link: function (scope, elt, attrs) {
      // all we do is raise an event so that the parent scope can get notified that the HTML elements are in place
      // for the signature stuff to be put in place.
      scope.$emit('signatureReady', elt, scope.signaturedata);
    }
  };

}]);


questionsModule.directive('questionMediaDirectory', ['bworkflowApi', '$rootScope', '$timeout', 'sharedScope', 'languageTranslate', function (bworkflowApi, $rootScope, $timeout, sharedScope, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_media_directory.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.answer = scope.presented.Answer;

      scope.userSelection = null;

      var parameters = {
        showpageviewings: scope.presented.showpageviewings,
        userid: scope.presented.userid
      };


      // This object is injected into our ABN directive, ABN will populate it with tree control functions we can call
      scope.treeControl = {};

      scope.getFolders = function () {
        bworkflowApi.execute('MediaDirectory', 'GetFolders', parameters).
          then(function (data) {
            angular.forEach(data.folders, function (node, key) {
              scope.setFolderIcons(node);
            });

            scope.folders = data.folders;

            // And expand them all
            $timeout(function () {
              if (scope.treeControl.expand_all)
                scope.treeControl.expand_all();
            });
          }, function (tasks) {

          });
      };

      // On language change, 
      $rootScope.$on('$translateChangeSuccess', scope.getFolders);

      scope.setFolderIcons = function (node) {
        if (node.data.isfile == true) {
          if (node.data.viewstatus == "unread") {
            node.notification = {
              type: 'success',
              text: 'new'
            };

          } else if (node.data.viewstatus == "read previous") {
            node.notification = {
              type: 'warning',
              text: 'updated'
            };

          }

          return;
        }

        if (node.children.length > 0) {
          angular.forEach(node.children, function (child, key) {
            scope.setFolderIcons(child);
          });

          return;
        }

        node.iconLeaf = "icon-folder-open";
      };

      scope.selectitem = function (branch) {
        var event = {
          selectedfolderid: null,
          selectedmediaid: null,
          selectedmediashowviachecklistid: null,
          type: null
        };


        if (branch.data.isfile == true) {
          scope.item = branch;

          scope.answer.SelectedId = branch.data.id;

          // Search up throught tree for a populated "ShowViaChecklistId" property
          var findNode = branch;
          while (findNode && !findNode.data.showviachecklistid) {
            findNode = scope.treeControl.get_parent_branch(findNode);
          }

          if (scope.presented.Name != null) {
            event.selectedmediaid = branch.data.id;
            event.type = 'mediafile';
            event.selectedmediashowviachecklistid = findNode != null ? findNode.data.showviachecklistid : 1;
          }
        } else {
          scope.item = null;

          if (scope.presented.Name != null) {
            event.type = 'mediafolder';
            event.selectedfolderid = branch.data.id;
          }
        }

        if (scope.presented.Name != null) {
          sharedScope.set(scope.presented.Name, event);

          scope.$emit('player_broadcast', {
            name: scope.presented.Name,
            data: event
          });

        }
      };

      scope.getFolders();
    }
  });

}]);

questionsModule.directive('questionMediaViewingSummary', ['bworkflowApi', '$timeout', '$sce', 'languageTranslate', function (bworkflowApi, $timeout, $sce, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_media_viewing_summary.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.answer = scope.presented.Answer;
      scope.selectedfolderid = null;

      scope.loadFolderSummary = function (folderid) {
        scope.selectedfolderid = folderid;

        var parameters = {
          folderid: folderid
        };


        bworkflowApi.execute('MediaViewingSummary', 'GetFolderSummary', parameters).
          then(function (data) {
            scope.data = data;
          }, function (tasks) {

          });
      };

      scope.showUserDetails = function (summary) {
        if (angular.isDefined(summary.showdetails) == false || summary.showdetails == false) {
          summary.showdetails = true;
        } else {
          summary.showdetails = false;
        }

        if (angular.isDefined(summary.userdetail) == true) {
          return;
        }

        var parameters = {
          folderid: scope.selectedfolderid,
          userid: summary.userid
        };


        bworkflowApi.execute('MediaViewingSummary', 'GetFolderUserDetail', parameters).
          then(function (data) {
            angular.forEach(data.views, function (view, key) {
              if (view.accepted == false) {
                view.acceptNoteAsHtml = $sce.trustAsHtml(view.acceptionnotes);
              }
            });

            summary.userdetail = data;
          }, function (tasks) {

          });
      };

      scope.toDuration = function (seconds) {
        return moment.duration(seconds, "seconds").humanize();
      };

      if (scope.presented.listen == true) {
        scope.$on(scope.presented.listento, function (event, args) {
          if (args.type == 'mediafolder') {
            scope.loadFolderSummary(args.selectedfolderid);
          }
        });
      }
    }
  });

}]);

//questionsModule.directive('questionMediaViewingSummaryGauge', ['$translate',
//    function ($translate) {
//        return {
//            restrict: 'A',
//            require: 'ngModel',
//            scope: {
//                summary: '=ngModel',
//                usercount: '='
//            },
//            link: function (scope, elt, attrs, ngModel) {
//                scope.gaugeOptions = {

//                    chart: {
//                        type: 'solidgauge',
//                        backgroundColor: 'rgba(255,255,255,0.65)'
//                    },

//                    title: null,

//                    pane: {
//                        center: ['50%', '85%'],
//                        size: '140%',
//                        startAngle: -90,
//                        endAngle: 90,
//                        background: {
//                            backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
//                            innerRadius: '60%',
//                            outerRadius: '100%',
//                            shape: 'arc'
//                        }
//                    },

//                    tooltip: {
//                        enabled: false
//                    },

//                    // the value axis
//                    yAxis: {
//                        lineWidth: 0,
//                        minorTickInterval: null,
//                        tickPixelInterval: 400,
//                        tickWidth: 0,
//                        title: {
//                            y: -70
//                        },
//                        labels: {
//                            y: 16
//                        }
//                    },

//                    plotOptions: {
//                        solidgauge: {
//                            dataLabels: {
//                                y: 5,
//                                borderWidth: 0,
//                                useHTML: true
//                            }
//                        }
//                    }
//                };

//                var chartOpts = Highcharts.merge(scope.gaugeOptions, {
//                    yAxis: {
//                        min: 0,
//                        max: scope.usercount,
//                    },

//                    credits: {
//                        enabled: false
//                    },

//                    series: [{
//                        name: $translate.instant('Speed'),
//                        data: [scope.summary.distinctreads],
//                        dataLabels: {
//                            format: $translate.instant('<div style="text-align:center"><span style="font-size:25px;color:' +
//                                ((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black') + '">{y}</span><br/>' +
//                                   '<span style="font-size:12px;color:silver">users have read</span></div>')
//                        },
//                        tooltip: {
//                            valueSuffix: ''
//                        }
//                    }]

//                });

//                chartOpts.chart.renderTo = $(elt)[0];
//                //chartOpts.chart.width = 200;
//                var hc = new Highcharts.Chart(chartOpts);
//            }
//        };
//    }
//]);


questionsModule.directive('imageEditor', [function () {
  return {
    scope: {
      image: '=',
      maxHeight: '='
    },

    restrict: 'EA',
    templateUrl: 'question_uploadmedia_canvas.html',
    link: function (scope, elt, attrs) {

      var canvasParent = $(elt).find('div.uploadmedia-canvascontainer');
      var editCanvasElt = $(elt).find('canvas.uploadmedia-editcanvas').get(0);

      var editCtx = $(editCanvasElt).get(0).getContext('2d');

      var sizeEditCanvas = function () {
        //first resize the actual canvas elt
        //what's our max width?
        var maxWidth = $(canvasParent).width();
        var maxHeight = scope.maxHeight;
        var aspectRatio = scope.image.width / scope.image.height;
        if (maxWidth / aspectRatio > maxHeight) {
          //we would be too high, clamp height
          var w = maxHeight * aspectRatio;
          $(editCanvasElt).width(w);
          $(editCanvasElt).height(maxHeight);
        } else {
          //we would be too wide, clamp width
          var h = maxWidth / aspectRatio;
          $(editCanvasElt).width(maxWidth);
          $(editCanvasElt).height(h);
        }
      };
      var loadImage = function () {
        editCanvasElt.width = scope.image.width;
        editCanvasElt.height = scope.image.height;
        editCtx.drawImage(scope.image, 0, 0, scope.image.width, scope.image.height);
      };

      var onImgChange = function () {
        if (typeof scope.image === 'undefined') return;
        loadImage();
        sizeEditCanvas();
      };

      var onWindowScale = function () {
        if (typeof scope.image === 'undefined') return;
        sizeEditCanvas();
      };

      $(window).on('resize', onWindowScale);
      scope.$watch('image', onImgChange);
      onImgChange();

    }
  };

}]);

questionsModule.controller('uploadMediaSlideCtrl', ['$scope', '$timeout', '$anchorScroll', '$location', function ($scope, $timeout, $anchorScroll, $location) {
  $scope.goBack = function () {
    $scope.$emit('unSlideModal');
  };

  //what kind of file upload mode will we support?
  if (!window.mobile && Modernizr.draganddrop && window.SquareIT.BWorkflow.HandleUploads) {

    //show drag and drop one
    $scope.fileSelectMode = 'draganddrop';

    $('.uploadmedia-drop-target').on('dragenter', function (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      $(ev.target).addClass('uploadmedia-drop-target-hover');
    });

    $('.uploadmedia-drop-target').on('dragleave', function (ev) {
      $(ev.target).removeClass('uploadmedia-drop-target-hover');
    });

    $('.uploadmedia-drop-target').on('dragover', function (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      ev.originalEvent.dataTransfer.dropEffect = 'copy';
    });

    $('.uploadmedia-drop-target').on('drop', function (ev) {
      ev.stopPropagation();
      ev.preventDefault();
      $(ev.target).removeClass('uploadmedia-drop-target-hover');

      var dt = ev.originalEvent.dataTransfer;
      var files = dt.files;

      window.SquareIT.BWorkflow.HandleUploads({
        files: files,
        maxWidth: $scope.maxWidth,
        maxHeight: $scope.maxHeight
      },
        function (resizedFiles) {
          $timeout(function () {
            $scope.d.mediaItems = $scope.d.mediaItems.concat(resizedFiles);
          });
        });
    });

    //need to disable pointer events on drop target's children so that drag/drop works as expected
    $timeout(function () {
      $('.uploadmedia-drop-target').children().css('pointer-events', 'none');
    });
  } else {

    //show input
    $scope.fileSelectMode = 'fileinput';

    //timeout to wait for the switch DOM to render
    /*$timeout(function () {
        $('.uploadmedia-fileinput-button').on('click', function(ev) {
            window.SquareIT.TakePhoto
            ({
                    maxWidth: $scope.maxWidth,
                    maxHeight: $scope.maxHeight
            }, function (files) {
                $timeout(function() {
                    $scope.d.mediaItems = $scope.d.mediaItems.concat(files);
                });
            });
        });
    });*/

  }

  //give the editor an anchor
  $scope.editorAnchorId = 'x' + window.rstring(8);

  $scope.isImage = function (mi) {
    return window.isImage(mi.fname);
  };

  $scope.doEdit = function (mi) {
    $scope.editing = true;
    console.log('editing');
    $scope.editTarget = mi;
    $location.hash($scope.editorAnchorId);
    $anchorScroll();
  };

  $scope.removeItem = function (mi) {
    var index = $scope.d.mediaItems.indexOf(mi);
    if (index != -1)
      $scope.d.mediaItems.splice(index, 1);
    if (mi == $scope.editTarget) {
      $scope.editTarget = undefined;
      $scope.editing = false;
    }
  };
}]);

questionsModule.directive('questionPresentWordDocument', ['bworkflowApi', 'languageTranslate', function (bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_present_word_document.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.answer = scope.presented.Answer;

      scope.backwards = function () {
        if (scope.pageNumber == 1) {
          return;
        }

        scope.pageNumber = scope.pageNumber - 1;
        scope.answer.lastpageread = scope.pageNumber;
        scope.notifyPageView(scope.pageNumber);

        scope.calculatePageUrl();
      };

      scope.forwards = function () {
        if (scope.presented.pagecount != null && scope.pageNumber >= scope.presented.pagecount) {
          return;
        }

        scope.pageNumber = scope.pageNumber + 1;
        scope.answer.lastpageread = scope.pageNumber;
        scope.notifyPageView(scope.pageNumber);

        if (scope.pageNumber == scope.presented.pagecount - 1) {
          scope.answer.allpagesread = true;
        }

        scope.calculatePageUrl();
      };

      scope.calculatePageUrl = function () {
        if (scope.presented.mergewithchecklist == true || scope.presented.pagecount == null) {
          scope.mediaurl = bworkflowApi.getfullurl("~/DocumentPreviewMediaImage/" + scope.presented.workingdocumentid + ".png?mediaid=" + scope.presented.mediaid + "&scale=1&pageindex=" + (scope.pageNumber - 1) + "&pagecount=1&resolution=" + scope.presented.resolution + "&usehighquality=" + scope.presented.usehighquality + "&useantialiasing=" + scope.presented.useantialiasing);
        } else {
          scope.mediaurl = bworkflowApi.getfullurl("~/MediaPreviewImage/" + scope.presented.mediaid + ".png?scale=1&pageindex=" + (scope.pageNumber - 1) + "&version=" + scope.presented.version); // version included to make things cache friendly
        }
      };

      scope.notifyPageView = function (toPage) {
        if (scope.presented.trackviewing == false) {
          return;
        }

        var parameters = {
          userid: scope.presented.userid,
          workingdocumentid: scope.presented.workingdocumentid,
          mediaid: scope.presented.mediaid,
          currentpageview: scope.currentpageview,
          nextpage: toPage
        };


        bworkflowApi.execute('MediaViewing', 'Notify', parameters).
          then(function (data) {
            scope.currentpageview = data.currentpageview;
          }, function (tasks) {

          });
      };

      // if the user is on the last page, they'll hit the next button of the player, so
      // we need to track that final page read on movement in the player
      scope.$on('populateAnswer', function (ev) {
        scope.notifyPageView(null);
      });

      scope.answer = scope.presented.Answer;
      scope.currentpageview = null;
      scope.pageNumber = scope.answer.lastpageread - 1;

      if (scope.pageNumber < 0) {
        scope.pageNumber = 0;
      }

      scope.forwards();



    }
  });

}]);

//Geolocation question (geocode)
questionsModule.directive('questionGeocode', ['bworkflowApi', 'languageTranslate', function (bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_geocode.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.locateStatus = 'locating';
      scope.answer = scope.presented.Answer;

      //first thing to do is try and geolocate
      bworkflowApi.getGPSLocation().
        then(function (gpsval) {
          scope.locateStatus = 'success';
          scope.coords = gpsval.coords;
        })['catch'](function (ex) {//Can't use .catch() because it's a reserved word and IE8 will barf
          scope.locateStatus = 'failure';
          scope.errorMessage = ex.message;
        });

      scope.$on('populateAnswer', function (ev) {
        if (scope.locateStatus == 'locating') {
          scope.answer.Error = 'The checklist was advanced before a location was acquired';
        } else if (scope.locateStatus == 'failure') {
          scope.answer.Error = scope.errorMessage;
        } else if (scope.locateStatus == 'success') {
          scope.answer.Latitude = scope.coords.latitude;
          scope.answer.Longitude = scope.coords.longitude;
          scope.answer.Accuracy = scope.coords.accuracy;
        }
      });

    }
  });

}]);



questionsModule.directive('questionTimeline', ['$timeout', 'bworkflowApi', '$compile', 'languageTranslate', function ($timeout, bworkflowApi, $compile, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.feeds = {};

      scope.taskItems = [];
      scope.taskWorklogItems = [];
      scope.taskChecklistItems = [];

      scope.groupItems = {};

      scope.items = [];

      scope.currentSelection = null;

      scope.buildItemsFromTasksFeed = function (feed) {
        scope.taskItems.length = 0;

        angular.forEach(feed.data, function (dataItem) {
          if (dataItem.alldata.DateStarted == null) {
            return;
          }

          var item = {
            id: dataItem.alldata.Id,
            data: dataItem,
            date: moment(dataItem.alldata.DateStarted).toDate(),
            type: 'Start Task',
            badge: {
              type: "success",
              icon: "icon-play"
            },

            body: "<strong>" + dataItem.alldata.Owner + "</strong> started task <strong>'" + dataItem.alldata.Name + "'</strong>"
          };



          if (dataItem.alldata.SiteName != null) {
            item.body = item.body + " at site <strong>" + dataItem.alldata.SiteName + "</strong>";
          }

          scope.taskItems.push(item);

          if (dataItem.alldata.DateCompleted == null) {
            item.body = item.body + "<br/><i>This item is currently active</i>";
            item.badge.type = "warning";
            return;
          }

          item = {
            id: dataItem.alldata.Id,
            data: dataItem,
            date: moment(dataItem.alldata.DateCompleted).toDate(),
            type: 'Complete Task',
            badge: {
              type: "danger",
              icon: "icon-stop"
            },

            body: "<strong>" + dataItem.alldata.Owner + "</strong> completed task <strong>'" + dataItem.alldata.Name + "'</strong>"
          };


          if (dataItem.alldata.SiteName != null) {
            item.body = item.body + " at site <strong>" + dataItem.alldata.SiteName + "</strong>";
          }

          item.body = item.body + " this took &cong; " + moment.duration(dataItem.alldata.ActualDuration * 1, 'seconds').humanize();

          scope.taskItems.push(item);
        });
      };

      scope.buildItemsFromTaskWorklogsFeed = function (feed) {
        scope.taskWorklogItems.length = 0;

        angular.forEach(feed.data, function (dataItem) {
          var item = {
            id: dataItem.alldata.TaskId,
            data: dataItem,
            date: moment(dataItem.alldata.DateCreated).toDate(),
            type: 'Clocked In',
            badge: {
              type: ["warning", "small"],
              icon: "icon-time"
            },

            body: '<i>This item is currently active</i>'
          };


          if (dataItem.alldata.DateCompleted != null) {
            // its been clocked into and out of
            item.body = undefined;
            item.badge.type = ["success", "small"];
          }

          scope.taskWorklogItems.push(item);
        });
      };

      scope.filterStartingWorklogsOut = function () {
        // if we are hooked up to both a task feed and a work log feed
        // we get 2 items in the end result for the start of a task. To avoid
        // confusion, we filter out the clockin item

        angular.forEach(scope.taskItems, function (taskItem) {
          var index = -1;

          for (var i = 0; i < scope.taskWorklogItems.length; i++) {
            if (taskItem.date.getTime() == scope.taskWorklogItems[i].date.getTime()) {
              index = i;
              break;
            }
          }

          if (index != -1) {
            scope.taskWorklogItems.splice(index, 1);
          }
        });
      };

      scope.buildItemsFromChecklistsFeed = function (feed) {
        scope.taskChecklistItems.length = 0;

        angular.forEach(feed.data, function (dataItem) {
          var startDate = moment(dataItem.alldata.DateStarted);
          var item = {
            id: dataItem.alldata.TaskId,
            data: dataItem,
            date: startDate.toDate(),
            type: 'Checklist',
            badge: {
              type: ["info", "small"],
              icon: "icon-file"
            },

            body: "<strong>" + dataItem.alldata.ReviewerName + "</strong> completed checklist <strong>'" + dataItem.alldata.Name + "'</strong> relating to task <strong>'" + dataItem.alldata.TaskName + "'</strong> this took about " + moment.duration(moment(dataItem.alldata.DateCompleted).diff(startDate)).humanize()
          };


          scope.taskChecklistItems.push(item);
        });
      };

      scope.buildItemsFromFeed = function (feed) {
        // there are a number of feed types we work with, namely
        // Tasks, TaskWorklogs, Checklists
        scope.items.length = 0;

        switch (feed.template.feed) {
          case "Tasks":
            scope.buildItemsFromTasksFeed(feed);
            break;
          case "TaskWorklogs":
            scope.buildItemsFromTaskWorklogsFeed(feed);
            break;
          case "Checklists":
            scope.buildItemsFromChecklistsFeed(feed);
            break;
        }


        scope.items.push.apply(scope.items, scope.taskItems);
        scope.filterStartingWorklogsOut();
        scope.items.push.apply(scope.items, scope.taskWorklogItems);
        scope.items.push.apply(scope.items, scope.taskChecklistItems);

        scope.buildGroupItems();
      };

      scope.buildGroupItems = function () {
        scope.groupItems = {};

        angular.forEach(scope.taskItems, function (taskItem) {
          if (angular.isDefined(scope.groupItems[taskItem.id]) == false) {
            var group = {
              id: taskItem.id
            };

            scope.groupItems[taskItem.id] = group;
            taskItem.group = group;
          } else {
            taskItem.group = scope.groupItems[taskItem.id];
          }
        });

        angular.forEach(scope.taskWorklogItems, function (workItem) {
          workItem.group = scope.groupItems[workItem.id];
        });

        angular.forEach(scope.taskChecklistItems, function (cItem) {
          cItem.group = scope.groupItems[cItem.id];
        });
      };

      if (scope.presented.datasource) {
        var split = scope.presented.datasource.split(',');

        // we store each of the feeds in a varibale based on their name
        angular.forEach(split, function (source) {
          var promise = bworkflowApi.getDataFeed(source);

          if (promise != null) {
            promise.then(function (feed) {
              var key = feed.notifier.id.split('.').join('');

              scope.feeds[key] = feed;

              scope.$watch('feeds.' + key + '.notifier', function (newValue, oldValue) {
                if (angular.isDefined(newValue) == false || newValue == null) {
                  return;
                }

                scope.buildItemsFromFeed(scope.feeds[newValue.id.split('.').join('')]);
              });
            });
          }
        });
      }

      scope.clearSources = function () {
        if (scope.presented.cleardatasources != null && scope.presented.cleardatasources != '') {
          var sources = scope.presented.cleardatasources.split(',');
          var fields = scope.currentSelection;

          if (fields != null) {
            fields = scope.currentSelection;
          }
          angular.forEach(sources, function (source) {
            var promise = bworkflowApi.getDataFeed(source);

            if (promise != null) {
              promise.then(function (toUpdate) {
                // copy across what was selected into the parameters for the feed
                toUpdate.clearParameters(fields);
              });
            }
          });
        }
      };

      scope.updateSources = function (data) {
        if (scope.presented.updatedatasources != null && scope.presented.updatedatasources != '') {
          var sources = scope.presented.updatedatasources.split(',');

          angular.forEach(sources, function (source) {
            var promise = bworkflowApi.getDataFeed(source);

            if (promise != null) {
              promise.then(function (toUpdate) {
                angular.forEach(data.alldata, function (value, key) {
                  toUpdate.parameters[key] = value;
                });

                // get data and force a refresh
                toUpdate.getData(true);
              });
            }
          });
        }
      };

      scope.$on('timeline.item.clicked', function (event, args) {
        if (scope.currentSelection != null) {
          scope.currentSelection.ui_selected = undefined;
        }

        scope.clearSources();
        scope.updateSources(args.data);

        scope.currentSelection = args.data;

        scope.currentSelection.ui_selected = true;
      });

      var content = "<div class='question timeline-question'><timeline items='items' dateformat='presented.dateformat' ng-if='items.length > 0'></timeline>";

      if (scope.presented.fillheight == true) {
        content = "<div class='question timeline-question' fill-height ";

        if (scope.presented.fillheightbottompadding != null) {
          content = content + "additional-padding='" + scope.presented.fillheightbottompadding + "'>";
        }

        content = content + "<timeline items='items' dateformat='presented.dateformat' ng-if='items.length > 0'></timeline></div>";
      }

      var dirElement = $compile(content)(scope);
      $(elt).append(dirElement);
    }
  });

}]);

var chartSeries = {
  link: function (scope, series, bworkflowApi, $interpolate) {

    scope.addSeries(series.name, []);

    var addSeriesColor = function (index) {
      // since we support multiple fields in a row being rendered as different series, our colour notation has to support
      // multiple colours too, we do this through a comma seperated string in a similar fashion to the multiple fields = multiple series
      var color = scope.defaultColors[scope.colors.length];

      if (angular.isDefined(series.linecolour) && series.linecolour != null && series.linecolour != '') {
        var splits = series.linecolour.split(',');

        if (splits.length < index) {
          index = splits.length - 1;
        }

        color = splits[index];
      }

      scope.colors.push(color);
    };

    var addArraySeries = function (scope, series, bworkflowApi) {
      addSeriesColor(0);
      scope.addSeries(series.name, series.data);
    };

    var addFeedSeries = function (scope, series, bworkflowApi, $interpolate) {
      var promise = bworkflowApi.getDataFeed(series.datasource);

      if (promise != null) {
        promise.then(function (f) {
          series.feed = f;

          var plotData = function (feed) {
            var groupedData = {};

            var data = [];

            // ok, this gets a bit complicated. The datasourcefield supports comma seperated so that multiple
            // fields can be pulled in.
            var fieldSplit = series.datasourcefield.split(',');

            // set up series colours first
            var i = 0;
            angular.forEach(fieldSplit, function (field) {
              addSeriesColor(i);
              i++;
            });

            feed.data.forEach(function (dto) {
              angular.forEach(fieldSplit, function (field) {
                if (angular.isDefined(dto.alldata[field]) == false) {
                  return;
                }

                if (dto.alldata[field] == null) {
                  data.push(null);
                } else {
                  var val = parseFloat(dto.alldata[field]);

                  if (series.groupbyfield != null && series.groupbyfield != '') {
                    if (series.fieldformat == null) {
                      series.fieldformat = '';
                    }

                    var gf = $interpolate(series.fieldformat, true, null, true);

                    if (angular.isDefined(gf) == false) {
                      gf = series.groupbyfield;
                    } else if (gf == null) {
                      gf = series.groupbyfield;
                    } else {
                      var params = {
                        value: dto.alldata[series.groupbyfield]
                      };


                      gf = gf(params);
                    }

                    // we seperate each field out into it's own group
                    gf = field + '_' + gf;

                    if (angular.isDefined(groupedData[gf]) == false) {
                      groupedData[gf] = 0;
                    }

                    groupedData[gf] = groupedData[gf] + val;
                  } else {
                    data.push(val);
                  }
                }
              });
            });

            if (series.groupbyfield != null) {
              angular.forEach(groupedData, function (val) {
                data.push(val);
              });
            }

            scope.addSeries(series.name, data);
          };

          if (angular.isDefined(f.data) && f.data.length > 0) {
            plotData(f);
          }

          series.feed.addAfterLoadHook(function (feed) {
            plotData(feed);
          });
        });
      }
    };

    if (series.type == "datasource") {
      addFeedSeries(scope, series, bworkflowApi, $interpolate);
    } else {
      addArraySeries(scope, series, bworkflowApi);
    }
  }
};


var chartAxis = {
  link: function (scope, series, bworkflowApi, $interpolate) {

    scope.labels = [];

    var addArrayAxis = function (scope, series, bworkflowApi) {
      angular.forEach(series.data, function (d) {
        scope.labels.push(d);
      });
    };

    var addFeedAxis = function (scope, series, bworkflowApi, $interpolate) {
      var promise = bworkflowApi.getDataFeed(series.datasource);

      if (promise != null) {
        promise.then(function (f) {
          series.feed = f;

          var addLabels = function (feed) {
            var data = [];

            feed.data.forEach(function (dto) {
              if (angular.isDefined(dto.alldata[series.datasourcefield]) == false) {
                return;
              }

              if (dto.alldata[series.datasourcefield] == null) {
                data.push(null);
              } else {
                var val = dto.alldata[series.datasourcefield];

                if (series.fieldformat != null && series.fieldformat != '') {
                  var result = $interpolate(series.fieldformat, true, null, true);

                  if (angular.isDefined(result) == false) {
                    // this means the embedded expressions haven't been fully resolved (so the variables aren't defined)
                    // not much we can do
                    data.push(val);
                  } else if (result == null) {
                    // this means there are no embedded expressions in the format, so we go with the raw val
                    data.push(val);
                  } else {
                    // we may need to do some work on the parameters given to us (transpose them to internal names or pre query processing)
                    var params = {
                      value: val
                    };


                    data.push(result(params));
                  }

                } else {
                  data.push(val);
                }
              }
            });

            scope.labels = data;
          };

          if (angular.isDefined(f.data) && f.data.length > 0) {
            addLabels(f);
          }

          series.feed.addAfterLoadHook(function (feed) {
            addLabels(feed);
          });
        });
      }
    };

    if (series.type == "datasource") {
      addFeedAxis(scope, series, bworkflowApi, $interpolate);
    } else {
      addArrayAxis(scope, series, bworkflowApi);
    }
  }
};


questionsModule.directive('questionLineChart', ['$timeout', 'bworkflowApi', '$interpolate', 'languageTranslate', function ($timeout, bworkflowApi, $interpolate, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_linechart.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.seriesCache = {};
      scope.data = [];
      scope.labels = [];
      scope.chartType = 'chart-' + scope.presented.highchart.chart.type;

      // default colours, we pick these based on index and the series index if the series doesn't specify a color
      scope.defaultColors = ['#803690', '#00ADF9', '#DCDCDC', '#46BFBD', '#FDB45C', '#949FB1', '#4D5360'];

      // the colors actually used (drawn from defaultColors or the series color)
      scope.colors = [];

      scope.addSeries = function (name, data) {
        if (angular.isDefined(scope.seriesCache[name]) == false) {
          scope.data.push([]);
          scope.seriesCache[name] = {
            name: name,
            index: scope.data.length
          };

        }

        scope.data[scope.seriesCache[name].index - 1] = data;
      };

      if (angular.isDefined(scope.presented.highchart.xAxis.labels) && scope.presented.highchart.xAxis.labels != null) {
        chartAxis.link(scope, scope.presented.highchart.xAxis.labels, bworkflowApi, $interpolate);
      }

      angular.forEach(scope.presented.highchart.series, function (s) {
        chartSeries.link(scope, s, bworkflowApi, $interpolate);
      });
    }
  });

}]);

questionsModule.directive('questionPieChart', ['$timeout', 'languageTranslate', function ($timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_piechart.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, undefined, languageTranslate);
      $timeout(function () {
        var chartOpts = scope.presented.highchart;
        chartOpts.chart.renderTo = $(elt).find('.piechart')[0];
        chartOpts.chart.width = $(chartOpts.chart.renderTo).width();
        var hc = new Highcharts.Chart(chartOpts);
      });
    }
  });

}]);

questionsModule.directive('questionNewsFeedList', ['$timeout', 'languageTranslate', function ($timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_news_feed_list.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, undefined, languageTranslate);

    }
  });

}]);

questionsModule.factory('taskListService', [function () {
  var svc = {
    currentTask: null,
    setCurrentTask: function (task) {
      svc.currentTask = task;
    }
  };

  return svc;
}]);


questionsModule.factory('safeBeaconService', ['$injector', function ($injector) {
  if ($injector.has('beaconSvc')) {
    return $injector.get('beaconSvc');
  } else {
    return {
      notsupported: true
    };

  }
}]);

questionsModule.factory('createNewTaskWorkflow', ['$q', 'bworkflowApi', 'taskTypesService', function ($q, bworkflowApi, taskTypesSvc) {
  var _taskTypes;
  taskTypesSvc.getTaskTypes().then(function (taskTypes) {
    _taskTypes = taskTypes;
  });

  return {
    createAndClockIn: function (template, userid, enforceSingleClockin) {
      var deferred = $q.defer();
      var loc = angular.copy(bworkflowApi.currentLocation());

      if (!template.basicdetails) {
        template.basicdetails = {};
      }

      if (!template.basicdetails.name || template.basicdetails.name == '') {
        // give the new task a default name that is the task type
        template.basicdetails.name = template.text;
      }

      var parameters = {
        userid: userid,
        template: template,
        clockin: true,
        location: loc,
        enforcesingleclockin: enforceSingleClockin
      };


      bworkflowApi.execute('TaskListManagement', 'NewTask', parameters, 5000).then(
        function (data) {
          deferred.resolve(data);
        },
        function (reason) {
          deferred.reject(reason);
        },
        function (notify) {
          deferred.notify(notify);
        });


      return deferred.promise;
    }
  };

}]);

questionsModule.run(['bworkflowApi', '$rootScope', function (bworkflowApi, $rootScope) {
  $rootScope.$on('dashboard.show', function () {
    var cacheExecutor = bworkflowApi.registerODataCacheExecutor({
      key: 'sites.searchByName',
      feed: 'Members',
      query: {
        $filter: 'IsASite eq true',
        $orderby: 'Name',
        $select: 'UserId,Name',
        datascope: {
          ExcludeOutsideHierarchies: false,
          IncludeCurrentUser: true,
          ExcludeExpired: true
        }
      },


      filter: function (data, params) {
        return data.filter(function (r) {
          return r.Name.toLowerCase().indexOf(params.searchmemberssearch.toLowerCase()) >= 0;
        });
      },
      expireSeconds: 60 * 60
    });

    cacheExecutor.refresh();
  });
}]);


questionsModule.directive('questionMultiplefacilityorder', ['$timeout', '$sce', 'orderSvc', 'bworkflowApi', 'languageTranslate', function ($timeout, $sce, orderSvc, bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_multiplefacilityorder.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.answer = scope.presented.Answer;
    }
  });

}]);

questionsModule.directive('questionTaskBatchOperations', ['$timeout', 'languageTranslate', function ($timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_task_batch_operations.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, undefined, languageTranslate);

    }
  });

}]);

questionsModule.directive('questionTaskSchedule', ['bworkflowApi', '$interval', '$sce', '$timeout', 'languageTranslate', function (bworkflowApi, $interval, $sce, $timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_task_schedule.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.init = function () {
        scope.answer = {};
        scope.answer.state = 'loading';
      };

      scope.getschedule = function (criteria) {
        scope.init();

        var parameters = {
          userid: scope.presented.userid,
          criteria: criteria
        };


        bworkflowApi.execute('TaskScheduleView', 'GetSchedule', parameters).
          then(function (data) {
            scope.answer.hourwidth = 50;

            scope.answer.hours = [];
            for (var i = 0; i < 24; i++) {
              scope.answer.hours.push(i);
            }

            scope.answer.schedule = data;
            scope.answer.state = "loaded";
          }, function (tasks) {

          });
      };

      scope.datetimeAsSeconds = function (time) {
        var startOfDay = moment(time).startOf('day');

        return moment(time).diff(startOfDay, 'seconds');
      };

      scope.getschedule(scope.presented.criteria);
    }
  });

}]);

questionsModule.directive('questionTaskAdministration', ['bworkflowApi', '$sce', 'languageTranslate', function (bworkflowApi, $sce, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_task_administration.html',
    link: function (scope, element, attrs, ngModel) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.criteria = {};

      scope.editingScheduledTask = false;
      scope.currentScheduledTask = null;

      scope.applyFormatsToScheduledTask = function (value) {
        value.calendarrule.startdate = moment(value.calendarrule.startdate).format('DD-MM-YYYY');

        if (value.calendarrule.enddate != null) {
          value.calendarrule.enddate = moment(value.calendarrule.enddate).format('DD-MM-YYYY');
        }
      };

      scope.loadTasks = function () {
        if (angular.isDefined(scope.criteria.projectid) == false) {
          return;
        }

        if (angular.isDefined(scope.criteria.userid) == false) {
          return;
        }

        bworkflowApi.execute('TaskAdministration', 'GetTasks', scope.criteria).
          then(function (data) {

            angular.forEach(data.scheduledtasks, function (value, key) {
              scope.applyFormatsToScheduledTask(value);
            });

            scope.schedules = data.scheduledtasks;
          }, function (tasks) {

          });
      };

      scope.editScheduledTask = function (s) {
        scope.currentScheduledTask = s;
        scope.editingScheduledTask = true;
      };

      scope.newScheduledTask = function () {
        if (angular.isDefined(scope.criteria.projectid) == false) {
          return;
        }

        if (angular.isDefined(scope.criteria.userid) == false) {
          return;
        }

        scope.currentScheduledTask = {
          name: 'New Task',
          projectid: scope.criteria.projectid,
          userid: scope.criteria.userid,
          calendarrule: {
            frequency: 'Daily',
            startdate: moment().format('DD-MM-YYYY'),
            enddate: null,
            sunday: false,
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false
          }
        };



        scope.editingScheduledTask = true;
      };

      scope.saveScheduledTask = function (s) {
        var copy = angular.copy(s);

        copy.calendarrule.startdate = moment(s.calendarrule.startdate, 'DD-MM-YYYY').format();
        if (s.calendarrule.enddate != null) {
          copy.calendarrule.enddate = moment(s.calendarrule.enddate, 'DD-MM-YYYY').format();
        }

        bworkflowApi.execute('TaskAdministration', 'SaveScheduledTask', copy).
          then(function (data) {
            scope.applyFormatsToScheduledTask(data);

            angular.copy(data, s);
          }, function (tasks) {

          });
      };
    }
  });

}]);

questionsModule.directive('questionTimesheetApproval', ['bworkflowApi', '$interval', '$sce', '$timeout', 'languageTranslate', function (bworkflowApi, $interval, $sce, $timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_timesheet_approval.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.init = function () {
        scope.answer = {};
        scope.answer.criteriastate = 'loading';
        scope.answer.selectedcriteria = {};

        scope.answer.timesheetstate = 'empty';

        scope.answer.timesheetdetails = {};
      };

      scope.getcriteria = function () {
        scope.init();

        var parameters = {
          userid: scope.presented.userid
        };


        bworkflowApi.execute('TimesheetApproval', 'GetCriteria', parameters).
          then(function (data) {
            scope.answer.availablecriteria = data;
            scope.answer.selectedcriteria.startdate = moment(data.startdate).format('DD-MM-YYYY');
            scope.answer.selectedcriteria.enddate = moment(data.enddate).format('DD-MM-YYYY');

            scope.answer.criteriastate = "loaded";
          }, function (tasks) {

          });
      };

      scope.gettimesheet = function (criteria) {
        criteria.userid = scope.presented.userid;

        bworkflowApi.execute('TimesheetApproval', 'GetTimesheet', criteria).
          then(function (data) {
            scope.answer.timesheet = data;
            scope.answer.timesheetstate = 'loaded';
          }, function (tasks) {

          });
      };

      scope.formatDate = function (date) {
        return moment(date).format('DD-MM-YYYY');
      };

      scope.formatTime = function (time) {
        if (time == null) {
          return "no entries";
        }

        return moment().startOf('day').seconds(time).format('H:mm:ss');
      };

      scope.hummanizeTime = function (timeAsString) {
        if (timeAsString == null || timeAsString == '') {
          return '';
        }

        if (moment(timeAsString, 'HH:mm:ss').isValid() == false) {
          return "invalid time";
        }

        return moment.duration(timeAsString).format('h [hrs] m [min]');
      };

      scope.datetimeAsSeconds = function (time) {
        var startOfDay = moment(time).startOf('day');

        return moment(time).diff(startOfDay, 'seconds');
      };

      scope.timeAsSeconds = function (time) {
        var startOfDay = moment(time, 'HH:mm:ss').startOf('day');

        return moment(time, 'HH:mm:ss').diff(startOfDay, 'seconds');
      };

      scope.approveforuser = function (item) {
        var parameters = {
          userid: scope.presented.userid,
          projectid: scope.answer.selectedcriteria.projectid,
          startdate: scope.answer.selectedcriteria.startdate,
          enddate: scope.answer.selectedcriteria.enddate,
          empid: item.userid
        };


        bworkflowApi.execute('TimesheetApproval', 'EditTimesheetForUser', parameters).
          then(function (data) {
            angular.copy(data.items[0], item);
          }, function (tasks) {

          });
      };

      scope.showdetails = function (item, log) {
        var parameters = {
          userid: scope.presented.userid,
          empid: item.userid,
          date: log.date,
          projectid: scope.answer.selectedcriteria.projectid
        };


        bworkflowApi.execute('TimesheetApproval', 'GetTimesheetDetail', parameters).
          then(function (data) {
            scope.answer.timesheetdetails.projectname = data.projectname;
            scope.answer.timesheetdetails.totalduration = data.totalduration;
            scope.answer.timesheetdetails.list = data.list;
            scope.answer.timesheetdetails.timesheet = data.timesheet;
            scope.answer.timesheetdetails.approvedDuration = '';

            if (data.timesheet.duration != null) {
              scope.answer.timesheetdetails.approvedDuration = scope.formatTime(data.timesheet.duration);
            }

            scope.answer.timesheetdetails.rowtitle = data.rowtitle;

            scope.answer.current = {};
            scope.answer.current.item = item;
            scope.answer.current.log = log;

            scope.answer.timesheetdetails.name = item.name;
            scope.answer.timesheetdetails.date = log.date;
            scope.answer.timesheetdetails.hourwidth = 50;
            scope.answer.timesheetdetails.hours = [];
            for (var i = 0; i < 24; i++) {
              scope.answer.timesheetdetails.hours.push(i);
            }

            scope.answer.showdetails = true;

            $timeout(function () {
              elt.find('.timesheetdetails').modal('show');
            });
          }, function (tasks) {

          });
      };

      scope.saveandclosedetails = function (timesheetdetails) {
        // we need to manage the duration variable 
        if (timesheetdetails.approvedDuration == null || timesheetdetails.approvedDuration == "") {
          timesheetdetails.timesheet.duration = null;
        } else {

          if (moment(timesheetdetails.approvedDuration, 'HH:mm:ss').isValid() == false) {
            return;
          }

          timesheetdetails.timesheet.duration = scope.timeAsSeconds(timesheetdetails.approvedDuration);
        }

        scope.edittimesheetdetail(timesheetdetails.timesheet);

        elt.find('.timesheetdetails').modal('hide');
      };

      scope.edittimesheetdetail = function (item) {

        item.userid = scope.presented.userid;

        bworkflowApi.execute('TimesheetApproval', 'EditTimesheetDetail', item).
          then(function (data) {

            var parameters = {
              userid: scope.presented.userid,
              projectid: scope.answer.selectedcriteria.projectid,
              empid: scope.answer.current.item.userid,
              startdate: moment(scope.answer.current.log.date).format('DD-MM-YYYY')
            };


            bworkflowApi.execute('TimesheetApproval', 'GetTimesheet', parameters).
              then(function (data) {
                if (data.items.length > 0) {
                  angular.copy(data.items[0].logs[0], scope.answer.current.log);
                } else {
                  // so there is no clock in or a timesheet for the user in the project on the day (the server has nothing), we'll blank things out ourselves
                  scope.answer.current.log.actualduration = null;
                  scope.answer.current.log.approvedduration = null;
                }

              }, function (tasks) {

              });

          }, function (tasks) {

          });
      };

      scope.getcriteria();
    }
  });

}]);

questionsModule.directive('uniqueusername', ['bworkflowApi', function (bworkflowApi) {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      element.blur(function () {
        var parameters = {
          username: element.val()
        };


        bworkflowApi.execute('CompanySummary', 'UsernameExists', parameters).
          then(function (data) {
            ngModel.$setValidity('uniqueusername', !data.exists);
          }, function (tasks) {
            ngModel.$setValidity('uniqueusername', false);
          });
      });
    }
  };

}]);

questionsModule.directive('questionCompanySummary', ['bworkflowApi', '$interval', '$sce', '$timeout', 'languageTranslate', function (bworkflowApi, $interval, $sce, $timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_company_summary.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.init = function () {
        scope.answer = {};
        scope.answer.state = 'loading';
      };

      scope.getsummary = function (criteria) {
        scope.init();

        var parameters = {
          userid: scope.presented.userid,
          criteria: criteria
        };


        bworkflowApi.execute('CompanySummary', 'GetSummary', parameters).
          then(function (data) {

            scope.answer.availableusertypes = data.availableusertypes;
            scope.answer.company = data;
            scope.answer.users = data.users;
            scope.answer.state = "loaded";
          }, function (tasks) {

          });
      };

      scope.getusers = function (criteria) {
        scope.init();

        var parameters = {
          userid: scope.presented.userid
        };


        bworkflowApi.execute('CompanySummary', 'GetUsers', parameters).
          then(function (data) {

            scope.answer.users = data.users;
            scope.answer.state = "loaded";
          }, function (tasks) {

          });
      };

      scope.shownewuserdialog = function () {
        scope.answer.currentuser = {};

        scope.answer.currentuser.name = '';
        scope.answer.currentuser.username = '';
        scope.answer.currentuser.email = '';
        scope.answer.currentuser.password = '';
        scope.answer.currentuser.confirmpassword = '';
        scope.answer.currentuser.type = '';
        scope.answer.currentuser.exists = false;

        scope.answer.shownewuser = true;

        $timeout(function () {
          elt.find('.newuserdialog').modal('show');
        });
      };

      scope.createnewuser = function (newuser) {
        var params = {
          userid: scope.presented.userid,
          user: newuser
        };


        bworkflowApi.execute('CompanySummary', 'NewUser', params).
          then(function (data) {
            newuser.exists = false;

            scope.answer.users.push(data);

            $timeout(function () {
              elt.find('.newuserdialog').modal('hide');
            });
          }, function (tasks) {

          });
      };

      scope.$on('help_add_steps', function (event, args) {
        args.push({
          element: '#companysummary-address-' + scope.presented.Id,
          intro: 'You can view the address information currently stored in the system for the company',
          position: 'bottom'
        });

        args.push({
          element: '#companysummary-add-' + scope.presented.Id,
          intro: 'You can add new employees to your company using the add button',
          position: 'left'
        });

        args.push({
          element: '#companysummary-employees-' + scope.presented.Id,
          intro: 'Employees already in the company will be displayed in company employees table',
          position: 'top'
        });

      });

      scope.getsummary(scope.presented.criteria);
    }
  });

}]);




// a directive that models editing a company.
questionsModule.directive('editcompany', ['bworkflowApi', 'cachedExecutionHandlers', '$sce', '$timeout', 'leafletData', '$filter', '$interval', function (bworkflowApi, cachedExecutionHandlers, $sce, $timeout, leafletData, $filter, $interval) {
  return {
    templateUrl: 'tasks/editcompany.html',
    link: function (scope, element, attrs, ngModel) {
      scope.map = {};

      scope.map.center = {
        lat: 52.374004,
        lng: 4.890359,
        zoom: 1
      };


      scope.map.markers = [];

      scope.map.show = false;

      scope.positionMap = function (location) {
        // the address lookup uses lon, but everything else uses lng, so jump a small hurdle to cope with that
        if (angular.isDefined(location.lng) == false) {
          location.lng = location.lon;
        }

        var map = scope.map;

        map.center.lat = parseFloat(location.lat);
        map.center.lng = parseFloat(location.lng);
        map.center.zoom = 16;

        if (map.companymarker == null) {
          map.companymarker = {
            lat: map.center.lat,
            lng: map.center.lng,
            focus: true,
            draggable: true,
            message: scope.company.name
          };


          map.markers.push(map.companymarker);
        }

        map.companymarker.lat = map.center.lat;
        map.companymarker.lng = map.center.lng;

        scope.map.show = true;
      };

      var pending;
      scope.startpendinggeocode = function () {
        scope.stoppendinggeocode();

        pending = $interval(function () {
          scope.geocode();
        }, 1000); // we'll wait a second for something to cancel the pending lookup
      };

      scope.stoppendinggeocode = function () {
        if (angular.isDefined(pending)) {
          $interval.cancel(pending);
          pending = undefined;
        }
      };

      scope.geocode = function (isAlternateSearch) {
        if (scope.company.address.street == null || scope.company.address.town == null || scope.company.address.stateid == null || scope.company.address.postcode == null) {
          scope.map.show = false;
          return;
        }

        var alternateSearch = false;
        if (angular.isDefined(isAlternateSearch) == true) {
          alternateSearch = isAlternateSearch;
        }

        if (alternateSearch == false && scope.company.address.street == scope.lastaddress.street && scope.company.address.town == scope.lastaddress.town && scope.company.address.stateid == scope.lastaddress.stateid && scope.company.address.postcode == scope.lastaddress.postcode) {
          // nothing has changed
          return;
        }

        var company = scope.company;

        var state = $filter('filter')(scope.countrystates, {
          id: company.address.stateid
        },
          true)[0];

        if (alternateSearch == false) {
          scope.locationoperation = 'primarysearch';
        }

        scope.lastaddress = angular.copy(scope.company.address);

        bworkflowApi.geolocate(alternateSearch == true ? "" : company.address.street, company.address.town, company.address.postcode, state.name, 'australia', scope.presented.geocodeurl).
          then(function (data) {
            if (data.length == 0) {
              if (alternateSearch == false) {
                // lets try with an alternative search by cutting of the street info
                scope.locationoperation = 'alternatesearch';

                $timeout(function () {
                  scope.geocode(true);
                });
              } else {
                scope.locationoperation = 'alternatefailed';
              }

              return;
            }


            if (alternateSearch == false) {
              scope.locationoperation = null;
            } else {
              scope.locationoperation = 'alternatefound';
            }

            scope.positionMap(data[0]);
          }, function (data) { });
      };

      scope.setcompany = function (data) {
        scope.company = angular.copy(data.company);
        scope.lastaddress = angular.copy(data.company.address);
        scope.original = data.company;

        scope.locationoperation = null;
      };

      // we can be notified to show a different company with an event
      scope.$on('editcompany', function (evt, data) {
        scope.setcompany(data);
      });

      scope.$on('editcompany-shown', function (evt, data) {
        scope.map.show = false;

        $timeout(function () {
          leafletData.getMap().then(function (map) {
            map.invalidateSize();

            if (scope.company.location.lat != null && scope.company.location.lng != null) {
              scope.positionMap(scope.company.location);
            }
          });
        });
      });

      scope.$on('editcompany-save', function (evt, data) {
        // we copy our data across
        angular.copy(scope.company, scope.original);

        if (scope.map.companymarker != null) {
          scope.original.location.lat = scope.map.companymarker.lat;
          scope.original.location.lng = scope.map.companymarker.lng;
        }

      });

      if (ngModel != null) {
        scope.setcompany(ngModel);
      }

      cachedExecutionHandlers.getAllCountryStates().then(function (data) {
        scope.countrystates = data;
      });
    }
  };

}]);


questionsModule.directive('questionCompanyDirectory', ['bworkflowApi', '$interval', '$sce', '$timeout', 'leafletData', '$filter', 'languageTranslate', function (bworkflowApi, $interval, $sce, $timeout, leafletData, $filter, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_company_directory.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.init = function () {
        scope.searchtext = '';
        scope.page = 1;
        scope.count = 10;
        scope.state = 'loading';

        var parameters = {
          userid: scope.presented.userid
        };


        bworkflowApi.execute('CountryStates', 'GetAll', parameters).
          then(function (data) {
            scope.countrystates = data.countrystates;
          }, function (tasks) {

          });
      };

      scope.initcompany = function (company) {
        if (company.location.lat != null) {
          company.mapcenter = {
            lat: parseFloat(company.location.lat),
            lng: parseFloat(company.location.lng),
            zoom: 16
          };

          company.mapmarkers = [];
          company.mapmarkers.push(company.mapcenter);
        }

        company.ratingInitialised = false;
      };

      scope.searchcompanies = function (text) {
        var parameters = {
          userid: scope.presented.userid,
          text: text,
          page: scope.page,
          count: scope.count
        };


        bworkflowApi.execute('CompanyDirectory', 'Search', parameters).
          then(function (data) {
            angular.forEach(data.companies, function (value, key) {
              scope.initcompany(value);

              // event handler hook up to perform the saving of ratings if changed by the user
              scope.$watch('companies[' + key + '].rating', function (newValue, oldValue) {
                var company = scope.companies[key];

                // this is getting raised when items are loaded in, we don't want to go back and save them as nothing
                // has actually changed (old was null, new is what ever). Use the ratingInitialised property to detect
                // the first hit and get out
                if (company.ratingInitialised == false) {
                  company.ratingInitialised = true;
                  return;
                }

                var params = {
                  userid: scope.presented.userid,
                  companyid: company.id,
                  rating: newValue
                };

                bworkflowApi.execute('CompanyDirectory', 'SaveRating', params);
              });
            });

            scope.companies = data.companies;
          }, function (tasks) {

          });
      };

      scope.showeditcompany = function (company) {
        scope.currentcompany = company;

        $timeout(function () {
          scope.$broadcast('editcompany', {
            company: company,
            countrystates: scope.countrystates
          });


          // we have to hook into the shown event and notify the edit company directive its visible as
          // leaflet map has problems sizing its self unless its visible
          var modal = elt.find('.editcompanydialog').modal('show');

          modal.on('shown', function () {
            scope.$broadcast('editcompany-shown', {});
          });

          modal.on('hidden', function () {
            modal.off('shown');
            modal.off('hidden');
          });
        });
      };

      scope.savecompany = function () {
        scope.$broadcast('editcompany-save', {});

        // we need to let the above happen
        $timeout(function () {
          var parameters = {
            userid: scope.presented.userid,
            company: scope.currentcompany
          };


          bworkflowApi.execute('CompanyDirectory', 'Save', parameters).
            then(function (data) {

              scope.initcompany(data.company);
              angular.copy(data.company, scope.currentcompany);


              elt.find('.editcompanydialog').modal('hide');

              scope.iseditngcompany = false;
            }, function (tasks) {

            });
        });
      };

      scope.mapdefaults = {
        attributionControl: false,
        zoomControl: false
      };


      scope.init();
    }
  });

}]);

questionsModule.directive('questionJobUsersSignature', ['$timeout', 'bworkflowApi', 'languageTranslate', function ($timeout, bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_job_users_signature.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.signaturedimensions = {
        width: 200,
        height: 100
      };


      scope.answer = scope.presented.Answer;

      scope.$on('signatureReady', function (ev, elt, user) {
        var hidden = elt.find("input");
        var canvas = elt.find("canvas");
        var sigWrapper = elt.find(".sigWrapper");

        elt.attr("style", "width:" + scope.signaturedimensions.width + "px");
        sigWrapper.attr("style", "height:" + scope.signaturedimensions.height + "px");
        canvas.attr("width", scope.signaturedimensions.width);
        canvas.attr("height", scope.signaturedimensions.height);

        user.signaturePad = elt.signaturePad({
          output: hidden,
          validateFields: false,
          drawOnly: true,
          lineTop: scope.signaturedimensions.height - 20
        });


        //if (scope.answer.Signature != null && scope.answer.Signature != "") {
        //    scope.signaturePad.regenerate(scope.answer.Signature);
        //}

        ev.stopPropagation();
      });

      scope.clearSignature = function (user) {
        user.signaturePad.clearCanvas();
      };

      scope.$on('populateAnswer', function (ev) {
        scope.answer.signatures = [];

        angular.forEach(scope.users, function (value, key) {
          if (value.selected == true) {
            sign = {
              userid: value.id,
              signature: value.signaturePad.getSignatureString()
            };

            scope.answer.signatures.push(sign);
          }
        });
      });

      scope.loadAvailableUsers = function () {
        var parameters = {
          userid: scope.presented.userid,
          workingdocumentid: scope.presented.workingdocumentid
        };


        bworkflowApi.execute('ProjectJobUsersSignOnGlass', 'GetAvailableUsers', parameters).
          then(function (data) {
            scope.users = data.users;
          });
      };

      scope.loadAvailableUsers();
    }
  });

}]);


questionsModule.directive('questionMergedhierarchicaldatasource', ['$timeout',
  'bworkflowApi', '$filter', 'languageTranslate',
  function ($timeout, bworkflowApi, $filter, languageTranslate) {
    return $.extend({}, questionDirectiveBase, {
      templateUrl: 'question_mergedhierarchicaldatasource.html',
      link: function (scope, elt, attrs) {
        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
        if (scope.presented.Name == null || scope.presented.Name == '') {
          alert('A merged hierarchical datasource requires a name for it to function');
          return;
        }

        // we attempt to build up the following hierarchy structure
        //
        // Task
        //   Checklist
        //     ChecklistResult
        //     ChecklistMediaStream
        //   TaskWorkLog
        //
        // or part there of (so for example if ChecklistMediaStream isn't supplied we
        // build up the rest of the hierarchy ommitting the media stream. 
        // If a high level item such as the task isn't available we'll build around the checklist.
        // If we have a Task feed and no checklist feed, but a checklistresult, then the checklist result
        // will feature at the level of the checklist.

        // the structure we create is hard coded based on the feeds we have.
        // we currently only support a single feed of each type. We keep direct
        // pointers to each of these to keep managing the hierarchy simple
        scope.relationships = [];

        scope.feed = {
          notifier: {
            refreshes: 0,
            id: scope.presented.Name
          },

          data: []
        };


        scope.waitingCount = 0;

        // builds a map of rows in the data of the feed mapped to the relationship
        // by parentids
        scope.buildRelationshipHash = function (relationship) {
          if (relationship.isTop == true) {
            return;
          }

          relationship.mappedData = {};

          angular.forEach(relationship.feed.data, function (item) {
            var key = item.alldata[relationship.foreignKey.field];

            if (angular.isDefined(relationship.mappedData[key]) == false) {
              relationship.mappedData[key] = [];
            }

            relationship.mappedData[key].push(item);
          });
        };

        scope.buildRelationshipHashes = function () {
          angular.forEach(scope.relationships, function (relationship) {
            scope.buildRelationshipHash(relationship);
          });
        };

        scope.traverseRelationshipThread = function (current) {
          var children = $filter('filter')(scope.relationships, {
            parent: current.id
          },
            true);

          if (children.length == 0) {
            return;
          }

          var data = current.feed.data;

          for (var i = 0; i < data.length; i++) {
            var item = data[i];

            if (angular.isDefined(item.children) == false) {
              item.children = {};
            }

            for (var j = 0; j < children.length; j++) {
              var child = children[j];

              item.children[child.feed.template.name] = {
                template: child.feed.template,
                data: child.mappedData[item.alldata[child.foreignKey.mapsToParentField]]
              };


              scope.traverseRelationshipThread(child);
            }
          }
        };

        scope.buildHierarchy = function (feed) {
          // we only rebuild the hierarchy if the top level feed 
          if (scope.waitingCount > 0) {
            // we aren't doing all the work of putting the hierarchy together
            // until we have all the data we are expecting. We essentially use reference
            // counting to know this
            return;
          }

          var topRelationship = null;
          angular.forEach(scope.relationships, function (relation) {
            if (relation.canBeTop == true) {
              if (topRelationship == null || topRelationship.parent != null && relation.parent == null) {
                topRelationship = relation;
              }
            }
          });

          if (topRelationship == null) {
            console.warn(scope.presented.Name + ' can not build a hierarchy as there is no top relationship feed supplied');
            return;
          }

          topRelationship.isTop = true;

          scope.buildRelationshipHashes();

          scope.traverseRelationshipThread(topRelationship);
        };

        if (scope.presented.datasource) {
          var split = scope.presented.datasource.split(',');

          scope.waitingCount = split.length;

          // we store each of the feeds in a varibale based on their name
          angular.forEach(split, function (source) {
            var promise = bworkflowApi.getDataFeed(source);

            if (promise != null) {
              promise.then(function (feed) {
                scope.waitingCount = scope.waitingCount - 1;

                switch (feed.template.feed) {
                  case "Tasks":
                    scope.relationships.push({
                      id: 'task',
                      canBeTop: true,
                      feed: feed,
                      parent: null,
                      foreignKey: null
                    });

                    break;
                  case "TaskWorklogs":
                    scope.relationships.push({
                      id: 'worklog',
                      canBeTop: false,
                      feed: feed,
                      parent: 'task',
                      foreignKey: {
                        field: 'TaskId',
                        mapsToParentField: 'Id'
                      }
                    });


                    break;
                  case "Checklists":
                    scope.relationships.push({
                      id: 'checklist',
                      canBeTop: true,
                      feed: feed,
                      parent: 'task',
                      foreignKey: {
                        field: 'TaskId',
                        mapsToParentField: 'Id'
                      }
                    });


                    break;
                  case "ChecklistResults":
                    scope.relationships.push({
                      id: 'checklistresults',
                      canBeTop: false,
                      feed: feed,
                      parent: 'checklist',
                      foreignKey: {
                        field: 'WorkingDocumentId',
                        mapsToParentField: 'WorkingDocumentId'
                      }
                    });


                    break;
                  case "ChecklistMediaStream":
                    scope.relationships.push({
                      id: 'checklistmediastream',
                      canBeTop: false,
                      feed: feed,
                      parent: 'checklist',
                      foreignKey: {
                        field: 'WorkingDocumentId',
                        mapsToParentField: 'WorkingDocumentId'
                      }
                    });


                    break;
                }


                feed.beforeLoadHooks.push(function (f) {
                  scope.waitingCount = scope.waitingCount + 1;
                });

                feed.afterLoadHooks.push(function (f) {
                  scope.waitingCount = scope.waitingCount - 1;

                  if (scope.waitingCount < 0) {
                    scope.waitingCount = 0;
                  }

                  scope.buildHierarchy();
                });

                // try to build the hierarchy with the feeds we now have
                scope.buildHierarchy();
              });
            }
          });
        }
      }
    });

  }]);


questionsModule.factory('liveBeaconSvc', ['$injector', function ($injector) {
  if (window && window.cordova) {
    return angular.extend({
      isSupported: true
    },
      $injector.get('beaconSvc'));
  } else {
    return {
      isSupported: false
    };

  }
}]);

questionsModule.directive('questionMapping', ['$injector', '$timeout', '$interpolate', '$parse',
  'bworkflowApi', '$filter', 'leafletData', 'liveBeaconSvc', '$geolocation', 'VmAdminClasses', 'L-destroyer', 'directiveApi', 'geolocation-registry', 'languageTranslate',
  function ($injector, $timeout, $interpolate, $parse, bworkflowApi, $filter, leafletData, liveBeaconSvc, $geolocation, VmAdmin, Ldestroyer, directiveApi, geolocationRegistry, languageTranslate) {
    return $.extend({}, questionDirectiveBase, {
      templateUrl: 'question_mapping.html',
      link: function (scope, elt, attrs) {
        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

        scope.beaconSvc = null;

        scope.LocationProviders = geolocationRegistry.byindex;
        scope.LocationProvidersByName = geolocationRegistry.byname;

        $geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).
          then(function (position) {
            scope.GPS = $geolocation;
            if (scope.GPS) {
              geolocationRegistry.add('GPS', 0, 'GPS', $geolocation.position);

              // Default BestEstimate to GPS (this is fine for Desktop, VMPlayer will override this)
              var be = geolocationRegistry.add('BestEstimate', 6, 'Best Estimate', $geolocation.position);
              be.isBestEstimate = true;
            }
          });

        var _followPositionWatch = null;
        scope.followPosition = function (source) {
          scope.unfollowPosition();

          source = source || "BestEstimate";
          _followPositionWatch = scope.$watch('LocationProvidersByName.' + source + '.position.coords.floorPlanId', function (newValue) {
            if (angular.isDefined(newValue) && angular.isDefined(scope.floorplans)) {
              var newFloorPlan = scope.floorplans[newValue];
              if (newFloorPlan) {
                newFloorPlan.select(true);
              }
            }
          });
        };

        scope.unfollowPosition = function () {
          if (_followPositionWatch) {
            _followPositionWatch();
            _followPositionWatch = null;
          }
        };

        scope.followPosition();

        if (window.razordata.environment == 'mobile') {
          scope.beaconSvc = $injector.get('beaconSvc');
          scope.LiveBeacons = scope.beaconSvc.getBeacons();
        }

        var Ldestroy = Ldestroyer(scope);

        function distanceKms(lat1, lon1, lat2, lon2) {
          var p = 0.017453292519943295; // Math.PI / 180
          var c = Math.cos;
          var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) * (
              1 - c((lon2 - lon1) * p)) / 2;

          return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
        }

        scope.floorplanApi = directiveApi();

        function getMap(fn) {
          scope.floorplanApi.getMap().then(fn);
        }
        scope.getMap = getMap;

        function Building(id, name) {
          var building = this;

          this.id = id;
          this.name = name;
          this.floorplans = [];
          this.selectedFloorplan = null;

          this.contextmenu = [{
            text: '<h5>' + name + '</h5>',
            disabled: true
          }];


          this.Floorplan = function Floorplan(dto) {
            var floorplan = this;
            angular.extend(this, dto);
            this.id = dto.Id;
            this.visible = false;
            this.building = building;

            building.contextmenu.push({
              text: floorplan.Floor.Name,
              iconCls: function () {
                return building.selectedFloorplan === floorplan ? 'icon-ok' : 'icon-blank';
              },
              callback: function () {
                floorplan.select(true); // User selection = Force select floor plan
              }
            });


            var _Limage = null;
            this.Limage = function () {
              if (_Limage == null) {
                _Limage = L.imageOverlay.rotated(
                  floorplan.ImageURL,
                  L.latLng(floorplan.TopLeftLatitude, floorplan.TopLeftLongitude),
                  L.latLng(floorplan.TopRightLatitude, floorplan.TopRightLongitude),
                  L.latLng(floorplan.BottomLeftLatitude, floorplan.BottomLeftLongitude), {
                  opacity: 0.8,
                  interactive: true,
                  contextmenu: true,
                  contextmenuItems: building.contextmenu
                });


              }
              return _Limage;
            };

            building.floorplans.push(floorplan);
            building.defaultFloorplan = floorplan.Floor.Level === 0 ? floorplan : building.defaultFloorplan || floorplan;

            this.select = function (forceSelect) {
              // If Building has a ForceSelected floor plan then ignore this request 
              if (!forceSelect && building.forceSelectedFloorplan) {
                return;
              }

              if (building.selectedFloorplan !== floorplan) {
                if (building.selectedFloorplan != null) {
                  building.selectedFloorplan.Limage().remove();
                  building.selectedFloorplan.visible = false;
                }

                building.selectedFloorplan = floorplan;
                floorplan.visible = true;

                getMap(function (map) {
                  floorplan.Limage().addTo(map);
                });

                Object.keys(scope.dataWatchs).forEach(function (key) {
                  scope.dataWatchs[key].refresh();
                });

                if (forceSelect) {
                  building.forceSelectedFloorplan = floorplan;
                }
              }
            };
          };
        }

        scope.floorplanFeed = bworkflowApi.createDataFeed({
          name: 'floorplans',
          feed: 'FloorPlans',
          filter: '', // Fetch and cache all floorplans
          expandfields: 'Floor',
          orderbyfields: 'BuildingId,Floor/Level',
          idfields: ['Id'],
          usepaging: false,
          parameterdefinitions: []
        },
          scope);

        scope.floorplanFeed.addAfterLoadHook(function (feed) {
          scope.buildings = {};
          scope.floorplans = {};
          feed.data.forEach(function (floorplanDto) {
            var building = scope.buildings[floorplanDto.alldata.BuildingId];
            if (angular.isUndefined(building)) {
              building = new Building(floorplanDto.alldata.BuildingId, floorplanDto.alldata.BuildingName);
              scope.buildings[building.id] = building;
            }
            var floorplan = new building.Floorplan(floorplanDto.alldata);
            building.floorplans.push(floorplan);
            scope.floorplans[floorplan.id] = floorplan;
          });

          Object.keys(scope.buildings).forEach(function (key) {
            var building = scope.buildings[key];
            building.defaultFloorplan.select();
          });
        });
        scope.floorplanFeed.getData(true);

        scope.options = {};
        scope.addOptions = function (options) {
          Object.keys(options).forEach(function (key) {
            var option = options[key];
            if ('icon' in option) {
              if (!(option.icon instanceof L.icon)) {
                // It defines an Icon, create it ..
                var icon = null;
                if ('html' in option.icon) {
                  icon = L.divIcon(option.icon);
                } else {
                  icon = L.icon(option.icon);
                }
                option.icon = icon;
              }
            }
            scope.options[key] = option;
          });
        };

        var fnGetOption = function (value) {
          if (angular.isString(value)) {
            return value in scope.options ? scope.options[value] : angular.undefined;
          } else {
            return value;
          }
        };

        var fnMakeOptions = function (options, dataScope) {
          // option is a Leaflet option definition suitable to the Layer type being rendered (Marker, Polyline, Polygon etc)
          //   options: { option definition }
          //   options: 'option name' 
          //   options: [
          //     ['angular boolean expression 0', ['option name when true' | { option definition when true }, 'option name when false' | { option definition when false} | undefined]]
          //     ['angular integer expression 1', ['option name 0' | { option definition }, 'option name 1' | { option definition }...]]
          //     ['angular string expression 2', { 'string0': 'option name 0' | { option definition }, 'string1': 'option name 1' | { option definition }...}]
          //     ['angular expression which evaluates to either option name or option definition']
          //   ]
          //   options: function(v, mergeOption)

          if (angular.isDefined(options)) {
            //   options: function(v, mergeOption)
            if (angular.isFunction(options)) {
              return function (v, mergeOption) {
                return fnGetOption(options(v, mergeOption));
              };
            } else if (angular.isString(options)) {
              //   options: 'option name' 
              return function (v, mergeOption) {
                return options in scope.options ? scope.options[options] : null;
              };
            } else if (angular.isArray(options)) {
              //   options: [
              //     ['angular boolean expression 0', ['option name when true' | { option definition when true }, 'option name when false' | { option definition when false} | undefined]]
              //     ['angular integer expression 1', ['option name 0' | { option definition }, 'option name 1' | { option definition }...]]
              //     ['angular string expression 2', { 'string0': 'option name 0' | { option definition }, 'string1': 'option name 1' | { option definition }...}]
              //     ['angular expression which evaluates to either option name or option definition']
              //   ]
              var tests = [];
              options.forEach(function (match) {
                var fnExpr = $parse(match[0]);
                var fnValue = fnGetOption; // Handles ['angular expression which evaluates to either option name or option definition']
                var fnContinueMerge = function () {
                  return false;
                };
                if (match.length >= 2) {
                  var values = match[1];
                  //     ['angular integer expression 0', ['option name 0' | { option definition }, 'option name 1' | { option definition }...]
                  //     ['angular string expression 1', { 'string0': 'option name 0' | { option definition }, 'string1': 'option name 1' | { option definition }...}]
                  if (values) {
                    fnValue = function (value) {
                      if (value == true) {
                        return fnGetOption(values[0]);
                      } else if (value == false) {
                        return fnGetOption(values[1]);
                      }
                      return fnGetOption(values[value]);
                    };
                  }

                  if (match.length >= 3) {
                    if (angular.isString(match[2])) {
                      fnContinueMerge = $parse(match[2]);
                    } else {
                      fnContinueMerge = function () {
                        return match[2] ? true : false;
                      };
                    }
                  }
                }
                tests.push({
                  fnExpr: fnExpr,
                  fnValue: fnValue,
                  fnContinueMerge: fnContinueMerge
                });

              });
              return function (v, mergeOption) {
                var option = null;
                tests.find(function (test) {
                  var testValue = test.fnExpr(v, dataScope);
                  if (angular.isDefined(testValue)) {
                    option = test.fnValue(testValue);

                    // extending the mergeOption allows us to define a base set of options and then
                    // have later option selectors merge in extra definitions
                    if (angular.isDefined(option)) {
                      angular.extend(mergeOption, option);
                      return !test.fnContinueMerge(v, dataScope);
                    }
                  }
                  return false;
                });

                return mergeOption;
              };
            } else if (angular.isObject(options)) {
              //   options: { Leaflet options }
              return function (v, mergeOption) {
                return options;
              };
            }
          }
          // fall through
          return function (v, mergeOption) {
            return mergeOption;
          };
        };

        scope.dataWatchs = {};

        var _nextId = 0;

        function DataWatch(data) {
          var feed = null;
          // For feed objects, we only want their data[] ..
          if (data && angular.isFunction(data.addAfterLoadHook)) {
            feed = data;
            data = feed.data;
          }

          var me = this;
          this.floorplanFilter = new FloorplanFilter();

          var id = 'dataWatch' + _nextId++;
          scope.dataWatchs[id] = this;

          this.data = data;
          this.Lobjects = [];
          this.latLngBounds = null;
          this.fitBoundsCount = 1; // Fit bounds 1st time only
          this.visible = false;

          this.init = function (fnLoad, map) {
            me.fnLoad = fnLoad;
            me.map = map;
          };

          this.addLobject = function (Lobject, floorplanId) {
            me.Lobjects.push(Lobject);
            me.floorplanFilter.include(Lobject, floorplanId);
          };

          this.refresh = function () {
            if (this.visible) {
              me.Lobjects.forEach(function (Lobj) {
                var floorplan = Lobj.floorplan;
                if (floorplan) {
                  if (floorplan.visible) {
                    Lobj.addTo(me.map);
                  } else {
                    Lobj.remove();
                  }
                } else {
                  // No associated floor
                  Lobj.addTo(me.map);
                }
              });
            } else {
              me.Lobjects.forEach(function (Lobj) {
                Lobj.remove();
              });
            }
          };

          this.show = function () {
            if (!me.visible) {
              me.visible = true;

              // For a feed, we watch afterLoadHook which is better performance than angular watch deep
              if (feed) {
                me.unwatch = feed.addAfterLoadHook(function (_, context) {
                  // If feed is Auto Refreshed then do not create new FloorplanFilter (keeps user chosen floors shown)
                  me.floorplanFilter = new FloorplanFilter(context && !context.refresh);
                  me.fnLoad(me);
                });
              } else {
                me.unwatch = scope.$watch('dataWatchs.' + id + '.data', function (newValue, oldValue) {
                  me.floorplanFilter = new FloorplanFilter();
                  me.fnLoad(me);
                }, true);
              }

              me.refresh();
            }
            return me;
          };

          this.hide = function () {
            if (me.visible) {
              me.visible = false;
              if (me.unwatch) {
                me.unwatch();
                me.unwatch = null;
              }
              me.Lobjects.forEach(function (Lobj) {
                Lobj.remove();
              });
            }
            return me;
          };

          this.destroy = function () {
            if (me.unwatch) {
              me.unwatch();
              me.unwatch = null;
            }
            Ldestroy(me.Lobjects);

            delete scope.dataWatchs[id];
          };
        }

        function DataScope(pageScope, pre) {
          var me = this;
          this.scope = pageScope.$new(false);

          pre = angular.isUndefined(pre) ? '' : pre;
          var $index = '$' + pre + 'index';
          var $first = '$' + pre + 'first';
          var $last = '$' + pre + 'last';
          var $middle = '$' + pre + 'middle';
          var $odd = '$' + pre + 'odd';
          var $even = '$' + pre + 'even';

          var scope = this.scope;

          this.reset = function (last) {
            me.index = -1;
            me.last = last;
          };

          this.next = function () {
            me.index = me.index + 1;
            scope[$index] = me.index;
            scope[$first] = me.index === 0;
            scope[$last] = me.index === me.last;
            scope[$middle] = !(scope[$first] || scope[$last]);
            scope[$odd] = !(scope[$even] = (me.index & 1) === 0);
          };
        }

        // 
        function fnAddOnEvents(fnLobject, options) {
          if (angular.isObject(options.on)) {
            return function (objArgs, objOptions) {
              var Lobject = fnLobject(objArgs, objOptions);
              Object.keys(options.on).forEach(function (key) {
                var fn = options.on[key];
                if (angular.isFunction(fn)) {
                  Lobject.on(key, function (ev) {
                    $timeout(fn, 0, true, Lobject.context, ev);
                  });
                }
              });
              return Lobject;
            };
          }
          return fnLobject;
        }

        function FloorplanFilter(forceSelect) {
          var me = this;
          this.includedFloorplans = {};

          this.include = function (Lobject, floorplanid) {
            if (floorplanid) {
              Lobject.floorplan = scope.floorplans[floorplanid];
              if (Lobject.floorplan) {
                if (!(Lobject.floorplan.building.id in me.includedFloorplans)) {
                  me.includedFloorplans[Lobject.floorplan.building.id] = Lobject.floorplan;
                }
              }
            }
          };

          this.select = function () {
            // Select floorplan for each Building
            Object.keys(me.includedFloorplans).forEach(function (buildingId) {
              me.includedFloorplans[buildingId].select(forceSelect);
            });
          };
        }

        var fnMakeHtmlOptions = function (options) {
          if (!options) {
            return null;
          }
          if (angular.isFunction(options)) {
            return function (v) {
              var r = options(v);
              if (angular.isString(r)) {
                return {
                  html: r,
                  options: null
                };

              } else {
                return r;
              }
            };
          } else if (angular.isString(options)) {
            var interp = $interpolate(options);
            return function (v) {
              return {
                html: interp(v),
                options: null
              };

            };
          } else if ('html' in options) {
            var interp = $interpolate(options.html);
            return function (v) {
              return {
                html: interp(v),
                options: options.options
              };

            };
          }
          return options; // not sure what it is, return it
        };

        var methods = ['polyline', 'polygon'];
        methods.forEach(function (polyxxx) {
          var Polyxxx = polyxxx.capitalize();

          scope['add' + Polyxxx] = function (data, options) {
            var dataWatch = new DataWatch(data);
            if (data) {
              getMap(function (map) {
                // options = {
                //   latitude: 'alldata.BestEstimateLocation.Latitude' || function(),
                //   longitude: 'alldata.BestEstimateLocation.Longitude' || function(),
                //   filter: 'data | groupBy: alldata.AssetId' || function(),
                //   options: see fnMakeOptions above
                // }
                options = options || {};

                // dataScope allows us to access ngRepeater like $index, $first, $last, $middle, $even, $odd fields
                var groupScope = new DataScope(scope.pageScope, 'group');
                var dataScope = new DataScope(groupScope.scope);

                var useScope = dataScope.scope;

                var fnFilter = angular.isFunction(options.filter) ? options.filter : $parse(options.filter || 'data');
                var fnLatitude = angular.isFunction(options.latitude) ? options.latitude : $parse(options.latitude || 'alldata.Latitude');
                var fnLongitude = angular.isFunction(options.longitude) ? options.longitude : $parse(options.longitude || 'alldata.Longitude');
                var fnFloorplanId = angular.isFunction(options.floorplanid) ? options.floorplanid : options.floorplanid ? $parse(options.floorplanid) : null;

                var Lpolyxxx = L[polyxxx];
                var fnPolyxxx = function (pts, polyOptions) {
                  var polyxxx = Lpolyxxx(pts, polyOptions); //.addTo(map);
                  polyxxx.context = pts;
                  return polyxxx;
                };

                var fnPopup = fnMakeHtmlOptions(options.popup);
                var fnAddPopup = fnPolyxxx;
                if (fnPopup) {
                  fnAddPopup = function (pts, polyOptions) {
                    var polyxxx = fnPolyxxx(pts, polyOptions);
                    if (polyxxx) {
                      var p = fnPopup(pts);
                      if (p) {
                        polyxxx.bindPopup(p.html, p.options);
                      }
                    }
                    return polyxxx;
                  };
                }
                var fnTooltip = fnMakeHtmlOptions(options.tooltip);
                var fnAddToolTip = fnAddPopup;
                if (fnTooltip) {
                  fnAddToolTip = function (pts, markerOptions) {
                    var polyxxx = fnAddPopup(pts, markerOptions);
                    if (polyxxx) {
                      var t = fnTooltip(pts);
                      if (t) {
                        polyxxx.bindTooltip(t.html, t.options);
                      }
                    }
                    return polyxxx;
                  };
                }
                var fnMakePolyxxx = fnAddOnEvents(fnAddToolTip, options);

                var fnMakePolyOptions = fnMakeOptions(options.options, useScope);

                var makePolyxxx = function (values) {
                  if (values.length) {
                    dataScope.reset(values.length);

                    var pts = [];
                    values.forEach(function (v) {
                      dataScope.next();
                      var latLng = L.latLng(fnLatitude(v, useScope), fnLongitude(v, useScope));
                      if (latLng) {
                        pts.push(latLng);
                      }
                    });

                    if (pts.length) {
                      dataWatch.latLngBounds = dataWatch.latLngBounds || L.latLngBounds(pts[0], pts[0]);
                      pts.forEach(function (pt) {
                        dataWatch.latLngBounds.extend(pt);
                      });
                    }

                    var polyOptions = fnMakePolyOptions(values[0], {});
                    var polyline = fnMakePolyxxx(pts, polyOptions);

                    dataWatch.addLobject(polyline, fnFloorplanId && fnFloorplanId(values[0]));
                  }
                };

                var loadPolyxxx = function (dataWatch) {
                  // Remove old 
                  Ldestroy([dataWatch.Lobjects]);
                  dataWatch.latLngBounds = null;

                  // Use $filter tree to filter, group, map, order data - note we pass pageScope which allows
                  // it to define custom filters :)
                  var result = fnFilter(dataWatch, scope.pageScope);

                  // result will be either an Array or Object (if groupBy used)
                  if (angular.isArray(result)) {
                    makePolyxxx(result);
                  } else {
                    var keys = Object.keys(result);
                    groupScope.reset(keys.length);
                    keys.forEach(function (key, index) {
                      groupScope.next();
                      makePolyxxx(result[key]);
                    });
                  }

                  if (angular.isDefined(options.fitBounds) && dataWatch.latLngBounds && dataWatch.fitBoundsCount) {
                    dataWatch.fitBoundsCount--;
                    map.fitBounds(dataWatch.latLngBounds, options.fitBounds);
                  }

                  dataWatch.refresh();
                };

                dataWatch.init(loadPolyxxx, map);

                dataWatch.show();
              });
            }
            return dataWatch;
          };
        });

        var methods = [{
          method: 'Marker',
          Lmethod: 'marker'
        },

        {
          method: 'Circle',
          Lmethod: 'circle'
        },

        {
          method: 'CircleMarker',
          Lmethod: 'circleMarker'
        }];


        methods.forEach(function (method) {
          scope['add' + method.method] = function (data, options) {
            var dataWatch = new DataWatch(data);

            if (data) {
              getMap(function (map) {
                // options = {
                //   filter: 'alldata.LocationType == 4',
                //   latitude: 'alldata.BestEstimateLocation.Latitude' || function(),
                //   longitude: 'alldata.BestEstimateLocation.Longitude' || function(),
                //   radius: 'alldata.BestEstimateLocation.Distance' || function(),
                //   icon: 'icon name' || function(),
                //   popup: '<b>{{ alldata.Name }}</b>' || function(),
                //   tooltip: '<b>{{ alldata.Name }}</b>' || function(),
                //   options: see fnMakeOptions above,
                //   on: {
                //     click: function(),
                //     dblclick: function(),
                //     mousedown: function(),
                //     mouseover: function(),
                //     mouseout: function(),
                //     contextmenu: function()
                //  },
                //  fitBounds: [padding X, padding Y],
                //  floorplanid: 'alldata.BestEstimateLocation.FacilityFloor.OnFloorPlanId',
                //  beaconid: 'alldata.BeaconId'
                // }
                options = options || {};

                // dataScope allows us to access ngRepeater like $index, $first, $last, $middle, $even, $odd fields
                var dataScope = new DataScope(scope.pageScope);
                var useScope = dataScope.scope;

                var fnFilter = angular.isFunction(options.filter) ? options.filter : options.filter ? $parse(options.filter) : null;
                var fnLatitude = angular.isFunction(options.latitude) ? options.latitude : $parse(options.latitude || 'alldata.Latitude');
                var fnLongitude = angular.isFunction(options.longitude) ? options.longitude : $parse(options.longitude || 'alldata.Longitude');
                var fnRadius = angular.isFunction(options.radius) ? options.radius : options.radius ? $parse(options.radius) : null;
                var fnFloorplanId = angular.isFunction(options.floorplanid) ? options.floorplanid : options.floorplanid ? $parse(options.floorplanid) : null;
                var fnLiveBeaconId = scope.beaconSvc && (angular.isFunction(options.livebeaconid) ? options.livebeaconid : options.livebeaconid ? $parse(options.livebeaconid) : null);

                var Lmarker = L[method.Lmethod];
                var fnMarker = function (v, markerOptions) {
                  var latLng = L.latLng(fnLatitude(v, useScope), fnLongitude(v, useScope));
                  if (!latLng) {
                    return null;
                  }
                  var marker = Lmarker(latLng, markerOptions);
                  marker.context = v;
                  return marker;
                };

                var fnAddLiveBeacon = angular.identity;
                if (fnLiveBeaconId) {
                  fnAddLiveBeacon = function (v) {
                    var beaconId = fnLiveBeaconId(v);
                    if (angular.isDefined(beaconId)) {
                      v.liveBeacon = scope.liveBeacons.find(function (b) {
                        return b.beaconId === beaconId;
                      });
                    }
                    return v;
                  };
                }

                var fnPopup = fnMakeHtmlOptions(options.popup);
                var fnAddPopup = fnMarker;
                if (fnPopup) {
                  fnAddPopup = function (v, markerOptions) {
                    var marker = fnMarker(v, markerOptions);
                    if (marker) {
                      var p = fnPopup(v);
                      if (p) {
                        marker.bindPopup(p.html, p.options);
                      }
                    }
                    return marker;
                  };
                }
                var fnTooltip = fnMakeHtmlOptions(options.tooltip);
                var fnAddToolTip = fnAddPopup;
                if (fnTooltip) {
                  fnAddToolTip = function (v, markerOptions) {
                    var marker = fnAddPopup(v, markerOptions);
                    if (marker) {
                      var t = fnTooltip(v);
                      if (t) {
                        marker.bindTooltip(t.html, t.options);
                      }
                    }
                    return marker;
                  };
                }
                var fnMakeMarker = fnAddOnEvents(fnAddToolTip, options);

                var fnMakeBasicOptions = fnMakeOptions(options.options, useScope);
                var fnMakeMarkerOptions = fnMakeBasicOptions;
                if (options.radius) {
                  fnMakeMarkerOptions = function (v, mergeOption) {
                    var markerOptions = fnMakeBasicOptions(v, mergeOption);
                    if (markerOptions) {
                      markerOptions.radius = Number(fnRadius(v, useScope));
                      if (markerOptions.radius === 0 || isNaN(markerOptions.radius) || !isFinite(markerOptions.radius)) {
                        return null;
                      }
                    }
                    return markerOptions;
                  };
                }

                var loadMarkers = function (dataWatch) {
                  // Remove old markers
                  Ldestroy([dataWatch.Lobjects]);
                  dataWatch.latLngBounds = null;
                  var floorplanid = null;

                  // And add new ones
                  dataScope.reset(dataWatch.data.length);
                  dataWatch.data.forEach(function (v) {
                    if (!fnFilter || fnFilter(v)) {

                      dataScope.next();
                      v = fnAddLiveBeacon(v);
                      var markerOptions = fnMakeMarkerOptions(v, {});
                      var marker = fnMakeMarker(v, markerOptions);
                      if (marker) {
                        dataWatch.latLngBounds = dataWatch.latLngBounds || L.latLngBounds(marker.getLatLng(), marker.getLatLng());
                        dataWatch.latLngBounds.extend(marker.getLatLng());

                        dataWatch.addLobject(marker, fnFloorplanId && fnFloorplanId(v));
                      }

                      // get a pointer to the datawatch object onto the odata one
                      // so we can then have easy access to it's methods from angular templates
                      // etc in a dashboard. This allows us to put popups etc through other dashboard
                      // element.
                      v.marker = function () {
                        return marker;
                      };
                    }
                  });

                  if (angular.isDefined(options.fitBounds) && dataWatch.latLngBounds && dataWatch.fitBoundsCount) {
                    dataWatch.fitBoundsCount--;
                    map.fitBounds(dataWatch.latLngBounds, options.fitBounds);
                  }

                  dataWatch.refresh();
                };

                dataWatch.init(loadMarkers, map);
                dataWatch.show();
              });
            }
            return dataWatch;
          };
        });
      }
    });

  }]);



questionsModule.directive('questionLeaveManagement', ['bworkflowApi', '$filter', 'languageTranslate', function (bworkflowApi, $filter, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_leave_management.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);
      scope.addingLeave = null;
      scope.showAdding = false;

      scope.leaveTypeTemplate = {
        name: 'leavetype',
        feed: 'LeaveTypes',
        orderbyfields: 'Name',
        idfields: ['Id']
      };


      scope.leaveTemplate = {
        name: 'leave',
        feed: "Leave",
        orderbyfields: 'StartDate desc, EndDate desc',
        idfields: ['Id'],
        itemsperpage: 10
      };


      scope.memberFeedTemplate = {
        name: 'members',
        feed: 'Members',
        filter: "substringof('[[searchmemberssearch]]', Name) and IsASite eq false",
        orderbyfields: 'Name',
        idfields: ['UserId'],
        itemsperpage: 5,
        datascope: {
          ExcludeOutsideHierarchies: false,
          IncludeCurrentUser: true,
          ExcludeExpired: true
        },

        parameterdefinitions: [{
          internalname: 'searchmemberssearch'
        }]
      };



      scope.replaceMemberFeedTemplate = {
        name: 'members',
        feed: 'Members',
        filter: "substringof('[[searchreplacedbysearch]]', Name) and IsASite eq false",
        orderbyfields: 'Name',
        idfields: ['UserId'],
        itemsperpage: 5,
        datascope: {
          ExcludeOutsideHierarchies: false,
          IncludeCurrentUser: true,
          ExcludeExpired: true
        },

        parameterdefinitions: [{
          internalname: 'searchreplacedbysearch'
        }]
      };



      scope.scheduleFeedTemplate = {
        name: 'rosterroles',
        feed: 'RosterRoles',
        filter: "UserId eq guid'[[ownerid]]'",
        orderbyfields: 'Roster,Role',
        idfields: ['RosterId', 'HierarchyBucketId'],
        usepaging: false,
        datascope: {
          QueryScope: '1'
        },

        parameterdefinitions: [{
          internalname: 'ownerid'
        }]
      };



      scope.newLeave = {
        originalUser: null,
        replacingUser: null
      };

      scope.selectedRole = {};
      scope.selectedRow = null;
      scope.showReplacing = false;

      scope.leaveTypeFeed = bworkflowApi.createDataFeed(scope.leaveTypeTemplate, scope);
      scope.leaveFeed = bworkflowApi.createDataFeed(scope.leaveTemplate, scope);
      scope.memberFeed = bworkflowApi.createDataFeed(scope.memberFeedTemplate, scope);
      scope.replaceMemberFeed = bworkflowApi.createDataFeed(scope.replaceMemberFeedTemplate, scope);
      scope.scheduleFeed = bworkflowApi.createDataFeed(scope.scheduleFeedTemplate, scope);

      scope.scheduleRoles = null;

      scope.scheduleFeed.afterLoadHooks.push(function (feed) {
        // we are going to do a little bit of processing on this to make things easier
        // to present in the HTML template
        scope.scheduleRoles = [];
        var roleCache = {};

        angular.forEach(feed.data, function (item) {
          var key = item.alldata.RoleId + item.alldata.Roster;
          if (angular.isDefined(roleCache[key]) == true) {
            return;
          }

          // we wrap these odata objects in one of our own so that
          // we can help out the UI a bit regarding what the user selects
          scope.scheduleRoles.push({
            leaveType: scope.addingLeave.alldata.Id,
            originalUser: scope.newLeave.originalUser,
            role: item,
            sd: null,
            ed: null,
            replacingUser: null,
            notes: null,
            valid: false,
            saved: false
          });


          roleCache[key] = '';
        });
      });

      scope.leaveTypeFeed.getData(true);
      scope.leaveFeed.getData(true);

      scope.addLeave = function (type) {
        scope.addingLeave = type;
        scope.showAdding = true;
        scope.selectedRow = null;
      };

      scope.$watch('newLeave.originalUser', function (newValue, oldValue) {
        if (newValue == null) {
          return;
        }

        scope.scheduleRoles = null;
        scope.scheduleFeed.parameters.ownerid = newValue.alldata.UserId;
        scope.scheduleFeed.getData(true);
      });

      scope.$watch('newLeave.replacingUser', function (newValue, oldValue) {
        if (scope.selectedRole == null) {
          return;
        }

        scope.selectedRole.replacingUser = newValue;
      });

      scope.$watch('selectedRole', function (newValue, oldValue) {
        if (scope.selectedRole == null) {
          return;
        }

        scope.selectedRole.valid = moment(scope.selectedRole.sd).isValid() && moment(scope.selectedRole.ed).isValid();
      }, true);

      scope.selectRole = function (role) {
        scope.selectedRole = role;
        scope.selectedRole.replacingUser = role.replacingUser;
        scope.showReplacing = true;

        if (role.replacingUser == null) {
          scope.$broadcast('OdataTypeahead.clear', {
            name: 'searchreplacedby'
          });

        }
      };

      scope.clearSelections = function () {
        scope.selectedRole = {};
        scope.scheduleRoles = null;
        scope.addingLeave = null;
        scope.showAdding = false;
        scope.showReplacing = false;
      };

      scope.saveLeave = function () {
        var valid = scope.buildValidLeaves();

        angular.forEach(valid, function (r) {
          r.originalUserId = r.originalUser.alldata.UserId;
          if (r.replacingUser != null) {
            r.replacingUserId = r.replacingUser.alldata.UserId;
          }

          r.startDate = moment(r.sd).format('DD-MM-YYYY');
          r.endDate = moment(r.ed).format('DD-MM-YYYY');

          r.roleId = r.role.alldata.RoleId;
          r.hierarchyBucketId = r.role.alldata.HierarchyBucketId;
        });

        var parameters = {
          userid: scope.presented.userid,
          leave: valid
        };


        bworkflowApi.execute('LeaveManagement', 'AddLeave', parameters).
          then(function (data) {
            var issue = 0;

            angular.forEach(data.leave, function (item, key) {

              var v = $filter('filter')(valid, item.hierarchyBucketId, true);

              if (item.success == false) {
                if (v.length > 0) {
                  v[0].error = item.message;
                }

                issue++;
              } else {
                v[0].saved = true;
              }
            });

            if (issue == 0) {
              scope.clearSelections();
            }

            scope.leaveFeed.getData(true);

          }, function (tasks) {

          });
      };

      scope.buildValidLeaves = function () {
        var valid = [];

        angular.forEach(scope.scheduleRoles, function (r) {
          if (r.valid == false || r.saved == true) {
            return;
          }

          valid.push(r);
        });

        return valid;
      };

      scope.selectRow = function (row) {
        scope.selectedRow = row;
      };

      scope.delete = function (leave) {
        if (confirm('Are you sure you want to delete this leave entry') == false) {
          return;
        }

        var parameters = {
          userid: scope.presented.userid,
          id: leave.alldata.Id
        };


        bworkflowApi.execute('LeaveManagement', 'DeleteLeave', parameters).
          then(function (data) {
            scope.selectedRow = null;
            scope.leaveFeed.getData(true);

          }, function (tasks) {

          });
      };
    }
  });

}]);


questionsModule.directive('questionTimesheetManagement', ['bworkflowApi', '$filter', '$timeout', 'languageTranslate', function (bworkflowApi, $filter, $timeout, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_timesheet_management.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.selectedRoster = {
        alldata: {
          Id: -1
        }
      };


      scope.selectedUser = null;
      scope.highlightDuration = 10;
      scope.startDate = null;
      scope.endDate = null;
      scope.cachedTimesheets = {};
      scope.selectedTimesheet = null;
      scope.selectedTimesheetItem = null;
      scope.selectedTimesheetItemData = null;
      scope.issaving = false;

      scope.timesheetItemTypesTemplate = {
        name: 'timesheetitemtypes',
        feed: 'TimesheetItemTypes',
        orderbyfields: 'Name',
        idfields: ['Id'],
        usepaging: false
      };


      scope.leaveTypeTemplate = {
        name: 'leavetype',
        feed: 'LeaveTypes',
        orderbyfields: 'Name',
        idfields: ['Id']
      };


      scope.rosterTemplate = {
        name: 'rosters',
        feed: 'Rosters',
        orderbyfields: 'Name',
        idfields: ['Id'],
        usepaging: false,
        selectfields: 'Id,Name'
      };


      scope.teamHierarchyTemplate = {
        name: 'teamhierarchy',
        feed: 'TeamHierarchys',
        filter: "RosterId eq [[RosterId]]",
        orderbyfields: 'LeftIndex',
        idfields: ['Id'],
        usepaging: false,
        selectfields: 'Id,LeftIndex,RoleName,RoleId,Name,UserId',
        parameterdefinitions: [{
          internalname: 'RosterId'
        }]
      };



      scope.timesheetItemTypeFeed = bworkflowApi.createDataFeed(scope.timesheetItemTypesTemplate, scope);
      scope.leaveTypeFeed = bworkflowApi.createDataFeed(scope.leaveTypeTemplate, scope);
      scope.rosterFeed = bworkflowApi.createDataFeed(scope.rosterTemplate, scope);
      scope.teamHierarchyFeed = bworkflowApi.createDataFeed(scope.teamHierarchyTemplate, scope);

      // we hook into the load of the team hierarchy, to manage the cached timesheets
      scope.teamHierarchyFeed.afterLoadHooks.push(function (feed) {
        scope.cachedTimesheets = {};
        angular.forEach(feed.data, function (value, index) {
          if (value.alldata.UserId == null) {
            return;
          }

          value.__status = "Empty";

          scope.cachedTimesheets[value.alldata.Id.toString()] = {
            __isLoaded: false,
            item: value,
            data: null
          };


          scope.loadTimesheet(value);
        });
      });

      scope.rosterFeed.afterLoadHooks.push(function (feed) {
        scope.selectedRoster = feed.data[0];
      });

      scope.timesheetItemTypeFeed.getData(true);
      scope.leaveTypeFeed.getData(true);
      scope.rosterFeed.getData(true);

      scope.$watch('selectedRoster', function (newValue, oldValue) {
        if (newValue == null || newValue.alldata.Id == -1) {
          return;
        }

        scope.teamHierarchyFeed.parameters.RosterId = newValue.alldata.Id;
        scope.teamHierarchyFeed.getData(true);
      });

      scope.$watch('highlightDuration', function (newValue, oldValue) {
        if (Number.isInteger(newValue) == false) {
          return;
        }

        scope.setHighlighting();
      });

      scope.setHighlighting = function () {
        if (scope.selectedTimesheet == null) {
          return;
        }

        angular.forEach(scope.selectedTimesheet.data, function (item) {
          var diff = Math.abs(moment.duration(item.__actualstarttime.diff(item.__scheduledstarttime)).asMinutes());

          if (diff > scope.highlightDuration) {
            item.__highlightstarttime = true;
          } else {
            item.__highlightstarttime = false;
          }

          diff = Math.abs(moment.duration(item.__actualendtime.diff(item.__scheduledendtime)).asMinutes());

          if (diff > scope.highlightDuration) {
            item.__highlightendtime = true;
          } else {
            item.__highlightendtime = false;
          }
        });
      };

      scope.canShowTeamHierarchy = function () {
        return scope.teamHierarchyFeed.data.length > 0 && moment(scope.startDate).isValid() && moment(scope.endDate).isValid();
      };

      scope.loadTimesheetFromCache = function (u) {
        return scope.cachedTimesheets[u.alldata.Id.toString()];
      };

      scope.loadTimesheet = function (u) {
        if (u.alldata.UserId == null) {
          return;
        }

        var sheet = scope.loadTimesheetFromCache(u);

        if (sheet.__isLoaded == false) {
          var parameters = {
            userid: scope.presented.userid,
            startdate: moment(scope.startDate).format('DD-MM-YYYY'),
            enddate: moment(scope.endDate).format('DD-MM-YYYY'),
            ownerid: u.alldata.UserId,
            rosterid: scope.selectedRoster.alldata.Id,
            roleid: u.alldata.RoleId
          };


          bworkflowApi.execute('TimesheetManagement', 'CalculateTimesheet', parameters).
            then(function (data) {
              angular.forEach(data.timesheetitems, function (value, index) {
                value.__approvedsd = moment(value.approvedstartdate, 'YYYY-MM-DDTHH:mm:ss');
                value.__approvedstartdate = moment(value.approvedstartdate).startOf('day');
                value.__approvedstartdatestring = value.__approvedstartdate.format('DD-MM-YYYY');
                value.__approvedstarttime = moment(value.approvedstartdate);
                value.__approvedstarttimestring = value.__approvedstarttime.format('HH:mm');

                value.__scheduledstarttime = moment(value.scheduledstartdate);
                value.__schedulestarttimestring = value.__scheduledstarttime.format('DD-MM-YYYY HH:mm');
                value.__scheduledendtime = moment(value.scheduledenddate);
                value.__scheduleendtimestring = value.__scheduledendtime.format('DD-MM-YYYY HH:mm');

                value.__actualstarttime = moment(value.actualstartdate);
                value.__actualstarttimestring = value.__actualstarttime.format('DD-MM-YYYY HH:mm');
                value.__actualendtime = moment(value.actualenddate);
                value.__actualendtimestring = value.__actualendtime.format('DD-MM-YYYY HH:mm');

                value.__scheduledlunchsd = moment(value.scheduledlunchstartdate, 'YYYY-MM-DDTHH:mm:ss');
                value.__scheduledlunchstartdate = moment(value.scheduledlunchstartdate).startOf('day');
                value.__scheduledlunchstartdatestring = value.__scheduledlunchstartdate.format('DD-MM-YYYY');
                value.__scheduledlunchstarttime = moment(value.scheduledlunchstartdate);
                value.__scheduledlunchstarttimestring = value.__scheduledlunchstarttime.format('HH:mm');

                value.__approveded = moment(value.approvedenddate, 'YYYY-MM-DDTHH:mm:ss');
                value.__approvedenddate = moment(value.approvedenddate).startOf('day');
                value.__approvedenddatestring = value.__approvedenddate.format('DD-MM-YYYY');
                value.__approvedendtime = moment(value.approvedenddate);
                value.__approvedendtimestring = value.__approvedendtime.format('HH:mm');

                value.__scheduledlunched = moment(value.scheduledlunchenddate, 'YYYY-MM-DDTHH:mm:ss');
                value.__scheduledlunchenddate = moment(value.scheduledlunchenddate).startOf('day');
                value.__scheduledlunchenddatestring = value.__scheduledlunchenddate.format('DD-MM-YYYY');
                value.__scheduledlunchendtime = moment(value.scheduledlunchenddate);
                value.__scheduledlunchendtimestring = value.__scheduledlunchendtime.format('HH:mm');

                value.__highlightstarttime = false;
                value.__highlightendtime = false;
              });

              sheet.data = data.timesheetitems;
              sheet.__isLoaded = true;

              var sheetStatus = "Empty";
              angular.forEach(sheet.data, function (item, index) {
                if (item.id != null) {
                  if (sheetStatus != "Partial") {
                    sheetStatus = "Full";
                  }
                  item.__status = "Full";
                } else {
                  sheetStatus = "Partial";
                  item.__status = "Partial";
                }

                // ok it could still get the partial status if clockins aren't mapped in
                angular.forEach(item.timesheetedtaskworklogs, function (log, index) {
                  if (log.timesheetitemid == 0) {
                    sheetStatus = "Partial";
                    log.__status = "Partial";
                    item.__status = "Partial";
                  } else {
                    log.__status = "Full";
                  }
                });
              });
              sheet.item.__status = sheetStatus;

            }, function (tasks) {

            });
        }

        return sheet;
      };

      scope.selectUser = function (u) {
        if (scope.selectedUser != null) {
          scope.selectedUser.__isSelected = false;
        }

        scope.selectedUser = u;
        scope.selectedUser.__isSelected = true;

        scope.issaving = false;

        scope.selectedTimesheet = scope.loadTimesheet(u);
        scope.setHighlighting();
      };

      scope.findWorkLogMapping = function (log, sheet) {
        var result = null;
        angular.forEach(sheet.data, function (item, index) {
          angular.forEach(item.timesheetedtaskworklogs, function (l, index) {
            if (l.projectjobtaskworklogid == log.id) {
              result = l;
            }
          });
        });

        return result;
      };

      scope.selectTimesheet = function (t) {
        if (scope.selectedTimesheetItem == t) {
          scope.selectedTimesheetItem = null;
          return;
        }

        var parameters = {
          rosterid: scope.selectedRoster.alldata.Id,
          sheeted: t.timesheetedtaskworklogs
        };


        bworkflowApi.execute('TimesheetManagement', 'GetTimesheetItemDetails', parameters).
          then(function (data) {
            scope.selectedTimesheetItemData = data;

            angular.forEach(scope.selectedTimesheetItemData.worklog, function (d, index) {
              var map = scope.findWorkLogMapping(d, scope.selectedTimesheet);
              if (map != null) {
                d.__status = map.__status;
              }
            });

          }, function (tasks) {

          });

        scope.selectedTimesheetItem = t;
      };

      scope.combineDateAndTime = function (d, t) {
        var day = d.clone();
        var tDay = t.clone().startOf('day');
        var time = t.clone();

        var r = day.add(time.diff(tDay, 'seconds'), 'seconds');

        return r;
      };

      scope.calculateApprovedDuration = function (t) {
        t.__approvedsd = scope.combineDateAndTime(t.__approvedstartdate, t.__approvedstarttime);
        t.__approveded = scope.combineDateAndTime(t.__approvedenddate, t.__approvedendtime);

        t.__scheduledlunchsd = scope.combineDateAndTime(t.__scheduledlunchstartdate, t.__scheduledlunchstarttime);
        t.__scheduledlunched = scope.combineDateAndTime(t.__scheduledlunchenddate, t.__scheduledlunchendtime);

        t.approvedduration = t.__approveded.diff(t.__approvedsd, 'seconds');
        t.lunchduration = t.__scheduledlunched.diff(t.__scheduledlunchsd, 'seconds');

        if (t.islunchpaid == false && t.lunchduration != null) {
          t.approvedduration = t.approvedduration - t.lunchduration;
        }
      };

      scope.save = function (selected) {
        // the save method saves each user timesheet 1 at a time.
        // the pattern used to do this is to create a set of actions, which
        // are loaded in sequence and chained together. The commit method of
        // each action is expected to do it's thing and then chain onto the
        // next action in the list of actions.
        scope.savingItemIndex = -1;
        scope.issaving = true;

        scope.saveactions = [];

        var startdate = moment(scope.startDate).format('DD-MM-YYYY');
        var enddate = moment(scope.endDate).format('DD-MM-YYYY');

        // loop through our cached timesheets and build up save actions for each one based on their state
        angular.forEach(scope.cachedTimesheets, function (value, index) {
          if (angular.isDefined(selected) == true && value != selected) {
            return;
          }

          var action = {
            parameters: {
              userid: scope.presented.userid,
              startdate: startdate,
              enddate: enddate,
              ownerid: value.item.alldata.UserId,
              rosterid: scope.selectedRoster.alldata.Id,
              roleid: value.item.alldata.RoleId,
              timesheetitems: value.data
            },

            text: value.item.alldata.Name,
            loading: false,
            complete: false,
            loadAttempts: 0,
            sheet: value,
            commit: function (data) {
              data.loading = true;

              angular.forEach(data.parameters.timesheetitems, function (t, v) {
                // need to copy from our working variables to the ones the server is going to use
                t.approvedstartdate = t.__approvedsd.format('YYYY-MM-DDTHH:mm:ss');
                t.approvedenddate = t.__approveded.format('YYYY-MM-DDTHH:mm:ss');

                t.scheduledlunchstartdate = t.__scheduledlunchsd.format('YYYY-MM-DDTHH:mm:ss');
                t.scheduledlunchenddate = t.__scheduledlunched.format('YYYY-MM-DDTHH:mm:ss');
              });

              bworkflowApi.execute('TimesheetManagement', 'Save', data.parameters).
                then(function (response) {
                  if (response.success == true) {
                    data.loading = false;
                    data.complete = true;
                    data.loadAttempts = data.loadAttempts + 1;
                  } else {
                    data.loading = false;
                    data.complete = false;
                    data.loadAttempts = data.loadAttempts + 1;
                  }

                  // reload what was saved
                  data.sheet.__isLoaded = false;
                  data.sheet.data = null;
                  scope.loadTimesheet(data.sheet.item);

                  scope.doSaveNextItem(); // next item in the chain please
                }, function (tasks) {
                  data.loading = false;
                  data.complete = false;
                  data.loadAttempts = data.loadAttempts + 1;

                  scope.doSaveNextItem(); // next item in the chain please
                });
            }
          };


          scope.saveactions.push(action);
        });

        // give the UI an opportunity to present things to the user
        $timeout(function () {
          // we let the actions chain themselves together one at a time
          // by committing them one at a time. When an action finishes,
          // it should call the doApproveNextItem method so that the
          // next action can do its thing. We start the chain here
          scope.doSaveNextItem();
        });
      };

      scope.doSaveNextItem = function () {
        scope.savingItemIndex = scope.savingItemIndex + 1;

        if (scope.savingItemIndex >= scope.saveactions.length) {
          scope.issavingcomplete = true;
          return;
        }

        var currentAction = scope.saveactions[scope.savingItemIndex];

        currentAction.commit(currentAction);
      };
    }
  });

}]);

questionsModule.factory('eway-payment', ['$http', '$q', '$window', 'pendingPaymentsService', function ($http, $q, $window, pendingPaymentsService) {
  var deferReady = $q.defer();
  var _api = $window.razordata.apiprefix;

  // Inject the eWay JS Script ..
  var scriptTag = document.createElement('script');
  scriptTag.src = 'https://secure.ewaypayments.com/scripts/eCrypt.js';
  scriptTag.onload = function () {
    deferReady.resolve();
  };
  var head = document.getElementsByTagName('head')[0];
  head.appendChild(scriptTag);

  return {
    ready: function () {
      return deferReady.promise;
    },

    processPreparedPayment: function (sharedPaymentUrl) {
      var defer = $q.defer();
      eCrypt.showModalPayment({
        sharedPaymentUrl: sharedPaymentUrl
      },
        function (result, transactionID, errors) {
          if (result === "Complete") {
            defer.resolve({
              transactionID: transactionID
            });

          } else if (result === "Cancel") {
            defer.resolve({
              cancelled: true
            });

          } else if (errors) {
            defer.reject(angular.isArray(errors) ? errors : [errors]);
          }
        });
      return defer.promise;
    },

    processPayment: function (pendingPaymentId) {
      var self = this;
      return $http({
        url: _api + 'eWayPayment/PreparePayment',
        method: 'POST',
        data: {
          pendingPaymentId: pendingPaymentId
        }
      }).

        then(function (response) {
          if (response.data.SharedPaymentUrl) {
            return self.processPreparedPayment(response.data.SharedPaymentUrl);
          } else if (response.data.Errors) {
            $http({
              url: _api + 'eWayPayment/GetErrorDescriptions',
              method: 'POST',
              data: response.data.Errors
            }).
              then(function (errors) {
                return $q.reject(errors.data);
              });
          }
        });
    },

    queryTransaction: function (pendingPaymentId) {
      return $http({
        url: _api + 'eWayPayment/QueryTransaction',
        method: 'POST',
        data: {
          pendingPaymentId: pendingPaymentId
        }
      }).

        then(function (response) {
          if (response.data && response.data.PendingPayments) {
            angular.forEach(response.data.PendingPayments, function (pp) {
              pendingPaymentsService.modelPendingPayment(pp);
            });
          }
          return response.data;
        });
    }
  };

}]);

questionsModule.directive('questionEwayPayment', ['$log', 'bworkflowApi', 'eway-payment', 'playerButtons', 'playerActions', 'languageTranslate', function ($log, bworkflowApi, ewayPayment, playerButtons, playerActions, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_eway_payment.html',
    scope: {
      presented: '=',
      pageScope: '='
    },

    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.acceptedTCs = true; // pageScope should set this to false if required
      scope.termsAndConditionsURL = '';
      scope.pageScopeReady = true; // pageScope can set to false/true to customise 
      ewayPayment.ready().then(function () {
        scope.ready = true;
      });

      scope.acceptTCs = function () {
        scope.acceptedTCs = !scope.acceptedTCs;
      };

      scope.readyToPay = function () {
        return scope.ready && scope.pageScopeReady && scope.acceptedTCs && !scope.success && !scope.presented.responseerrors && scope.PayPendingPayment;
      };

      scope.Transaction = scope.presented.transaction;

      scope.setPendingPayment = function (pp) {
        scope.PayPendingPayment = pp;
        if (scope.PayPendingPayment) {
          scope.PayPendingPayment.payNowState = 1;
          playerButtons.canNext = false;
        }
      };

      scope.answer = scope.presented.Answer;

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return scope.answer.TransactionID;
      };

      scope.payNow = function () {
        scope.PayPendingPayment.payNowState = 2;
        scope.processing = true;
        scope.success = false;
        delete scope.errors;

        ewayPayment.processPayment(scope.PayPendingPayment.Id).then(function (result) {
          scope.processing = false;
          if (!result.cancelled) {
            ewayPayment.queryTransaction(scope.PayPendingPayment.Id).then(function (response) {
              scope.paymentResult = response.TransactionResponse;
              scope.success = scope.paymentResult.TransactionStatus.Status;
              if (scope.paymentResult.TransactionStatus.Status) {
                delete scope.PayPendingPayment.payNowState;
                scope.answer.TransactionID = scope.paymentResult.TransactionStatus.TransactionID;

                // Force next page
                playerActions.doTransition('next');
              } else {
                scope.PayPendingPayment.payNowState = 1;
              }
            }, function (error) {
              scope.PayPendingPayment.payNowState = 1;
              scope.errors = [error];
            });
          } else {
            scope.PayPendingPayment.payNowState = 1;
          }
        }, function (errors) {
          scope.PayPendingPayment.payNowState = 1;
          scope.errors = errors;
          scope.processing = false;
        });
      };
    }
  });

}]);







questionsModule.factory('orderSvc', ['jsFunctionSvc', '$sce', '$http', '$window', function (jsFunctionSvc, $sce, $http, $window) {
  var _api = $window.razordata.apiprefix;

  var svc = {
    units: ['', 'qty', 'm', 'm<sup>2</sup>', 'm<sup>3</sup>', 'm<sup>2</sup>'],

    createTotaller: function () {
      return jsFunctionSvc.getScript().then(function (result) {
        var fns = result.functions;
        var now = result.utcnow; // but use servertime if possible
        var fnItemPrice = function (item, order, orders) {
          // Reset all item calculated properties in prep for the custom function
          item.discount = 0;
          item.surcharge = 0;
          delete item.totalPrice;
          delete item.priceNotes;
          delete item.error;

          // Pricing function can customise the units ..
          item.units = svc.units[item.producttype.units];

          var notes = [];
          if (fns && item.pricejsfunctionid) {
            item.totalPrice = fns.byId[item.pricejsfunctionid]({
              item: item,
              notes: notes,
              order: order,
              orders: orders,
              now: now
            });


            if (angular.isUndefined(item.priceNotes)) {
              item.priceNotes = $sce.trustAsHtml(notes.join('<br/>'));
            }
          } else {
            // By default we accept Quantity as whole +ve numbers to be valid, any special Quantities must be handled by a custom JS function
            if (item.quantity > 0 && Math.round(item.quantity) === item.quantity) {
              item.totalPrice = item.quantity * item.price;
            } else if (item.quantity) {
              delete item.totalPrice;
              item.error = 'Invalid quantity';
            }
          }

          return item.totalPrice;
        };

        var fnTotal = function (order, orders) {
          if (angular.isUndefined(orders) && angular.isDefined(order.length)) {
            orders = order;

            var grandTotalFn = [];
            orders.grandTotalFn = function (fn, priority) {
              grandTotalFn.push({
                fn: fn,
                priority: priority || 0
              });

            };

            // its a number of orders we are being asked to total up
            angular.forEach(orders, function (o) {
              fnTotal(o, orders);
            });

            grandTotalFn = grandTotalFn.sortBy(function (tfn) {
              return tfn.priority;
            });

            // GrandTotal is *always* the sum of all Order totals, custom pricing must adjust an order.total and not expect anything but sum('total')
            orders.grandTotal = orders.sum('total');
            angular.forEach(grandTotalFn, function (tfn) {
              tfn.fn(orders);

              // Resum the grandTotal for the next fn to work with
              orders.grandTotal = orders.sum('total');
            });

            return orders.grandTotal;
          } else {
            var totalFn = [];
            order.totalFn = function (fn, priority) {
              totalFn.push({
                fn: fn,
                priority: priority || 0
              });

            };

            // its a single order we are totalling up
            angular.forEach(order.items, function (item) {
              fnItemPrice(item, order, orders);
            });

            totalFn = totalFn.sortBy(function (tfn) {
              return tfn.priority;
            });

            // Order total is *always* the sum of each item's totalPrice
            order.total = order.items.sum(function (i) {
              return i.quantity ? i.totalPrice || 0 : 0;
            });
            angular.forEach(totalFn, function (tfn) {
              tfn.fn(order);

              // Resum the order total for the next fn to work with
              order.total = order.items.sum(function (i) {
                return i.quantity ? i.totalPrice || 0 : 0;
              });
            });

            // Orders with 'fixed' items need to be identified
            order.fixedCount = order.items.count(function (i) {
              return i.fixed;
            });

            return order.total;
          }
        };
        return {
          total: fnTotal
        };

      });
    },

    getProductCatalog: function (fsId) {
      return $http({
        url: _api + 'ProductCatalog/CatalogsForFacilityStructure?id=' + fsId.toString()
      }).
        then(function (response) {
          return response.data;
        });
    }
  };

  return svc;
}]);

// This is a directive used by questionOrder to present the UI for catalogs
questionsModule.directive('questionOrderCatalog', ['bworkflowApi', '$sce', 'orderSvc', '$filter', '$timeout', function (bworkflowApi, $sce, orderSvc, $filter, $timeout) {
  return {
    require: "ngModel",
    templateUrl: 'question_order_catalog.html',
    scope: {
      template: '=',
      order: '=ngModel'
    },

    link: function (scope, element, attrs, ngModel) {
      scope.selected = {
        product: -1,
        manualproducts: '',
        children: {}
      };

      scope.productstoconfigure = [];

      scope.units = orderSvc.units;
      scope.showconfigurechild = false;

      scope.trustAsHtml = function (txt) {
        return $sce.trustAsHtml(txt);
      };

      scope.removeitem = function (item) {
        delete item.quantity;
        if (angular.isDefined(item.width)) {
          delete item.width;
        }
        if (angular.isDefined(item.length)) {
          delete item.length;
        }
        delete item.error;
        item.added = false;
      };

      orderSvc.createTotaller().then(function (totaller) {
        scope.$watch('order.items.length', function () {
          totaller.total(scope.order);
        });

        scope.changeItemQuantity = function (item) {
          totaller.total(scope.order);
        };

        scope.changeItemLength = function (item) {
          if (!item.length || item.length < 0 || !item.width || item.width < 0) {
            // can't have an area
            delete item.quantity;
          } else {
            item.quantity = item.length * item.width;
          }

          totaller.total(scope.order);
        };

        scope.changeItemWidth = function (item) {
          if (!item.length || item.length < 0 || !item.width || item.width < 0) {
            // can't have an area
            delete item.quantity;
          } else {
            item.quantity = item.length * item.width;
          }

          totaller.total(scope.order);
        };
      });

      scope.$watch("selected.product", function (newValue, oldValue) {
        if (angular.isDefined(newValue) === false || newValue === null) {
          return;
        }

        var item = null;
        angular.forEach(scope.order.items, function (i) {
          if (i.productid === scope.selected.product.productid) {
            item = i;
          }
        });

        if (item === null) {
          return;
        }

        if (item.childproducts.length === 0) {
          item.added = true;
          return;
        }

        scope.productstoconfigure.push(scope.selected.product);
        scope.showconfigurechild = true;
      });

      scope.processManualEntry = function () {
        if (angular.isDefined(scope.selected.manualproducts) === false || scope.selected.manualproducts === null) {
          return;
        }

        scope.productstoconfigure = [];

        var parts = scope.selected.manualproducts.split(',');
        angular.forEach(parts, function (part) {
          var p = part.trim();

          var item = null;
          angular.forEach(scope.order.items, function (i) {
            if (i.product == p || i.code == p) {
              item = i;
            }
          });

          if (item === null) {
            return;
          }

          if (item.childproducts.length === 0) {
            item.added = true;
            return;
          }

          scope.productstoconfigure.push(item);
          scope.showconfigurechild = true;
          return;
        });
      };

      scope.removeProductToConfigure = function (p) {
        var index = scope.productstoconfigure.indexOf(p);
        if (index != -1) {
          scope.productstoconfigure.splice(index, 1);
        }

        if (scope.productstoconfigure.length == 0) {
          scope.selected.manualproducts = '';
          scope.showconfigurechild = false;
        }
      };

      scope.confirmChild = function (parent, child) {
        scope.selected.product = child;

        scope.removeProductToConfigure(parent);
      };

      scope.cancelChild = function (parent, child) {
        scope.removeProductToConfigure(parent);
      };
    }
  };

}]);

questionsModule.directive('questionOrder', ['$timeout', '$sce', 'orderSvc', 'bworkflowApi', '$q', 'languageTranslate', function ($timeout, $sce, orderSvc, bworkflowApi, $q, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    templateUrl: 'question_order.html',
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.answer = scope.presented.Answer;

      scope.visibleOrders = [];

      scope.findOrderForCatalog = function (orders, catalog, fsId) {
        var order = null;

        angular.forEach(orders, function (o) {
          if (o.id === catalog.id && (!fsId || o.facilityStructureId === fsId)) {
            order = o;
          }
        });

        return order;
      };

      scope.findTypeForProduct = function (item) {
        var type = null;
        angular.forEach(scope.presented.template.producttypes, function (t) {
          if (t.id === item.typeid) {
            type = t;
          }
        });

        return type;
      };

      scope.hasQuantity = function (i) {
        return angular.isDefined(i.quantity) && i.quantity !== null && i.quantity !== '' && i.quantity !== 0;
      };

      scope.populateOrders = function (orders, catalogs, fs) {
        var result = [];
        angular.forEach(catalogs, function (cat) {
          var order = scope.findOrderForCatalog(orders, cat, fs ? fs.Id : null);

          if (order === null) {
            order = angular.copy(cat);
            order.uniqueid = generateCombGuid();

            if (fs) {
              order.facilityStructureId = fs.Id;
              order.title = fs.Name;
            }

            orders.push(order);

            // map in the product types
            angular.forEach(order.items, function (i) {
              i.producttype = scope.findTypeForProduct(i);

              // to keep things simple for the addremove template, we have a property that indicates if a product
              // has been added to an order
              i.added = false;
              i.valid = true;
              if (scope.hasQuantity(i)) {
                i.added = true;
              }
            });
          }

          result.push(order);
        });

        return result;
      };

      scope.isOrderVisible = function (order) {
        if (scope.presented.template.showcatalogs.length === 0) {
          return true;
        }

        var visible = false;

        angular.forEach(scope.presented.template.showcatalogs, function (cat) {
          if (order.name.toLowerCase() === cat.toLowerCase()) {
            visible = true;
          }
        });

        return visible;
      };

      scope.fillVisibleOrders = function (orders) {
        scope.visibleOrders = [];
        angular.forEach(orders, function (o) {
          if (scope.isOrderVisible(o)) {
            scope.visibleOrders.push(o);
          }
        });
      };

      orderSvc.createTotaller().then(function (totaller) {
        scope.total = function (orders) {
          return totaller.total(orders);
        };
      });

      scope.populateOrders(scope.answer.orders, scope.presented.template.catalogs);
      scope.fillVisibleOrders(scope.answer.orders);

      scope.getAnswerValue = function () {
        return "This will get wrapped back round into us below";
      };

      scope.mandatoryvalidator = function (question, value, v, stage) {
        var result = "should pass mandatory";
        var addedCount = 0;
        var errorCount = 0;

        angular.forEach(scope.answer.orders, function (order) {
          angular.forEach(order.items, function (item) {
            item.valid = true;

            if (item.added === true) {
              addedCount = addedCount + 1;

              if (item.error) {
                errorCount++;
              }
              if (scope.hasQuantity(item) === false || item.error) {
                item.valid = false;
                result = null; // so there is an item that is marked as added, but has no quantity, so this aint right
              }
            }
          });
        });

        if (errorCount) {
          return {
            passed: false,
            message: 'There are errors with 1 or more items'
          };

        }

        if (addedCount === 0) {
          result = null;
        }

        if (result !== null) {
          return {
            passed: true
          };

        } else {
          return {
            passed: false,
            message: 'At least 1 item must be selected and all items selected must have a quantity set'
          };

        }
      };

      scope.removeOrder = function (order) {
        if (order.total > 0) {
          if (!confirm("Are you sure you want to remove order " + (order.title || order.name) + " ?")) {
            return;
          }
        }

        var i = scope.answer.orders.indexOf(order);
        if (i >= 0) {
          scope.answer.orders.splice(i, 1);
          scope.fillVisibleOrders(scope.answer.orders);

          scope.$emit('order.removed', order);
        }
      };

      scope.selectOrderTab = function (order) {
        $timeout(function () {
          $('#atab' + order.uniqueid).tab('show');
        });
      };

      // This PageScope callable function allows a Facility Structure question and Order question to pair up to provide support for ordering from multiple facility structure catalogs
      scope.pairWithFSQuestion = function (pairedFSQuestion, events) {
        scope.pairedFSQuestion = pairedFSQuestion;
        pairedFSQuestion.pairedOrderQuestion = scope;

        pairedFSQuestion.$watch('answer.SelectedFacilityStructureId', function (fsId) {
          if (fsId) {
            var fs = pairedFSQuestion.getSelectedFacilityStructure();
            scope.addFacilityStructureCatalog(fs).then(function (selectedOrders) {
              // Prepare FS question for next selection
              pairedFSQuestion.resetSelection();

              if (events && angular.isFunction(events.selectedFacilityStructure)) {
                events.selectedFacilityStructure(fs);
              }
              if (selectedOrders.length > 0) {
                scope.selectOrderTab(selectedOrders[0]);
              }
            });
          }
        });
      };

      scope.addFacilityStructureCatalog = function (facilityStructure, filterFn) {
        var pq;
        if (angular.isNumber(facilityStructure)) {
          var feedTemplate = {
            name: 'facilitystructure',
            feed: 'FacilityStructures',
            filter: 'Id eq ' + facilityStructure.toString(),
            orderbyfields: 'Name',
            idfields: ['Id'],
            itemsperpage: 1
          };

          var feed = bworkflowApi.createDataFeed(feedTemplate);
          pq = feed.getData(true).then(function (data) {
            if (data.length > 0) {
              return data[0].alldata;
            } else {
              return null;
            }
          });
        } else {
          pq = $q.when(facilityStructure);
        }
        return pq.then(function (fs) {
          return orderSvc.getProductCatalog(fs.Id).then(function (catalogs) {
            if (filterFn) {
              catalogs = filterFn(catalogs);
            }
            var selected = scope.populateOrders(scope.answer.orders, catalogs, fs);
            scope.fillVisibleOrders(scope.answer.orders);
            return selected;
          });
        });
      };

      scope.orderTemplate = function (order) {
        if (angular.isUndefined(order.template)) {
          order.template = angular.copy(scope.presented.template);
        }
        return order.template;
      };
    }
  });

}]);