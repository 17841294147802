'use strict';

import * as faceapi from 'face-api.js';

import app from '../ngmodule';
import { IWebServiceUrl } from '../../common/web-service-url';

export interface IVMFaceRecognitionService {
    detectSingleFace(input: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement): Promise<faceapi.FaceDetection | undefined>;
}

class vmFaceRecognitionService implements IVMFaceRecognitionService {
    protected isValid: boolean;

    static $inject = ['webServiceUrl'];
    constructor(protected webServiceUrl: IWebServiceUrl) {
        this.isValid = false;

        try {
            if (faceapi.nets.ssdMobilenetv1.isLoaded) {
                return;
            }

            faceapi.nets.ssdMobilenetv1.loadFromUri(this.webServiceUrl.site('vm-face-recognition/models')).then(() => this.isValid = true);
        }
        catch (error) {
            console.error('vmFaceRecognitionService failed to initialise', error);
        }
    }

    async detectSingleFace(input: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement): Promise<faceapi.FaceDetection | undefined> {
        if (this.isValid == false) {
            return undefined;
        }

        let detections = await faceapi.detectSingleFace(input);

        return detections;
    }
}

app.factory('vmFaceRecognition', vmFaceRecognitionService);