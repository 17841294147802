'use strict';

import './index.scss';
import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionSelectfacilitystructure', ['bworkflowApi', '$interval', 'languageTranslate', function (bworkflowApi, $interval, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.answer = scope.presented.Answer;

      scope.facilityStructureFeedFilters = [];

      scope.addFacilityStructureFeedFilter = function (filterFunction) {
        scope.facilityStructureFeedFilters.push(filterFunction);
      };

      scope.applyFacilityStructureFeedFilters = function (feed) {
        if (scope.facilityStructureFeedFilters.length == 0) {
          return; // nothing to do
        }

        // run through each filer function and remove anything it indicates to filter out
        angular.forEach(scope.facilityStructureFeedFilters, function (filterFunction) {
          var removals = filterFunction(feed.data);

          angular.forEach(removals, function (remove) {
            var index = feed.data.indexOf(remove);

            if (index != -1) {
              feed.data.splice(index, 1);
            }
          });
        });
      };

      scope.refreshFacilityStructureFeed = function () {
        scope.facilityStructureFeed.getData(true);
      };

      scope.getAnswerValue = function () {
        return scope.answer.SelectedFacilityStructureId;
      };

      scope.facilityStructureFeedTemplate = {
        name: 'facilitystructure',
        feed: 'FacilityStructures',
        filter: "Archived eq false and ParentId eq [[selectedFacilityStructureId]]",
        orderbyfields: 'SortOrder,Name',
        idfields: ['Id'],
        itemsperpage: 100,
        parameterdefinitions: [{
          internalname: 'selectedFacilityStructureId'
        }]
      };



      scope.selectedFacilityStructureId = null;

      scope.defaultSelectedFacilityStructureId = "null";
      if (scope.presented.startfromparentid != null) {
        scope.defaultSelectedFacilityStructureId = scope.presented.startfromparentid;
      }

      scope.selectedHistory = [];

      scope.facilityStructureFeed = bworkflowApi.createDataFeed(scope.facilityStructureFeedTemplate, scope);

      scope.facilityStructureFeed.addAfterLoadHook(function (feed) {
        scope.applyFacilityStructureFeedFilters(feed);

        if (feed.data.length == 0) {
          // assume we are at the bottom and select our answer
          scope.answer.SelectedFacilityStructureId = scope.selectedFacilityStructureId;
        }
      });

      // On error, retry
      scope.facilityStructureFeed.addAfterErrorHook(function (feed) {
        feed.countdown = 5;
        feed.timer = $interval(function () {
          if (--feed.countdown == 0) {
            $interval.cancel(feed.timer);
            feed.getData(true);
          }
        }, 1000);
      });

      scope.$watch('selectedFacilityStructureId', function (newValue, oldValue) {
        if (newValue === null) {
          // we change the filter as a null variable get's inserted into the filter as ''
          scope.facilityStructureFeed.template.filter = "Archived eq false and ParentId eq " + scope.defaultSelectedFacilityStructureId;
        } else {
          scope.facilityStructureFeed.template.filter = "Archived eq false and ParentId eq [[selectedFacilityStructureId]]";
        }

        scope.facilityStructureFeed.parameters.selectedFacilityStructureId = newValue;
        scope.facilityStructureFeed.getData(true);
      });

      scope.selectFacilityStructure = function (structure, index, $event) {
        // a final answer can't be arrived at if they've just made a selection as more data
        // needs to get loaded through odata for the next level. If nothing comes back on that (see afterloadhook)
        // then we'll set the answer
        scope.answer.SelectedFacilityStructureId = null;

        if (index !== -1) {
          // 0 or positive value indicates a backward movement (using breadcrumbs), so we need to chop off the end
          // of our history to just before this
          scope.selectedHistory.splice(index, scope.selectedHistory.length - index);
        }

        if (structure !== null) {

          scope.selectedHistory.push(structure);

          scope.selectedFacilityStructureId = structure.alldata.Id;
        } else {
          scope.selectedFacilityStructureId = null;
        }

        if (angular.isDefined($event) === true) {
          $event.preventDefault();
        }
      };

      scope.raiseEvent = function (eventName, args) {
        // allows someone to raise an event to get other elements to do things,
        // for example clicking a button in the label refreshing a task list
        scope.$emit('player_broadcast', {
          name: eventName,
          data: args
        });

      };

      scope.getSelectedFacilityStructure = function () {
        if (!scope.answer.SelectedFacilityStructureId) {
          return null;
        }
        return scope.selectedHistory[scope.selectedHistory.length - 1].alldata;
      };

      scope.resetSelection = function () {
        scope.selectedFacilityStructureId = null;
        scope.selectedHistory = [];
      };

      scope.popToParent = function () {
        var fs = scope.getSelectedFacilityStructure();
        if (fs && fs.ParentId && fs.Id !== scope.defaultSelectedFacilityStructureId) {
          scope.selectedFacilityStructureId = fs.ParentId;
          scope.selectedHistory.pop();
          scope.answer.SelectedFacilityStructureId = null;
        } else {
          scope.resetSelection();
        }
      };
    }
  });

}]);