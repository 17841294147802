import { IWebServiceUrl } from "../../../../../common/web-service-url";
import { FacilityType, OData, ILabel, ILabelsFacility, ILabelsFacilityDefinition, Guid } from 'lingo-api';
import { ReadOnlyODataFacility } from "../read-only-odata-facility";
import { ODataBuilder } from '../../../../odata-builder';

export class LabelsFacility extends ReadOnlyODataFacility<Guid, ILabel, OData.Offline.Label> implements ILabelsFacility {
	readonly facilityType: FacilityType = `labels`;

	static $inject = [
		`definition`,
		`webServiceUrl`,
		`$http`];
	constructor(
		private definition: ILabelsFacilityDefinition
	) {
		super(`Labels`, {
			uniqueId: r => r.id.toString()
		});
	}

	mapRecord(odata: OData.Offline.Label): ILabel {
		return {
			...odata,
			labelGroup: ''			// TODO
		}
	}
}
