'use strict';

import mod from './ngmodule';
import './services';
import './run';

import './dashboard';
import './player';
import './select';

import './embedded-player';

export default mod;