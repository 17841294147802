import { IOfflineChecklistExecutionQueue, ExecutionQueue } from '../../bworkflow-api/types';
import { IWorkingDocumentFinishParameters } from './types';
import ngmodule from './ngmodule';
import { IPlayerExecute } from '../../bworkflow-api/player-execute';

const HANDLER = `OfflineChecklist`;

export class OfflineChecklistExecutionQueue extends ExecutionQueue implements IOfflineChecklistExecutionQueue {
	static $inject = [`$q`, `RequestsErrorHandler`, `PlayerExecute`];
	constructor(private $q: ng.IQService, private RequestsErrorHandler: any, private PlayerExecute: IPlayerExecute) {
		super();
	}

	finish(parameters: IWorkingDocumentFinishParameters): ng.IPromise<any> {
		var deferred = this.$q.defer();

		this.RequestsErrorHandler.specificallyHandled(
			() => {
				this.$q.all({ execute: this.PlayerExecute(HANDLER, `Finish`, parameters) }).then(
					function (result) {
						deferred.resolve(result.execute);
					},
					function (reason) {
						deferred.reject(reason);
					});

			});


		return deferred.promise;
	}
}

ngmodule.factory(`offlineChecklistExecutionQueue`, OfflineChecklistExecutionQueue);