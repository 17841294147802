'use strict';

import './index.scss';

import app from '../../ngmodule';
import angular from 'angular';
import '../../../services/bworkflow-api/bworkflow-api';
import '../../../services/transition-cache';

app.controller('dashboardContentCtrl', ['$scope',
  '$stateParams',
  '$state',
  'bworkflowApi',
  'transitionCache',
  '$compile',
  '$timeout',
  '$rootScope',
  '$window',
  'vmDashboardService',
  function ($scope, $stateParams, $state, bworkflowApi, transitionCache, $compile, $timeout, $rootScope, $window, vmDashboardService) {
    $scope.loading = true;

    $scope.introoptions = {};

    $scope.$on('player_broadcast', function (event, args) {
      if (args.__handled) {
        return;
      }
      // this event gets raised by questions when they want to notify other questions that something has changed.
      // typically this occurrs in a dashboard. We need to broadcast this back down the scopes to let others know
      // of the change. The event object created by who ever created the event should be of the form

      // args.name = name of event to broadcast out
      // args.data = object containing arguments relevant to the event
      $scope.$broadcast(args.name, args.data);
      args.__handled = true;
    });

    $scope.$on('player_broadcast_ajax_error', function (event, args) {
      // this allows code to handle the ajax errors themselves and then
      // to also broadcast it out and have it handled by the normal code as well.
      // An example of this is the odata feeds, which add the noUI flag to the object
      // so its logged on the server, but nothing is shown to the user
      $scope.$broadcast('ajax.error', args);
    });

    vmDashboardService.getDashboards($stateParams.name).then(models => {
      $scope.computedDashboardModels = models;
    });
  }]);