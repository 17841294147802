import * as uuid from 'uuid';
import { ValidationFunction, ValidateWith } from "../../lingojs/lingo-program-context";
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { IPresentedAnswer, IPresentedQuestion, IPresented } from "../../presented-types";
import moment from "moment";
import { IQuestionDefinition, QuestionType, IHtmlPresentationDefinition, DesignQuestion } from 'lingo-api';

export abstract class Question implements DesignQuestion {
	questionType: QuestionType;
	id: string = ``;
	name: string = ``;
	htmlPresentation?: IHtmlPresentationDefinition;
	visible?: boolean;
	prompt?: string;
	hidePrompt?: boolean;
	note?: string;
	allowNotes?: boolean;
	labels: string[] = [];

	constructor(questionType: QuestionType) {
		this.questionType = questionType;
	}

	public makeId(name: string) {
		return uuid.v5(name, this.id);
	}

	validateWith(fn: ValidationFunction): ValidateWith {
		return {
			question: this,
			validation: fn
		}
	}
}

export abstract class Answer implements ILingoAnswer {
	private _value: any;
	private _passFail: boolean | null = null;
	private _score: number | null = null;
	private _answeredDateUtc: moment.Moment | null = null;
	private _notes: string | null = null;

	constructor(public answer: IPresentedAnswer, public presented: IPresented) {
		this._answeredDateUtc = moment().utc();
	}

	get value() {
		return this._value;
	}
	set value(value: any) {
		this._value = value;
	}

	get passFail(): boolean | null {
		return this._passFail;
	}
	set passFail(value: boolean | null) {
		this._passFail = value;
	}
	get score(): number | null {
		return this._score;
	}
	set score(value: number | null) {
		this._score = value;
	}
	get answeredDateUtc(): moment.Moment | null {
		return this._answeredDateUtc;
	}
	set answeredDateUtc(value: moment.Moment | null) {
		this._answeredDateUtc = value;
	}
	get notes(): string | null {
		return this._notes;
	}
	set notes(value: string | null) {
		this._notes = value;
	}

	toString(): string | null {
		let v = this.value;
		if (v == null) {
			return null;
		}
		return v.toString();
	}

	finishParameter() {
		let p = this.presented as IPresentedQuestion;
		return {
			presented: {
				presentedId: p.Id,
				questionId: p.QuestionId,
				name: p.Name,
				questionType: p.QuestionType,
				prompt: p.Prompt,
				possibleScore: p.definition.score,
				labels: p.definition.labels,
				presentedDateUtc: p.presentedDateUtc
			},
			value: this.toString(),
			passFail: this.passFail,
			score: this.score,
			answeredDateUtc: this.answeredDateUtc,
			notes: this.notes
		}
	}
}