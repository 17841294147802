'use strict';

import app from '../../../ngmodule';
import { ControllerBase } from '../../../control-base';

export class CardBodyController extends ControllerBase {
    public image: string | undefined;

    static $inject = ['$element', '$timeout'];
    constructor(protected $element: ng.IRootElementService, protected $timeout: ng.ITimeoutService) {
        super();

        this.image = undefined;
    }

    $onInit(): void {
        if (this.image == undefined) {
            this.image = this.valueFromHtml("image", '', this.$element);
        }
    }
}

app.component('vmCardBody', {
    template: require('./template.html').default,
    transclude: true,
    bindings: {
        image: '<',
    },    
    controller: CardBodyController
});