'use strict';

import app from '../ngmodule';
import angular from 'angular';

app.factory('autoLogoutSvc', ['tabletProfile', '$interval', '$rootScope', function (tabletProfile, $interval, $rootScope) {
  var _autoLogoutTimePromise = null;

  var svc = {
    autoLogoutTime: null,
    startAutoLogoutTimer: function (secs, override) {
      if (svc.autoLogoutTime == null || svc.autoLogoutTime < secs || override) {
        svc.autoLogoutTime = secs;
      }

      if (!_autoLogoutTimePromise) {
        _autoLogoutTimePromise = $interval(function () {
          svc.autoLogoutTime = svc.autoLogoutTime - 1;

          if (svc.autoLogoutTime <= 0) {
            svc.logoutNow();
          }
        }, 1000);
      }
    },
    logoutNow: function () {
      $rootScope.$emit('auto-logout.execute');
    },
    logoutShortSecs: 0,
    logoutLongSecs: 0,
    shortRestart: function (override) {
      if (svc.logoutShortSecs) {
        svc.startAutoLogoutTimer(svc.logoutShortSecs, override);
      }
    },
    longRestart: function (override) {
      if (svc.logoutLongSecs) {
        svc.startAutoLogoutTimer(svc.logoutLongSecs, override);
      }
    }
  };


  tabletProfile(function (profile) {
    svc.logoutLongSecs = profile.autologoutlongsecs;
    svc.logoutShortSecs = profile.autologoutshortsecs;

    svc.longRestart();
  });

  return svc;
}]);