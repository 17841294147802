import app from './ngmodule';
import { IValidationMessageFactory, IValidationResult, IValidationRule } from '../';
import { ValidationRuleBase } from './validation-rule-base';

// Validation rule that fails if the value handed into it is undefined or null
export class MandatoryValidationRule extends ValidationRuleBase {
    // if true then '' is treated the same as null | undefined and the rule fails, if false then '' is treated as a value and the rule passes.
    // this defaults to true
    protected treatEmptyStringAsNull: boolean;

    constructor(message: string | IValidationMessageFactory, treatEmptyStringAsNull?: boolean) {
        super(message);

        this.treatEmptyStringAsNull = true;

        if (treatEmptyStringAsNull != null) {
            this.treatEmptyStringAsNull = treatEmptyStringAsNull;
        }
    }

    isValid(value: any, template: any): IValidationResult {
        return ((value == null) || (value != null && value.toString() == '' && this.treatEmptyStringAsNull == true)) ? { errors: [this.getMessage(value, template)] } : { errors: [] };
    }
}