'use strict';

import app from './ngmodule';
import './services/auto-logout-svc';
import './services/app-update-monitor';
import { WebServiceUrl } from '../common/web-service-url';

app.run(['$rootScope', '$window', 'autoLogoutSvc', 'appUpdateMonitor', function ($rootScope, $window, autoLogoutSvc, appUpdateMonitor) {
  $rootScope.stateOrder = ['root.select.groups', 'root.select.checklists', 'root.select.reviewees', 'root.play.begin'];
  $rootScope.nonceStack = []; //for the ordering of checklist forward/back

  // fix for EVS-780, this handles keyboard presses and makes sure that the auto logout side
  // of things get's reset with each key press
  $window.addEventListener('keyup', function (e) {
    autoLogoutSvc.longRestart(false);
  }, true);

  $window.addEventListener('click', function (e) {
    autoLogoutSvc.shortRestart(false);
  }, true);

  // fix for EVS-781, this stops the hardware backbutton in android doing nasty things
  // to the users in the app (logging them out etc)
  $window.document.addEventListener('backbutton', function (e) {
    e.preventDefault();
  }, false);
}]);

