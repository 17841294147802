'use strict';

import app from '../ngmodule';

app.config(['$stateProvider', function ($stateProvider) {

  $stateProvider.
    state('root.select', {
      url: '/select',
      abstract: true
    }).

    state('root.select.groups', {
      url: '/groups',
      views: {
        'header-control@root': {
          template: ''
        },

        'content@root': {
          component: 'groupsCtrl'
        }
      },


      resolve: {
        groups: ["bworkflowApi", function (bworkflowApi) {
          return bworkflowApi.getPublishedGroups();
        }]
      }
    }).


    state('root.select.checklists', {
      url: '/checklists/:groupId',
      views: {
        'content@root': {
          component: 'checklistsCtrl'
        }
      },


      resolve: {
        group: ["bworkflowApi", "$stateParams", function (bworkflowApi, $stateParams) {
          return bworkflowApi.getPublishedGroup($stateParams.groupId);
        }]
      }
    }).


    state('root.select.reviewees', {
      url: '/reviewees/:groupId/:resourceId/:publishingGroupResourceId',
	  params : {
		resourceId : "0"
	  },
      views: {
        'content@root': {
          component: 'revieweesCtrl'
        }
      },


      resolve: {
        group: ["bworkflowApi", "$stateParams", function (bworkflowApi, $stateParams) {
          return bworkflowApi.getPublishedGroup($stateParams.groupId);
        }]
      }
    });


}]);