'use strict';

import questionsModule from '../ngmodule';

questionsModule.constant('ChecklistState', {
  Error: 'Error',
  Running: 'Running',
  Presenting: 'Presenting',
  Blocked: 'Blocked',
  Finishing: 'Finishing',
  Finished: 'Finished'
});