'use strict';

import app from '../../ngmodule';
import angular from 'angular';
import { ITreeViewNode, ITreeViewNodeSelectedCallback } from '../interfaces';
import { ControllerBase } from '../../control-base';

export class TreeViewLevelController extends ControllerBase {
    protected item?: ITreeViewNode;

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();
    }
}

app.component('vmTreeViewLevel', {
    template: require('./template.html').default,
    bindings: {
        item: '<'
    },
    controller: TreeViewLevelController
});