'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionPresentation', ['bworkflowApi', '$sce', 'languageTranslate', 'vmRegisteredComponentProvider', function (bworkflowApi, $sce, languageTranslate, vmRegisteredComponentProvider) {
    return $.extend({}, questionDirectiveBase, {
        template: require('./template.html').default,
        link: function (scope, elt, attrs) {
            scope.presented.showValidationErrorsAtTop = true;

            scope.answer = scope.presented.Answer;
            scope.pageNumber = 0;

            if (scope.answer.Data && scope.answer.Data.length) {
                scope.pageNumber = scope.answer.Data[0];
            }

            questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

            scope.pageChanged = function (evt) {
                scope.pageCount = evt.pageCount;
                scope.pageNumber = evt.pageNumber;
            };

            scope.getAnswerValue = function () {
                return [scope.pageNumber];
            };

            scope.$on('populateAnswer', function (ev) {
                scope.answer.Data = [scope.pageNumber];
            });

            scope.mandatoryvalidator = function (question, value, v, stage) {
                if (scope.pageCount !== scope.pageNumber) {
                    return { passed: false, message: v.message };
                }

                return { passed: true };
            };
        }
    });
}]);
