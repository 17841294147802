'use strict';

import './vm-card-header';
import './vm-card-body';
import './vm-card-footer';
import './index.scss';

import app from '../../ngmodule';

import angular from 'angular';
import { ControllerBase } from '../../control-base';
import { CardListController } from '..';

export class CardController extends ControllerBase {
    public cardList: any;
    public focussed: boolean;

    static $inject = ['$element'];
    constructor(protected $element: ng.IRootElementService) {
        super();

        this.cardList = null;
        this.focussed = false;
    }

    // we have both title and title-as-string due to the fact that
    // when this is put in a repeater question, having a string with a space in the title
    // was causing an error in the $compile used in the repeater. We can wrap single quotes around things, so
    // title="'something with a space'" but have also added title-as-string which expects a string to side step this
    $onInit(): void {
        this.cardList.addCard(this);
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.focussed && changes.focussed.currentValue != null) {
            this.focussed = changes.focussed.currentValue;

            if (this.cardList != null) {
                if (this.focussed == true) {
                    this.$element.addClass('focussed');
                    this.cardList.focusOnCard(this);
                }
                else if (changes.focussed.previousValue == true) {
                    this.$element.removeClass('focussed');
                    this.cardList.focusOffCard(this);
                }
            }
        }
    }

    hide(): void {
        if (this.$element.hasClass('d-none')) {
            return;
        }

        this.$element.addClass('d-none');
    }

    show(): void {
        if (this.$element.hasClass('d-none') == false) {
            return;
        }

        this.$element.removeClass('d-none');
    }
}

app.component('vmCard', {
    template: require('./template.html').default,
    bindings: {
        focussed: '<'
    },
    transclude: true,
    require: {
        cardList: '^vmCardList'
    },
    controller: CardController
});