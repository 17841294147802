'use strict';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';
import moment from 'moment';
import '../../directives/vm-datetime-picker';


questionsModule.directive('questionDateTime', ['bworkflowApi', 'languageTranslate', function (bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.answer = scope.presented.Answer;

      if (scope.answer.DateTime != null) {
        scope.answer.DateTime = moment(scope.answer.DateTime).toDate();
      }

      scope.debug = function () {
        debugger;
      }

      scope.presented.controlShowsPrompt = true;
      scope.presented.controlShowsValidationErrors = true;

      scope.updateSources = function (newDate) {
        if (scope.presented.updatedatasources != null && scope.presented.updatedatasources != '') {
          var sources = scope.presented.updatedatasources.split(',');

          angular.forEach(sources, function (source) {
            var promise = bworkflowApi.getDataFeed(source);

            if (promise != null) {
              promise.then(function (toUpdate) {
                var startname = scope.presented.Name;
                var endname = startname;

                if (startname == null || startname == '') {
                  startname = 'StartDate';
                  endname = 'EndDate';
                } else {
                  // this will make it available as startnameStartdate where
                  // startname is the name of the question, notice the - results
                  // in the startdate being capitilized (Angular) rather than
                  // it being accessed through the - if you get my drift.
                  startname = startname + 'StartDate';
                  endname = endname + 'EndDate';
                }

                // we do a range of dates to make things easy on the odata side
                toUpdate.parameters[startname] = angular.copy(newDate);
                toUpdate.parameters[endname] = angular.copy(newDate).addDays(1);

                // get data and force a refresh
                toUpdate.getData(true);
              });
            }
          });
        }
      };

      // this supports validation as each question type stores it's answer in a different way
      scope.getAnswerValue = function () {
        return moment(scope.answer.DateTime).isValid() ? scope.answer.DateTime : null;
      };

      // Adding this in as a fix for EVS-1237
      scope.$on('populateAnswer', function (ev) {
        var m = moment(scope.answer.DateTime);

        if (m.isValid() == false) {
          return;
        }

        if (scope.sendAsLocalTime) {
          scope.answer.DateTime = m.format('YYYY-MM-DD') + 'T00:00:00.000';
        } else {
          scope.answer.DateTime = m.format('YYYY-MM-DD') + 'T00:00:00.000Z';
        }
      });

      if (scope.presented.updatedatasources != null && scope.presented.updatedatasources != '') {
        scope.$watch('answer.DateTime', function (newValue, oldValue) {
          if (newValue == null || moment(newValue).isValid() == false) {
            return;
          }

          scope.updateSources(newValue);
        });
      }

      function isBetween(m, s, e) {
        if (!s || !e) {
          return false;
        }
        s = moment(s);
        e = moment(e);
        if (!s.isValid() || !e.isValid()) {
          return false;
        }
        return !m.isBefore(s) && !m.isAfter(e);
      }

      scope.dateOnRender = function (date) {
        var m = moment(date);

        if (angular.isDefined(scope.minDate)) {
          if (m.isBefore(scope.minDate)) {
            return 'disabled';
          }
        }
        if (angular.isDefined(scope.maxDate)) {
          if (m.isAfter(scope.maxDate)) {
            return 'disabled';
          }
        }

        var between;
        if (angular.isDefined(scope.pairedStartDate)) {
          between = isBetween(m, scope.pairedStartDate.answer.DateTime, scope.answer.DateTime);
        } else if (angular.isDefined(scope.pairedEndDate)) {
          between = isBetween(m, scope.answer.DateTime, scope.pairedEndDate.answer.DateTime);
        }
        return between;
      };

      scope.dateChanged = function (evt) {
        scope.answer.DateTime = evt.value;

        scope.validate('single');
      };

      scope.setMinDate = function (dt) {
        scope.minDate = moment(dt);
        if (scope.minDate.isValid()) {
          var m = moment(scope.answer.DateTime);
          if (m.isValid()) {
            if (m.isBefore(scope.minDate)) {
              scope.answer.DateTime = scope.minDate.toDate();
            }
          }
        }
      };

      scope.setMaxDate = function (dt) {
        scope.maxDate = moment(dt);
        if (scope.maxDate.isValid()) {
          var m = moment(scope.answer.DateTime);
          if (m.isValid()) {
            if (m.isAfter(scope.maxDate)) {
              scope.answer.DateTime = scope.maxDate.toDate();
            }
          }
        }
      };

      scope.pairWithEndDate = function (endDateQuestion, minMaxDates) {
        scope.pairedEndDate = endDateQuestion;
        endDateQuestion.pairedStartDate = scope;

        if (angular.isArray(minMaxDates) && minMaxDates.length > 0) {
          var min = moment(minMaxDates[0]);
          if (min.isValid()) {
            scope.setMinDate(min);
            endDateQuestion.setMinDate(min);
          }
          if (minMaxDates.length > 1) {
            var max = moment(minMaxDates[1]);
            if (max.isValid()) {
              scope.setMaxDate(max);
              endDateQuestion.setMaxDate(max);
            }
          }
        }
      };
    }
  });

}]);