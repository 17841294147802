import { EventEmitter } from "events";
import { IAuthService, IUser } from ".";
import { debugFunc, DebugFunc } from "../../../utils/debug-func";
import { IWebServiceUrl } from "../../../common/web-service-url";

export const unknownUser: IUser = { authenticated: false, unknown: true, anonymous: false, email: '', roles: [], userId: '', username: '' };
export const anonymousUser: IUser = { authenticated: false, unknown: false, anonymous: true, email: '', roles: [], userId: '', username: '' };

export type AuthState = 'logged-out' | 'logging-in' | 'logged-in' | 'logging-out';

export abstract class BaseAuthService implements IAuthService {
	private $user?: ng.IPromise<IUser>;
	private $login?: ng.IDeferred<void>;
	private $logout?: ng.IDeferred<void>;

	private _state: AuthState = 'logged-out';

	events: EventEmitter = new EventEmitter();

	get state(): AuthState {
		return this._state;
	}

	constructor(
		protected $q: ng.IQService,
		protected $http: ng.IHttpService,
		protected webServiceUrl: IWebServiceUrl,
		protected $rootScope: ng.IRootScopeService,
		protected log: DebugFunc
	) {
	}

	setState(newState: AuthState, user?: IUser) {
		if (this._state !== newState) {
			this._state = newState;
			this.log.info(`${newState} ${user?.username ?? ''}`);
			this.events.emit(newState, {
				user
			});
		}
	}

	async onLogin(): Promise<void> {
		delete this.$login;
		delete this.$logout;
		delete this.$user;
		this.getUser().then(user => {
			this.$rootScope.$emit('auth-login');
			this.setState('logged-in', user);
		});
	}

	async onLogout(): Promise<void> {
		delete this.$user;
		delete this.$login;
		delete this.$logout;
		this.setState('logged-out');
	}

	// internal getUser can be called multiple times which may be needed for refreshToken to work
	_getUser(): ng.IPromise<IUser> {
		return this.$http.get<IUser>(this.webServiceUrl.auth('GetUser')).then(response => response.data);
	}

	// External getUser will gaurantee only 1 request is pending at a time
	getUser(): ng.IPromise<IUser> {
		// Unknown user, retrieve it ..
		if (!this.$user) {
			this.$user = this._getUser();
		}
		return this.$user;
	}

	abstract isAuthenticated(): ng.IPromise<boolean>;

	hasRole(user: IUser, role: string): boolean {
		for (let r of user.roles) {
			if (r.toLowerCase() == role.toLowerCase()) {
				return true;
			}
		}

		return false;
	}

	hasOneOfRoles(user: IUser, roles: Array<string>): boolean {
		for (let r of roles) {
			if (this.hasRole(user, r) == true) {
				return true;
			}
		}

		return false;
	}

	hasAllOfRoles(user: IUser, roles: Array<string>): boolean {
		for (let r of roles) {
			if (this.hasRole(user, r) == false) {
				return false;
			}
		}

		return true;
	}

	cancelLogin() {
		delete this.$login;
		delete this.$logout;
		delete this.$user;
	}

	abstract _login(): ng.IDeferred<void>;
	login(): ng.IPromise<IUser> {
		if (!this.$login) {
			this.setState('logging-in');
			this.$login = this._login();
			this.$login.promise.then(() => {
				this.onLogin();
			});
		}
		return this.$login.promise.then(() => {
			return this.getUser();
		})
	}

	abstract loginTicket(ticketId: string): ng.IPromise<IUser>;

	abstract _logout(): ng.IDeferred<void>;
	logout(): ng.IPromise<void> {
		return this.isAuthenticated().then(authenticated => {
			if (authenticated) {
				if (!this.$logout) {
					this.setState('logging-out');
					this.$logout = this._logout();
					this.$logout.promise.then(() => {
						this.onLogout();
					});
				}
				return this.$logout.promise;
			}
		});
	}
}