'use strict';

import './index.scss';

import app from '../ngmodule';
import angular from 'angular';
import { ControllerBase } from '../control-base';
import { IVMRegisteredComponentProvider, IVMRegisteredComponent } from '../../services/vm-registered-component-provider';
import { IWebServiceUrl } from '../../common/web-service-url';
import moment from 'moment';
import { IAuthService } from '../../services/auth/auth-svc/index';
import { IMediaOnClient, IMediaOnServer, IVMMediaService, MediaOnServerSource } from '../../services/vm-media-service';
import { DetectSingleFaceLandmarksTask } from 'face-api.js';

interface IPresentationRenderer {
    render(element: HTMLElement, page: IPresentationMedia, zoom: number): ng.IPromise<void>;
}

interface IPresentationMediaOverride {
    page: number,
    media: IPresentationMedia
}

enum PresentationMediaType {
    word = 'word',
    img = 'img',
    youtube = 'youtube',
    vimeo = 'vimeo'
}

interface IPresentationMedia {
    type: string,
    id?: string,
    url?: string | IMediaOnServer | IMediaOnClient,
    title?: string,
    allow?: string,
    name?: string,
    filename?: string,
    pages?: number,
    clientTrackingId?: number,
    chapter?: IPresentationChapter
}

interface IPresentationMediaWithOverride extends IPresentationMedia {
    overrides?: Array<IPresentationMediaOverride>
}

interface IPresentationChapter {
    name: string,
    media: Array<IPresentationMediaWithOverride>,
    index?: number,
    pages?: Array<PresentationPage>
}

interface IPresentationModule {
    module: string,
    chapters: Array<IPresentationChapter>
}

interface IPresentationGetPageCountResult {
    media: Array<IPresentationMedia>;
}

class PresentationPage implements IPresentationMedia {
    public index: number;
    public type: string;
    public id?: string;
    public url?: string | IMediaOnServer | IMediaOnClient;
    public title?: string;
    public allow?: string;
    public chapter?: IPresentationChapter;

    isWithOveride(value: IPresentationMedia | IPresentationMediaWithOverride): value is IPresentationMediaWithOverride {
        if (value == null) {
            return false;
        }

        return (value as IPresentationMediaWithOverride).overrides !== undefined;
    }

    constructor(index: number, media: IPresentationMedia | IPresentationMediaWithOverride) {
        this.index = index; // this is the page number within the media, ie if there is a video on page 1 of the presentation and a 10 page word document as the second piece of media, this index will be 1 to 10 for the word document (not 2 to 11)

        this.type = media.type;
        this.id = media.id;
        this.url = media.url;
        this.title = media.title;
        this.allow = media.allow;

        if (media.chapter != null) {
            this.chapter = media.chapter;
            if (this.chapter.pages != null) {
                this.chapter.pages.push(this);
            }
        }

        if (this.isWithOveride(media)) {
            if (media.overrides == null) {
                return;
            }

            for (let o of media.overrides) {

                if (o.page == this.index) {
                    this.type = o.media.type;
                    this.id = o.media.id;
                    this.url = o.media.url;
                    this.title = o.media.title;
                    this.allow = o.media.allow;
                }
            }
        }
    }
}

class CanvasPageRenderer implements IPresentationRenderer {
    constructor(protected $q: ng.IQService, protected vmMediaService: IVMMediaService) { }

    render(element: HTMLElement, page: IPresentationMedia, zoom: number): ng.IPromise<void> {
        if (element == null) {
            return this.$q.reject("An element must be provided");
        }

        let canvas: HTMLCanvasElement = element as HTMLCanvasElement;

        if (canvas == null) {
            return this.$q.reject('Element needs to be a HTML canvas');
        }

        let p = this.$q.defer<void>();

        if (this.vmMediaService.isMediaOnServer(page.url)) {
            page.url = this.vmMediaService.createOnClientFromOnServer([page.url])[0]; // replace the on server representation with an onclient one, so that the download of things can be cached

            this.loadAndDrawPage(page.url, canvas, zoom, p);
        }
        else if (this.vmMediaService.isMediaOnClient(page.url)) {
            this.loadAndDrawPage(page.url, canvas, zoom, p);
        }
        else {
            return this.$q.reject('page object is not of the correct type');
        }

        return p.promise;
    }

    loadAndDrawPage(onClient: IMediaOnClient, canvas: HTMLCanvasElement, zoom: number, defer: ng.IDeferred<void>): void {
        this.vmMediaService.loadClient(onClient).then((client) => {
            if (onClient.data?.loadedAsBlob) {
                defer.reject('There was an issue obtaining the canvas context');
                return;
            }

            let img = new Image();
            img.onload = () => {
                var ctx = canvas.getContext("2d");

                if (ctx == null) {
                    defer.reject('There was an issue obtaining the canvas context');
                    return;
                }

                if (zoom > 0) // indicates a none fit to page zooming
                {
                    canvas.width = img.width * zoom;
                    canvas.height = img.height * zoom;

                    ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);
                }
                else {
                    ctx.drawImage(img, 0, 0); // let the browser do the scaling to fit to page
                }

                defer.resolve();
            };

            img.src = 'data:image/png;base64,' + client.data?.data;
        }).catch((reason) => {
            defer.reject('There was an issue loading the media');
        });
    }
}

class FramePageRenderer implements IPresentationRenderer {
    constructor(protected $q: ng.IQService) { }

    render(element: HTMLElement, page: IPresentationMedia, zoom: number): ng.IPromise<void> {
        if (element == null) {
            return this.$q.reject("An element must be provided");
        }

        let frame: HTMLFrameElement = element as HTMLFrameElement;

        if (frame == null) {
            return this.$q.reject('Element needs to be a HTML frame');
        }

        if (page.url == null) {
            return this.$q.reject('Page must have a URL');
        }

        frame.src = page.url as string;

        return this.$q.when();
    }
}

class RendererFactory {
    private rendererCache: any;

    constructor(protected $q: ng.IQService, protected vmMediaService: IVMMediaService) {
        let canvasRenderer = new CanvasPageRenderer(this.$q, this.vmMediaService);
        let frameRenderer = new FramePageRenderer(this.$q);

        this.rendererCache = {};

        this.rendererCache[PresentationMediaType.word] = canvasRenderer;
        this.rendererCache[PresentationMediaType.img] = canvasRenderer;
        this.rendererCache[PresentationMediaType.vimeo] = frameRenderer;
        this.rendererCache[PresentationMediaType.youtube] = frameRenderer;
    }

    getPageRenderer(page: IPresentationMedia): IPresentationRenderer {
        return this.rendererCache[page.type];
    }
}

class PresentationController extends ControllerBase implements IVMRegisteredComponent {
    public name: string;
    private _modules: Array<IPresentationModule>;
    public selectedModule: string | number | IPresentationModule;
    public selectedChapter: string | number | IPresentationChapter;

    public currentModule: IPresentationModule | null;

    public pageNumber: number;

    protected media: Array<IPresentationMedia>;
    protected pages: Array<PresentationPage>;
    public previousPage: PresentationPage | null;
    public currentPage: PresentationPage | null;

    protected rendererFactory: RendererFactory;

    protected viewMode: string;
    protected canvasElement: HTMLCanvasElement | null;
    protected currentScale: number;

    public backButtonText: string;
    public nextButtonText: string;

    public onPageChange?: null | ((args: any) => void) = null;

    private lastStateKey: string = '';                      // Used to detect and remove duplicate navigates
    public userId: string | null = null;
    public workingDocumentId: string | null = null;

    getComponentType(): string {
        return 'Presentation';
    }

    isModuleString(value: string | number | IPresentationModule): value is string {
        if (value == null) {
            return false;
        }

        return (value as string).substr !== undefined;
    }

    isModuleNumber(value: string | number | IPresentationModule): value is number {
        if (value == null) {
            return false;
        }

        return (value as number).toExponential !== undefined;
    }

    isChapterString(value: string | number | IPresentationChapter): value is string {
        if (value == null) {
            return false;
        }

        return (value as string).substr !== undefined;
    }

    isChapterNumber(value: string | number | IPresentationChapter): value is number {
        if (value == null) {
            return false;
        }

        return (value as number).toExponential !== undefined;
    }

    static $inject = ['$element', '$sce', '$q', 'vmRegisteredComponentProvider', 'bworkflowApi', 'webServiceUrl', '$injector', 'vmMediaService', '$timeout'];
    constructor(
        protected $element: ng.IRootElementService,
        protected $sce: ng.ISCEService,
        protected $q: ng.IQService,
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider,
        protected bworkflowApi: any,
        protected webServiceUrl: IWebServiceUrl,
        protected $injector: any,
        protected vmMediaService: IVMMediaService,
        protected $timeout: ng.ITimeoutService) {
        super();

        this.name = '';
        this._modules = new Array<IPresentationModule>();
        this.media = new Array<IPresentationMedia>();
        this.selectedModule = '';
        this.selectedChapter = '';
        this.currentModule = null;
        this.pageNumber = 0;
        this.previousPage = null;
        this.currentPage = null;
        this.pages = new Array<PresentationPage>();

        this.viewMode = 'fit-page';
        this.currentScale = 1;

        this.canvasElement = null;

        this.backButtonText = '';
        this.nextButtonText = '';

        this.rendererFactory = new RendererFactory(this.$q, this.vmMediaService);
    }

    $onInit(): void {
        if (this.name != null) {
            this.vmRegisteredComponentProvider.register(this);
        }

        if (this.backButtonText == '' || this.backButtonText == null) {
            this.backButtonText = 'Back';
        }

        if (this.nextButtonText == '' || this.nextButtonText == null) {
            this.nextButtonText = 'Next';
        }
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        let doBuild: boolean = false;

        if (changes.modules && changes.modules.currentValue != null) {
            this._modules = angular.copy(changes.modules.currentValue);
            doBuild = true;
        }

        if (changes.selectedModule) {
            this.selectedModule = changes.selectedModule.currentValue;
            doBuild = true;
        }

        this.selectModule();

        if (this.currentModule == null) {
            return;
        }

        if (doBuild == true) {
            this.buildPages(this.currentModule).then(() => {
                this.selectChapter();
                this.forwards(); // both build pages and select chapter leave things needing to be forwarded
            });
        }

        if (changes.selectedChapter) {
            this.selectedChapter = changes.selectedChapter.currentValue;

            if (doBuild == false) {
                this.selectChapter();
                this.forwards();
            }
        }
    }

    $onDestroy(): void {
        // We are currently viewing a page, close off the media viewing ..
        this.currentPage = null;
        this.sendMediaNotify();

        if (this.name == null) {
            return;
        }

        this.vmRegisteredComponentProvider.deRegister(this);
    }

    forwards(): void {
        if (this.pageNumber > this.pages.length) {
            return;
        }

        this.previousPage = this.currentPage;

        this.pageNumber += 1;
        this.currentPage = this.pages[this.pageNumber - 1];

        this.renderCurrentPage();
    }

    backwards(): void {
        if (this.pageNumber < 0) {
            return;
        }

        this.previousPage = this.currentPage;

        this.pageNumber = this.pageNumber - 1;
        this.currentPage = this.pages[this.pageNumber - 1];

        this.renderCurrentPage();
    }

    renderCurrentPage(): void {
        // render the page, we do this in a timeout so that angular has the chance to
        // change out the template of what's being displayed so that the .viewer find
        // locates the right type of element for things to be displayed in
        this.$timeout(() => {
            if (this.currentPage == null) {
                return;
            }

            let r = this.rendererFactory.getPageRenderer(this.currentPage);
            r.render(this.$element.find('.viewer')[0], this.currentPage, this.currentScale);

            if (this.previousPage !== this.currentPage) {
                this.notifyPageView(true);
            }
        });
    }

    toggleFitToPage(): void {
        if (this.currentPage == null) {
            return;
        }

        this.viewMode = this.viewMode == 'fit-page' ? 'normal' : 'fit-page';
        this.currentScale = this.viewMode == 'fit-page' ? 0 : 1;
        this.renderCurrentPage();
    }

    zoomIn(): void {
        if (this.currentPage == null) {
            return;
        }

        if (this.currentScale == 0) {
            this.currentScale = 1;
        }

        this.viewMode = 'normal';
        this.currentScale = this.currentScale * 2;
        this.renderCurrentPage();
    }

    zoomOut(): void {
        if (this.currentPage == null) {
            return;
        }

        if (this.currentScale == 0) {
            this.currentScale = 1;
        }

        this.viewMode = 'normal';
        this.currentScale = this.currentScale / 2;
        this.renderCurrentPage();
    }

    sendMediaNotify() {
        var $userId = this.$q.resolve(this.userId);
        if (this.userId == null) {
            var authSvc: IAuthService = this.$injector.get('authSvc');
            $userId = authSvc.getUser().then(user => user.userId);
        }
        $userId.then(userId => {
            if (userId != null) {
                var parameters = {
                    userid: userId,
                    workingdocumentid: this.workingDocumentId,
                    startviewingmediaid: this.currentPage?.id,
                    startviewingpage: this.currentPage?.index,
                    finishviewingmediaid: this.previousPage?.id,
                    finishviewingpage: this.previousPage?.index,
                    viewingdate: moment().utc().format(),
                };

                this.bworkflowApi.execute('MediaViewing', 'Notify2', parameters);
            }
        });
    }

    notifyPageView(forwards: boolean): void {
        let stateKey = `${this.currentPage?.id}:${this.currentPage?.index}:${this.previousPage?.id}:${this.previousPage?.index}`;
        if (this.lastStateKey != stateKey) {
            this.lastStateKey = stateKey;
            this.sendMediaNotify();
        }

        if (this.onPageChange == null) {
            return;
        }

        this.onPageChange({
            $event: {
                previousPage: this.previousPage,
                currentPage: this.currentPage,
                pageCount: this.pages.length,
                pageNumber: this.pageNumber,
                currentChapter: this.currentPage ? this.currentPage.chapter : null
            }
        });
    }

    selectModule(): void {
        this.currentModule = null;

        if (this.selectedModule == null || this.selectedModule == '') {
            this.currentModule = this._modules[0];
            return;
        }

        if (this.isModuleNumber(this.selectedModule) == true) {
            if (this.selectedModule < 0 || this.selectedModule > this._modules.length - 1) {
                this.currentModule = this._modules[0];
                return;
            }

            this.currentModule = this._modules[this.selectedModule as number];
            return;
        }

        let toFind: string = '';

        if (this.isModuleString(this.selectedModule) == true) {
            toFind = this.selectedModule as string;
        }
        else {
            // we need to search on the module name as we take a copy in $onChanges of the modules,
            // so we will likely have 2 different objects representing the same module (one in modules and one in selectedModule)
            toFind = (this.selectedModule as IPresentationModule).module;
        }

        for (let m of this._modules) {
            if (m.module == toFind) {
                this.currentModule = m;
                break;
            }
        }

        if (this.currentModule == null) {
            this.currentModule = this._modules[0];
        }
    }

    selectChapter(): void {
        if (this.currentModule == null) {
            return;
        }

        if (this.selectedChapter == null || this.selectedChapter == '') {
            return;
        }

        let index: number = 0;

        if (this.isChapterNumber(this.selectedChapter) == true) {
            index = this.selectedChapter as number;
        }
        else {
            let name: string = '';

            if (this.isChapterString(this.selectedChapter) == true) {
                name = this.selectedChapter as string;
            }
            else {
                name = (this.selectedChapter as IPresentationChapter).name;
            }

            let i: number = 0;
            for (let c of this.currentModule.chapters) {
                if (c.name == name) {
                    index = i;
                    break;
                }
                i++;
            }
        }

        if (index < 0 || index >= this.currentModule.chapters.length) {
            index = 0;
        }

        let chapter: IPresentationChapter = this.currentModule.chapters[index];

        if (chapter.pages == null || chapter.pages.length == 0) {
            return;
        }

        let pageNumber = 0;
        for (let p of this.pages) {
            if (p == chapter.pages[0]) {
                break;
            }
            pageNumber++;
        }

        this.pageNumber = pageNumber;
    }

    buildPages(module: IPresentationModule): ng.IPromise<void> {
        // first we need to build up a list of all of the refernces
        // that are possibly multi page docs, so we can request from the server
        // how long each one is
        this.media = [];
        this.pages = [];
        this.pageNumber = 0;
        let multipagedocs: Array<IPresentationMedia> = [];
        var id = 0;
        var j = 0;

        let p = this.$q.defer<void>();

        for (let chapter of module.chapters) {
            chapter.index = j;
            chapter.pages = new Array<PresentationPage>();

            for (let media of chapter.media) {
                this.media.push(media);

                /*                if (media.type.toLowerCase() != 'word') {
                                    media.pages = 1;
                                    media.chapter = chapter;
                                    continue;
                                }
                */
                media.clientTrackingId = id;

                let mediaCopy = angular.copy(media); // we take a copy, as the next line adds a circular dependency, which screws things when we call back to the server
                mediaCopy.name = mediaCopy.name || chapter.name;
                multipagedocs.push(mediaCopy);
                media.chapter = chapter;
                id++;
            }
            j++;
        }

        this.bworkflowApi.execute('Presentation', 'GetPageCounts', { media: multipagedocs })
            .then((result: any) => {
                let data: IPresentationGetPageCountResult = result;
                for (let d of data.media) {
                    let match = this.media.find(m => m.clientTrackingId == d.clientTrackingId);

                    if (match != null) {
                        match.id = d.id;
                        match.name = d.name;
                        match.filename = d.filename;
                        match.pages = d.pages || 1;
                    }
                }

                // ok so now all our media have page counts, we can now use this to build up
                // a script of pages
                for (let media of this.media) {
                    if (media.pages == null) {
                        // this might happen if a media has no id, in this case we cannot track viewings against it
                        continue;
                    }

                    for (var i = 0; i < media.pages; i++) {
                        let page = new PresentationPage(i + 1, media);

                        if (page.type == PresentationMediaType.youtube || page.type == PresentationMediaType.vimeo) {
                            page.url = this.$sce.trustAsResourceUrl(page.url);
                        }
                        else {
                            // for images and word documents we load them from media on the server, we use mediaonserver objects to model this
                            if (page.id == null) {
                                // this shouldn't happen
                                return;
                            }

                            page.url = {
                                id: page.id,
                                name: '',
                                source: page.type == PresentationMediaType.word ? MediaOnServerSource.mediaPreview : MediaOnServerSource.media,
                                options: {
                                    pageIndex: i
                                }
                            }
                        }

                        this.pages.push(page);
                    }
                }

                p.resolve();
            });

        return p.promise;
    }
}

app.component('vmPresentation', {
    template: require('./template.html').default,
    bindings: {
        name: '<',
        modules: '<',
        selectedModule: '<',
        selectedChapter: '<',
        pageNumber: '<',
        onPageChange: '&',
        backButtonText: '<',
        nextButtonText: '<',
        userId: '<',
        workingDocumentId: '<'
    },
    controller: PresentationController
});