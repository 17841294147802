'use strict';

import '../../directives/vm-file-upload';
import '../../services/vm-media-service';

import angular from 'angular';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionUploadMedia', ['bworkflowApi',
  '$timeout',
  '$state',
  '$filter',
  'vmMediaService',
  'languageTranslate', function (bworkflowApi, $timeout, $state, $filter, vmMediaService, languageTranslate) {
    return $.extend({}, questionDirectiveBase, {
      template: require('./template.html').default,
      link: function (scope, elt, attrs) {
        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

        scope.answer = scope.presented.Answer;

        scope.imageOptions = {};
        if (scope.presented.options.maxWidth != null) {
          scope.imageOptions.maxWidth = scope.presented.options.maxWidth;
        }

        if (scope.presented.options.maxHeight != null) {
          scope.imageOptions.maxHeight = scope.presented.options.maxHeight;
        }

        scope.acceptFiles = "image/*";
        if(scope.presented.options.allowanymedia)
        {
          scope.acceptFiles = "image/png, image/jpeg, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
        }

        scope.buildClientMedia = function (files) {
          scope.d = {};

          var onServers = [];
          angular.forEach(files, function (file) {
            // need to build an IMediaOnServer object
            onServers.push({
              id: file.mediaId,
              name: file.name,
              source: 1,
              options: {}
            });
          });

          var onClients = vmMediaService.createOnClientFromOnServer(onServers);

          angular.forEach(onClients, function (client) {
            vmMediaService.loadClient(client);
          });

          scope.onClients = onClients;
        };

        scope.changed = function (evt) {
          scope.presented.Answer.Items = evt.files.map(file => {
            if (file.mediaOnServer == null || evt.type == 3) {
              // it's been uploaded from the client, so we need to send down the image data
              let result = {
                fname: file.name,
                name: file.name,
                type: 'image'
              };
              if (file.isImage == true) {
                result.data = file.data.data;
              }
              else {
                result.data = window.btoa(file.data.data);
              }

              return result;
            }
            else {
              // loaded from the server, so the server has the data, just needs to know that it's still linked in to us
              return { mediaId: file.mediaOnServer.id, fname: file.name, name: file.name };
            }
          });
        };

        scope.buildClientMedia(scope.presented.Answer.Items);
      }
    })
  }
]);

/*
questionsModule.directive('questionUploadMedia', ['bworkflowApi',
  '$timeout',
  '$state',
  '$filter',
  'languageTranslate', function (bworkflowApi, $timeout, $state, $filter, languageTranslate) {
    return $.extend({}, questionDirectiveBase, {
      template: require('./template.html').default,
      link: function (scope, elt, attrs) {
        questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

        scope.answer = scope.presented.Answer;
        scope.state = 'list';

        scope.maxHeight = scope.presented.options.maxHeight;
        scope.maxWidth = scope.presented.options.maxWidth;

        // this supports validation as each question type stores it's answer in a different way
        scope.getAnswerValue = function () {
          return scope.d.mediaItems.length == 0 ? '' : scope.d.mediaItems.length;
        };

        var addPhotoFile = function (file, cb) {
          window.SquareIT.BWorkflow.HandleUploads({
            maxWidth: scope.maxWidth,
            maxHeight: scope.maxHeight,
            allowImage: true,
            files: [file]
          },
            function (files) {
              $timeout(function () {
                scope.d.mediaItems = scope.d.mediaItems.concat(files);

                if (cb) {
                  cb();
                }
              });
            });
        };

        scope.takePhoto = function () {
          if (window.cordova && navigator.camera) {
            navigator.camera.getPicture(function (imageUri) {
              window.resolveLocalFileSystemURL(imageUri, function (fileEntry) {
                fileEntry.file(function (file) {
                  addPhotoFile(file, function () {
                    navigator.camera.cleanup();
                  });
                });
              });
            }, function (error) {
              $timeout(function () {
                alert('Error taking photo\n' + error);
              });
            }, {
              // Some common settings are 20, 50, and 100
              quality: 50,
              destinationType: Camera.DestinationType.FILE_URI,
              // In this app, dynamically set the picture source, Camera or photo gallery
              sourceType: Camera.PictureSourceType.CAMERA,
              encodingType: Camera.EncodingType.JPEG,
              mediaType: Camera.MediaType.PICTURE,
              allowEdit: false,
              correctOrientation: false //Corrects Android orientation quirks
            });
          } else {
            $(elt.find('.camera_input')[0]).trigger('click');
          }
        };

        scope.photoTaken = function (element) {
          if (element.files.length === 0) return;

          addPhotoFile(element.files[0]);
        };

        scope.deletePhoto = function (item) {
          if (confirm("Are you sure you want to remove this photo?") == true) {
            var index = scope.d.mediaItems.indexOf(item);
            if (index != -1)
              scope.d.mediaItems.splice(index, 1);
          }
        };

        scope.getMediaLink = function (id) {
          return scope.getFullServerPath('/Media/GetMediaItem/' + id);
        };

        scope.d = {};
        scope.d.mediaItems = $.map(scope.presented.Answer.Items, function (mi) {
          var newMi = mi; //reference copy, but we're allowed to clobber scope.presented.Answer anyway

          //we have mediaIds, code up Image objects
          //also set type to 'image' if needed
          if (window.isImage(mi.fname)) {
            newMi.type = 'image';
          } else {
            newMi.type = 'file';
          }

          if (newMi.type == 'image') {
            newMi.data = new Image();
            newMi.data.src = $filter('mediaItem')(newMi.mediaId);
          }
          return newMi;
        });

        scope.$on('populateAnswer', function (ev) {
          //need to fill up scope.Answer
          //mediaItems is a good start
          scope.answer.Items = scope.d.mediaItems;
          //but we need to strip out Files and Images and replace them with base64 coded strings
          for (var i = 0; i < scope.answer.Items.length; i++) {
            var item = scope.answer.Items[i];
            if (item.mediaId) {
              //If tghe object's mediaid is set, it (as in it's data) hasn't been modified at all
              //(i.e. this means, hasn't been drawn on)
              //as an optimisation, don't reupload, just allow the server to look up the media by ID
              item.data = null;
            } else if (item.type == 'image') {
              //pull something out of the data url
              //starts with data:image/{png,jpg};base64,
              //look for base64, and start trim before that
              var b64idx = item.data.src.indexOf('base64,');
              item.data = item.data.src.substring(b64idx + 7);
            } else if (item.type == 'file') {
              //base64 code the binary string
              item.data = window.btoa(item.data);
            }
          }

        });

      }
    });

  }]);

  */