import { AboutCategory } from "./about-category";

export abstract class BaseAboutService {
	constructor(protected $timeout: ng.ITimeoutService) {
	}

	getOrAddCustom(categories: AboutCategory[], id: string, fnCreate: () => AboutCategory): AboutCategory {
		let category = categories.find(c => c.id === id);
		if (category == null) {
			category = fnCreate();
			categories.push(category);
		}
		return category;
	}

	getOrAdd(categories: AboutCategory[], id: string, display: string): AboutCategory {
		return this.getOrAddCustom(categories, id, () => new AboutCategory(this.$timeout, id, display));
	}


	cancel(categories?: AboutCategory[]): void {
		categories?.map(c => c.cancel());
	}
}
