import app from './ngmodule';
import { IValidationMessageFactory, IValidationResult, IValidationRule } from '../';
import { ValidationRuleBase } from './validation-rule-base';

// Validation rule that fails if length of the value is not between 
export class LengthValidationRule extends ValidationRuleBase {
    constructor(message: string | IValidationMessageFactory, public minLength?: number, public maxLength?: number) {
        super(message);
    }

    isValid(value: any, template: any): IValidationResult {
        let result = {
            errors: new Array<string>()
        };

        if (value == null) {
            // if null or undefined we don't look at it
            return result;
        }

        let len = value.toString().length;

        if ((this.minLength != null && len < this.minLength) || (this.maxLength != null && len > this.maxLength)) {
            result.errors.push(this.getMessage(value, template));
        }

        return result;
    }
}