'use strict';

import angular from 'angular';
import 'angular-recaptcha';
import questionsModule from '../ngmodule';
import questionDirectiveBase from '../question-directive-base';

questionsModule.directive('questionRecaptcha', ['bworkflowApi', 'languageTranslate', function (bworkflowApi, languageTranslate) {
  return $.extend({}, questionDirectiveBase, {
    template: require('./template.html').default,
    link: function (scope, elt, attrs) {
      scope.answer = scope.presented.Answer;

      scope.presented.controlShowsPrompt = true;

      questionDirectiveBase.link(scope, undefined, bworkflowApi, languageTranslate);

      scope.answer.recaptchaResponse = "";
    }
  });

}]);