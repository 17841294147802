import { Question, Answer } from "./question";
import { JsonTreeTranslators, Convert } from '@virtual-mgr/json-tree';
import { ITemperatureProbeQuestionDefinition, TemperatureProbeQuestionReadingType, DesignTemperatureProbeQuestion } from 'lingo-api';
import { ILingoAnswer } from "../../lingojs/lingo-answers-context";
import { ITemperatureProbeAnswer, IPresentedTemperatureProbeQuestion } from "../../presented-types";

export class TemperatureProbeQuestion extends Question implements DesignTemperatureProbeQuestion {
	defaultAnswer?: number;
	readingType?: TemperatureProbeQuestionReadingType = `realtime`;
	displayAsFahrenheit?: boolean = false;
	maximumPassReading?: number;
	minimumPassReading?: number;
	refreshPeriod?: number = 1;
	allowedProbeProviders?: string[];
	allowedProbeSerialNumbers?: string[];
	probeOutOfRangeTimeout?: number = 30;
	disconnectProbeTimeout?: number = 10;

	constructor() {
		super(`temperatureProbe`);
	}
}

export class TemperatureProbeAnswer extends Answer implements ILingoAnswer {
	constructor(public answer: ITemperatureProbeAnswer, public presentedQuestion: IPresentedTemperatureProbeQuestion) {
		super(answer, presentedQuestion);
	}

	get value() {
		return this.answer.Celcius;
	}
	set value(value: number | null) {
		this.answer.Celcius = value;
	}
}

JsonTreeTranslators.register({
	ctr: TemperatureProbeQuestion,
	create: () => new TemperatureProbeQuestion()
}, {
	ctr: TemperatureProbeAnswer,
	flatten(o: TemperatureProbeAnswer) {
		return {
			answer: o.answer,
			presentedQuestion: o.presentedQuestion
		}
	},
	fatten(o: any, fatten: Convert, store: Convert) {
		return store(new TemperatureProbeAnswer(fatten(o.answer), fatten(o.presentedQuestion)));
	}
});
