'use strict';

import './index.scss';

import app from '../../ngmodule';

app.directive('playEmbedded', ["bworkflowApi", "$q", "$timeout", "appUpdateMonitor", "playerButtons", "ChecklistState",
  function (bworkflowApi, $q, $timeout, appUpdateMonitor, playerButtons, ChecklistState) {
    return {
      template: require('./template.html').default,
      restrict: 'E',
      require: 'ngModel',
      scope: {
        workingdocumentid: '=ngModel',
        exposeAnswerModel: '=?',
        onState: '&',
        onStarted: '&',
        onFinishing: '&',
        onFinished: '&',
        onNavigating: '&'
      },

      link: function (scope, elt, attrs, ngModel) {
        appUpdateMonitor.addPauser(scope); // Pause updates whilst our scope is alive

        if (angular.isDefined(scope.exposeAnswerModel) == false) {
          scope.exposeAnswerModel = false;
        }

        scope.ChecklistState = ChecklistState;
        scope.models = [];
        scope.status = 'none';

        scope.show = function (r) {
          switch (r.State) {
            case ChecklistState.Error:
              scope.showError(r);
              break;
            case ChecklistState.Presenting:
              scope.showStep(r);
              break;
            case ChecklistState.Finishing:
              scope.showFinishing(r);
              break;
            case ChecklistState.Finished:
              scope.showFinished(r);
              if (r.Presented) {
                scope.showStep(r);
              } else {
                scope.showFinished(r);
              }
              break;
          }


          scope.onState({ $event: { state: r.State } });
        };

        scope.showStep = function (r) {
          // we need do a little work to get things into a format where the rest of the framework
          // understands things. The following code is copied from a section of the code that KJ
          // has put together.
          var PlayerModel = r;
          var AnswerModel = {};

          if (r.Presented) {
            //enumerate all the answerable nodes in the answerModel
            (function thisfunc(_, p) {//jquery each makes the actual value the second arg, so define the function this way
              if (p.IsAnswerable) {
                AnswerModel[p.Id] = p.Answer || {};
                p.Answer = AnswerModel[p.Id];
                // the embedded player includes the question id, as it could be used for context information
                // and the questionid in this case is the only thing that is fixed that allows us to re-hook up
                // the answer model back to the checklist on the server
                p.Answer.QuestionId = p.QuestionId;
              }
              if (p.Children) {
                $.each(p.Children, thisfunc);
              }
            })(undefined, PlayerModel.Presented);

            playerButtons.canNext = r.State !== ChecklistState.Finished;
            playerButtons.canPrevious = r.CanPrevious;

            scope.AnswerModel = AnswerModel;
            scope.PlayerModel = PlayerModel;
            var m = {
              PlayerModel: PlayerModel,
              AnswerModel: AnswerModel,
              show: true
            };


            if (scope.exposeAnswerModel) {
              scope.PlayerModel.Answers = AnswerModel;
            }

            scope.models.push(m);

            scope.status = 'ready';

            scope.$emit('embedded-player.showstep', r);
          }
        };

        scope.showFinishing = function (r) {
          var args = {
            show: true,
            data: r
          };


          if (scope.onFinishing != null) {
            scope.onFinishing({ $event: args });
          }

          scope.$emit('embedded-player.finishing', args);

          if (args.show == true) {
            scope.status = 'finishing';
          }
        };

        scope.showFinished = function (r) {
          var args = {
            show: true,
            data: r
          };

          if (scope.onFinished != null) {
            scope.onFinished({ $event: args });
          }

          scope.$emit('embedded-player.finished', args);

          if (args.show == true) {
            scope.status = 'finished';
          }
        };

        scope.showError = function (r) {

        };

        scope.loadworkingdocument = function (id) {
          scope.status = 'loading';

          scope.models = [];

          // ok we accept either the id of a working document, or a JSON representation of a PlayModel
          // object that who ever we are embedded in has loaded and handed to us
          if (angular.isDefined(id.WorkingDocumentId) == false) {
            bworkflowApi.continueChecklist(id).
              then(function (r) {
                if (scope.onStarted != null) {
                  scope.onStarted({ $event: { data: r } });
                }

                scope.$emit('embedded-player.started', {
                  data: r
                });


                scope.show(r);
              }, function (ex) {
                scope.status = 'error';
                // need to handle this
              });
          } else {
            // simple, we have what we need
            if (scope.onStarted != null) {
              scope.onStarted({ $event: { data: id } });
            }
            scope.$emit('embedded-player.started', {
              data: id
            });

            scope.show(id);
          }
        };

        scope.$watch('workingdocumentid', function (newValue, oldValue) {
          if (newValue == null) {
            return;
          }

          scope.loadworkingdocument(newValue);
        });

        scope.collectAnswers = function (type) {
          // this code has been copied and adapter from the main player

          //start by broadcasting a next notification downwards
          //In the eventArgs will be a method that can be called to attach a promise to a list
          //We only actually do the server next method once all the promises have been resolved
          var promiseList = [];
          var attachPromise = function (promise) {
            promiseList.push(promise);
          };
          var ev = {
            attachPromise: attachPromise,
            type: type //either 'next' or 'prev'
          };
          //events are broadcast synchronously in angular:
          //https://groups.google.com/forum/#!msg/angular/yyH3FYAy5ZY/GVy0ckj29CIJ
          //Means that promiseList is populated after this line
          scope.$broadcast('populateAnswer', ev);

          return promiseList;
        };

        scope.navigateChecklist = function (type, args) {
          var promiseList = scope.collectAnswers(type);

          if (args == null) {
            args = {};
          }

          var allowOffline = false;
          if (angular.isDefined(args.allowOffline)) {
            allowOffline = args.allowOffline;
          }

          var wdid = scope.workingdocumentid;
          if (angular.isDefined(wdid.WorkingDocumentId)) {
            wdid = wdid.WorkingDocumentId;
          }

          //only after the promises are resolved do we do anything else
          $q.all(promiseList).
            then(function () {
              if (type == 'next') {
                playerButtons.areAjaxing = true;

                if (scope.onNavigating != null) {
                  scope.onNavigating({ $event: { starting: true } });
                }

                var p = bworkflowApi.nextChecklist(wdid,
                  scope.AnswerModel, allowOffline);

                if (angular.isDefined(args.afterNextCallback) == true) {
                  args.afterNextCallback();
                }

                return p;
              } else if (type == 'prev') {
                playerButtons.areAjaxing = true;

                if (scope.onNavigating != null) {
                  scope.onNavigating({ $event: { starting: true } });
                }

                return bworkflowApi.prevChecklist(wdid, allowOffline);
              } else return undefined;
            }).
            then(function (r) {
              playerButtons.areAjaxing = false;

              if (scope.onNavigating != null) {
                scope.onNavigating({ $event: { starting: false, canBack: r.CanPrevious } });
              }

              scope.show(r);
            }, function (ex) {
              playerButtons.areAjaxing = false;

              if (scope.onNavigating != null) {
                scope.onNavigating({ $event: { starting: false } });
              }
            });
        };

        scope.saveChecklist = function () {
          var promiseList = scope.collectAnswers('next');

          return bworkflowApi.saveChecklist(scope.workingdocumentid, scope.AnswerModel);
        };

        scope.finish = function (args) {
          scope.navigateChecklist('next', args);
        };

        scope.cleanModels = function () {
          if (scope.models.length <= 2) {
            return;
          }

          // the last 2 items in the models array are the most recent
          // so could be animating, we don't touch these, but remove everthing
          // else to avoid clutter
          scope.models.splice(0, scope.models.length - 2);
        };

        scope.validate = function (args) {
          var data = {
            errors: [],
            stage: 'next'
          };

          scope.$broadcast('validate', data);

          if (args) {
            if (angular.isDefined(args.validationCallback) == true) {
              args.validationCallback(data.errors.length == 0);
            }
          }

          return data.errors.length == 0;
        };

        scope.next = function (args) {
          if (scope.validate(args) == false) {
            return;
          }

          scope.action = 'next';

          $timeout(function () {
            if (scope.models.length > 0) {
              scope.models[scope.models.length - 1].show = false;

              scope.cleanModels();
            }

            scope.navigateChecklist('next', args);
          });
        };

        scope.$on('embedded-player.next', function (event, args) {
          scope.next(args);
        });

        scope.back = function (args) {
          scope.action = 'prev';

          $timeout(function () {
            if (scope.models.length > 0) {
              scope.models[scope.models.length - 1].show = false;

              scope.cleanModels();
            }

            scope.navigateChecklist('prev', args);
          });
        }

        scope.$on('embedded-player.back', function (event, args) {
          scope.back(args);
        });

        scope.$on('embedded-player.save', function (event, args) {
          scope.saveChecklist();
        });
      }
    };
  }]);