import angular from 'angular';

// Dependancy race between here and ../player/ngmodule which will define the actual dependencies 
var ngModule: ng.IModule;
try {
	ngModule = angular.module('player');
} catch {
	ngModule = angular.module('player', []);
}

export default ngModule;

if(typeof window.cordova !== 'undefined')
{
	ngModule.requires.push('vmplayer-common');
}
