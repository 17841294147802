'use strict';

import angular from 'angular';

export default function (questionModule, questionExport) {
  var match = /questions\/(.*?)\//.exec(questionModule.id);
  return angular.merge({
    compile: "<div render-question='$ctrl.input' answers='$ctrl.output' page-scope='$ctrl.pageScope' watch='true' flex></div>",
    makeOutput: function (input) {
      this.answer = input.Answer || {};
      return this.answer;
    },
    input: angular.merge({
      Directive: match[1],
      HtmlPresentation: {
        CustomStyling: {}
      },

      Visible: true,
      Answer: {}
    },
      questionExport.input)
  },
    questionExport);
}