import moment, { Moment, Duration } from "moment";
import { FacilityType, IFacility, IWorkingDocumentFacility } from 'lingo-api';
import { IOfflineChecklistPlayer } from "../../offline-checklist-player";

export class WorkingDocumentFacility implements IFacility, IWorkingDocumentFacility {
	readonly facilityType: FacilityType = `workingDocument`;

	constructor(
		private offlineChecklistPlayer: IOfflineChecklistPlayer) {
	}

	async setExpiresAt(expiresAt: string | Date | Moment): Promise<void> {
		let expiresAtUtc = moment(expiresAt).utc();
		const wdCtx = this.offlineChecklistPlayer.getCurrentContext();
		let workingDocument = await this.offlineChecklistPlayer.getWorkingDocument(wdCtx.workingDocumentId);
		workingDocument.expiresAtUtc = expiresAtUtc;
	}

	async setExpiresDuration(duration: string | Duration): Promise<void> {
		let expiresAtUtc = moment().add(duration).utc();
		const wdCtx = this.offlineChecklistPlayer.getCurrentContext();
		let workingDocument = await this.offlineChecklistPlayer.getWorkingDocument(wdCtx.workingDocumentId);
		workingDocument.expiresAtUtc = expiresAtUtc;
	}

	async getExpiresAt(): Promise<Moment> {
		const wdCtx = this.offlineChecklistPlayer.getCurrentContext();
		let workingDocument = await this.offlineChecklistPlayer.getWorkingDocument(wdCtx.workingDocumentId);
		return workingDocument.expiresAtUtc;
	}
}