import app from './ngmodule';
import { IValidationMessageFactory, IValidationResult, IValidationRule } from '../';
import { ValidationRuleBase } from './validation-rule-base';

export interface ICustomValidationRule { (value: any, template: any): boolean };

// Validation rule that fails if the value handed into it is undefined or null
export class CustomValidationRule extends ValidationRuleBase {
    constructor(message: string | IValidationMessageFactory, protected callback: ICustomValidationRule) {
        super(message);
    }

    isValid(value: any, template: any): IValidationResult {
        return this.callback(value, template) ? { errors: [] } : { errors: [this.getMessage(value, template)] };
    }
}