'use strict';

import angular from 'angular';
import app from '../../../ngmodule';
import './style.scss';

app.directive('dashboardMenuList', ["$state", "$stateParams", function ($state, $stateParams) {
  return {
    template: require('./template.html').default,
    scope: {
      items: '=ngModel'
    },

    link: function (scope, elt, attrs) {
      scope.selected = $stateParams.name;

      scope.$watch('items', function (newValue, oldValue) {
        if (!angular.isArray(newValue)) {
          return;
        }
        scope.selectDashboard(newValue[0]);
      });

      scope.selectDashboard = function (dashboard) {
        $state.go('root.dashboard.name', { name: dashboard.ChecklistName });
      };
    }
  };

}]);