'use strict';

import angular from 'angular';

var pendingPaymentsModule = angular.module('pendingPayments', []);
export default pendingPaymentsModule;

pendingPaymentsModule.factory('pendingPaymentsService', ['$http', '$window', function ($http, $window) {
  var _api = $window.razordata.apiprefix;

  var svc = {
    modelPendingPayment: function (pp) {
      pp.ActivatedDateUtc = pp.ActivatedDateUtc ? moment(pp.ActivatedDateUtc) : null;
      pp.PaidInFullDateUtc = pp.PaidInFullDateUtc ? moment(pp.PaidInFullDateUtc) : null;
      pp.CancelledDateUtc = pp.CancelledDateUtc ? moment(pp.CancelledDateUtc) : null;
      pp.DueDateUtc = pp.DueDateUtc ? moment(pp.DueDateUtc) : null;
      pp.FromUtc = pp.FromUtc ? moment(pp.FromUtc) : null;
      pp.ToUtc = pp.ToUtc ? moment(pp.ToUtc) : null;
      pp.PendingExpiryUtc = pp.PendingExpiryUtc ? moment(pp.PendingExpiryUtc) : null;
    },

    modelPendingPayments: function (pendingPayments) {
      if (!pendingPayments) {
        return;
      }
      var self = this;
      if (pendingPayments.Plan) {
        pendingPayments.Plan.Start = moment(pendingPayments.Plan.Start);
        pendingPayments.Plan.End = moment(pendingPayments.Plan.End);
        pendingPayments.Plan.FirstBillingDateUtc = moment(pendingPayments.Plan.FirstBillingDateUtc);
        pendingPayments.Plan.LastBillingDateUtc = moment(pendingPayments.Plan.LastBillingDateUtc);
      }
      pendingPayments.totalDue = 0;
      pendingPayments.totalFull = 0;
      pendingPayments.totalDiscount = 0;
      angular.forEach(pendingPayments.Payments, function (pp) {
        self.modelPendingPayment(pp);
        pendingPayments.totalDue += pp.DueAmount;
        pendingPayments.totalFull += pp.FullAmount;
        pendingPayments.totalDiscount += pp.DiscountAmount;
      });
    },

    applyCouponCode: function (couponCode, orderId) {
      var self = this;
      return $http({
        url: _api + 'Order/ApplyCouponCode',
        method: 'POST',
        data: {
          couponCode: couponCode,
          orderId: orderId
        }
      }).

        then(function (response) {
          if (response.data && response.data.payments) {
            self.modelPendingPayments(response.data.payments);
          }
          return response.data;
        });
    },

    removeCoupon: function (couponId, orderId) {
      var self = this;
      return $http({
        url: _api + 'Order/RemoveCouponCode',
        method: 'POST',
        data: {
          couponId: couponId,
          orderId: orderId
        }
      }).

        then(function (response) {
          if (response.data && response.data.payments) {
            self.modelPendingPayments(response.data.payments);
          }
          return response.data;
        });
    },

    orderCoupons: function (orderId) {
      var self = this;
      return $http({
        url: _api + 'Order/OrderCoupons',
        method: 'POST',
        data: {
          orderId: orderId
        }
      }).

        then(function (response) {
          if (response.data && response.data.payments) {
            self.modelPendingPayments(response.data.payments);
            response.data.order.CancelledDateUtc = response.data.order.CancelledDateUtc ? moment(response.data.order.CancelledDateUtc) : null;
          }
          return response.data;
        });
    },

    cancelOrder: function (orderId) {
      var self = this;
      return $http({
        url: _api + 'Order/CancelOrder',
        method: 'POST',
        data: {
          orderId: orderId
        }
      }).

        then(function (response) {
          if (response.data && response.data.payments) {
            self.modelPendingPayments(response.data.payments);
            response.data.order.CancelledDateUtc = response.data.order.CancelledDateUtc ? moment(response.data.order.CancelledDateUtc) : null;
          }
          return response.data;
        });
    }
  };


  return svc;
}]);