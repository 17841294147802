'use strict';

import './index.scss';
import '../vm-card-list';
import '../vm-image-manipulator';

import app from '../ngmodule';
import angular, { IRootElementService } from 'angular';
import { ControllerWithPromptBase } from '../control-with-prompt-base';
import { IMediaOnClient, IVMMediaService, MediaOnClientStatus } from '../../services/vm-media-service';
import { IImageManipulatorChangedEvent } from '../vm-image-manipulator';
import moment from 'moment';
import { IVMRegisteredComponent, IVMRegisteredComponentProvider } from '../../services/vm-registered-component-provider';


export enum FileUploadChangedType {
    NewFile = 1,
    DeletedFile,
    EditedFile
}

export interface IFileUploadChangedEvent {
    type: FileUploadChangedType;
    changed: IMediaOnClient;

    files: Array<IMediaOnClient>;
}

export interface IFileUploadImageOptions {
    maxWidth?: number;
    maxHeight?: number;
}

class FileUploadChangedEvent implements IFileUploadChangedEvent {
    type: FileUploadChangedType;
    changed: IMediaOnClient;

    files: Array<IMediaOnClient>;

    constructor(type: FileUploadChangedType, changed: IMediaOnClient, files: Array<IMediaOnClient>) {
        this.type = type;
        this.changed = changed;
        this.files = files;
    }
}

class FileWrapper {
    file?: IMediaOnClient;

    constructor(f?: IMediaOnClient) {
        this.file = f;
    }
}

class FileUploadController extends ControllerWithPromptBase implements IVMRegisteredComponent {
    public fileWrappers: Array<FileWrapper>;
    public invalid: boolean;

    public name: string;

    public description?: string;
    public descriptionAsHtml?: string;

    public showPromptAndDescription: boolean = true;

    public imageOptions?: IFileUploadImageOptions;
    public maxFileCount: number;

    public allowUploadFile = true;
    public allowTakePicture = true;
    public allowPictureEdit = true;
    public accepts: string;

    protected uploadFileElement?: IRootElementService;
    protected editing: boolean;
    protected editingWrapper?: FileWrapper;

    public onUpdate?: null | ((args: any) => void) = null;

    private takingPicture = false;

    static $inject = ['$element', '$sce', '$timeout', 'vmMediaService', 'vmRegisteredComponentProvider'];
    constructor(
        protected $element: ng.IRootElementService,
        $sce: ng.ISCEService,
        protected $timeout: ng.ITimeoutService,
        protected vmMediaService: IVMMediaService,
        protected vmRegisteredComponentProvider: IVMRegisteredComponentProvider
    ) {
        super($sce);

        this.name = '';

        this.invalid = false;
        this.fileWrappers = [];
        this.maxFileCount = 1;
        this.editing = false;
        this.accepts = "image/*";
    }

    getComponentType(): string {
        return 'FileUpload';
    }

    $onInit(): void {
        super.$onInit();


        if (this.description == undefined) {
            this.description = this.valueFromHtml("description", '', this.$element);
        }

        if (angular.isString(this.description)) {
            this.descriptionAsHtml = this.$sce.trustAsHtml(this.description);
        }
        else {
            this.descriptionAsHtml = this.description;
        }

        if (this.showPromptAndDescription == undefined) {
            this.showPromptAndDescription = this.valueFromHtml("show-prompt-and-description", true, this.$element);
        }

        if (this.maxFileCount == undefined) {
            this.maxFileCount = this.valueFromHtml("max-file-count", 1, this.$element);
        }

        if (this.allowTakePicture == undefined) {
            this.allowTakePicture = this.valueFromHtml("allow-take-picture", true, this.$element);
        }

        if (this.allowPictureEdit == undefined) {
            this.allowPictureEdit = this.valueFromHtml("allow-picture-edit", true, this.$element);
        }

        if (this.allowUploadFile == undefined) {
            this.allowUploadFile = this.valueFromHtml("allow-upload-file", true, this.$element);
        }

        if (this.accepts == undefined) {
            this.accepts = this.valueFromHtml("accepts", "image/*", this.$element);
        }

        if(this.name && this.name != '')
        {
            this.vmRegisteredComponentProvider.register(this);
        }

        this.uploadFileElement = this.$element.find('div .uploadfile');

        this.uploadFileElement.on('change', (event: Event) => {
            this.$timeout(() => {
                let files: FileList | null = (event.target as HTMLInputElement).files;
                if (files == null) {
                    return;
                }

                this.vmMediaService.createOnClientFromFiles(files).then(
                    onClients => {
                        for (let c of onClients) {
                            if (c.isImage) {
                                this.vmMediaService.removeOrientationFromOnClient(c, this.imageOptions?.maxWidth, this.imageOptions?.maxHeight).then(() => {
                                    this.wrapFile(c);
                                });
                            }
                            else {
                                this.wrapFile(c);
                            }
                        }
                    }
                );
            });
        });
    }

    wrapFile(client: IMediaOnClient): void {
        let wrapper: FileWrapper = new FileWrapper(client);
        this.fileWrappers.push(wrapper);

        this.changed(FileUploadChangedType.NewFile, wrapper);
    }

    $onChanges(changes: angular.IOnChangesObject): void {
        if (changes.files && changes.files.currentValue) {
            this.fileWrappers = new Array<FileWrapper>();

            for (let f of changes.files.currentValue) {
                let wrapper: FileWrapper = new FileWrapper(f);
                this.fileWrappers.push(wrapper);
            }
        }

        if (changes.maxFileCount && changes.maxFileCount.currentValue) {
            this.maxFileCount = changes.maxFileCount.currentValue;
        }

        if (changes.imageOptions && changes.imageOptions.currentValue) {
            this.imageOptions = changes.imageOptions.currentValue;
        }
    }

    changed(type: FileUploadChangedType, changed: FileWrapper): void {
        if (this.onUpdate == null || changed.file == null) {
            return;
        }

        let files: Array<IMediaOnClient> = [];

        for (let w of this.fileWrappers) {
            if (w.file == null) {
                continue;
            }

            files.push(w.file);
        }

        this.onUpdate({ $event: new FileUploadChangedEvent(type, changed.file, files) });
    }

    selectFile(selector: string): boolean {
        if (this.uploadFileElement == null) {
            return false;
        }

        this.uploadFileElement[0].click();

        return false;
    }

    showTakePicture(): void {
        this.takingPicture = true;
    }

    onTakePicture(dataUrl: string): void {
        this.takingPicture = false;
        let moc = this.vmMediaService.createMediaOnClientFromDataUrl(`Photo ${moment.utc().format()}`, dataUrl);

        if(moc == null)
        {
            return;
        }

        if(this.imageOptions && (this.imageOptions.maxWidth || this.imageOptions.maxHeight))
        {
            this.vmMediaService.removeOrientationFromOnClient(moc, this.imageOptions.maxWidth, this.imageOptions.maxHeight).then((client) => {
                this.wrapFile(client);
            });

            return;
        }

        this.wrapFile(moc);
    }

    cancelTakePicture(): void {
        this.takingPicture = false;
    }

    removeFile(wrapper: FileWrapper): void {
        this.fileWrappers.splice(this.fileWrappers.indexOf(wrapper), 1);

        this.changed(FileUploadChangedType.DeletedFile, wrapper);
    }

    editFile(wrapper: FileWrapper): void {
        this.editing = true;
        this.editingWrapper = wrapper;
    }

    cancelEdit(): void {
        this.editing = false;
        this.editingWrapper = undefined;
    }

    confirmEdit(args: IImageManipulatorChangedEvent): void {
        if (this.editingWrapper?.file?.data == null) {
            return;
        }

        if (args.editedMediaOnClient.data?.data == null) {
            return;
        }

        this.editingWrapper.file.data.data = args.editedMediaOnClient.data.data;
        this.editingWrapper.file.data.contentType = args.editedMediaOnClient.data.contentType;

        this.changed(FileUploadChangedType.EditedFile, this.editingWrapper);

        this.editing = false;
        this.editingWrapper = undefined;
    }
}

app.component('vmFileUpload', {
    template: require('./template.html').default,
    bindings: {
        name: '@',
        prompt: '<',
        description: '<',
        showPromptAndDescription: '<',
        files: '<',
        invalid: '<',
        onUpdate: '&',
        imageOptions: '<',
        accepts: '<',
        maxFileCount: '<',
        allowUploadFile: '<',
        allowTakePicture: '<',
        allowPictureEdit: '<'
    },
    controller: FileUploadController
});